import React, { useState, useEffect } from 'react'
import { Input, Icon, Alert } from 'rsuite'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import { isString, find } from 'lodash'
import usePageSetting from '../../hook/usePageSetting'
import { generateRandomCode as generateCode } from '../../utils'
import { statusType, scheduleType } from './constants'
import { TitleCell, TypeCell, JobCell, RecordCell } from '../../components/scheduler'
import { getSchedulerList, getSchedulerOptions, updateScheduler, deleteScheduler } from '../../api'
import { DateCell, ActionCell, ToggleCell } from '../../components/table/cell'
import { ListWrap, AddListModel, FormGrop, AddListGrop, BreadcrumbNav, DropdownGroup, FormBottomButtons } from '../../components/views'
import PopWarning from '../../components/popWarning'
import { TableController } from '../../components/table'
import "react-datepicker/dist/react-datepicker.css"

const initCurrentInfo = {
	id: null,
	name: '',
	function: '',
	description: '',
  type: '',
	nameError: '',
	descError: '',
  notification_slack: [],
}

const Scheduler = () => {
	const [schedulerChannels, getSchedulerChannels] = useState([])
	const [schedulerFunctions, getSchedulerFunctions] = useState([])
  const { isAddMode, list, currentInfo, warning, toggleAddMode, handleOnChange, setList, setCurrentInfo, setWarning } = usePageSetting(initCurrentInfo)

	// init
	useEffect(() => {
		fetchSchedulerOptions()
		fetchSchedulerList()
	}, [])

	const fetchSchedulerOptions = async () => {
		const response = await getSchedulerOptions()
		if (response.status !== 200) return console.log(response.data)
		const { availableSlackNotificationChannels, availableSchedulerFunctions } = response.data
		getSchedulerChannels(availableSlackNotificationChannels.map(({ id, channel }) => {
			return {name: channel, value: id }
		}))
		getSchedulerFunctions(availableSchedulerFunctions)
	}

	const fetchSchedulerList = async () => {
		const response = await getSchedulerList()
		if (response.status !== 200) return console.log(response.data)
		const { data } = response
		setList(data)
	}

	const handlePostActivity = async () => {
		// const { title, startDate, endDate, reward, cap, area } = currentInfo

		// // check if required input is empty
		// setCurrentInfo({
		// 	...currentInfo,
		// 	titleError: !title? '請填寫或選擇項目': '',
		// 	startDateError: !startDate? '請填寫或選擇項目': '',
		// 	endDateError: !endDate? '請填寫或選擇項目': '',
		// 	rewardError: reward === 0 ? '請填寫或選擇項目': '',
		// })
		// if (!title || !startDate || !endDate || reward === 0) return setWarning('欄位不能為空！')

		// // check if area is empty
		// if (isEmpty(area)) return setWarning('請設定獎勵範圍')

		// // call api
		// let body = {
		// 	title,
		// 	start_time: startDate,
		// 	end_time: endDate,
		// 	reward,
		// 	cap,
		// 	area
		// }
		// console.log('body', body)
		// const response = await createReward(body)
		// if (response.status !== 200) return setWarning('新增失敗，請稍候再嘗試')
		// setWarning('新增成功！')
		// toggleAddMode()
	}

	const handleDeleteScheduler = async (id) => {
		const response = await deleteScheduler({ ids: id })
		if (response.status !== 200) return Alert.error(response.data.message)
		fetchSchedulerList()
		Alert.success('刪除成功')
	}

  const handleNotificationChange = (id, type, value) => {
    const newData = [...currentInfo.notification_slack].map(data => {
      if (data.id !== id) return data
      data[type] = value
      return data
    })
    setCurrentInfo({
      ...currentInfo,
      notification_slack: newData
    })
  }

  const handleNotifyAction = (action, id, newData) => {
		switch (action) {
			case 'add':
				setCurrentInfo({
					...currentInfo,
					notification_slack: [...currentInfo.notification_slack, newData]
				})
				break;
			case 'delete':
				setCurrentInfo({
					...currentInfo,
					notification_slack: isString(id) ? currentInfo.notification_slack.filter(data => data.id !== id) : currentInfo.notification_slack.map(data => {
						if (data.id !== id) return data
						data.toDelete = true
						return data
					})
				})
				break;
			default:
				break;
		}
	}

	const handleEditOrCopy = (data, action) => {
		const { id, name, scheduler_function, description, schedule_type, start_time, end_time, active, time_setting, notification_slack } = data
		// if (action === 'copy') Alert.success('已複製訊息', 3000)

		setCurrentInfo({
			id,
			name,
			active,
			function: scheduler_function,
			description,
			type: schedule_type,
			nameError: '',
			descError: '',
			time_setting,
			notification_slack,
		})
	}

	const handleWarningConfirm = () => {
		setWarning('')
		setList([])
		fetchSchedulerList()
	}

return (
	<>
		<div style={{ width: '100%'}}>
		{warning && <PopWarning warning={warning} textCenter={true} confirm={handleWarningConfirm} />}
		{isAddMode ? (
			<AddListModel>
        <BreadcrumbNav start="Scheduler" entry="Review Scheduler" onClick={toggleAddMode} />
				<AddListGrop title={'Bee 基本資訊'}>
					{currentInfo.id  && <FormGrop title={'ID'} type='children'><Input value={currentInfo.id} disabled /></FormGrop>}
					<FormGrop title={'名稱*'} placeholder={'Title'} type={'input'} value={currentInfo.name} handleOnChange={handleOnChange} setStateKey={'name'} errorMessage={currentInfo.nameError} />
					<FormGrop title={'Job'} type='children'><Input value={find(schedulerFunctions, { id: currentInfo.function }).name} disabled /></FormGrop>
          <FormGrop title={'描述*'} placeholder={'Description'} type={'input'} value={currentInfo.description} handleOnChange={handleOnChange} setStateKey={'description'} errorMessage={currentInfo.descError} />
          <FormGrop title={'Schedule 類型'} type={'children'}>
            <DropdownGroup
              style={{ pointerEvents: 'none' }}
              activeKey={currentInfo.type} options={scheduleType} onSelect={(eventKey) => {
                if (eventKey === currentInfo.type) return
                setCurrentInfo({
                  ...currentInfo,
                  type: eventKey
                })
              }}
            />
					</FormGrop>
          <FormGrop title={'設置 Slack 通知'} type={'children'}>
            {currentInfo.notification_slack.map(({ id, job, notify_when, channel, msg, toDelete }) => {
              if (toDelete) return null
							const disable = !isString(id)
              return (
                <div className="notification-setting-wrap" key={id}>
                  <p>當</p>
                  <DropdownGroup
										style={{ pointerEvents: `${disable ? 'none' : 'auto'}` }}
										activeKey={notify_when}
										options={statusType}
										onSelect={(v) => handleNotificationChange(id, 'notify_when', v)}
									/>
                  <p>通知</p>
									{schedulerChannels && (
										<DropdownGroup
											style={{ pointerEvents: `${disable ? 'none' : 'auto'}` }}
											activeKey={channel}
											options={schedulerChannels}
											onSelect={(v) => handleNotificationChange(id, 'channel', v)}
										/>
									)}
                  {!disable && <Icon icon='trash' size="lg" onClick={() => handleNotifyAction('delete', id)} />}
                </div>
              )
            })}
            <button className="btn btn-add-notification" onClick={() => handleNotifyAction('add', null, { 'id': generateCode(4), job: '', notify_when: '', channel: '' })}>+ 新增通知</button>
					</FormGrop>
				</AddListGrop>
        <FormBottomButtons
          confirmText={currentInfo.id ? '更新' : '設置'}
          cancelText="取消"
          // confirm={handlePostActivity}
          cancel={toggleAddMode}
        />
			</AddListModel>
		) : (
			<>
				<ListWrap status={'Bee on time 🐝'} />
				<TableController data={list} topHeight={104}>
					<Column flexGrow={1} align="center">
						<HeaderCell>Active</HeaderCell>
						<ToggleCell dataKey="active" />
					</Column>
					<Column flexGrow={.5} align="center">
						<HeaderCell>ID</HeaderCell>
						<Cell dataKey="id" />
					</Column>
					<Column flexGrow={2.5} align="center">
						<HeaderCell>Bee</HeaderCell>
						<TitleCell dataKey="name"/>
					</Column>
					<Column flexGrow={2.5} align="center">
						<HeaderCell>Job</HeaderCell>
						<JobCell dataKey="scheduler_function" jobs={schedulerFunctions} />
					</Column>
					<Column flexGrow={1.5} align="center">
						<HeaderCell>Schedule</HeaderCell>
						<TypeCell />
					</Column>
					<Column flexGrow={1.5} align="center">
						<HeaderCell>Last Run</HeaderCell>
						<DateCell dataKey="last_run" format="YYYY-MM-DD HH:mm" />
					</Column>
					<Column flexGrow={1.5} align="center">
						<HeaderCell>Next Run</HeaderCell>
						<DateCell dataKey="next_run" format="YYYY-MM-DD HH:mm" />
					</Column>
					<Column flexGrow={3} align="center">
						<HeaderCell>Recent Records</HeaderCell>
						<RecordCell />
					</Column>
					<Column flexGrow={2} align="center">
						<HeaderCell>Action</HeaderCell>
						<ActionCell
							dataKey="id"
							toggle={toggleAddMode}
							handleEdit={handleEditOrCopy}
							// handleDelete={handleDeleteScheduler}
							noCopy
						/>
					</Column>
				</TableController>
			</>
			)}
		</div>
	</>
	)
}

export default Scheduler
