import React, { useState, useEffect } from 'react'
import { Tag } from 'rsuite'

const EventStatusTag = ({ start, end }) => {
  const [option, setOption] = useState({})
  const getOption = () => {
    const now = Math.floor(Date.now() / 1000)
    switch (true) {
      case start > now:
        return { text: '待開始', color: '#0a5dc2' }
      case end < now:
        return{ text: '已結束', color: '#c5c6c7' }
      default:
        return { text: '已開始', color: '#4caf50' }
    }
  }

  useEffect(() => {
    setOption(getOption())
  }, [])

  return (
    <Tag style={{ backgroundColor: option.color, color: '#fff' }}>
      {option.text}
    </Tag>
  )
}

export default EventStatusTag
