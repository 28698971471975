import React from 'react'
import { Panel, Icon } from 'rsuite'
import moment from 'moment-timezone'
import { TextRow, Label, Text } from './styles'
import { ReviewLabel, DocumentLabel, DateSpan, CheckIcon, SuspendBlackLabel } from './index'

const statusMap = {
  SILVER: '白銀會員',
  GOLD: '黃金會員',
  DIAMOND: '鑽石會員',
}

const Membership = React.memo(({ info, rentalSuspendBlack }) => {
  return (
    <Panel bordered header="會員狀態">
      <TextRow>
        <Label>T Reward 會員</Label>
        <Text>{statusMap[info.status] || '-'}</Text>
      </TextRow>
      <TextRow>
        <Label>T Reward 加入</Label>
        <Text>
          <DateSpan unixtime={info.created_at} />
        </Text>
      </TextRow>
      <TextRow>
        <Label>格上會員 Acct Id</Label>
        <Text>{info.rental_id}</Text>
      </TextRow>
      <TextRow>
        <Label>裕隆會員 SSO Id</Label>
        <Text>{info.yulon_sso_id}</Text>
      </TextRow>
      <TextRow>
        <Label>由 LG 發動註冊裕隆會員</Label>
        <Text>
          <CheckIcon checked={!!info.yulon_is_bound} />
        </Text>
      </TextRow>
      <TextRow>
        <Label>證件審核狀態</Label>
        <Text>
          <ReviewLabel status={info.rental_documents_review?.verifyStatus} />
        </Text>
      </TextRow>
      <TextRow>
        <Label>身分證(正面)</Label>
        <Text>
          <DocumentLabel status={info.rental_documents_review?.idCardFrontStatus} reason={info.rental_documents_review?.idCardFrontReason} />
        </Text>
      </TextRow>
      <TextRow>
        <Label>身分證(反面)</Label>
        <Text>
          <DocumentLabel status={info.rental_documents_review?.idCardBackStatus} reason={info.rental_documents_review?.idCardBackReason} />
        </Text>
      </TextRow>
      <TextRow>
        <Label>汽車駕照</Label>
        <Text>
          <DocumentLabel
            status={info.rental_documents_review?.driverLicenceFrontStatus}
            reason={info.rental_documents_review?.driverLicenceFrontReason}
          />
        </Text>
      </TextRow>
      {!!info.rental_documents_review?.selfieId && (
        <TextRow>
          <Label>自拍照</Label>
          <Text>
            <DocumentLabel status={info.rental_documents_review?.selfieStatus} />
          </Text>
        </TextRow>
      )}
      {!!info.rental_documents_review?.liveVideoId && (
        <TextRow>
          <Label>自拍影片</Label>
          <Text>
            <DocumentLabel status={info.rental_documents_review?.liveVideoStatus} />
          </Text>
        </TextRow>
      )}

      <TextRow>
        <Label>會員狀態</Label>
        <Text>
          {!!rentalSuspendBlack?.s2gSuspended && <SuspendBlackLabel title="違規停權" />}{' '}
          {!!rentalSuspendBlack?.inBlackList && <SuspendBlackLabel title="黑名單" />}
        </Text>
      </TextRow>

      <TextRow>
        <Label>黑名單原因備註</Label>
        <Text>{rentalSuspendBlack?.blackReason || ''}</Text>
      </TextRow>

      <TextRow>
        <Label>違規停權原因備註</Label>
        <Text>{rentalSuspendBlack?.suspendedReason || ''}</Text>
      </TextRow>

      <TextRow>
        <Label>違規停權開始時間</Label>
        <Text>{!!rentalSuspendBlack?.s2gSuspended && moment((rentalSuspendBlack?.suspendStartTime || 0) * 1000).format('YYYY-MM-DD HH:mm')}</Text>
      </TextRow>

      <TextRow>
        <Label>違規停權結束時間</Label>
        <Text>{!!rentalSuspendBlack?.s2gSuspended && moment((rentalSuspendBlack?.suspendEndTime || 0) * 1000).format('YYYY-MM-DD HH:mm')}</Text>
      </TextRow>
    </Panel>
  )
})

export default Membership
