import React from 'react'
import { Modal, Button } from 'rsuite'
import { TextWrapper } from '../../pages/drivers_identifiers/styles'
import ACTION from '../../constants/identifiers_actions'

const IdentifiersConfirmModal = React.memo(({ show, close, confirm, driver, receiver, items }) => {
  return (
    <Modal backdrop={true} show={show} onHide={close}>
      <Modal.Header>
        <Modal.Title>確認識別物資訊</Modal.Title>
      </Modal.Header>
      {driver && (
        <Modal.Body>
          <TextWrapper>
            <div>
              {driver.name} {driver.uid}
            </div>
            {receiver && receiver.uid !== driver.uid && (
              <div>
                ({receiver.name} {receiver.uid} 代領)
              </div>
            )}
            <div></div>
            <div>識別物:</div>
            {items.map((v, i) => (
              <div key={i}>
                {v.name}*{v.amount} {ACTION[v.action]}
              </div>
            ))}
          </TextWrapper>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button onClick={confirm} appearance="primary">
          確定送出
        </Button>
        <Button onClick={close} appearance="subtle">
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  )
})

export default IdentifiersConfirmModal
