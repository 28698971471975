import React from 'react'
import { Tag } from 'rsuite'

const getTagInfo = (type) => {
  switch (type) {
    case 'OPERATION':
      return {
        name: '重複領用',
        color: 'orange',
      }
    case 'FIRST_TIME_USER':
      return {
        name: '首次租車',
        color: 'blue',
      }
    case 'ONE_TIME_EVENT':
      return {
        name: '單次領用',
        color: 'green',
      }
    case 'SERIAL_NUMBER':
      return {
        name: '亂碼序號',
        color: 'red',
      }
    default:
      return {
        name: type,
        color: null,
      }
  }
}

export const TagItem = ({ type }) => {
  const tagInfo = getTagInfo(type)
  return <Tag color={tagInfo.color}>{tagInfo.name}</Tag>
}

export default React.memo(TagItem)
