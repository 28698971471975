const handleTransactionStatus = (status) => {
  switch (status) {
    case 'PENDING':
      return {
        color: 'blue',
        text: '空白',
      }
    case 'INPROGRESS':
      return {
        color: 'green',
        text: '付款完成',
      }
    case 'COMPLETED':
      return {
        color: 'green',
        text: '已撥款',
      }
    case 'REFUND':
      return {
        color: 'blue',
        text: '已退款',
      }
    case 'FAILED':
      return {
        color: 'red',
        text: '付款失敗',
      }
    case 'CANCELLED':
      return {
        color: 'blue',
        text: '付款取消',
      }
    case 'ARREARS':
      return {
        color: 'red',
        text: '小黃呆帳',
      }
    case 'COLLECTABLE':
      return {
        color: 'yellow',
        text: '平台代墊',
      }
    default:
      return {
        color: 'orange',
        text: '',
      }
  }
}

const handleAdjustmentStatus = (status) => {
  switch (status) {
    case 'APPROVED':
      return {
        color: 'green',
        text: '申請通過',
      }
    case 'CANCELLED':
      return {
        color: null,
        text: '申請取消',
      }
    case 'REJECTED':
      return {
        color: 'red',
        text: '申請失敗',
      }
    case 'PENDING':
      return {
        color: 'yellow',
        text: '等待審核中',
      }
    default:
      return {
        color: 'orange',
        text: '',
      }
  }
}

export { handleTransactionStatus, handleAdjustmentStatus }
