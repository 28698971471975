import React, {useState, useEffect} from 'react'
import {isEmpty} from 'lodash'
import {Modal, Button, Input, Alert} from 'rsuite'
import {releaseReservationTrip} from '../../api'
import dayjs from 'dayjs'

const ReleaseReservationModal = ({tripData, show, handleClose, fetchReservationTrips, loading, setLoading}) => {
    const [message, setMessage] = useState('')

    useEffect(() => {
        setMessage(`親愛的乘客您好：您預約 ${dayjs(tripData.reserve_time * 1000).format('YYYY-MM-DD HH:mm')} 的行程，因原訂司機無法承接，已將您的預約行程釋出等候其他司機接單，謝謝您的諒解，祝您行程順利。`)
    }, [tripData])


    const handleReleaseReservationTrip = async () => {
        if (isEmpty(message)) return Alert.warning('請確實填寫')
        setLoading(true)

        // call api
        const body = {
            to_user: message
        }
        const response = await releaseReservationTrip(tripData.trip_id, body)
        setLoading(false)
        if (response.status !== 200) return Alert.error(response.data.msg)
        fetchReservationTrips()
        return handleClose()
    }

    return (
        <Modal backdrop="static" size="xs" show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title style={{textAlign: 'center'}}>釋放行程訊息 trip{tripData.trip_id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <textarea
                    style={{height: 200}}
                    type="text"
                    placeholder="傳給乘客 釋放行程 訊息"
                    value={message}
                    onChange={(value) => setMessage(value)}/>
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'center'}}>
                <Button appearance="subtle" onClick={() => handleClose()} disabled={loading}>
                    取消
                </Button>
                <Button appearance="primary" onClick={() => handleReleaseReservationTrip()} disabled={loading}>
                    送出
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ReleaseReservationModal
