import React from 'react'
import { Nav } from 'rsuite'

// onChange
const TypeNav = ({ activeKey, handleTypeOnSelect }) => {
  const handleOnSelect = (recentKey) => {
    if (recentKey === activeKey) return
    handleTypeOnSelect(recentKey)
  }

  return (
    <Nav appearance="tabs" activeKey={activeKey} onSelect={handleOnSelect} >
      <Nav.Item eventKey="news">群發</Nav.Item>
      <Nav.Item eventKey="notifications">特定通知</Nav.Item>
      <Nav.Item eventKey="inbox">個人信件</Nav.Item>
    </Nav>
  )
}

export default React.memo(TypeNav)