import React from 'react'
import ExcelJs from 'exceljs'
import { Button } from 'rsuite'

export const ExportCsvButton = ({ buttonText, fileName, sheetData }) => {
  const handleExportData = () => {
    const workbook = new ExcelJs.Workbook()
    sheetData.forEach((sheetData) => {
      const sheet = workbook.addWorksheet(sheetData.sheetName)
      sheet.addTable({
        name: sheetData.sheetName,
        ref: `A1`, // 從 A1 格開始
        headerRow: true,
        columns: sheetData.thead.map((s) => {
          return { name: s }
        }),
        rows: sheetData.tbody,
      })
      if (sheetData.columnWidths) {
        sheetData.columnWidths.forEach((column) => {
          sheet.getColumn(column.number).width = column.width
        })
      }
    })

    // 表格裡面的資料都填寫完成之後，訂出下載的callback function
    // 異步的等待他處理完之後，創建url與連結，觸發下載
    workbook.csv.writeBuffer().then((buffer) => {
      const link = document.createElement('a')
      const blob = new Blob([buffer], {
        type: 'text/plain;charset=utf-8',
      })
      link.download = `${fileName}.csv`
      link.href = URL.createObjectURL(blob)
      link.click()
    })
  }

  return (
    <Button appearance="ghost" size="xs" style={{ marginLeft: 5 }} onClick={handleExportData}>
      {buttonText}
    </Button>
  )
}

export default ExportCsvButton
