import { transLocation } from '../../../utils/map'

const getSeparateLocation = (areas) => {
  let startLocation = {}
  let endLocation = {}
  areas.forEach(({ id, start_location, end_location }) => {
    if (start_location) startLocation[id] = transLocation(start_location[0])
    if (end_location) endLocation[id] = transLocation(end_location[0])
  })
  return { startLocation, endLocation }
}

export { getSeparateLocation }