import React from 'react'
import styled from 'styled-components'
import { IconButton, Icon, Divider } from 'rsuite'
import { Cell } from 'rsuite-table'

const ActionCellWrap = styled(Cell)`
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

// TODO: reuse to src/components/table/cell/CommonActionCell.js
const ActionCell = ({ rowData, toggle, handleEdit, handleCopy, handleDelete, view, noCopy, ...props }) => {
  const handleAction = (action) => {
    switch (action) {
      case 'copy':
        handleCopy ? handleCopy(rowData) : handleEdit(rowData, 'copy')
        toggle && toggle()
        break
      case 'edit':
        handleEdit(rowData)
        toggle && toggle()
        break
      case 'delete':
        handleDelete(rowData.id || rowData, rowData)
        break
      default:
        break
    }
  }

  return (
    <ActionCellWrap {...props}>
      {handleEdit && (
        <>
          <IconButton appearance="subtle" onClick={() => handleAction('edit')} icon={<Icon icon={view ? 'eye' : 'edit2'} />} />
          <Divider vertical />
        </>
      )}
      {!noCopy && (
        <>
          <IconButton appearance="subtle" onClick={() => handleAction('copy')} icon={<Icon icon="copy-o" />} />
          <Divider vertical />
        </>
      )}
      {handleDelete && <IconButton appearance="subtle" onClick={() => handleAction('delete')} icon={<Icon icon="trash2" />} />}
    </ActionCellWrap>
  )
}

export default React.memo(ActionCell)
