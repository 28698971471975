import React, { useCallback } from 'react'
import dayjs from 'dayjs'
import { isEmpty, forEach } from 'lodash'
import { Popover, Whisper } from 'rsuite'
import { Cell } from 'rsuite-table'

const WEEK_DAYS = {
  '0': '一',
  '1': '二',
  '2': '三',
  '3': '四',
  '4': '五',
  '5': '六',
  '6': '日',
}

const TimeCell = ({ rowData, ...props }) => {
  let periods = []
  let recentInterval = null
  let weekDays = null
  const { designatedDailyTimeframe, designatedWeeklyTimeframe } = rowData
  if (!isEmpty(designatedDailyTimeframe)) {
    periods = designatedDailyTimeframe
  }
  if (!isEmpty(designatedWeeklyTimeframe)) {
    const { interval, hours_setting, day_of_week } = designatedWeeklyTimeframe[designatedWeeklyTimeframe.length -1]
    periods = hours_setting
    recentInterval = interval
    weekDays = {...day_of_week}
    forEach(weekDays, (value, key) => {
      (value === "0" || value === 0) && delete weekDays[key]
    })
  }

  const renderContent = useCallback(() => {
    return (
      <>
        <p>{`以 ${rowData.enable_date? dayjs(rowData.enable_date * 1000).format('YYYY-MM-DD') : '活動使用時間'} 作為起始`}{(recentInterval && recentInterval !== 1) && <span>{`，設定每隔 ${recentInterval} 週`}</span>}</p>
        <p>
          {weekDays && Object.keys(weekDays).map((day, index) => {
            return <span key={index}>{`${index > 0 ? '、': ''}每週${WEEK_DAYS[day]}`}</span>
          })}
        </p>
        {periods.map(({ time_of_day_start, time_of_day_end }, index) => {
          return (
            <p key={index}>{`${time_of_day_start.substring(0, 5)} ~ ${time_of_day_end.substring(0, 5)}`}</p>
          )
        })}
      </>
    )
  }, [rowData])

	const speaker = !isEmpty(periods) ? <Popover title={`使用時段條件限制`}>{renderContent()}</Popover> : <div />


	return (
		<Cell {...props}>
      <Whisper placement="right" speaker={speaker}>
        {!isEmpty(periods) ? (<a className="time-cell-wrapper">{renderContent()}</a>): <div />}
      </Whisper>
		</Cell>
	)
}

export default React.memo(TimeCell)