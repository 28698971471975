import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { RadioGroup, Radio, InputGroup, Input, Icon, Alert, Button, ButtonToolbar, List, FlexboxGrid } from 'rsuite'
import { Heading, Wrapper } from '../driver_mileage/styles'
import { getDriversIdentifiers, getDriversIdentifiersInfo, postDriversIdentifiers } from '../../api'
import StorePicker from '../../components/drivers/StorePicker'
import IdentifiersPicker from '../../components/drivers/IdentifiersPicker'
import IdentifiersListItem from '../../components/drivers/IdentifiersListItem'
import IdentifiersConfirmModal from '../../components/drivers/IdentifiersConfirmModal'
import IdentifiersStockTable from '../../components/drivers/IdentifiersStockTable'
import { LabelWrapper, Label, Subtitle } from './styles'
import ACTION from '../../constants/identifiers_actions'

const CreateDriversIdentifiers = () => {
  const { driver_id } = useParams()
  const [store, setStore] = useState()
  const [isSelf, setIsSelf] = useState(true)
  const [receiver, setReceiver] = useState()
  const [driver, setDriver] = useState()
  const [searchDriverId, setSearchDriverID] = useState(driver_id)
  const [selectedItems, setSelectedItems] = useState([])
  const [amountMap, setAmountMap] = useState(new Map())
  const [popup, setPopup] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const getDriver = async () => {
      try {
        const { data } = await getDriversIdentifiersInfo(driver_id)
        const { data: { data: { driver } } = {} } = await getDriversIdentifiers(driver_id)
        setDriver({
          ...driver,
          items: data,
        })
        setReceiver(driver)
        setAmountMap(
          data.reduce((accu, curr) => {
            accu.set(curr.id, curr.amount)
            return accu
          }, new Map()),
        )
      } catch (error) {
        Alert.error(error?.message || 'fetch error')
      }
    }
    getDriver()
  }, [])

  const handleReceiverTypeChange = (self) => {
    setIsSelf(self)
    if (!self) {
      setSearchDriverID('')
      return
    }
    setSearchDriverID(driver_id)
    setReceiver(driver)
  }

  const handleReceiverIdChange = (value) => {
    setSearchDriverID(value)
  }

  const enableCheckReceiver = searchDriverId && !isSelf
  const handleCheckReceiver = async () => {
    if (!enableCheckReceiver) return
    const { data: { data: { driver } = {}, message } = {} } = await getDriversIdentifiers(searchDriverId)
    if (message) {
      Alert.warning(message)
      setReceiver(null)
      return
    }
    setReceiver(driver)
  }

  const removeItem = (id) => {
    setSelectedItems(selectedItems.filter((v) => v.id !== Number(id)))
  }

  const setItem = (item) => {
    const newValues = []
    selectedItems.forEach((v) => {
      newValues.push(v.id === item.id ? item : v)
    })

    setSelectedItems(newValues)
  }

  const isValidPayload = () => {
    return selectedItems.every((v) => v.action && v.amount)
  }

  const confirm = async () => {
    if (!store) {
      Alert.warning('請選擇兌換地點')
      return
    }

    setPopup(true)
  }

  const submit = async () => {
    const logs = selectedItems.map((v) => ({
      driver_id,
      exchange_store_id: store,
      agent_driver_id: receiver.uid === Number(driver_id) ? null : receiver.uid,
      item_id: v.id,
      amount: v.amount,
      action: v.action,
    }))

    const data = await postDriversIdentifiers(driver_id, { logs })
    setPopup(false)
    if (data?.status !== 200) {
      Alert.error('發生錯誤')
      return
    }
    try {
      await navigator.clipboard.writeText(confirmText())
      Alert.success('領取成功, 文字訊息已複製')
    } catch (error) {
      Alert.success('領取成功')
      console.error(error)
    }
    history.push(`/drivers_identifiers/${driver_id}`)
  }

  const confirmText = () => {
    if (!driver) return ''

    return `確認識別物資訊\n
      ${driver.name} ${driver.uid}
      ${receiver && receiver.uid !== driver.uid ? `(${receiver.name} ${receiver.uid} 代領)\n` : ''}
      識別物:
      ${selectedItems.map((v) => `${v.name}*${v.amount} ${ACTION[v.action]}`).join('\n')}
    `
      .split('\n')
      .map((v) => v.trim())
      .join('\n')
  }

  return (
    <Wrapper>
      <Heading>司機識別物</Heading>
      <Subtitle>兌換地點</Subtitle>
      <StorePicker selectedStore={store} setSelectedStore={setStore} />
      <Subtitle>兌換人</Subtitle>
      <RadioGroup defaultValue={true} inline value={isSelf} onChange={handleReceiverTypeChange}>
        <Radio value={true}>本人領取</Radio>
        <Radio value={false}>代理人</Radio>
      </RadioGroup>
      <div style={{ display: 'flex' }}>
        <InputGroup style={{ width: 220 }}>
          <Input
            value={searchDriverId}
            onPressEnter={handleCheckReceiver}
            placeholder="輸入代理人司機ID"
            onChange={handleReceiverIdChange}
            disabled={isSelf}
          />
          <InputGroup.Button onClick={handleCheckReceiver} disabled={!enableCheckReceiver}>
            <Icon icon="search" />
          </InputGroup.Button>
        </InputGroup>
        {receiver && (
          <LabelWrapper style={{ marginLeft: '20px' }}>
            <Label>
              {receiver.name} {receiver.label}
            </Label>
          </LabelWrapper>
        )}
      </div>
      {driver && (
        <FlexboxGrid align="top" style={{ columnGap: '30px', minWidth: '800px', flexWrap: 'nowrap' }}>
          <FlexboxGrid.Item colspan={7} style={{ minWidth: '360px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Subtitle>識別物領取情況</Subtitle>
            <IdentifiersStockTable data={driver.items} />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Subtitle>領取識別物</Subtitle>
            <IdentifiersPicker width={400} selectedItems={selectedItems} setSelectedItems={setSelectedItems} />
            {!!selectedItems.length && (
              <List bordered style={{ minWidth: '600px', minHeight: '200px' }} size="sm">
                {selectedItems.map((v) => (
                  <IdentifiersListItem key={v.id} item={v} setItem={setItem} remove={removeItem} amountMap={amountMap} />
                ))}
              </List>
            )}
          </FlexboxGrid.Item>
        </FlexboxGrid>
      )}
      <ButtonToolbar style={{ marginTop: '10px', marginBottom: '30px' }}>
        <Button block appearance="primary" onClick={confirm} disabled={!selectedItems.length || !isValidPayload()}>
          確認
        </Button>
      </ButtonToolbar>
      <IdentifiersConfirmModal
        show={popup}
        close={setPopup.bind(this, false)}
        confirm={submit}
        driver={driver}
        receiver={receiver}
        items={selectedItems}
      />
    </Wrapper>
  )
}

export default CreateDriversIdentifiers
