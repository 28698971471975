import React from 'react'

export const ListWrap = React.memo(({ status, children, addListText, onClick, opacity }) => {
	const styles = {
		justifyContent: 'space-between',
		opacity
	}
	return (
		<div >
			<div className="list-status-wrap">
				<h4 className="list-status">{status}</h4>
				<div className="add-list-wrap" onClick={onClick}>
					<button className="add-list">{addListText}</button>
				</div>
			</div>
			{/* add space-between style */}
			<div className="list-top" style={styles}>
				{children}
			</div>
		</div>
	)
})
