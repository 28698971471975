import React from 'react'
import { ErrorMessage } from 'rsuite'
import { ListWrapper, ListTitleWrapper, ListTitle } from './styles'

const FormInput = React.memo(({ title, $require, error, children }) => (
  <ListWrapper>
    <ListTitleWrapper className="rs-form-control-wrapper">
      <ListTitle $require={$require}>
        <span>{title}</span>
        <ErrorMessage className="error-message" show={error?.hasError} placement="rightStart">
          {error?.errorMessage}
        </ErrorMessage>
      </ListTitle>
    </ListTitleWrapper>
    {children}
  </ListWrapper>
))

export default FormInput
