import React, { Component } from 'react'
import { IconButton, Icon, Divider, Alert } from 'rsuite'
import { Cell } from 'rsuite-table'
import { deleteTable } from '../../../api'

export class ActionCell extends Component {
	handleAction = (action) => {
		const { rowData, toogleModle, handleUpdate } = this.props
		handleUpdate(rowData, action)
		toogleModle()
	}

	handleDeleteAction = () => {
		const { handlePopWarning } = this.props
		handlePopWarning({
			text: '確定刪除活動？',
			confirm: () => this.handleDeleteTable(),
			isCancel: true,
			cancel: () => handlePopWarning('')
		})
	}
	handleDeleteTable = async () => {
		const { rowData, dataKey, fetchData, table, handlePopWarning } = this.props
		const id = rowData[dataKey]
		const condition_response = await deleteTable('marketing_events_advance_condition', rowData.function_id)
		if (condition_response.status !== 200) return Alert.error('刪除失敗，請稍後再試', 3000)
		deleteTable(table, id).then((data) => {
			if (data.status !== 200) return
			this.props.handleupdatedata(20, 0, [])
			fetchData()
			handlePopWarning('')
		})
	}
	render() {
		const { rowData, dataKey, children, fetchData, toogleModle, handleUpdate, handleupdatedata, handlePopWarning, ...props } = this.props
		return (
			<Cell {...props} className="link-group" style={{ display: 'flex', alignItems: 'center' }}>
				<IconButton
					appearance="subtle"
					onClick={this.handleAction}
					icon={<Icon icon="edit2" />}
				/>
				<Divider vertical />
				<IconButton
					appearance="subtle"
					onClick={() => this.handleAction('copy')}
					icon={<Icon icon="copy-o" />}
				/>
				<Divider vertical />
				<IconButton
					appearance="subtle"
					onClick={this.handleDeleteAction}
					handleupdatedata={'handleupdatedata'}
					icon={<Icon icon="trash2" />}
				/>
			</Cell>
		)
	}
}

export default ActionCell
