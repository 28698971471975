import React, { useState } from 'react'
import { ContextStore } from '../../reducers'
import { ACTION } from '../../constants/actions'
import Input from '../../components/views/Input'
import { postLogin, getUser } from '../../api'
import { FlexboxGrid, Panel } from 'rsuite'


function Login() {
	const { auth, authDispatch } = React.useContext(ContextStore)
	const [state, setState] = useState({
		account: '',
		password: ''
	})

	function handleInput(e) {
		const value = e.target.value
		const name = e.target.name
		setState({
			...state,
			[name]: value
		})
	}

	function handleLogin(e) {
		e.preventDefault()
		const { account, password } = state
		if (!account || !password) return
		const data = {
			account,
			password
		}
		postLogin(data).then((login) => {
			if (login.status === 200) {
				getUser().then((data) => {
					const user = data.data.error ? null : data.data
					authDispatch({ type: ACTION.SET_USER, user })
					authDispatch({ type: ACTION.SET_CHECK_LOGIN_STATUS, logingStatus: user ? 'SUCCESS' : 'FAILURE' })
				})
			} else {
				authDispatch({ type: ACTION.SET_CHECK_LOGIN_STATUS, logingStatus: 'FAILURE' })
			}
		})
	}

	return (
		<FlexboxGrid justify="center" className="login-wrap">
			<Panel bordered style={{ width: '450px' }}>
				<div className="login-form">
					<div className="login-account title subtitle_01">帳號</div>
					<Input name="account" type="account" placeholder="account" value={state.account} handleInput={handleInput} handleSubmit={handleLogin} />
					<div className="login-password title subtitle_01">密碼</div>
					<Input name="password" type="password" placeholder="password" value={state.password} handleInput={handleInput} handleSubmit={handleLogin} />
					{auth.logingStatus === 'FAILURE' && <div className="login-error subtitle_01">登入失敗</div>}
					<button className="login-button" onClick={handleLogin}>登入</button>
				</div>
			</Panel>
		</FlexboxGrid>
	)
}

export default Login;
