import React from 'react'
import { Icon, Popover, Whisper } from 'rsuite'

const CouponsGuide = React.memo(() => {
	const speaker = (
		<Popover title='優惠券設定說明' style={{ width: '30vw' }}>
			<ol>
				<li>1. 優惠券分為「重複領用、首次乘車、單次領用、亂碼序號」四個類別</li>
				<li>2. 右側可進行「修改、複製、刪除」操作</li>
				<li>3. <b>亂碼序號</b>不能進行修改，其序號可透過 <b>[批量序號]</b> 按鈕下載 csv 檔</li>
				<li>4. 鼠標移到<b>名稱</b>，會顯示優惠券的使用狀況</li>
				<li>5. 鼠標移到<b>優惠</b>，如果是打折會顯示上限金額</li>
				<li>6. <b>Feature</b> 會顯示優惠券適用的 feature_map，目前有小黃和多元車選項</li>
			</ol>
		</Popover>
	)
	return (
		<div style={{ marginLeft: '4px' }}>
			<Whisper placement="right" speaker={speaker}>
				<Icon icon={'question-circle'}/>
			</Whisper>
		</div>
	)
})

const CouponListWrap = React.memo(({ status, children, addListText, onClick }) => {
	return (
		<div >
			<div className="list-status-wrap">
				<h4 className="list-status" style={{ display: 'flex', alignItems: 'center' }}>{status}<CouponsGuide /></h4>
				<div className="add-list-wrap" onClick={onClick}>
					<button className="add-list">{addListText}</button>
				</div>
			</div>
			<div className="list-top" style={{ justifyContent: 'space-between' }}>
				{children}
			</div>
		</div>
	)
})

export default CouponListWrap