import React, { memo } from 'react'
import { Popover, Whisper } from 'rsuite'
import { Cell } from 'rsuite-table'

const CellWithPopover = ({ children, renderPopoverContent, rowData, ...props }) => {
  return (
    <Cell {...props}>
      <Whisper placement="top" trigger="hover" speaker={<Popover>{renderPopoverContent(rowData)}</Popover>}>
        <div>{typeof children === 'function' ? children(rowData) : children}</div>
      </Whisper>
    </Cell>
  )
}

export default memo(CellWithPopover)
