export const tileLayer = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

 // 選擇座標點、區域
export const controlOptions = {
  point: {
    position: "topleft",
    drawRectangle: false,
    drawPolygon: false,
    editMode: false,
    drawPolyline: false,
    drawCircle: false,
    drawCircleMarker: false,
    cutPolygon: false,
  },
  range: {
    position: 'topleft',
    drawMarker: false,
    drawRectangle: false,
    drawCircle: false,
    drawCircleMarker: false,
    drawPolyline: false,
    cutPolygon: false,
  }
}