import React from 'react'
import dayjs from 'dayjs'
import {
  Popover,
  Whisper,
  Tag,
  Button,
  DatePicker,
  InputGroup,
  Input,
  Icon,
} from 'rsuite'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import TableController from '../table/Table'

const receiptTypeMap = {
  RECEIPT: '收據',
  INVOICE: '發票',
}

const CompanyPaymentTable = ({
  state,
  payments,
  openContent,
  sendEmail,
  handlePicker,
  getPlanDescription,
  updatePaymentRecords,
  rsuiteHandleInputChange,
}) => {
  const speaker = (text) => {
    return (
      <Popover>
        <b>{text}</b>
      </Popover>
    )
  }

  const whisper = (data) => {
    return (
      <Whisper placement="top" speaker={speaker(data)}>
        <div>{data}</div>
      </Whisper>
    )
  }

  const handleTag = (status) => {
    const text = status === 'FINISHED' ? '已付款' : '未付款'
    const color = status === 'FINISHED' ? 'green' : 'red'
    return <Tag color={color}>{text}</Tag>
  }

  const handleSetPaymentButton = (data) => {
    const status = data.payment.status
    const updateStatus = status === 'PENDING' ? 'FINISHED' : 'PENDING'
    const statusWording = status === 'PENDING' ? '設為已付款' : '設為未付款'
    const color = status === 'PENDING' ? 'yellow' : 'red'
    return (
      <Button
        color={color}
        size="sm"
        onClick={() =>
          updatePaymentRecords(
            data.payment.id,
            updateStatus,
            undefined,
            undefined
          )
        }
      >
        {statusWording}
      </Button>
    )
  }

  return (
    <TableController data={payments} topHeight={200} rowHeight={60} rowClassName="company-payment-row" loading={state.loading} autoHeight>
      <Column minWidth={50} flexGrow={1} align="center" fixed>
        <HeaderCell>公司編號</HeaderCell>
        <Cell>
          {(rowData) => {
            const company_info_url =
              '/company/company_info/' + rowData.payment.company
            return <a href={company_info_url}>{rowData.payment.company}</a>
          }}
        </Cell>
      </Column>
      <Column minWidth={150} flexGrow={1} align="center">
        <HeaderCell>公司名稱</HeaderCell>
        <Cell dataKey="name" />
      </Column>
      <Column minWidth={150} flexGrow={1} align="center">
        <HeaderCell>月份</HeaderCell>
        <Cell>
          {(rowData) =>
            dayjs(rowData.payment.month * 1000).format('YYYY-MM-DD')
          }
        </Cell>
      </Column>
      <Column minWidth={80} flexGrow={1} align="center">
        <HeaderCell>報帳類型</HeaderCell>
        <Cell>
          {(rowData) => receiptTypeMap[rowData.payment.receipt_type]}
        </Cell>
      </Column>
      <Column minWidth={120} flexGrow={1} align="center">
        <HeaderCell>發票號碼</HeaderCell>
        <Cell>
          {(rowData) => rowData.payment.InvoiceNumber || '-'}
        </Cell>
      </Column>
      <Column minWidth={50} flexGrow={1} align="center">
        <HeaderCell>金額</HeaderCell>
        <Cell>{(rowData) => rowData.payment.amount}</Cell>
      </Column>
      <Column minWidth={150} flexGrow={1} align="center">
        <HeaderCell>帶入合約</HeaderCell>
        <Cell>
          {(rowData) =>
            whisper(
              (rowData.payment.status === 'FINISHED' ? '' : '預計\n') +
                getPlanDescription(
                  rowData.payment.amount,
                  rowData.payment.agreement
                )
            )
          }
        </Cell>
      </Column>
      <Column minWidth={50} flexGrow={1} align="center">
        <HeaderCell>應繳金額</HeaderCell>
        <Cell>{(rowData) => rowData.payment.final_amount}</Cell>
      </Column>
      <Column minWidth={50} flexGrow={1} align="center">
        <HeaderCell>付款狀態</HeaderCell>
        <Cell>{(rowData) => handleTag(rowData.payment.status)}</Cell>
      </Column>
      <Column minWidth={150} flexGrow={1} align="center">
        <HeaderCell>封鎖日</HeaderCell>
        <Cell>
          {(rowData) =>
            dayjs(rowData.payment.month * 1000)
              .add(rowData.pending_statement_month, 'month')
              .set('date', rowData.pending_statement_day)
              .endOf('day')
              .format('YYYY-MM-DD HH:mm')
          }
        </Cell>
      </Column>
      <Column minWidth={150} flexGrow={1} align="center">
        <HeaderCell>設為</HeaderCell>
        <Cell>{(rowData) => handleSetPaymentButton(rowData)}</Cell>
      </Column>
      <Column minWidth={150} flexGrow={1} align="center">
        <HeaderCell>代收轉付收據/發票</HeaderCell>
        <Cell>
          {(rowData) => (
            <>
              <Button
                appearance="default"
                size="sm"
                onClick={() => openContent(rowData.id, rowData.payment.month, rowData.payment.receipt_type)}
              >
                查看
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                appearance="default"
                size="sm"
                onClick={() => sendEmail(rowData.id, rowData.payment.month, rowData.payment.receipt_type)}
              >
                寄信
              </Button>
            </>
          )}
        </Cell>
      </Column>
      <Column minWidth={200} flexGrow={1} align="center">
        <HeaderCell>設定入帳日</HeaderCell>
        <Cell>
          {(rowData) => (
            <DatePicker
              value={
                state[
                  `payment_day-${rowData.payment.id}`
                ]
              }
              onChange={(date) =>
                handlePicker(
                  `payment_day-${rowData.payment.id}`,
                  date,
                  rowData.payment.id,
                )
              }
            />
          )}
        </Cell>
      </Column>
      <Column minWidth={150} flexGrow={1} align="center">
        <HeaderCell>備註</HeaderCell>
        <Cell>
          {(rowData) => (
            <InputGroup>
              <Input
                placeholder="備註"
                value={
                  state[
                    `note-${rowData.payment.id}`
                  ]
                }
                onChange={(value) =>
                  rsuiteHandleInputChange(
                    `note-${rowData.payment.id}`,
                    value
                  )
                }
              />
              <InputGroup.Button
                onClick={() =>
                  updatePaymentRecords(
                    rowData.payment.id,
                    undefined,
                    state[
                      `note-${rowData.payment.id}`
                    ],
                    undefined
                  )
                }
              >
                <Icon icon="upload2" />
              </InputGroup.Button>
            </InputGroup>
          )}
        </Cell>
      </Column>
      <Column minWidth={150} flexGrow={1} align="center">
        <HeaderCell>最後更新資料時間</HeaderCell>
        <Cell>
          {(rowData) =>
            dayjs(rowData.payment.updated_at).format('YYYY-MM-DD HH:mm')
          }
        </Cell>
      </Column>
    </TableController>
  )
}

export default CompanyPaymentTable
