import React from 'react'
import styled from 'styled-components'
import { Toggle } from 'rsuite'

const ToggleWrap = styled.div`
  display: flex;
  margin: 0 0 10px 10px;
  p {
    margin-left: 5px;
  }
`

const ToggleController = ({ setInProgress, inProgress }) => {
  return (
    <ToggleWrap>
      <Toggle size="md" checked={inProgress} onChange={(checked) => setInProgress(checked)}/>
      <p>顯示目前活動</p>
    </ToggleWrap>
  )
}

export default React.memo(ToggleController)