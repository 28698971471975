import IconFullyEquipped from '../../icons/svg/ic_fully_equipped.svg'

// 目前已經沒有使用到的功能：
// 地圖 /mapbox
// 預約叫車 /trip
// 餐廳外送 /delivery
export const sidebarItem = [
  {
    link: '/',
    img: 'dashboard',
    title: '主頁',
  },
  {
    drawer: {
      name: '廣告部門',
      img: 'squares',
      item: [
        {
          link: '/marketing/scheduled_pin',
          title: '地圖踩點',
        },
        {
          link: '/marketing/advertisement',
          title: '廣告版位設置',
        },
      ],
    },
  },
  {
    drawer: {
      name: '財務部門',
      img: 'order-form',
      item: [
        {
          link: '/finance/statement',
          title: '獲取報表',
        },
        {
          link: '/finance/offset',
          title: '會計沖帳',
        },
        {
          link: '/finance/allocation/ctbc',
          title: '上傳中信撥款',
        },
        {
          link: '/finance/allocation/driver',
          title: '上傳撥款名單',
        },
        {
          link: '/finance/allocation/transactions',
          title: '上傳交易紀錄(LINE Pay/藍新)',
        },
        {
          link: '/finance/driver_insurance',
          title: '車隊服務費',
        },
        {
          link: '/finance/driver_system_fee',
          title: '平台系統設定費',
        },
      ],
    },
  },
  {
    drawer: {
      name: '行銷部門',
      img: 'magic',
      item: [
        {
          link: '/marketing/advertisement',
          title: '廣告',
        },
        {
          link: '/marketing/campaign',
          title: '分眾推播',
        },
        {
          link: '/marketing/coupons',
          title: '優惠券',
        },
        {
          link: '/marketing/rental_coupons',
          title: '租車優惠券',
        },
        {
          link: '/marketing/happyhours',
          title: 'Happy Hours',
        },
        {
          link: '/marketing/user_report',
          title: '上傳用戶名單',
        },
        { link: '/marketing/mgm_generator', title: 'MGM活動產生器' },
        // {
        // {
        //   link: '/marketing/member_center',
        //   title: '會員中心設置',
        // },
      ],
    },
  },
  {
    drawer: {
      name: '營運部門',
      img: 'car',
      item: [
        {
          link: '/news',
          title: '預約發信',
        },
        {
          link: '/dispatch_area',
          title: '空中排班',
        },
        {
          link: '/driver_campaign',
          title: '司機活動後台',
        },
        {
          link: '/driver_mileage',
          title: '司機獎勵積點(點數商品)',
        },
        {
          link: '/driver_mileage_batch',
          title: '司機批次點數發放',
        },
        {
          link: '/drivers_identifiers',
          title: '司機識別物',
        },
        {
          link: '/blocked_history',
          title: '司機帳號接單封鎖',
        },
        {
          link: '/canceled_reservation',
          title: '預約單封鎖',
        },
        {
          link: '/surge_price_multiplier_tests',
          title: '浮動倍率測試',
        }
      ],
    },
  },
  {
    drawer: {
      name: '客服部門',
      img: 'volume-control-phone',
      item: [
        {
          link: '/cs/trip_transaction',
          title: '審核車資',
        },
        {
          link: '/cs/receipt',
          title: '乘車收據',
        },
      ],
    },
  },
  {
    drawer: {
      name: '企業簽單',
      img: 'building',
      item: [
        {
          link: '/company/business_payment',
          title: '會計核對',
        },
        {
          link: '/company/company_list',
          title: '公司列表',
        },
      ],
    },
  },
  {
    drawer: {
      name: 'User',
      img: 'group',
      item: [
        {
          link: '/user_info',
          title: '用戶資訊',
        },
        {
          link: '/drivers/22762',
          title: '司機資訊頁',
        },
        {
          link: '/transactions',
          title: '付款資訊',
        },
      ],
    },
  },
  {
    drawer: {
      name: 'DAMA+',
      img: 'align-left',
      item: [
        {
          link: '/dama/user_group/add',
          title: '新增分眾名單',
        },
        {
          link: '/dama/user_group',
          title: '分眾名單管理',
        },
        {
          link: '/dama/event_management/add',
          title: '新增活動2.0',
        },
        {
          link: '/dama/event_management',
          title: '活動管理',
        },
        {
          link: '/dama/add_event',
          title: '新增活動_BigQuery',
        },
      ],
    },
  },
  {
    link: '/modify_table',
    img: 'table',
    title: '編輯表格',
  },
  {
    link: '/scheduler',
    img: 'check-square-o',
    title: 'Bee on time',
  },
  {
    link: '/equipped',
    img: IconFullyEquipped,
    title: '全標配審核',
  },
  {
    drawer: {
      name: '權限管理',
      img: 'group',
      item: [
        {
          link: '/identities/personnel_management',
          title: '公司成員列表',
        },
      ],
    },
  },
]

export default sidebarItem
