export const TypeData = [{ key: 'line', text: 'line', value: 'line' }]
export const AudienceData = [
  { key: 'TESTER', text: 'TESTER', value: 'TESTER' },
  {
    key: 'ALL_REGISTERED_USER_WITHOUT_CORPORATE_USERS',
    text: 'ALL_REGISTERED_USER_WITHOUT_CORPORATE_USERS',
    value: 'ALL_REGISTERED_USER_WITHOUT_CORPORATE_USERS',
  },
  { key: 'ALL_NONE_REGISTERED_USER', text: 'ALL_NONE_REGISTERED_USER', value: 'ALL_NONE_REGISTERED_USER' },
  { key: 'TREWARDS_ALL', text: 'TREWARDS_ALL', value: 'TREWARDS_ALL' },
]
export const triggerAtData = [
  { key: 'PHONE_VERIFIED', text: 'PHONE_VERIFIED', value: 'PHONE_VERIFIED' },
  { key: 'NEW_USER', text: 'NEW_USER', value: 'NEW_USER' },
  { key: 'TRIP_STARTED', text: 'TRIP_STARTED', value: 'TRIP_STARTED' },
  { key: 'LINEBOT_FOLLOW', text: 'LINEBOT_FOLLOW', value: 'LINEBOT_FOLLOW' },
]

export const adEventType = [
  { key: 'PAGE_LINK', text: '網頁連結', value: 'PAGE_LINK' },
  { key: 'COUPON_REDIRECT', text: 'COUPON導網址', value: 'COUPON_REDIRECT' },
]
