import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import TripController from '../components/trip_controller';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 200,
	},
	content: {
		padding: 20,
	},
	menu: {
		width: 200,
	},
	fab: {
		margin: theme.spacing.unit,
	},
});

class Trip extends React.Component {
	state = {
		trip_list: [
			{
				user_id: 101,
				driver_id: '',
				start_address: '',
				start_latitude: '',
				start_longitude: '',
				end_address: '',
				end_latitude: '',
				end_longitude: '',
				note: 'TaxiGo 活動',
				reservation_time: (new Date()).toISOString().substring(0, 16)
			}
		]
	};

	handleChange(index, name, value) {
		let trip_list = this.state.trip_list
		trip_list[index][name] = value
		console.log(index, name, value, trip_list)
		this.setState({ trip_list: trip_list });
	};

	addNew() {
		let trip_list = this.state.trip_list
		trip_list.push({
			user_id: 101,
			driver_id: '',
			start_address: '',
			start_latitude: '',
			start_longitude: '',
			end_address: '',
			end_latitude: '',
			end_longitude: '',
			note: 'TaxiGo 活動',
			reservation_time: '2017-05-24T10:30'
		})
		this.setState({ trip_list: trip_list });
	};

	duplicate(value) {
		console.log('duplicate', value)
		const clone = Object.assign({}, value);
		let trip_list = this.state.trip_list
		trip_list.push(clone)
		this.setState({ trip_list: trip_list });
	}

	delete(index) {
		let trip_list = this.state.trip_list
		trip_list.splice(index, 1)

		console.log('delete', index, trip_list)
		this.setState({ trip_list: trip_list });
	}

	validate(trip) {
		if (!trip.end_address || !trip.end_latitude || !trip.end_longitude ||
			!trip.start_address || !trip.start_latitude || !trip.start_longitude ||
			!trip.reservation_time) {
			return false
		}
		return true
	}

	request() {
		let validate = true
		this.state.trip_list.map((trip) => {
			if (this.validate(trip) === false) {
				alert(`資訊錯誤 ${JSON.stringify(trip)}`)
				validate = false
			}
			return trip
		})

		if (validate) {
			this.state.trip_list.map((trip) => {
				this.requestRide(trip)
				return trip
			})
		}
		alert('已送出')
	}


	requestRide(trip) {

		let data = trip
		var reserve_date = new Date(trip.reservation_time).getTime() / 1000;
		data.reservation_time = reserve_date

		fetch(`${process.env.REACT_APP_API_URI}/rides`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify(data)
		}).then(async function (res) {
			let data = await res.json()
			console.log(data)
		})
	}

	render() {
		const { classes } = this.props;
		let list = this.state.trip_list.map((trip, index) => {
			console.log(index, trip)
			return (<TripController key={index} index={index} data={trip}
				duplicate={this.duplicate.bind(this)} delete={this.delete.bind(this)}
				handleChange={this.handleChange.bind(this)} />)
		})
		return (
			<div className={classes.content}>
				{list}
				<Fab color="primary" aria-label="Add" className={classes.fab} onClick={this.addNew.bind(this)}>
					<AddIcon />
				</Fab>
				<Fab color="primary" aria-label="Add" className={classes.fab} onClick={this.request.bind(this)}>
					<PlayArrowIcon />
				</Fab>
			</div>
		);
	}
}

Trip.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Trip);
