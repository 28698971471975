import React, { useState, useEffect } from 'react'
import { TagPicker, Icon, Alert } from 'rsuite'
import { getDriverTag, postTag, deleteTag, searchTagName } from '../../api'
import { difference, filter } from 'lodash'

const Picker = ({driverId}) => {
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [cacheData, setCacheData] = useState([])
  const [value, setValue] = useState([])

  // init
  useEffect(() => {
    fetchDriverTag()
  }, [])


  const fetchDriverTag = async () => {
    const response = await getDriverTag(driverId)
    if (response.status === 200) {
      setCacheData(response.data.data.get_tag)
      setValue(response.data.data.get_tag.map(tag => tag.name))
    }
  }

  // add tag
  const handleSelect = async (recentValue, item) => {
    const differ = difference(recentValue, value)[0]
    if (!differ) return
    const body = {
        'uid': driverId,
        'name': differ,
    }
    // check if the tag exists
    const searchResponse = await searchTagName(differ)
    const isExist = searchResponse.data.data.tags.length
    const suggestion = isExist? `添加"${differ}"` : `新建"${differ}"標籤`
    const warning = isExist? '添加失敗，請稍後再試' : '新建標籤失敗，請稍後再試'
    const postResponse = await postTag(body)
    if (postResponse.status !== 200) return Alert.error(warning, 3000)
    Alert.success(suggestion, 3000)
    fetchDriverTag()
    setItems(filter(items, n => recentValue.indexOf(n.name) < 0))
  }

  // delete tag
  const handleChange = async (recentValue) => {
    const differ = difference(value, recentValue)[0]
    if (!differ) return
    const body = {
      'uid': driverId,
      'name': differ,
    }
    const response = await deleteTag({data: body})
    if (response.status !== 200) return Alert.error(`刪除失敗，請稍後再試`, 3000)
    Alert.error(`刪除"${differ}"`, 3000)
    fetchDriverTag()
  }


  const handleCleanItems = () => {
    setItems([])
  }

  const handleSearch = async (keyword) => {
    if (!keyword) return
    setLoading(true)
    const response = await searchTagName(keyword)
    if (response.data.data) {
      setLoading(false)
      setItems(response.data.data.tags)
    }
  }

    return (
      <TagPicker
        creatable
        data={items}
        cacheData={cacheData}
        value={value}
        style={{ width: '60vw', padding: '5px 0' }}
        labelKey={'name'}
        valueKey={'name'}
        onChange={handleChange}
        onSearch={handleSearch}
        onSelect={handleSelect}
        renderMenu={menu => {
          if (loading) {
            return (
              <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                <Icon icon="spinner" spin /> Loading...
              </p>
            );
          }
          return menu
        }}
        onClose={handleCleanItems}
      />
    )
}



export default Picker