import React from 'react'

function FormBottomButtons({ confirmText, cancelText, confirm, cancel }) {
	return (
		<div className="form-bottom-wrap">
      {confirmText && confirm && <button className="confirm-button" onClick={confirm}>{confirmText}</button>}
      <button className="cancel-button" onClick={cancel}>{cancelText}</button>
    </div>
	)
}

export default React.memo(FormBottomButtons)
