import React from 'react'
import { Panel } from 'rsuite'
import { TextRow, Label, Text } from './styles'
import DateSpan from './DateSpan'

const Basic = React.memo(({ info, style }) => {
  return (
    <Panel bordered header="基本資料" style={style}>
      <TextRow>
        <Label>UID</Label>
        <Text>{info.uid}</Text>
      </TextRow>
      <TextRow>
        <Label>電話</Label>
        <Text>{info.phone_number}</Text>
      </TextRow>
      <TextRow>
        <Label>電子信箱</Label>
        <Text>{info.email}</Text>
      </TextRow>
      <TextRow>
        <Label>註冊日期</Label>
        <Text>
          <DateSpan unixtime={info.create_time} />
        </Text>
      </TextRow>
    </Panel>
  )
})

export default Basic
