import styled from 'styled-components'

export const ReceiptWrapper = styled.div`
  table {
    border-collapse: separate;
  }

  & > table > tbody > tr:nth-last-child(-n + 2) {
    display: none;
  }
`

export const Heading = styled.h4`
  color: #7a92ad;
  padding-left: 4px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 30px;
`
