import { filter } from 'lodash'

const nameFilter = (name, activities) => {
  if (!name.length) return activities
  const newActivities = filter(activities, activity => activity.title.includes(name))
  return newActivities
}

const periodFilter = (activities) => {
  const current = new Date().getTime()
  const newActivities = activities.filter((rowData) => {
    if (rowData.end_time && current > rowData.end_time*1000) return false
    if (rowData.start_time && current < rowData.start_time*1000) return false
    return true
  })
  return newActivities
}

export { nameFilter, periodFilter }