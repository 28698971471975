import React from 'react'
import { Panel, Button, Icon } from 'rsuite'
import { isEmpty } from 'lodash'
import { default as Map } from './Map'

const PreviewMap = ({
  mapId,
  point,
  center,
  latlngs,
  title,
  setEditLocation,
  setStateKey,
  errorMessage,
  multi
}) => {

  function getValue() {
    const value = point ? point : latlngs
    return !isEmpty(value) ? JSON.stringify(value, null, 2) : ''
  }

  return (
    <Panel bodyFill bordered style={{ margin: '20px 0'}}>
      <div className="preview-map-container">
      {latlngs ? <Map id={mapId} center={center} latlngs={latlngs} width='50%' padding='12%' multi={multi}/> : <Map id={mapId} point={point} width='50%' padding='12%' />  }
        <div className="preview-wrapper">
          <div className="preview-title">
            {errorMessage && <div className="title-error caption_01"><Icon icon={'warning'} style={{ color: '#f44336' }} /><span>{errorMessage}</span></div>}
            <h4>{title}</h4>
            <Button appearance="primary" onClick={()=> setEditLocation(setStateKey)}>設置</Button>
          </div>
          <textarea
            disabled
            cols="45"
            rows="15"
            value={getValue()} />
        </div>
      </div>
    </Panel>
  )
}


export default React.memo(PreviewMap)
