import { ACTION } from '../constants/actions'

const initialState = {
	marketingCoupons: [],
	carousel: {
		columns: [],
		data: []
	}
}

function marketingReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION.GET_MARKETING_COUPON:
			return {
				...state,
				marketingCoupons: action.marketingCoupons
			}
		case ACTION.GET_ADS_CAROUSEL:
			return {
				...state,
				carousel: action.carousel
			}
		default:
			return state
	}
}

export { initialState, marketingReducer }
