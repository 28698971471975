import React from 'react'
import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css';


export const MarkdownEditor = ({value, handleOnChange, setStateKey}) => {
  // Initialize a markdown parser
  const mdParser = new MarkdownIt({
    html: true,
  });
  
  const handleEditorChange = ({ html, text }) => {
    handleOnChange(text, setStateKey)
  }
  return (
    <MdEditor
      style={{ height: "600px" }}
      value={value}
      renderHTML={(text) => mdParser.render(text)}
      onChange={handleEditorChange}
    />
  )
}

export default React.memo(MarkdownEditor)
