import React, { useCallback } from 'react'
import { Button } from 'rsuite'
import styled from 'styled-components'
import CouponInput from '../../../components/coupons/CouponInput'

const Root = styled.div`
  width: 300px;
  margin: 4px 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  & div:first-child {
    flex: 1;
  }
`

/**
 *  data: { id:string, value:string, type:'taxi'|'rental'}[]
 */
function CouponInputList({ data, onChange, onRemove }) {
  const handleChange = useCallback(
    (id) => (value) => {
      onChange(id, value)
    },
    [onChange],
  )

  const handleRemove = useCallback(
    (id) => () => {
      onRemove(id)
    },
    [onRemove],
  )

  const renderItems = useCallback(() => {
    return data.map((item) => (
      <Row key={item.id}>
        <CouponInput variant={item.type} placeholder="promo_id or id" onChange={handleChange(item.id)} value={item.value} />
        <Button appearance="default" onClick={handleRemove(item.id)}>
          刪除
        </Button>
      </Row>
    ))
  }, [data, handleChange, handleRemove])

  return <Root>{renderItems()}</Root>
}

export default CouponInputList
