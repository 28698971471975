import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { IconButton, Icon } from 'rsuite'
import { ListWrapper, ListTitleWrapper, ListTitle, InputStyled } from './styles'
import { DEFAULT_CONTENT_DATA } from './utils'
import ContentItem from './ContentItem'

const ContentWrapper = styled.div`
  margin-top: 24px;
`

const AddContents = React.memo(({ eventId, contentsData, setContentsData }) => {
  const updateContentItemData = (id, key, value) => {
    const newContentsData = contentsData.map((content) => (id === content.idKey ? { ...content, [key]: value } : content))
    setContentsData(newContentsData)
  }

  const addContentHandler = () => {
    setContentsData([...contentsData, { ...DEFAULT_CONTENT_DATA[0], event: eventId, idKey: dayjs().valueOf() }])
  }

  const removeContentItem = (id) => {
    const newContentsData = contentsData.filter((content) => content.idKey !== id)
    setContentsData(newContentsData)
  }

  return (
    <>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle>Event ID</ListTitle>
        </ListTitleWrapper>
        <InputStyled disabled value={eventId} />
      </ListWrapper>
      <ContentWrapper>
        {contentsData.map((data, index) => (
          <ContentItem
            key={data.idKey}
            data={data}
            index={index}
            updateContentItemData={updateContentItemData}
            removeContentItem={removeContentItem}
          />
        ))}
      </ContentWrapper>
      <IconButton appearance="ghost" icon={<Icon icon="plus" />} onClick={addContentHandler}>
        新增活動區塊
      </IconButton>
    </>
  )
})

export default AddContents
