import React from 'react'
import { SelectPicker } from 'rsuite'

const IntervalPicker = React.memo(({ style, value, onChange }) => {
  const options = [
    { label: '一天', value: 1 },
    { label: '兩天', value: 2 },
    { label: '三天', value: 3 },
    { label: '四天', value: 4 },
    { label: '五天', value: 5 },
    { label: '六天', value: 6 },
    { label: '一個禮拜', value: 7 },
    { label: '兩個禮拜', value: 14 },
    { label: '一個月', value: 30 },
    { label: '永久', value: -1 },
  ]

  return (
    <SelectPicker
      data={options}
      defaultValue={value}
      searchable={false}
      cleanable={false}
      style={style}
      maxHeight={400}
      onChange={onChange}
    />
  )
})

export default IntervalPicker
