import React, { memo } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin-top: 12px;
  background: #ffffff;
  color: #1e201f;
`
const Title = styled.h4`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  padding: 28px 20px 20px;
`
const SectionWrapper = ({ className, title, children }) => {
  return (
    <Wrapper className={className}>
      <Title>{title}</Title>
      {children}
    </Wrapper>
  )
}

const Button = styled.button`
  position: relative;
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 20px;
  color: #1e201f;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  &::before {
    content: url(https://storage.googleapis.com/web-static-files-bucket/icons/general/arrow-right-grey.svg);
    width: 16px;
    height: 16px;
    position: absolute;
    right: 20px;
  }
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: calc(100vw - 40px);
    height: 1px;
    background: #f5f5f5;
  }
  &:active {
    background-color: #eeefee;
  }
  &:last-child {
    &::after {
      height: 0;
    }
  }
`

const ButtonTitle = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  img {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 9px;
  }
`

const ButtonDesc = styled.div`
  margin-right: 22px;
  color: #a5a6a5;
  text-align: right;
`

const IconButtonWithDescription = ({ className, title, desc, icon, onClick, test }) => {
  return (
    <Button className={className} type="button" onClick={onClick} data-test={test}>
      <ButtonTitle>
        {icon && <img src={icon} alt="" />}
        <p>{title}</p>
      </ButtonTitle>
      <ButtonDesc>{desc}</ButtonDesc>
    </Button>
  )
}
const CouponButton = styled(IconButtonWithDescription)`
  &:last-child {
    height: 68px;
    border-bottom: 14px solid #ffffff;
  }
`

export const CouponSection = memo(() => {
  return (
    <SectionWrapper title="專屬乘車優惠">
      <CouponButton
        test="ride_discount_button"
        title="天天領乘車金"
        desc="未領取"
        icon="https://storage.googleapis.com/web-static-files-bucket/icons/general/Ic_Img_M_Money_Green.svg"
      />
      <CouponButton
        test="daily_pass_button"
        title="日日搭/送禮"
        icon="https://storage.googleapis.com/web-static-files-bucket/icons/general/Ic_Img_M_Gift_Green.svg"
      />
      <CouponButton
        test="mileage_exchange_button"
        title="T里程兌換乘車券"
        desc="開通享優惠"
        icon="https://storage.googleapis.com/web-static-files-bucket/icons/general/Ic_Img_M_Trewards_Green.svg"
      />
      <CouponButton
        test="recommend_num_button"
        title="分享拿乘車券"
        desc="推薦序號 ABC123"
        icon="https://storage.googleapis.com/web-static-files-bucket/icons/general/Ic_Img_M_Share_Green.svg"
      />
      <CouponButton
        test="promo_code_button"
        title="輸入優惠序號"
        icon="https://storage.googleapis.com/web-static-files-bucket/icons/general/Ic_Img_M_Discount_Green.svg"
      />
    </SectionWrapper>
  )
})

export const TRewardSection = memo(() => {
  return (
    <SectionWrapper title="關於 T Rewards ">
      <IconButtonWithDescription title="會員升等與專屬回饋" />
      <IconButtonWithDescription title="常見問答集" />
    </SectionWrapper>
  )
})
