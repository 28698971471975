const handleAction = (action) => {
	const result = []
	action.forEach((item) => {
		if (item.type === 'message') {
			item.text = `[${item.text}]`
			const { text, type, area } = item
			result.push({ text, type, area })
		} else {
			const { type, linkUri, area } = item
			result.push({ type, linkUri, area })
		}
	})
	return result
}

const creatContent = (title, baseUrl, action, lineText) => {
	const lineBotText = {
		type: 'text',
		text: lineText,
		altText: '您有新訊息',
		weight: 'regular',
		wrap: true,
		color: '#FFFFFF',
		flex: 0,
		size: 'md',
		margin: 'md',
		align: 'start',
		gravity: 'top',
	}
	const imagemap = [
		{
			type: 'imagemap',
			baseSize: { height: 1040, width: 1040 },
			altText: title,
			baseUrl,
			actions: handleAction(action),
		}
	]
	lineText && imagemap.unshift(lineBotText)
	return JSON.stringify(imagemap)
}

const creactAction = (actionNum) => {
	if (actionNum === 1) {
		return [{
			type: '',
			area: { x: 0, y: 0, width: 1040, height: 1040 }
		}]
	} else if (actionNum === 2) {
		return [{
			type: '',
			area: { x: 0, y: 0, width: 520, height: 1040 },
		}, {
			type: '',
			area: { x: 520, y: 0, width: 520, height: 1040 }
		}]
	} else if (actionNum === 3) {
		return [{
			type: '',
			area: { x: 0, y: 520, width: 346, height: 520 }
		}, {
			type: '',
			area: { x: 346, y: 520, width: 346, height: 520 }
		}, {
			type: '',
			area: { x: 692, y: 520, width: 348, height: 520 }
		}]
	}
}

export { creatContent, creactAction }
