import React, { useEffect, useState } from 'react'
import { TagPicker, Alert } from 'rsuite'
import { getRentalCarSeries } from '../../api'

const CarSeriesPicker = ({ value, setValue }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await getRentalCarSeries()
        setData(result.data.map((v) => ({ label: v.carName, value: v.id })))
      } catch (error) {
        Alert.error(error?.message || 'fetch car series error')
      }
    }
    fetch()
  }, [])

  return <TagPicker
    data={data}
    value={value}
    onChange={setValue}
    placeholder="全部車種"
    cleanable={false}
    placement={'top'}
    style={{ width: 300 }}
  />
}

export default React.memo(CarSeriesPicker)
