import { Input, Radio, Dropdown } from 'rsuite'
import styled from 'styled-components'

export const ListWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`
export const ListTitleWrapper = styled.div`
  margin-right: 30px;
  color: #797979;
`
export const ListTitle = styled.div`
  font-size: 16px;
  flex-shrink: 0;
  ${(props) =>
    props.$require &&
    `
    &::after {
    content: '*';
    color: #F44336;
  }
  `}
`
export const ListSubtitle = styled.div`
  font-size: 12px;
`
export const InputStyled = styled(Input)`
  max-width: 600px;
  ::placeholder {
    color: #8e8e93;
    font-weight: 400;
  }
`
export const StyledDropdown = styled(Dropdown)`
  min-width: 200px;
  width: 20%;
`
export const StyledDropdownItem = styled(Dropdown.Item)`
  min-width: 200px;
`
export const StyledRadio = styled(Radio)`
  color: #575757;
`
