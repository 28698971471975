import React from 'react'
import { Popover, Whisper } from 'rsuite'
import { Cell } from 'rsuite-table'

const ParseCell = ({ rowData, dataKey, ...props }) => {
  // pro 是回字串
  const stringData = rowData[dataKey]
  // 確認可被解析成正常 object
  const { payload, action } = typeof stringData === 'string' && stringData.includes('}') ? JSON.parse(stringData) : stringData

  const speaker = (
    <Popover>
      <b>{payload}</b>
    </Popover>
  )
  return (
    <Cell {...props}>
      {props.action === 'payload' && (
        <Whisper placement="top" speaker={speaker}>
          <div>{payload}</div>
        </Whisper>
      )}
      {props.action === 'action' && action}
    </Cell>
  )
}

export default ParseCell
