import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Button, Icon, Input, InputGroup, Loader, Message } from 'rsuite'
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'
import { Heading, Title, Wrapper, HStack } from '../driver_mileage/styles'
import IdentifiersStockTable from '../../components/drivers/IdentifiersStockTable'
import { Subtitle } from './styles'
import { getDriversIdentifiers, getDriversIdentifiersInfo } from '../../api'

const TABLE_COLUMNS = [
  { label: 'ID', dataKey: 'id', width: 70 },
  { label: '建立時間', dataKey: 'created_at', width: 150, format: (v) => dayjs(v).format('YYYY-MM-DD HH:mm') },
  { label: '項目', dataKey: 'item', width: 200 },
  { label: '動作', dataKey: 'action', width: 70 },
  { label: '數量', dataKey: 'amount', width: 70 },
  { label: '領取地點', dataKey: 'store' },
  { label: '操作人員', dataKey: 'operator', width: 200 },
  { label: 'Note', dataKey: 'note', width: 150 },
]

const DriversIdentifiers = () => {
  const { driver_id = '' } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [driverId, setDriverId] = useState(driver_id)
  const [driver, setDriver] = useState(null)
  const [logs, setLogs] = useState([])
  const history = useHistory()

  useEffect(() => {
    handleSearch()
  }, [])

  const handleSearchInputChange = (value) => setDriverId(value)

  const handleSearchClick = async () => {
    if (driver_id === driverId) {
      await handleSearch()
      return
    }
    history.push(`/drivers_identifiers/${driverId}`)
  }

  const handleSearch = async () => {
    if (!driverId) return

    setIsLoading(true)
    const { data: info } = await getDriversIdentifiersInfo(driverId)
    const { data: { data, message } = {}, status } = await getDriversIdentifiers(driverId)
    setIsLoading(false)
    if (status !== 200) {
      Alert.error(message || 'fetch failed')
    }
    if (data?.driver) {
      setDriver({ ...data?.driver, items: info })
    } else {
      setDriver(null)
    }
    setLogs(data?.logs || [])
  }

  const toAddPage = () => history.push(`/drivers_identifiers/${driverId}/add`)

  return (
    <Wrapper>
      <Heading>司機識別物</Heading>
      <Title>司機ID</Title>
      <HStack>
        <div>
          <InputGroup>
            <Input value={driverId} placeholder="請輸入司機ID" onChange={handleSearchInputChange} onPressEnter={handleSearchClick} />
            <InputGroup.Addon>
              <Icon icon="search" />
            </InputGroup.Addon>
          </InputGroup>
        </div>
        <Button appearance="primary" onClick={handleSearchClick} disabled={!driverId || isLoading}>
          查詢
        </Button>
        {isLoading && <Loader />}
      </HStack>
      {driver && (
        <div style={{ width: '360px' }}>
          <Message
            type="info"
            description={
              <div>
                {driver.uid} {driver.name}
                <span style={{ color: '#1675E0', marginLeft: '10px' }}>{driver.label}</span>
              </div>
            }
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '10px', paddingLeft: '8px' }}>
            <h6 style={{ lineHeight: '36px' }}>識別物領取情況</h6>
            <Button appearance="ghost" onClick={toAddPage}>
              識別物變動
            </Button>
          </div>
          <IdentifiersStockTable data={driver.items} />
        </div>
      )}
      <Subtitle>識別物領取紀錄</Subtitle>
      <Table height={400} data={logs} loading={isLoading} bordered wordWrap>
        {TABLE_COLUMNS.map((v) => (
          <Column key={v.dataKey} width={v.width} verticalAlign="middle">
            <HeaderCell>{v.label}</HeaderCell>
            <Cell>
              {(rowData) => {
                return <span>{v.format ? v.format(rowData[v.dataKey]) : rowData[v.dataKey]}</span>
              }}
            </Cell>
          </Column>
        ))}
      </Table>
    </Wrapper>
  )
}

export default DriversIdentifiers
