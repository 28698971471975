import React from 'react'
import { ContextStore } from '../../reducers';
import Welcome from '../../components/dashboard/Welcome';

function Dashboard() {
	const { auth } = React.useContext(ContextStore)
	const { user } = auth

	return (
		<div className="dashboard-wrap">
			<Welcome user={user} />
		</div>
	)
}

export default Dashboard
