import { Schema } from 'rsuite'

const { StringType, ArrayType } = Schema.Types

const EmployeeModel = Schema.Model({
  name: StringType().isRequired('姓名 欄位為必填'),
  username: StringType().isRequired('使用者帳號 欄位為必填'),
  email: StringType().isEmail('請輸入正確的email格式').isRequired('Email 欄位為必填'),
  phone: StringType()
    .pattern(/^9[0-9]{8}$/, '手機號碼若為0912123123，請輸入912123123')
    .isRequired('此欄位為必填'),
  role: ArrayType().isRequired('部門 欄位為必選'),
  type: StringType().isRequired('工作性質 欄位為必選'),
  status: StringType().isRequired('在職狀態 欄位為必選'),
})

export default EmployeeModel
