import React, { useState, useEffect } from "react";
import { Button, Icon, Alert } from "rsuite";
import { isEmpty, forEach } from "lodash";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import { tileLayer, controlOptions } from './setting'

const GeoGenerator = ({
  type,
  value,
  setStateKey,
  handleClose,
  setEditLocation,
  handleOnChange,
  multi
}) => {
  const [map, setMap] = useState(null);
  const [target, setTarget] = useState(isEmpty(value) ? null : value);
  delete L.Icon.Default.prototype._getIconUrl;
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
  });

  const getMapCenter = () => {
    if (multi && !isEmpty(target)) {
      return Object.values(target)[0][0]
    }
    if (type === 'point') return target
    return isEmpty(target) ? [] : target[0]
  }

  const initMap = (startPoint = [25.0475613, 121.5173399]) => {
    const container = L.DomUtil.get("map");
    if (container) container._leaflet_id = null;

    let map = L.map("map", { center: startPoint, zoom: 15 });
    L.tileLayer(tileLayer).addTo(map);
    map.pm.addControls(controlOptions[type]);
    return map;
  };

  const addPointToMap = (map, point) => {
    if (isEmpty(point)) return
    L.marker(point).addTo(map)
  }

  const addPolygonToMap = (myMap, latlngs) => {
    if (isEmpty(latlngs)) return
    const polygon = L.polygon(latlngs)
    polygon.addTo(myMap)
  }

  const handleGeoJSON = () => {
    const drawLayers = map.pm.getGeomanLayers(true)._layers;
    // 多區域
    if (multi) {
      let areas = {}
      forEach(drawLayers, (value, key) => {
        if (value._latlngs) {
          let range = JSON.parse(JSON.stringify(value._latlngs[0]))
          range.push(range[0])
          areas[key] = range
        }
      })
      return setTarget(areas)
    }

    // 圈單一區域或座標點
    switch (Object.keys(drawLayers).length) {
      case 0:
        Alert.error("請圈選區域！");
        setTarget(null)
        break;
      case 1:
        if (type === 'point') {
          const point = Object.values(drawLayers)[0]._latlng
          setTarget([point.lat, point.lng])  
        }
        if (type === 'range') {
          forEach(drawLayers, (value) => {
            if (value._latlngs) {
              let range = JSON.parse(JSON.stringify(value._latlngs[0]))
              range.push(range[0])
              setTarget(range)
            }
          })
        }
        break;
      default:
        Alert.error("只能選擇一個區域！");
        break;
    }
  };

  const getOutput = (value) => {
    return value ? JSON.stringify(value, null, 2) : "";
  };

  const handleConfirm = () => {
    if (!target) return;
    handleOnChange(target, setStateKey);
    setEditLocation("");
  };

  const handleClear = () => {
    handleOnChange([], setStateKey)
    setEditLocation('')
  }


  useEffect(() => {
    const mapCenter = getMapCenter()
    const initialMap = !isEmpty(mapCenter) ? initMap(mapCenter) : initMap()
    // 多區域
    if (multi) {
      forEach(target, (value) => {
        addPolygonToMap(initialMap, value)
      })
    } else {
      type === 'point' ? addPointToMap(initialMap, target) : addPolygonToMap(initialMap, target)
    }

    setMap(initialMap);
  }, []);

  return (
    <>
      <div className="generator-container">
        <div className="map" id="map" />
        <div className="setting-wrap">
          <Button color="red" onClick={handleGeoJSON}>
            <Icon icon="location-arrow" />
            <span>獲取座標</span>
          </Button>
          <textarea
            disabled
            cols="45"
            rows="50"
            value={getOutput(target)}
            readOnly
          />
        </div>
      </div>
      <div className="generator-button-wrap">
        <Button size="lg" appearance="default" onClick={handleClose}>取消設置</Button>
        <Button size='lg' appearance="ghost" onClick={handleClear}>無範圍</Button>
        <Button size="lg" appearance="primary" onClick={handleConfirm} disabled={isEmpty(target)}>設置範圍</Button>
      </div>
    </>
  );
};

export default React.memo(GeoGenerator);
