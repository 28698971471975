import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'
import { Alert, Button, Icon, Placeholder, Tag, Panel } from 'rsuite'
import { Heading, Wrapper } from '../receipt/styles'
import { TextRow, Label, Text } from './styles'
import { getSurgePriceMultiplierTest } from '../../api'

const { Paragraph } = Placeholder

const dateFormat = (v) => {
  if (!v || v < 0) return '-'
  return dayjs(v).format('YYYY-MM-DD HH:mm')
}

const SurgePriceMultiplierTestsDetail = () => {
  const { test_id = '' } = useParams()
  const [loading, setLoading] = useState(false)
  const [test, setTest] = useState({})
  const [columns, setColumns] = useState([])
  const history = useHistory()

  useEffect(() => {
    fetchTest()
  }, [])

  const fetchTest = async () => {
    setLoading(true)
    const { status, data } = await getSurgePriceMultiplierTest(test_id)
    setLoading(false)
    if (status !== 200) {
      Alert.error('surge multiplier test fetch failed')
      return
    }

    setTest(data.data)
    if (data.data.destination_result.length) {
      setColumns(Object.keys(data.data.destination_result[0]))
    }
  }

  return (
    <Wrapper>
      <Heading style={{ lineHeight: '2em' }}>浮動倍率測試詳情</Heading>
      <Panel bordered bodyFill style={{ backgroundColor: '#fff', padding: '20px', maxWidth: 600, color: '#666' }}>
        {loading ? (
          <Paragraph rows={5} />
        ) : (
          <>
            <TextRow>
              <Label>ID</Label>
              <Text><code>{test.id}</code></Text>
            </TextRow>
            <TextRow>
              <Label>名稱</Label>
              <Text>{test.display_name}</Text>
            </TextRow>
            <TextRow>
              <Label>開始時間</Label>
              <Text>{dateFormat(test.start_time * 1000)}</Text>
            </TextRow>
            <TextRow>
              <Label>結束時間</Label>
              <Text>{dateFormat(test.end_time * 1000)}</Text>
            </TextRow>
            <TextRow>
              <Label style={{ flexShrink: 0 }}>網格查詢</Label>
              <Text>
                <code>{test.grid_query}</code>
              </Text>
            </TextRow>
            <TextRow>
              <Label>樣板</Label>
              <Text>
                <Tag>
                  <strong>{test.template_name}</strong>
                </Tag>
              </Text>
            </TextRow>
            <TextRow>
              <Label>啟用</Label>
              <Text>
                {test.active === 1 ? <Icon icon="check" style={{ color: '#1f7505' }} /> : '-'}
              </Text>
            </TextRow>
            <TextRow>
              <Label>檔案格式</Label>
              <Text>
                <Tag style={{ marginTop: -4 }}>
                  { test.destination_table_schema || 'N/A' }
                </Tag>
              </Text>
            </TextRow>
            <TextRow>
              <Label>上傳檔案</Label>
            </TextRow>
            <Table height={500} data={test.destination_result} bordered>
              {columns.map((v) => ({ label: v, dataKey:v, align: 'center', width: 200 })).map((v) => (
                <Column key={v.dataKey} width={v.width} align={v.align} verticalAlign="middle">
                  <HeaderCell>{v.label}</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      return <span>{v.format ? v.format(rowData[v.dataKey]) : rowData[v.dataKey]}</span>
                    }}
                  </Cell>
                </Column>
              ))}
            </Table>
            <Button style={{ float: 'right', marginTop: 20 }} appearance="subtle" onClick={() => history.push('/surge_price_multiplier_tests')}>
              返回
            </Button>
          </>
        )}
      </Panel>
    </Wrapper>
  )
}

export default SurgePriceMultiplierTestsDetail
