import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  Alert,
  Breadcrumb,
  Button,
  ControlLabel,
  DatePicker,
  FlexboxGrid,
  Form,
  FormControl,
  FormGroup,
  Icon,
  IconButton,
  Loader,
  Radio,
  RadioGroup,
  SelectPicker,
  TagPicker,
  Toggle,
  Tooltip,
  Whisper,
} from 'rsuite'
import dayjs from 'dayjs'
import { debounce, map, maxBy, remove } from 'lodash'
import { array, boolean, date, mixed, number, object, string } from 'yup'
import { estimateUserGroup, getUserGroupFilterOptions, getUserGroupV2, postUserGroup, uploadUserGroup } from '../../api'
import {
  FILTER_METHODS,
  FREQUENCY_BY_DAY_OPTIONS,
  TEST_TRACK_GROUP,
  UPLOAD_FILE_TEMPLATE,
  UPLOAD_FILE_TYPE,
  UPLOAD_FILE_TYPE_LABEL,
  USER_PROPORTION,
} from './const'
import { DimensionWrapper, FormContainer, Root, Surface, Title } from './styles'
import ExportCsvButton from './Dimension/ExportCsvButton'
import { DropdownGroup } from '../../components/views'
import Dimension from './Dimension'

const schemaForFile = mixed()
  .required('必須上傳檔案')
  .test('is-valid-type', '檔案格式僅接受 .csv', (file) => {
    const fileExtension = file?.type.split('/')[1]
    const allowedExtensions = ['csv']
    return allowedExtensions.includes(fileExtension)
  })

const schemaForRules = mixed()
  .test('required', '必須選擇維度', (rules) => Array.isArray(rules) && rules.length)
  .test('is-valid-type', '維度選擇條件必須齊全', (rules) => {
    return !rules.some((item) => !item.finished)
  })

const schemaForCreateByFile = object().shape({
  groupTitle: string().required('分眾名稱不能空白'),
  proportion: array().of(number()).nullable(),
  file: schemaForFile,
})

const schemaForCreateByDimension = object().shape({
  groupTitle: string().required('分眾名稱不能空白'),
  rules: schemaForRules,
  excludeUserGroup: array().of(number()).nullable(),
  proportion: array().of(number()).nullable(),
  testGroup: string().nullable(),
  testWeek: date().required('追蹤分析的日期不能空白'),
  auto_update: boolean().required(),
  frequency_by_day: number()
    .when('auto_update', {
      is: true,
      then: (schema) => schema.oneOf(FREQUENCY_BY_DAY_OPTIONS.map((item) => item.value)).required('必須選擇更新週期'),
    })
    .when('auto_update', { is: false, then: (schema) => schema.nullable() }),
  end_time: date()
    .when('auto_update', {
      is: true,
      then: (schema) => schema.min(dayjs().toDate(), '更新到期時間必須是今天之後').required('必須選擇更新到期時間'),
    })
    .when('auto_update', { is: false, then: (schema) => schema.nullable() }),
})

const DEFAULT_FORM_VALUE = {
  groupTitle: '',
  addMethod: FILTER_METHODS.BY_MANUAL,
  excludeUserGroup: null,
  proportion: null,
  testGroup: null,
  testWeek: null,
  rules: [],
  auto_update: false,
  frequency_by_day: null,
  end_time: null,
}

const DuplicateUserGroupDAMA = () => {
  const history = useHistory()
  const { id } = useParams()
  const [userGroupExists, setUserGroupExists] = useState([])
  const [userGroupExistsCache, setUserGroupExistsCache] = useState([])
  const userGroupExistsRef = useRef([])
  const [formValue, setFormValue] = useState(DEFAULT_FORM_VALUE)
  const [isSearchExistUserGroup, setIsSearchExistUserGroup] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isInitializing, setIsInitializing] = useState(false)
  const [isEstimate, setIsEstimate] = useState(false)
  const [filters, setFilters] = useState([])
  const [options, setOptions] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      setIsInitializing(true)

      try {
        const userGroupFilterOptionsResponse = await getUserGroupFilterOptions()
        if (userGroupFilterOptionsResponse.status !== 200) {
          throw new Error(userGroupFilterOptionsResponse.data.msg)
        }
        const { filter, options } = userGroupFilterOptionsResponse.data
        const mapFilters = map(filter, (obj) => {
          return {
            ...obj,
            value: obj.type,
          }
        })
        setFilters(mapFilters)
        setOptions(options)

        const userGroupRefResponse = await getUserGroupV2({ id })

        if (userGroupRefResponse.status !== 200) {
          throw new Error(userGroupRefResponse.data.msg)
        }

        const ref = Array.isArray(userGroupRefResponse.data) && userGroupRefResponse.data.length ? userGroupRefResponse.data[0] : null

        if (ref) {
          setFormValue((prev) => {
            const next = { ...prev }
            if (ref.settings?.rules) {
              next.rules = ref.settings.rules.map((data, index) => ({ ...data, id: index + 1, finished: true }))
            }
            if (ref.settings?.distribution) next.proportion = ref.settings.distribution
            if (ref.settings?.testGroup) next.testGroup = ref.settings.testGroup
            if (ref.settings?.filter) next.excludeUserGroup = ref.settings.filter
            if (ref.auto_update) next.auto_update = true
            if (ref.frequency_by_day) next.frequency_by_day = ref.frequency_by_day
            if (ref.end_time) next.end_time = dayjs.unix(ref.end_time).toDate()
            return next
          })
        }

        const idArray = ref.settings?.filter ? ref.settings.filter : []

        const userGroupExistsResponse = await getUserGroupV2({ limit: 10, offset: 0 })

        if (userGroupExistsResponse.status !== 200) {
          throw new Error(userGroupExistsResponse.data.msg)
        }

        if (Array.isArray(userGroupExistsResponse.data) && userGroupExistsResponse.data.length) {
          const userGroupExists = [...userGroupExistsResponse.data]
          // 判斷應用的排除名單有沒有在選項裡
          const idContained = idArray.filter((id) => userGroupExistsResponse.data.some((item) => item.id === id))
          const idNotContained = idArray.filter((id) => !idContained.includes(id))

          if (idNotContained.length) {
            const response = await getUserGroupV2({ id: idNotContained.join(',') })
            if (response.status !== 200) {
              throw new Error(response.data.msg)
            }
            if (Array.isArray(response.data)) {
              response.data.forEach((item) => userGroupExists.push(item))
            }
          }
          userGroupExistsRef.current = userGroupExists
          setUserGroupExists(userGroupExists)
          const cache = userGroupExists.filter((item) => idArray.includes(item.id))
          setUserGroupExistsCache(cache)
        }
      } catch (e) {
        return Alert.error(e.message)
      }
      setIsInitializing(false)
    }

    fetchData()
  }, [id])

  const handleSearchExistUserGroup = useCallback(async (searchStr = '') => {
    setIsSearchExistUserGroup(true)
    const response = await getUserGroupV2({ search: searchStr, limit: 10, offset: 0 })
    if (response.status !== 200) {
      return Alert.error(response.data.msg)
    }
    setUserGroupExists(response.data)
    return setIsSearchExistUserGroup(false)
  }, [])

  const handleRenderMenu = useCallback(
    (menu) => {
      if (isSearchExistUserGroup) {
        return (
          <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
            <Icon icon="spinner" spin />
            Loading...
          </p>
        )
      }
      return menu
    },
    [isSearchExistUserGroup],
  )

  const handleExistUserGroupExited = useCallback(() => {
    setUserGroupExists(userGroupExistsRef.current)
  }, [])

  const handleExistUserGroupSelect = useCallback((value, item) => {
    setUserGroupExistsCache((prev) => {
      remove(prev, (v) => v.id === value.id)
      prev.push(item)
      return [...prev]
    })
  }, [])

  const handleDisableDate = useCallback((date) => dayjs(date).day() !== 1, [])

  const shouldBeDisabled = useMemo(() => isInitializing || isSubmitting || isEstimate, [isSubmitting, isInitializing, isEstimate])

  const handleFileChange = useCallback((e) => {
    const inputFile = e.target.files[0]
    if (!schemaForFile.isValidSync(inputFile)) {
      return Alert.warning('請上傳 csv 文件')
    }
    setFormValue((prev) => ({ ...prev, file: inputFile }))
  }, [])

  const handleDimensionType = useCallback((id, action, type) => {
    switch (action) {
      case 'add':
        setFormValue((prev) => {
          const newRules = [...prev.rules, { id, finished: false, type: null, parameters: {} }]
          return { ...prev, rules: newRules }
        })
        break
      case 'delete':
        setFormValue((prev) => {
          const newRules = prev.rules.filter((data) => data.id !== id)
          return { ...prev, rules: newRules }
        })
        break
      case 'edit':
        setFormValue((prev) => {
          const newRules = prev.rules.map((data) => (data.id === id ? { ...data, type, finished: false, parameters: {} } : data))
          return { ...prev, rules: newRules }
        })
        break
      default:
        break
    }
  }, [])

  const handleParameters = useCallback((id, newRule) => {
    setFormValue((prev) => {
      const newRules = prev.rules.map((data) => (data.id === id ? newRule : data))
      return { ...prev, rules: newRules }
    })
  }, [])

  const renderDimension = useCallback(() => {
    return formValue.rules.map((rule) => {
      const { id, type, finished } = rule
      return (
        <DimensionWrapper key={id} $error={!finished}>
          <div>
            <DropdownGroup
              style={{ width: 'min-Content', borderRight: '2px solid #dadada', paddingRight: '12px', marginRight: '12px' }}
              activeKey={type}
              options={filters}
              onSelect={(selectType) => handleDimensionType(id, 'edit', selectType)}
              disabledList={[]}
              placeholder="選擇維度"
            />
            <Dimension data={rule} filters={filters} options={options} handleParameters={handleParameters} />
          </div>
          <Button appearance="ghost" onClick={() => handleDimensionType(id, 'delete')}>
            - 移除
          </Button>
        </DimensionWrapper>
      )
    })
  }, [formValue.rules, filters, options, handleDimensionType, handleParameters])

  const handleAddDimension = useCallback(() => {
    handleDimensionType((maxBy(formValue.rules, 'id')?.id ?? 0) + 1, 'add')
  }, [formValue.rules, handleDimensionType])

  const handleParseFile = useCallback(async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = (e) => {
        const data = e.target.result.split(',\r').join()
        resolve(data)
      }
    })
  }, [])

  const formatRulesForAPI = useCallback((rules) => {
    return rules.map((data) => {
      const newData = { ...data }
      delete newData.id
      delete newData.finished
      const { coupon_type } = data.parameters
      // 整理多選項為 NONE 或僅一個的情況
      if (coupon_type && coupon_type.length === 1) {
        newData.parameters = {
          ...data.parameters,
          coupon_type: coupon_type[0],
        }
      }
      // 過濾 coupon_type 裡 null 與 undefined，避免 api error （LT-8544）
      else if (Array.isArray(coupon_type)) {
        const safeCouponType = coupon_type.filter((item) => item !== null && item !== undefined)
        newData.parameters = {
          ...data.parameters,
          coupon_type: safeCouponType,
        }
      }
      return newData
    })
  }, [])

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true)
    try {
      if (formValue.addMethod === FILTER_METHODS.BY_FILE) {
        await schemaForCreateByFile.validate(formValue)
        const payload = {
          title: formValue.groupTitle,
          type: formValue.fileType,
          file: await handleParseFile(formValue.file),
          distribution: formValue.proportion,
        }
        const params = Object.keys(payload)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`)
          .join('&')
        const response = await uploadUserGroup(params)
        if (response.status !== 200) throw new Error(response.data.msg)
        Alert.success('上傳成功！', 2000)
      }
      if (formValue.addMethod === FILTER_METHODS.BY_MANUAL) {
        await schemaForCreateByDimension.validate(formValue)
        const payload = {
          title: formValue.groupTitle,
          rules: formatRulesForAPI(formValue.rules),
          testWeek: dayjs(formValue.testWeek).format('YYYY-MM-DD'),
          auto_update: formValue.auto_update ? 1 : 0,
        }
        if (formValue.proportion) payload.distribution = formValue.proportion
        if (formValue.excludeUserGroup) payload.filter = formValue.excludeUserGroup
        if (formValue.testGroup) payload.testGroup = formValue.testGroup
        if (formValue.auto_update) {
          payload.frequency_by_day = formValue.frequency_by_day
          payload.end_time = dayjs(formValue.end_time).unix()
          payload.start_time = dayjs().unix()
        }

        const response = await postUserGroup(payload)
        if (response.status !== 200) throw new Error(response.data.msg)
        if (Array.isArray(response.data)) {
          response.data.forEach(({ title, user_group_id }) => {
            Alert.success(`新增 id 為 ${user_group_id} 的分眾名單「${title}」！`, 2000)
          })
        }
      }
      return history.push('/dama/user_group')
    } catch (e) {
      Alert.error(e.message)
      setIsSubmitting(false)
    }
  }, [formValue, handleParseFile, history, formatRulesForAPI])

  const handleEstimate = useCallback(async () => {
    setIsEstimate(true)
    try {
      await schemaForCreateByDimension.validate(formValue)
      const payload = {
        title: formValue.groupTitle,
        rules: formatRulesForAPI(formValue.rules),
        testWeek: dayjs(formValue.testWeek).format('YYYY-MM-DD'),
      }
      if (formValue.proportion) payload.distribution = formValue.proportion
      if (formValue.excludeUserGroup) payload.filter = formValue.excludeUserGroup
      if (formValue.testGroup) payload.testGroup = formValue.testGroup

      const response = await estimateUserGroup(payload)
      if (response.status !== 200) throw new Error(response.data.msg)
      const { size } = response.data
      Alert.success(`共有 ${size} 位使用者！`)
    } catch (e) {
      Alert.error(e.message)
    }
    setIsEstimate(false)
  }, [formValue, formatRulesForAPI])

  return (
    <Root>
      <Breadcrumb>
        <Breadcrumb.Item componentClass={Link} to="/dama/user_group">
          DAMA+分眾名單管理
        </Breadcrumb.Item>
        <Breadcrumb.Item active>新增分眾名單(副本)</Breadcrumb.Item>
      </Breadcrumb>
      <Title>新增分眾名單(副本)</Title>
      <FormContainer>
        {isInitializing && <Loader backdrop content="loading..." vertical />}
        <Form formValue={formValue} onChange={setFormValue} onSubmit={handleSubmit}>
          {formValue.addMethod === FILTER_METHODS.BY_MANUAL && (
            <FormGroup>
              <ControlLabel>轉換為範本</ControlLabel>
              <FormControl name="auto_update" accepter={Toggle} disabled={shouldBeDisabled} checked={formValue.auto_update} />
            </FormGroup>
          )}
          <FormGroup>
            <ControlLabel>分眾名稱</ControlLabel>
            <FormControl name="groupTitle" disabled={shouldBeDisabled} />
          </FormGroup>
          <FormGroup>
            <ControlLabel>篩選方式</ControlLabel>
            <FormControl name="addMethod" accepter={RadioGroup} disabled={shouldBeDisabled}>
              <Radio value={FILTER_METHODS.BY_MANUAL} disabled={shouldBeDisabled}>
                新增篩選維度
              </Radio>
              <Radio value={FILTER_METHODS.BY_FILE} disabled={shouldBeDisabled}>
                檔案上傳
              </Radio>
            </FormControl>
          </FormGroup>
          <FormGroup>
            <Surface>
              {formValue.addMethod === FILTER_METHODS.BY_MANUAL && (
                <>
                  {renderDimension()}
                  <IconButton appearance="ghost" icon={<Icon icon="plus" />} disabled={shouldBeDisabled} onClick={handleAddDimension}>
                    新增維度
                  </IconButton>
                </>
              )}
              {formValue.addMethod === FILTER_METHODS.BY_FILE && (
                <>
                  <FormGroup>
                    <ControlLabel>檔案類型</ControlLabel>
                    <FormControl name="fileType" accepter={RadioGroup} disabled={shouldBeDisabled}>
                      <Radio value={UPLOAD_FILE_TYPE.UID} disabled={shouldBeDisabled}>
                        {UPLOAD_FILE_TYPE_LABEL[UPLOAD_FILE_TYPE.UID]}
                        <ExportCsvButton
                          buttonText="範例下載"
                          fileName={UPLOAD_FILE_TYPE.UID}
                          sheetData={UPLOAD_FILE_TEMPLATE[UPLOAD_FILE_TYPE.UID]}
                        />
                      </Radio>
                      <Radio value={UPLOAD_FILE_TYPE.PHONE} disabled={shouldBeDisabled}>
                        {UPLOAD_FILE_TYPE_LABEL[UPLOAD_FILE_TYPE.PHONE]}
                        <ExportCsvButton
                          buttonText="範例下載"
                          fileName={UPLOAD_FILE_TYPE.PHONE}
                          sheetData={UPLOAD_FILE_TEMPLATE[UPLOAD_FILE_TYPE.PHONE]}
                        />
                      </Radio>
                    </FormControl>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>上傳 CSV</ControlLabel>
                    <input style={{ padding: '5px' }} type="file" accept=".csv" disabled={shouldBeDisabled} onChange={handleFileChange} />
                  </FormGroup>
                </>
              )}
            </Surface>
          </FormGroup>
          {formValue.addMethod === FILTER_METHODS.BY_MANUAL && (
            <FormGroup>
              <ControlLabel>
                自動更新週期(限範本分眾)
                <Whisper placement="right" trigger="hover" speaker={<Tooltip>名單更新時間點 = 此分眾創建時間點</Tooltip>}>
                  <Icon icon="info" />
                </Whisper>
              </ControlLabel>
              <FlexboxGrid style={{ gap: '8px' }} align="middle">
                <FlexboxGrid.Item>
                  <FormControl
                    name="frequency_by_day"
                    accepter={SelectPicker}
                    data={FREQUENCY_BY_DAY_OPTIONS}
                    searchable={false}
                    cleanable={false}
                    placeholder="請選擇名單更新週期"
                    disabled={shouldBeDisabled || !formValue.auto_update}
                  />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>更新直到</FlexboxGrid.Item>
                <FlexboxGrid.Item>
                  <FormControl
                    name="end_time"
                    accepter={DatePicker}
                    format="YYYY-MM-DD"
                    ranges={[]}
                    appearance="default"
                    cleanable={false}
                    placeholder="選擇日期"
                    disabled={shouldBeDisabled || !formValue.auto_update}
                    disabledDate={(date) => dayjs().isAfter(date, new Date())}
                    oneTap
                  />
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </FormGroup>
          )}
          {formValue.addMethod === FILTER_METHODS.BY_MANUAL && (
            <FormGroup>
              <ControlLabel>排除已建立的分眾名單</ControlLabel>
              <FormControl
                name="excludeUserGroup"
                accepter={TagPicker}
                placeholder="選擇欲排除之分眾名單名稱"
                data={userGroupExists}
                cacheData={userGroupExistsCache}
                labelKey="title"
                valueKey="id"
                onSearch={debounce(handleSearchExistUserGroup, 300)}
                onExited={handleExistUserGroupExited}
                onSelect={handleExistUserGroupSelect}
                renderMenu={handleRenderMenu}
                disabled={shouldBeDisabled}
                searchable
              />
            </FormGroup>
          )}
          <FormGroup>
            <ControlLabel>拆分多組分眾</ControlLabel>
            <FormControl
              name="proportion"
              accepter={SelectPicker}
              data={USER_PROPORTION}
              labelKey="name"
              searchable={false}
              placeholder="選擇拆分比例"
              disabled={shouldBeDisabled}
            />
          </FormGroup>
          {formValue.addMethod === FILTER_METHODS.BY_MANUAL && (
            <FormGroup>
              <ControlLabel>追蹤參數設定</ControlLabel>
              <FormControl
                name="testGroup"
                accepter={SelectPicker}
                data={TEST_TRACK_GROUP}
                labelKey="name"
                searchable={false}
                placeholder="選擇追蹤參數"
                disabled={shouldBeDisabled}
              />
            </FormGroup>
          )}
          {formValue.addMethod === FILTER_METHODS.BY_MANUAL && (
            <FormGroup>
              <ControlLabel>追蹤分析的日期</ControlLabel>
              <FormControl
                name="testWeek"
                accepter={DatePicker}
                format="YYYY-MM-DD"
                ranges={[]}
                appearance="default"
                cleanable={false}
                disabledDate={handleDisableDate} // 只能填入星期一作為分析日
                placeholder="Test Week 分析日"
                disabled={shouldBeDisabled}
                placement="autoVerticalStart"
                oneTap
              />
            </FormGroup>
          )}
          <FormGroup>
            <FlexboxGrid style={{ gap: '8px' }} align="middle">
              {formValue.addMethod === FILTER_METHODS.BY_MANUAL && (
                <Button onClick={handleEstimate} appearance="ghost" loading={isEstimate} disabled={shouldBeDisabled}>
                  人數試算
                </Button>
              )}
              <Button type="submit" appearance="primary" loading={isSubmitting} disabled={shouldBeDisabled}>
                送出
              </Button>
            </FlexboxGrid>
          </FormGroup>
        </Form>
      </FormContainer>
    </Root>
  )
}

export default DuplicateUserGroupDAMA
