import React from 'react'
import { Icon } from 'rsuite'
import { PreviewNotOpenYetPageWrapper } from './styles'
import defaultTheme from './theme'

const PreviewNotOpenYetPage = ({ theme = defaultTheme }) => {
  return (
    <PreviewNotOpenYetPageWrapper theme={theme}>
      <div className="top">
        <div className="icon" />
        <div className="icon">
          <Icon icon="close" />
        </div>
      </div>
      <div className="body">
        <div className="cover" />
        <div className="title">朋友還未打開禮物</div>
      </div>
    </PreviewNotOpenYetPageWrapper>
  )
}

export default PreviewNotOpenYetPage
