import React, { useState } from 'react'
import { Grid, Row, Col, Divider, Modal, Button } from 'rsuite';
import FormGrop from '../views/FormGrop'

const RefundModal = (props) => {
	const [refund_amount, setRefundAmount] = useState(0)

	// const { transactionData, toggleButtonModel, currentStatus } = this.props

	return (
		<Modal show={props.currentStatus} onHide={props.toggleButtonModel}>
			<Modal.Header>
				<Modal.Title>退款</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{props.transactionData &&
				(<>
				  <Grid fluid>
					<Row className="show-grid">
						<Col xs={6}>Transaction ID</Col>
						<Col xs={6}>{props.transactionData.transaction_id}</Col>
					</Row>
					<Row className="show-grid">
						<Col xs={6}>Order ID</Col>
						<Col xs={6}>{props.transactionData.order_id}</Col>
					</Row>
					<Row className="show-grid">
						<Col xs={6}>付款方式</Col>
						<Col xs={6}>{props.transactionData.payment_method}</Col>
					</Row>
					<Row className="show-grid">
						<Col xs={6}>狀態</Col>
						<Col xs={6}>{props.transactionData.status}</Col>
					</Row>
					<Row className="show-grid">
						<Col xs={6}>商品種類</Col>
						<Col xs={6}>{props.transactionData.type}</Col>
					</Row>
					<Row className="show-grid">
						<Col xs={6}>商品編號</Col>
						<Col xs={6}>{props.transactionData.mid}</Col>
					</Row>
					<Row className="show-grid">
						<Col xs={6}>付款金額</Col>
						<Col xs={6}>{props.transactionData.amount}</Col>
					</Row>				 
				</Grid>
				<Divider />
				<FormGrop title='退刷金額' placeholder='退刷金額' type={'input'} value={refund_amount} handleOnChange={(e) => setRefundAmount(e.target.value)} />
			</>)}
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={() => props.refundSubmit(refund_amount)} appearance="primary">確認</Button>
			</Modal.Footer>
		</Modal>
	)
	
}

export default RefundModal
