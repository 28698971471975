import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Uploader, Loader } from 'rsuite'
import { NotificationModal } from '../views'

const StyledUploader = styled(Uploader)`
  margin-top: 5px;
`

const ImageUploader = React.memo(({ handleImageUpload, handleRemove, multiple = false, accept = 'image/*' }) => {
  const [isLoading, setUploadLoading] = useState(false)
  const [fileList, setFileList] = useState([])
  const [showModal, setShowModal] = useState({ open: false, msg: '' })
  const handleUpload = async (file) => {
    setUploadLoading(true)
    await handleImageUpload(file)
    setUploadLoading(false)
    setFileList([])
  }
  const handleChange = (file) => {
    setFileList(file)
  }

  const shouldUpload = useCallback((file) => {
    return new Promise((resolve) => {
      const img = new Image()
      img.src = window.URL.createObjectURL(file.blobFile)
      // 10M
      if (file.blobFile.size > 1024 * 1024 * 10) {
        setFileList([])
        setShowModal({ open: true, msg: '檔案大小超過10M' })
        resolve(false)
        return
      }
      img.onload = () => {
        if (img.width !== 1040 || img.height !== 1040) {
          setFileList([])
          setShowModal({ open: true, msg: '請檢查圖片尺寸及檔案類型' })
          resolve(false)
        } else {
          resolve(true)
        }
      }
    })
  }, [])

  return (
    <>
      <StyledUploader
        listType="picture-text"
        action="//jsonplaceholder.typicode.com/posts/"
        fileListVisible={false}
        accept={accept}
        multiple={multiple}
        onUpload={handleUpload}
        shouldUpload={shouldUpload}
        onRemove={handleRemove}
        onChange={handleChange}
        fileList={fileList}
      >
        {isLoading && <Loader />}
      </StyledUploader>
      <NotificationModal show={showModal.open} handleClose={() => setShowModal({ open: false, msg: '' })} dialogClassName="pink-dialog">
        <NotificationModal.Header type="warning" title="上傳失敗！" />
        <NotificationModal.Body>{showModal.msg}</NotificationModal.Body>
      </NotificationModal>
    </>
  )
})

export default ImageUploader
