import React from 'react'
import { Tag } from 'rsuite'

const StatusLabel = React.memo(({ startTime, endTime }) => {
  const now = Math.floor(Date.now() / 1000)
  let component
  switch (true) {
    case endTime !== -1 && startTime >= endTime:
      component = (
        <Tag color="green">
          <strong>已解除</strong>
        </Tag>
      )
      break
    case startTime > now:
      component = (
        <Tag color="blue">
          <strong>預約封鎖</strong>
        </Tag>
      )
      break
    case endTime !== -1 && endTime <= now:
      component = (
        <Tag color="green">
          <strong>已解除</strong>
        </Tag>
      )
      break
    default:
      component = (
        <Tag color="red">
          <strong>封鎖中</strong>
        </Tag>
      )
      break
  }
  return component
})

export default StatusLabel
