const theme = {
  title: '#f0bdb0',
  mainBg: '#45137a',
  neutral: '#fff8ef',
  buttonBg: '#f4742b',
  buttonText: '#ffffff',
  buttonBorder: '#f4742b',
  listBg: '#1b0342',
  box: { title: '#f4742b', text: '#ffffff', bg: '#350f5e' },
  tag: { firstRide: '#b62dba', newMember: '#a1a1a1', oldMember: '#8aa80b' },
}

export default theme
