import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Modal, Button } from 'rsuite'
// import ModelType from './ModelTypes'
import { VStack } from './ReactUI';

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	}
})

class InputGroupModel extends React.Component {

	componentWillReceiveProps(props) {
		this.setState(props)
	}

	get ouput() {
		return this.props.newRow.reduce((result, column) => {
			if (column.value !== undefined) {
				result[column.column_name] = column.outputValue != undefined ? column.outputValue : column.value
			}

			return result
		}, {})
	}

	render() {
		return (
			<div>
				<Modal show={this.props.show} onHide={this.props.closeModel}>
					<Modal.Header>
						<Modal.Title>編輯欄位</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.props.newRow.map((row, i) => {
							row.data_index = i
							row.onChange = (e, index, key) => {
								const { newRow } = this.props
								newRow[index].value = e?.target ? e.target.value : e

								this.setState({ newRow })
							}

							return (
								<VStack key={`${row.column_name}-${i}`}>
									{<p>{row.title}</p>}
									{row.component}
									< hr className="dashed" />
								</VStack>
							)
						})}
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={async () => {
							await this.props.okModel(this.ouput)
						}} appearance="primary">送出</Button>
						<Button onClick={this.props.closeModel} appearance="subtle">Cancel</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}

InputGroupModel.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InputGroupModel)
