import React from 'react'
import { Input, InputPicker } from 'rsuite'
import { FormController, FormField, FormLabel, SectionTitle, Stack } from './styles'

const PanelFirstAward = () => {
  return (
    <Stack>
      <SectionTitle>主態獎勵</SectionTitle>
      <FormField isRequired>
        <FormLabel>種類</FormLabel>
        <FormController>
          <InputPicker placeholder="請選擇" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>獎勵點數</FormLabel>
        <FormController>
          <Input placeholder="輸入點數" />
        </FormController>
      </FormField>
      <SectionTitle>客態獎勵</SectionTitle>
      <FormField isRequired>
        <FormLabel>種類</FormLabel>
        <FormController>
          <InputPicker placeholder="請選擇" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>乘車券</FormLabel>
        <FormController>
          <InputPicker placeholder="請選擇" />
        </FormController>
      </FormField>
    </Stack>
  )
}

export default PanelFirstAward
