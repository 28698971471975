import React, { useEffect, useState, useContext } from 'react'
import { DatePicker, Breadcrumb, Alert, Checkbox, Input } from 'rsuite'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { ContextStore } from '../../reducers'
import { transformList, getAddModeTitle, checkDeletable, sortColumnBy } from './helper'
import {
  ListWrap,
  MarkdownEditor,
  TableController,
  TitleCell,
  DescCell,
  TypeCell,
  CategoryCell,
  ActionCell,
  StatusCheckbox,
  TypeNav,
  SearchInput,
  NewsModal,
} from '../../components/news'
import { getNews, createNews, deleteNews, createNotification, deleteInbox, updateNews, deleteNotification } from '../../api'
import AddListModel from '../../components/views/AddListModel'
import FormGrop from '../../components/views/FormGrop'
import FormGropWrap from '../../components/views/FormGropWrap'
import AddListGrop from '../../components/views/AddListGrop'
import PopWarning from '../../components/popWarning'
import { MoreButton } from '../../components/table'
import { ACTION } from '../../constants/actions'
import 'react-datepicker/dist/react-datepicker.css'

const initState = {
  id: null,
  title: '',
  desc: '',
  startDate: '',
  endDate: '',
  checkList: [],
  drivers: '',
  content: '',
  category: 'IMPORTANT',
  titleError: '',
  descError: '',
  startDateError: '',
  endDateError: '',
  driversError: '',
  contentError: '',
}

const News = () => {
  const { newsParams, newsDispatch } = useContext(ContextStore)
  const [showMoreButton, setMoreButton] = useState(true)
  const [showModal, setModal] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [isAddMode, setAddMode] = useState(false)
  const [previewMode, setPreviewMode] = useState({ isPreview: false, isDeletable: false })
  const [warning, setWarning] = useState('')
  const [newsData, setNewsData] = useState([])
  const [currentInfo, setCurrentInfo] = useState(initState)

  const fetchNews = async () => {
    const { activeKey, driverId, limit, offset, includeExpired, includeFuture, sortColumn, sortType } = newsParams
    if (!driverId && activeKey === 'inbox') return

    const params = {
      limit,
      offset,
      include_expired: includeExpired,
      include_future_post: includeFuture,
    }
    const response = await getNews(activeKey, params, driverId)
    if (response.status !== 200 || isEmpty(response.data.data)) return
    const data = response.data.data.news
    setMoreButton(data.length >= limit)
    const concatData = newsData.concat(data)
    setNewsData(sortColumnBy(concatData, sortColumn, sortType)) // 排序
    newsDispatch({ type: ACTION.SET_NEWS_OFFSET, offset: offset + limit })
  }

  const toggleAddMode = () => {
    if (isAddMode) setPreviewMode(false)
    setAddMode(!isAddMode)
  }

  const handleOnChange = (e, setStateKey) => {
    const value = e.target ? e.target.value : e
    setCurrentInfo({
      ...currentInfo,
      [setStateKey]: value,
    })
  }

  const handleDatePicker = (date, stateKey) => {
    setCurrentInfo({
      ...currentInfo,
      [stateKey]: dayjs(+date).unix(),
    })
  }

  const handleTypeOnSelect = (activeKey) => {
    setNewsData([])
    setSearchInput('')
    newsDispatch({ type: ACTION.FILTER_NEWS_ACTIVEKEY, activeKey })
  }

  const handleStatusChange = (statusArr) => {
    const includeExpired = statusArr.indexOf('expired') >= 0
    const includeFuture = statusArr.indexOf('future') >= 0
    setNewsData([])
    newsDispatch({ type: ACTION.FILTER_NEWS_PERIOD, includeExpired, includeFuture })
  }

  const handleSearchDriver = (driverId) => {
    setNewsData([])
    newsDispatch({ type: ACTION.FILTER_NEWS_DRIVERID, driverId })
  }

  const handleUpdateNews = async (newsId, body) => {
    const response = await updateNews(newsId, body)
    if (response.status !== 200) return setWarning('修改失敗，請稍候再嘗試')
    setWarning('修改成功！')
    return toggleAddMode()
  }

  const handlePostNews = async () => {
    const { id, title, desc, startDate, endDate, checkList, drivers, content, category } = currentInfo
    // check if input empty
    setCurrentInfo({
      ...currentInfo,
      titleError: !title ? '請填寫或選擇項目' : '',
      driversError: !drivers ? '請填寫或選擇項目' : '',
      contentError: !content ? '請填寫或選擇項目' : '',
    })
    if (!title || !drivers || !content) return setWarning('欄位不能為空！')

    // call api
    const body = {
      title,
      description: desc,
      content,
      pin: checkList.indexOf('top') >= 0 ? 1 : 0,
      cover: checkList.indexOf('cover') >= 0 ? 1 : 0,
      start_time: startDate || 0,
      end_time: endDate || 0,
      category,
    }

    console.log('body', body)
    // 修改信件
    if (id) return handleUpdateNews(id, body)

    let response
    // 群發
    if (drivers === 'ALL') {
      body.status = 'NORMAL'
      body.option = {
        broadcast: checkList.includes('broadcast'),
      }
      response = await createNews(body)
    } else {
      // 特定收件者
      body.save = true
      body.drivers = drivers.split(',')
      if (checkList.includes('broadcast')) body.broadcast = true
      response = await createNotification(body)
    }
    if (response.status !== 200) return setWarning('新增失敗，請稍候再嘗試')
    setWarning('新增成功！')
    return toggleAddMode()
  }

  const handleDeleteNews = async (newsId) => {
    let response
    const { activeKey, driverId } = newsParams
    switch (activeKey) {
      case 'inbox':
        response = await deleteInbox(driverId, newsId)
        break
      case 'notifications':
        response = await deleteNotification(newsId)
        break
      default:
        response = await deleteNews(newsId)
        break
    }
    if (response && response.status !== 200) {
      let message = '刪除失敗，請稍候再嘗試'
      console.log('errorResponse', response.data)
      const { msg } = response.data
      if (msg && msg.includes('read')) message = '因有司機已讀故無法刪除，請修正結束時間'
      return setWarning(message)
    }
    if (previewMode.isPreview) toggleAddMode()
    return setWarning('刪除成功')
  }

  const handlePreviewMode = (data, action) => {
    if (action === 'delete') setPreviewMode({ isPreview: true, isDeletable: checkDeletable(data) })
    if (action === 'copy') Alert.success('已複製訊息', 3000)
    const { driverId } = newsParams
    const { id, title, description, created_at, start_time, end_time, pin, cover, content, drivers, category } = data
    // set checkList
    const checkList = []
    pin && checkList.push('top')
    cover && checkList.push('cover')

    // set drivers
    let currentDriver = ''
    if (driverId) currentDriver = driverId

    // set current info
    setCurrentInfo({
      id: action === 'copy' ? null : id,
      title: title || '',
      desc: description || '',
      startDate: start_time || created_at,
      endDate: end_time || '',
      checkList,
      drivers: currentDriver || transformList(drivers) || 'ALL',
      content: content || '',
      category: category || 'IMPORTANT',
      titleError: '',
      descError: '',
      startDateError: '',
      endDateError: '',
      driversError: '',
      contentError: '',
    })
  }

  const handleWarningConfirm = () => {
    setWarning('')
    setNewsData([])
    newsDispatch({ type: ACTION.SET_NEWS_OFFSET, offset: 0 })
  }

  const handleSortColumn = (sortColumn, sortType) => {
    const sortedData = sortColumnBy(newsData, sortColumn, sortType)
    setNewsData(sortedData)
    newsDispatch({ type: ACTION.SORT_NEWS_COLUMN, sortColumn, sortType })
  }

  useEffect(() => {
    if (isAddMode) return
    setCurrentInfo(initState)
  }, [isAddMode])

  useEffect(() => {
    if (newsParams.activeKey === 'inbox') setModal(true)
  }, [newsParams.activeKey])

  useEffect(() => {
    fetchNews()
  }, [newsParams.driverId])

  useEffect(() => {
    if (newsParams.offset === 0) fetchNews()
  }, [newsParams.offset])

  return (
    <>
      <div style={{ width: '100%' }}>
        {showModal && (
          <NewsModal
            showModal={showModal}
            setModalShown={setModal}
            value={searchInput}
            setSearchInput={setSearchInput}
            handleSearchUser={handleSearchDriver}
          />
        )}
        {warning && <PopWarning warning={warning} textCenter confirm={handleWarningConfirm} />}
        {isAddMode ? (
          <AddListModel>
            <Breadcrumb>
              <Breadcrumb.Item onClick={toggleAddMode}>信件</Breadcrumb.Item>
              <Breadcrumb.Item active>設置</Breadcrumb.Item>
            </Breadcrumb>
            <AddListGrop title={`${getAddModeTitle(previewMode.isPreview, currentInfo.id)}信件`}>
              {currentInfo.id && (
                <FormGrop title="信件 ID" type="children">
                  <Input value={currentInfo.id} disabled />
                </FormGrop>
              )}
              <FormGrop
                title="標題*"
                placeholder="Title"
                type="input"
                value={currentInfo.title}
                handleOnChange={handleOnChange}
                setStateKey="title"
                errorMessage={currentInfo.titleError}
              />
              <FormGrop
                title="副標"
                placeholder="Description"
                type="input"
                value={currentInfo.desc}
                handleOnChange={handleOnChange}
                setStateKey="desc"
                errorMessage={currentInfo.descError}
              />
              <FormGropWrap>
                <FormGrop title="預約時間（若未填寫，即刻寄出）" placeholder="Start Time" type="children" errorMessage={currentInfo.startDateError}>
                  <DatePicker
                    defaultValue={currentInfo.startDate ? new Date(currentInfo.startDate * 1000) : ''}
                    onOk={(date) => handleDatePicker(date, 'startDate')}
                    placement="bottomStart"
                    appearance="subtle"
                    placeholder="選擇日期和時間"
                    isoWeek
                    hideMinutes={(second) => second % 5 !== 0}
                    format="YYYY-MM-DD HH:mm"
                    ranges={[{ value: new Date(), closeOverlay: true }]}
                  />
                </FormGrop>
                <FormGrop title="結束時間" placeholder="End Time" type="children" errorMessage={currentInfo.endDateError}>
                  <DatePicker
                    defaultValue={currentInfo.endDate ? new Date(currentInfo.endDate * 1000) : ''}
                    onOk={(date) => handleDatePicker(date, 'endDate')}
                    placement="bottomStart"
                    placeholder="選擇日期和時間"
                    appearance="subtle"
                    isoWeek
                    hideMinutes={(second) => second % 5 !== 0}
                    format="YYYY-MM-DD HH:mm"
                    ranges={[{ value: new Date(), closeOverlay: true }]}
                  />
                </FormGrop>
              </FormGropWrap>
              <FormGrop title="信件設置" type="checkbox" value={currentInfo.checkList} handleOnChange={(value) => handleOnChange(value, 'checkList')}>
                <Checkbox value="top">置頂</Checkbox>
                <Checkbox value="cover">蓋版</Checkbox>
                {!currentInfo.id && <Checkbox value="broadcast">Push Notification</Checkbox>}
              </FormGrop>
              <FormGrop
                title="信件分類"
                type="radio"
                value={currentInfo.category}
                currentValue={currentInfo.category}
                handleOnChange={handleOnChange}
                setStateKey="category"
                options={[
                  { label: '重要', value: 'IMPORTANT' },
                  { label: '活動', value: 'EVENT' },
                  { label: '系統', value: 'SYSTEM' },
                ]}
              />
              <FormGrop
                title="填寫收件人 ID*"
                placeholder="以逗點區分 ID，例如 30,101，或是輸入 ALL 將發給所有司機"
                type="input"
                value={currentInfo.drivers}
                handleOnChange={handleOnChange}
                setStateKey="drivers"
                errorMessage={currentInfo.driversError}
              />
              <FormGrop title="信件內文*" type="children" errorMessage={currentInfo.contentError}>
                <MarkdownEditor value={currentInfo.content} handleOnChange={handleOnChange} setStateKey="content" />
              </FormGrop>
            </AddListGrop>
            <div className="campaign-button-wrap">
              {previewMode.isDeletable && (
                <button type="button" className="campaign-confirm-button" onClick={() => handleDeleteNews(currentInfo.id)}>
                  刪除
                </button>
              )}
              {!previewMode.isPreview && (
                <button type="button" className="campaign-confirm-button" onClick={handlePostNews}>
                  {currentInfo.id ? '更新' : '新增'}
                </button>
              )}
              <button type="button" className="campaign-cancel-button" onClick={toggleAddMode}>
                取消
              </button>
            </div>
          </AddListModel>
        ) : (
          <div style={{ width: '100%', height: '95%' }}>
            <ListWrap status="司機預約發信" addListText="+ 新增信件" onClick={toggleAddMode}>
              <div style={{ display: 'flex' }}>
                <TypeNav activeKey={newsParams.activeKey} handleTypeOnSelect={handleTypeOnSelect} />
                <StatusCheckbox
                  includeExpired={newsParams.includeExpired}
                  includeFuture={newsParams.includeFuture}
                  handleStatusChange={handleStatusChange}
                />
              </div>
              {newsParams.activeKey === 'inbox' && (
                <SearchInput value={searchInput} setSearchInput={setSearchInput} handleSearchUser={handleSearchDriver} />
              )}
            </ListWrap>
            <TableController data={newsData} topHeight={104} handleSortColumn={handleSortColumn} newsParams={newsParams}>
              <Column minWidth={50} flexGrow={0} align="center" fixed sortable>
                <HeaderCell>ID</HeaderCell>
                <Cell dataKey="id" />
              </Column>
              <Column minWidth={50} flexGrow={0}>
                <HeaderCell>分類</HeaderCell>
                <Cell>{(rowData) => <CategoryCell value={rowData.category} />}</Cell>
              </Column>
              <Column minWidth={80} flexGrow={2}>
                <HeaderCell>標題</HeaderCell>
                <TitleCell dataKey="title" activeKey={newsParams.activeKey} handlePreviewMode={handlePreviewMode} toggleAddMode={toggleAddMode} />
              </Column>
              <Column minWidth={80} flexGrow={2}>
                <HeaderCell>副標</HeaderCell>
                <DescCell dataKey="description" />
              </Column>
              {newsParams.activeKey === 'notifications' && (
                <Column minWidth={150} flexGrow={1}>
                  <HeaderCell>收件人</HeaderCell>
                  <DescCell dataKey="drivers" />
                </Column>
              )}
              <Column width={250} flexGrow={1.5}>
                <HeaderCell>內文</HeaderCell>
                <Cell dataKey="content" />
              </Column>
              <Column minWidth={50} flexGrow={1.5} sortable>
                <HeaderCell>開始時間</HeaderCell>
                <Cell dataKey="start_time">
                  {(rowData) => dayjs((rowData.start_time ? rowData.start_time : rowData.created_at) * 1000).format('YY-MM-DD HH:mm')}
                </Cell>
              </Column>
              <Column minWidth={50} flexGrow={1.5} sortable>
                <HeaderCell>結束時間</HeaderCell>
                <Cell dataKey="end_time">{(rowData) => (rowData.end_time ? dayjs(rowData.end_time * 1000).format('YY-MM-DD HH:mm') : '未設定')}</Cell>
              </Column>
              <Column minWidth={140} flexGrow={1.5}>
                <HeaderCell>信件狀態</HeaderCell>
                <TypeCell />
              </Column>
              <Column width={180}>
                <HeaderCell>{newsParams.activeKey === 'news' ? '修改 / 複製 / 刪除' : '複製 / 刪除'}</HeaderCell>
                <ActionCell
                  dataKey="id"
                  activeKey={newsParams.activeKey}
                  toggleAddMode={toggleAddMode}
                  handlePreviewMode={handlePreviewMode}
                  handleDeleteNews={handleDeleteNews}
                />
              </Column>
            </TableController>
            {showMoreButton && <MoreButton onClick={fetchNews} />}
          </div>
        )}
      </div>
    </>
  )
}

export default News
