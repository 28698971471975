import React from 'react'

function Tab(props) {
	const { tabs, currentTab, handleTab } = props
	return (
		<div className="tab-wrap">
			<div className="tab">
				{tabs.map((tab, index) => <h4 key={index} className={`tab-text ${currentTab.tab === tab.tab && 'active'}`} onClick={() => handleTab(tab)}>{tab.title}</h4>)}
			</div>
		</div>
	)
}

export default Tab
