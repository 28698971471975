import React, { useState, useCallback } from 'react'
import { Alert, Button, FlexboxGrid, Panel } from 'rsuite'
import { useHistory } from 'react-router-dom'
import Input from '../../components/views/Input'
import { AuthServices } from '../../api'

const ResetPassword = React.memo(() => {
  const history = useHistory()
  const [state, setState] = useState({
    password: '',
    newPassword: '',
    checkNewPassword: '',
  })

  const formAlert = useCallback((showAlert, msg = '') => {
    if (showAlert) Alert.error(msg)
    return showAlert
  }, [])

  const handleInput = (e) => {
    const { value, name } = e.target
    setState({
      ...state,
      [name]: value,
    })
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    const { password, newPassword, checkNewPassword } = state
    if (formAlert(!password, '請輸入舊密碼')) return
    if (formAlert(newPassword !== checkNewPassword, '新密碼與確認密碼不一致')) return
    const re = new RegExp(/^[a-zA-Z0-9!@#$%\\^&*)(+=._-]*$/)
    if (formAlert(!re.test(newPassword) || !re.test(checkNewPassword), '密碼不可為全形或空白')) return
    const form = {
      current_password: password,
      new_password: newPassword,
      double_check: checkNewPassword,
    }
    const data = await AuthServices.postResetPassword(form)
    if (data.status !== 200) {
      Alert.error('密碼修改失敗')
    } else {
      Alert.info('密碼修改成功')
      history.push('/')
    }
  }

  return (
    <FlexboxGrid justify="center" className="reset-password-wrap">
      <Panel bordered style={{ width: '450px' }}>
        <div className="reset-password-form">
          <div className="reset-password-account title subtitle_01">舊密碼</div>
          <Input name="password" type="password" placeholder="password" value={state.password} handleInput={handleInput} />
          <div className="reset-password-password title subtitle_01">新密碼</div>
          <Input name="newPassword" type="password" placeholder="new password" value={state.newPassword} handleInput={handleInput} />
          <div className="reset-password-password title subtitle_01">再輸入一次新密碼</div>
          <Input
            name="checkNewPassword"
            type="password"
            placeholder="check new password again"
            value={state.checkNewPassword}
            handleInput={handleInput}
          />
          <Button className="reset-password-button" color="black" appearance="primary" onClick={handleLogin}>
            確定送出
          </Button>
        </div>
      </Panel>
    </FlexboxGrid>
  )
})

export default ResetPassword
