import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { DatePicker, Button, Icon, IconButton, Input } from 'rsuite'

const SectionTitle = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: #2589f5;
  padding-bottom: 5px;
  border-bottom: 1px solid #d2d2d2;
  margin-top: 10px;
  margin-bottom: 25px;
`
const ListWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`
const ListTitleWrapper = styled.div`
  margin-right: 30px;
  color: #797979;
`
const ListTitle = styled.div`
  font-size: 16px;
  flex-shrink: 0;
  ${(props) =>
    props.$require &&
    `
    &::after {
    content: '*';
    color: #F44336;
  }
  `}
`
const InputStyled = styled(Input)`
  max-width: 70%;
  ::placeholder {
    color: #8e8e93;
    font-weight: 400;
  }
`
const ColorBlock = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 12px;
  background: transparent;
  border-radius: 2px;
  border: 1px solid #797979;
  ${({ $color }) => $color && $color.length === 7 && $color.includes('#') && `background: ${$color};`}
`
const MissionItem = styled.div`
  position: relative;
  margin: 15px 0;
  border: 1px solid #eeeeee;
  border-radius: 7px;
  padding: 24px;
`

const MemberCenterForm = ({ currentData, setCurrentData }) => {
  const handleMissionItem = (inputValue, missionIndex, dataKey) => {
    const { mission_items } = currentData
    setCurrentData({
      ...currentData,
      mission_items: mission_items.map((item, index) => {
        if (index !== missionIndex) return item
        const newItem = { ...item }
        newItem[dataKey] = inputValue
        return newItem
      }),
    })
  }

  const removeLastMission = () => {
    const newMissionItems = currentData.mission_items
    newMissionItems.pop()
    setCurrentData({ ...currentData, mission_items: newMissionItems })
  }

  const { id, name, start_time, end_time, bulletin_view, profile_view } = currentData

  return (
    <>
      <SectionTitle>活動標題</SectionTitle>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>活動 ID</ListTitle>
        </ListTitleWrapper>
        {id && <InputStyled value={id} disabled />}
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>活動標題</ListTitle>
        </ListTitleWrapper>
        <InputStyled placeholder="輸入標題，上限 8 個字" value={name || ''} onChange={(value) => setCurrentData({ ...currentData, name: value })} />
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>主題色</ListTitle>
        </ListTitleWrapper>
        <InputStyled
          placeholder="輸入色碼 例：#FFFFFF"
          value={bulletin_view?.mainColor || ''}
          onChange={(value) => setCurrentData({ ...currentData, bulletin_view: { ...bulletin_view, mainColor: value } })}
        />
        {bulletin_view?.mainColor && <ColorBlock $color={bulletin_view?.mainColor} />}
      </ListWrapper>
      <SectionTitle>活動時間</SectionTitle>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>活動顯示時間</ListTitle>
        </ListTitleWrapper>
        <DatePicker
          style={{ width: '70%' }}
          format="YYYY-MM-DD HH:mm"
          placeholder="選擇日期與時間"
          ranges={[]}
          onOk={(date) => setCurrentData({ ...currentData, start_time: dayjs(date).unix() })}
          onClean={() => setCurrentData({ ...currentData, start_time: '' })}
          value={start_time ? new Date(start_time * 1000) : null}
        />
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>活動結束時間</ListTitle>
        </ListTitleWrapper>
        <DatePicker
          style={{ width: '70%' }}
          format="YYYY-MM-DD HH:mm"
          placeholder="選擇日期與時間"
          ranges={[]}
          onOk={(date) => setCurrentData({ ...currentData, end_time: dayjs(date).unix() })}
          onClean={() => setCurrentData({ ...currentData, end_time: '' })}
          value={end_time ? new Date(end_time * 1000) : null}
        />
      </ListWrapper>
      <SectionTitle>頭像 Banner</SectionTitle>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle>頭像底圖</ListTitle>
        </ListTitleWrapper>
        <InputStyled
          placeholder="輸入圖片連結"
          value={profile_view?.avatarImgUrl || ''}
          onChange={(value) => setCurrentData({ ...currentData, profile_view: { ...profile_view, avatarImgUrl: value } })}
        />
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>背景底圖</ListTitle>
        </ListTitleWrapper>
        <InputStyled
          placeholder="輸入圖片連結"
          value={profile_view?.bannerImgUrl || ''}
          onChange={(value) => setCurrentData({ ...currentData, profile_view: { ...profile_view, bannerImgUrl: value } })}
        />
      </ListWrapper>
      <SectionTitle>任務牆 Banner</SectionTitle>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>標題</ListTitle>
        </ListTitleWrapper>
        <InputStyled placeholder="同活動標題" value={name} disabled />
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>活動日期底色</ListTitle>
        </ListTitleWrapper>
        <InputStyled
          placeholder="輸入色碼 例：#FFFFFF"
          value={bulletin_view?.periodBgColor || ''}
          onChange={(value) => setCurrentData({ ...currentData, bulletin_view: { ...bulletin_view, periodBgColor: value } })}
        />
        {bulletin_view?.periodBgColor && <ColorBlock $color={bulletin_view?.periodBgColor} />}
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>日期文字顏色</ListTitle>
        </ListTitleWrapper>
        <InputStyled
          placeholder="輸入色碼 例：#FFFFFF"
          value={bulletin_view?.periodTextColor || ''}
          onChange={(value) => setCurrentData({ ...currentData, bulletin_view: { ...bulletin_view, periodTextColor: value } })}
        />
        {bulletin_view?.periodTextColor && <ColorBlock $color={bulletin_view?.periodTextColor} />}
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>任務按鈕顏色</ListTitle>
        </ListTitleWrapper>
        <InputStyled
          placeholder="輸入色碼 例：#FFFFFF"
          value={bulletin_view?.missionButtonBgColor || ''}
          onChange={(value) => setCurrentData({ ...currentData, bulletin_view: { ...bulletin_view, missionButtonBgColor: value } })}
        />
        {bulletin_view?.missionButtonBgColor && <ColorBlock $color={bulletin_view?.missionButtonBgColor} />}
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>背景圖片</ListTitle>
        </ListTitleWrapper>
        <InputStyled
          placeholder="輸入圖片連結"
          value={bulletin_view?.bannerImgUrl || ''}
          onChange={(value) => setCurrentData({ ...currentData, bulletin_view: { ...bulletin_view, bannerImgUrl: value } })}
        />
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>官網連結</ListTitle>
        </ListTitleWrapper>
        <InputStyled
          placeholder="輸入連結"
          value={bulletin_view?.officialLink || ''}
          onChange={(value) => setCurrentData({ ...currentData, bulletin_view: { ...bulletin_view, officialLink: value } })}
        />
      </ListWrapper>
      <SectionTitle>任務內容</SectionTitle>
      {currentData.mission_items.map(({ title, desc, buttonText, buttonLink }, index) => {
        const isLastMission = index !== 0 && index === currentData.mission_items.length - 1
        return (
          <MissionItem key={title}>
            {isLastMission && (
              <IconButton
                style={{ position: 'absolute', right: '12px', top: '12px' }}
                onClick={removeLastMission}
                appearance="subtle"
                icon={<Icon icon="trash2" />}
              />
            )}
            <ListWrapper>
              <ListTitleWrapper>
                <ListTitle $require>{title}</ListTitle>
              </ListTitleWrapper>
              <InputStyled placeholder="輸入任務說明" value={desc || ''} onChange={(value) => handleMissionItem(value, index, 'desc')} />
            </ListWrapper>
            <ListWrapper>
              <ListTitleWrapper>
                <ListTitle $require>按鈕文字</ListTitle>
              </ListTitleWrapper>
              <InputStyled
                placeholder="輸入按鈕文字，上限4個字"
                value={buttonText || ''}
                onChange={(value) => handleMissionItem(value, index, 'buttonText')}
              />
            </ListWrapper>
            <ListWrapper>
              <ListTitleWrapper>
                <ListTitle $require>按鈕連結</ListTitle>
              </ListTitleWrapper>
              <InputStyled placeholder="輸入連結" value={buttonLink || ''} onChange={(value) => handleMissionItem(value, index, 'buttonLink')} />
            </ListWrapper>
          </MissionItem>
        )
      })}
      <Button
        size="sm"
        appearance="ghost"
        onClick={() => {
          setCurrentData({
            ...currentData,
            mission_items: [...currentData.mission_items, { title: `任務 ${currentData.mission_items.length + 1}` }],
          })
        }}
      >
        + 新增任務
      </Button>
    </>
  )
}

export default MemberCenterForm
