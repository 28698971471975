import React from 'react'
import { Popover, Whisper } from 'rsuite'
import { Cell } from 'rsuite-table'

const TitleCell = ({ rowData, dataKey, ...props }) => {
	const speaker = <Popover><b>{rowData.title}</b></Popover>
	return (
		<Cell {...props}>
			<Whisper placement="top" speaker={speaker}>
				<a>{rowData[dataKey]}</a>
			</Whisper>
		</Cell>
	)
}

export default TitleCell
