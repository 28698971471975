import React, { Component } from 'react';
import io from 'socket.io-client';
import dotenv from 'dotenv';
import Mapboxgl from 'mapbox-gl';

dotenv.config()
Mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

function LayerData(type, coordinates, properties) {
	this.type = "Feature"
	this.geometry = {
		'type': type, // 'Point'/'LineString'
		'coordinates': coordinates
	}
	this.properties = properties // object

}

const city_center = [
	[ 121.528696, 25.020232 ],
	[ 120.679038, 24.143192 ],
	[ 120.304707, 22.636182 ]
]

class MapboxMap extends Component {

	constructor(props) {
		super(props)
		this.state = {
			city: 0,
			layer_data: [],
			driverMarkers: [],
			driver_data: {},
			point_source_data: {},
			arc: [],
			steps: 5.1,
			testPoints: {
				currentPoint: [121.519537, 25.045570],
				nextPoint: [121.577687, 25.040869]
			}
		}
	}

	componentDidMount() {
		console.log('in mapbox')
		try {
			this.createMap()
		} catch (err) {
			console.log(err)
		}
	}

	createSocket() {
		const socket = io.connect(`${process.env.REACT_APP_SOCKET_URI}/admin`)

		socket.on('connect', function () {
			console.log('socket on')
			socket.emit('login')
		})

		socket.on('live_map', function (data) {
			try {
				console.log('live_map', data)
				const updated_point_data = this.socketDataHandler(data)

				// Reminder: minor error message from .setData method below

				this.map.getSource('driverPointSource')
					.setData(updated_point_data)

			} catch (e) {
				console.log('socket err', e)
			}

		}.bind(this))
	}

	socketDataHandler(data) {
		let updated_point_data = []

		Object.keys(data).forEach(key => {
			updated_point_data.push(new LayerData(
				'Point',
				data[key].coordinate,
				{
					driver_id: data[key].driver_id,
					status: data[key].status ? data[key].status : '',
					location: data[key].coordinate
				}
			))
		})

		updated_point_data = {
			"type": "FeatureCollection",
			"features": updated_point_data
		}

		return updated_point_data
	}

	rotateCity() {
		this.setState({city : this.state.city == 2 ? 0 : this.state.city + 1}, function(){
			this.map.flyTo({
				center: city_center[this.state.city],
				essential: true // this animation is considered essential with respect to prefers-reduced-motion
				});
		})
	}

	createMap() {
		this.map = new Mapboxgl.Map({
			container: this.mapContainer,
			style: 'mapbox://styles/mapbox/light-v10',
			center: [121.528696, 25.020232],
			zoom: 13
		})

		this.buildLayer()

		this.map.on('click', 'unclustered-point', function (e) {
			let coordinates = e.features[0].geometry.coordinates.slice();
			let location = e.features[0].properties.location;
			let driver_id = e.features[0].properties.driver_id;
			let status = e.features[0].properties.status ? e.features[0].properties.status : 'here'
			let tag = `${driver_id} is ${status} at ${location}`

			// Ensure that if the map is zoomed out such that multiple
			// copies of the feature are visible, the popup appears
			// over the copy being pointed to.
			while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
				coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
			}
			console.log(driver_id, coordinates)

			new Mapboxgl.Popup()
				.setLngLat(coordinates)
				.setHTML(tag)
				.addTo(this.map);

		}.bind(this));

		// Change the cursor to a pointer when the mouse is over the places layer.
		this.map.on('mouseenter', 'unclustered-point', function () {
			this.map.getCanvas().style.cursor = 'pointer';
		}.bind(this));

		// Change it back to a pointer when it leaves.
		this.map.on('mouseleave', 'unclustered-point', function () {
			this.map.getCanvas().style.cursor = '';
		}.bind(this));

		setInterval(this.rotateCity.bind(this), 15000);

		this.getDrivers()
		this.createSocket()
	}

	buildLayer() {
		this.map.on('load', function () {
			let point_feature_list = []

			this.map.addSource('driverPointSource', {
				"type": "geojson",
				"data": {
					"type": "FeatureCollection",
					"features": point_feature_list
				},
				"cluster": true,
				"clusterMaxZoom": 10,
				"clusterRadius": 25
			})

			this.setState({
				point_source_data: {
					"type": "FeatureCollection",
					"data": {
						"type": "FeatureCollection",
						"features": point_feature_list
					},
					"cluster": true,
					"clusterMaxZoom": 10,
					"clusterRadius": 25
				}
			})

			this.map.addLayer({
				"id": "driverPointLayer",
				"type": "circle",
				"source": 'driverPointSource',
				"filter": ["has", "point_count"],
				"paint": {
					// Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
					// with three steps to implement three types of circles:
					//   * Blue, 20px circles when point count is less than 100
					//   * Yellow, 30px circles when point count is between 100 and 750
					//   * Pink, 40px circles when point count is greater than or equal to 750
					"circle-color": [
						"step",
						["get", "point_count"],
						"#51bbd6",
						100,
						"#f1f075",
						750,
						"#f28cb1"
					],
					"circle-radius": [
						"step",
						["get", "point_count"],
						3,
						100,
						30,
						750,
						50
					]
				}
			})

			this.map.addLayer({
				"id": "cluster-count",
				"type": "symbol",
				"source": "driverPointSource",
				"filter": ["has", "point_count"],
				"layout": {
					"text-field": "{point_count_abbreviated}",
					"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
					"text-size": 12
				}
			});

			this.map.addLayer({
				id: "unclustered-point-IDLE",
				type: "circle",
				source: "driverPointSource",
				filter: ["!", ["has", "point_count"]],
				paint: {
					"circle-color": "#11b4da",
					"circle-radius": 4,
					"circle-stroke-width": 1,
					"circle-stroke-color": "#fff"
				}
			});

			// 'ENROUTE_PICKUP','ENROUTE_TRIP','PENDING_PAYMENT','PENDING_PAYMENT_COMPLETE'

			// this.map.addLayer({
			//     id: "unclustered-point-ENROUTE",
			//     type: "circle",
			//     source: "driverPointSource",
			//     filter: ['in', 'status', 'ENROUTE_PICKUP', 'ENROUTE_TRIP', 'PENDING_PAYMENT', 'PENDING_PAYMENT_COMPLETE'],
			//     paint: {
			//         "circle-color": "#EE1D1D",
			//         "circle-radius": 4,
			//         "circle-stroke-width": 1,
			//         "circle-stroke-color": "#fff"
			//     }
			// })

		}.bind(this))
	}

	getDrivers() {
		try {
			const url = `${process.env.REACT_APP_API_URI}/test/getDriverTrackMB`
			fetch(url, {
				headers: {
					'Content-Type': 'application/json'
				}
			}).then(async function (res) {
				console.log(` GET | ${url} `)
				const data = await res.json()
				const result = data.result

				this.setState({
					driver_data: result
				}, () => {
					this.getDriversHandler()
				})

			}.bind(this)).catch(function (err) {
				console.log('getDrivers', err)
			})
		} catch (err) {
			console.log(err)
		}
	}

	getDriversHandler() {
		let point_feature_list = []

		for (let key in this.state.driver_data) {
			let last_index = this.state.driver_data[key].location.length - 1

			let point_feature_data = new LayerData(
				'Point',
				[this.state.driver_data[key].location[last_index][0], this.state.driver_data[key].location[last_index][1]],
				{
					"driver_id": key,
					"location": [this.state.driver_data[key].location[last_index][0], this.state.driver_data[key].location[last_index][1]]
				}
			)

			point_feature_list.push(point_feature_data)
		}

		return point_feature_list
	}

	render() {
		return (
			<div className="main">
				<div id='map' ref={el => this.mapContainer = el} className="absolute top right left bottom" width="100%" height="100%" />
			</div>
		)
	}
}

export default MapboxMap;
