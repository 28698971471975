import _ from 'lodash'
import React, { useEffect, useMemo, useState, useCallback, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Breadcrumb, Button, Icon, IconButton, Input, InputGroup, InputPicker, Panel, Radio } from 'rsuite'
import { array, number, object, string } from 'yup'
import { getDriverNameById, getExchangeStuff, postExchangeStuff } from '../../api'
import { ContextStore } from '../../reducers'
import { DriverInfo, ExchangeStuffAssignee, ExchangeStuffList, ExchangeStuffRow, ExchangeSum, Heading, Subject, SubmitRow, Wrapper } from './styles'

const RECEIVER_TYPE_SELF = 'RECEIVER_TYPE_SELF'
const RECEIVER_TYPE_ASSIGNEE = 'RECEIVER_TYPE_ASSIGNEE'

const formSchema = object({
  exchange_store_id: string().required('必須選擇兌換地點'),
  admin_id: string().required(),
  agent_driver_id: string().optional(),
  items: array()
    .of(object({ id: string().required(), quantity: number().min(1, '兌換商品數量不能為 0'), miles: number().required() }))
    .min(1, '必須選擇至少一樣兌換商品'),
})

const numberOrZero = (x) => (typeof x === 'number' ? x : 0)

const ExchangeStuff = () => {
  const { auth } = useContext(ContextStore)
  const { driver_id } = useParams()
  const history = useHistory()

  const [currentReceiverType, setCurrentReceiverType] = useState(RECEIVER_TYPE_SELF)
  const [currentPlace, setCurrentPlace] = useState(null) // id
  const [currentSelectedStuff, setCurrentSelectedStuff] = useState([]) // item[]
  const [searchDriverId, setSearchDriverId] = useState(null)
  const [assigneeInfo, setAssigneeInfo] = useState(null) // { id: '', name: '' }

  const [isLoading, setIsLoading] = useState(false)

  const [driverInfo, setDriverInfo] = useState({
    id: driver_id,
    name: '',
    total_miles: 0,
    expired_miles: 0,
    driverGrade: { grade: '' },
  })
  const [stuffList, setStuffList] = useState([])
  const [placeList, setPlaceList] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getExchangeStuff(driver_id)
        if (!data.data) {
          throw new Error(data.message)
        }
        setDriverInfo({
          id: data.data.driver_info.id,
          name: data.data.driver_info.name,
          total_miles: data.data.total_miles,
          expired_miles: data.data.expired_miles,
          isEquipped: data.data.isEquipped || false,
          driverGrade: data.data.driverGrade,
        })
        console.log(driverInfo)
        setStuffList([...data.data.item_list])
        setPlaceList([...data.data.exchange_store_list])
      } catch (error) {
        Alert.error(error.message)
      }
    }
    fetchData()
  }, [driver_id])

  const handledStuffItems = useMemo(() => {
    return stuffList.map((item) => ({ value: item.id, label: `${item.title}(${item.miles})` }))
  }, [stuffList])

  const handledPlaces = useMemo(() => {
    return placeList.map((item) => ({ value: item.id, label: item.name }))
  }, [placeList])

  const totalSpentPoints = useMemo(() => {
    return currentSelectedStuff.reduce((prev, current) => prev + Math.abs(current.miles * current.quantity), 0)
  }, [currentSelectedStuff])

  const handleReceiverTypeChange = useCallback((value) => {
    setCurrentReceiverType(value)
  }, [])

  const handleStuffChange = useCallback(
    (value) => {
      const item = stuffList.find((elem) => elem.id === value)
      setCurrentSelectedStuff((prev) => {
        // 如果重複選擇，則自動合併，然後在已選擇的東西數量上+1
        const findIndex = _.findIndex(prev, { id: item.id })
        if (findIndex > -1) {
          prev.splice(findIndex, 1, { ...item, quantity: prev[findIndex].quantity + 1 })
          return [...prev]
        }
        return [...prev, { ...item, quantity: 1 }]
      })
    },
    [stuffList],
  )

  const handlePlaceChange = useCallback((value) => {
    setCurrentPlace(value)
  }, [])

  const handleQuantityChange = useCallback(
    (id, value) => () => {
      setCurrentSelectedStuff((prev) => {
        const findIndex = _.findIndex(prev, { id })
        if (findIndex > -1) {
          if (prev[findIndex].quantity + value >= 1) {
            prev.splice(findIndex, 1, { ...prev[findIndex], quantity: prev[findIndex].quantity + value })
            return [...prev]
          }
        }
        return prev
      })
    },
    [],
  )

  const handleDeleteStuffItem = useCallback(
    (id) => () => {
      setCurrentSelectedStuff((prev) => {
        const findIndex = _.findIndex(prev, { id })
        if (findIndex > -1) {
          prev.splice(findIndex, 1)
          return [...prev]
        }
        return prev
      })
    },
    [],
  )

  const handleSearchInputChange = useCallback((val) => setSearchDriverId(val), [])

  const handleSearchDriver = useCallback(async () => {
    try {
      const { data } = await getDriverNameById(searchDriverId)
      setAssigneeInfo(data.data)
    } catch (error) {
      Alert.error(error.message)
    }
  }, [searchDriverId])

  const renderSelectedStuff = useCallback(() => {
    return currentSelectedStuff.map((item) => (
      <li key={item.id}>
        <p className="title">{item.title}</p>
        <div className="actions">
          <IconButton icon={<Icon icon="minus" />} onClick={handleQuantityChange(item.id, -1)} circle />
          <p className="quantity">{item.quantity}</p>
          <IconButton icon={<Icon icon="plus" />} color="blue" onClick={handleQuantityChange(item.id, 1)} circle />
        </div>
        <div className="point">{`${item.miles} 點`}</div>
        <IconButton size="sm" icon={<Icon icon="close" />} onClick={handleDeleteStuffItem(item.id)} circle />
      </li>
    ))
  }, [currentSelectedStuff, handleQuantityChange, handleDeleteStuffItem])

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true)
      if (driverInfo.total_miles - totalSpentPoints < 0) {
        throw new Error('點數不足')
      }
      const formatItems = currentSelectedStuff.map((item) => ({ id: item.id, quantity: item.quantity, miles: numberOrZero(item.miles) }))
      const payload = { exchange_store_id: currentPlace, admin_id: auth.user.admin_id, agent_driver_id: assigneeInfo?.id, items: formatItems }
      await formSchema.validate(payload)
      const { data } = await postExchangeStuff(driverInfo.id, payload)
      if (!data.data) {
        throw new Error(data.message)
      }
      Alert.success('兌換執行成功')
      setIsLoading(false)
      history.push(`/driver_mileage?did=${driverInfo.id}`)
    } catch (error) {
      Alert.error(error.message)
      setIsLoading(false)
    }
  }, [driverInfo, totalSpentPoints, currentPlace, assigneeInfo, currentSelectedStuff, history, auth])

  return (
    <Wrapper>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/driver_mileage">司機獎勵積點(點數商品)</Breadcrumb.Item>
        <Breadcrumb.Item active>點數兌換商品</Breadcrumb.Item>
      </Breadcrumb>
      <Heading>點數兌換商品</Heading>
      <DriverInfo>
        <div className="cell">
          <p className="subject">司機ID</p>
          <span className="content">{`${driverInfo.id} ${driverInfo.name}`}</span>
        </div>
        <div className="cell">
          <p className="subject">目前點數</p>
          <span className="content">{driverInfo.total_miles}</span>
        </div>
        <div className="cell">
          <p className="subject">即將到期的點數</p>
          <span className="content">{driverInfo.expired_miles}</span>
        </div>
        <div className="cell">
          <p className="subject">GO好友</p>
          <span className="content">{driverInfo.isEquipped ? '是' : '否'}</span>
        </div>
        <div className="cell">
          <p className="subject">司機等級</p>
          <span className="content">{driverInfo.driverGrade.grade ? `${driverInfo.driverGrade.grade}` : '無'}</span>
        </div>
      </DriverInfo>
      <Subject>兌換地點</Subject>
      <ExchangeStuffRow>
        <InputPicker data={handledPlaces} onChange={handlePlaceChange} cleanable={false} placement="auto" />
      </ExchangeStuffRow>
      <Subject>兌換人</Subject>
      <ExchangeStuffRow>
        <Radio checked={RECEIVER_TYPE_SELF === currentReceiverType} value={RECEIVER_TYPE_SELF} onChange={handleReceiverTypeChange}>
          本人
        </Radio>
        <ExchangeStuffAssignee>
          <Radio
            className="w-240"
            checked={RECEIVER_TYPE_ASSIGNEE === currentReceiverType}
            value={RECEIVER_TYPE_ASSIGNEE}
            onChange={handleReceiverTypeChange}
          >
            代理人
          </Radio>
          {currentReceiverType === RECEIVER_TYPE_ASSIGNEE && (
            <>
              <InputGroup>
                <Input placeholder="輸入代理人司機ID" onChange={handleSearchInputChange} />
                <InputGroup.Button onClick={handleSearchDriver}>
                  <Icon icon="search" />
                </InputGroup.Button>
              </InputGroup>
              {assigneeInfo && (
                <Panel className="info" bordered>
                  {assigneeInfo.name ? `${assigneeInfo.id} ${assigneeInfo.name}` : '找不到此司機'}
                </Panel>
              )}
            </>
          )}
        </ExchangeStuffAssignee>
      </ExchangeStuffRow>
      <Subject>兌換商品</Subject>
      <ExchangeStuffRow>
        <InputPicker data={handledStuffItems} onChange={handleStuffChange} cleanable={false} placement="auto" />
      </ExchangeStuffRow>
      {!!currentSelectedStuff.length && (
        <>
          <ExchangeStuffList>{renderSelectedStuff()}</ExchangeStuffList>
          <ExchangeSum>
            <span className="total">{`總計應扣: ${totalSpentPoints} 點`}</span>
            <span>{`兌換後剩餘: ${driverInfo.total_miles - totalSpentPoints} 點`}</span>
          </ExchangeSum>
        </>
      )}
      <SubmitRow>
        <Button appearance="primary" onClick={handleSubmit} disabled={isLoading}>
          確認兌換
        </Button>
      </SubmitRow>
    </Wrapper>
  )
}

export default ExchangeStuff
