import React from 'react'
import PreviewBotCard from '../preview/PreviewBotCard'
import PreviewExpiredPage from '../preview/PreviewExpiredPage'
import PreviewJoinedPage from '../preview/PreviewJoinedPage'
import PreviewMainPage from '../preview/PreviewMainPage'
import PreviewNotOpenYetPage from '../preview/PreviewNotOpenYetPage'
import PreviewOpenedPage from '../preview/PreviewOpenedPage'
import PreviewShareSuccessPage from '../preview/PreviewShareSuccessPage'
import PreviewVisitorPage from '../preview/PreviewVisitorPage'
import { SectionTitle, HS, Stack } from './styles'

const PanelPreview = () => {
  return (
    <Stack>
      <SectionTitle>
        主態畫面
        <span className="sub-content">畫面僅供參考</span>
      </SectionTitle>
      <HS>
        <div className="box">
          <PreviewMainPage />
        </div>
        <div className="box">
          <PreviewShareSuccessPage />
        </div>
        <div className="box">
          <PreviewOpenedPage />
        </div>
        <div className="box">
          <PreviewNotOpenYetPage />
        </div>
      </HS>
      <SectionTitle>
        客態畫面
        <span className="sub-content">畫面僅供參考</span>
      </SectionTitle>
      <HS>
        <div className="box">
          <PreviewVisitorPage />
        </div>
        <div className="box">
          <PreviewVisitorPage isMember={false} />
        </div>
        <div className="box">
          <PreviewJoinedPage />
        </div>
        <div className="box">
          <PreviewJoinedPage isMember={false} />
        </div>
        <div className="box">
          <PreviewExpiredPage />
        </div>
        <div className="box">
          <PreviewExpiredPage isMember={false} />
        </div>
      </HS>
      <SectionTitle>
        BOT 卡片
        <span className="sub-content">畫面僅供參考</span>
      </SectionTitle>
      <HS itemWidth="300px">
        <div className="box">
          <PreviewBotCard
            cover=""
            title="好友送來 萬聖節魔法糖"
            content={
              '馬上打開領乘車券，11/4前每天都能領取不同好友分享的萬聖節魔法糖，驚喜獲得10~100元乘車券！好友新註冊首搭後，一起再拿LINE POINTS 25點。當日開啟有效！\n 提醒您：點選打開驚喜盒即同意您的個資(頭像、暱稱、參與次數)被蒐集與利用。'
            }
            primaryButtonText="立即開啟"
            secondaryButtonText="查看活動詳情"
          />
        </div>
        <div className="box">
          <PreviewBotCard
            cover=""
            title="提醒好友：首搭一起拿25點"
            content="推薦好友新註冊首次搭乘後，你和好友都能再獲得LINE POINTS 25點。"
            primaryButtonText="查看活動詳情"
          />
        </div>
        <div className="box">
          <PreviewBotCard
            cover=""
            title="提醒好友：首搭一起拿25點"
            content="推薦好友新註冊首次搭乘後，你和好友都能再獲得LINE POINTS 25點。"
            primaryButtonText="查看活動詳情"
          />
        </div>
        <div className="box">
          <PreviewBotCard
            cover=""
            title="好友首搭禮：恭喜獲得25點"
            content={'新註冊用戶完成首次搭乘後，你和好友都能再獲得LINE POINTS 25點。\n回饋點數將於2022/11/15起陸續發放，敬請耐心等候。'}
            primaryButtonText="查看活動詳情"
          />
        </div>
      </HS>
    </Stack>
  )
}

export default PanelPreview
