import React from 'react'
import styled from 'styled-components'
import { handleEliteStatus, handleVehicleType } from '../../pages/equipped/helper'

const DriverInfoWrapper = styled.div`
  margin-bottom: 30px;
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px;
  }
  textarea {
    margin-top: 10px;
    width: 50vw;
    height: 100px;
  }
  & > div {
    display: flex;
  }
`
const DriverInfoColumn = styled.div`
  min-width: 200px;
  margin-right: 20px;
`
const ColumnItem = styled.div`
  display: flex;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
  h6 {
    font-size: 14px;
    width: 75px;
  }
`
const DriverInfo = ({ info, handleOnChange }) => {
  const { driver_id, name, plate_number, car_brand, phone_number, vehicle_type, equipped_note } = info
  return (
    <DriverInfoWrapper>
      <div>
        <DriverInfoColumn>
          <ColumnItem>
            <h6>姓名</h6>
            <p>{name}</p>
          </ColumnItem>
          <ColumnItem>
            <h6>DID</h6>
            <p>{driver_id}</p>
          </ColumnItem>
          <ColumnItem>
            <h6>車牌</h6>
            <p>{plate_number}</p>
          </ColumnItem>
          <ColumnItem>
            <h6>車款</h6>
            <p>{car_brand}</p>
          </ColumnItem>
          <ColumnItem>
            <h6>電話</h6>
            <p>{phone_number}</p>
          </ColumnItem>
        </DriverInfoColumn>
        <DriverInfoColumn>
          <ColumnItem>
            <h6>營業車種</h6>
            <p>{handleVehicleType(vehicle_type)}</p>
          </ColumnItem>
          <ColumnItem>
            <h6>全標配</h6>
            <p>{handleEliteStatus(info)}</p>
          </ColumnItem>
        </DriverInfoColumn>
      </div>
      <h6>備註</h6>
      <textarea type="text" placeholder="填寫審核備註" value={equipped_note} onChange={(e) => handleOnChange(e, 'equipped_note')} />
    </DriverInfoWrapper>
  )
}

export default React.memo(DriverInfo)
