import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'
import { Panel, Pagination } from 'rsuite'

const TABLE_COLUMNS = [
  { label: 'Coupon ID', dataKey: 'id', width: 100 },
  { label: '優惠名稱', dataKey: 'promo_title', width: 150 },
  { label: '是否過期', dataKey: 'expiry_date', width: 80, format: (v) => (v < dayjs().unix() ? '已過期' : '未過期') },
  { label: '狀態', dataKey: 'order', width: 80, format: (v) => (v ? '已使用' : '未使用') },
  { label: '訂單', dataKey: 'order', width: 100, format: (v) => (v ? v : '-') },
  { label: '金額', dataKey: 'amount', width: 100, format: (v) => `$${v}` },
  { label: '上限', dataKey: 'max_amount', width: 100, format: (v) => (v ? `$${v}` : '-') },
  { label: '取得時間', dataKey: 'created_at', width: 150, format: (v) => dateFormat(v) },
  { label: '使用時間', dataKey: 'redeem_at', width: 150, format: (v) => dateFormat(v * 1000) },
  { label: '有效期限', dataKey: 'expiry_date', width: 150, format: (v) => dateFormat(v * 1000) },
  { label: '使用條件', dataKey: 'promo_desc', width: 150 },
]

const dateFormat = (v) => {
  if (!v) return '-'
  return dayjs(v).format('YYYY-MM-DD HH:mm')
}

const RentalCouponTable = React.memo(({ data, isLoading, header }) => {
  const [page, setPage] = useState(1)
  const limit = 5
  const filteredData = data.filter((v, i) => {
    const start = limit * (page - 1)
    const end = start + limit
    return i >= start && i < end
  })

  return (
    <Panel bordered bodyFill style={{ paddingBottom: 12 }}>
      {header ? (
        header
      ) : (
        <div className="rs-panel-heading">
          <span>用戶優惠券</span>
        </div>
      )}
      <Table style={{ minWidth: 400 }} rowHeight={90} autoHeight data={filteredData} loading={isLoading} wordWrap>
        {TABLE_COLUMNS.map((v) => (
          <Column key={v.dataKey} width={v.width} align={v.align} verticalAlign="middle">
            <HeaderCell>{v.label}</HeaderCell>
            <Cell>
              {(rowData) => {
                return (
                  <span style={{ color: '#575757', wordBreak: 'break-word', ...(v.style && { ...v.style }) }}>
                    {v.format ? v.format(rowData[v.dataKey], rowData) : rowData[v.dataKey]}
                  </span>
                )
              }}
            </Cell>
          </Column>
        ))}
      </Table>
      <Pagination
        prev
        next
        maxButtons={1}
        size="xs"
        activePage={page}
        onSelect={setPage}
        pages={Math.ceil(data.length / limit)}
        style={{ marginTop: 12, marginRight: 12, float: 'right' }}
      />
    </Panel>
  )
})

export default RentalCouponTable
