const AREA_SETTING = {
  1: [{ x: 0, y: 0, width: 1040, height: 1040 }],
  2: [
    { x: 0, y: 0, width: 520, height: 1040 },
    { x: 520, y: 0, width: 520, height: 1040 },
  ],
  3: [
    { x: 0, y: 520, width: 346, height: 520 },
    { x: 346, y: 520, width: 346, height: 520 },
    { x: 692, y: 520, width: 348, height: 520 },
  ],
}

const handleActions = (actions) => {
  return actions.map((item, index) => {
    const key = item.type === 'uri' ? 'linkUri' : 'text'
    return { type: item.type, [key]: item.value, area: AREA_SETTING[actions.length][index] }
  })
}

const createFlexMessageJSON = (title, imgUrl, actions) => {
  const currentActions = handleActions(actions)
  return {
    baseSize: { height: 1040, width: 1040 },
    altText: title,
    baseUrl: imgUrl,
    actions: currentActions,
  }
}

export default createFlexMessageJSON
