import styled from 'styled-components'

export const Root = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  padding: 20px 20px 80px;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const Title = styled.h6`
  color: #4b4d4c;
  margin-bottom: 20px;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 0.5px;
    background-color: #a5a6a5;
    margin-top: 5px;
  }
`

export const StepsBox = styled.div`
  padding: 12px 20px;

  & .rs-steps-item {
    cursor: pointer;
  }

  & .rs-steps-item-title {
    transition-property: color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;

    &:hover {
      color: #020617;
    }
  }
`

export const Content = styled.div`
  width: 100%;
  max-height: calc(100vh - 260px);
  overflow-y: scroll;
  flex: 1;
`

export const Footer = styled.div`
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding: 12px 20px;
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
`

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const DataGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const ConfigLabel = styled.label`
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
`

export const ConfigGroup = styled.div`
  display: flex;
  gap: 4px;
`

export const ConfigValue = styled.div`
  flex: 1;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #0f172a1a;
  background-color: #fafafa;
  max-height: 8rem;
  overflow-y: auto;
`
