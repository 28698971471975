import { Input, Button, IconButton, DatePicker } from 'rsuite'
import styled from 'styled-components'
import CloseIcon from '@rsuite/icons/Close'

export const CreateInput = styled(Input)`
  background-color: #f5f6f7;
  outline: none;
  border: none;
  transition: 0.3s;
  &::placeholder {
    opacity: 0.5 !important;
  }
  &:focus {
    border-color: none;
    background-color: #f5fafb;
  }
`
export const CreateWrapper = styled.div`
  border: 1px solid #e1e1e1;
  border-radius: 7px;
  padding: 15px 20px;
  margin-top: 20px;
  position: relative;
`
export const CreateClose = styled(CloseIcon)`
  border: 1px solid #e1e1e1;
  height: 20px;
  width: 20px;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
`
export const CreateTitle = styled.div`
  color: #4b4d4c;
  font-size: 14px;
  font-weight: 500;
`
export const StyledIconButton = styled(IconButton)`
  width: 150px;
  flex: 1;
  &:not(:last-child) {
    margin-right: 20px;
  }
`
export const StyledConfirmButton = styled(Button)`
  background-color: #1e201f;
  color: #ffffff;
  height: 45px;
  margin-bottom: 10px;
  &:hover,
  &:active {
    color: #fff;
  }
`
export const StyledPageButton = styled(Button)`
  margin-left: 10px;
  &:first-child {
    margin-left: auto;
  }
`
export const StyledDatePicker = styled(DatePicker)`
  margin: 0 10px;
`
export const PageButtonWrapper = styled.div`
  display: flex;
`
export const Breadcrumb = styled.div`
  font-size: 12px;
  color: #4b4d4c;
  display: flex;
  margin-bottom: 20px;
  span:first-child {
    color: #0069e0;
  }
  span:last-child {
    margin-left: 5px;
  }
`
export const Wrapper = styled.div`
  pointer-events: initial;
  min-height: 100%;
  width: 100%;
  background-color: #fff;
  padding: 15px 30px;
`
export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const ItemWrapper = styled.div`
  margin-bottom: 30px;
`
export const Title = styled.h6`
  color: #4b4d4c;
  margin-bottom: 20px;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 0.5px;
    background-color: #a5a6a5;
    margin-top: 5px;
  }
`
export const SubTitle = styled.h6`
  color: #4b4d4c;
  margin: 10px 0;
  font-size: 14px;
`
export const Content = styled.div`
  width: 100%;
  height: 100%;
`
export const CustomCellWrapper = styled.div`
  margin-bottom: 30px;
`
export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const SendTestCampaignWrapper = styled.div`
  display: flex;
  margin: 10px 0;
  justify-content: end;
`

export const FlexWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-wrap: nowrap;

  & .table-container {
    flex: 1;
  }
`

export const FormContainer = styled.div`
  width: 100%;

  & .rs-form-control-wrapper,
  .rs-picker-toggle-wrapper,
  .rs-input {
    width: 100%;
    max-width: 450px;
  }

  & .rs-control-label {
    font-weight: bold;
  }
`

export const Surface = styled.div`
  width: 100%;
  padding: 20px;
  border: 1px solid #dadada;
  border-radius: 8px;
`

export const DimensionWrapper = styled.div`
  padding: 18px;
  border: 2px solid;
  border-radius: 4px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  & > div {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }
  .rs-dropdown-menu {
    z-index: 20;
  }
  ${({ $error }) => `
    background: ${$error ? 'rgba(202,62,71, .1)' : 'rgba(157,211,168,0.3)'};
    border-color: ${$error ? 'rgba(202,62,71, .5)' : '#9dd3a8'};
  `}
`

export const Root = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  padding: 20px 20px 80px;
  display: flex;
  flex-direction: column;
`

export const ManagementContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-wrap: nowrap;

  & .tab-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .table-container {
    flex: 1;
  }
`

export const Label = styled.div`
  padding: 10px;
`
