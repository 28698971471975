import React, { Component } from 'react'
import { ContextStore } from '../../../reducers'
import { getModifyTable2, postTable, putTable, postImage } from '../../../api'
import { parseContent } from '../../../pages/marketing/advertisement/helper'
import { DatePicker, Breadcrumb, Animation } from 'rsuite'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import { TitleCell, Collapse } from '../../../components/campaign'
import { forEach } from 'lodash'
import moment from 'moment-timezone'
import AddListModel from '../../views/AddListModel'
import AddListGrop from '../../views/AddListGrop'
import FormGropWrap from '../../views/FormGropWrap'
import FormGrop from '../../views/FormGrop'
import ListWrap from '../../views/ListWrap'
import PopWarning from '../../../components/popWarning'
import TableController from '../../../components/table/Table'
import ImgCell from '../view/ImgCell'
import UrlCell from '../view/UrlCell'
import ButtonCell from '../view/ButtonCell'
import BroadcastActionCell from './BroadcastActionCell'
import Simulator from './Simulator'

export class Broadcast extends Component {
	state = {
		userId: '',
		table: 'user_broadcast',
		buttonIsLink: [{ label: '一般文字', value: 'no' }, { label: '附加連結', value: 'yes' }],
		limit: 20,
		offset: 0,
		ubData: [],
		addModel: false,
		currentPopUp: false,
		currentbuttonLink: 'no',
		currentLink: '',
		startDate: '',
		endDate: '',
		imgUrl: '',
		currentTitle: '',
		currentTextArea: '',
		currentBtnTitle: '',
		currentBtnTitleError: '',
		currentTextAreaError: '',
		startDateError: '',
		endDateError: '',
		currentTitleError: '',
		currentLinkError: '',
		imgUrlError: '',
		warning: undefined,
		showLoadMore: true,
		desc: 'id',
		filterBroadcast: [],
		filterTime: +(moment().format('X')),
		isLoading: false
	}

	componentDidMount() {
		const { auth } = this.context
		this.setState({ userId: auth.user.admin_id })
		this.fetchUserBroadcasting()
		this.fetchFilterBroadcast()
	}

	fetchUserBroadcasting = async () => {
		const { table, ubData, limit, desc } = this.state
		let conditions = []
		conditions.push({ key: 'limit', value: limit })
		conditions.push({ key: 'offset', value: ubData.length })
		conditions.push({ key: 'desc', value: desc })
		const data = await getModifyTable2({ table: table, conditions })
		if (data.status !== 200) return
		this.handleResult(data)
	}

	fetchFilterBroadcast = async () => {
		const { filterTime } = this.state
		let conditions = []
		conditions.push({ key: 'end_time_gte', value: filterTime })
		conditions.push({ key: 'start_time_lte', value: filterTime })
		conditions.push({ key: 'offset', value: 0 })
		conditions.push({ key: 'desc', value: 'id' })
		const data = await getModifyTable2({ table: 'user_broadcast', conditions })
		if (data.status !== 200) return
		const response = data.data.data.get.data
		console.log('filterBroadcast', response);

		const filterBroadcast = []
		response.forEach((item) => {
			item.button = parseContent(item.button)
			filterBroadcast.push(item)
		})
		filterBroadcast.reverse()
		console.log('filterBroadcast', filterBroadcast)
		this.setState({ filterBroadcast })
	}

	handleResult = (data) => {
		const { offset: preOffset, ubData: preUbData, limit } = this.state
		const result = data.data.data.get.data
		// console.log(result, 'result')
		const broadcastResult = []

		result.forEach(item => {
			item.button = parseContent(item.button)
			broadcastResult.push(item)
		})
		if (result.length < 20) {
			this.setState({
				showLoadMore: false,
				ubData: preUbData.concat(broadcastResult)
			})
		} else {
			this.setState({
				offset: preOffset + limit,
				ubData: preUbData.concat(broadcastResult)
			})
		}
	}

	handleOpenAddModel = () => {
		this.setState({ addModel: !this.state.addModel }, () => {
			const { addModel } = this.state
			if (addModel) return
			this.setState({
				currentUpdate: null,
				currentbuttonLink: 'no',
				currentLink: '',
				startDate: '',
				endDate: '',
				imgUrl: '',
				currentTitle: '',
				currentTextArea: '',
				currentBtnTitle: '',
				currentBtnTitleError: '',
				currentTextAreaError: '',
				startDateError: '',
				endDateError: '',
				currentTitleError: '',
				currentLinkError: '',
				imgUrlError: '',
			})
		})
	}

	handleOnChange = (e, setStateKey) => {
		const value = e.target ? e.target.value : e
		this.setState({
			[setStateKey]: value,
			[`${setStateKey}Error`]: false
		})
	}

	handlePostUserBroadcasting = () => {
		const { table, userId, startDate, endDate, imgUrl, currentTitle, currentTextArea, currentPopUp, currentBtnTitle, currentUpdate, buttonIsLink, currentLink, currentLinkError, currentbuttonLink } = this.state
		const object = { startDate, endDate, imgUrl, currentTitle, currentTextArea, currentPopUp, currentBtnTitle }
		forEach(object, (value, key) => !value && this.setState({ [`${key}Error`]: '請填寫完整或選擇項目' }))

		if (currentbuttonLink === 'yes' && !currentLink) {
			return this.setState({ currentLinkError: '請填寫連結' })
		}

		const button = currentbuttonLink === 'no' ? JSON.stringify({ label: currentBtnTitle }) :
			JSON.stringify([{
				type: 'button',
				style: 'link',
				action: { type: 'uri', uri: currentLink, label: currentBtnTitle }
			}])

		const popUp = currentPopUp === true ? 1 : 0
		const body = {
			title: currentTitle,
			start_time: startDate,
			end_time: endDate,
			content: currentTextArea,
			button: button,
			img_url: imgUrl,
			is_pop_up: popUp,
			admin_id: userId,
			id: currentUpdate ? currentUpdate.id : undefined
		}
		if (!currentTitle || !startDate || !endDate || !currentTextArea || !imgUrl || !button) return this.setState({ warning: '欄位不能為空！' })

		if (currentUpdate) return this.handlePutUserBroadcasting(body)
		postTable(table, body).then(data => {
			if (data.status !== 200) return this.setState({ warning: '新增活動失敗，請稍候再嘗試 ( ˘･з･)' })
			this.setState({ warning: '新增活動成功 (*‘ v`*)', offset: 0, limit: 20, ubData: [] }, () => {
				this.handleOpenAddModel()
				this.fetchUserBroadcasting()
			})
		})
	}

	handlePutUserBroadcasting = async (body) => {
		const { table, ubData } = this.state
		const id = body.id
		// console.log(body, 'body')
		const data = await putTable(table, id, '/batch', body)
		if (data.status !== 200) return this.setState({ warning: '更新活動失敗，請稍候再嘗試' })
		this.setState({ warning: '更新活動成功！', limit: ubData.length, offset: 0, ubData: [], showLoadMore: true }, () => {
			this.handleOpenAddModel()
			this.fetchUserBroadcasting()
		})

	}

	handleDatePicker = (date, name) => {
		this.setState({ [name]: +(moment(+date).format('X')) })
	}

	handleUpdate = (data, action) => {
		const { title, img_url, content, start_time, end_time, is_pop_up, button } = data
		this.setState({
			currentUpdate: action !== 'copy' ? data : false,
			currentTitle: title,
			imgUrl: img_url,
			currentTextArea: content,
			startDate: start_time,
			endDate: end_time,
			currentPopUp: is_pop_up === 0 ? false : true,
			currentbuttonLink: button.length ? 'yes' : 'no',
			currentBtnTitle: button.length ? button[0].action.label : button.label,
			currentLink: button.length ? button[0].action.uri : ''
		})
	}

	handleChangePop = (e) => {
		const target = e.target
		const name = target.name
		this.setState({ [name]: target.checked })
	}

	handleupdatedata = (child_limit, child_offset, child_mktEvent) => {
		this.setState({ limit: child_limit, offset: child_offset, ubData: child_mktEvent })
	}

	handleSimulatorDate = (date) => {
		this.setState({ filterTime: +(moment(+date).format('X')) }, () => {
			this.fetchFilterBroadcast()
		})
	}

	handleOnUpload = async (file) => {
		this.setState({ isLoading: true })
		const formData = new FormData()
		formData.append('file', file.blobFile)
		formData.append('doc_type', 'image')
		const data = await postImage(this.state.userId, formData)
		if (data.status !== 200) return
		this.setState({ isLoading: false })
		this.handleOnChange(data.data.image_url, 'imgUrl')
	}

	render() {
		const {
			table,
			warning,
			ubData,
			addModel,
			startDate,
			endDate,
			startDateError,
			endDateError,
			currentPopUp,
			imgUrl,
			buttonIsLink,
			currentLink,
			imgUrlError,
			currentTitle,
			currentTitleError,
			currentUpdate,
			currentTextArea,
			currentTextAreaError,
			currentBtnTitle,
			currentBtnTitleError,
			currentbuttonLink,
			currentLinkError,
			showLoadMore,
			filterBroadcast,
			isLoading
		} = this.state
		const { handlePopWarning } = this.props
		return (
			<div className="broadcast-wrap">
				{warning && <PopWarning warning={warning} textCenter={true} confirm={() => this.setState({ warning: '' })} />}
				{addModel ? (
					<AddListModel>
						<Breadcrumb>
							<Breadcrumb.Item onClick={this.handleOpenAddModel}>Broadcast</Breadcrumb.Item>
							<Breadcrumb.Item active>Add Broadcast</Breadcrumb.Item>
						</Breadcrumb>
						<AddListGrop title={'新增活動'}>
							<FormGropWrap>
								<FormGrop title={'開始時間'} type={'children'} errorMessage={startDateError} >
									<DatePicker
										defaultValue={startDate ? new Date(startDate * 1000) : undefined}
										onOk={(date) => this.handleDatePicker(date, 'startDate')}
										placement={'bottomStart'}
										appearance={'subtle'}
										placeholder={'From...'}
										isoWeek
										hideMinutes={second => second % 30 !== 0}
										format={'YYYY-MM-DD HH:mm'}
										ranges={[{ value: new Date(), closeOverlay: true }]} />
								</FormGrop>
								<FormGrop title={'結束時間'} type={'children'} errorMessage={endDateError}>
									<DatePicker
										defaultValue={endDate ? new Date(endDate * 1000) : undefined}
										onOk={(date) => this.handleDatePicker(date, 'endDate')}
										placement={'bottomStart'}
										appearance={'subtle'}
										placeholder={'To...'}
										isoWeek
										hideMinutes={second => second % 30 !== 0}
										format={'YYYY-MM-DD HH:mm'}
										ranges={[{ value: new Date(), closeOverlay: true }]} />
								</FormGrop>
							</FormGropWrap>
							<FormGrop title={'圖片網址'} placeholder={'Image url'} type={'input'} handleOnChange={this.handleOnChange} value={imgUrl} setStateKey={'imgUrl'} errorMessage={imgUrlError} />
							<FormGrop type={'uploader'} multiple={false} handleOnUpload={this.handleOnUpload} isLoading={isLoading} />
						</AddListGrop>
						<AddListGrop title={'設定'}>
							<FormGrop title={'主旨'} placeholder={'Title'} type={'input'} handleOnChange={this.handleOnChange} value={currentTitle} setStateKey={'currentTitle'} errorMessage={currentTitleError} />
							<FormGrop title={'文字內容'} placeholder={'Content'} type={'textarea'} value={currentTextArea} handleOnChange={this.handleOnChange} setStateKey={'currentTextArea'} errorMessage={currentTextAreaError} />
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<label>蓋版</label>
								<input
									name="currentPopUp"
									type="checkbox"
									checked={currentPopUp}
									onChange={this.handleChangePop}
									style={{ width: '15px', marginLeft: '20px' }}
								/>
							</div>
						</AddListGrop>
						<AddListGrop title={'按鈕設定'}>
							<FormGrop title={'按鈕加入連結'} placeholder={'Title'} type={'radio'} currentValue={currentbuttonLink} options={buttonIsLink} handleOnChange={this.handleOnChange} setStateKey={'currentbuttonLink'} />
							<FormGrop title={'按鈕文字'} placeholder={'Button Title'} type={'input'} handleOnChange={this.handleOnChange} value={currentBtnTitle} setStateKey={'currentBtnTitle'} errorMessage={currentBtnTitleError} />
							<Animation.Collapse in={currentbuttonLink === 'yes'}>
								{(props, ref) => (
									<Collapse {...props} ref={ref}>
										<FormGrop title={'連結網址'} placeholder={'Link'} type={'input'} value={currentLink} handleOnChange={this.handleOnChange} setStateKey={'currentLink'} errorMessage={currentLinkError} />
									</Collapse>
								)}
							</Animation.Collapse>
						</AddListGrop>
						<div className="campaign-button-wrap">
							<button className="campaign-confirm-button" onClick={this.handlePostUserBroadcasting}>{currentUpdate ? '更新' : '新增'}</button>
							<button className="campaign-cancel-button" onClick={this.handleOpenAddModel}>取消</button>
						</div>
					</AddListModel>)
					: (<div className="broadcast-content">
						<ListWrap addListText={'新增大聲公'} onClick={this.handleOpenAddModel} />
						<TableController data={ubData} topHeight={104}>
							<Column minWidth={50} flexGrow={0.5} align="center" fixed>
								<HeaderCell>Id</HeaderCell>
								<Cell dataKey="id" />
							</Column>
							<Column minWidth={150} flexGrow={1} align="center">
								<HeaderCell>Start</HeaderCell>
								<Cell>{(rowData) => moment(rowData.start_time * 1000).format('YYYY-MM-DD HH:mm')}</Cell>
							</Column>
							<Column minWidth={150} flexGrow={1} align="center">
								<HeaderCell>End</HeaderCell>
								<Cell>{(rowData) => moment(rowData.end_time * 1000).format('YYYY-MM-DD HH:mm')}</Cell>
							</Column>
							<Column minWidth={80} flexGrow={1.5}>
								<HeaderCell>Title</HeaderCell>
								<TitleCell dataKey="title" />
							</Column>
							<Column width={150} height={200} align="center">
								<HeaderCell>Content</HeaderCell>
								<UrlCell dataKey="content" />
							</Column>
							<Column minWidth={120} flexGrow={0.5}>
								<HeaderCell>Image</HeaderCell>
								<ImgCell dataKey="img_url" />
							</Column>
							<Column minWidth={50} flexGrow={0.5} align="center">
								<HeaderCell>Button</HeaderCell>
								<ButtonCell dataKey="button" />
							</Column>
							<Column minWidth={50} flexGrow={0.5} align="center">
								<HeaderCell>Pop Up</HeaderCell>
								<Cell>{(rowData) => { return rowData.is_pop_up === 0 ? '一般' : '蓋版' }}</Cell>
							</Column>
							<Column minWidth={180} flexGrow={1} align="center">
								<HeaderCell></HeaderCell>
								<BroadcastActionCell dataKey="id" table={table} handleUpdate={this.handleUpdate} fetchData={this.fetchUserBroadcasting} toogleModle={this.handleOpenAddModel} handleupdatedata={this.handleupdatedata} handlePopWarning={handlePopWarning} />
							</Column>
						</TableController>
						{showLoadMore && <div className="load-more-text subtitle_01" onClick={this.fetchUserBroadcasting}>Load more</div>}
					</div>)
				}
				<Simulator addModel={addModel} filterBroadcast={filterBroadcast} handleSimulatorDate={this.handleSimulatorDate} currentbuttonLink={currentbuttonLink} currentBtnTitle={currentBtnTitle} currentTitle={currentTitle} currentTextArea={currentTextArea} imgUrl={imgUrl} />
			</div>
		)
	}
}

Broadcast.contextType = ContextStore
export default Broadcast
