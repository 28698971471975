import React from 'react'

const MoreButton = ({ onClick }) => {
  return (
    <div className="more-button-wrap">
      <div className="add-list-wrap" onClick={onClick}>
        <button className="add-list">載入更多</button>
      </div>
		</div>
  )
}

export default React.memo(MoreButton)