import { ACTION } from '../constants/actions'

const initialState = {
  activeKey: 'news',
  driverId: null,
  limit: 20,
  offset: 0,
  includeExpired: false,
  includeFuture: false,
  sortColumn: '',
  sortType: 'desc'
}

function newsReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION.FILTER_NEWS_ACTIVEKEY:
			return {
        ...state,
        activeKey: action.activeKey,
        offset: 0,
        sortColumn: '',
        sortType: 'desc',
        driverId: null
			}
		case ACTION.FILTER_NEWS_DRIVERID:
			return {
        ...state,
        offset: 0,
        driverId: action.driverId
			}
    case ACTION.SET_NEWS_OFFSET:
      return {
        ...state,
        offset: action.offset
      }
    case ACTION.FILTER_NEWS_PERIOD:
      return {
        ...state,
        offset: 0,
        includeExpired: action.includeExpired,
        includeFuture: action.includeFuture
      }
    case ACTION.SORT_NEWS_COLUMN:
      return {
        ...state,
        sortColumn: action.sortColumn,
        sortType: action.sortType    
      }
		default:
			return state
	}
}


export { initialState, newsReducer }
