import React from 'react'
import { Input, InputGroup, Icon } from 'rsuite'

const SearchInput = ({value, setSearchInput, handleSearchUser}) => {
  const handleOnChange = (input) => {
    setSearchInput(input)
  }

  return (
    <InputGroup size="sm" style={{ maxWidth: '20%', margin: 5 }}>
      <Input placeholder="輸入司機 ID" type="number" value={value} onChange={handleOnChange} />
      <InputGroup.Button onClick={() => handleSearchUser(value)}>
        <Icon icon="search" />
      </InputGroup.Button>
    </InputGroup>
  )
}

export default React.memo(SearchInput)