import React from 'react'
import { Panel, Avatar } from 'rsuite'
import { PanelItem, TextBox } from './styles'

const Summary = React.memo(({ info = {}, style }) => {
  return (
    <Panel bordered bodyFill style={{ padding: '12px 12px 20px 12px', ...style }}>
      <PanelItem style={{ marginBottom: 6 }}>
        <Avatar size="lg" circle src={info.profile_image_url} />
      </PanelItem>
      <PanelItem style={{ flexDirection: 'column', textAlign: 'center', marginBottom: 16 }}>
        <span style={{ fontWeight: 'bold' }}>{info.first_name}</span>
      </PanelItem>
      <PanelItem>
        <TextBox>
          <div style={{ fontWeight: 'bold' }}>{info.rating || '-'}</div>
          <div style={{ fontSize: '.8em' }}>評分</div>
        </TextBox>
        <TextBox>
          <div style={{ fontWeight: 'bold' }}>{info.completed_trips}</div>
          <div style={{ fontSize: '.8em' }}>行程</div>
        </TextBox>
      </PanelItem>
    </Panel>
  )
})

export default Summary
