import React, {useState} from 'react'
import {getCompanyById, putCompany} from "../../../api";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
class CompanyDetail extends React.Component {
    state = {
        id: '',
        account: '',
        name: '',
        tax_num: '',
        virtual_account: '',
        nick_name: '',
        isBlocked: '未封鎖',
        description: '',
        postal_code: '',
        address: '',
        email: '',
        quota: '',
        deposit: '',
        telephone_num: '',
        mobile_num: '',
        pending_statement_month: '',
        pending_statement_day: '',
        blockedStatuses: ['未封鎖', '封鎖'],
        paid_date: '',
        deposit_note: '',
    }

    componentDidMount() {
        this.getCompany()
    }

    getCompany = async () => {
        const data = await getCompanyById(this.props.company_id)
        const [company] = data.data.data.company
        // console.log('companys' + JSON.stringify(company))
        if (data.status !== 200) return
        this.setState({
            id: company.id,
            account: company.account,
            name: company.name,
            tax_num: company.tax_num,
            virtual_account: company.virtual_account,
            nick_name: company.nick_name,
            isBlocked: (company.isBlocked == 0 ? '未封鎖' : '封鎖'),
            description: company.description,
            postal_code: company.postal_code,
            address: company.address,
            email: company.email,
            quota: company.quota,
            deposit: company.deposit,
            telephone_num: company.telephone_num,
            mobile_num: company.mobile_num,
            pending_statement_month: company.pending_statement_month,
            pending_statement_day: company.pending_statement_day,
            paid_date: company.paid_date,
            deposit_note: company.deposit_note,
            deposit_log: company.deposit_log
        })
    }


    updateCompany = async () => {
        const body = {
            account: this.state.account,
            name: this.state.name,
            tax_num: this.state.tax_num,
            virtual_account: this.state.virtual_account,
            nick_name: this.state.nick_name,
            isBlocked: (this.state.isBlocked == '未封鎖') ? 0 : 1,
            description: this.state.description,
            postal_code: this.state.postal_code,
            address: this.state.address,
            email: this.state.email,
            quota: this.state.quota,
            deposit: this.state.deposit,
            telephone_num: this.state.telephone_num,
            mobile_num: this.state.mobile_num,
            pending_statement_month: this.state.pending_statement_month,
            pending_statement_day: this.state.pending_statement_day,
            paid_date: this.state.paid_date,
            deposit_note: this.state.deposit_note
        }

        const result = await putCompany(this.state.id, body)
        console.log('result ' + JSON.stringify(result))
        if (result.status !== 200) return
        window.location.reload()
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
            [name]: value
        })
    }

    render() {
        return (

            <div>
                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <label htmlFor="inputFleetName" className="col-sm-2 control-label">ID</label>
                    <div className="col-sm-10">
                        <label className="col-sm-2 control-label">{this.state.id}</label>
                    </div>
                </div>
                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <label htmlFor="inputFleetName" className="col-sm-2 control-label">帳號</label>
                    <div className="col-sm-10">
                        <label className="col-sm-2 control-label">{this.state.account}</label>
                    </div>
                </div>
                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <label htmlFor="inputFleetName" className="col-sm-2 control-label">全名</label>
                    <div className="col-sm-10">
                        <TextField
                            name="name"
                            className="form-control"
                            id="name"
                            placeholder="全名"
                            value={this.state.name}
                            onChange={this.handleInputChange}/>
                    </div>
                </div>
                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <label htmlFor="inputFleetName" className="col-sm-2 control-label">統一編號</label>
                    <div className="col-sm-10">
                        <TextField
                            name="tax_num"
                            className="form-control"
                            id="tax_num"
                            placeholder="統一編號"
                            value={this.state.tax_num}
                            onChange={this.handleInputChange}/>
                    </div>
                </div>

                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <label htmlFor="inputFleetName" className="col-sm-2 control-label">虛擬帳戶</label>
                    <div className="col-sm-10">
                        <TextField
                            name="virtual_account"
                            className="form-control"
                            id="virtual_account"
                            placeholder="虛擬帳戶"
                            value={this.state.virtual_account}
                            onChange={this.handleInputChange}/>
                    </div>
                </div>
                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <label htmlFor="inputFleetName" className="col-sm-2 control-label">簡稱</label>
                    <div className="col-sm-10">
                        <TextField
                            name="nick_name"
                            className="form-control"
                            id="nick_name"
                            placeholder="簡稱"
                            value={this.state.nick_name}
                            onChange={this.handleInputChange}/>
                    </div>
                </div>


                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <label htmlFor="inputFleetName" className="col-sm-2 control-label">說明</label>
                    <div className="col-sm-10">
                        <TextField
                            name="description"
                            className="form-control"
                            id="description"
                            placeholder="說明"
                            defaultValue='負責人-Amber'
                            value={this.state.description}
                            onChange={this.handleInputChange}/>
                    </div>
                </div>
                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <div className="col-sm-10">
                        <TextField
                            id="standard-select-user"
                            select
                            label="用戶選擇"
                            value={this.state.isBlocked}
                            onChange={this.handleChange('isBlocked')}
                            margin="normal"
                        >
                            {this.state.blockedStatuses.map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </div>
                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <label htmlFor="inputFleetName" className="col-sm-2 control-label">郵遞區號</label>
                    <div className="col-sm-10">
                        <TextField
                            name="postal_code"
                            className="form-control"
                            id="postal_code"
                            placeholder="郵遞區號"
                            value={this.state.postal_code}
                            onChange={this.handleInputChange}/>
                    </div>
                </div>
                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <label htmlFor="inputFleetName" className="col-sm-2 control-label">地址</label>
                    <div className="col-sm-10">
                        <TextField
                            name="address"
                            className="form-control"
                            id="address"
                            placeholder="地址"
                            value={this.state.address}
                            onChange={this.handleInputChange}/>
                    </div>
                </div>

                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <label htmlFor="inputFleetName" className="col-sm-2 control-label">公司郵件</label>
                    <div className="col-sm-10">
                        <TextField
                            name="email"
                            className="form-control"
                            id="email"
                            placeholder="公司郵件"
                            value={this.state.email}
                            onChange={this.handleInputChange}/>
                    </div>
                </div>
                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <label htmlFor="inputFleetName" className="col-sm-2 control-label">總預算</label>
                    <div className="col-sm-10">
                        <TextField
                            name="quota"
                            className="form-control"
                            id="quota"
                            placeholder="總預算"
                            value={this.state.quota}
                            onChange={this.handleInputChange}/>
                    </div>
                </div>
                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <label htmlFor="inputFleetName" className="col-sm-2 control-label">保證金</label>
                    <div className="col-sm-10">
                        <TextField
                            name="deposit"
                            className="form-control"
                            id="deposit"
                            placeholder="保證金"
                            value={this.state.deposit}
                            onChange={this.handleInputChange}/>
                    </div>
                </div>

                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <label htmlFor="inputFleetName" className="col-sm-2 control-label">保證金入帳時間(YYYY-MM-DD)</label>
                    <div className="col-sm-10">
                        <TextField
                            name="paid_date"
                            className="form-control"
                            id="paid_date"
                            placeholder="YYYY-MM-DD"
                            value={this.state.paid_date}
                            onChange={this.handleInputChange}/>
                    </div>
                </div>

                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <label htmlFor="inputFleetName" className="col-sm-2 control-label">保證金註記</label>
                    <div className="col-sm-10">
                        <TextField
                            name="deposit_note"
                            className="form-control"
                            id="deposit_note"
                            placeholder="保證金註記"
                            value={this.state.deposit_note}
                            onChange={this.handleInputChange}/>
                    </div>
                </div>

                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <label htmlFor="inputFleetName" className="col-sm-2 control-label">室內電話</label>
                    <div className="col-sm-10">
                        <TextField
                            name="telephone_num"
                            className="form-control"
                            id="telephone_num"
                            placeholder="室內電話"
                            value={this.state.telephone_num}
                            onChange={this.handleInputChange}/>
                    </div>
                </div>
                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <label htmlFor="inputFleetName" className="col-sm-2 control-label">手機</label>
                    <div className="col-sm-10">
                        <TextField
                            name="mobile_num"
                            className="form-control"
                            id="mobile_num"
                            placeholder="手機"
                            value={this.state.mobile_num}
                            onChange={this.handleInputChange}/>
                    </div>
                </div>


                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <label htmlFor="inputFleetName" className="col-sm-2 control-label">未結帳-封鎖間隔月</label>
                    <div className="col-sm-10">
                        <TextField
                            name="pending_statement_month"
                            className="form-control"
                            id="pending_statement_month"
                            placeholder="未結帳-封鎖間隔月"
                            value={this.state.pending_statement_month}
                            onChange={this.handleInputChange}/>
                    </div>
                </div>

                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <label htmlFor="inputFleetName" className="col-sm-2 control-label">未結帳-封鎖日</label>
                    <div className="col-sm-10">
                        <TextField
                            name="pending_statement_day"
                            className="form-control"
                            id="pending_statement_day"
                            placeholder="未結帳-封鎖日"
                            value={this.state.pending_statement_day}
                            onChange={this.handleInputChange}/>
                    </div>
                </div>

                <div className="form-group" style={{padding: 20 + 'px'}}>
                    <button type="button" className="btn btn-block btn-default" onClick={this.updateCompany}>更新公司
                    </button>
                </div>
            </div>
        )
    }

}

export default CompanyDetail
