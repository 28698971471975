import React from 'react'
import { Icon } from 'rsuite'
import { PreviewShareSuccessPageWrapper } from './styles'
import defaultTheme from './theme'

const PreviewShareSuccessPage = ({ theme = defaultTheme }) => {
  return (
    <PreviewShareSuccessPageWrapper theme={theme}>
      <div className="top">
        <div className="icon">
          <Icon icon="chevron-left" />
        </div>
        <div className="icon">
          <Icon icon="close" />
        </div>
      </div>
      <div className="body">
        <div className="cover" />
        <div className="title">分享成功</div>
        <div className="content">你還可以繼續分享給其他朋友喔！</div>
        <div className="solid-button">繼續分享給其他朋友</div>
      </div>
    </PreviewShareSuccessPageWrapper>
  )
}

export default PreviewShareSuccessPage
