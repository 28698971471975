import dayjs from 'dayjs'

export const DEFAULT_EVENT_DATA = {
  title: '',
  content: '',
  href: '',
  published: 1,
  users: '',
  start_time: '',
  end_time: '',
  type: 'EVENT',
}
export const DEFAULT_CONTENT_DATA = [
  {
    event: '',
    type: 'BOX',
    title: '',
    body: '',
    row: 0,
    column: 0,
    flex: 1,
    info: null,
    query: '',
    idKey: dayjs().valueOf(),
  },
]

export const VERIFY_STEP_DATA = {
  1: ['title', 'content', 'users', 'start_time', 'end_time', 'type'],
  2: ['event', 'type', 'title', 'body', 'query'],
}
