import React from 'react'
import styled from 'styled-components'
import { IconButton, Icon, Divider } from 'rsuite'
import { Cell } from 'rsuite-table'

const ActionCellWrap = styled(Cell)`
	& > div {
		display: flex;
		justify-content: center;
		align-items: center;	
	}
`

const ActionCell = ({ rowData, dataKey, toggleAddMode, handleCopyActivity, handleDeleteActivity, ...props }) => {

	const handleCopyAction = () => {
		handleCopyActivity(rowData)
		toggleAddMode()
	}

	const handleDeleteAction = () => {
		handleDeleteActivity(rowData.id)
	}


	return (
		<ActionCellWrap {...props}>
			<IconButton
				appearance="subtle"
				onClick={handleCopyAction}
				icon={<Icon icon="copy-o" />}
			/>
			<Divider vertical />
			<IconButton
				appearance="subtle"
				onClick={handleDeleteAction}
				icon={<Icon icon="trash2" />}
			/>
		</ActionCellWrap>
	)
}

export default ActionCell