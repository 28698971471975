import styled from 'styled-components'

export const Root = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Heading = styled.h6`
  color: #4b4d4c;
  margin-bottom: 20px;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 0.5px;
    background-color: #a5a6a5;
    margin-top: 5px;
  }
`

export const Top = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Foot = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const HStack = styled.div`
  display: flex;
  gap: 8px;
`
