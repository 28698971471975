import { nameFilter, periodFilter} from './filters'
import { isEmpty } from 'lodash'

const filterRewards = (searchWord, isRequired, activities) => {
  if (!isRequired && isEmpty(searchWord)) return activities

  const activitiesByName = nameFilter(searchWord, activities)
  if (!isRequired) return activitiesByName
  const activitiesByPeriod = periodFilter(activitiesByName)
  return activitiesByPeriod
}

export { filterRewards }