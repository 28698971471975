import React from 'react'
import { isEmpty } from 'lodash'
import RadioBox from './RadioBox'
import { Dropdown, Button, IconButton, Icon, Uploader, Loader, InputPicker, CheckboxGroup } from 'rsuite'
import DropdownItem from './DropdownItem'

function FormGrop({ title, value, placeholder, type, options, icon, index, multiple, currentValue, handleOnChange, handleOnUpload, children, setStateKey, errorMessage, handleOnblur, min, max, isLoading }) {
	return (
		<div className="add-formGrop">
			<div className="add-title-wrap">
				<div className="subtitle_0">{title}</div>
				{errorMessage && <div className="title-error caption_01"><Icon icon={'warning'} style={{ color: '#f44336' }} /><span>{errorMessage}</span></div>}
			</div>
			{type === 'input' && <input className="add-list-input" type="text" placeholder={placeholder} onChange={(e) => handleOnChange(e, setStateKey, index)} value={value} onBlur={(e) => handleOnblur && handleOnblur(e, setStateKey, index)} />}
			{type === 'number' && <input className="add-list-input" type="number" placeholder={placeholder} onChange={(e) => handleOnChange(e, setStateKey, index)} value={value} onBlur={(e) => handleOnblur && handleOnblur(e, setStateKey, index)} min={min} max={max} />}
			{type === 'select-button' && (
				<Dropdown value={value} title={value || placeholder} trigger={'click'} menuStyle={{ minWidth: '100%' }} onSelect={(keyIndex, event) => handleOnChange(keyIndex, event, index)}>
					<DropdownItem options={isEmpty(options.filter(o => o.value)) ? options.map(r => ({ value: r })) : options} />
				</Dropdown>
			)}
      {type === 'input-picker' && (
        <InputPicker labelKey={'value'} valueKey={'value'} data={options} onChange={(value, event) => handleOnChange(value)} block preventOverflow={true} maxHeight={250}/>
      )}
			{type === 'radio' && <div className="control-radio-wrap">{options.map((item, index) => <RadioBox key={index} label={item.label} value={item.value} index={index} handleOnChange={handleOnChange} currentValue={currentValue} setStateKey={setStateKey} />)}</div>}
			{type === 'textarea' && <textarea placeholder={placeholder} value={value} onChange={(e) => handleOnChange(e, setStateKey)}></textarea>}
			{type === 'button' && (
				<div className="add-formGrop-button-wrap">
					{icon ? <IconButton block icon={<Icon icon={icon} />} onClick={handleOnChange}>{placeholder}</IconButton> : <Button icon={icon} block onClick={handleOnChange}>{placeholder}</Button>}
				</div>
			)}
			{type === 'uploader' && <Uploader listType="picture-text" fileListVisible={false} multiple={multiple} onUpload={handleOnUpload} action="//jsonplaceholder.typicode.com/posts/">
				{isLoading && <Loader />}
			</Uploader>}
			{type === 'checkbox' && <CheckboxGroup value={value} onChange={(e) => handleOnChange(e, setStateKey)} inline>{children}</CheckboxGroup>}
			{type === 'children' && children}
		</div>
	)
}

export default React.memo(FormGrop)
