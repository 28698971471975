/* eslint-disable consistent-return */
import React, { useMemo } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { Alert, Popover, Whisper, Button, Tag } from 'rsuite'
import OfflineInfo from './OfflineInfo'
import { cancelTripAdjustment } from '../../api'
import { handlePaymentMethod, handleTransactionStatus, handleAdjustmentStatus, handleTransactions } from '../../pages/trip_transaction/helper'

const TripContainer = styled.div`
  position: relative;
  justify-content: space-between;
  padding: 10px;
  border-radius: 4px;
  .trip-info {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: normal;
    &-column {
      width: 100px;
    }
  }
  button {
    margin: 0 10px;
  }
`
const TripDetailWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
  h6 {
    width: 100px;
  }
`
const RecordContainer = styled.div`
  display: flex;
  & > div {
    width: 48%;
    margin-right: 10px;
  }
`
const Record = styled.div`
  position: relative;
  width: 100%;
  margin-right: 20px;
  margin-bottom: 20px;
  border: 2px solid #eee;
  border-radius: 7px;
  color: #757d85;
  padding: 15px;
`
const SectionTitle = styled.h5`
  margin-bottom: 10px;
  padding: 10px 5px;
  border-radius: 4px;
  background-color: #eee;
  font-size: 16px;
  text-align: center;
`
const TransactionTitle = styled.h6`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .rs-tag {
    margin-left: 10px;
  }
`
const RecordDetailWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
  h6 {
    font-size: 13px;
    width: 30%;
  }
`
const ReviewWrapper = styled.div`
  margin: 10px 0;
  border-radius: 7px;
  padding: 10px;
  min-width: 50%;
  width: max-content;
  background: #f5f4e8;
`
const SubmitWrapper = styled(ReviewWrapper)`
  background-color: white;
  border: 1px solid #eeeeee;
`
const RecordButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 15px;
  right: 15px;
`
const TripDetail = ({ title, value, children }) => {
  return (
    <TripDetailWrapper>
      <h6>{title}</h6>
      {value && <p>{value}</p>}
      {children && children}
    </TripDetailWrapper>
  )
}
const RecordDetail = ({ title, value, children }) => {
  return (
    <RecordDetailWrapper>
      <h6>{title}</h6>
      {value && <p>{value}</p>}
      {children && children}
    </RecordDetailWrapper>
  )
}
const TransactionCard = ({ data, isCancelFee }) => {
  const { order_id, transaction_id, payment_method, amount, status, created_time } = data
  return (
    <Record>
      <TransactionTitle>
        {handlePaymentMethod(payment_method)}
        <Tag size="sm" color={handleTransactionStatus(status).color}>
          {handleTransactionStatus(status).text}
        </Tag>
        {isCancelFee && <Tag size="sm">取消費用</Tag>}
      </TransactionTitle>
      <RecordDetail title="交易編號" value={order_id} />
      <RecordDetail title="金流編號" value={transaction_id} />
      <RecordDetail title="金額" value={`$${amount}`} />
      <RecordDetail title="交易時間" value={dayjs(created_time * 1000).format('YYYY-MM-DD HH:mm:ss')} />
    </Record>
  )
}

const TripInfo = ({ tripData, handleApply, handleApplyCompany, fetchList, fetchTripData, setReview }) => {
  const { tripInfo, adjustment } = tripData
  const { basic, coupons, driver_service_fee, t_membership_mileage, transaction_summary, cancel_fee_summary } = tripInfo

  const handleCancelAdjustment = async (id) => {
    if (!id) return
    const response = await cancelTripAdjustment(id)
    if (response.status !== 200) return Alert.error(response.data.msg)
    const { trip_id } = basic
    fetchTripData(trip_id)
    fetchList()
  }

  const renderCoupon = (coupons = []) => {
    const coupon = coupons[coupons.length - 1]
    if (!coupon) return null
    const { coupon_id, promo_title, coupon_amount, coupon_max_amount, is_for_cash, redeem_value } = coupon
    const speaker = (
      <Popover title="優惠券明細">
        <div className="coupon-popover">
          <p>{`Coupon ID: ${coupon_id}`}</p>
          <p>{`${promo_title}`}</p>
          <p>
            {`優惠：${coupon_amount < 1 ? `${coupon_amount * 10}折` : `${coupon_amount}元`}（${
              coupon_max_amount ? `最高上限 ${coupon_max_amount}` : ''
            } ${is_for_cash ? '可使用現金' : ''}）`}
          </p>
        </div>
      </Popover>
    )
    return (
      <Whisper placement="right" speaker={speaker}>
        <p>{isEmpty(coupon) ? '' : `${promo_title} $${redeem_value}`}</p>
      </Whisper>
    )
  }

  const showApplyButton = useMemo(() => {
    const { driver_id, trip_status } = basic
    // 1. TIMEOUT 搜車失敗的行程
    if (!driver_id) return false
    // 2. 已有取消費的取消行程
    const cancelFee = cancel_fee_summary?.total || 0
    if (trip_status === 'TRIP_CANCELED' && cancelFee > 0) return false
    return true
  }, [basic, cancel_fee_summary])

  const memoedIsShowCashback = useMemo(() => {
    if (!basic?.reserve_time) return false
    const month = dayjs().format('MM')
    const tripMonth = dayjs(basic?.reserve_time * 1000).format('MM')
    return month !== tripMonth
  }, [basic?.reserve_time])

  // LT-8842 新增取消費相關的交易紀錄
  const transactions = transaction_summary ? transaction_summary.details : []
  const cancelFeeTransactions = cancel_fee_summary ? cancel_fee_summary.details : []
  return (
    <>
      <TripContainer>
        <TripDetail title="行程 TID" value={basic ? basic.trip_id : ''}>
          {showApplyButton && (
            <Button style={{ background: '#1e201f', color: '#fff' }} size="xs" onClick={handleApply}>
              變更行程金流
            </Button>
          )}
          <Button disabled={basic?.payment_method === 'company'} size="xs" style={{ marginLeft: 0 }} onClick={handleApplyCompany}>
            變更為企業簽單
          </Button>
        </TripDetail>
        <TripDetail title="行程編號" value={basic?.trip_hash || ''} />
        <TripDetail title="司機 DID" value={basic?.driver_id || ''} />
        <TripDetail title="乘客 UID" value={basic?.user_id || ''} />
        <TripDetail title="總車資" value={`$${basic?.amount}`} />
        <TripDetail title="付款方式" value={handlePaymentMethod(basic?.payment_method || '')} />
        <TripDetail title="乘車券">{renderCoupon(coupons)}</TripDetail>
        <TripDetail title="司機派遣費" value={`$${driver_service_fee.length ? driver_service_fee[driver_service_fee.length - 1].fee : 0}`} />
        <TripDetail
          title="T里程"
          value={
            t_membership_mileage.length
              ? `乘客實際付款 $${t_membership_mileage[t_membership_mileage.length - 1].fare}，獲得 ${
                  t_membership_mileage[t_membership_mileage.length - 1].miles
                }T`
              : '無'
          }
        />
        {basic?.trip_status === 'TRIP_CANCELED' && <TripDetail title="取消費" value={`$${cancel_fee_summary?.total || 0}`} />}
      </TripContainer>
      <RecordContainer>
        <div>
          <SectionTitle>交易紀錄</SectionTitle>
          {transactions && transactions.map((data) => <TransactionCard key={`${data.order_id}-${data.transaction_id}`} data={data} />)}
          {cancelFeeTransactions &&
            cancelFeeTransactions.map((data) => <TransactionCard key={`${data.order_id}-${data.transaction_id}`} data={data} isCancelFee />)}
        </div>
        <div>
          <SectionTitle>變更紀錄</SectionTitle>
          {adjustment &&
            adjustment.map((data) => {
              const {
                id,
                trip_id,
                before,
                after,
                status,
                created_at,
                submitted_name,
                submit_note,
                reviewed_at,
                reviewed_by,
                reviewed_name,
                review_note,
              } = data
              const beforeData = JSON.parse(before)
              const afterData = JSON.parse(after)
              const isInvalid = status === 'CANCELLED' || status === 'REJECTED' // 申請取消或失敗，就不必 highlight 調整結果
              const { refund, collectable, offline } = handleTransactions(afterData.transactions)
              const { refund: cancelFeeRefund, collectable: cancelFeeCollectable } = handleTransactions(afterData.cancel_fee_transactions)

              return (
                <Record key={`${id}-${trip_id}`}>
                  <Tag style={{ marginBottom: '10px' }} color={handleAdjustmentStatus(status).color}>
                    {handleAdjustmentStatus(status).text}
                  </Tag>
                  {status === 'PENDING' && (
                    <RecordButtonsWrapper>
                      <Button size="sm" color="red" appearance="ghost" style={{ marginBottom: 10 }} onClick={() => handleCancelAdjustment(id)}>
                        撤銷申請
                      </Button>
                      <Button size="sm" color="red" appearance="default" onClick={() => setReview(true)}>
                        主管審核
                      </Button>
                    </RecordButtonsWrapper>
                  )}
                  {beforeData.trip_status !== afterData.trip_status && (
                    <RecordDetail title="行程狀態" value={`${beforeData.trip_status} ➞ ${afterData.trip_status}`} />
                  )}
                  {beforeData.payment_method !== afterData.payment_method && (
                    <RecordDetail
                      title="付款方式"
                      value={`${handlePaymentMethod(beforeData.payment_method)} ➞ ${handlePaymentMethod(afterData.payment_method)}`}
                    />
                  )}
                  <RecordDetail title="總車資" value={`$${beforeData.trip_fare} ➞ $${afterData.trip_fare}`} />
                  <RecordDetail title="乘車券" value={`${beforeData.coupon_id || '無'} ➞ ${afterData.coupon_id || '無'}`} />
                  <RecordDetail title="優惠券折抵金額" value={`$${beforeData.coupon || 0} ➞ $${afterData.coupon || 0}`} />
                  <RecordDetail title="司機派遣費" value={`$${beforeData.service_fee} ➞ $${afterData.service_fee}`} />
                  {memoedIsShowCashback &&  <RecordDetail title="車資回饋金" value={`$${beforeData.grading_cashback || 0} ➞ $${afterData.grading_cashback || 0}`} />}
                  <RecordDetail title="T里程" value={`${beforeData.mileage || 0} ➞ ${afterData.mileage || 0}`} />
                  <RecordDetail
                    title="應付車資"
                    value={`$${beforeData.trip_fare - (beforeData.coupon || 0)} ➞ $${Number(afterData.trip_fare) - (afterData.coupon || 0)}`}
                  />
                  {!!(beforeData.total_online || afterData.total_online) && (
                    <RecordDetail
                      title="調整刷卡金額"
                      value={`已付 $${beforeData.total_online}${
                        beforeData.total_online !== afterData.total_online ? ` ➞ $${afterData.total_online}` : ''
                      }`}
                    />
                  )}
                  {!!(beforeData.total_cash || afterData.total_cash) && (
                    <RecordDetail
                      title="調整現金"
                      value={`已付 $${beforeData.total_cash}${beforeData.total_cash !== afterData.total_cash ? ` ➞ $${afterData.total_cash}` : ''}`}
                    />
                  )}
                  {!!(beforeData.total_company || afterData.total_company) && (
                    <RecordDetail
                      title="企業簽單金額"
                      value={`$${beforeData.total_company}${
                        beforeData.total_company !== afterData.total_company ? ` ➞ $${afterData.total_company}` : ''
                      }`}
                    />
                  )}
                  {!!(beforeData.total_collectable || afterData.total_collectable) && (
                    <RecordDetail
                      title="平台代墊金額"
                      value={`$${beforeData.total_collectable}${
                        beforeData.total_collectable !== afterData.total_collectable ? ` ➞ $${afterData.total_collectable}` : ''
                      }`}
                    />
                  )}
                  <>
                    {!isEmpty(offline) && (
                      <RecordDetail title="路招付款">
                        <OfflineInfo transaction={offline} />
                      </RecordDetail>
                    )}
                    {!!refund && (
                      <RecordDetail title="應退刷金額">
                        <p style={{ color: isInvalid ? 'gray' : 'red', textDecoration: isInvalid ? 'line-through' : 'none' }}>{`退 -$${refund}`}</p>
                      </RecordDetail>
                    )}
                    {!!collectable && (
                      <RecordDetail title="應補刷金額">
                        <p style={{ color: isInvalid ? 'gray' : 'green', textDecoration: isInvalid ? 'line-through' : 'none' }}>
                          {`收 $${collectable}`}
                        </p>
                      </RecordDetail>
                    )}
                    {!!cancelFeeRefund && (
                      <RecordDetail title="應退刷金額(取消費)">
                        <p style={{ color: isInvalid ? 'gray' : 'red', textDecoration: isInvalid ? 'line-through' : 'none' }}>
                          {`退 -$${cancelFeeRefund}`}
                        </p>
                      </RecordDetail>
                    )}
                    {!!cancelFeeCollectable && (
                      <RecordDetail title="應補刷金額(取消費)">
                        <p style={{ color: isInvalid ? 'gray' : 'green', textDecoration: isInvalid ? 'line-through' : 'none' }}>
                          {`收 $${cancelFeeCollectable}`}
                        </p>
                      </RecordDetail>
                    )}
                  </>
                  <SubmitWrapper>
                    <RecordDetail title="申請人" value={submitted_name} />
                    <RecordDetail title="申請原因" value={submit_note} />
                    <RecordDetail title="申請日期" value={dayjs(created_at).format('YYYY-MM-DD HH:mm')} />
                  </SubmitWrapper>
                  {reviewed_by && (
                    <ReviewWrapper>
                      <RecordDetail title="審核人" value={reviewed_name} />
                      <RecordDetail title="審核原因" value={review_note} />
                      <RecordDetail title="審核日期" value={dayjs(reviewed_at).format('YYYY-MM-DD HH:mm')} />
                    </ReviewWrapper>
                  )}
                </Record>
              )
            })}
        </div>
      </RecordContainer>
    </>
  )
}

export default React.memo(TripInfo)
