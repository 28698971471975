import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { IconButton, Icon, InputPicker } from 'rsuite'
import { isFunction } from 'lodash'
import { CreateWrapper, CreateClose, CreateTitle, CreateInput } from './styles'
import FlexMessageModel from './FlexMessageModel'
import ModelDemo from '../../components/campaign/ModelDemo'
import ImageUploader from '../../components/dama/ImageUploader'

const ACTIONS_INPUT_PICKER_DATA = [
  {
    label: 'Uri',
    value: 'uri',
  },
  {
    label: 'Message',
    value: 'message',
  },
]
const ACTIONS = {
  a: [{ title: 'Action 1', type: '', id: 1, area: { x: 0, y: 0, width: 1040, height: 1040 } }],
  b: [
    { title: 'Action 1', type: '', id: 1, area: { x: 0, y: 0, width: 520, height: 1040 } },
    { title: 'Action 2', type: '', id: 2, area: { x: 520, y: 0, width: 520, height: 1040 } },
  ],
  c: [
    { title: 'Action 1', type: '', id: 1, area: { x: 0, y: 0, width: 346, height: 1040 } },
    { title: 'Action 2', type: '', id: 2, area: { x: 346, y: 0, width: 347, height: 1040 } },
    { title: 'Action 3', type: '', id: 3, area: { x: 693, y: 0, width: 347, height: 1040 } },
  ],
}

const StyledInputPicker = styled(InputPicker)`
  margin-top: 5px;
`
const StyledInput = styled(CreateInput)`
  margin-top: 5px;
`
const Wrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 25px;
  }
`
const ActionInputWrapper = styled.div`
  margin-top: 20px;
`
const Remind = styled.div`
  font-size: 12px;
  line-height: 1.5;
  font-weight: 700;
  color: #00c299;
  margin-top: 5px;
`

const CreateFlexMessage = React.memo(
  ({ handleFilterActions, handleOnChangeFlexMessage, title, imgUrl, id, setFlexMessage, actions, handlePostImage }) => {
    const [displayModel, setModelDisplay] = useState(false)
    const [activeModel, setActiveModel] = useState('')
    const [currentActions, setActions] = useState(ACTIONS)

    const handleOnChangeActionInput = (value, actionId, type) => {
      const newActions = currentActions[activeModel].map((item) => ({ ...item, [type]: item.id === actionId ? value : item[type] }))
      const flexMessage = actions.map((item) => (item.id === id ? { ...item, actions: newActions } : item))
      setActions({ ...currentActions, [activeModel]: newActions })
      setFlexMessage(flexMessage)
    }

    const handleModel = (model) => {
      setActiveModel(model)
      const currentActions = actions.map((item) => (item.id === id ? { ...item, actions: ACTIONS[model] } : item))
      setFlexMessage(currentActions)
    }
    const handleOnUpload = useCallback(
      async (file) => {
        if (!isFunction(handlePostImage)) return
        const data = await handlePostImage(file)
        if (data) handleOnChangeFlexMessage(data.imageMap, 'imgUrl', id)
      },
      [handlePostImage, handleOnChangeFlexMessage, id],
    )
    return (
      <>
        <FlexMessageModel displayModel={displayModel} setActiveModel={handleModel} setModelDisplay={setModelDisplay} />
        <CreateWrapper>
          <CreateClose onClick={handleFilterActions} />
          <Wrapper>
            <CreateTitle>標題</CreateTitle>
            <StyledInput placeholder="請輸入標題" value={title} onChange={(value) => handleOnChangeFlexMessage(value, 'title', id)} />
          </Wrapper>
          <Wrapper>
            <CreateTitle>圖片網址</CreateTitle>
            <StyledInput placeholder="請上傳圖片" value={imgUrl || ''} />
            <ImageUploader handleImageUpload={handleOnUpload} handleRemove={() => handleOnChangeFlexMessage('', 'imgUrl', id)} />
          </Wrapper>
          <Wrapper>
            <IconButton block appearance="default" icon={<Icon icon="web" />} onClick={() => setModelDisplay(true)}>
              {activeModel ? `選擇版型${activeModel}` : '選擇版型'}
            </IconButton>
          </Wrapper>
          {activeModel && (
            <Wrapper>
              <CreateTitle>{`版型 ${activeModel} 範例`}</CreateTitle>
              {activeModel && <ModelDemo currentLineBotModel={activeModel} />}
            </Wrapper>
          )}
          {activeModel &&
            currentActions[activeModel].map((item) => (
              <Wrapper key={item.id}>
                <CreateTitle>{item.title}</CreateTitle>
                <StyledInputPicker
                  data={ACTIONS_INPUT_PICKER_DATA}
                  block
                  preventOverflow
                  placeholder="選擇 Action"
                  onSelect={(value) => handleOnChangeActionInput(value, item.id, 'type')}
                />
                {item.type && (
                  <ActionInputWrapper>
                    <CreateTitle>{`Action ${item.type}`}</CreateTitle>
                    <StyledInput
                      placeholder={item.type === 'uri' ? '請輸入網址' : '請輸入對應的訊息'}
                      value={item.value || ''}
                      onChange={(value) => handleOnChangeActionInput(value, item.id, 'value')}
                    />
                    {item.type === 'Message' && <Remind>*選擇 Message 模式請記得告知工程部門</Remind>}
                  </ActionInputWrapper>
                )}
              </Wrapper>
            ))}
        </CreateWrapper>
      </>
    )
  },
)

export default CreateFlexMessage
