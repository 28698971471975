import React, { memo } from 'react'
import { Tag } from 'rsuite'
import { Cell } from 'rsuite-table'

const getTagInfo = (type) => {
  switch (type) {
    case 'SUCCESS':
      return {
        name: '已開立',
        color: 'green',
      }
    case 'INVALIDATE':
      return {
        name: '已作廢',
        color: 'red',
      }
    case 'CANCELLED':
      return {
        name: '已取消',
        color: 'blue',
      }
    default:
      return {
        name: '未開立',
        color: '',
      }
  }
}

export const InvoiceStatusTag = ({ rowData, dataKey, ...props }) => {
  const { invoice_id, invoiceStatus, invoiceNumber } = rowData
  const { color, name } = getTagInfo(invoiceStatus)
  return <Cell {...props}>{invoice_id && invoiceStatus === 'SUCCESS' ? invoiceNumber : <Tag color={color}>{name}</Tag>}</Cell>
}

export default memo(InvoiceStatusTag)
