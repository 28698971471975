import React, { useState } from 'react'
import { Modal, Button, Input } from 'rsuite'
import { FormGroup } from '../blocked_history/styles'

const AddModal = React.memo(({ show, close, confirm, driver }) => {
  const [tripId, setTripId] = useState('')
  const [note, setNote] = useState('')

  const submit = () => {
    confirm({
      tripId,
      note,
    })
  }

  const payloadIsReady = !!tripId

  return (
    <Modal backdrop={true} show={show} onHide={close} size="sm">
      <Modal.Header>
        <Modal.Title>建立司機取消預約單</Modal.Title>
        {driver && <p style={{ marginTop: '10px' }}>司機ID: {driver.uid}</p>}
      </Modal.Header>
      {driver && (
        <Modal.Body style={{ marginTop: '10px' }}>
          <FormGroup>
            <FormGroup.Label>行程 ID</FormGroup.Label>
            <FormGroup.Control>
              <Input style={{ width: 200 }} value={tripId} onChange={setTripId} placeholder="請輸入行程ID" />
            </FormGroup.Control>
          </FormGroup>
          <FormGroup>
            <FormGroup.Label>封鎖原因</FormGroup.Label>
            <FormGroup.Control>
              <Input value={note} onChange={setNote} componentClass="textarea" rows={3} placeholder="請輸入封鎖原因" />
            </FormGroup.Control>
          </FormGroup>
        </Modal.Body>
      )}
      <Modal.Footer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={close} appearance="subtle">
            取消
          </Button>
          <Button disabled={!payloadIsReady} onClick={submit} color="red">
            建立
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
})

export default AddModal
