const modelData = ['a', 'b', 'c']

const parseContent = (content) => {
	const contentData = JSON.parse(content)
	const result = {}
	contentData.forEach((item) => {
		if (item.type === 'text') {
			result.lineText = item.text
		} else if (item.type === 'imagemap') {
			result.baseUrl = item.baseUrl
			result.model = modelData[item.actions.length - 1]
			result.actions = item.actions.map((action) => {
				return action.type === 'message'
					? { type: action.type, text: action.text, area: action.area }
					: { type: action.type, text: action.linkUri, area: action.area }
			})
		}
	})
	return result
}

export default parseContent
