import React from 'react'
import { useHistory } from 'react-router-dom'
// import { ContextStore } from '../../reducers'
import { Button, Icon, Header, Popover, Whisper, Avatar } from 'rsuite'
import { AuthServices } from '../../api'

const Speaker = React.memo(({ user, ...props }) => {
  const history = useHistory()
  const handleResetPasswordClick = () => history.push('/reset_password')
  const handleLogout = async () => {
    await AuthServices.postSignOut()
    window.location.href = '/login'
  }
  return (
    user && (
      <Popover {...props}>
        <div className="pop-profile-top">
          <Avatar circle size="lg" src={user.profile_image_url} />
          <h5 className="name">{user.name}</h5>
          <div className="role">{user.role}</div>
          <div className="subtitle_02">{user.email}</div>
        </div>
        <div className="pop-profile-button">
          <Button appearance="link" onClick={handleResetPasswordClick}>
            變更密碼
          </Button>
          <Button appearance="subtle" onClick={handleLogout}>
            登出
          </Button>
        </div>
      </Popover>
    )
  )
})
const HeaderModel = React.memo(({ user }) => {
  return (
    <Header className="header-wrap">
      <Whisper trigger="click" placement="bottomEnd" speaker={<Speaker user={user} />}>
        <Avatar circle size="sm" src={user && user.profile_image_url}>
          {!user && <Icon icon="user-circle-o" />}
        </Avatar>
      </Whisper>
    </Header>
  )
})

export default HeaderModel
