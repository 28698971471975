import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { isEmpty } from 'lodash'
import { ContextStore } from '../../reducers'
import AddListModel from '../../components/views/AddListModel'
import FormGrop from '../../components/views/FormGrop'
import AddListGrop from '../../components/views/AddListGrop'
import PopWarning from '../../components/popWarning'
import { getVegBoxDriver, putVegBoxDriverSwitch, postVegBoxDriverInventory, getVegBoxItems } from '../../api'

const ItemInventory = styled.span`
	margin-left: 10px;
`

const VegboxControl = () => {
  const { auth } = useContext(ContextStore)
  const [warning, setWarning] = useState('')
  const [driverID, setDriverID] = useState('')
  const [line_taxi, setLINETAXI] = useState(false)
  const [line_taxi_plus, setLINETAXIPlus] = useState(false)
  const [veg_box, setVEGBOX] = useState(false)
  const [vehicle_type, setVehicleType] = useState(null)
  const [inventory, setInventory] = useState([])
  const [newInventory, setNewInventory] = useState(0)
  const [vegItems, setVegItems] = useState([])
  const [vegItem, setVegItem] = useState('')


  useEffect(() => {
  }, [auth])


	const handleDriverIDOnChange = (e) => {
		const value = e.target ? e.target.value : e
		setDriverID(value)
	}

	const handleNewInventory = (e) => {
		const value = e.target ? e.target.value : e
		setNewInventory(value)
	}

  const handleGetDriverInfo = async () => {
    if (!driverID) return

    const vegDriverResult = await getVegBoxDriver(driverID)
    const vegItemsResult = await getVegBoxItems()
    console.log('vegDriverResult', vegDriverResult)
    if (vegDriverResult.status !== 200 || vegItemsResult.status !== 200) return setWarning('網路不穩，請稍後再試')
    const { line_taxi, line_taxi_plus, veg_box, vehicle_type, inventory } = vegDriverResult.data
    const { items = [] } = vegItemsResult.data
    const filterItems = items.map((item) => ({id: item.id, value: item.name}))
    warning && setWarning('')
    setLINETAXI(line_taxi)
    setLINETAXIPlus(line_taxi_plus)
    setVEGBOX(veg_box)
    setVehicleType(vehicle_type)
    setInventory(inventory)
    setVegItems(filterItems)
    !vegItem && setVegItem(filterItems[0])
  }

  const sumbitNewInventory = async () => {
    console.log(driverID)
    if (!driverID) return
    const { id } = vegItem
    const data = await postVegBoxDriverInventory(driverID, {
      inventory: newInventory,
      item: id
    })
    console.log('data', data)
    handleGetDriverInfo()
  }

  const switchMode = async () => {
    console.log(driverID)
    if (!driverID) return

    const data = await putVegBoxDriverSwitch(driverID)
    console.log('data', data)

    handleGetDriverInfo()
  }

  const handleVegItem = (keyIndex) => {
    setVegItem(vegItems[keyIndex])
  }

  return (
    <>
      {warning && <PopWarning warning={warning} textCenter={true} confirm={() => setWarning('')} />}
      <AddListModel >
        <AddListGrop title={'獲取報表'}>
          <FormGrop title={'司機ID'} placeholder={'Driver ID'} type={'input'} value={driverID}  handleOnChange={handleDriverIDOnChange} setStateKey={'driverID'} errorMessage={warning} />
          <div className="campaign-button-wrap">
            <button className="campaign-confirm-button" onClick={handleGetDriverInfo}>{'送出'}</button>
          </div>
        </AddListGrop>
        {vehicle_type && <AddListGrop title={'司機資訊'}>
        <div>當前模式：{line_taxi ? '小黃計程車' : (line_taxi_plus ? '多元計程車' : (veg_box ? '特派員模式' : ''))}</div>
        <div>車輛種類：{vehicle_type}</div>
        {inventory.map((item, index) => (
          <div key={index}>
            <span>商品紀錄：{item.name}</span>
            <ItemInventory>存貨：{item.balance}</ItemInventory>
          </div>
        ))}
        </AddListGrop>}
        {vehicle_type && <AddListGrop title={'設定存貨'}>
          <FormGrop title={'存貨數量'} placeholder={'10'} type={'input'} value={newInventory}  handleOnChange={handleNewInventory} setStateKey={'newInventory'} errorMessage={warning} />
          <FormGrop title={'商品紀錄'} placeholder={'Type'} type={'select-button'} value={isEmpty(vegItem) ? '選擇商品' : vegItem.value } options={vegItems} handleOnChange={handleVegItem} setStateKey={'vegItem'} errorMessage={warning} />
          <div className="campaign-button-wrap">
            <button className="campaign-confirm-button" onClick={sumbitNewInventory}>{'更新存貨或商品紀錄'}</button>
          </div>
          <div className="campaign-button-wrap">
            <button className="campaign-confirm-button" onClick={switchMode}>{'切換模式'}</button>
          </div>
        </AddListGrop>}
      </AddListModel>
    </>
  )
}

export default VegboxControl