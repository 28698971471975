import React, { Component } from 'react'
import { Modal, Button } from 'rsuite'
import { isEmpty } from 'lodash'
export class LineBotModel extends Component {
	state = {
		modelData: ['a', 'b', 'c'],
	}
	render() {
		const { currentLineBotModel, handleLineBotModel, toggleButtonModel, currentStatus } = this.props
		const { modelData } = this.state
		return (
			<Modal show={currentStatus} onHide={toggleButtonModel}>
				<Modal.Header>
					<Modal.Title>選擇版型</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="line-bot-model-container">
						{!isEmpty(modelData) && modelData.map((item, index) => (
							<div className={`line-bot-model ${item}`} key={index} data-model={modelData[index]} onClick={(e) => handleLineBotModel(e)}>
								<span>{`版型${item}`}</span>
								{currentLineBotModel === item && <img className="active" src="https://static.taxigo-cdn.com/icons/general/s-checkbox-checked.svg" alt="" />}
							</div>
						))}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={toggleButtonModel} appearance="primary">確認</Button>
				</Modal.Footer>
			</Modal>
		)
	}
}

export default LineBotModel
