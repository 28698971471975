import React from 'react'
import styled from 'styled-components'
import { IconButton, Icon, Divider } from 'rsuite'
import { Cell } from 'rsuite-table'

const ActionCellWrap = styled(Cell)`
	& > div {
		display: flex;
		justify-content: center;
		align-items: center;	
	}
`

const ActionCell = ({ rowData, dataKey, toggleAddMode, handleEditOrCopy, view, ...props }) => {
	const handleAction = (action) => {
		switch (action) {
			case 'copy':
				handleEditOrCopy(rowData, 'copy')
				toggleAddMode()
				break;
			case 'edit':
				handleEditOrCopy(rowData)
				toggleAddMode()
				break;
			default:
				break;
		}
	}

	return (
		<ActionCellWrap {...props}>
			<IconButton
				appearance="subtle"
				onClick={() => handleAction('edit')}
				icon={<Icon icon={view ? 'eye' : 'edit2'} />}
				/>
			<Divider vertical />
			<IconButton
				appearance="subtle"
				onClick={() => handleAction('copy')}
				icon={<Icon icon="copy-o" />}
			/>
		</ActionCellWrap>
	)
}

export default ActionCell
