const handlePaymentMethod = (method) => {
  switch (method) {
    case 'linepay':
      return 'LINE Pay'
    case 'linebank':
      return 'LINE Bank'
    case 'creditcard':
      return '信用卡'
    case 'collectable':
      return '平台代墊'
    case 'company':
      return '企業簽單'
    default:
      return '現金'
  }
}

export default handlePaymentMethod
