import React from 'react'
import styled from 'styled-components'
import { Tag, Icon } from 'rsuite'

const Wrapper = styled.div`
  width: 100%;
  height: min-content;
  flex: 1;
  padding: 0 1vw;
  .rs-steps-item-title {
    font-size: 14px;
  }
`
const InfoWrapper = styled.div`
  text-align: left;
  display: flex;
  margin: 5px;
`
const InfoTitle = styled.div`
  font-weight: 600;
  width: 35%;
`
const InfoDesc = styled.div`
  text-align: left;
  width: 65%;
`

const TripInfo = ({ title, desc, tag, icon }) => {
  return (
    <InfoWrapper>
      <InfoTitle>
        {icon && <Icon icon={icon} style={{ marginRight: '4px' }} />}
        {title}
      </InfoTitle>
      <InfoDesc>
        {desc}
        {tag && <Tag style={{ display: 'inline', marginLeft: '4px' }}>{tag}</Tag>}
      </InfoDesc>
    </InfoWrapper>
  )
}

const TripFare = React.memo(({ fare }) => {
  return (
    <Wrapper>
      {fare.fix_fare && (
        <>
          <TripInfo title="距離" desc={`${fare.distance} m (${fare.distance / 1000} km)`} />
        </>
      )}
      {fare.fix_fare && (
        <>
          <TripInfo title="時間" desc={`${fare.duration} sec (${fare.duration / 60} 分鐘)`} />
        </>
      )}
      {fare.fix_fare && (
        <>
          <TripInfo title="浮動倍率" desc={`${fare.fix_fare.surgeMultiplier} 倍`} />
        </>
      )}
      <hr />
      {fare.fix_fare && (
        <>
          <TripInfo title="多元車資" desc={`${fare.fix_fare.estimate}元`} />
        </>
      )}
      {fare.premium && (
        <>
          <TripInfo title="星選車資" desc={`${fare.premium.estimate}元`} />
        </>
      )}
      {fare.by_meter && (
        <>
          <TripInfo title="小黃車資" desc={`${fare.by_meter.estimate[0]}元 ~ ${fare.by_meter.estimate[2]}元`} />
        </>
      )}
    </Wrapper>
  )
})

export default TripFare
