import React from 'react'
import { isArray } from 'lodash'
import dayjs from 'dayjs'
import { DateRangePicker, SelectPicker, InputNumber, TagPicker, DatePicker, FlexboxGrid, Input } from 'rsuite'
import { Label } from '../styles'

import { DropdownGroup } from '../../../components/views'

// 維度的替代文字
const getDimensionPlaceholder = (type) => {
  switch (type) {
    case 'YEARMONTH':
    case 'DATE_PERIOD':
    case 'TIME_PERIOD':
      return '選擇期間'
    case 'REGION':
    case 'CITY':
      return '選擇區域'
    case 'USER_TRENDS':
      return '趟次變化'
    case 'COUPON_TYPE':
      return '選擇任一乘車券類型'
    case 'MAU_TYPE':
      return '選擇類型狀態'
    case 'VEHICLE_TYPE':
      return '選擇車種'
    case 'SUB_VEHICLE_TYPE':
      return '選擇車種'
    case 'PROMO_CODE_LIST':
      return '輸入優惠碼： promo_id,promo_id,promo_id'
    default:
      return '請選擇'
  }
}

const MultiSelector = ({ type, optionData, parameters, handleSelect, handleDatePeriod, handleYearmonth, property, disabled }) => {
  // 1 參數名稱
  // 2 篩選的值
  // 3 提示文字
  const value = parameters[property]

  const placeholder = getDimensionPlaceholder(type)

  const renderComponent = () => {
    const { start_date, end_date, operator, numeric_value, roi, trips } = parameters
    switch (type) {
      case 'DATE_PERIOD':
        return (
          <DateRangePicker
            value={start_date && end_date ? [new Date(start_date), new Date(end_date)] : []}
            appearance="default"
            cleanable={false}
            isoWeek
            onChange={handleDatePeriod}
            placeholder={placeholder}
            disabled={disabled}
          />
        )
      case 'YEARMONTH':
        return (
          <DatePicker
            format="YYYY-MM"
            value={value ? dayjs(value, 'YYYYMM').toDate() : null}
            ranges={[]}
            appearance="default"
            cleanable={false}
            placeholder={placeholder}
            disabledDate={(date) => {
              const currentDate = new Date()
              if (dayjs(date).isAfter(dayjs('2021-01-01')) && dayjs(date).isBefore(currentDate)) return false
              return true
            }}
            onChange={handleYearmonth}
            disabled={disabled}
          />
        )
      case 'YEARMONTHDAY':
        return (
          <DatePicker
            format="YYYY-MM-DD"
            value={value ? dayjs(value, 'YYYYMMDD').toDate() : null}
            ranges={[]}
            appearance="default"
            cleanable={false}
            placeholder={placeholder}
            onChange={(v) => handleSelect(dayjs(v).format('YYYYMMDD'), property)}
            disabled={disabled}
          />
        )
      case 'PROMO_CODE_LIST':
        return (
          <Input
            format="YYYY-MM-DD"
            value={value}
            appearance="default"
            placeholder={placeholder}
            onChange={(v) => handleSelect(v, property)}
            disabled={disabled}
          />
        )
      case 'TIME_PERIOD':
      case 'CITY':
        return (
          <SelectPicker
            style={{ width: '20vw' }}
            data={optionData}
            groupBy="group"
            labelKey="name"
            searchable={false}
            cleanable={false}
            renderMenuGroup={(menuGroup) => {
              return menuGroup === 'undefined' ? '其他選項' : menuGroup
            }}
            placeholder={placeholder}
            onSelect={(v) => handleSelect(v, property)}
            value={value || ''}
            disabled={disabled}
          />
        )
      case 'OPERATOR_WITH_NUMERIC_VALUE':
        // 該 type 有兩參數要填：operator、numeric_value
        return (
          <>
            <DropdownGroup
              style={{ width: 'min-Content' }}
              activeKey={operator}
              options={optionData}
              placeholder="選擇條件"
              onSelect={(v) => handleSelect(v, 'operator')}
              disabled={disabled}
            />
            <InputNumber
              min={0}
              value={(isArray(numeric_value) ? numeric_value?.[0] : numeric_value) || null}
              placeholder="請輸入趟次"
              onChange={(v) => {
                if (operator === 'BETWEEN') {
                  const betweenValue = [v, numeric_value?.[1] || 0]
                  return handleSelect(betweenValue, 'numeric_value')
                }
                return handleSelect(v, 'numeric_value')
              }}
              disabled={disabled}
            />
            {/* 選擇「介於之間」 */}
            {operator === 'BETWEEN' && (
              <InputNumber
                min={0}
                value={numeric_value ? numeric_value[1] : null}
                placeholder="請輸入趟次"
                onChange={(v) => {
                  const betweenValue = [numeric_value?.[0] || 0, v]
                  handleSelect(betweenValue, 'numeric_value')
                }}
                disabled={disabled}
              />
            )}
          </>
        )
      case 'COUPON_TYPE':
        return (
          <TagPicker
            data={optionData}
            labelKey="name"
            style={{ minWidth: '30vw', marginLeft: '10px' }}
            placeholder={placeholder}
            value={Array.isArray(value) ? value : [value]}
            onChange={(selections) => handleSelect(selections, property)}
            disabled={disabled}
          />
        )
      case 'WITHIN_TIME_PERIOD':
      case 'WITHIN_CITY':
      case 'WITHIN_REGION':
      case 'USER_TRENDS':
      case 'REGION':
      case 'REGION_WITHOUT_NONE':
      case 'MAU_TYPE':
      case 'COUPON_SENSITIVITY_LABEL':
      case 'NULL_PERCENTAGE_LABEL':
      case 'CAI_LABEL':
      case 'NES_LABEL':
      case 'TIME_LABEL':
      case 'TIME_LABEL_TRIP_PERCENTAGE':
      case 'ROI_GROUP':
      case 'LOCAL_TYPE':
      case 'VEHICLE_TYPE':
      case 'COUPON_TYPE_SINGLE':
        return (
          <DropdownGroup
            style={{ width: 'min-Content' }}
            activeKey={value}
            options={optionData}
            placeholder={placeholder}
            onSelect={(v) => handleSelect(v, property)}
            disabled={disabled}
          />
        )
      case 'SUB_VEHICLE_TYPE':
        return (
          <TagPicker
            data={optionData}
            labelKey="name"
            style={{ minWidth: '30vw', marginLeft: '10px' }}
            placeholder={placeholder}
            value={Array.isArray(value) ? value : [value]}
            onChange={(selections) => handleSelect(selections, property)}
            disabled={disabled}
          />
        )
      case 'ROI':
        return (
          <InputNumber
            min={0}
            value={roi || null}
            placeholder="請輸入 ROI"
            onChange={(v) => {
              handleSelect(v, 'roi')
            }}
            disabled={disabled}
          />
        )
      case 'TRIPS':
        return (
          <InputNumber
            min={0}
            value={trips || null}
            placeholder="請輸入趟次"
            onChange={(v) => {
              handleSelect(v.replace('.', ''), 'trips')
            }}
            disabled={disabled}
          />
        )
      case 'NEW_USER_STATUS_DETAIL':
        return (
          <>
            {/* 選擇「服務 = 計程車」 */}
            {property === 'taxi_user' && (
              <FlexboxGrid>
                <Label>距註冊日</Label>
                <InputNumber
                  style={{ width: 80 }}
                  min={0}
                  value={numeric_value ? numeric_value[0] : null}
                  onChange={(v) => {
                    handleSelect([v, numeric_value?.[1], numeric_value?.[2]], 'numeric_value')
                  }}
                  disabled={disabled}
                />
                <Label>天，且距首搭日</Label>
                <InputNumber
                  style={{ width: 80 }}
                  min={0}
                  value={numeric_value ? numeric_value[1] : null}
                  onChange={(v) => {
                    handleSelect([numeric_value?.[0], v, numeric_value?.[2]], 'numeric_value')
                  }}
                  disabled={disabled}
                />
                <Label>天內，有</Label>
                <InputNumber
                  style={{ width: 80 }}
                  min={0}
                  value={numeric_value ? numeric_value[2] : null}
                  onChange={(v) => {
                    handleSelect([numeric_value?.[0], numeric_value?.[1], v], 'numeric_value')
                  }}
                  disabled={disabled}
                />
                <Label>趟乘車記錄者（扣掉首搭）</Label>
              </FlexboxGrid>
            )}

            {/* 選擇「服務 = 計程車」 */}
            {property === 'rental_user' && (
              <FlexboxGrid>
                <Label>距有效會員日</Label>
                <InputNumber
                  style={{ width: 80 }}
                  min={0}
                  value={numeric_value ? numeric_value[0] : null}
                  onChange={(v) => {
                    handleSelect([v, numeric_value?.[1], numeric_value?.[2]], 'numeric_value')
                  }}
                  disabled={disabled}
                />
                <Label>天，且距首租日</Label>
                <InputNumber
                  style={{ width: 80 }}
                  min={0}
                  value={numeric_value ? numeric_value[1] : null}
                  onChange={(v) => {
                    handleSelect([numeric_value?.[0], v, numeric_value?.[2]], 'numeric_value')
                  }}
                  disabled={disabled}
                />
                <Label>天內，有</Label>
                <InputNumber
                  style={{ width: 80 }}
                  min={0}
                  value={numeric_value ? numeric_value[2] : null}
                  onChange={(v) => {
                    handleSelect([numeric_value?.[0], numeric_value?.[1], v], 'numeric_value')
                  }}
                  disabled={disabled}
                />
                <Label>趟租車記錄者（扣掉首租）</Label>
              </FlexboxGrid>
            )}
          </>
        )
      default:
        return null
    }
  }
  return renderComponent()
}

export default React.memo(MultiSelector)
