// "column_name": "model",
// "column_comment": "",
// "data_type": "varchar",
// "character_maximum_length": 100,
// "description": "車款"

//pool-mysql 有更新 Types 時要記得更新
import React from 'react'
import { HStack, VStack, Spacer } from "./ReactUI"
import { Icon, IconButton, Avatar, Whisper, Popover, Calendar, Badge } from 'rsuite'
import ReactMarkdown from "react-markdown"
import { isArray } from 'lodash'
import { JsonEditor } from 'jsoneditor-react';
import { TextField } from '@material-ui/core';
import Map from '../../components/map/Map';
import { transLocation } from '../../utils/map/transLocation';

/*
@popover: 點擊格子後pop的預覽
@preview: 未點擊的顯示
@component: 編輯框
*/
class Base {
	overflow = 'wrap'
	// readOnly = false

	constructor(value) {
		if (typeof value === 'object') {
			for (const key in value) {
				this[key] = value[key]
			}
		}
	}

	valueViewer({ value, row, col, cell }) {
		if (cell.model_type === 'PK') {
			return <p>{cell.value}</p>
		}

		return <Whisper
			trigger="click"
			enterable
			placement='autoHorizontal'
			speaker={
				<Popover style={{ whiteSpace: 'pre', overflowY: 'scroll', backgroundColor: "#FEFEFE", maxHeight: '500px', maxWidth: '500px' }}>
					<div>{cell.popover || cell.value}</div>
				</Popover>
			}>

			<div style={{ maxWidth: '300px', maxHeight: '100px', whiteSpace: 'pre', overflow: 'hidden' }}>
				{cell.preview || cell.value}
			</div>
		</Whisper>
	}

	get component() {
		return this.title === 'query'
			? <textarea
					cols="50"
					rows="15"
					name={this.title}
					placeholder={this.title}
					defaultValue={this.outputValue}
					style={{ 'whiteSpace': 'pre' }}
					onChange={e => this.onChange(e, this.data_index, this.column_name)}
			/>
			: <input className="input-wrap"
					name={this.column_name}
					type={this.input_type || 'text'}
					placeholder={this.title}
					value={this.value}
					style={{ 'minWidth': '100px', 'whiteSpace': 'pre' }}
					onChange={e => this.onChange(e, this.data_index, this.column_name)} />
	}

	get outputValue() {
		return this.value
	}

	get title() {
		return this.description || this.column_comment || this.column_name || this.value
	}

	get debugDescription() {
		return Object.keys(this).map(key => {
			return `${key}: ${JSON.stringify(this[key])}`
		})
	}
}

class KeyName extends Base {
	readOnly = true

	get preview() {
		return <div style={{ 'whiteSpace': 'pre', overflow: 'hidden' }}>
			{this.title}
			<Icon icon={this.icon} />
		</div>
	}
}

class PK extends Base {
	readOnly = true

	get component() {
		return (
			<div>
				<p>{this.column_name}:</p>
				<p>{this.value}</p>
			</div>
		)
	}
}

class FK extends PK {
	readOnly = false

	get popover() {
		if (!this.fk) {
			return <div />
		}

		const filter = [{
			"column": this.fk.column,
			"operator_option": "=",
			"text": this.value,
			"isHidden": false
		}]

		const url = `/modify_table/${this.fk.table}?filter=${JSON.stringify(filter)}`

		return <VStack>
			<a target="_blank" rel="noopener noreferrer" href={url}>跳轉</a>

			<iframe
				id={`iframe-${this.value}-${this.column_name}`}
				title="preview"
				width="1500px"
				height="400px"
				src={url}
			/>
		</VStack>
	}

	get component() {
		return <input
			className="input-wrap"
			name={this.key}
			type='number'
			placeholder={this.key}
			value={this.value}
			onChange={(e) => this.onChange(e, this.data_index, this.column_name)} />
	}
}

class FKText extends FK {
	get component() {
		return <input
			className="input-wrap"
			name={this.key}
			type='text'
			placeholder={this.key}
			value={this.value}
			onChange={(e) => this.onChange(e, this.data_index, this.column_name)} />
	}
}

class Str extends Base {
}

class Text extends Base {
	get preview() {
		return this.value.replace(/\n(.*)/g, '')
	}

	get component() {
		return <textarea
			cols="50"
			rows="15"
			name={this.title}
			placeholder={this.title}
			value={this.value}
			style={{ 'whiteSpace': 'pre' }}
			onChange={(e) => this.onChange(e, this.data_index, this.column_name)} />
	}
}

class Markdown extends Text {
	get popover() {
		return <ReactMarkdown source={this.value} />
	}
}

class IconBtn extends Base {
	readOnly = true
	forceComponent = true

	get component() {
		return <IconButton
			appearance="subtle"
			size="xs"
			onClick={() => this.onClick()}
			icon={<Icon icon={this.icon} />}
		/>
	}
}

class Point extends Base {
	constructor(value) {
		super(value)

		this.value = this.value || { x: 0, y: 0 }
	}

	get map() {
		const point = [
			Math.min(this.value.y, this.value.x),
			Math.max(this.value.y, this.value.x)
		]

		return <Map
			id={`map-${this.data_index}-${this.column_name}`}
			point={point}
			width={'250px'}
			padding={'90px'}
		/>
	}

	get popover() {
		const latlng = toLatLng(this.value)

		return <VStack>
			<a target="_blank" rel="noopener noreferrer" href={`https://google.com.tw/maps/?q=${latlng}`}>打開 GoogleMaps</a>

			{this.map}
		</VStack >
	}

	get preview() {
		return `${this.value.x}, ${this.value.y}`
	}

	get component() {
		return <VStack>
			{this.map}
			<p>
				<input className="input-wrap"
					name={this.column_name}
					type={this.input_type || 'text'}
					placeholder={this.title}
					value={this.value.x}
					style={{ 'minWidth': '100px', 'whiteSpace': 'pre' }}
					onChange={event => {

						const fakeEvent = {
							target: {
								value: {
									x: event.target.value,
									y: this.value.y
								}
							}
						}

						this.onChange(fakeEvent, this.data_index, this.column_name)
					}} />

				<input className="input-wrap"
					name={this.column_name}
					type={this.input_type || 'text'}
					placeholder={this.title}
					value={this.value.y}
					style={{ 'minWidth': '100px', 'whiteSpace': 'pre' }}
					onChange={event => {

						const fakeEvent = {
							target: {
								value: {
									x: this.value.x,
									y: event.target.value
								}
							}
						}

						this.onChange(fakeEvent, this.data_index, this.column_name)
					}} />
			</p>
		</VStack>
	}

}

//alias
class POINT extends Point { }

class Polygon extends Str {
	constructor(value) {
		super(value)
		// this.value = this.value || []
		try {
			JSON.parse(this.value)

			if (this.value) {
				this.value = JSON.stringify(JSON.parse(this.value)) || []
			}
		} catch (e) {
			// console.log(e)
		}
	}

	get map() {
		if (!isArray(this.value)) return null
		const a = this.value[0].reduce((a, b) => a + b.y, 0) / this.value[0].length
		const b = this.value[0].reduce((a, b) => a + b.x, 0) / this.value[0].length

		const center = [
			Math.min(a, b),
			Math.max(a, b),
		]

		return <Map
			id={`map-${this.data_index}-${this.column_name}`}
			center={center}
			latlngs={transLocation(this.value)}
			width={'250px'}
			padding={'90px'}
		/>
	}

	get popover() {
		return this.map
	}

	get preview() {
		return JSON.stringify(this.value, null, 2)
	}

	get component() {
		return <VStack>
			{/* {this.map} */}

			<textarea
				cols="50"
				rows="15"
				name={this.title}
				placeholder={this.title}
				defaultValue={this.outputValue}
				style={{ 'whiteSpace': 'pre' }}
				onChange={(e) => this.onChange(toPolygon(e.target.value), this.data_index, this.column_name)}
			/>
		</VStack>
	}

	get outputValue() {
		try {
			return JSON.stringify(this.value, null, 2) || ''
		} catch (error) {
			return this.value
		}
	}
}

class ENUM extends Str {
	get valuesWithEmpty() {
		return [''].concat(this.values)
	}

	get component() {
		return <select id="lang" value={this.value} onChange={(e) => this.onChange(e, this.data_index, this.column_name)} style={{ width: '100%' }} >
			{this.valuesWithEmpty.map(v => (<option key={v} value={v}>{v}</option>))}
		</select>
	}
}
class Num extends Str {
	get component() {
		return <input
			className="input-wrap"
			name={this.key}
			type='number'
			placeholder={this.key}
			value={this.value}
			onChange={(e) => this.onChange(e, this.data_index, this.column_name)} />
	}
}

class JSONString extends Str {
	constructor(value) {
		super(value)

		try {
			JSON.parse(this.value)

			if (this.value) {
				this.value = JSON.stringify(JSON.parse(this.value))
			}
		} catch (e) {
			// console.log(e)
		}
	}

	get popover() {
		// try {
		// 	return <JsonEditor
		// 		mode={'view'}
		// 		search={false}
		// 		value={this.value ? JSON.parse(this.value) : this.outputValue}
		// 	/>
		// } catch (error) {
		// 	return <p></p>
		// }

		return this.outputValue
	}

	get component() {
		// return <JsonEditor
		// 	mode={'code'}
		// 	search={false}
		// 	value={this.value ? JSON.parse(this.value) : this.outputValue}
		// 	onChange={(e) => {
		// 		this.onChange(JSON.stringify(e), this.data_index, this.column_name)
		// 	}}
		// />
		return <textarea
			cols="50"
			rows="15"
			name={this.title}
			placeholder={this.title}
			value={this.outputValue}
			style={{ 'whiteSpace': 'pre' }}
			onChange={(e) => this.onChange(e, this.data_index, this.column_name)} />
	}

	get outputValue() {
		try {
			return JSON.stringify(JSON.parse(this.value), null, 2)
		} catch (error) {
			return this.value
		}
	}
}

class NumberString extends Str { }
class Email extends Str { }
class URL extends Str {

	get isImage() {
		return (this.value || '').match(/.*(\.jpg|\.png|\.gif|\.svg)/)
	}

	get forPop() {
		if (this.isImage) {
			return <img src={this.value} alt="" />
		}
		else {
			return <iframe
				id="iframe"
				title="preview"
				width="500px"
				height="400px"
				src={this.value}>
			</iframe>
		}
	}

	get popover() {
		return <VStack >
			{this.value}

			{this.forPop}
		</VStack>
	}

	get preview() {
		return <HStack>
			{this.isImage
				? <Avatar src={this.value} />
				: <a href={this.value} target="_blank">跳轉</a>
			}
			<Spacer />
			<p>{this.value ? this.value.replace(/(.*?)\//g, '') : this.value}</p>
		</HStack>
	}

	get component() {
		return (
			<VStack>
				{this.isImage
					? <Avatar src={this.value} />
					: <iframe
						id="iframe"
						title="preview"
						width="500px"
						height="400px"
						src={this.value}>
					</iframe>
				}

				<input className="input-wrap"
					name={this.column_name}
					type='url'
					placeholder={this.column_name}
					value={this.value}
					style={{ width: '500px' }}
					onChange={(e) => this.onChange(e, this.data_index, this.column_name)}
				/>
			</VStack>
		)
	}
}

class Time extends Str {
	input_type = 'datetime-local'

	get outputValue() {
		if (!this.value) {
			return
		}

		return this.value
			.replace('T', ' ')
			.replace('.000Z', '')
			+ (this.timezoneOffset ? ` GMT+08:00` : '')
	}

	get preview() {
		try {
			return <input
				className="input-wrap"
				name={this.column_name}
				type={this.input_type}
				placeholder={this.column_name}
				value={(this.value || '')}
				style={{ border: 'none' }}
				disabled="disabled"
			/>
		} catch (error) {
			return <p> {this.value} </p>
		}
	}

	get component() {
		// return <TextField
		// 	id={this.input_type}
		// 	label="Next appointment"
		// 	type={this.input_type}
		// 	defaultValue={this.value || ''}
		// 	InputLabelProps={{ shrink: true }}
		// 	onChange={e => this.onChange(e, this.data_index, this.column_name)}
		// />

		return <input
			className="input-wrap"
			name={this.column_name}
			type={this.input_type}
			placeholder={this.column_name}
			value={this.value || ''}
			onChange={e => this.onChange(e, this.data_index, this.column_name)} />
	}
}

class DateTime extends Time {

	timezoneOffset = true

	constructor(value) {
		super(value)

		try {
			if (this.value) {
				const dateTime = this.value.replace('Z', '+00:00')
				const timestamp = Date.parse(dateTime) + 3600 * 8 * 1000
				this.value = new Date(timestamp).toISOString().slice(0, 19)
			} else {
				this.value = ''
			}
		} catch (error) {
			this.value = ''
		}
	}
}


class DateType extends Time {
	input_type = 'date'

	constructor(value) {
		super(value)

		try {
			if (this.value) {
				this.value = this.value.slice(0, 10)
			} else {
				this.value = ''
			}
		} catch (error) {
			this.value = ''
		}
	}

	get outputValue() {
		return this.value
	}

	get popover() {
		return <Calendar
			defaultValue={this.value}
			bordered={true}
			onSelect={() => true}
			onChange={() => true}
			value={this.value}
			compact={true}
			renderCell={date => {
				const current = new Date(this.value)

				const isSameDay = date.getFullYear() === current.getFullYear() &&
					date.getMonth() === current.getMonth() &&
					date.getDate() === current.getDate();

				if (isSameDay) {
					return <Badge />
				}
			}}
		/>
	}
}

class TimeType extends Time {
	input_type = 'time'


	get outputValue() {
		return this.value
	}
}

class UNIX_TIMESTAMP extends Time {
	timezoneOffset = true

	constructor(value) {
		super(value)

		if (typeof this.value === 'string' && this.value.includes('T')) {
			//因為input update過後會變為 ISO String
			const dateTime = this.value.replace('.000Z', '')
			const timestamp = new Date(dateTime).getTime() / 1000 + 3600 * 8
			this.value = new Date(timestamp * 1000).toISOString().slice(0, 19)
		} else if (this.value) {
			//一開始拿到的 UNIX_TIMESTAMP
			const timestamp = parseInt(this.value) + 3600 * 8
			this.value = new Date(timestamp * 1000).toISOString().slice(0, 19)
		} else {
			this.value = ''
		}
	}
}

export default {
	Base,
	DateTime,
	Date: DateType,
	Time: TimeType,
	UNIX_TIMESTAMP,

	Email,
	ENUM,
	IconBtn,
	JSONString,
	KeyName,
	Num,
	NumberString,
	FK,
	FKText,
	PK,
	Point,
	POINT,
	Polygon,
	Str,
	Text,
	Markdown,
	URL,
}


////////////////////////////////////



function toLatLng(value) {
	if (value.x < value.y) {
		return `${value.x}, ${value.y}`
	}
	return `${value.y}, ${value.x}`
}

function toPolygon(value) {
	let arr
	try {
		arr = JSON.parse(value)[0]
	} catch (error) {
		console.error('Detect invalid polygon value: invalid json')
		return
	}

	if (!Array.isArray(arr)) {
		console.error('Detect invalid polygon value: parsed object is not array')
		return
	}

	let polygonStr = ''
	// 拼湊成 Polygon 要求的格式
	arr.forEach((point, index) => {
		if (index === 0) {
			polygonStr += '((' + point.x + ' ' + point.y + ','
		} else if (index === arr.length - 1) {
			polygonStr += point.x + ' ' + point.y + '))'
		} else {
			polygonStr += point.x + ' ' + point.y + ','
		}
	})
	console.log('polygonStr', polygonStr)
	return polygonStr
}
