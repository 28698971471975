import React from 'react'
import { TextWrapper } from './styles'

const TextRow = ({ label, value, prefix = '', show = true }) => {
  return show ? (
    <TextWrapper>
      <div>{label}</div>
      <div>{value !== undefined && value !== null && !Number.isNaN(value) ? `${prefix}${value}` : '-'}</div>
    </TextWrapper>
  ) : (
    <></>
  )
}

export default React.memo(TextRow)
