import React from 'react'
import { find, isEmpty } from 'lodash'
import { Popover, Whisper } from 'rsuite'
import { Cell } from 'rsuite-table'

const JobCell = ({ rowData, dataKey, jobs, ...props }) => {
	const getJobName = () => {
		if (isEmpty(jobs) || !dataKey) return ''
		return find(jobs, { id: rowData[dataKey] }).name
	}
	const value = getJobName()

	return (
		<Cell {...props}>
			<Whisper placement="top" speaker={<Popover><b>{value}</b></Popover>}>
				<p>{value}</p>
			</Whisper>
		</Cell>
	)
}

export default React.memo(JobCell)