import { isEmpty, mapValues, find } from 'lodash'
import { REVIEW_ITEMS } from '../constants'
import { generateRandomCode as generateId } from '../../../utils'

const handleEquippedDocs = (docs, uid) => {
  if (isEmpty(docs)) return []

  // 沒有上傳過的項目也要顯示在審核列表中，預設給一個假的資料
  const newDocs = mapValues(REVIEW_ITEMS, (value, key) => {
    return find(docs, { type: key }) || { id: generateId(3), uid, type: key, review_type: 'NEW', url: null }
  })
  return Object.values(newDocs)
}

export default handleEquippedDocs
