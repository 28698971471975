import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  Alert,
  Breadcrumb,
  Button,
  ControlLabel,
  DatePicker,
  FlexboxGrid,
  Form,
  FormControl,
  FormGroup,
  Icon,
  IconButton,
  Loader,
  Modal,
  Radio,
  RadioGroup,
  SelectPicker,
  TagPicker,
  Toggle,
  Tooltip,
  Whisper,
} from 'rsuite'
import dayjs from 'dayjs'
import { debounce, map, remove } from 'lodash'
import { boolean, object } from 'yup'
import { getUserGroupFilterOptions, getUserGroupV2, toggleUserGroupAutoUpdate } from '../../api'
import {
  FILTER_METHODS,
  FREQUENCY_BY_DAY_OPTIONS,
  TEST_TRACK_GROUP,
  UPLOAD_FILE_TEMPLATE,
  UPLOAD_FILE_TYPE,
  UPLOAD_FILE_TYPE_LABEL,
  USER_PROPORTION,
} from './const'
import { DimensionWrapper, FormContainer, Root, Surface, Title } from './styles'
import ExportCsvButton from './Dimension/ExportCsvButton'
import { DropdownGroup } from '../../components/views'
import Dimension from './Dimension'

const schema = object().shape({
  auto_update: boolean().required(),
})

const DEFAULT_FORM_VALUE = {
  groupTitle: '',
  addMethod: FILTER_METHODS.BY_MANUAL,
  excludeUserGroup: null,
  proportion: null,
  testGroup: null,
  testWeek: null,
  rules: [],
  auto_update: false,
  frequency_by_day: null,
  end_time: null,
}

const EditUserGroupDAMA = () => {
  const history = useHistory()
  const { id } = useParams()
  const userGroupRef = useRef(null)
  const [userGroupExists, setUserGroupExists] = useState([])
  const [userGroupExistsCache, setUserGroupExistsCache] = useState([])
  const userGroupExistsRef = useRef([])
  const [formValue, setFormValue] = useState(DEFAULT_FORM_VALUE)
  const [isSearchExistUserGroup, setIsSearchExistUserGroup] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isInitializing, setIsInitializing] = useState(false)
  const [filters, setFilters] = useState([])
  const [options, setOptions] = useState([])
  const [isShowModal, setIsShowModal] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsInitializing(true)

      try {
        const userGroupFilterOptionsResponse = await getUserGroupFilterOptions()
        if (userGroupFilterOptionsResponse.status !== 200) {
          throw new Error(userGroupFilterOptionsResponse.data.msg)
        }
        const { filter, options } = userGroupFilterOptionsResponse.data
        const mapFilters = map(filter, (obj) => {
          return {
            ...obj,
            value: obj.type,
          }
        })
        setFilters(mapFilters)
        setOptions(options)

        const userGroupRefResponse = await getUserGroupV2({ id })

        if (userGroupRefResponse.status !== 200) {
          throw new Error(userGroupRefResponse.data.msg)
        }

        const ref = Array.isArray(userGroupRefResponse.data) && userGroupRefResponse.data.length ? userGroupRefResponse.data[0] : null
        if (ref) {
          userGroupRef.current = ref

          setFormValue((prev) => {
            const next = { ...prev }
            if (ref.title) next.groupTitle = ref.title
            if (ref.settings?.rules) {
              next.rules = ref.settings.rules.map((data, index) => ({ ...data, id: index + 1, finished: true }))
            }
            if (ref.settings?.distribution) next.proportion = ref.settings.distribution
            if (ref.settings?.testGroup) next.testGroup = ref.settings.testGroup
            if (ref.settings?.filter) next.excludeUserGroup = ref.settings.filter
            if (ref.auto_update) next.auto_update = true
            if (ref.frequency_by_day) next.frequency_by_day = ref.frequency_by_day
            if (ref.end_time) next.end_time = dayjs.unix(ref.end_time).toDate()
            return next
          })
        }

        const idArray = ref.settings?.filter ? ref.settings.filter : []

        const userGroupExistsResponse = await getUserGroupV2({ limit: 10, offset: 0 })

        if (userGroupExistsResponse.status !== 200) {
          throw new Error(userGroupExistsResponse.data.msg)
        }

        if (Array.isArray(userGroupExistsResponse.data) && userGroupExistsResponse.data.length) {
          const userGroupExists = [...userGroupExistsResponse.data]
          // 判斷應用的排除名單有沒有在選項裡
          const idContained = idArray.filter((id) => userGroupExistsResponse.data.some((item) => item.id === id))
          const idNotContained = idArray.filter((id) => !idContained.includes(id))

          if (idNotContained.length) {
            const response = await getUserGroupV2({ id: idNotContained.join(',') })
            if (response.status !== 200) {
              throw new Error(response.data.msg)
            }
            if (Array.isArray(response.data)) {
              response.data.forEach((item) => userGroupExists.push(item))
            }
          }
          userGroupExistsRef.current = userGroupExists
          setUserGroupExists(userGroupExists)
          const cache = userGroupExists.filter((item) => idArray.includes(item.id))
          setUserGroupExistsCache(cache)
        }
      } catch (e) {
        return Alert.error(e.message)
      }
      setIsInitializing(false)
    }

    fetchData()
  }, [id])

  const handleSearchExistUserGroup = useCallback(async (searchStr = '') => {
    setIsSearchExistUserGroup(true)
    const response = await getUserGroupV2({ search: searchStr, limit: 10, offset: 0 })
    if (response.status !== 200) {
      return Alert.error(response.data.msg)
    }
    setUserGroupExists(response.data)
    return setIsSearchExistUserGroup(false)
  }, [])

  const handleRenderMenu = useCallback(
    (menu) => {
      if (isSearchExistUserGroup) {
        return (
          <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
            <Icon icon="spinner" spin />
            Loading...
          </p>
        )
      }
      return menu
    },
    [isSearchExistUserGroup],
  )

  const handleExistUserGroupExited = useCallback(() => {
    setUserGroupExists(userGroupExistsRef.current)
  }, [])

  const handleExistUserGroupSelect = useCallback((value, item) => {
    setUserGroupExistsCache((prev) => {
      remove(prev, (v) => v.id === value.id)
      prev.push(item)
      return [...prev]
    })
  }, [])

  const handleDisableDate = useCallback((date) => dayjs(date).day() !== 1, [])

  const shouldBeDisabled = useMemo(() => isInitializing || isSubmitting, [isSubmitting, isInitializing])

  const renderDimension = useCallback(() => {
    return formValue.rules.map((rule) => {
      const { id, type, finished } = rule
      return (
        <DimensionWrapper key={id} $error={!finished}>
          <div>
            <DropdownGroup
              style={{ width: 'min-Content', borderRight: '2px solid #dadada', paddingRight: '12px', marginRight: '12px' }}
              activeKey={type}
              options={filters}
              disabledList={[]}
              placeholder="選擇維度"
              disabled
            />
            <Dimension data={rule} filters={filters} options={options} disabled />
          </div>
          <Button appearance="ghost" disabled>
            - 移除
          </Button>
        </DimensionWrapper>
      )
    })
  }, [formValue.rules, filters, options])

  const renderModalContent = useCallback(() => {
    const idSlugs = Array.isArray(userGroupRef.current?.distributionGroups) ? userGroupRef.current.distributionGroups.join(', ') : ''
    if (formValue.auto_update) {
      return `此分眾有被拆分成多組做A/B Testing。當你再次將此分眾作為範本，${idSlugs} 也會一同被開啟，名單會持續被自動更新。`
    }
    return `此分眾有被拆分成多組做A/B Testing。當你關閉此分眾範本，${idSlugs} 也會一同被關閉，名單也不會再自動更新。`
  }, [formValue])

  const handleCloseModal = useCallback(() => {
    !shouldBeDisabled && setIsShowModal(false)
  }, [shouldBeDisabled])

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true)
    try {
      await schema.validate(formValue)
      const payload = { auto_update: formValue.auto_update ? 1 : 0 }
      const response = await toggleUserGroupAutoUpdate(id, payload)
      if (response.status !== 200) {
        throw new Error(response.data.msg)
      }
      if (Array.isArray(response.data.updated_ids)) {
        response.data.updated_ids.forEach(({ id }) => {
          Alert.success(`已變更 id 為 ${id} 的分眾名單!`, 2000)
        })
      }
      return history.push('/dama/user_group')
    } catch (e) {
      Alert.error(e.message)
      setIsSubmitting(false)
    }
  }, [formValue, history, id])

  const handleBeforeSubmit = useCallback(() => {
    console.log(userGroupRef.current)
    if (Array.isArray(userGroupRef.current?.distributionGroups) && userGroupRef.current.distributionGroups.length > 1) {
      setIsShowModal(true)
    } else {
      handleSubmit()
    }
  }, [handleSubmit])

  return (
    <Root>
      <Breadcrumb>
        <Breadcrumb.Item componentClass={Link} to="/dama/user_group">
          DAMA+分眾名單管理
        </Breadcrumb.Item>
        <Breadcrumb.Item active>編輯分眾名單</Breadcrumb.Item>
      </Breadcrumb>
      <Title>編輯分眾名單</Title>
      <FormContainer>
        {isInitializing && <Loader backdrop content="loading..." vertical />}
        <Form formValue={formValue} onChange={setFormValue} onSubmit={handleBeforeSubmit}>
          {formValue.addMethod === FILTER_METHODS.BY_MANUAL && (
            <FormGroup>
              <ControlLabel>轉換為範本</ControlLabel>
              <FormControl name="auto_update" accepter={Toggle} disabled={shouldBeDisabled} checked={formValue.auto_update} />
            </FormGroup>
          )}
          <FormGroup>
            <ControlLabel>分眾名稱</ControlLabel>
            <FormControl name="groupTitle" disabled />
          </FormGroup>
          <FormGroup>
            <ControlLabel>篩選方式</ControlLabel>
            <FormControl name="addMethod" accepter={RadioGroup} disabled>
              <Radio value={FILTER_METHODS.BY_MANUAL} disabled>
                新增篩選維度
              </Radio>
              <Radio value={FILTER_METHODS.BY_FILE} disabled>
                檔案上傳
              </Radio>
            </FormControl>
          </FormGroup>
          <FormGroup>
            <Surface>
              {formValue.addMethod === FILTER_METHODS.BY_MANUAL && (
                <>
                  {renderDimension()}
                  <IconButton appearance="ghost" icon={<Icon icon="plus" />} disabled>
                    新增維度
                  </IconButton>
                </>
              )}
              {formValue.addMethod === FILTER_METHODS.BY_FILE && (
                <>
                  <FormGroup>
                    <ControlLabel>檔案類型</ControlLabel>
                    <FormControl name="fileType" accepter={RadioGroup} disabled>
                      <Radio value={UPLOAD_FILE_TYPE.UID} disabled>
                        {UPLOAD_FILE_TYPE_LABEL[UPLOAD_FILE_TYPE.UID]}
                        <ExportCsvButton
                          buttonText="範例下載"
                          fileName={UPLOAD_FILE_TYPE.UID}
                          sheetData={UPLOAD_FILE_TEMPLATE[UPLOAD_FILE_TYPE.UID]}
                        />
                      </Radio>
                      <Radio value={UPLOAD_FILE_TYPE.PHONE} disabled>
                        {UPLOAD_FILE_TYPE_LABEL[UPLOAD_FILE_TYPE.PHONE]}
                        <ExportCsvButton
                          buttonText="範例下載"
                          fileName={UPLOAD_FILE_TYPE.PHONE}
                          sheetData={UPLOAD_FILE_TEMPLATE[UPLOAD_FILE_TYPE.PHONE]}
                        />
                      </Radio>
                    </FormControl>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>上傳 CSV</ControlLabel>
                    <input style={{ padding: '5px' }} type="file" accept=".csv" disabled />
                  </FormGroup>
                </>
              )}
            </Surface>
          </FormGroup>
          {formValue.addMethod === FILTER_METHODS.BY_MANUAL && (
            <FormGroup>
              <ControlLabel>
                自動更新週期(限範本分眾)
                <Whisper placement="right" trigger="hover" speaker={<Tooltip>名單更新時間點 = 此分眾創建時間點</Tooltip>}>
                  <Icon icon="info" />
                </Whisper>
              </ControlLabel>
              <FlexboxGrid style={{ gap: '8px' }} align="middle">
                <FlexboxGrid.Item>
                  <FormControl
                    name="frequency_by_day"
                    accepter={SelectPicker}
                    data={FREQUENCY_BY_DAY_OPTIONS}
                    searchable={false}
                    cleanable={false}
                    placeholder="請選擇名單更新週期"
                    disabled
                  />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>更新直到</FlexboxGrid.Item>
                <FlexboxGrid.Item>
                  <FormControl
                    name="end_time"
                    accepter={DatePicker}
                    format="YYYY-MM-DD"
                    ranges={[]}
                    appearance="default"
                    cleanable={false}
                    placeholder="選擇日期"
                    disabled
                    disabledDate={(date) => dayjs().isAfter(date, new Date())}
                    oneTap
                  />
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </FormGroup>
          )}
          {formValue.addMethod === FILTER_METHODS.BY_MANUAL && (
            <FormGroup>
              <ControlLabel>排除已建立的分眾名單</ControlLabel>
              <FormControl
                name="excludeUserGroup"
                accepter={TagPicker}
                placeholder="選擇欲排除之分眾名單名稱"
                data={userGroupExists}
                cacheData={userGroupExistsCache}
                labelKey="title"
                valueKey="id"
                onSearch={debounce(handleSearchExistUserGroup, 300)}
                onExited={handleExistUserGroupExited}
                onSelect={handleExistUserGroupSelect}
                renderMenu={handleRenderMenu}
                disabled
                searchable
              />
            </FormGroup>
          )}
          <FormGroup>
            <ControlLabel>拆分多組分眾</ControlLabel>
            <FormControl
              name="proportion"
              accepter={SelectPicker}
              data={USER_PROPORTION}
              labelKey="name"
              searchable={false}
              placeholder="選擇拆分比例"
              disabled
            />
          </FormGroup>
          {formValue.addMethod === FILTER_METHODS.BY_MANUAL && (
            <FormGroup>
              <ControlLabel>追蹤參數設定</ControlLabel>
              <FormControl
                name="testGroup"
                accepter={SelectPicker}
                data={TEST_TRACK_GROUP}
                labelKey="name"
                searchable={false}
                placeholder="選擇追蹤參數"
                disabled
              />
            </FormGroup>
          )}
          {formValue.addMethod === FILTER_METHODS.BY_MANUAL && (
            <FormGroup>
              <ControlLabel>追蹤分析的日期</ControlLabel>
              <FormControl
                name="testWeek"
                accepter={DatePicker}
                format="YYYY-MM-DD"
                ranges={[]}
                appearance="default"
                cleanable={false}
                disabledDate={handleDisableDate} // 只能填入星期一作為分析日
                placeholder="Test Week 分析日"
                placement="autoVerticalStart"
                disabled
                oneTap
              />
            </FormGroup>
          )}
          <FormGroup>
            <FlexboxGrid style={{ gap: '8px' }} align="middle">
              <Button type="submit" appearance="primary" loading={isSubmitting} disabled={shouldBeDisabled}>
                送出
              </Button>
            </FlexboxGrid>
          </FormGroup>
        </Form>
      </FormContainer>
      <Modal backdrop={!shouldBeDisabled} show={isShowModal} onHide={handleCloseModal}>
        <Modal.Body>{renderModalContent()}</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit} appearance="primary" loading={isSubmitting} disabled={shouldBeDisabled}>
            了解，並更新
          </Button>
          <Button onClick={handleCloseModal} appearance="subtle" disabled={shouldBeDisabled}>
            取消
          </Button>
        </Modal.Footer>
      </Modal>
    </Root>
  )
}

export default EditUserGroupDAMA
