import React from 'react'
import { PreviewBotCardWrapper } from './styles'

const PreviewBotCard = ({ cover = '', title = '', content = '', primaryButtonText = '', secondaryButtonText = '' }) => {
  return (
    <PreviewBotCardWrapper>
      <img className="cover" src={cover} alt="bot-card-cover" />
      <div className="body">
        <div className="title">{title}</div>
        <div className="content">{content}</div>
        <div className="primary-button">{primaryButtonText}</div>
        {!!secondaryButtonText && <div className="secondary-button">{secondaryButtonText}</div>}
      </div>
    </PreviewBotCardWrapper>
  )
}

export default PreviewBotCard
