import { isString } from 'lodash'

const getTimePeriods = (timePeriods) => {
  const newTimePeriods = []
  timePeriods.forEach((period) => {
    const { id, time_of_day_start, time_of_day_end } = period
    if (!time_of_day_start || !time_of_day_end) return
    const data = {...period}
    if (isString(id)) delete data.id
    newTimePeriods.push(data)
  })
  return newTimePeriods
}

export { getTimePeriods }