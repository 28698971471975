import React, {useState} from 'react'
import TitleHeader from "../../../components/views/TitleHeader"
import {Panel, PanelGroup} from 'rsuite'
import {
    getUserData
} from "../../../api"
import moment from 'moment-timezone'

class AboutMe extends React.Component {
    state = {
        users: {}
    }

    componentDidMount() {
        this.getUserData()
    }

    getUserData = async () => {
        const data = await getUserData(this.props.user_id)
        // console.log('getUserData' + JSON.stringify(data.data.data))
        this.setState({users: data.data.data})
    }

    render() {
        const link = `/users/${this.props.user_id}`
        return (
            <div>
                <p><h5><a href={link} target="_blank">About Me</a></h5></p>
                <p><h6>Email:</h6></p>
                <p>{(this.state.users && this.state.users.info) ? this.state.users.info.email : ''}</p>
                <p><h6>註冊日期:</h6></p>
                <p>{this.state.users && this.state.users.info && moment(this.state.users.info.create_time * 1000).format('YYYY/MM/DD')}</p>
                <p><h6>付款方式:</h6></p>
                <p> 預設支付方式: {this.state.users.preferred_payment}</p>
                <p> 信用卡資訊:
                    Card4No=>{(this.state.users && this.state.users.spg) ? this.state.users.spg.Card4No : ''}</p>
                <p> LINEPay 資訊:
                    Card4No=>{(this.state.users && this.state.users.linepay_info) ? this.state.users.linepay_info.Card4No : ''}</p>
                <p> 自動付款狀態: {!this.state.users.auto_payment_method ? (<span>關閉</span>) : (
                    <span>開啟 {this.state.users.auto_payment_method}</span>)}</p>

                <p><h5>簽約用戶</h5></p>
                <PanelGroup accordion bordered>
                    <Panel header="點擊這裡和下滑可以看看更多簽約用戶細節" bordered>
                        {this.state.users.company && this.state.users.company.map((it) => {
                            return (<>
                                <p><h6>unit_id: {it.unit_id}</h6></p>
                                <p> Company: {it.company_name}</p>
                                <p> email: {it.email}</p>
                                <p> 驗證狀態: {it.status}</p>
                                <p> 角色: {it.status}</p>
                                <p> 群組名稱: {it.name}</p>
                                <p> 群組預算: {it.quota}</p>
                                <p> 個人預算: {it.profile_quota}</p>
                                <p> 個人單趟上限: {it.profile_trip_quota == -1 ? 0 : it.profile_trip_quota}</p>
                                <p> 個人角色: {it.permission.join(', ')}</p>
                            </>)
                        })}
                    </Panel>
                </PanelGroup>
            </div>
        )
    }
}

export default AboutMe
