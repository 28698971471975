import styled from 'styled-components'

export const Root = styled.div`
  width: 100%;
  padding: 16px 20px;
`

export const TopBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`

export const ListBox = styled.div`
  width: 100%;
  margin: 40px 0;
`

export const ListItemGrid = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr 2fr 2fr 160px;
  gap: 20px;
`

export const ListItemTitle = styled.p`
  font-size: 16px;
`

export const ListItemSlimTitle = styled.p`
  font-size: 0.85em;
  color: #71717a;
`

export const HStack = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const ListItemTag = styled.div`
  width: 60px;
  border-radius: 4px;
  padding: 4px;
  font-size: 12px;
  background-color: ${(props) => (props.variant === 'receiver' ? '#0ea5e9' : '#a78bfa')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: white;
`

export const VStack = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`
