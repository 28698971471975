import React from 'react'
import { Tag } from 'rsuite'
import { Cell } from 'rsuite-table'

const getTagInfo = (type) => {
  switch (type) {
    case 'COMPLETED':
      return {
        name: '已扣款',
        color: 'green',
      }
    case 'INPROGRESS':
      return {
        name: '未扣款',
        color: 'yellow',
      }
    case 'CANCELLED':
      return {
        name: '已取消',
        color: 'red',
      }
    default:
      return {
        name: '',
        color: '',
      }
  }
}

export const ChargeStatusCell = ({ rowData, dataKey, ...props }) => {
  const { color, name } = getTagInfo(rowData[dataKey])
  return (
    <Cell {...props}>
      <Tag color={color}>{name}</Tag>
    </Cell>
  )
}

export default React.memo(ChargeStatusCell)
