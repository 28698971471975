import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Alert, Grid, Row, Col, Nav, Button } from 'rsuite'
import { Heading, Wrapper } from './styles'
import { getUserInfo, issueRentalCoupon } from '../../api'
import { Summary, Basic, Membership, RentalCouponTable, OrderTable, DetailModal, InfoModal, CouponPicker } from '../../components/user_info'

const UserInfo = () => {
  const { uid = '' } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [summary, setSummary] = useState({})
  const [basic, setBasic] = useState({})
  const [membership, setMembership] = useState({})
  const [rentalCouponHistory, setRentalCouponHistory] = useState([])
  const [rentalHistory, setRentalHistory] = useState([])
  const [rentalSuspendBlack, setRentalSuspendBlack] = useState({})
  const [popupOrderDetail, setPopupOrderDetail] = useState(false)
  const [popupOrderInfo, setPopupOrderInfo] = useState(false)
  const [orderDetail, setOrderDetail] = useState({})
  const [invoice, setInvoice] = useState(null)
  const [invoiceCache, setInvoiceCache] = useState({})
  const [orderInfo, setOrderInfo] = useState({})
  const [tab, setTab] = useState('rental')
  const [promoId, setPromoId] = useState(null)

  useEffect(() => {
    fetchUserInfo()
  }, [uid])

  const handleTabSelect = (value) => setTab(value)
  const fetchRentalCouponHistory = async () => {
    if (!uid) return
    setIsLoading(true)
    const { data: { data } = {}, status } = await getUserInfo(uid, {
      q: '{rental_coupon_history}',
    })
    setIsLoading(false)
    if (status !== 200) {
      Alert.error('rental coupon history fetch failed')
      return
    }
    setRentalCouponHistory(data?.rental_coupon_history)
  }

  const fetchUserInfo = async () => {
    if (!uid) return
    setIsLoading(true)
    const { data: { data } = {}, status } = await getUserInfo(uid, {
      q: '{basic,membership,rental_coupon_history,rental_history(limit:6),rating,completed_trips,rental_documents_review,rental_suspend_black}',
    })
    setIsLoading(false)
    if (status !== 200) {
      Alert.error('user info fetch failed')
      return
    }

    if (!data.basic) {
      reset()
      return
    }

    setSummary({
      first_name: data?.basic?.first_name,
      profile_image_url: data?.basic?.profile_image_url,
      rating: data?.rating,
      completed_trips: data?.completed_trips,
    })
    setBasic({
      uid,
      phone_number: data?.basic?.phone_number,
      email: data?.basic?.email,
      create_time: data?.basic?.create_time,
    })
    setMembership({
      status: data?.membership?.membership?.status,
      created_at: data?.membership?.membership?.created_at,
      rental_binding: !!data?.basic?.partner_id,
      rental_id: data?.basic?.partner_id || '-',
      yulon_sso_id: data?.basic?.yulon_sso_id || '-',
      yulon_is_bound: !!data?.basic?.yulon_is_bound,
      rental_documents_review: data?.rental_documents_review,
    })
    setRentalCouponHistory(data?.rental_coupon_history)
    setRentalHistory(data?.rental_history)
    setRentalSuspendBlack(data?.rental_suspend_black)
  }

  const fetchInvoice = async (orderNo) => {
    if (invoiceCache[orderNo]) {
      setInvoice(invoiceCache[orderNo])
      return
    }

    const { data: { data } = {}, status } = await getUserInfo(uid, {
      q: `{rental_invoice(orderNo:${orderNo})}`,
    })
    if (status !== 200) {
      Alert.error('invoice fetch failed')
      return
    }
    setInvoiceCache({ ...invoiceCache, [orderNo]: data.rental_invoice })
    setInvoice(data.rental_invoice)
  }

  const fetchOrder = async ({ limit = 10, offset = 0 }) => {
    const { data: { data } = {}, status } = await getUserInfo(uid, {
      q: `{rental_history(limit:${limit},offset:${offset})}`,
    })
    if (status !== 200) {
      Alert.error('order fetch failed')
      return
    }
    setRentalHistory(data?.rental_history)
  }

  const reset = () => {
    setSummary({})
    setBasic({})
    setMembership({})
    setRentalCouponHistory([])
  }

  const handlePopupOrderDetail = (value) => {
    setOrderDetail(value)
    fetchInvoice(value.orderNo)
    setPopupOrderDetail(true)
  }

  const handlePopupOrderInfo = (value) => {
    setOrderInfo(value)
    setPopupOrderInfo(true)
  }

  const handleCloseDetail = () => {
    setInvoice(null)
    setPopupOrderDetail(false)
  }

  const issueCoupon = async () => {
    const response = await issueRentalCoupon({ promo_id: promoId, uid })
    if (response.status !== 200) {
      Alert.error(response?.data?.message || '操作失敗')
      return
    }
    Alert.success('發送成功')
    setPromoId(null)
    fetchRentalCouponHistory()
  }

  const couponTableHeader = (
    <div className="rs-panel-heading" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
      <span>用戶優惠券</span>
      <div style={{ maxWidth: '60%', textAlign: 'right' }}>
        <CouponPicker value={promoId} setValue={setPromoId} />
        <Button style={{ marginLeft: 12 }} disabled={!promoId} onClick={issueCoupon}>
          發放
        </Button>
      </div>
    </div>
  )

  return (
    <Wrapper className="user-info">
      <Heading>用戶資訊</Heading>
      {basic.phone_number && (
        <Grid fluid style={{ minWidth: 400, width: '100%' }}>
          <Row gutter={20}>
            <Col md={6} xs={12}>
              <Summary info={summary} style={{ minHeight: 240 }} />
            </Col>
            <Col md={9} xs={24}>
              <Basic info={basic} style={{ minHeight: 240 }} />
            </Col>
            <Col md={9} xs={24}>
              <Membership info={membership} rentalSuspendBlack={rentalSuspendBlack} style={{ minHeight: 240 }} />
            </Col>
          </Row>
        </Grid>
      )}
      <Nav appearance="subtle" activeKey={tab} onSelect={handleTabSelect}>
        <Nav.Item eventKey="rental">租車</Nav.Item>
        <Nav.Item eventKey="trip">叫車</Nav.Item>
      </Nav>
      {tab === 'rental' && (
        <>
          <RentalCouponTable data={rentalCouponHistory} isLoading={isLoading} header={couponTableHeader} />
          <OrderTable
            data={rentalHistory}
            popupDetail={handlePopupOrderDetail}
            popupInfo={handlePopupOrderInfo}
            fetch={fetchOrder}
            isLoading={isLoading}
          />
          <DetailModal show={popupOrderDetail} close={handleCloseDetail} info={orderDetail} invoice={invoice} />
          <InfoModal show={popupOrderInfo} close={setPopupOrderInfo.bind(null, false)} info={orderInfo} />
        </>
      )}
    </Wrapper>
  )
}

export default UserInfo
