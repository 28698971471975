import React from 'react'
import { SelectPicker } from 'rsuite'
import { promoType } from '../../pages/rental_coupons/promoType'

const PromoTypePicker = React.memo(({ value, onChange }) => {
  const data = promoType.map((v) => ({ ...v, label: v.name }))

  return <SelectPicker onChange={onChange} searchable={false} cleanable={false} data={data} value={value} style={{ width: 220 }} />
})

export default PromoTypePicker
