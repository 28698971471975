import React, {useState} from 'react'
import TitleHeader from "../../../components/views/TitleHeader";
import AboutMe from "./about_me";

class UserInfo extends React.Component {
    state = {}

    componentDidMount() {

    }

    render() {

        return (
            <div>
                <AboutMe user_id={this.props.match.params.user_id}></AboutMe>
            </div>
        )
    }
}

export default UserInfo
