import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'

export default function usePageSetting(initCurrentInfo) {
  const location = useLocation()
  const [isAddMode, setAddMode] = useState(false)
  const [list, setList] = useState([])
  const [currentInfo, setCurrentInfo] = useState(initCurrentInfo)
  const [warning, setWarning] = useState('')
  const [permissions, setPermissions] = useState([])

  useEffect(() => {
    const { search } = location
    if (isEmpty(search)) setAddMode(false)
  }, [location])

  const toggleAddMode = () => {
    setAddMode(!isAddMode)
  }

  const handleOnChange = (e, setStateKey) => {
    const value = e.target ? e.target.value : e
    setCurrentInfo({
      ...currentInfo,
      [setStateKey]: value,
    })
  }

  const handleDatePicker = (date, stateKey) => {
    setCurrentInfo({
      ...currentInfo,
      [stateKey]: dayjs(+date).unix(),
    })
  }

  useEffect(() => {
    if (isAddMode) return
    setCurrentInfo(initCurrentInfo)
  }, [isAddMode])

  return { isAddMode, list, currentInfo, warning, toggleAddMode, handleOnChange, handleDatePicker, setList, setCurrentInfo, setWarning, permissions, setPermissions }
}
