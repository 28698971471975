import React, { useEffect, useState, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Input, InputGroup, Alert, Button, RadioGroup, Radio } from 'rsuite'
import dayjs from 'dayjs'
import { FormGrop, FormSection, SubGroupWrap } from '../../components/views'
import { Wrapper, Title } from '../../components/views/styles'
import { PromoTypePicker, DatePicker, TypePicker, CategoryPicker, CarSeriesPicker } from '../../components/rental_coupons'
import { InputNumber } from '../../components/coupons'
import { generateCode } from '../coupons/helper/generateCode'
import { getRentalCoupon, updateRentalCoupon, createRentalCoupon } from '../../api'

const initCoupon = {
  id: '',
  promo_type: 'OPERATION',
  promo_title: '',
  promo_desc: '',
  promo_code: '',
  count: 1,
  total_number_of_coupons: -1,
  amount: 50,
  max_amount: null,
  type: null,
  category: null,
  minimum_charge: 0,
  car_series_ids: [],
  discount_time: 15,
  minimum_rent_time: 0,
}

const EditRentalCoupon = ({ mode = 'edit' }) => {
  const { id = '' } = useParams()
  const [coupon, setCoupon] = useState(initCoupon)
  const [isTotalLimited, setIsTotalLimited] = useState(false)
  const [validDaysType, setValidDaysType] = useState('NUM_OF_DAYS')
  const [discountType, setDiscountType] = useState('amount')
  const [discount, setDiscount] = useState(0.9)
  const [maxAmount, setMaxAmount] = useState(100)
  const [validDays, setValidDays] = useState(1)
  const [validDate, setValidDate] = useState(null)
  const [validator, setValidator] = useState({})
  const isSerialNumber = useMemo(() => coupon.promo_type === 'SERIAL_NUMBER', [coupon.promo_type])
  const history = useHistory()
  const title = `${mode === 'edit' ? '修改' : '新增'}租車優惠券`

  useEffect(() => {
    mode === 'add' || fetch()
  }, [])

  const fetch = async () => {
    const { data, status } = await getRentalCoupon(id)
    if (status !== 200) {
      Alert.error('rental coupons fetch failed')
      return
    }
    if (!data) {
      Alert.error('coupon not found')
      return
    }
    setIsTotalLimited(data.total_number_of_coupons !== -1)
    data.max_amount && setMaxAmount(data.max_amount)
    if (data.amount > 0 && data.amount < 1) {
      setDiscountType('discount')
      setDiscount(data.amount)
    } else if (data.discount_time > 0) {
      setDiscountType('discount_time')
    }

    if (data.valid_days > 10000) {
      setValidDaysType('DATE')
      setValidDate(data.valid_days)
    } else {
      setValidDays(data.valid_days)
    }

    const amount = data.amount < 1 ? initCoupon.amount : data.amount
    if (mode === 'copy') {
      Alert.success('已複製訊息')
      setCoupon({ ...data, promo_code: '', amount })
      return
    }

    setCoupon({ ...data, amount })
  }

  const handleGenerateCode = () => {
    setCoupon({ ...coupon, promo_code: generateCode(8) })
  }

  const handleDateSelect = (key, value) => handleOnChange(dayjs(value).unix(), key)
  const handleOnChange = (value, key) => setCoupon({ ...coupon, ...{ [key]: value } })
  const handleTotalLimitedChange = (value) => {
    handleOnChange(value ? Math.max(1, coupon.total_number_of_coupons) : -1, 'total_number_of_coupons')
    setIsTotalLimited(value)
  }

  const handlePromoTypeChange = (value) => {
    if (value === 'SERIAL_NUMBER') {
      setCoupon({ ...coupon, total_number_of_coupons: 1, promo_type: 'SERIAL_NUMBER' })
      setIsTotalLimited(true)
      return
    }
    handleOnChange(value, 'promo_type')
  }

  const handleMinimumChargeChange = (value) => {
    if (value) {
      if (discountType === 'discount_time') {
        handleOnChange(15, 'minimum_rent_time')
      } else {
        handleOnChange(1, 'minimum_charge')
      }
    } else {
      setCoupon({ ...coupon, minimum_rent_time: 0, minimum_charge: 0 })
    }
  }

  const handleDiscountTypeChange = (value) => {
    setDiscountType(value)
    if (value === 'discount_time') {
      handleOnChange(0, 'minimum_charge')
    } else {
      handleOnChange(0, 'minimum_rent_time')
    }
  }

  const submit = async () => {
    const valid_days = validDaysType === 'DATE' ? validDate : validDays
    let amount = 0
    let minimum_charge = 0
    let discount_time = 0
    let minimum_rent_time = 0
    let max_amount = null
    switch (discountType) {
      case 'discount_time':
        discount_time = coupon.discount_time
        minimum_rent_time = coupon.minimum_rent_time
        break
      case 'discount':
        amount = discount
        max_amount = maxAmount
        minimum_charge = coupon.minimum_charge
        break
      default:
        amount = coupon.amount
        max_amount = null
        minimum_charge = coupon.minimum_charge
        break
    }
    const payload = { ...coupon, valid_days, amount, minimum_charge, discount_time, minimum_rent_time, max_amount }
    if (!validate(payload)) return

    let response
    if (mode === 'edit') {
      response = await updateRentalCoupon(id, payload)
    } else {
      response = await createRentalCoupon(payload)
    }

    if (response.status !== 200) {
      Alert.error(response.data?.msg || response.data?.devMessage || '操作失敗')
      return
    }
    Alert.info('操作成功')
    history.push('/marketing/rental_coupons')
  }

  const validate = (payload) => {
    const hint_msg = '請填寫或選擇項目'
    const validation = {
      promo_title: !payload.promo_title ? hint_msg : '',
      ...(!isSerialNumber && { promo_code: !payload.promo_code ? hint_msg : '' }),
      start_date: !payload.start_date ? hint_msg : '',
      end_date: !payload.end_date ? hint_msg : '',
      enable_date: !payload.enable_date ? hint_msg : '',
      validDate: validDaysType === 'DATE' && !validDate ? hint_msg : '',
      discount_time: payload.discount_time % 15 !== 0 ? '折抵時數須為15的倍數' : '',
      minimum_rent_time: payload.minimum_rent_time % 15 !== 0 ? '低消時數須為15的倍數' : '',
    }
    setValidator(validation)
    for (const value of Object.values(validation)) {
      if (value) return false
    }
    return true
  }

  const readyForSubmit = () => {
    if (mode !== 'edit') return true
    return !!coupon.id
  }

  return (
    <Wrapper>
      <Title>{title}</Title>
      <div>
        {mode === 'edit' && (
          <FormGrop title="Promo ID" type="children">
            <Input disabled value={coupon.id} />
          </FormGrop>
        )}
        <FormGrop title="適用活動類型" type="children">
          <PromoTypePicker value={coupon.promo_type} onChange={handlePromoTypeChange} />
        </FormGrop>
        <FormGrop title="優惠券名稱*" type="children" errorMessage={validator.promo_title}>
          <Input value={coupon.promo_title} onChange={(value) => handleOnChange(value, 'promo_title')} />
        </FormGrop>
        {coupon.promo_type !== 'SERIAL_NUMBER' && (
          <FormGrop title="優惠券代碼" type="children" errorMessage={validator.promo_code}>
            <InputGroup>
              <Input value={coupon.promo_code} onChange={(value) => handleOnChange(value, 'promo_code')} />
              <InputGroup.Button onClick={handleGenerateCode} color="blue">
                隨機產生
              </InputGroup.Button>
            </InputGroup>
          </FormGrop>
        )}
        <div style={{ display: 'flex', columnGap: 24 }}>
          <FormGrop title="開始領取時間*" type="children" errorMessage={validator.start_date}>
            <DatePicker handleSelect={(v) => handleDateSelect('start_date', v)} value={coupon.start_date} />
          </FormGrop>
          <FormGrop title="結束領取時間*" type="children" errorMessage={validator.end_date}>
            <DatePicker handleSelect={(v) => handleDateSelect('end_date', v)} value={coupon.end_date} />
          </FormGrop>
        </div>
        <FormSection title="數量及折扣設定">
          <SubGroupWrap title="每次領取張數">
            <InputNumber title="一次領取" unit="張" min={1} value={coupon.count} handleOnChange={handleOnChange} setStateKey="count" />
          </SubGroupWrap>
          <SubGroupWrap title={isSerialNumber ? '發出總組數' : '發出總張數'}>
            <RadioGroup inline value={isTotalLimited} onChange={handleTotalLimitedChange}>
              <Radio value={false} disabled={isSerialNumber}>
                無限制
              </Radio>
              <Radio value>限制</Radio>
            </RadioGroup>
            {Boolean(isTotalLimited) && (
              <InputNumber
                title="最多發放"
                unit={isSerialNumber ? '組' : '張'}
                min={1}
                value={coupon.total_number_of_coupons}
                handleOnChange={handleOnChange}
                setStateKey="total_number_of_coupons"
              />
            )}
          </SubGroupWrap>
          <SubGroupWrap title="折扣方式" errorMessage={discountType === 'discount_time' ? validator.discount_time : ''}>
            <RadioGroup value={discountType} onChange={handleDiscountTypeChange}>
              <div className="coupon-radio-button">
                <Radio value="amount">固定金額</Radio>
                {discountType === 'amount' && (
                  <InputNumber min={0} title="每次折抵" unit="元" value={coupon.amount} handleOnChange={handleOnChange} setStateKey="amount" />
                )}
              </div>
              <div className="coupon-radio-button">
                <Radio value="discount">優惠折數</Radio>
                {discountType === 'discount' && (
                  <>
                    <InputNumber title="打折" min={0} max={0.9} step={0.01} value={discount} handleOnChange={setDiscount} />
                    <InputNumber min={0} title="上限" unit="元" value={maxAmount} handleOnChange={setMaxAmount} />
                  </>
                )}
              </div>
              <div className="coupon-radio-button">
                <Radio value="discount_time">時數</Radio>
                {discountType === 'discount_time' && (
                  <InputNumber
                    min={15}
                    title="每次折抵"
                    unit="分鐘"
                    step={15}
                    value={coupon.discount_time}
                    handleOnChange={handleOnChange}
                    setStateKey="discount_time"
                  />
                )}
              </div>
            </RadioGroup>
          </SubGroupWrap>
          <SubGroupWrap title="低消門檻" errorMessage={discountType === 'discount_time' ? validator.minimum_rent_time : ''}>
            <RadioGroup
              inline
              value={discountType === 'discount_time' ? Boolean(coupon.minimum_rent_time) : Boolean(coupon.minimum_charge)}
              onChange={handleMinimumChargeChange}
            >
              <Radio value={false}>無限制</Radio>
              <Radio value>
                低消
                {discountType === 'discount_time' && '時數'}
              </Radio>
            </RadioGroup>
            {discountType !== 'discount_time' && coupon.minimum_charge > 0 && (
              <InputNumber unit="元" min={1} value={coupon.minimum_charge} handleOnChange={handleOnChange} setStateKey="minimum_charge" />
            )}
            {discountType === 'discount_time' && coupon.minimum_rent_time > 0 && (
              <InputNumber
                unit="分鐘"
                min={15}
                step={15}
                value={coupon.minimum_rent_time}
                handleOnChange={handleOnChange}
                setStateKey="minimum_rent_time"
              />
            )}
          </SubGroupWrap>
        </FormSection>
        <FormSection title="優惠券使用設定">
          <SubGroupWrap title="開始使用時間*" errorMessage={validator.enable_date}>
            <DatePicker handleSelect={(v) => handleDateSelect('enable_date', v)} value={coupon.enable_date} />
          </SubGroupWrap>
          <SubGroupWrap title="限制使用天數">
            <RadioGroup inline value={validDaysType} onChange={setValidDaysType}>
              <Radio value="DATE">否</Radio>
              <Radio value="NUM_OF_DAYS">是</Radio>
            </RadioGroup>
            {validDaysType === 'NUM_OF_DAYS' && (
              <InputNumber title="領取" unit="天內" min={1} value={validDays} setStateKey="valid_days" handleOnChange={setValidDays} />
            )}
          </SubGroupWrap>
          <SubGroupWrap title="限制車種">
            <CarSeriesPicker value={coupon.car_series_ids} setValue={(value) => handleOnChange(value, 'car_series_ids')} />
          </SubGroupWrap>
          {validDaysType === 'DATE' && (
            <SubGroupWrap title="結束使用時間*" errorMessage={validator.validDate}>
              <DatePicker handleSelect={(value) => setValidDate(dayjs(value).unix())} value={validDate} />
            </SubGroupWrap>
          )}
          <SubGroupWrap title="活動條件說明">
            <div style={{ width: '100%' }}>
              <FormGrop type="children">
                <Input
                  value={coupon.promo_desc}
                  onChange={(value) => handleOnChange(value, 'promo_desc')}
                  placeholder="請填寫適用的活動條件"
                  style={{ marginBottom: -20, width: '100%' }}
                />
              </FormGrop>
            </div>
          </SubGroupWrap>
        </FormSection>
        <FormSection title="添加 Tags">
          <SubGroupWrap title="設定 Coupon Type">
            <TypePicker value={coupon.type} setValue={(value) => handleOnChange(value, 'type')} />
          </SubGroupWrap>
          <SubGroupWrap title="設定 Coupon Category">
            <CategoryPicker value={coupon.category} setValue={(value) => handleOnChange(value, 'category')} />
          </SubGroupWrap>
        </FormSection>
        <Button appearance="primary" onClick={submit} disabled={!readyForSubmit()}>
          送出
        </Button>
      </div>
    </Wrapper>
  )
}

export default EditRentalCoupon
