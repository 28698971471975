import React from 'react'
import styled from 'styled-components'
import { Popover, Whisper } from 'rsuite'
import { Cell } from 'rsuite-table'
import { TagItem } from './TagItem'

const TitleCellWrap = styled(Cell)`
	cursor: pointer;
`

const TitleCell = ({ rowData, dataKey, activeKey, toggleAddMode, handlePreviewMode, ...props }) => {
	const handleClickTitle = () => {
		activeKey === 'news' ? handlePreviewMode(rowData) : handlePreviewMode(rowData, 'delete')
		toggleAddMode()
	}

	const speaker = <Popover><b>{rowData[dataKey]}</b></Popover>
	return (
		<TitleCellWrap {...props}>
			{rowData.pin? <TagItem type={'pin'} />: null}
			<Whisper placement="top" speaker={speaker}>
				<a onClick={handleClickTitle}>{rowData[dataKey]}</a>
			</Whisper>
		</TitleCellWrap>
	)
}

export default React.memo(TitleCell)
