import React, { useEffect, useState, useCallback } from 'react'
import { Breadcrumb, Modal, Alert, Input } from 'rsuite'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import dayjs from 'dayjs'
import { isEmpty, isEqual, forEach } from 'lodash'
import { getCoordination, transPolygonStr, transLocation } from '../../utils/map'
import { getDispatchArea, postDispatchArea, updateDispatchArea } from '../../api'
import { ActionCell } from '../../components/dispatch_area'
import { TitleCell } from '../../components/happyhours'
import ListWrap from '../../components/views/ListWrap'
import MoreButtonWrap from '../../components/views/MoreButton'
import { MapCell, PreviewMap, GeoGenerator } from '../../components/map'
import AddListModel from '../../components/views/AddListModel'
import FormGrop from '../../components/views/FormGrop'
import AddListGrop from '../../components/views/AddListGrop'
import PopWarning from '../../components/popWarning'
import TableController from '../../components/table/Table'
import "react-datepicker/dist/react-datepicker.css"

const initCurrentState = {
	id: null,
	title: '',
	visibleDistance: 0,
	center: [],
	area: [],
	titleError: '',
	visibleDistanceError: '',
	centerError: '',
	areaError: '',
}

const DispatchArea = () => {
	const [cacheData, setCacheData] =  useState({})
	const [isAddMode, setAddMode] = useState(false)
	const [showMoreButton, setMoreButton] = useState(true)
	const [mapEditType, setMapEditType] = useState('')
	const [dispatchAreas, setDispatchAreas] = useState([])
	const [warning, setWarning] = useState('')
  const [currentInfo, setCurrentInfo] = useState(initCurrentState)
	// init
	useEffect(() => {
		fetDispatchAreas()
	}, [])

	// reset add mode
	useEffect(() => {
		if (isAddMode) return
		setCurrentInfo(initCurrentState)
	}, [isAddMode])

	const fetDispatchAreas = async (num) => {
		let limit = 10
		let offset = 0
		if (num) offset = num
		const response = await getDispatchArea(limit, offset)
		if (response.status !== 200) return console.log(response.data)

		const responseData = response.data.data.get.data
		if (responseData.length < limit) setMoreButton(false)
		const newData = Boolean(offset) ? dispatchAreas.concat(responseData) : responseData
		setDispatchAreas(newData)
	}

	const toggleAddMode = () => {
    setAddMode(!isAddMode)
  }

	const handlePostArea = async () => {
		const { id, title, visibleDistance, center, area } = currentInfo

		// check if required input is empty
		setCurrentInfo({
			...currentInfo,
			titleError: !title? '請填寫或選擇項目': '',
			visibleDistanceError: !visibleDistance? '請填寫或選擇項目': '',
			centerError: isEmpty(center) ? '請填寫或選擇項目': '',
			areaError: isEmpty(area) ? '請填寫或選擇項目': '',
		})
		if (!title || !visibleDistance || !center || !area) return setWarning('欄位不能為空！')

		// call api
		let body = {
			name: title,
			visible_distance: visibleDistance,
			center: {y: center[0], x: center[1]},
			area: transPolygonStr(area),
		}
		console.log('body', body)

		if (id) return handleUpdateArea(id, body)

		const response = await postDispatchArea(body)
		if (response.status !== 200) return setWarning('新增失敗，請稍候再嘗試')
		setWarning('新增成功！')
		toggleAddMode()
	}

	const handleUpdateArea = (id, body) => {
		// 因為一次只能修改一個欄位，暫時先這樣做
		forEach(body, async (value, key) => {
			if(!isEqual(value, cacheData[key])) {
				const response = await updateDispatchArea(id, { [`${key}`]: value })
				if (response.status !== 200) return setWarning('修改失敗，請稍候再嘗試')
			}
		})
    setWarning('修改成功！')
		toggleAddMode()
	}

	const handleEditOrCopy = (data, action) => {
		const { id, name, visible_distance, center, area } = data
		const { x, y } = center
		if (action === 'copy') {
			Alert.success('已複製訊息', 3000)
		} else {
			// 一次只能更新一個欄位，先 cache 住以便做檢查
			setCacheData({
				name: name,
				visible_distance: visible_distance,
				center: x > y ? { x, y } : { x: y, y: x },
				area: transPolygonStr(transLocation(area)),
			})
		}

		setCurrentInfo({
			id: action === 'copy' ? null : id,
			title: name || '',
			visibleDistance: visible_distance || 0,
			center: getCoordination(center) || [],
			area: transLocation(area) || [],
			titleError: '',
			visibleDistanceError: '',
			centerError: '',
			areaError: '',
		})
	}

	const handleOnChange = (e, setStateKey) => {
		const value = e.target ? e.target.value : e
		setCurrentInfo({
      ...currentInfo,
      [setStateKey]: value
    })
	}

	const handleWarningConfirm = () => {
		setWarning('')
		setMoreButton(true)
		fetDispatchAreas()
	}

	const handleClose = useCallback(() => {
    setMapEditType('')
  }, [])

	return (
		<>
			<div style={{ width: '100%'}}>
			{!isEmpty(mapEditType) && <Modal size={'lg'} show={!isEmpty(mapEditType)} onHide={handleClose} >
				{mapEditType === 'area' ? (
					<GeoGenerator
						type="range"
						value={currentInfo.area}
						handleClose={handleClose}
						setStateKey={'area'}
						setEditLocation={setMapEditType}
						handleOnChange={handleOnChange}
					/>) : (
					<GeoGenerator
						type="point"
						value={currentInfo.center}
						handleClose={handleClose}
						setStateKey={'center'}
						setEditLocation={setMapEditType}
						handleOnChange={handleOnChange}
					/>)
				}
			</Modal>}
			{warning && <PopWarning warning={warning} textCenter={true} confirm={handleWarningConfirm} />}
			{isAddMode ? (
				<AddListModel >
					<Breadcrumb>
						<Breadcrumb.Item onClick={toggleAddMode}>排班區</Breadcrumb.Item>
						<Breadcrumb.Item active>設置</Breadcrumb.Item>
					</Breadcrumb>
					<AddListGrop title={currentInfo.id ? '修改排班區' : '新增排班區'}>
						{currentInfo.id  && <FormGrop title={'排班區 ID'} type='children'><Input value={currentInfo.id} disabled /></FormGrop>}
						<FormGrop title={'排班區名稱*'} placeholder={'Title'} type={'input'} value={currentInfo.title} handleOnChange={handleOnChange} setStateKey={'title'} errorMessage={currentInfo.titleError} />
						<FormGrop title={'司機的可視範圍*'} placeholder={'Visible Distance'} type={'number'} value={currentInfo.visibleDistance} handleOnChange={handleOnChange} setStateKey={'visibleDistance'} errorMessage={currentInfo.visibleDistanceError} />
						<div>
							<PreviewMap
								title={'中心點設置*'}
								mapId={'center-map'}
								point={currentInfo.center}
								setEditLocation={setMapEditType}
								setStateKey={'center'}
								errorMessage={currentInfo.centerError}
							/>
							<PreviewMap
								title={'排班區範圍*'}
								mapId={'area-map'}
								latlngs={currentInfo.area}
								setEditLocation={setMapEditType}
								setStateKey={'area'}
								errorMessage={currentInfo.areaError}
							/>
						</div>
					</AddListGrop>
					<div className="campaign-button-wrap">
						<button className="campaign-confirm-button" onClick={handlePostArea}>{currentInfo.id ? '更新' : '新增'}</button>
						<button className="campaign-cancel-button" onClick={toggleAddMode}>取消</button>
					</div>
				</AddListModel>
			) : (
				<div>
					<ListWrap status={'空中排班'} addListText={'+ 新增排班區'} onClick={toggleAddMode}>
					</ListWrap>
					<TableController data={dispatchAreas} topHeight={104} rowHeight={230}>
						<Column width={60} flexGrow={0} align="center">
							<HeaderCell>ID</HeaderCell>
							<Cell dataKey="id" />
						</Column>
						<Column minWidth={140} flexGrow={1}>
							<HeaderCell>排班區</HeaderCell>
							<TitleCell dataKey="name" />
						</Column>
						<Column width={50} flexGrow={1}>
							<HeaderCell>可視範圍(m)</HeaderCell>
							<Cell dataKey="visible_distance" />
						</Column>
						<Column minWidth={250} flexGrow={2} align="center">
							<HeaderCell>中心點</HeaderCell>
							<MapCell dataKey="center" type="point" />
						</Column>
						<Column minWidth={300} flexGrow={2} align="center">
							<HeaderCell>排班區範圍</HeaderCell>
							<MapCell dataKey="area" center="center" />
						</Column>
						<Column minWidth={80} flexGrow={1}>
							<HeaderCell>更新時間</HeaderCell>
							<Cell>
								{(rowData) => dayjs(rowData.updated_at).format('YY-MM-DD HH:mm')}
							</Cell>
						</Column>
						<Column minWidth={80} flexGrow={1}>
							<HeaderCell>創建時間</HeaderCell>
							<Cell>
								{(rowData) => dayjs(rowData.created_at).format('YY-MM-DD HH:mm')}
							</Cell>
						</Column>
						<Column width={140} align="center">
							<HeaderCell>操作</HeaderCell>
							<ActionCell dataKey="id" toggleAddMode={toggleAddMode} handleEditOrCopy={handleEditOrCopy} />
						</Column>
					</TableController>
					{showMoreButton && <MoreButtonWrap>
						<div className="add-list-wrap" onClick={()=> fetDispatchAreas(dispatchAreas.length)}>
							<button className="add-list">載入更多</button>
						</div>
					</MoreButtonWrap>}
				</div>
				)}
			</div>
		</>
	)
}

export default DispatchArea
