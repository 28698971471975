import React from 'react'
import { Dropdown } from 'rsuite'

function DropdownItem({ options, onSelect, handleOnChange }) {
	return (
		<>
			{options.map((item, index) => <Dropdown.Item key={index} eventKey={index} onSelect={onSelect}>{item.value}</Dropdown.Item>)}
		</>
	)
}

export default React.memo(DropdownItem)