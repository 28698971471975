import React from 'react'
import { Popover, Whisper } from 'rsuite'
import { Cell } from 'rsuite-table'
import { isEmpty } from 'lodash'

const ModelCell = ({ rowData, dataKey, ...props }) => {
	const speaker = (
		<Popover title='Description'>
			<div className="description-wrap">
				<p><b>Line Bot訊息:</b> {rowData[dataKey].lineText}</p>
				{!isEmpty(rowData[dataKey].actions) && rowData[dataKey].actions.map((item, index) => (
					<p key={index}><b>{`Action ${index + 1}:`}</b> {`${item.type} ${item.text}`}</p>)
				)}
			</div>
		</Popover>
	)
	return (
		<Cell {...props}>
			<Whisper placement="top" speaker={speaker}>
				<a>{`版型 ${rowData[dataKey].model}`}</a>
			</Whisper>
		</Cell>
	)
}

export default ModelCell
