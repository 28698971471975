import React from 'react'
import SearchInput from './SearchInput'
import ToggleController from './ToggleController'

const TableFilter = ({ inProgress, searchWord, setInProgress, setSearchWord }) => {
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <ToggleController inProgress={inProgress} setInProgress={setInProgress}/>
      <SearchInput value={searchWord} setSearchWord={setSearchWord}/>
		</div>
  )
}

export default React.memo(TableFilter)