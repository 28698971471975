import React, { useMemo } from 'react'
import { Tag } from 'rsuite'
import { Cell } from 'rsuite-table'
import { REVIEW_ITEMS } from '../../pages/equipped/constants'

const getTagInfo = (type) => {
  switch (type) {
    case 'NEW':
      return {
        name: '未審核',
        color: 'yellow',
      }
    case 'REJECT':
      return {
        name: '不通過',
        color: 'red',
      }
    case 'BLOCK':
      return {
        name: '未通過，且不能重新上傳',
        color: 'red',
      }
    case 'ACCEPT':
      return {
        name: '已通過',
        color: 'green',
      }
    default:
      return null
  }
}

export const StatusCell = ({ rowData, dataKey, ...props }) => {
  const { color, name } = useMemo(() => getTagInfo(rowData.review_type), [rowData])
  return (
    <Cell {...props}>
      <Tag color={color}>{name}</Tag>
      {REVIEW_ITEMS[rowData.type]}
    </Cell>
  )
}

export default React.memo(StatusCell)
