import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { isEmpty } from 'lodash'
import { ProfileSection, EquitySection, CouponSection, TRewardSection, ActivityWall } from './preview'

dayjs.extend(isBetween)

const Root = styled.div`
  position: relative;
  min-width: 375px;
  height: max-content;
  background-color: #f5f5f5;
  border-radius: 7px;
  margin: 10px 20px;
  overflow: hidden;
`
const NavigationBar = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(21, 211, 137);
  ${({ $customBg }) => $customBg && `background: ${$customBg};`}
  h6 {
    color: #ffffff;
  }
  img {
    width: 32px;
    height: 24px;
  }
`
const NavIcon = styled.div`
  width: 32px;
  height: 24px;
  ${({ $iconUrl }) => `background: url(${$iconUrl});`}
  cursor: pointer;
  margin: 0 8px;
`
const Wrapper = styled.div`
  position: relative;
  top: -20px;
  z-index: 2;
`

const MemberCenterPreview = ({ previewData, setPreviewData }) => {
  if (isEmpty(previewData)) return <Root />
  const { bulletin_view, profile_view } = previewData
  const { mainColor } = bulletin_view
  return (
    <Root>
      <NavigationBar $customBg={mainColor}>
        <NavIcon $iconUrl="https://static.taxigo-cdn.com/icons/general/navbar-left-arrow.svg" />
        <h6>會員中心</h6>
        <NavIcon $iconUrl="https://static.taxigo-cdn.com/icons/general/navbar-close.svg" onClick={() => setPreviewData({})} />
      </NavigationBar>
      <ProfileSection mainColor={mainColor} profile_view={profile_view} />
      <Wrapper>
        <EquitySection />
        <CouponSection />
        {!isEmpty(previewData.bulletin_view) && <ActivityWall previewData={previewData} />}
        <TRewardSection />
      </Wrapper>
    </Root>
  )
}

export default MemberCenterPreview
