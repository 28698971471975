import React, { useState } from 'react'
import { Modal, Button, Input } from 'rsuite'
import { FormGroup } from './styles'

const UnblockDriverModal = React.memo(({ show, close, confirm }) => {
  const [note, setNote] = useState('')
  const submit = () => confirm(note)

  return (
    <Modal backdrop={true} show={show} onHide={close} size="sm">
      <Modal.Header>
        <Modal.Title>解除封鎖</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginTop: '10px' }}>
        <FormGroup>
          <FormGroup.Label>解除原因</FormGroup.Label>
          <FormGroup.Control>
            <Input value={note} onChange={setNote} componentClass="textarea" rows={3} placeholder="Note" />
          </FormGroup.Control>
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={close} appearance="subtle">
            取消
          </Button>
          <Button onClick={submit} color="red" disabled={!note}>
            解除封鎖
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
})

export default UnblockDriverModal
