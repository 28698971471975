import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, ButtonToolbar, ControlLabel, Form, FormControl, FormGroup, HelpBlock, Modal, Radio, RadioGroup } from 'rsuite'
import _, { cloneDeep } from 'lodash'
import CouponInputList from './CouponInputList'
import getID from '../../../utils/getID'

function CreateModal({ show, onHide, onConfirm, availableSenderCouponRatio, availableReceiverCouponRatio }) {
  const [coupons, setCoupons] = useState([]) // { id:string, value:string, type:'ride'|'rental'}[]
  const [data, setData] = useState({
    type: 'sender',
    title: '',
    webImageUrl: '',
    ratio: 0,
  })

  useEffect(() => {
    if (!show) {
      setCoupons([])
      setData({
        type: 'sender',
        title: '',
        webImageUrl: '',
        ratio: 0,
      })
    }
  }, [show])

  const handleAddCoupon = useCallback(
    (type) => () => {
      setCoupons((prev) => {
        return [
          ...prev,
          {
            id: getID(),
            value: '',
            type,
          },
        ]
      })
    },
    [],
  )

  const handleCouponRemoved = useCallback((id) => {
    setCoupons((prev) => {
      _.remove(prev, (n) => n.id === id)
      return [...prev]
    })
  }, [])

  const handleCouponInputChange = useCallback((id, value) => {
    setCoupons((prev) => {
      return prev.map((item) => (item.id === id ? { ...item, value: Number(value) || undefined } : item))
    })
  }, [])

  const handleChange = useCallback(
    (name) => (value) => {
      setData((prev) => {
        const next = cloneDeep(prev)
        next[name] = value
        return next
      })
    },
    [],
  )

  const handleSubmit = useCallback(() => {
    const result = { ...data, ratio: Math.floor(data.ratio * 1000), taxi: [], rental: [] }
    coupons.forEach((item) => {
      if (Number(item.value)) {
        result[item.type].push(Number(item.value))
      }
    })
    onConfirm(result)
  }, [data, coupons, onConfirm])

  const availableRatio = useMemo(
    () => (data.type === 'sender' ? availableSenderCouponRatio : availableReceiverCouponRatio),
    [availableSenderCouponRatio, availableReceiverCouponRatio, data],
  )

  return (
    <Modal size="sm" show={show} onHide={onHide} backdrop="static">
      <Modal.Header>
        <Modal.Title>新增優惠券</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <FormGroup>
            <ControlLabel>獎項類型</ControlLabel>
            <FormControl value={data.type} accepter={RadioGroup} onChange={handleChange('type')}>
              <Radio value="sender">主態</Radio>
              <Radio value="receiver">客態</Radio>
            </FormControl>
          </FormGroup>
          <FormGroup>
            <ControlLabel>獎項標題</ControlLabel>
            <FormControl onChange={handleChange('title')} />
          </FormGroup>
          <FormGroup>
            <ControlLabel>獎項圖片</ControlLabel>
            <FormControl onChange={handleChange('webImageUrl')} placeholder="https://...." />
            <HelpBlock tooltip>請填入圖片網址</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>獎項機率</ControlLabel>
            <FormControl onChange={handleChange('ratio')} placeholder="最多至小數點第三位" />
            <HelpBlock>{`目前剩餘可分配機率為 ${availableRatio}`}</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>優惠券</ControlLabel>
            <ButtonToolbar>
              <Button appearance="primary" size="sm" onClick={handleAddCoupon('taxi')}>
                新增乘車券
              </Button>
              <Button appearance="primary" size="sm" onClick={handleAddCoupon('rental')}>
                新增租車券
              </Button>
            </ButtonToolbar>
            <CouponInputList data={coupons} onChange={handleCouponInputChange} onRemove={handleCouponRemoved} />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit} appearance="primary" disabled={data.ratio > availableRatio}>
          確認
        </Button>
        <Button onClick={onHide} appearance="subtle">
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CreateModal
