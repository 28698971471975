import React, { Component } from 'react'
import { IconButton, Icon, Divider } from 'rsuite'
import { Cell } from 'rsuite-table'
import { deleteMktBroadcasting } from '../../api'

export class ActionCell extends Component {
	handleAction = (action) => {
		const { rowData, toogleModle, handleUpdate } = this.props
		handleUpdate(rowData, action)
		toogleModle()
	}
	handleDeleteAction = () => {
		const { rowData, dataKey, fetchData } = this.props
		const body = { data: { id: rowData[dataKey] } }
		deleteMktBroadcasting(body).then((data) => {
			if (data.status !== 200) return
			fetchData()
		})
	}
	render() {
		const { rowData, dataKey, children, fetchData, toogleModle, handleUpdate, ...props } = this.props
		return (
			<Cell {...props} className="link-group" style={{ display: 'flex', alignItems: 'center' }}>
				<IconButton
					appearance="subtle"
					onClick={this.handleAction}
					icon={<Icon icon="edit2" />}
				/>
				<Divider vertical />
				<IconButton
					appearance="subtle"
					onClick={() => this.handleAction('copy')}
					icon={<Icon icon="copy-o" />}
				/>
				<Divider vertical />
				<IconButton
					appearance="subtle"
					onClick={this.handleDeleteAction}
					icon={<Icon icon="trash2" />}
				/>
			</Cell>
		)
	}
}

export default ActionCell
