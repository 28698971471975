import styled from 'styled-components'

const Label = styled.div`
  margin-top: 6px;
  font-weight: bold;
  min-width: 56px;
`

const Control = styled.div`
  flex-grow: 1;
`

export const FormGroup = styled.div`
  padding: 0 12px;
  margin-top: 20px;
  display: flex;
  column-gap: 20px;
`

FormGroup.Label = Label
FormGroup.Control = Control
