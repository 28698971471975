import React from 'react'
import { List, FlexboxGrid, InputNumber, RadioGroup, Radio, IconButton, Icon } from 'rsuite'

const getAllowedOptions = (currentAmount, maxAmount) => {
  const options = new Map([
    ['STOCK', false],
    ['EXCHANGE', false],
    ['RETURN', false],
  ])
  switch (true) {
    case currentAmount === 0:
      options.set('STOCK', true)
      break
    case maxAmount >= 0 && currentAmount >= maxAmount:
      options.set('EXCHANGE', true)
      options.set('RETURN', true)
      break
    default:
      options.set('STOCK', true)
      options.set('EXCHANGE', true)
      options.set('RETURN', true)
  }

  return options
}

const IdentifiersListItem = ({ item, setItem, remove, amountMap }) => {
  const options = getAllowedOptions(amountMap.get(item.id), item.max_amount)

  const handleActionChange = (value) => {
    setItem({ ...item, action: value })
  }

  const handleAmountChange = (value) => {
    setItem({ ...item, amount: Number(value) })
  }

  const handleRemove = (event) => {
    typeof remove === 'function' && remove(event.target.getAttribute('attr-item-id'))
  }

  return (
    <List.Item style={{ lineHeight: '40px' }}>
      <FlexboxGrid align="middle">
        <FlexboxGrid.Item colspan={7}>{item.name}</FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5}>
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={8}>
              <div>數量</div>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={2} />
            <FlexboxGrid.Item colspan={12}>
              <InputNumber value={item.amount} onChange={handleAmountChange} min={1} max={2} />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={10} style={{ textAlign: 'center' }}>
          <RadioGroup inline value={item.action} onChange={handleActionChange}>
            <Radio value="STOCK" disabled={!options.get('STOCK')}>
              新領取
            </Radio>
            <Radio value="EXCHANGE" disabled={!options.get('EXCHANGE')}>
              更換
            </Radio>
            <Radio value="RETURN" disabled={!options.get('RETURN')}>
              繳回
            </Radio>
          </RadioGroup>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={2} style={{ textAlign: 'right' }}>
          <IconButton onClick={handleRemove} appearance="subtle" icon={<Icon icon="close-circle" attr-item-id={item.id} />} />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </List.Item>
  )
}

export default IdentifiersListItem
