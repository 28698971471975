import { ACTION } from '../constants/actions'

const initialState = {
  item: {},
  type: '', // COPY,
}

function damaPlusReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.SET_COPY_DAMA_ITEM:
      return {
        ...state,
        type: 'COPY',
        item: action.item,
      }
    case ACTION.SET_READ_DAMA_ITEM:
      return {
        ...state,
        type: 'READ',
        item: action.item,
      }

    default:
      return state
  }
}

export { initialState, damaPlusReducer }
