import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Alert,
  Breadcrumb,
  Button,
  ButtonGroup,
  ButtonToolbar,
  CheckPicker,
  Checkbox,
  CheckboxGroup,
  ControlLabel,
  DatePicker,
  FormGroup,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputNumber,
  Modal,
  Panel,
  Radio,
  RadioGroup,
  SelectPicker,
} from 'rsuite'
import { Link, useHistory } from 'react-router-dom'
import { useForm, Controller, useWatch, useFieldArray } from 'react-hook-form'
import { cloneDeep, differenceWith, isEmpty } from 'lodash'
import dayjs from 'dayjs'
import { CustomForm, FeatureMapCol, FeatureMapBox, Heading, Root, TimePeriodRow, TimePeriodStack, FeatureMapContent } from './form.style'
import useFormHelper, { COUPON_FORM_FEATURES } from '../../hook/coupons/useFormHelper'
import { GeoGenerator, PreviewMap } from '../map'
import { createCoupon, updateCoupon } from '../../api'

const CouponForm = ({ feature = COUPON_FORM_FEATURES.CREATE, initialValues }) => {
  const history = useHistory()
  const [editLocationModal, setEditLocationModal] = useState()

  const title = useMemo(() => {
    switch (feature) {
      case COUPON_FORM_FEATURES.UPDATE:
        return '編輯優惠券'
      case COUPON_FORM_FEATURES.DUPLICATE:
        return '複製優惠券'
      default:
        return '新增優惠券'
    }
  }, [feature])

  const {
    settings: { types, categories },
    static: { promoTypeOptions, discountTypes, discountWays, lifespanTypes, carFunctionalTypes, carTypes, ondemandTypes, airportAreas },
    helper: { filterFormValuesForSubmit, generateCode, dateTransform, formatDateTransform, numberTransform, featureMapTransform, locationTransform },
    defaultValues,
    schema,
  } = useFormHelper({ feature, initialValues })

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  })

  const [
    discountType,
    discountWay,
    fixedAmount,
    percentOffAmount,
    percentOffMaxAmount,
    numberOfCoupons,
    unlimitedNumberOfCoupons,
    minimumChargeToggle,
    validDaysToggle,
    invalidDate,
    expireInDays,
    linePointCouponSettingsShadow_feedbackAmount,
    linePointCouponSettingsShadow_feedbackRatio,
    linePointCouponSettingsShadow_maxAmount,
    lifespanType,
    lifespanDailyPeriods,
    lifespanWeeklyPeriods,
    lifespanWeeklyInterval,
    lifespanWeeklyDayOfWeek,
  ] = useWatch({
    control,
    name: [
      'discount_type',
      'discount_way',
      'fixed_amount',
      'percent_off_amount',
      'percent_off_max_amount',
      'number_of_coupons',
      'unlimited_number_of_coupons',
      'minimum_charge_toggle',
      'valid_days_toggle',
      'invalid_date',
      'expire_in_days',
      'line_point_coupon_settings_shadow.feedback_amount',
      'line_point_coupon_settings_shadow.feedback_ratio',
      'line_point_coupon_settings_shadow.max_amount',
      'lifespan_type',
      'lifespan_daily_periods',
      'lifespan_weekly_periods',
      'lifespan_weekly_interval',
      'lifespan_weekly_day_of_week',
    ],
  })

  const featureMapGroup = useWatch({ control, name: 'feature_map_group' })

  const {
    fields: lifespanDailyFields,
    append: appendLifespanDaily,
    remove: removeLifespanDaily,
  } = useFieldArray({
    control,
    name: 'lifespan_daily_periods',
  })

  const {
    fields: lifespanWeeklyFields,
    append: appendLifespanWeekly,
    remove: removeLifespanWeekly,
  } = useFieldArray({
    control,
    name: 'lifespan_weekly_periods',
  })

  const {
    fields: featureMapGroupFields,
    append: appendFeatureMapGroup,
    remove: removeFeatureMapGroup,
  } = useFieldArray({ control, name: 'feature_map_group' })

  const handleDiscountTypeChange = (discountType) => {
    switch (discountType) {
      case discountTypes.normal:
        setValue('discount_way', discountWays.fixed_amount)
        setValue('coupon_is_line_point_type', false)
        break
      case discountTypes.line_points:
        setValue('discount_way', discountWays.fixed_line_points)
        setValue('coupon_is_line_point_type', true)
        break
      default:
        break
    }
  }

  useEffect(() => {
    switch (discountWay) {
      case discountWays.fixed_amount:
        setValue('amount', fixedAmount)
        setValue('max_amount', undefined)
        break
      case discountWays.percent_off:
        setValue('amount', percentOffAmount)
        setValue('max_amount', percentOffMaxAmount)
        break
      case discountWays.fixed_line_points:
        setValue('line_point_coupon_settings', {
          feedback_amount: linePointCouponSettingsShadow_feedbackAmount,
          max_amount: null,
          feedback_ratio: 0,
        })
        break
      case discountWays.line_point_ratio:
        setValue('line_point_coupon_settings', {
          feedback_amount: 0,
          max_amount: linePointCouponSettingsShadow_maxAmount,
          feedback_ratio: linePointCouponSettingsShadow_feedbackRatio,
        })
        break
      default:
        break
    }

    if ([discountWays.fixed_amount, discountWays.percent_off].includes(discountWay)) {
      // reset LINE point coupon settings
      setValue('line_point_coupon_settings', {
        feedback_amount: 0,
        feedback_ratio: 0,
        max_amount: null,
      })
    }

    if ([discountWays.fixed_line_points, discountWays.line_point_ratio].includes(discountWay)) {
      // reset normal coupon settings
      setValue('max_amount', undefined)
      setValue('amount', 0)
    }
  }, [
    discountWay,
    setValue,
    fixedAmount,
    percentOffAmount,
    discountWays,
    percentOffMaxAmount,
    linePointCouponSettingsShadow_feedbackAmount,
    linePointCouponSettingsShadow_maxAmount,
    linePointCouponSettingsShadow_feedbackRatio,
  ])

  useEffect(() => {
    if (unlimitedNumberOfCoupons) {
      setValue('total_number_of_coupons', -1)
    } else {
      setValue('total_number_of_coupons', numberOfCoupons)
    }
    return () => {}
  }, [unlimitedNumberOfCoupons, numberOfCoupons, setValue])

  useEffect(() => {
    if (!minimumChargeToggle) {
      setValue('minimum_charge', 0)
    }
  }, [minimumChargeToggle, setValue])

  useEffect(() => {
    if (validDaysToggle) {
      setValue('valid_days', expireInDays)
    } else {
      setValue('valid_days', invalidDate)
    }
  }, [setValue, invalidDate, expireInDays, validDaysToggle])

  useEffect(() => {
    switch (lifespanType) {
      case lifespanTypes.all:
        setValue('designated_daily_timeframe', undefined)
        setValue('designated_weekly_timeframe', undefined)

        break
      case lifespanTypes.designated_daily_timeframe:
        {
          const result = cloneDeep(lifespanDailyPeriods)
          if (feature === COUPON_FORM_FEATURES.UPDATE) {
            const designatedDailyTimeframeShadow = getValues('designated_daily_timeframe_shadow')
            const toDelete = designatedDailyTimeframeShadow.map((row) => ({ ...row, time_of_day_end: undefined, time_of_day_start: undefined }))
            setValue('designated_daily_timeframe', [...result, ...toDelete])
          } else {
            setValue('designated_daily_timeframe', result)
          }

          setValue('designated_weekly_timeframe', undefined)
        }
        break
      case lifespanTypes.designated_weekly_timeframe:
        setValue('designated_daily_timeframe', undefined)
        setValue('designated_weekly_timeframe', [
          {
            day_of_week: Array.isArray(lifespanWeeklyDayOfWeek)
              ? lifespanWeeklyDayOfWeek.reduce(
                  (prev, current) => {
                    const next = { ...prev, [current]: 1 }
                    return next
                  },
                  { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 },
                )
              : { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 },
            interval: lifespanWeeklyInterval,
            hours_setting: lifespanWeeklyPeriods,
          },
        ])
        break
      default:
        break
    }
  }, [
    lifespanType,
    lifespanTypes,
    getValues,
    setValue,
    lifespanDailyPeriods,
    lifespanWeeklyInterval,
    lifespanWeeklyPeriods,
    lifespanWeeklyDayOfWeek,
    feature,
  ])

  useEffect(() => {
    const result = featureMapGroup.reduce((prev, current) => {
      const startLocations = Object.keys(current.startLocation).map((key) => {
        return {
          start_location: current.startLocation[key],
          coupon: current.coupon,
          coupon_settings_group_id: current.id,
        }
      })
      const endLocations = Object.keys(current.endLocation).map((key) => {
        return {
          end_location: current.endLocation[key],
          coupon: current.coupon,
          coupon_settings_group_id: current.id,
        }
      })

      const designatedAreasForDelete =
        feature === COUPON_FORM_FEATURES.UPDATE && Array.isArray(current.designated_areas_shadow)
          ? current.designated_areas_shadow.map((row) => ({ ...row, end_location: undefined, start_location: undefined }))
          : []

      const item = {
        id: current.id,
        coupon: current.coupon,
        cartype_feature_map: current.cartype_feature_map,
        // 如果選擇一般則跟車種的 feature 一樣
        functional_feature_map:
          typeof current.functional_feature_map === 'string'
            ? current.functional_feature_map
                .replace('as_car_type', current.cartype_feature_map || '')
                .split(',')
                .filter((item) => item.length)
                .join(',') // 把多餘的 , 清掉
            : current.functional_feature_map,
        is_ondemand: current.is_ondemand,
        designated_areas: [...startLocations, ...endLocations, ...designatedAreasForDelete],
        action: 'edit',
      }
      prev.push(item)
      return prev
    }, [])

    const originCouponSettingsGroup = getValues('coupon_settings_group') || []

    if (feature === COUPON_FORM_FEATURES.UPDATE) {
      const diff = differenceWith(originCouponSettingsGroup, result, (a, b) => a.id === b.id)
      diff.forEach((row) => result.push({ ...row, action: 'delete' }))
    }

    console.log('coupon_settings_group: ', result)

    setValue('coupon_settings_group', result)
  }, [featureMapGroup, setValue, getValues, feature])

  const handleAddPeriodForDaily = useCallback(() => {
    appendLifespanDaily({ time_of_day_start: dayjs().format('HH:mm:ss'), time_of_day_end: dayjs().format('HH:mm:ss') })
  }, [appendLifespanDaily])

  const handleRemovePeriodForDaily = useCallback(
    (index) => () => {
      removeLifespanDaily(index)
    },
    [removeLifespanDaily],
  )

  const handleAddPeriodForWeekly = useCallback(() => {
    appendLifespanWeekly({ time_of_day_start: formatDateTransform.out(new Date()), time_of_day_end: formatDateTransform.out(new Date()) })
  }, [appendLifespanWeekly, formatDateTransform])

  const handleRemovePeriodForWeekly = useCallback(
    (index) => () => {
      removeLifespanWeekly(index)
    },
    [removeLifespanWeekly],
  )

  const handleCreatePromoCode = useCallback(() => {
    const code = generateCode(7)
    setValue('promo_code', code)
  }, [generateCode, setValue])

  const handleAddFeatureMap = useCallback(() => {
    appendFeatureMapGroup({ cartype_feature_map: null, functional_feature_map: null, is_ondemand: null, startLocation: {}, endLocation: {} })
  }, [appendFeatureMapGroup])

  const handleRemoveFeatureMapGroup = useCallback(
    (index) => () => {
      removeFeatureMapGroup(index)
    },
    [removeFeatureMapGroup],
  )

  const handleLocationChange = useCallback(
    (value, key) => {
      setValue(key, value)
    },
    [setValue],
  )

  const submit = useCallback(
    async (values) => {
      try {
        const payload = filterFormValuesForSubmit(values)
        console.log('payload for submit: ', payload)
        await schema.validate(payload)

        switch (feature) {
          case COUPON_FORM_FEATURES.CREATE:
            {
              const resp = await createCoupon(payload)
              if (resp.status !== 200) throw new Error(resp.data.msg)
              Alert.success('新增完成！')
            }
            break
          case COUPON_FORM_FEATURES.UPDATE:
            {
              const resp = await updateCoupon({ ...payload, promo_id: initialValues?.promo_id })
              if (resp.status !== 200) throw new Error(resp.data.msg)
              Alert.success('編輯完成！')
            }
            break
          case COUPON_FORM_FEATURES.DUPLICATE:
            {
              const resp = await createCoupon(payload)
              if (resp.status !== 200) throw new Error(resp.data.msg)
              Alert.success('複製完成！')
            }
            break
          default:
            break
        }
        history.push('/marketing/coupons')
      } catch (error) {
        Alert.error(error.message)
      }
    },
    [feature, filterFormValuesForSubmit, history, schema, initialValues],
  )

  return (
    <>
      <Root>
        <Breadcrumb>
          <Breadcrumb.Item componentClass={Link} to="/marketing/coupons">
            優惠券
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{title}</Breadcrumb.Item>
        </Breadcrumb>
        <Heading>{title}</Heading>
        <CustomForm>
          <Panel header="基本資訊" bordered>
            <FormGroup>
              <ControlLabel>適用活動類型</ControlLabel>
              <Controller
                control={control}
                name="promo_type"
                render={({ field }) => <SelectPicker data={promoTypeOptions} searchable={false} cleanable={false} {...field} />}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>優惠券名稱</ControlLabel>
              <Controller
                control={control}
                name="promo_title"
                render={({ field }) => {
                  return <Input {...field} value={field.value || ''} />
                }}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>優惠代碼</ControlLabel>
              <Controller
                control={control}
                name="promo_code"
                render={({ field }) => (
                  <InputGroup>
                    <Input {...field} value={field.value || ''} />
                    <InputGroup.Button color="green" onClick={handleCreatePromoCode}>
                      產生代碼
                    </InputGroup.Button>
                  </InputGroup>
                )}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>開始領取時間</ControlLabel>
              <Controller
                control={control}
                name="start_date"
                render={({ field: { value, onChange, ...field } }) => (
                  <DatePicker
                    cleanable={false}
                    format="YYYY-MM-DD HH:mm:ss"
                    ranges={[
                      {
                        label: 'Now',
                        value: new Date(),
                      },
                    ]}
                    placeholder="選擇日期時間"
                    value={dateTransform.in(value)}
                    onChange={(val) => onChange(dateTransform.out(val))}
                    {...field}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>結束領取時間</ControlLabel>
              <Controller
                control={control}
                name="end_date"
                render={({ field: { value, onChange, ...field } }) => (
                  <DatePicker
                    cleanable={false}
                    format="YYYY-MM-DD HH:mm:ss"
                    ranges={[
                      {
                        label: 'Now',
                        value: new Date(),
                      },
                    ]}
                    placeholder="選擇日期時間"
                    value={dateTransform.in(value)}
                    onChange={(val) => onChange(dateTransform.out(val))}
                    {...field}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>類型選擇</ControlLabel>
              <Controller
                control={control}
                name="discount_type"
                render={({ field: { onChange, ...field } }) => (
                  <RadioGroup
                    onChange={(v) => {
                      onChange(v)
                      handleDiscountTypeChange(v)
                    }}
                    {...field}
                  >
                    <Radio value={discountTypes.normal}>一般乘車券</Radio>
                    <Radio value={discountTypes.line_points}>LINE Points 回饋券</Radio>
                  </RadioGroup>
                )}
              />
            </FormGroup>
          </Panel>
          <Panel header="數量及金額設定" bordered>
            <FormGroup>
              <ControlLabel>每次領取張數</ControlLabel>
              <Controller
                control={control}
                name="count"
                render={({ field: { onChange, ...field } }) => <InputNumber min={1} onChange={(v) => onChange(numberTransform.out(v))} {...field} />}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>發出總張數</ControlLabel>
              <Controller
                control={control}
                name="unlimited_number_of_coupons"
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Radio value>無限制</Radio>
                    <Radio value={false}>
                      限制
                      <Controller
                        control={control}
                        name="number_of_coupons"
                        disabled={unlimitedNumberOfCoupons}
                        render={({ field: { onChange, ...field } }) => (
                          <InputNumber
                            className="in-radio-input"
                            postfix="張"
                            min={0}
                            onChange={(v) => onChange(numberTransform.out(v))}
                            {...field}
                          />
                        )}
                      />
                    </Radio>
                  </RadioGroup>
                )}
              />
            </FormGroup>
            {discountType === discountTypes.normal && (
              <FormGroup>
                <ControlLabel>折扣方式</ControlLabel>
                <Controller
                  control={control}
                  name="discount_way"
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <Radio value={discountWays.fixed_amount}>
                        固定金額
                        <Controller
                          control={control}
                          name="fixed_amount"
                          disabled={discountWay !== discountWays.fixed_amount}
                          render={({ field: { onChange, ...field } }) => (
                            <InputNumber
                              className="in-radio-input"
                              prefix="每次折抵"
                              postfix="元"
                              min={0}
                              onChange={(v) => onChange(numberTransform.out(v))}
                              {...field}
                            />
                          )}
                        />
                      </Radio>
                      <Radio value={discountWays.percent_off}>
                        優惠折數
                        <Controller
                          control={control}
                          name="percent_off_amount"
                          disabled={discountWay !== discountWays.percent_off}
                          render={({ field: { onChange, ...field } }) => (
                            <InputNumber
                              className="in-radio-input"
                              prefix="打折"
                              min={0.0}
                              max={0.9}
                              step={0.01}
                              onChange={(v) => onChange(numberTransform.out(v))}
                              {...field}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="percent_off_max_amount"
                          disabled={discountWay !== discountWays.percent_off}
                          render={({ field: { onChange, ...field } }) => (
                            <InputNumber
                              className="in-radio-input"
                              prefix="最高上限"
                              postfix="元"
                              min={0}
                              onChange={(v) => onChange(numberTransform.out(v))}
                              {...field}
                            />
                          )}
                        />
                      </Radio>
                    </RadioGroup>
                  )}
                />
              </FormGroup>
            )}
            {discountType === discountTypes.line_points && (
              <FormGroup>
                <ControlLabel>回饋方式</ControlLabel>
                <Controller
                  control={control}
                  name="discount_way"
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <Radio value={discountWays.fixed_line_points}>
                        固定點數
                        <Controller
                          control={control}
                          name="line_point_coupon_settings_shadow.feedback_amount"
                          disabled={discountWay !== discountWays.fixed_line_points}
                          render={({ field: { onChange, ...field } }) => (
                            <InputNumber
                              className="in-radio-input"
                              prefix="每次回饋"
                              postfix="點"
                              min={0}
                              onChange={(v) => onChange(numberTransform.out(v))}
                              {...field}
                            />
                          )}
                        />
                      </Radio>
                      <Radio value={discountWays.line_point_ratio}>
                        比例
                        <Controller
                          control={control}
                          name="line_point_coupon_settings_shadow.feedback_ratio"
                          disabled={discountWay !== discountWays.line_point_ratio}
                          render={({ field: { onChange, ...field } }) => (
                            <InputNumber
                              className="in-radio-input"
                              prefix="比例"
                              min={0.0}
                              max={1}
                              step={0.01}
                              onChange={(v) => onChange(numberTransform.out(v))}
                              {...field}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="line_point_coupon_settings_shadow.max_amount"
                          disabled={discountWay !== discountWays.line_point_ratio}
                          render={({ field: { onChange, ...field } }) => (
                            <InputNumber
                              className="in-radio-input"
                              prefix="上限"
                              postfix="點"
                              min={0}
                              onChange={(v) => onChange(numberTransform.out(v))}
                              {...field}
                            />
                          )}
                        />
                      </Radio>
                    </RadioGroup>
                  )}
                />
              </FormGroup>
            )}
            <FormGroup>
              <ControlLabel>是否現金使用</ControlLabel>
              <Controller
                control={control}
                name="is_for_cash"
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Radio value={0}>不可以</Radio>
                    <Radio value={1}>可以</Radio>
                  </RadioGroup>
                )}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>低消門檻</ControlLabel>
              <Controller
                control={control}
                name="minimum_charge_toggle"
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Radio value={false} disabled={field.disabled}>
                      無低消
                    </Radio>
                    <Radio value disabled={field.disabled}>
                      低消
                      <Controller
                        control={control}
                        name="minimum_charge"
                        disabled={!minimumChargeToggle}
                        render={({ field: { onChange, ...field } }) => (
                          <InputNumber
                            className="in-radio-input"
                            postfix="元"
                            min={0}
                            onChange={(v) => onChange(numberTransform.out(v))}
                            {...field}
                          />
                        )}
                      />
                    </Radio>
                  </RadioGroup>
                )}
              />
            </FormGroup>
          </Panel>
          <Panel header="Feature Map 設定" bordered>
            {featureMapGroupFields.map((item, i) => (
              <FeatureMapBox key={item.id} shaded>
                <FeatureMapContent>
                  <FeatureMapCol>
                    <ControlLabel>主要車種</ControlLabel>
                    <Controller
                      control={control}
                      name={`feature_map_group.${i}.cartype_feature_map`}
                      render={({ field: { ref: _ref, value, onChange, ...field } }) => {
                        return (
                          <CheckPicker
                            data={carTypes}
                            searchable={false}
                            value={featureMapTransform.in(value)}
                            onChange={(v) => onChange(featureMapTransform.out(v))}
                            {...field}
                          />
                        )
                      }}
                    />
                  </FeatureMapCol>
                  <FeatureMapCol>
                    <ControlLabel>次要車種</ControlLabel>
                    <Controller
                      control={control}
                      name={`feature_map_group.${i}.functional_feature_map`}
                      render={({ field: { ref: _ref, value, onChange, ...field } }) => {
                        return (
                          <CheckPicker
                            data={carFunctionalTypes}
                            searchable={false}
                            value={featureMapTransform.in(value)}
                            onChange={(v) => onChange(featureMapTransform.out(v))}
                            {...field}
                          />
                        )
                      }}
                    />
                  </FeatureMapCol>
                  <FeatureMapCol>
                    <ControlLabel>行程類型</ControlLabel>
                    <Controller
                      control={control}
                      name={`feature_map_group.${i}.is_ondemand`}
                      render={({ field: { ref: _ref, ...field } }) => {
                        return <SelectPicker data={ondemandTypes} searchable={false} cleanable={false} {...field} />
                      }}
                    />
                  </FeatureMapCol>
                  <FeatureMapCol>
                    <FeatureMapCol>
                      <Controller
                        control={control}
                        name={`feature_map_group.${i}.startLocation`}
                        render={({ field: { ref: _ref, ...field } }) => {
                          return (
                            <PreviewMap
                              title="上車範圍"
                              mapId={`coupons-start-map-${i}`}
                              latlngs={field.value}
                              setEditLocation={setEditLocationModal}
                              setStateKey={`feature_map_group.${i}.startLocation`}
                              multi
                            />
                          )
                        }}
                      />
                      <Controller
                        control={control}
                        name={`feature_map_group.${i}.startLocation`}
                        render={({ field: { onChange, value } }) => (
                          <ButtonToolbar>
                            <ButtonGroup vertical>
                              <Button
                                appearance="ghost"
                                onClick={() => {
                                  onChange(locationTransform.out(value, { 9999: airportAreas.taipei }))
                                }}
                              >
                                松山機場
                              </Button>
                              <Button
                                appearance="ghost"
                                onClick={() => {
                                  onChange(locationTransform.out(value, { 9998: airportAreas.taoyuan }))
                                }}
                              >
                                桃園機場
                              </Button>
                              <Button
                                appearance="ghost"
                                onClick={() => {
                                  onChange(locationTransform.out(value, { 9997: airportAreas.taichung }))
                                }}
                              >
                                台中機場
                              </Button>
                              <Button
                                appearance="ghost"
                                onClick={() => {
                                  onChange(locationTransform.out(value, { 9996: airportAreas.kaohsiung }))
                                }}
                              >
                                高雄機場
                              </Button>
                            </ButtonGroup>
                          </ButtonToolbar>
                        )}
                      />
                    </FeatureMapCol>
                    <FeatureMapCol>
                      <Controller
                        control={control}
                        name={`feature_map_group.${i}.endLocation`}
                        render={({ field: { ref: _ref, ...field } }) => {
                          return (
                            <PreviewMap
                              title="下車範圍"
                              mapId={`coupons-end-map-${i}`}
                              latlngs={field.value}
                              setEditLocation={setEditLocationModal}
                              setStateKey={`feature_map_group.${i}.endLocation`}
                              multi
                            />
                          )
                        }}
                      />
                      <Controller
                        control={control}
                        name={`feature_map_group.${i}.endLocation`}
                        render={({ field: { onChange, value } }) => (
                          <ButtonToolbar>
                            <ButtonGroup vertical>
                              <Button
                                appearance="ghost"
                                onClick={() => {
                                  onChange(locationTransform.out(value, { 9999: airportAreas.taipei }))
                                }}
                              >
                                松山機場
                              </Button>
                              <Button
                                appearance="ghost"
                                onClick={() => {
                                  onChange(locationTransform.out(value, { 9998: airportAreas.taoyuan }))
                                }}
                              >
                                桃園機場
                              </Button>
                              <Button
                                appearance="ghost"
                                onClick={() => {
                                  onChange(locationTransform.out(value, { 9997: airportAreas.taichung }))
                                }}
                              >
                                台中機場
                              </Button>
                              <Button
                                appearance="ghost"
                                onClick={() => {
                                  onChange(locationTransform.out(value, { 9996: airportAreas.kaohsiung }))
                                }}
                              >
                                高雄機場
                              </Button>
                            </ButtonGroup>
                          </ButtonToolbar>
                        )}
                      />
                    </FeatureMapCol>
                  </FeatureMapCol>
                </FeatureMapContent>
                <IconButton icon={<Icon icon="trash-o" />} onClick={handleRemoveFeatureMapGroup(i)}>
                  刪除
                </IconButton>
              </FeatureMapBox>
            ))}
            <IconButton icon={<Icon icon="plus" />} appearance="primary" color="green" onClick={handleAddFeatureMap}>
              新增 Feature Map
            </IconButton>
          </Panel>
          <Panel header="優惠券使用設定" bordered>
            <FormGroup>
              <ControlLabel>開始使用時間</ControlLabel>
              <Controller
                control={control}
                name="enable_date"
                render={({ field: { value, onChange, ...field } }) => (
                  <DatePicker
                    cleanable={false}
                    format="YYYY-MM-DD HH:mm:ss"
                    ranges={[
                      {
                        label: 'Now',
                        value: new Date(),
                      },
                    ]}
                    placeholder="選擇日期時間"
                    value={dateTransform.in(value)}
                    onChange={(val) => onChange(dateTransform.out(val))}
                    {...field}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>限制使用天數</ControlLabel>
              <Controller
                control={control}
                name="valid_days_toggle"
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Radio value={false}>不限制</Radio>
                    <Radio value>
                      限制
                      <Controller
                        control={control}
                        name="expire_in_days"
                        disabled={!validDaysToggle}
                        render={({ field: { onChange, ...field } }) => (
                          <InputNumber
                            className="in-radio-input"
                            postfix="天內使用"
                            min={0}
                            onChange={(v) => onChange(numberTransform.out(v))}
                            {...field}
                          />
                        )}
                      />
                    </Radio>
                  </RadioGroup>
                )}
              />
            </FormGroup>
            {!validDaysToggle && (
              <FormGroup>
                <ControlLabel>結束使用時間</ControlLabel>
                <Controller
                  control={control}
                  name="invalid_date"
                  render={({ field: { value, onChange, ...field } }) => (
                    <DatePicker
                      cleanable={false}
                      format="YYYY-MM-DD HH:mm:ss"
                      ranges={[
                        {
                          label: 'Now',
                          value: new Date(),
                        },
                      ]}
                      placeholder="選擇日期時間"
                      value={dateTransform.in(value)}
                      onChange={(val) => onChange(dateTransform.out(val))}
                      {...field}
                    />
                  )}
                />
              </FormGroup>
            )}
            <FormGroup>
              <ControlLabel>活動條件說明</ControlLabel>
              <Controller
                control={control}
                name="promo_desc"
                render={({ field }) => <Input componentClass="textarea" rows={3} placeholder="請填寫適用的活動條件" {...field} />}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>活動適用時段</ControlLabel>
              <Controller
                control={control}
                name="lifespan_type"
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Radio value={lifespanTypes.all}>所有時段</Radio>
                    <Radio value={lifespanTypes.designated_daily_timeframe}>時間規則：每日</Radio>
                    <TimePeriodStack>
                      {lifespanDailyFields.map((item, i) => {
                        return (
                          <TimePeriodRow key={item.id}>
                            <Controller
                              control={control}
                              name={`lifespan_daily_periods.${i}.time_of_day_start`}
                              disabled={lifespanType !== lifespanTypes.designated_daily_timeframe}
                              render={({ field: { ref: _ref, value, onChange, ...field } }) => (
                                <DatePicker
                                  cleanable={false}
                                  format="HH:mm:ss"
                                  ranges={[
                                    {
                                      label: 'Now',
                                      value: new Date(),
                                    },
                                  ]}
                                  placeholder="選擇時間"
                                  value={formatDateTransform.in(value)}
                                  onChange={(val) => onChange(formatDateTransform.out(val))}
                                  {...field}
                                />
                              )}
                            />
                            <span>~</span>
                            <Controller
                              control={control}
                              name={`lifespan_daily_periods.${i}.time_of_day_end`}
                              disabled={lifespanType !== lifespanTypes.designated_daily_timeframe}
                              render={({ field: { ref: _ref, value, onChange, ...field } }) => (
                                <DatePicker
                                  cleanable={false}
                                  format="HH:mm:ss"
                                  ranges={[
                                    {
                                      label: 'Now',
                                      value: new Date(),
                                    },
                                  ]}
                                  placeholder="選擇時間"
                                  value={formatDateTransform.in(value)}
                                  onChange={(val) => onChange(formatDateTransform.out(val))}
                                  {...field}
                                />
                              )}
                            />
                            <IconButton
                              disabled={lifespanType !== lifespanTypes.designated_daily_timeframe}
                              icon={<Icon icon="trash-o" />}
                              onClick={handleRemovePeriodForDaily(i)}
                            />
                          </TimePeriodRow>
                        )
                      })}
                      <IconButton
                        disabled={lifespanType !== lifespanTypes.designated_daily_timeframe}
                        icon={<Icon icon="plus" />}
                        appearance="primary"
                        color="green"
                        onClick={handleAddPeriodForDaily}
                      >
                        新增時間區段
                      </IconButton>
                    </TimePeriodStack>
                    <Radio value={lifespanTypes.designated_weekly_timeframe}>時間規則：週</Radio>
                    <TimePeriodStack>
                      <Controller
                        control={control}
                        name="lifespan_weekly_interval"
                        disabled={lifespanType !== lifespanTypes.designated_weekly_timeframe}
                        render={({ field: { onChange, ...field } }) => (
                          <InputNumber
                            className="in-radio-input"
                            prefix="每"
                            postfix="週"
                            min={0}
                            onChange={(v) => onChange(numberTransform.out(v))}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="lifespan_weekly_day_of_week"
                        disabled={lifespanType !== lifespanTypes.designated_weekly_timeframe}
                        render={({ field }) => (
                          <CheckboxGroup inline {...field}>
                            <Checkbox disabled={field.disabled} value="0">
                              週一
                            </Checkbox>
                            <Checkbox disabled={field.disabled} value="1">
                              週二
                            </Checkbox>
                            <Checkbox disabled={field.disabled} value="2">
                              週三
                            </Checkbox>
                            <Checkbox disabled={field.disabled} value="3">
                              週四
                            </Checkbox>
                            <Checkbox disabled={field.disabled} value="4">
                              週五
                            </Checkbox>
                            <Checkbox disabled={field.disabled} value="5">
                              週六
                            </Checkbox>
                            <Checkbox disabled={field.disabled} value="6">
                              週日
                            </Checkbox>
                          </CheckboxGroup>
                        )}
                      />
                      {lifespanWeeklyFields.map((item, i) => (
                        <TimePeriodRow key={item.id}>
                          <Controller
                            control={control}
                            name={`lifespan_weekly_periods.${i}.time_of_day_start`}
                            disabled={lifespanType !== lifespanTypes.designated_weekly_timeframe}
                            render={({ field: { ref: _ref, value, onChange, ...field } }) => (
                              <DatePicker
                                cleanable={false}
                                format="HH:mm:ss"
                                ranges={[
                                  {
                                    label: 'Now',
                                    value: new Date(),
                                  },
                                ]}
                                placeholder="選擇時間"
                                value={formatDateTransform.in(value)}
                                onChange={(val) => onChange(formatDateTransform.out(val))}
                                {...field}
                              />
                            )}
                          />
                          <span>~</span>
                          <Controller
                            control={control}
                            name={`lifespan_weekly_periods.${i}.time_of_day_end`}
                            disabled={lifespanType !== lifespanTypes.designated_weekly_timeframe}
                            render={({ field: { ref: _ref, value, onChange, ...field } }) => (
                              <DatePicker
                                cleanable={false}
                                format="HH:mm:ss"
                                ranges={[
                                  {
                                    label: 'Now',
                                    value: new Date(),
                                  },
                                ]}
                                placeholder="選擇時間"
                                value={formatDateTransform.in(value)}
                                onChange={(val) => onChange(formatDateTransform.out(val))}
                                {...field}
                              />
                            )}
                          />
                          <IconButton
                            disabled={lifespanType !== lifespanTypes.designated_weekly_timeframe}
                            icon={<Icon icon="trash-o" />}
                            onClick={handleRemovePeriodForWeekly(i)}
                          />
                        </TimePeriodRow>
                      ))}
                      <IconButton
                        disabled={lifespanType !== lifespanTypes.designated_weekly_timeframe}
                        icon={<Icon icon="plus" />}
                        appearance="primary"
                        color="green"
                        onClick={handleAddPeriodForWeekly}
                      >
                        新增時間區段
                      </IconButton>
                    </TimePeriodStack>
                  </RadioGroup>
                )}
              />
            </FormGroup>
          </Panel>
          <Panel header="Tags 設定" bordered>
            <FormGroup>
              <ControlLabel>Coupon Type</ControlLabel>
              <Controller
                control={control}
                name="type"
                render={({ field }) => <SelectPicker placement="topEnd" data={types} labelKey="type" valueKey="id" searchable={false} {...field} />}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Coupon Category</ControlLabel>
              <Controller
                control={control}
                name="category"
                render={({ field }) => (
                  <SelectPicker placement="topEnd" data={categories} labelKey="category" valueKey="id" searchable={false} {...field} />
                )}
              />
            </FormGroup>
          </Panel>
          <ButtonToolbar>
            <Button appearance="primary" size="lg" loading={isSubmitting} onClick={handleSubmit(submit)} block>
              送出
            </Button>
            <Button appearance="ghost" size="lg" disabled={isSubmitting} onClick={history.goBack} block>
              取消
            </Button>
          </ButtonToolbar>
        </CustomForm>
      </Root>
      {!isEmpty(editLocationModal) && (
        <Modal size="lg" show={!isEmpty(editLocationModal)} onHide={() => setEditLocationModal('')}>
          <GeoGenerator
            type="range"
            value={editLocationModal ? getValues(editLocationModal) : []}
            handleClose={() => setEditLocationModal(null)}
            setStateKey={editLocationModal}
            setEditLocation={setEditLocationModal}
            handleOnChange={handleLocationChange}
            multi
          />
        </Modal>
      )}
    </>
  )
}

export default CouponForm
