import React from 'react'
import { Button, Icon, Modal } from 'rsuite'

const EventConfirmModal = ({ show, handleClose, handleDraftConfirm, handleScheduleConfirm }) => {
  return (
    <Modal backdrop="static" size="xs" show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>
          <Icon
            icon="remind"
            style={{
              color: '#ffb300',
              fontSize: 24,
              paddingRight: 10,
            }}
          />
          請確認是否進入排程
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="event-confirm-modal-body">
        <p>請確認以上發券推播的內容，是否有需要有其他人審核，如果有，請選擇維持草稿，若要按指定時間排程發送，請選擇進入排程。</p>
      </Modal.Body>
      <Modal.Footer className="event-confirm-modal-footer" style={{ display: 'flex', flexDirection: 'column' }}>
        <Button onClick={handleDraftConfirm} appearance="link">
          維持草稿
        </Button>
        <Button onClick={handleScheduleConfirm} appearance="link">
          進入排程
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(EventConfirmModal)
