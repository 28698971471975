import React from 'react'
import dayjs from 'dayjs'

import { handlePaymentMethod } from '../../pages/trip_transaction/helper'
import { Table } from './styles'

const dateFormat = (v) => {
  if (!v) return '-'
  return dayjs(v).format('YYYY-MM-DD HH:mm')
}

const amountFormat = (v) => {
  if (v.status === 'REFUND') return `-$${v.amount}`
  return `$${v.amount}`
}

const TransactionTable = ({ data }) => {
  return (
    <Table className="text-gray-2" style={{ marginTop: -12 }}>
      <tbody>
        {data.map((v, i) => {
          return (
            <tr key={i}>
              <td width={150}>{v.transaction_id}</td>
              <td width={200}>{dateFormat(v.created_time * 1000)}</td>
              <td width={150}>{v.supplementaryOrderNo ? '租車補款' : v.status_label}</td>
              <td width={150}>{handlePaymentMethod(v.payment_method)}</td>
              <td width={100}>{amountFormat(v)}</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default React.memo(TransactionTable)
