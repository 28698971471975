import React from 'react'
import { Cell } from 'rsuite-table'
import { Popover, Whisper } from 'rsuite'
import dayjs from 'dayjs'
import { isNumber } from 'lodash'

// dataKey: 要顯示的 property
// defaultKey: dataKey 沒有值時，預設要顯示的 property
// defaultValue: dataKey 沒有值時，預設要顯示的字
// format: 要顯示的日期格式
const DateCell = ({ rowData, dataKey, defaultKey, defaultValue, format = 'YYYY-MM-DD HH:mm', ...props }) => {
  const getDateValue = () => {
    if (rowData[dataKey]) return dayjs(isNumber(rowData[dataKey]) ? rowData[dataKey] * 1000 : rowData[dataKey]).format(format)
    if (defaultValue) return defaultValue
    if (rowData[defaultKey]) return dayjs(rowData[defaultKey] * 1000).format(format)
    return ''
  }

  const value = getDateValue()

  return (
    <Cell {...props} >
      <Whisper placement="top" speaker={<Popover><b>{value}</b></Popover>}>
				<p>{value}</p>
			</Whisper>
    </Cell>
  )
}

export default React.memo(DateCell)