import React from 'react'
import { DatePicker, Input, InputGroup, InputPicker } from 'rsuite'
import { FormController, FormField, FormLabel, Stack } from './styles'

const PanelInfo = () => {
  return (
    <Stack>
      <FormField isRequired>
        <FormLabel>活動代碼</FormLabel>
        <FormController>
          <InputGroup>
            <InputGroup.Addon>MGM</InputGroup.Addon>
            <Input placeholder="輸入名稱" />
          </InputGroup>
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>活動標題</FormLabel>
        <FormController>
          <Input placeholder="輸入名稱" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>開始時間</FormLabel>
        <FormController>
          <DatePicker format="YYYY-MM-DD HH:mm" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>結束時間</FormLabel>
        <FormController>
          <DatePicker format="YYYY-MM-DD HH:mm" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>活動狀態</FormLabel>
        <FormController>
          <InputPicker placeholder="請選擇" />
        </FormController>
      </FormField>
    </Stack>
  )
}

export default PanelInfo
