import React, { Component } from 'react'
import { DatePicker, Panel, Tag } from 'rsuite'
import SwipeableViews from 'react-swipeable-views'

class Simulator extends Component {
	handleActionPreview = (card) => {
		return window.open(card.button[0] && card.button[0].action.uri)
	}
	render() {
		const { addModel, handleSimulatorDate, filterBroadcast, imgUrl,
			currentTitle,
			currentTextArea,
			currentbuttonLink,
			currentBtnTitle,
		} = this.props
		const SwipeableStyles = {
			root: {
				width: '235px',
				overflowX: 'initial !important',
			}
		}
		return (
			<div className="simulator-wrap">
				<div className="simulator-date-filter">
					{!addModel &&
						<DatePicker
							defaultValue={new Date()}
							onOk={(date) => handleSimulatorDate(date)}
							placement={'bottomEnd'}
							appearance={'default'}
							placeholder={'Select Date'}
							isoWeek
							format='YYYY-MM-DD HH:mm'
							ranges={[{ value: new Date(), closeOverlay: true }]} />}
				</div>
				<div className="simulator-broadcast">
					{addModel ?
						<Panel shaded bordered bodyFill style={{ display: 'inline-block', width: 220, height: 300, backgroundColor: 'white' }}>
							<img src={imgUrl} height="145" alt="" />
							<div className="card-body">
								<div className="card-content">
									<h6>{currentTitle}</h6>
									<div className="content body_03">{currentTextArea}</div>
								</div>
								<div className="button" onClick={() => window.open(currentbuttonLink)} >{currentBtnTitle}</div>
							</div>
						</Panel>
						: <SwipeableViews style={SwipeableStyles.root} index={0} >
							{filterBroadcast.map((card, index) => {
								const isPopUp = Boolean(card.is_pop_up)
								return (
									<Panel key={index} shaded bordered bodyFill style={{ display: 'inline-block', width: 220, height: 300, backgroundColor: 'white' }}>
										<div>
											{isPopUp && <Tag style={{ position: 'absolute', margin: '5px 5px', whiteSpace: 'nowrap'}}>蓋版</Tag>}
											<img src={card.img_url} height="145" alt="" />
										</div>
										<div className="card-body">
											<div className="card-content">
												<h6>{card.title}</h6>
												<div className="content body_03">{card.content}</div>
											</div>
											<div className="button" onClick={() => this.handleActionPreview(card)} >{card.button[0] ? card.button[0].action.label : card.button.label}</div>
										</div>
									</Panel>)
							})}
						</SwipeableViews>}
				</div>
			</div>
		)
	}

}

export default Simulator
