import { isEmpty } from 'lodash'

const sortColumnBy = (newsData, sortColumn, sortType) => {
  if (isEmpty(sortColumn)) return newsData

  const data = newsData
  data.sort((a,b) => {
    let firstItem = a[`${sortColumn}`]
    let secondItem = b[`${sortColumn}`]
    // if no start_time => created_at
    if (sortColumn === 'start_time') {
      firstItem = firstItem || a['created_at']
      secondItem = secondItem || b['created_at']
    }
    return (sortType === 'desc') ? secondItem - firstItem : firstItem - secondItem
  })
  return data
}

export { sortColumnBy }