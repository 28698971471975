import React from 'react'
import dayjs from 'dayjs'
import { DatePicker, Icon } from 'rsuite'

function transTime(time) {
  const today = new Date().toString()
  return new Date(dayjs(today.substring(0, 16) + time).unix() * 1000)
}

const TimePeriodSelector = ({ data, handleTimePeriod }) => {
  const { id, time_of_day_start, time_of_day_end, toDelete } = data

  const onTimeChange = (date, type) => {
    const initData = {...data}
    const formatDate = dayjs(dayjs(+date).unix()*1000).format('HH:mm:ss')
    type === 'start' ? initData.time_of_day_start = formatDate : initData.time_of_day_end = formatDate
    handleTimePeriod('edit', id, initData)
  }

  return (
    <div className={`time-period-selector ${toDelete && 'delete'}`}>
      <DatePicker
        disabled={Boolean(toDelete)}
        defaultValue={time_of_day_start ? transTime(time_of_day_start) : undefined }
        format="HH:mm:ss"
        ranges={[]}
        onChange={(date) => onTimeChange(date, 'start')}/>
      <span> ~ </span>
      <DatePicker
        disabled={Boolean(toDelete)}
        defaultValue={time_of_day_end ? transTime(time_of_day_end) : undefined }
        format="HH:mm:ss"
        ranges={[]}
        onChange={(date) => onTimeChange(date, 'end')}/>
      {!toDelete && <Icon icon='trash' size="lg" onClick={() => handleTimePeriod('delete', id)} />}
      {toDelete && <Icon icon='reload' size="lg" onClick={() => handleTimePeriod('reset', id)} />}
    </div>
  )
}

export default React.memo(TimePeriodSelector)