import React from 'react'
import { filter, find } from 'lodash'
import { Checkbox } from 'rsuite'

const optionType = [
  { name: '電話',
    value: 'phone_number',
  },
  { name: '信箱',
    value: 'email',
  },
  { name: '地址',
    value: 'address',
  },
  { name: '司機 ID',
    value: 'id_number',
  }
]

const getOptionName = (value) => {
  const name = find(optionType, { value }).name
  return name
}

const CheckboxSection = ({ options, setOptions }) => {
  const checkedList = filter(options, { isChecked: true })

  const handleAllOptions = (v, checked) => {
    setOptions([
      { id: 1, value: "phone_number", isChecked: checked },
      { id: 2, value: "email", isChecked: checked },
      { id: 3, value: "address", isChecked: checked },
      { id: 4, value: "id_number", isChecked: checked }
    ])
  }

  const handleCheckOption = (value, checked) => {
    const newOptions = options.map((option) => {
      if (option.value !== value) return option
      let newOption = option
      newOption.isChecked = checked
      return newOption
    })
    setOptions(newOptions)
  }

  return (
    <div style={{ width: '100%', borderRadius: '4px' }}>
      <Checkbox style={{borderBottom: '1px solid #eeeeee'}} checked={checkedList.length === 4} onChange={handleAllOptions}>全選</Checkbox>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {options.map((option, index) => {
          return <Checkbox key={index} value={option.value} checked={option.isChecked} onChange={handleCheckOption}>{getOptionName(option.value)}</Checkbox>
        })}
      </div>
    </div>

  )
}

export default React.memo(CheckboxSection)

