/* eslint-disable consistent-return */
import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { last } from 'lodash'
import { Icon, Button } from 'rsuite'
import { SearchInput } from '../../components/table'
import { getTripInfo, getConfig, getRecalculatedFare } from '../../api'
import PopWarning from '../../components/popWarning'
import TripMap from './TripMap'
import TripDetails from './TripDetails'
import TripFare from './TripFare'

const Container = styled.div`
  pointer-events: initial;
  min-height: 100%;
  width: 100%;
  background-color: #fff;
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
`
const TripWrapper = styled.div`
  display: flex;
`
const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const ToolWrapper = styled.div`
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
`
export const Title = styled.h4`
  color: #4b4d4c;
  margin-bottom: 20px;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 0.5px;
    background-color: #a5a6a5;
    margin-top: 5px;
  }
`
const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
`
const Tag = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  margin-right: 4px;
  &::before {
    content: '';
    background: ${({ $color }) => $color || '#797979'};
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 10px;
  }
`
const TagButton = styled(Tag)`
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid transparent;
  &:hover {
    border-color: #dedee1;
    background: #f7f7fa;
  }
`
const IconTagButton = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  margin-right: 4px;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid transparent;
  &:hover {
    border-color: #dedee1;
    background: #f7f7fa;
  }
  .rs-icon {
    margin-right: 6px;
  }
`

const FOCUS_TYPE = {
  START_ADDRESS: 'START_ADDRESS',
  DRIVER_ARRIVED: 'DRIVER_ARRIVED',
  START_RANGE: 'START_RANGE',
}
const ARRIVAL_RANGE = 30 // 預設 30m

const TripRoute = () => {
  const [tripId, setTripId] = useState(null)
  const [basicInfo, setBasicInfo] = useState({})
  const [tripUpdates, setTripUpdates] = useState([])
  const [routes, setRoutes] = useState(null)
  const [estimatedData, setEstimatedData] = useState(null)
  const [warning, setWarning] = useState('')
  const [focusType, setFocusType] = useState(null)
  const [arrivalRange, setArrivalRange] = useState(ARRIVAL_RANGE)
  const [fareInfo, setFareInfo] = useState('')

  const fetchTrip = useCallback(async (trip_id) => {
    const response = await getTripInfo(trip_id, { q: '{basic,trip_updates,routes_details,estimated_data}' })
    if (response.status !== 200) return setWarning(response.data.msg)
    const { basic = {}, trip_updates = [], routes_details = [], estimated_data = [] } = response.data.data

    trip_updates.push({
      uid: basic.user_id,
      update_type: 'USER_REQUESTED', // 用戶叫車
      last_update: basic.request_time,
    })
    if (basic.request_time !== basic.reserve_time) {
      trip_updates.push({
        uid: basic.user_id,
        update_type: 'USER_RESERVED_TIME', // 用戶用車時間(預約單)
        last_update: basic.reserve_time,
      })
    }
    trip_updates.push({
      uid: basic.user_id,
      update_type: 'USER_RESERVED_TIME', // 用戶用車時間
      last_update: basic.reserve_time,
    })
    estimated_data.forEach((estimate) => {
      const description = []
      if (estimate.estimate && estimate.estimate.fare_range) {
        description.push(`預估車資 ${JSON.stringify(estimate.estimate.fare_range)}`)
      }
      estimate.stops.forEach((stop) => {
        description.push(`停靠點 ${stop.address} ${stop.is_passed ? '已經過' : '未經過'}`)
      })
      trip_updates.push({
        uid: basic.user_id,
        update_type: 'ESTIMATE',
        last_update: new Date(estimate.created_at).getTime() / 1000,
        description,
      })
    })

    const lastAddress = last(basic.stops)
    const newStops = basic.stops.filter((item) => item.id !== lastAddress.id)
    window.history.replaceState(null, null, `${window.location.pathname}?trip=${trip_id}`)
    setBasicInfo({ ...basic, lastAddress, stops: newStops })
    setTripUpdates(trip_updates.sort((a, b) => a.last_update - b.last_update))
    setRoutes(routes_details)
    setEstimatedData(estimated_data)
  }, [])

  const handleSearch = useCallback(() => {
    tripId && fetchTrip(tripId)
  }, [tripId, fetchTrip])

  useEffect(() => {
    const loadScript = (src) => {
      const head = document.querySelector('head')
      const script = document.createElement('script')
      script.setAttribute('async', true)
      script.setAttribute('src', src)
      head.appendChild(script)
    }
    const initTripData = () => {
      const searchParams = new URLSearchParams(window.location.search)
      const tripId = searchParams.get('trip')
      if (!tripId) return
      fetchTrip(tripId)
      setTripId(tripId)
    }
    const fetchConfig = async () => {
      const response = await getConfig()
      if (response.status === 200) {
        const { driver_arrived_distance_limit } = response.data
        const validRange = parseInt(driver_arrived_distance_limit, 10)
        if (validRange) {
          setArrivalRange(validRange)
        }
      }
    }
    loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyC9fcxp7Ri6BWjBxjqiEQIwb3DVd0DwMJg&libraries=geometry')
    initTripData()
    fetchConfig()
  }, [fetchTrip])

  const handleRecalculateFare = useCallback(async () => {
    const response = await getRecalculatedFare(tripId)
    if (response.status !== 200) return setWarning(response.data.msg)
    const fare = response.data.data
    setFareInfo(fare)
  }, [tripId])

  return (
    <>
      {warning && <PopWarning warning={warning} textCenter confirm={() => setWarning('')} />}
      <Container>
        <Title>行程路徑</Title>
        <SearchInput
          title="輸入 Trip ID"
          onKeyDown={(e) => {
            if (e.keyCode === 13) handleSearch()
          }}
          onClick={handleSearch}
          value={tripId || ''}
          setSearchWord={setTripId}
          withButton
        />
        <TagsWrapper>
          <Tag $color="#995781">派單司機</Tag>
          <Tag $color="#88a1b3">前往載客路徑</Tag>
          <Tag $color="#3197de">行程路徑</Tag>
          <Tag $color="#32CD32">建議路線</Tag>
          {/* 看起來沒有在用，先註解掉 */}
          {/* <Tag $color="#a9dbad">更換停靠點建議路線</Tag> */}
          <IconTagButton onClick={() => setFocusType(FOCUS_TYPE.START_ADDRESS)}>
            <Icon size="lg" style={{ color: '#2ea628' }} icon="map-marker" />
            上車點
          </IconTagButton>
          {basicInfo?.trips_driver_arrived?.driver_location?.x && (
            <TagButton $color="#f6003c" onClick={() => setFocusType(FOCUS_TYPE.DRIVER_ARRIVED)}>
              司機滑已抵達
            </TagButton>
          )}
          <TagButton $color="rgba(255, 250, 94, 0.7)" onClick={() => setFocusType(FOCUS_TYPE.START_RANGE)}>
            {`上車點周圍 ${arrivalRange}m`}
          </TagButton>
        </TagsWrapper>
        {!!tripUpdates.length && (
          <TripWrapper>
            <MapWrapper>
              <TripMap
                basic={basicInfo}
                trip_updates={tripUpdates}
                routes={routes}
                estimatedData={estimatedData}
                arrivalRange={arrivalRange}
                focusType={focusType}
              />
              <ToolWrapper>
                <TripFare fare={fareInfo} />
                <Button size="sm" color="red" appearance="ghost" style={{ width: 200, marginTop: 20 }} onClick={() => handleRecalculateFare()}>
                  重新計算車資
                </Button>
              </ToolWrapper>
            </MapWrapper>
            <TripDetails basic={basicInfo} trip_updates={tripUpdates} />
          </TripWrapper>
        )}
      </Container>
    </>
  )
}

export default TripRoute
