import dayjs from 'dayjs'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Alert, Button, Icon, Input, InputGroup, Loader, Modal } from 'rsuite'
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'
import TablePagination from 'rsuite/lib/Table/TablePagination'
import { deleteDriverReward, getDriverRewardList } from '../../api'
import DriverMileageProvider, { useDriverMileage } from './DriverMileageProvider'
import { Heading, Title, Wrapper, HStack, DriverInfoPanel, FilterRow, TableBox } from './styles'

const formatCreateAt = (originData) => dayjs(originData).format('YYYY-MM-DD H:mm')

const Actions = (rowData) => {
  const { openDeleteModal } = useDriverMileage()
  const { editable, id } = rowData

  return (
    <Button size="sm" disabled={!editable} onClick={() => openDeleteModal(id)}>
      取消紀錄
    </Button>
  )
}

const TABLE_COLUMNS = [
  { label: '建立時間', dataKey: 'created_at', width: 100, format: formatCreateAt },
  { label: '類別', dataKey: 'category', width: 100 },
  { label: '事件Id', dataKey: 'id', width: 100 },
  { label: '獎懲項目', dataKey: 'title', width: 100 },
  { label: '行程', dataKey: 'trip_id', width: 100 },
  { label: '數量', dataKey: 'quantity', width: 70 },
  { label: '點數加總', dataKey: 'miles', width: 100 },
  { label: '兌換地點', dataKey: 'exchange_store_name', width: 120 },
  { label: '操作人員', dataKey: 'admin_username', width: 100 },
  { label: '操作', dataKey: 'actions', width: 120, render: Actions },
  { label: 'Note', dataKey: 'note', width: 150, flexGrow: 1 },
]

const MyCell = ({ rowData, dataKey, format, render, ...props }) => {
  if (render) {
    return <Cell {...props}>{render(rowData)}</Cell>
  }
  const result = format ? format(rowData[dataKey]) : rowData[dataKey]
  return <Cell {...props}>{result || '-'}</Cell>
}

const DEFAULT_LENGTH_A_PAGE = 50

const DriverMileage = () => {
  const history = useHistory()
  const { search } = useLocation()

  const [isLoading, setIsLoading] = useState(false)
  const [searchDriverID, setSearchDriverID] = useState(null)

  const [driverInfo, setDriverInfo] = useState(null)
  const [rewardList, setRewardList] = useState([])

  const [currentPage, setCurrentPage] = useState(1)

  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false)
  const currentDeleteMileageIDRef = useRef(null)
  const [isDeleting, setIsDeleting] = useState(false)

  const handlePageChange = useCallback((value) => {
    setCurrentPage(value)
  }, [])

  const renderTableColumns = useCallback(() => {
    return TABLE_COLUMNS.map((item) => (
      <Column key={item.dataKey} align="center" verticalAlign="middle" width={item.width} flexGrow={item.flexGrow} fixed>
        <HeaderCell>{item.label}</HeaderCell>
        <MyCell {...item} />
      </Column>
    ))
  }, [])

  const handleCreateReward = useCallback(() => {
    if (!driverInfo?.id) {
      return Alert.warning('請先查詢司機資料')
    }
    return history.push(`/driver_mileage/${driverInfo.id}/create`)
  }, [history, driverInfo])

  const handleExchangeStuff = useCallback(() => {
    if (!driverInfo?.id) {
      return Alert.warning('請先查詢司機資料')
    }
    return history.push(`/driver_mileage/${driverInfo.id}/exchange`)
  }, [history, driverInfo])

  const handleSearchInputChange = useCallback((value) => {
    setSearchDriverID(value)
  }, [])

  const handleSearch = useCallback(async (did) => {
    try {
      setIsLoading(true)
      const { data } = await getDriverRewardList(did)
      if (!data.data) {
        throw new Error(data.message)
      }
      setDriverInfo({
        id: data.data.driver_info.id,
        name: data.data.driver_info.name,
        total_miles: data.data.total_miles,
        expired_miles: data.data.expired_miles,
        isEquipped: data.data.isEquipped || false,
        driverGrade: data.data.driverGrade || { grade: '' },
      })
      setRewardList([...data.data.history])
    } catch (error) {
      Alert.error(error.message)
    }
    setIsLoading(false)
  }, [])

  const handleSearchClick = useCallback(() => {
    handleSearch(searchDriverID)
  }, [searchDriverID, handleSearch])

  const tableData = useMemo(() => {
    return rewardList.filter((x, i) => {
      const start = (currentPage - 1) * DEFAULT_LENGTH_A_PAGE
      const end = start + DEFAULT_LENGTH_A_PAGE
      return i >= start && i < end
    })
  }, [currentPage, rewardList])

  useEffect(() => {
    const didInSearchParams = new URLSearchParams(search).get('did')
    if (didInSearchParams) {
      handleSearch(didInSearchParams)
    }
  }, [search, handleSearch])

  const openDeleteModal = useCallback((id) => {
    currentDeleteMileageIDRef.current = id
    setIsDeleteModalShow(true)
  }, [])

  const hideDeleteModal = useCallback(() => {
    currentDeleteMileageIDRef.current = null
    setIsDeleteModalShow(false)
  }, [])

  const deleteRewardItem = useCallback(async () => {
    try {
      if (!driverInfo.id || !currentDeleteMileageIDRef.current) throw new Error('driver_id or driver_mileage_id is undefined')
      setIsDeleting(true)
      const { data } = await deleteDriverReward(driverInfo.id, currentDeleteMileageIDRef.current)
      if (!data.data) {
        throw new Error(data.message)
      }
      await handleSearch(driverInfo.id)
      Alert.success('刪除成功')
      setIsDeleteModalShow(false)
    } catch (error) {
      Alert.error(error.message)
    }
    setIsDeleting(false)
  }, [driverInfo, handleSearch])

  return (
    <>
      <Wrapper>
        <Heading>司機獎勵積點(點數商品)</Heading>
        <Title>司機ID</Title>
        <HStack>
          <div>
            <InputGroup>
              <Input onChange={handleSearchInputChange} onPressEnter={handleSearchClick} />
              <InputGroup.Addon>
                <Icon icon="search" />
              </InputGroup.Addon>
            </InputGroup>
          </div>
          <Button appearance="primary" onClick={handleSearchClick}>
            查詢
          </Button>
          {isLoading && <Loader />}
        </HStack>
        {driverInfo && (
          <DriverInfoPanel>
            <div className="item">
              <p className="title">司機ID</p>
              <span className="content">{`${driverInfo.id} ${driverInfo.name}`}</span>
            </div>
            <div className="item">
              <p className="title">目前點數</p>
              <span className="content">{`${driverInfo.total_miles} 點`}</span>
            </div>
            <div className="item">
              <p className="title">本月即將到期的點數</p>
              <span className="content">{`${driverInfo.expired_miles} 點`}</span>
            </div>
            <div className="item">
              <p className="title">GO好友</p>
              <span className="content">{`${driverInfo.isEquipped ? '是' : '否'}`}</span>
            </div>
            <div className="item">
              <p className="title">司機等級</p>
              <span className="content">{driverInfo.driverGrade.grade ? `${driverInfo.driverGrade.grade}` : '無'}</span>
            </div>
          </DriverInfoPanel>
        )}
        <FilterRow>
          {/* LT-8713: 篩選歷史紀錄先規劃成優化項目，不屬於這次 Epic (LT-8591) 必要功能 ，所以先隱藏已完成的篩選 UI Component。 */}
          {/* <DateRangePicker placeholder="建立時間" />
        <SelectPicker data={[]} placeholder="獎懲項目" />
        <SelectPicker data={[]} placeholder="兌換品項" />
        <InputPicker data={[]} placeholder="兌換地點" />
        <Button appearance="ghost">全部清除</Button>
        <Button appearance="primary">查詢</Button> */}
          <div className="right-box">
            <Button appearance="primary" onClick={handleCreateReward}>
              新增一筆獎勵懲處
            </Button>
            <Button appearance="primary" onClick={handleExchangeStuff}>
              新增一項兌換商品
            </Button>
          </div>
        </FilterRow>
        <DriverMileageProvider value={{ openDeleteModal }}>
          <TableBox>
            <Table height={400} data={tableData} loading={isLoading} bordered cellBordered wordWrap>
              {renderTableColumns()}
            </Table>
            <TablePagination
              displayLength={DEFAULT_LENGTH_A_PAGE}
              showLengthMenu={false}
              total={rewardList.length}
              activePage={currentPage}
              onChangePage={handlePageChange}
            />
          </TableBox>
        </DriverMileageProvider>
      </Wrapper>
      <Modal size="xs" show={isDeleteModalShow} onHide={hideDeleteModal} backdrop={!isDeleting}>
        <Modal.Header closeButton={false}>
          <Modal.Title>確定要刪除紀錄嗎?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button onClick={deleteRewardItem} appearance="primary" color="red" loading={isDeleting}>
            刪除
          </Button>
          <Button onClick={hideDeleteModal} appearance="subtle" disabled={isDeleting}>
            取消
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DriverMileage
