const parseContent = (data) => {
	const contentData = JSON.parse(data)
	const objectData = Array.isArray(contentData) ? contentData[0].contents : contentData
	const { body, footer, hero } = objectData
	const result = {}
	result.content = body
	result.footer = footer
	result.hero = hero || {}
	return result
}

const createContent = (cardTitle, cardText, imgUrl, backgroundColor, btnText, btnColor, btnlink) => {
	const cardJson = {
		type: "bubble",
		hero: {
			type: "image",
			url: "url",
			size: "full",
			aspectRatio: "20:13",
			aspectMode: "cover"
		},
		body: {
			type: "box",
			layout: "vertical",
			contents: [
				{
					type: "text",
					text: "text",
					wrap: true,
					weight: "bold",
					size: "lg"
				},
				{
					type: "text",
					text: "text",
					wrap: true
				}
			],
			backgroundColor: "backgroundColor",
			spacing: "md"
		},
		footer: {
			type: "box",
			layout: "vertical",
			contents: [
				{
					type: "button",
					style: "primary",
					height: "sm",
					color: "color",
					action: {
						type: "uri",
						uri: "uri",
						label: "label"
					}
				}
			]
		}
	}
	cardJson.hero.url = imgUrl
	cardJson.body.contents[0].text = cardTitle
	cardJson.body.contents[1].text = cardText
	cardJson.body.backgroundColor = backgroundColor
	cardJson.footer.contents[0].color = btnColor
	cardJson.footer.contents[0].action.uri = btnlink
	cardJson.footer.contents[0].action.label = btnText

	return JSON.stringify(cardJson)
}

export { parseContent, createContent }
