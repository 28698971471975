import React from 'react'
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'

const TABLE_COLUMNS = [
  { label: '項目', dataKey: 'item', width: 220 },
  { label: '已領取', dataKey: 'amount', width: 70, align: 'center' },
  { label: '領取上限', dataKey: 'max_amount', width: 70, align: 'center', format: (v) => (v < 0 ? 'N/A' : v) },
]

const IdentifiersStockTable = React.memo(({ data = [] }) => {
  return (
    <Table autoHeight data={data} wordWrap>
      {TABLE_COLUMNS.map((v) => (
        <Column key={v.dataKey} width={v.width} align={v.align} verticalAlign="middle">
          <HeaderCell>{v.label}</HeaderCell>
          <Cell>
            {(rowData) => {
              return <span>{v.format ? v.format(rowData[v.dataKey]) : rowData[v.dataKey]}</span>
            }}
          </Cell>
        </Column>
      ))}
    </Table>
  )
})

export default IdentifiersStockTable
