import React from 'react'
import { Button, Icon } from 'rsuite';
import { Cell } from 'rsuite-table'
import { TagItem } from './TagItem'

export const TypeCell = ({ rowData, handleCopyCode, ...props }) => {
	return (
		<Cell {...props}>
			<div className="type-cell-wrapper">
				<TagItem type={rowData.promo_type} />
				{rowData.promo_type === 'SERIAL_NUMBER' ? 
					<Button color="red" appearance="ghost" size="xs" onClick={() => handleCopyCode(rowData)}><Icon icon="copy"/>   批量序號</Button> :
					<p>{rowData.promo_code}</p>
				}
			</div>
		</Cell>
	)
}

export default React.memo(TypeCell)