import React, { Component } from 'react'
import { DatePicker } from 'rsuite';

class Simulator extends Component {
	handleActionPreview = (action) => {
		return window.open(action.text)
	}
	render() {
		const { addListModel, handleSimulatorDate, currentUrl, currentAction, filterCampaign } = this.props
		const previewUrl = addListModel ? currentUrl : (filterCampaign[0] && filterCampaign[0].content.baseUrl)
		const previewActions = addListModel ? currentAction : (filterCampaign[0] && filterCampaign[0].content.actions)
		return (
			<div className="simulator-wrap">
				<div className="simulator-date-filter">
					{!addListModel &&
						<DatePicker
							defaultValue={new Date()}
							onOk={(date) => handleSimulatorDate(date)}
							placement={'bottomEnd'}
							appearance={'default'}
							placeholder={'Select Date'}
							isoWeek
							format='YYYY-MM-DD HH:mm'
							ranges={[{ value: new Date(), closeOverlay: true }]} />}
				</div>
				<div className="simulator-campaign">
					<img className="" src={`${previewUrl}/300`} alt="" />
					<div className="actions">
						{previewActions && previewActions.map((action, index) => {
							return <div className="link" key={index} style={{ width: `${230 / previewActions.length}px` }} onClick={() => this.handleActionPreview(action)}></div>
						})}
					</div>
				</div>
			</div>
		)
	}

}

export default Simulator
