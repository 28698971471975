import React, { useState, useEffect } from 'react'
import { Alert, Modal, Button, Icon } from 'rsuite'
import { getModifyTable2, removeEvent } from '../../api'
import { ListWrap } from '../../components/news'
import { Checkbox, CheckboxGroup } from 'rsuite'
import { MoreButton } from '../../components/table'
import { CommonActionCell as ActionCell, WhisperCell } from '../../components/table/cell'
import { Table, Column, HeaderCell, Cell } from 'rsuite-table'
import EventStatusTag from '../../components/driver_campaign/EventStatusTag'
import dayjs from 'dayjs'

const DriverCampaigns = ({ history }) => {
  const [driverEvents, setDriverEvents] = useState([])
  const [showMoreButton, setMoreButton] = useState(true)
  const [sortColumn, setSortColumn] = useState('id')
  const [sortType, setSortType] = useState('desc')
  const [filters, setFilters] = useState([])
  const [showModal, setShowModal] = useState({ open: false, id: '' })

  const fetchEvents = async ({ offset = 0, reset = false } = {}) => {
    const limit = 20
    const conditions = [
      ...getParams(),
      { key: sortType, value: sortColumn },
      { key: 'limit', value: limit },
      { key: 'offset', value: offset },
    ]
    const data = await getModifyTable2({ table: 'driversEvents', conditions })
    if (data.status !== 200) return Alert.error('發生錯誤，請稍候嘗試')

    const { get: { data: events } } = data.data.data
    setMoreButton(events.length >= limit)
    const concatEvents = reset ? events : driverEvents.concat(events)

    return setDriverEvents(concatEvents)
  }

  useEffect(() => {
    fetchEvents()
  }, [sortColumn, sortType, filters])

  const handleSortColumn = (sortColumn, sortType) => {
    setDriverEvents([])
    setSortColumn(sortColumn)
    setSortType(sortType)
  }

  const handleFilter = value => {
    setDriverEvents([])
    setFilters(value)
  }

  const getParams = () => {
    const params = []
    const now = Math.floor(new Date().getTime() / 1000)
    const bit = filters.reduce((a, b) => a + b, 0)
    switch (bit) {
      case 1:
        params.push({ key: 'start_time_gt', value: now })
        break
      case 2:
        params.push({ key: 'start_time_lte', value: now })
        params.push({ key: 'end_time_gte', value: now })
        break
      case 3:
        params.push({ key: 'end_time_gte', value: now })
        break
      case 4:
        params.push({ key: 'end_time_lt', value: now })
        break
      case 5:
        params.push({ key: 'start_time_not_between_end_time', value: now })
        break
      case 6:
        params.push({ key: 'start_time_lte', value: now })
        break
    }

    return params
  }

  const toAddEventPage = () => {
    history.push('/add_driver_campaign')
  }

  const getIconHandlers = (rowData) => {
    return [
      {
        icon: 'edit2',
        handler: () => history.push(`/driver_campaign/${rowData.id}`)
      },
      {
        icon: 'copy-o',
        handler: () => history.push(`/add_driver_campaign/${rowData.id}`)
      },
      {
        icon: 'trash2',
        handler: () => {
          setShowModal({ open: true, id: rowData.id })
        }
      },
    ]
  }

  const handleClose = () => {
    setShowModal({ open: false, id: '' })
  }

  const handleDeleteEvent = async () => {
    const data = await removeEvent(showModal.id, new URLSearchParams({ cascade: true }))
    setShowModal({ open: false, id: '' })
    if (data.status !== 200) return Alert.error('發生錯誤，請稍候嘗試')
    await fetchEvents({ reset: true })
    Alert.success('活動已刪除成功')
  }

  return (
    <>
      <ListWrap status="司機活動後台" addListText="+ 建立活動" onClick={toAddEventPage} opacity={1}>
        <CheckboxGroup style={{ fontSize: 12, margin: '0 10px 5px 10px', userSelect: 'none' }} inline name="checkboxList" value={filters} onChange={handleFilter}>
          <Checkbox value={1}>待開始</Checkbox>
          <Checkbox value={2}>已開始</Checkbox>
          <Checkbox value={4}>已結束</Checkbox>
        </CheckboxGroup>
      </ListWrap>
      <Table
        height={600}
        data={driverEvents}
        shouldUpdateScroll={false}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        <Column width={100} align="center" sortable>
          <HeaderCell>Event ID</HeaderCell>
          <Cell dataKey="id" />
        </Column>
        <Column width={150}>
          <HeaderCell>活動標題</HeaderCell>
          <WhisperCell dataKey="title" />
        </Column>
        <Column width={80}>
          <HeaderCell>活動類型</HeaderCell>
          <Cell dataKey="type">
            {(rowData) => rowData.type === 'EVENT' ? '趟次活動' : '推薦活動'}
          </Cell>
        </Column>
        <Column width={80}>
          <HeaderCell>是否公開</HeaderCell>
          <Cell dataKey="published" align="center">
            {(rowData) => rowData.published ? <Icon icon="check" style={{ color: '#4caf50' }} /> : ''}
          </Cell>
        </Column>
        <Column width={80}>
          <HeaderCell>活動狀態</HeaderCell>
          <Cell dataKey="type">
            {(rowData) => <EventStatusTag start={rowData.start_time} end={rowData.end_time} />}
          </Cell>
        </Column>
        <Column width={180} sortable>
          <HeaderCell>開始時間(顯示活動頁)</HeaderCell>
          <Cell dataKey="start_time">
            {(rowData) => dayjs(rowData.start_time * 1000).format('YYYY-MM-DD HH:mm')}
          </Cell>
        </Column>
        <Column width={180} sortable>
          <HeaderCell>結束時間(隱藏活動頁)</HeaderCell>
          <Cell dataKey="end_time">
            {(rowData) => dayjs(rowData.end_time * 1000).format('YYYY-MM-DD HH:mm')}
          </Cell>
        </Column>
        <Column width={150}>
          <HeaderCell>活動描述</HeaderCell>
          <WhisperCell dataKey="content" />
        </Column>
        <Column width={150}>
          <HeaderCell>活動網址</HeaderCell>
          <Cell dataKey="href">
            {(rowData) => <a target="_blank" href={rowData.href}>{rowData.href}</a>}
          </Cell>
        </Column>
        <Column width={100} align="center">
          <HeaderCell>操作</HeaderCell>
          <ActionCell dataKey="id" iconAndHandlers={getIconHandlers} />
        </Column>
      </Table>
      {showMoreButton && <MoreButton onClick={() => fetchEvents({ offset: driverEvents.length })} />}
      <Modal show={showModal.open} onHide={handleClose} size="xs">
        <Modal.Header>
          確定要刪除活動嗎?
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            取消
          </Button>
          <Button onClick={handleDeleteEvent} appearance="primary">
            確定
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default React.memo(DriverCampaigns)
