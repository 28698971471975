import React from 'react'
import { Input } from 'rsuite'
import { FormController, FormField, FormLabel, SectionTitle, Stack } from './styles'

const PanelFirstAward = () => {
  return (
    <Stack>
      <SectionTitle>客態抽獎頁</SectionTitle>
      <FormField isRequired>
        <FormLabel>首搭說明</FormLabel>
        <FormController>
          <Input componentClass="textarea" rows={5} placeholder="輸入文字" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>活動時間與獎勵說明</FormLabel>
        <FormController>
          <Input componentClass="textarea" rows={5} placeholder="輸入文字" />
        </FormController>
      </FormField>
      <SectionTitle>客態抽獎頁 (獎項已開啟過)</SectionTitle>
      <FormField isRequired>
        <FormLabel>標題</FormLabel>
        <FormController>
          <Input placeholder="分享成功" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>圖片</FormLabel>
        <FormController>
          <Input placeholder="輸入圖片連結" />
        </FormController>
      </FormField>
      <SectionTitle>客態抽獎頁 (獎項已過期)</SectionTitle>
      <FormField isRequired>
        <FormLabel>標題</FormLabel>
        <FormController>
          <Input placeholder="分享成功" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>圖片</FormLabel>
        <FormController>
          <Input placeholder="輸入圖片連結" />
        </FormController>
      </FormField>
    </Stack>
  )
}

export default PanelFirstAward
