import React, { Component } from 'react'
import { Table } from 'rsuite-table'

export class TableController extends Component {
  render() {
    const { children, data, rowHeight, topHeight = 0, autoHeight, rowClassName, loading } = this.props
    const height = document.body.clientHeight - topHeight - 44
    return (
      <div>
        <Table
          height={height}
          autoHeight={autoHeight}
          loading={loading}
          data={data}
          id="table"
          affixHeader
          rowHeight={rowHeight}
          rowClassName={rowClassName}
          shouldUpdateScroll={false}
        >
          {children}
        </Table>
      </div>
    )
  }
}

export default React.memo(TableController)
