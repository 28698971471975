import styled from 'styled-components'

export const Wrapper = styled.div`
  pointer-events: initial;
  min-height: 100%;
  width: 100%;
  background-color: #fff;
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Heading = styled.h2`
  color: #272c36;
  font-size: 20px;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 0.5px;
    background-color: #a5a6a5;
    margin-top: 5px;
  }
`

export const Title = styled.h6`
  color: #272c36;
  font-size: 18px;
`

export const HStack = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
`

export const DriverInfoPanel = styled.div`
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  color: #272c36;

  & .item {
    display: flex;
    align-items: center;
  }

  & .title {
    font-size: 16px;
    font-weight: bold;
    margin-right: 6px;
  }

  & .content {
    font-size: 14px;
  }
`

export const FilterRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  & .right-box {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
  }
`

export const TableBox = styled.div`
  width: 100%;
  padding: 12px;
`

export const DriverInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin: 1.5em 0 2.5em;

  & .cell {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  & .subject {
    color: #272c36;
    font-size: 18px;
    font-weight: bold;
  }

  & .content {
    color: #272c36;
    font-size: 16px;
  }
`

export const Subject = styled.h4`
  color: #272c36;
  font-size: 18px;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 0.5px;
    background-color: #a5a6a5;
    margin-top: 5px;
  }
`

export const RewardOptions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;

  & .cell {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  & .subject {
    color: #272c36;
    font-size: 18px;
    font-weight: bold;
  }
`

export const SubmitRow = styled.div`
  margin: 1.5em 0 2.5em;
`

export const ExchangeStuffRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  & .rs-picker-input,
  .rs-input-group,
  .rs-input {
    width: 100%;
  }
`

export const ExchangeStuffAssignee = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  & *:not(:first-child) {
    margin-left: 36px;
  }

  & .info {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 500;
    color: #575757;
    display: flex;
    gap: 12px;
  }
`

export const ExchangeStuffList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 6px;

  & li {
    width: 100%;
    max-width: 640px;
    padding: 6px 0;
    display: flex;
    align-items: center;
    gap: 32px;
    border-bottom: 1px solid #e5e5ea;
    color: #575757;

    .title {
      flex: 1;
      font-size: 16px;
    }

    .point {
      font-size: 16px;
      color: #8e8e93;
    }

    .quantity {
      font-size: 16px;
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }
`

export const ExchangeSum = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  color: #8e8e93;
  font-size: 18px;
  font-weight: 500;

  .total {
    color: #3498ff;
  }
`
