import React, { memo, useMemo } from 'react'
import styled from 'styled-components'
import { Button } from 'rsuite'

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  margin-top: 10px;
  margin-left: 20px;
  p {
    margin: 0;
  }
`
const ModalTitle = styled.p`
  width: 120px;
`
const Value = styled.span`
  text-decoration: none;
  ${({ $isPrevious }) =>
    $isPrevious &&
    `
    text-decoration: line-through;
    color: #a39e9e;
  `}
`

const ModalColumn = ({ title, previous, newValue, buttonText, buttonClick, children, value, style, show = true }) => {
  const showDefaultValue = useMemo(() => !children && !value, [children, value])
  return show ? (
    <ModalWrapper>
      <ModalTitle>{title}</ModalTitle>
      {children && children}
      {value && <p style={style}>{value}</p>}
      {showDefaultValue && (
        <p>
          <Value $isPrevious={newValue}>{previous}</Value>
          {newValue ? ` ➞ ${newValue}` : ''}
        </p>
      )}
      {buttonText && buttonClick && (
        <Button style={{ marginLeft: '4px' }} size="xs" color="red" onClick={buttonClick}>
          {buttonText}
        </Button>
      )}
    </ModalWrapper>
  ) : (
    <></>
  )
}

export default memo(ModalColumn)
