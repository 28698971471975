import React from 'react'
import styled from 'styled-components'
import { CreateInput, CreateClose, CreateTitle, CreateWrapper } from './styles'

const StyledInput = styled(CreateInput)`
  margin-top: 5px;
`
const CreateTextMessage = React.memo(({ handleFilterActions, handleOnChangeTextMessage, id, value, isDisabled = false }) => {
  return (
    <CreateWrapper>
      <CreateClose onClick={handleFilterActions} />
      <CreateTitle>文字訊息</CreateTitle>
      <StyledInput
        placeholder="請輸入文字"
        componentClass="textarea"
        rows={15}
        onChange={(value) => handleOnChangeTextMessage(value, id)}
        value={value}
        disabled={isDisabled}
      />
    </CreateWrapper>
  )
})

export default CreateTextMessage
