import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ContextProvider } from './reducers/index'
import AuthListener from './containers/authListener'
import MainLayout from './containers/mainLayout'
import Routes from './containers/routes'

function App() {
  return (
    <ContextProvider>
      <AuthListener>
        <BrowserRouter>
          <MainLayout>
            <Routes />
          </MainLayout>
        </BrowserRouter>
      </AuthListener>
    </ContextProvider>
  )
}

export default App
