import React, { useCallback } from 'react'
import { Col, ControlLabel, Form, FormControl, FormGroup, Grid, Row } from 'rsuite'
import { cloneDeep, set } from 'lodash'
import { Root, Title } from './index.style'
import Card from './Card'
import { useMGMConfig } from '../provider'

function StepBotCard() {
  const { value, updateValue } = useMGMConfig()

  const handleChange = useCallback(
    (name) => (val) => {
      const new_bot_card = cloneDeep(value.bot_card)
      set(new_bot_card, name, val)
      updateValue('bot_card', new_bot_card)
    },
    [value, updateValue],
  )

  return (
    <Root>
      <Title>一般</Title>
      <Grid fluid>
        <Row>
          <Col sm={24} lg={8}>
            <Form>
              <FormGroup>
                <ControlLabel>活動標題</ControlLabel>
                <FormControl value={value.bot_card.event_title} onChange={handleChange('event_title')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>網頁連結</ControlLabel>
                <FormControl value={value.bot_card.official_site_url} onChange={handleChange('official_site_url')} />
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Grid>
      <Title>提醒首搭/租小卡</Title>
      <Grid fluid>
        <Row>
          <Col sm={24} lg={8}>
            <Form>
              <FormGroup>
                <ControlLabel>圖片連結</ControlLabel>
                <FormControl value={value.bot_card.firstTripReminder.image} onChange={handleChange('firstTripReminder.image')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>主態標題</ControlLabel>
                <FormControl value={value.bot_card.firstTripReminder.title.sender} onChange={handleChange('firstTripReminder.title.sender')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>客態標題</ControlLabel>
                <FormControl value={value.bot_card.firstTripReminder.title.receiver} onChange={handleChange('firstTripReminder.title.receiver')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>說明文案</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  rows={3}
                  value={value.bot_card.firstTripReminder.description}
                  onChange={handleChange('firstTripReminder.description')}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>按鈕顏色</ControlLabel>
                <FormControl value={value.bot_card.firstTripReminder.button_color} onChange={handleChange('firstTripReminder.button_color')} />
              </FormGroup>
            </Form>
          </Col>
          <Col sm={24} lg={6}>
            <Card
              cover={value.bot_card.firstTripReminder.image}
              title={value.bot_card.firstTripReminder.title.sender}
              content={value.bot_card.firstTripReminder.description}
              primaryButtonText="查看活動詳情"
              primaryButtonBg={value.bot_card.firstTripReminder.button_color}
            />
          </Col>
          <Col sm={24} lg={6}>
            <Card
              cover={value.bot_card.firstTripReminder.image}
              title={value.bot_card.firstTripReminder.title.receiver}
              content={value.bot_card.firstTripReminder.description}
              primaryButtonText="查看活動詳情"
              primaryButtonBg={value.bot_card.firstTripReminder.button_color}
            />
          </Col>
        </Row>
      </Grid>
      <Title>分享小卡</Title>
      <Grid fluid>
        <Row>
          <Col sm={24} lg={8}>
            <Form>
              <FormGroup>
                <ControlLabel>圖片連結</ControlLabel>
                <FormControl value={value.bot_card.share_client.banner} onChange={handleChange('share_client.banner')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>標題</ControlLabel>
                <FormControl value={value.bot_card.share_client.title} onChange={handleChange('share_client.title')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>說明文案</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  rows={3}
                  value={value.bot_card.share_client.content}
                  onChange={handleChange('share_client.content')}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>主按鈕文字</ControlLabel>
                <FormControl value={value.bot_card.share_client.solid_button_label} onChange={handleChange('share_client.solid_button_label')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>次按鈕文字</ControlLabel>
                <FormControl value={value.bot_card.share_client.sub_button_label} onChange={handleChange('share_client.sub_button_label')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>次按鈕連結</ControlLabel>
                <FormControl value={value.bot_card.share_client.sub_button_link} onChange={handleChange('share_client.sub_button_link')} />
              </FormGroup>
            </Form>
          </Col>
          <Col sm={24} lg={12}>
            <Card
              cover={value.bot_card.share_client.banner}
              title={value.bot_card.share_client.title}
              content={value.bot_card.share_client.content}
              primaryButtonText={value.bot_card.share_client.solid_button_label || '主按鈕文字'}
              secondaryButtonText={value.bot_card.share_client.sub_button_label || '次按鈕文字'}
            />
          </Col>
        </Row>
      </Grid>
      <Title>客態完成首搭/租後，主/客態的推播小卡</Title>
      <Grid fluid>
        <Row>
          <Col sm={24} lg={8}>
            <Form>
              <FormGroup>
                <ControlLabel>圖片連結</ControlLabel>
                <FormControl value={value.bot_card.firstTripBonusLinePoints.image} onChange={handleChange('firstTripBonusLinePoints.image')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>主態標題</ControlLabel>
                <FormControl value={value.bot_card.firstTripBonusLinePoints.title} onChange={handleChange('firstTripBonusLinePoints.title')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>說明文案</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  rows={3}
                  value={value.bot_card.firstTripBonusLinePoints.description}
                  onChange={handleChange('firstTripBonusLinePoints.description')}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>按鈕顏色</ControlLabel>
                <FormControl
                  value={value.bot_card.firstTripBonusLinePoints.button_color}
                  onChange={handleChange('firstTripBonusLinePoints.button_color')}
                />
              </FormGroup>
            </Form>
          </Col>
          <Col sm={24} lg={6}>
            <Card
              cover={value.bot_card.firstTripBonusLinePoints.image}
              title={value.bot_card.firstTripBonusLinePoints.title}
              content={value.bot_card.firstTripBonusLinePoints.description}
              primaryButtonText="查看活動詳情"
              primaryButtonBg={value.bot_card.firstTripBonusLinePoints.button_color}
            />
          </Col>
        </Row>
      </Grid>
    </Root>
  )
}

export default StepBotCard
