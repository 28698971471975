const filterBatch = (coupons) => {
  let batchList = []
  return coupons.filter((coupon) => {
    if (!coupon.batch) return true
    if (!batchList.includes(coupon.batch)) {
      batchList.push(coupon.batch)
      return true
    }
    return false
  })
}

export { filterBatch }