import React, {useState} from 'react'
import TitleHeader from "../../../components/views/TitleHeader";
import CompanyDetail from "./company_detail";
import AgreementManager from "./agreemant_manager";
import ChangeLog from "./change_log";
import UserList from "./user_list";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class CompanyInfo extends React.Component {
    state = {
        // showAdminList: false,
    }

    componentDidMount() {

    }


    toggleDisplayAdminList = () => {
        this.setState({showAdminList: !this.state.showAdminList})
    }

    render() {
        return (<div>
                <TitleHeader>
                    <h4 className="list-status">公司資訊</h4>
                </TitleHeader>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <h5>更新公司</h5>
                         </AccordionSummary>
                    <AccordionDetails>
                        <CompanyDetail company_id={this.props.match.params.id}/>
                    </AccordionDetails>

                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <h5>合約</h5>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AgreementManager company_id={this.props.match.params.id}/>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <h5>保證金LOG</h5>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ChangeLog company_id={this.props.match.params.id}/>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <h5>成員列表</h5>
                    </AccordionSummary>
                    <AccordionDetails>
                        <UserList company_id={this.props.match.params.id}/>
                    </AccordionDetails>
                </Accordion>


            </div>
        )
    }

}

export default CompanyInfo
