const setFeatureCollection = (features) => {
  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features,
    },
  }
}

export default { setGeoJSON: setFeatureCollection }
