import React, { useState, useEffect } from 'react'
import { Modal, Button, Input, SelectPicker, Loader, Alert, Icon } from 'rsuite'
import { FormGroup } from '../blocked_history/styles'
import { calculateFareAdjustment } from '../../api'
import Preview from './Preview'

const CompanyTripModal = React.memo(({ show, close, confirm, companies, tripInfo }) => {
  const [company, setCompany] = useState(null)
  const [groups, setGroups] = useState([])
  const [group, setGroup] = useState(null)
  const [note, setNote] = useState('')
  const [calculating, setCalculating] = useState(false)
  const [calculation, setCalculation] = useState(null)

  const handleCompanySelect = (value) => {
    setCompany(value)
    setGroups(companies.find((v) => v.value === value).groups)
    setCalculation(null)
  }
  const handleGroupSelect = (value) => {
    setGroup(value)
    setCalculation(null)
  }
  const calculate = async () => {
    setCalculating(true)
    const payload = {
      ...tripInfo,
      payment_method: 'company',
      company_payment: {
        company,
        group,
      },
      trip_status: 'TRIP_PAYMENT_PROCESSED',
      coupon_id: undefined,
      trip_id: undefined,
    }
    const response = await calculateFareAdjustment(tripInfo.trip_id, payload)
    await sleep(300)
    setCalculating(false)
    if (response.status !== 200) {
      Alert.error(response.data.msg)
      setCalculation(null)
      return
    }
    setCalculation(response.data)
  }

  const handleClose = () => {
    reset()
    close()
  }

  useEffect(() => {
    setCompany(companies?.[0]?.value || null)
    setGroups(companies?.[0]?.groups || [])
  }, [companies])

  useEffect(() => {
    setGroup(groups?.[0]?.value || null)
  }, [groups])

  const submit = () => {
    const payload = { calculation, submit_note: note }
    confirm(payload)
  }

  const reset = () => {
    setNote('')
    setCalculation(null)
  }

  const readyForCalculate = company && group
  const readyForSubmit = !!calculation && note
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  return (
    <Modal backdrop="static" show={show} onHide={handleClose} size="xs">
      <Modal.Header>
        <Modal.Title style={{ textAlign: 'center' }}>變更為企業簽單</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginTop: '10px' }}>
        <FormGroup>
          <FormGroup.Label>公司</FormGroup.Label>
          <FormGroup.Control>
            <SelectPicker
              searchable={false}
              cleanable={false}
              data={companies}
              onSelect={handleCompanySelect}
              value={company}
              style={{ maxWidth: '80%', minWidth: 200 }}
              menuStyle={{ maxWidth: 250 }}
            />
          </FormGroup.Control>
        </FormGroup>
        <FormGroup>
          <FormGroup.Label>公司群組</FormGroup.Label>
          <FormGroup.Control>
            <SelectPicker
              searchable={false}
              cleanable={false}
              data={groups}
              onSelect={handleGroupSelect}
              value={group}
              style={{ maxWidth: '80%', minWidth: 200 }}
              menuStyle={{ maxWidth: 250 }}
            />
          </FormGroup.Control>
        </FormGroup>
        <FormGroup>
          <FormGroup.Label>變更資料</FormGroup.Label>
          <FormGroup.Control style={{ display: 'flex', alignItems: 'center', columnGap: 12 }}>
            <Button disabled={calculating || !readyForCalculate} onClick={calculate} appearance="ghost">
              計算
            </Button>
            {calculating && <Loader />}
            {!!calculation && !calculating && <Icon icon="check" style={{ color: '#1f7505' }} />}
          </FormGroup.Control>
        </FormGroup>
        {calculation && (
          <div style={{ paddingLeft: '20%', marginTop: 16 }}>
            <Preview calculation={calculation} />
          </div>
        )}
        <FormGroup>
          <FormGroup.Label>變更原因</FormGroup.Label>
          <FormGroup.Control>
            <Input value={note} onChange={setNote} componentClass="textarea" rows={3} placeholder="請輸入變更原因" />
          </FormGroup.Control>
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleClose} appearance="subtle">
            取消
          </Button>
          <Button disabled={!readyForSubmit} onClick={submit} appearance="primary">
            送出審核
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
})

export default CompanyTripModal
