import React from 'react'
import styled from 'styled-components'

const ProfileWrapper = styled.div`
  ${({ $backgroundColor }) => `
	background-color: ${$backgroundColor || 'rgb(21, 211, 137)'};
	`}
  position: relative;
  border-bottom-right-radius: 12px;
  padding: 20px 20px 12px;
  z-index: 10;
`
const MemberProfile = styled.div`
  position: relative;
  display: flex;
`
const MGMAvatarImg = styled.div`
  position: absolute;
  left: -12px;
  top: -12px;
  img {
    width: 110px;
    height: 110px;
  }
`

const MGMBannerImg = styled.div`
  position: absolute;
  right: -20px;
  bottom: 4px;
  img {
    width: 120px;
    height: 100px;
  }
`
const MemberAvatar = styled.div`
  height: 86px;
  width: 86px;
  border-radius: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 12px;
  background-image: url('https://profile.line-scdn.net/0h6a83sQgLaU4VSkZoNWEXMWUaaiQ2OzBcbCwleiNIN3ksfHsaai92KHJKMSwufXoQPnskf3BJMH8ZWR4oCxyVehJ6N3sgeC8ZMS0krw');
  z-index: 1;
`
const MemberInfo = styled.div`
  color: rgba(255, 255, 255, 0.8);
`
const MemberName = styled.h4`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.9);
  margin-left: 2px;
`
const MembershipWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.5;
`
const MembershipTag = styled.div`
  position: relative;
  display: inline-block;
  padding: 0 6px 0 12px;
  margin-left: 6px;
  margin-bottom: 4px;
  border-radius: 4px;
  background: #474745;
  color: #ffffff;
  &::before {
    content: url(https://storage.googleapis.com/web-static-files-bucket/icons/general/Ic_Img_S_Diamond.svg);
    height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    left: -6px;
    transform: translateY(-50%);
  }
`
const ExpirationDate = styled.p`
  color: #ffffff;
  opacity: 0.8;
  margin: 0;
`
const Progress = styled.div`
  height: 4px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 24px;
  margin-bottom: 8px;
`

const ProgressFiller = styled.div`
  height: 4px;
  background-color: #ffffff;
  border-radius: 24px;
  width: ${(props) => props.$completedRate}%;
`

const HintText = styled.div`
  text-align: start;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  &::after {
    content: url(https://storage.googleapis.com/web-static-files-bucket/icons/general/arrow-right-white.svg);
    width: 16px;
    height: 16px;
    margin-left: 2px;
    margin-bottom: 1px;
  }
`

const ProfileSection = ({ mainColor, profile_view }) => {
  return (
    <ProfileWrapper $backgroundColor={mainColor}>
      <MemberProfile>
        <>
          {profile_view?.avatarImgUrl && (
            <MGMAvatarImg>
              <img src={profile_view?.avatarImgUrl} alt="" />
            </MGMAvatarImg>
          )}
          <MemberAvatar />
        </>
        <MemberInfo>
          <MemberName>黃小明</MemberName>
          <MembershipWrapper>
            <MembershipTag>鑽石會員</MembershipTag>
            <ExpirationDate>有效至2023/12/31</ExpirationDate>
          </MembershipWrapper>
        </MemberInfo>
        {profile_view?.bannerImgUrl && (
          <MGMBannerImg>
            <img src={profile_view.bannerImgUrl} alt="" />
          </MGMBannerImg>
        )}
      </MemberProfile>
      <Progress>
        <ProgressFiller $completedRate={70} />
      </Progress>
      <HintText>已累積 63 趟行程，期限內再 27 趟續等鑽石會員</HintText>
    </ProfileWrapper>
  )
}

export default ProfileSection
