const initialState = {
  width: 260,
}

function sidebarReducer(state = initialState, action) {
  console.log('reducer', action.width)
  switch (action.type) {
    case 'width':
      return {
        ...state,
        width: action.width,
      }
    default:
      return state
  }
}

export { initialState, sidebarReducer }
