import React, { useState, useEffect } from 'react'
import L from 'leaflet';
import { isEmpty, isPlainObject, forEach, isArray, isNumber } from 'lodash'
import "leaflet/dist/leaflet.css";
import '@geoman-io/leaflet-geoman-free';  
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { tileLayer } from './setting'

const Map = ({ id, point, center, latlngs, width, padding, multi }) => {
  const [map, setMap] = useState(null)
  delete L.Icon.Default.prototype._getIconUrl
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
  });

  function getMapCenter() {
    if (multi && isArray(point)) {
      return point[0]
    }
    if (multi && !isEmpty(latlngs)) {
      return Object.values(latlngs)[0][0]
    }
    return point || center || latlngs[0] || []
  }

  function initMap(startPoint = [25.0475613, 121.5173399]) {
    // check if map exists
    const container = L.DomUtil.get(id)
    if(container) container._leaflet_id = null
    let map = L.map(id, { center: startPoint, zoom: 15 })
    L.tileLayer(tileLayer).addTo(map)
    return map;
  }

  function addMarkToMap(map, point) {
    if (isEmpty(point)) return
    L.marker(point).addTo(map)
  }

  function addPolygonToMap (map, latlngs) {
    if (isEmpty(latlngs)) return
    L.polygon(latlngs).addTo(map)
  }

  useEffect(() => {
    const mapCenter = getMapCenter()
    const initialMap = !isEmpty(mapCenter) ? initMap(mapCenter) : initMap()
    point && isNumber(point[0]) && addMarkToMap(initialMap, point)
    latlngs && isPlainObject(latlngs[0]) && addPolygonToMap(initialMap, latlngs)
    // 多點座標
    if (multi && point && isArray(point[0])) {
      point.forEach(value => {
        addMarkToMap(initialMap, value)
      })
    }
    // 多區域
    if (multi && latlngs && Object.keys(latlngs).length) {
      forEach(latlngs, (value) => {
        addPolygonToMap(initialMap, value)
      })
    }
    setMap(initialMap)
  }, [latlngs, point, id])

  return (
    <div id={id} style={{ zIndex: 3, width, padding, margin: '0 auto' }}/>
  )
}

export default React.memo(Map)
