/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react'
import { DatePicker, Alert } from 'rsuite'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import { SearchInput, TableController } from '../../components/table'
import { ChargeStatusCell, InvoiceStatusTag } from '../../components/finance'
import { getDriverInsurance, postDriverInsuranceReceive, putDriverInsuranceRefund } from '../../api'
import { ListWrap, AddListModel, FormGrop, AddListGrop, BreadcrumbNav, DropdownGroup } from '../../components/views'
import { DateCell, ButtonCell } from '../../components/table/cell'
import PopWarning from '../../components/popWarning'
import usePageSetting from '../../hook/usePageSetting'

const INSURANCE_PACKAGE = [
  { name: '車隊服務費 - 基本方案 $199', value: 199 },
  { name: '車隊服務費 - 加值方案 $399', value: 399 },
]

const initialState = {
  driver_id: 0,
  date: '',
  insurance: '',
  idError: '',
  dateError: '',
  insuranceError: '',
}

const FinanceDriverInsurance = () => {
  const [searchWord, setSearchWord] = useState(null)
  const { isAddMode, list, currentInfo, warning, toggleAddMode, handleDatePicker, handleOnChange, setList, setCurrentInfo, setWarning } =
    usePageSetting(initialState)

  const fetchDriverPayments = async (driverId) => {
    const response = await getDriverInsurance(driverId)
    if (response.status !== 200) return Alert.error(response.data.msg)
    const { data } = response
    if (isEmpty(data)) Alert.error('未搜尋到任何紀錄')
    window.history.replaceState(null, null, `${window.location.pathname}?driver=${driverId}`)
    setList(data)
  }

  const handleSubmit = async () => {
    const { driver_id, date, insurance } = currentInfo
    setCurrentInfo({
      ...currentInfo,
      idError: !driver_id ? '請填入司機 ID' : '',
      dateError: !date ? '請選擇年月份' : '',
      insuranceError: !insurance ? '請選擇入帳科目' : '',
    })
    if (!driver_id || !date || !insurance) return setWarning('欄位不能為空！')

    // call api
    const body = {
      driver_id,
      yearmonth: dayjs(date * 1000).format('YYYYMM'),
      amount: insurance,
    }
    const response = await postDriverInsuranceReceive(body)
    if (response.status !== 200) return setWarning(response.data.msg)
    setWarning('已送出紀錄！')
    toggleAddMode()
    fetchDriverPayments(driver_id)
    window.history.replaceState(null, null, `${window.location.pathname}?driver=${driver_id}`)
  }

  const handleSearch = () => {
    if (!searchWord) return
    fetchDriverPayments(searchWord)
  }

  const handleDriverRefund = async (data) => {
    const { id, driver_id, description } = data
    const yearmonth = description.split(' ')[0]
    const response = await putDriverInsuranceRefund({ id, driver_id, yearmonth })
    if (response.status !== 200) return setWarning(response.data.msg)
    setWarning('已申請退款/取消！')
    fetchDriverPayments(driver_id)
  }

  useEffect(() => {
    const toggleSpecificTrip = () => {
      const params = new URLSearchParams(window.location.search)
      const driverId = params.get('driver')
      if (driverId) {
        setSearchWord(driverId)
        fetchDriverPayments(driverId)
      }
    }

    toggleSpecificTrip()
  }, [])

  const { driver_id, date, insurance, idError, dateError, insuranceError } = currentInfo
  return (
    <>
      {warning && <PopWarning warning={warning} textCenter confirm={() => setWarning('')} />}
      {isAddMode ? (
        <AddListModel>
          <BreadcrumbNav
            start="車隊服務費"
            entry="新增紀錄"
            onClick={() => {
              setSearchWord('')
              toggleAddMode()
              setList([])
              window.history.replaceState(null, null, window.location.pathname)
            }}
          />
          <AddListGrop title="新增紀錄">
            <FormGrop
              title="司機 ID*"
              placeholder="DID"
              type="number"
              value={driver_id}
              handleOnChange={handleOnChange}
              setStateKey="driver_id"
              errorMessage={idError}
            />
            <FormGrop title="年月份*" type="children" errorMessage={dateError}>
              <DatePicker
                defaultValue={date ? new Date(date * 1000) : null}
                onOk={(date) => handleDatePicker(date, 'date')}
                placement="bottomStart"
                appearance="subtle"
                placeholder="選擇年月份"
                format="YYYY-MM"
                ranges={[{ value: new Date(), closeOverlay: true }]}
                style={{ width: 200 }}
              />
            </FormGrop>
            <FormGrop title="入帳科目*" type="children" errorMessage={insuranceError}>
              <DropdownGroup
                activeKey={insurance}
                options={INSURANCE_PACKAGE}
                onSelect={(value) => {
                  handleOnChange(value, 'insurance')
                }}
              />
            </FormGrop>
            <FormGrop
              title="金額*"
              type="number"
              value={insurance}
              errorMessage={insuranceError}
              style={{ 'pointer-events': 'none' }}
              handleOnChange={() => {}}
            />
          </AddListGrop>
          <div className="campaign-button-wrap">
            <button type="button" className="campaign-confirm-button" onClick={handleSubmit}>
              送出
            </button>
          </div>
        </AddListModel>
      ) : (
        <>
          <ListWrap status="車隊服務費 - 入帳/取消" addListText="+ 新增紀錄" onClick={toggleAddMode}>
            <SearchInput
              title="搜尋司機 ID"
              value={searchWord}
              setSearchWord={setSearchWord}
              withButton
              onClick={handleSearch}
              onKeyDown={(e) => {
                if (e.keyCode === 13) handleSearch()
              }}
            />
          </ListWrap>
          <TableController data={list} topHeight={104}>
            <Column flexGrow={1.5} align="center">
              <HeaderCell>ID</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column flexGrow={2} align="center">
              <HeaderCell>DID</HeaderCell>
              <Cell dataKey="driver_id" />
            </Column>
            <Column flexGrow={1.5} align="center">
              <HeaderCell>年月份</HeaderCell>
              <Cell>{(rowData) => rowData.description.split(' ')[0]}</Cell>
            </Column>
            <Column flexGrow={1.5} align="center">
              <HeaderCell>方案</HeaderCell>
              <Cell>{({ amount }) => (amount === -399 || amount === 399 ? '加值方案' : '基本方案')}</Cell>
            </Column>
            <Column flexGrow={1} align="center">
              <HeaderCell>金額</HeaderCell>
              <Cell dataKey="amount" />
            </Column>
            <Column flexGrow={2} align="center">
              <HeaderCell>扣款狀態</HeaderCell>
              <ChargeStatusCell dataKey="status" />
            </Column>
            <Column flexGrow={2.5} align="center">
              <HeaderCell>扣款時間</HeaderCell>
              <DateCell dataKey="fund_time" format="YYYY-MM-DD HH:mm" />
            </Column>
            <Column flexGrow={2.5} align="center">
              <HeaderCell>最後更新時間</HeaderCell>
              <DateCell dataKey="last_update" format="YYYY-MM-DD HH:mm" />
            </Column>
            <Column flexGrow={2} align="center">
              <HeaderCell>Charge ID</HeaderCell>
              <Cell dataKey="charge_id" />
            </Column>
            <Column flexGrow={2} align="center">
              <HeaderCell>發票號碼</HeaderCell>
              <InvoiceStatusTag />
            </Column>
            <Column flexGrow={2} align="center">
              <HeaderCell>Action</HeaderCell>
              <ButtonCell title="退款/取消" handleClick={handleDriverRefund} size="xs" />
            </Column>
          </TableController>
        </>
      )}
    </>
  )
}

export default FinanceDriverInsurance
