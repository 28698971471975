import React from 'react'
import { InputNumber } from 'rsuite'

const FormInputNumber = ({ title, unit, min, max, value, handleOnChange, setStateKey, step = 1 }) => {
  return (
    <div className="input-number-wrap">
      <span>{title}</span>
      <InputNumber
        size="sm"
        style={{ width: 100 }}
        min={min}
        max={max}
        value={value}
        onChange={(value) => handleOnChange(Number(value), setStateKey)}
        step={step}
      />
      <span>{unit}</span>
    </div>
  )
}

export default React.memo(FormInputNumber)
