import React from 'react'
import { SelectPicker } from 'rsuite'

const Picker = ({ data, value, labelKey, setStateKey, handleOnChange }) => {
  function handleSelect(e) {
    handleOnChange(e, setStateKey)
  }
  function handleClean() {
    handleOnChange('', setStateKey)
  }

  return (
      <SelectPicker placement="rightEnd" style={{ width: '40%' }} valueKey='id' labelKey={labelKey} value={value} data={data} onSelect={handleSelect} onClean={handleClean}/>
  )
}

export default React.memo(Picker)
