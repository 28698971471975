import React, { createContext, useReducer } from 'react'
import { authReducer, initialState as authState } from './auth'
import { marketingReducer, initialState as marketingState } from './marketing'
import { newsReducer, initialState as newsState } from './news'
import { sidebarReducer, initialState as sidebarState } from './sidebar'
import { damaPlusReducer, initialState as damaPlusState } from './damaPlus'

const ContextStore = createContext({
  authState,
  marketingState,
  newsState,
  sidebarState,
  damaPlusState,
})

const ContextProvider = ({ children }) => {
  const [auth, authDispatch] = useReducer(authReducer, authState)
  const [marketing, marketingDispatch] = useReducer(marketingReducer, marketingState)
  const [newsParams, newsDispatch] = useReducer(newsReducer, newsState)
  const [sidebar, sidebarDispatch] = useReducer(sidebarReducer, sidebarState)
  const [damaPlus, damaPlusDispatch] = useReducer(damaPlusReducer, damaPlusState)

  return (
    <ContextStore.Provider
      value={{
        auth,
        marketing,
        newsParams,
        sidebar,
        damaPlus,
        authDispatch,
        marketingDispatch,
        newsDispatch,
        sidebarDispatch,
        damaPlusDispatch,
      }}
    >
      {children}
    </ContextStore.Provider>
  )
}

export { ContextStore, ContextProvider }
