import React from 'react'
import CheckIcon from './CheckIcon'

const VERIFY_STATUS = {
  UNVERIFIED: 0, // 尚未審核
  PASSED: 1,
  BLUR: 2, // 照片模糊
  INFO_ERROR: 3,
  NO_DATA: 4,
  OTHER: 5,
  UNKNOWN: -9, // 視同尚未送審
  NONE: -1, // 查無狀態
}

const DocumentLabel = ({ status, reason }) => {
  const reviewStatus = status ?? -1
  let component
  switch (reviewStatus) {
    case VERIFY_STATUS.PASSED:
      component = <CheckIcon checked={true} />
      break
    case VERIFY_STATUS.NONE:
      component = <CheckIcon />
      break
    default:
      component = reason && reason !== 'NA' ? <span>{reason}</span> : <CheckIcon />
      break
  }
  return component
}

export default React.memo(DocumentLabel)
