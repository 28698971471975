import React from 'react'
import { forEach } from 'lodash'
import { InputNumber, Panel, Button, CheckboxGroup, Checkbox } from 'rsuite'
import TimePeriodSelector from './TimePeriodSelector'
import { generateCode } from '../../pages/coupons/helper'

const WeeklySetting = ({ day_of_week, interval, data = [], handleOnChange, handleTimePeriod }) => {
  const weekDays = {...day_of_week}
  forEach(weekDays, (value, key) => {
    (value === "0" || value === 0) && delete weekDays[key]
  })

  const onCheckboxChange = (selectList) => {
    const initObj = { 0: '0', 1: '0', 2: '0', 3: '0', 4: '0', 5: '0', 6: '0' }
    selectList.forEach(item => {
      initObj[item] = "1"
    })
    handleOnChange(initObj, 'day_of_week')
  }

  const onAddButtonClick = () => {
    handleTimePeriod('add', null, { 'id': generateCode(3), 'time_of_day_start': null , 'time_of_day_end': null })
  }

  return (
    <Panel header="選擇時間規則：週" bordered className="weekly-setting-wrapper">
      <div>
        <div className="input-number-wrap">
          <span>{'每'}</span>
          <InputNumber 
            size="sm"
            style={{ width: 100}} 
            value={interval}
            onChange={(value) => handleOnChange(value, 'interval')} />
          <span>{'週重複一次（以「活動使用時間」作為起始週）'}</span>
        </div>
        <CheckboxGroup
          inline
          name="checkboxList"
          value={Object.keys(weekDays)}
          onChange={onCheckboxChange}
          >
            <Checkbox value="0">週一</Checkbox>
            <Checkbox value="1">週二</Checkbox>
            <Checkbox value="2">週三</Checkbox>
            <Checkbox value="3">週四</Checkbox>
            <Checkbox value="4">週五</Checkbox>
            <Checkbox value="5">週六</Checkbox>
            <Checkbox value="6">週日</Checkbox>
        </CheckboxGroup>
      </div>
      <div>
        <div className="input-number-wrap time-period">
          <span>{'設定'}</span>
          <InputNumber 
            size="sm"
            style={{ width: 100}} 
            value={data.length}
            onChange={(value) => console.log(value)}/>
          <span>{'組時間'}</span>
          <Button appearance="ghost" size="xs" onClick={onAddButtonClick}>新增區段</Button>
        </div>
        {data.map((data) => {
          return (
            <TimePeriodSelector
              key={data.id}
              data={data}
              handleTimePeriod={handleTimePeriod}
            />
          )
        })}
      </div>
    </Panel>
  )
}

export default React.memo(WeeklySetting)

