import React from 'react'
import styled from 'styled-components'
import { Button, Icon, Modal } from 'rsuite'

const NotificationModal = ({ show, handleClose, children, dialogClassName, ...props }) => {
  return (
    <Modal backdrop="static" size="xs" show={show} onHide={handleClose} dialogClassName={dialogClassName}>
      {children}
    </Modal>
  )
}

const StyledIcon = styled(Icon)`
  font-size: 24px;
  padding-right: 10px;
`

const NotificationModalHeader = ({ type = 'warning', title = '' }) => {
  const modalIcon = () => {
    if (type === 'remind') {
      return <StyledIcon icon={type} style={{ color: '#ffb300' }} />
    }
    if (type === 'warning') {
      return <StyledIcon icon={type} style={{ color: '#f44336' }} />
    }
    return <></>
  }
  return (
    <Modal.Header>
      <Modal.Title>
        {modalIcon()}
        {title}
      </Modal.Title>
    </Modal.Header>
  )
}

const NotificationModalBody = ({ children }) => (
  <Modal.Body className="event-confirm-modal-body" style={{ marginTop: 15, paddingBottom: 15 }}>
    <p>{children}</p>
  </Modal.Body>
)

const NotificationModalFooter = ({ buttons = [] }) => {
  const Buttons = buttons.map((button, i) => {
    return (
      <Button key={`NotificationModalFooter-${i + 1}`} onClick={button.handler} appearance="link">
        {button.text}
      </Button>
    )
  })
  return (
    <Modal.Footer className="event-confirm-modal-footer" style={{ display: 'flex', flexDirection: 'column' }}>
      {Buttons}
    </Modal.Footer>
  )
}

NotificationModal.Header = NotificationModalHeader
NotificationModal.Body = NotificationModalBody
NotificationModal.Footer = NotificationModalFooter

export default NotificationModal
