import { Form } from 'rsuite'
import styled from 'styled-components'

export const Root = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
`

export const Heading = styled.h6`
  color: #4b4d4c;
  margin-bottom: 20px;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 0.5px;
    background-color: #a5a6a5;
    margin-top: 5px;
  }
`

export const CustomForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & .rs-picker-select,
  .rs-input,
  .rs-picker-date,
  .rs-input-group {
    width: 100% !important;
    max-width: 400px;
  }

  & .in-radio-input {
    width: 100%;
    margin-top: 8px;

    & .rs-input {
      width: 100%;
    }
  }
`

export const TimePeriodStack = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
`

export const TimePeriodRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export const FeatureMapBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  padding: 20px;
`

export const FeatureMapContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`

export const FeatureMapCol = styled.div`
  min-width: 320px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  & .rs-picker-select,
  .rs-input,
  .rs-picker-date,
  .rs-input-group,
  .rs-picker-check {
    width: 100% !important;
    max-width: 240px;
  }
`
