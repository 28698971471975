import React from 'react'
import { RadioGroup, DatePicker, Toggle } from 'rsuite'
import dayjs from 'dayjs'
import { ListWrapper, ListTitleWrapper, ListTitle, ListSubtitle, InputStyled, StyledDropdown, StyledDropdownItem, StyledRadio } from './styles'

const EVENTS_TYPE = {
  EVENT: 'EVENT',
  PROMO: 'PROMO',
}

const AddEvent = React.memo(({ eventData, setEventData, driverUsers }) => {
  const handleRadio = (type) => {
    if (eventData.type === type) return
    setEventData({ ...eventData, type })
  }
  return (
    <>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>活動標題</ListTitle>
        </ListTitleWrapper>
        <InputStyled placeholder="輸入活動標題" value={eventData.title} onChange={(value) => setEventData({ ...eventData, title: value })} />
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>活動描述</ListTitle>
        </ListTitleWrapper>
        <InputStyled
          placeholder="輸入活動內容與獎勵"
          value={eventData.content}
          onChange={(value) => setEventData({ ...eventData, content: value })}
        />
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle>活動連結(選填)</ListTitle>
        </ListTitleWrapper>
        <InputStyled placeholder="輸入活動連結" value={eventData.href} onChange={(value) => setEventData({ ...eventData, href: value })} />
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>是否公開</ListTitle>
        </ListTitleWrapper>
        <Toggle checked={!!eventData.published} onChange={((value) => setEventData({ ...eventData, published: value ? 1 : 0 }))} />
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>活動類型</ListTitle>
        </ListTitleWrapper>
        <RadioGroup name="radioList" inline onChange={(value) => handleRadio(value)} value={eventData.type}>
          <StyledRadio value={EVENTS_TYPE.EVENT}>趟次活動</StyledRadio>
          <StyledRadio value={EVENTS_TYPE.PROMO}>推薦活動</StyledRadio>
        </RadioGroup>
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>活動顯示時間</ListTitle>
          <ListSubtitle>(顯示在我的戰績，需早於活動開始時間)</ListSubtitle>
        </ListTitleWrapper>
        <DatePicker
          format="YYYY-MM-DD HH:mm:ss"
          placeholder="選擇日期與時間"
          ranges={[]}
          onOk={(date) => setEventData({ ...eventData, start_time: dayjs(date).unix() })}
          onClean={() => setEventData({ ...eventData, start_time: '' })}
          disabledDate={(date) => !dayjs().isBefore(dayjs(date).add(1, 'day'))}
          disabledHours={(hour, date) => !dayjs().isBefore(dayjs(date).hour(hour))}
          value={eventData.start_time ? new Date(eventData.start_time * 1000) : null}
        />
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>活動結束時間</ListTitle>
          <ListSubtitle>(顯示倒數時間)</ListSubtitle>
        </ListTitleWrapper>
        <DatePicker
          format="YYYY-MM-DD HH:mm:ss"
          placeholder="選擇日期與時間"
          ranges={[]}
          onOk={(date) => setEventData({ ...eventData, end_time: dayjs(date).unix() })}
          onClean={() => setEventData({ ...eventData, end_time: '' })}
          disabledDate={(date) => !dayjs().isBefore(dayjs(date).add(1, 'day'))}
          disabledHours={(hour, date) => !dayjs().isBefore(dayjs(date).hour(hour))}
          value={eventData.end_time ? new Date(eventData.end_time * 1000) : null}
        />
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>活動對象</ListTitle>
        </ListTitleWrapper>
        <StyledDropdown
          title={driverUsers.filter((item) => item.id === eventData.users)[0]?.description || '選擇活動對象'}
          activeKey={eventData.users}
          onSelect={(eventKey) => setEventData({ ...eventData, users: +eventKey })}
        >
          {driverUsers.map((item) => (
            <StyledDropdownItem key={item.id} eventKey={item.id}>
              {item.description}
            </StyledDropdownItem>
          ))}
        </StyledDropdown>
      </ListWrapper>
    </>
  )
})

export default AddEvent
