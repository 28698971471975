import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  Alert,
  Breadcrumb,
  Button,
  ButtonToolbar,
  ControlLabel,
  DatePicker,
  Form,
  FormControl,
  FormGroup,
  Icon,
  IconButton,
  Panel,
  Radio,
  RadioGroup,
  SelectPicker,
  Toggle,
} from 'rsuite'
import dayjs from 'dayjs'
import { array, boolean, date, number, object, string } from 'yup'
import { isEmpty } from 'lodash'
import {
  getCampaign,
  getCoupons,
  getRentalCoupons,
  postCampaign,
  postDamaImage,
  postDamaPushMessage,
  putCampaignStatus,
  putSubmitCampaign,
} from '../../api'
import { FormContainer, RadioWrapper, Root, Title } from './styles'
import {
  ACTION_TYPE,
  CAMPAIGNS_TAB,
  CAMPAIGN_OPERATE_ACTIONS,
  CAMPAIGN_TYPE,
  EVENT_MANAGEMENT_SEARCH_PARAMS_KEY,
  EXECUTION_TYPE,
  FREQUENCY_BY_DAY_OPTIONS,
  MESSAGE_TYPE,
  SCHEDULE_TYPE,
} from './const'
import UserGroupPickTable from '../../components/dama/UserGroupPickTable'
import { hasAudience, hasCoupon } from './helper'
import createFlexMessageJSON from './helper/createFlexMessageJSON'
import CreateTextMessage from './CreateTextMessage'
import CreateImageMapMessage from './CreateImageMapMessage'
import CreateCouponFields from './CreateCouponFields'
import getID from '../../utils/getID'

const extractCouponFields = (coupons = []) => {
  if (coupons.length === 0) return { couponFields: [], couponExecutionType: EXECUTION_TYPE.INSTANT, couponExecutionTime: '' }
  // TODO: 雖然選多張券的 schedule_type 及 schedule 設置應該是相同的，待檢查
  const { schedule_type, schedule } = coupons[0]
  const couponFields = coupons.map((item) => {
    return {
      id: item.id,
      text: item.payload,
      type: item.action === ACTION_TYPE.ISSUE_RENTAL_COUPON ? 'rental' : 'ride',
    }
  })
  return {
    couponFields,
    couponExecutionType: schedule_type,
    couponExecutionTime: schedule,
  }
}

const extractActions = (coupons = []) => {
  if (coupons.length === 0) return { actions: [], notificationExecutionType: EXECUTION_TYPE.INSTANT, notificationExecutionTime: '' }
  const item = coupons[0]
  return {
    actions: item.payload,
    notificationExecutionType: item.schedule_type,
    notificationExecutionTime: item.schedule,
  }
}

const DEFAULT_FORM_VALUE = {
  name: '',
  campaignType: CAMPAIGN_TYPE.COUPON,
  couponExecutionType: EXECUTION_TYPE.INSTANT,
  template_toggle: false,
  period: null,
  last_execute_day: null,
}

const schema = object().shape({
  name: string().required('活動名稱不能空白'),
  campaignType: string()
    .oneOf([CAMPAIGN_TYPE.AUDIENCE, CAMPAIGN_TYPE.COUPON, CAMPAIGN_TYPE.COUPON_AND_AUDIENCE], '活動類型格式錯誤')
    .required('必須選擇活動類型'),
  couponExecutionType: string().oneOf([EXECUTION_TYPE.INSTANT, EXECUTION_TYPE.SCHEDULE], '活動模式格式錯誤').required('必須選擇活動模式'),
  executionTime: number().required('必須設定執行時間'),
  userGroup: array().min(1, '必須選擇受眾名單').required('必須選擇受眾名單'),
  couponFields: array()
    .when('campaignType', { is: (val) => hasCoupon(val), then: (schema) => schema.min(1, '必須選擇優惠券'), otherwise: (schema) => schema.length(0) })
    .required('必須選擇優惠券'),
  actions: array()
    .when('campaignType', {
      is: (val) => hasAudience(val),
      then: (schema) => schema.min(1, '必須選擇推播訊息'),
      otherwise: (schema) => schema.length(0),
    })
    .required('必須選擇推播訊息'),
  template_toggle: boolean().required(),
  period: number()
    .when('template_toggle', {
      is: true,
      then: (schema) => schema.oneOf(FREQUENCY_BY_DAY_OPTIONS.map((item) => item.value)).required('必須選擇推播頻率'),
    })
    .when('template_toggle', { is: false, then: (schema) => schema.nullable() }),
  last_execute_day: date()
    .when('template_toggle', {
      is: true,
      then: (schema) =>
        schema
          .min(dayjs().toDate(), '結束週期時間必須是今天之後')
          .max(dayjs().add(1, 'year').toDate(), '無效的結束週期時間。此時間最大值為活動創建當日+365日')
          .required('必須選擇結束週期時間'),
    })
    .when('template_toggle', { is: false, then: (schema) => schema.nullable() }),
})

const DuplicateEventV2 = () => {
  const { id } = useParams()
  const history = useHistory()
  const [formValue, setFormValue] = useState(DEFAULT_FORM_VALUE)
  const [userGroup, setUserGroup] = useState([])
  const [executionTime, setExecutionTime] = useState()
  const [couponFields, setCouponFields] = useState([])
  const [actions, setActions] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isPushingMsg, setIsPushingMsg] = useState(false)

  const isProcessing = useMemo(() => isSaving || isSubmitting, [isSubmitting, isSaving])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await getCampaign(id)
        if (resp.status !== 200) {
          throw new Error(resp.data.msg)
        }
        const { data } = resp
        const userGroupRef = data.user_group.map((item) => item.user_group_id)
        setUserGroup(userGroupRef)
        const content = typeof data.content === 'string' ? JSON.parse(data.content) : {}
        if (hasCoupon(content.type)) {
          setExecutionTime(content.couponsExecuteTime)
        } else if (hasAudience(content.type)) {
          setExecutionTime(content.notificationExecuteTime)
        }
        const couponFieldRef =
          hasCoupon(content.type) && content.actions.length > 0
            ? extractCouponFields(content.actions.filter((item) => [ACTION_TYPE.ISSUE_COUPON, ACTION_TYPE.ISSUE_RENTAL_COUPON].includes(item.action)))
            : { couponFields: [], couponExecutionType: EXECUTION_TYPE.INSTANT, couponExecutionTime: '' }
        const notificationRef =
          hasAudience(content.type) && content.actions.length > 0
            ? extractActions(content.actions.filter((item) => item.action === ACTION_TYPE.SEND_LINE_MESSAGE))
            : { actions: [], notificationExecutionType: EXECUTION_TYPE.INSTANT, notificationExecutionTime: '' }
        setCouponFields(couponFieldRef.couponFields)
        setActions(notificationRef.actions)
        setFormValue((prev) => {
          return {
            ...prev,
            campaignType: content.type,
            couponExecutionType: hasCoupon(content.type) ? couponFieldRef.couponExecutionType : notificationRef.notificationExecutionType,
          }
        })
      } catch (e) {
        Alert.error(e.message)
      }
    }

    fetchData()

    return () => {}
  }, [id])

  const handleUserGroupChange = useCallback((val) => {
    setUserGroup(val)
  }, [])

  const handleExecutionTimeChange = useCallback((value) => {
    setExecutionTime(dayjs(value).unix())
  }, [])

  const handleExecutionTimeClean = useCallback(() => {
    setExecutionTime(null)
  }, [])

  const convertToPayload = useCallback((data) => {
    const convertCouponFieldToParams = ({ text, type, ...fields }, executionType, executionTime) => {
      if (type === 'ride') {
        return { ...fields, action: ACTION_TYPE.ISSUE_COUPON, schedule_type: executionType, schedule: executionTime, payload: text }
      }
      return { ...fields, action: ACTION_TYPE.ISSUE_RENTAL_COUPON, schedule_type: executionType, schedule: executionTime, payload: text }
    }
    const convertActionsToParams = (actions, executionType, executionTime) => {
      return { action: ACTION_TYPE.SEND_LINE_MESSAGE, schedule_type: executionType, schedule: executionTime, payload: actions }
    }

    const notifications_setting_list = data.actions.map((item) => {
      if (item.type === MESSAGE_TYPE.FLEX_MESSAGE) {
        // 目前還沒有支援 flexMessage
        return null
      }
      if (item.type === MESSAGE_TYPE.IMAGEMAP) {
        return { type: item.type, ...createFlexMessageJSON(item.altText, item.baseUrl, item.actions) }
      }
      return item
    })

    const couponParams = hasCoupon(data.campaignType)
      ? data.couponFields.map((item) => convertCouponFieldToParams(item, data.couponExecutionType, data.executionTime))
      : []
    const actionParams = hasAudience(data.campaignType) ? [convertActionsToParams(data.actions, data.couponExecutionType, data.executionTime)] : []
    const mergedActions = [...couponParams, ...actionParams]

    return {
      template_toggle: data.template_toggle,
      period: data.period,
      last_execute_day: dayjs(data.last_execute_day).format('YYYY-MM-DD'),
      status: SCHEDULE_TYPE.SCHEDULED,
      type: SCHEDULE_TYPE.SCHEDULED,
      notifications_setting_list,
      schedule: data.executionTime,
      user_group: data.userGroup,
      name: data.name,
      content: {
        type: data.campaignType,
        notificationExecuteTime: hasAudience(data.campaignType) ? data.executionTime : null,
        couponsExecuteTime: hasCoupon(data.campaignType) ? data.executionTime : null,
        actions: mergedActions,
      },
    }
  }, [])

  const renderFlexMessageFields = useCallback(() => {
    const handleOnChangeTextMessage = (value, id) => {
      const newActions = actions.map((item) => (item.id === id ? { ...item, text: value } : item))
      setActions(newActions)
    }
    const handleOnChangeFlexMessage = (value, type, id) => {
      const newActions = actions.map((item) => (item.id === id ? { ...item, [type]: value } : item))
      setActions(newActions)
    }
    const handleRemoveActions = (id) => {
      const filterActions = actions.filter((item) => item.id !== id)
      setActions(filterActions)
    }
    const handlePostImage = async (file) => {
      const formData = new FormData()
      formData.append('file', file.blobFile)
      formData.append('doc_type', 'imageMap')
      const data = await postDamaImage('marketing', formData)
      if (data.status !== 200) {
        // createModel(<NewPopWarning createPortal warning={data.msg || '發生錯誤，請稍後再試'} textCenter />)
        return null
      }
      return data?.data
    }
    return actions.map((item) =>
      item.type === 'text' ? (
        <CreateTextMessage
          key={item.id}
          handleOnChangeTextMessage={handleOnChangeTextMessage}
          handleFilterActions={() => handleRemoveActions(item.id)}
          value={item.text}
          id={item.id}
        />
      ) : (
        <CreateImageMapMessage
          key={item.id}
          handleOnChangeFlexMessage={handleOnChangeFlexMessage}
          handleFilterActions={() => handleRemoveActions(item.id)}
          handlePostImage={handlePostImage}
          id={item.id}
          altText={item.altText || ''}
          baseUrl={item.baseUrl || ''}
          setFlexMessage={setActions}
          actions={actions}
        />
      ),
    )
  }, [actions])

  const handleOnClickAddActions = useCallback(
    (isTextMessage) => () => {
      isTextMessage
        ? setActions((prev) => [...prev, { text: '', id: dayjs().valueOf(), type: 'text' }])
        : setActions((prev) => [
            ...prev,
            {
              title: '',
              id: dayjs().valueOf(),
              type: 'imagemap',
              baseSize: {
                width: 1040,
                height: 1040,
              },
            },
          ])
    },
    [],
  )

  const renderCouponFields = useCallback(() => {
    const handleOnChangeCouponFields = (value, id) => {
      const fields = couponFields.map((item) => (item.id === id ? { ...item, text: value } : item))
      setCouponFields(fields)
    }

    const handleRemoveCouponField = (id) => {
      const fields = couponFields.filter((item) => item.id !== id)
      setCouponFields(fields)
    }
    return couponFields.map((item) => {
      return (
        <CreateCouponFields
          key={item.id}
          getCoupons={getCoupons}
          getRentalCoupons={getRentalCoupons}
          handleOnChangeCouponFields={handleOnChangeCouponFields}
          handleFilterCouponField={() => handleRemoveCouponField(item.id)}
          value={item.text}
          type={item.type}
          id={item.id}
        />
      )
    })
  }, [couponFields])

  const handleAddCouponItem = useCallback(
    (type) => () => {
      // type: rental | ride
      setCouponFields((prev) => [...prev, { type, text: '', id: getID() }])
    },
    [],
  )

  const handleSaveAsDraft = async () => {
    try {
      setIsSaving(true)
      const data = {
        ...formValue,
        executionTime: formValue.couponExecutionType === EXECUTION_TYPE.INSTANT ? dayjs().unix() : executionTime,
        userGroup,
        couponFields: hasCoupon(formValue.campaignType) ? couponFields : [],
        actions: hasAudience(formValue.campaignType) ? actions : [],
      }
      await schema.validate(data)
      const payload = convertToPayload(data)
      const postCampaignResponse = await postCampaign(payload)
      if (postCampaignResponse.status !== 200) {
        throw new Error(postCampaignResponse.data.msg)
      }
      Alert.success('儲存為草稿成功！')
      return history.push(`/dama/event_management?${EVENT_MANAGEMENT_SEARCH_PARAMS_KEY.TAB}=${CAMPAIGNS_TAB.OTHER}`)
    } catch (e) {
      Alert.error(e.message)
      setIsSaving(false)
    }
  }

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)
      const data = {
        ...formValue,
        executionTime: formValue.couponExecutionType === EXECUTION_TYPE.INSTANT ? dayjs().unix() : executionTime,
        userGroup,
        couponFields: hasCoupon(formValue.campaignType) ? couponFields : [],
        actions: hasAudience(formValue.campaignType) ? actions : [],
      }
      await schema.validate(data)
      const payload = convertToPayload(data)
      const postCampaignResponse = await postCampaign(payload)
      if (postCampaignResponse.status !== 200) {
        throw new Error(postCampaignResponse.data.msg)
      }
      if (
        !data.template_toggle ||
        [CAMPAIGN_OPERATE_ACTIONS.NEW_TEMPLATE_AND_CAMPAIGN_SCHEDULE, CAMPAIGN_OPERATE_ACTIONS.NEW_CAMPAIGN_SCHEDULE].includes(
          postCampaignResponse.data.action,
        )
      ) {
        const submitCampaignResponse = await putSubmitCampaign(postCampaignResponse.data.campaign_id)
        if (submitCampaignResponse.status !== 200) {
          throw new Error(submitCampaignResponse.data.msg)
        }
      }
      if (data.template_toggle && data.couponExecutionType === EXECUTION_TYPE.SCHEDULE) {
        const launchTemplateScheduleResponse = await putCampaignStatus(postCampaignResponse.data.campaign_id)
        if (launchTemplateScheduleResponse.status !== 200) {
          throw new Error(launchTemplateScheduleResponse.data.msg)
        }
      }
      Alert.success('新增活動成功！')
      return history.push(`/dama/event_management?${EVENT_MANAGEMENT_SEARCH_PARAMS_KEY.TAB}=${CAMPAIGNS_TAB.SCHEDULED}`)
    } catch (e) {
      Alert.error(e.message)
      setIsSubmitting(false)
    }
  }

  const handlePushMessageClick = useCallback(async () => {
    try {
      setIsPushingMsg(true)
      const resp = await postDamaPushMessage({ messages: actions })
      if (!isEmpty(resp.data)) {
        if (Array.isArray(resp?.data?.details)) {
          const errMsg = resp?.data?.details
            .map((detail) => {
              if (detail?.property && detail?.message) {
                return `${detail?.property} ${detail?.message}`
              }

              if (typeof detail === 'string') {
                return detail
              }
              return 'unknown_details'
            })
            .join('\n')

          throw new Error(errMsg)
        }
        const errorMessage = resp?.data?.msg || resp?.data?.message
        if (errorMessage && errorMessage.length) {
          throw new Error(errorMessage)
        }
      }
      Alert.success('成功送出')
    } catch (e) {
      Alert.error(e.message)
    }
    setIsPushingMsg(false)
  }, [actions])

  return (
    <Root>
      <Breadcrumb>
        <Breadcrumb.Item componentClass={Link} to="/dama/event_management">
          DAMA+活動管理
        </Breadcrumb.Item>
        <Breadcrumb.Item active>複製活動2.0</Breadcrumb.Item>
      </Breadcrumb>
      <Title>複製活動</Title>
      <FormContainer>
        <Form formValue={formValue} onChange={setFormValue}>
          <FormGroup>
            <ControlLabel>選擇分眾名單</ControlLabel>
            <FormControl accepter={Panel}>
              <UserGroupPickTable pickedUserGroupById={userGroup} onChange={handleUserGroupChange} />
            </FormControl>
          </FormGroup>
          <FormGroup>
            <ControlLabel>活動名稱</ControlLabel>
            <FormControl name="name" placeholder="請輸入名稱" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>範本活動</ControlLabel>
            <FormControl name="template_toggle" accepter={Toggle} />
          </FormGroup>
          <FormGroup>
            <ControlLabel>活動模式</ControlLabel>
            <FormControl name="campaignType" accepter={RadioGroup} inline>
              <Radio value={CAMPAIGN_TYPE.COUPON}>只發券</Radio>
              <Radio value={CAMPAIGN_TYPE.AUDIENCE}>只推播</Radio>
              <Radio value={CAMPAIGN_TYPE.COUPON_AND_AUDIENCE}>發券+推播</Radio>
            </FormControl>
          </FormGroup>
          <FormGroup>
            <ControlLabel>執行時間</ControlLabel>
            <FormControl name="couponExecutionType" accepter={RadioGroup}>
              <Radio value={EXECUTION_TYPE.INSTANT}>建立活動後立即發券</Radio>
              <RadioWrapper>
                <Radio value={EXECUTION_TYPE.SCHEDULE}>指定日期時間發券</Radio>
                <DatePicker
                  style={{ marginLeft: '8px', maxWidth: '200px' }}
                  disabled={formValue.couponExecutionType !== EXECUTION_TYPE.SCHEDULE}
                  format="YYYY-MM-DD HH:mm:ss"
                  placeholder="選擇日期與時間"
                  ranges={[]}
                  onOk={handleExecutionTimeChange}
                  onClean={handleExecutionTimeClean}
                  disabledDate={(date) => !dayjs().isBefore(dayjs(date).add(1, 'day'))}
                  disabledHours={(hour, date) => !dayjs().isBefore(dayjs(date).hour(hour))}
                  value={executionTime ? dayjs.unix(executionTime).toDate() : undefined}
                />
              </RadioWrapper>
            </FormControl>
          </FormGroup>

          {formValue.template_toggle && (
            <>
              <FormGroup>
                <ControlLabel>推播週期</ControlLabel>
                <FormControl
                  name="period"
                  accepter={SelectPicker}
                  data={FREQUENCY_BY_DAY_OPTIONS}
                  searchable={false}
                  cleanable={false}
                  placeholder="請選擇推播週期"
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>結束週期時間</ControlLabel>
                <FormControl
                  name="last_execute_day"
                  accepter={DatePicker}
                  format="YYYY-MM-DD"
                  ranges={[]}
                  appearance="default"
                  cleanable={false}
                  placeholder="選擇日期"
                  disabledDate={(date) => dayjs().isAfter(date, new Date())}
                  placement="top"
                  oneTap
                />
              </FormGroup>
            </>
          )}
          {hasCoupon(formValue.campaignType) && (
            <FormGroup>
              <ControlLabel>新增分眾發券</ControlLabel>
              <FormControl accepter={Panel}>
                <ButtonToolbar>
                  <IconButton icon={<Icon icon="plus" />} appearance="ghost" onClick={handleAddCouponItem('ride')}>
                    新增一組乘車券
                  </IconButton>
                  <IconButton icon={<Icon icon="plus" />} appearance="ghost" onClick={handleAddCouponItem('rental')}>
                    新增一組租車券
                  </IconButton>
                </ButtonToolbar>
                {renderCouponFields()}
              </FormControl>
            </FormGroup>
          )}
          {hasAudience(formValue.campaignType) && (
            <FormGroup>
              <ControlLabel>新增推播訊息</ControlLabel>
              <FormControl accepter={Panel}>
                <ButtonToolbar>
                  <IconButton appearance="ghost" icon={<Icon icon="pencil" />} onClick={handleOnClickAddActions(true)}>
                    新增文字訊息
                  </IconButton>
                  <IconButton appearance="ghost" icon={<Icon icon="image" />} onClick={handleOnClickAddActions(false)}>
                    新增圖文訊息
                  </IconButton>
                </ButtonToolbar>
                {renderFlexMessageFields()}
              </FormControl>
            </FormGroup>
          )}
          <FormGroup>
            <ButtonToolbar>
              {hasAudience(formValue.campaignType) && (
                <Button color="cyan" onClick={handlePushMessageClick} disabled={isPushingMsg} loading={isPushingMsg}>
                  推播給自己
                </Button>
              )}
              <Button appearance="primary" color="green" onClick={handleSaveAsDraft} disabled={isProcessing} loading={isSaving}>
                儲存草稿
              </Button>
              <Button appearance="primary" disabled={isProcessing} loading={isSubmitting} onClick={handleSubmit}>
                創建活動
              </Button>
            </ButtonToolbar>
          </FormGroup>
        </Form>
      </FormContainer>
    </Root>
  )
}

export default DuplicateEventV2
