/* eslint-disable no-restricted-syntax */
import React, { useState, useRef, useEffect } from 'react'
import { Button, Alert, Loader } from 'rsuite'
import styled from 'styled-components'
import { createEvent, updateEvent, createContents, getDriversEvents, buildData, buildUsers } from '../../api'
import { DEFAULT_EVENT_DATA, DEFAULT_CONTENT_DATA, VERIFY_STEP_DATA } from './utils'
import AddEvent from './AddEvent'
import AddContents from './AddContents'
import { Link, useParams } from 'react-router-dom'

export const Breadcrumb = styled.div`
  font-size: 12px;
  color: #4b4d4c;
  display: flex;
  margin-bottom: 40px;
  span:last-child {
    margin-left: 5px;
  }
`
const Wrapper = styled.div`
  padding: 30px;
`
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`
const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
`
const StepStatus = styled.div`
  height: 24px;
  width: 24px;
  background-color: #3498ff;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`
const Content = styled.div`
  width: 100%;
  padding: 32px 40px;
  background-color: #fff;
  min-height: calc(100% - 145px);
`
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: end;
`
const LoaderBackground = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: #fff;
  z-index: 10;
  opacity: 0.5;
`

const DriverCampaign = React.memo(({ history, location, action }) => {
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(1)
  const [eventData, setEventData] = useState(DEFAULT_EVENT_DATA)
  const [contentsData, setContentsData] = useState(DEFAULT_CONTENT_DATA)
  const [driverUsers, setDriverUsers] = useState([])

  const { id } = useParams()
  const [eventId, setEventId] = useState(id)

  const recordEventIdAndUpdateContents = (eventID) => {
    // every content need event id to call createContent API
    const newContentsData = contentsData.map((item) => ({ ...item, event: eventID }))
    setContentsData(newContentsData)
  }
  const addEventHandler = async () => {
    try {
      const data = await createEvent(eventData)
      const { insertId } = data.data
      setEventId(insertId)
      recordEventIdAndUpdateContents(insertId)
      if (data.status !== 200) return 'FAILED'
      return 'OK'
    } catch {
      return 'FAILED'
    }
  }
  const updateEventHandler = async () => {
    try {
      const data = await updateEvent(id, eventData)
      if (data.status !== 200) return 'FAILED'
      return 'OK'
    } catch {
      return 'FAILED'
    }
  }
  const addContentsHandler = async () => {
    // remove idKey property, body no need idKey
    const data = contentsData.map(({ id, event, type, title, body, row, column, flex, info, query }) => ({
      id,
      event,
      type,
      title,
      body,
      row,
      column,
      flex,
      info,
      query,
    }))
    try {
      const response = await Promise.allSettled([createContents(eventId, { contents: data })])
      const isFailed = response.some(({ status }) => {
        status === 'rejected' && Alert.error(`活動區塊發生錯誤！`)
        return status === 'rejected'
      })
      if (isFailed) {
        return 'FAILED'
      }
      return 'OK'
    } catch {
      return 'FAILED'
    }
  }
  const findOne = async (id) => {
    const params = { q: `{events(id: ${id}){contents}}` }
    const data = await getDriversEvents(params)
    if (data.status !== 200) return Alert.error('發生錯誤，請稍候嘗試')
    const { events: [event] } = data.data.data

    return event
  }
  const initStateForEdit = async () => {
    const event = await findOne(id)
    setEventData({
      ...event,
      created_at: undefined,
      updated_at: undefined,
      contents: undefined
    })
    setContentsData(event.contents.map(v => ({
      ...v,
      idKey: v.id,
      created_at: undefined,
      updated_at: undefined,
    })))
  }
  const initStateForCopy = async () => {
    const event = await findOne(id)
    setEventData({
      ...event,
      id: undefined,
      created_at: undefined,
      updated_at: undefined,
      contents: undefined
    })
    setContentsData(event.contents.map(v => ({
      ...v,
      idKey: v.id,
      id: undefined,
      created_at: undefined,
      updated_at: undefined,
    })))
  }
  const ACTION_MAP = {
    edit: {
      eventId: id,
      title: '編輯活動',
      eventOnClick: updateEventHandler,
      contentOnClick: addContentsHandler,
      init: initStateForEdit
    },
    create: {
      eventId,
      title: '建立活動',
      eventOnClick: addEventHandler,
      contentOnClick: addContentsHandler,
      init: () => null
    },
    copy: {
      eventId,
      title: '建立活動',
      eventOnClick: addEventHandler,
      contentOnClick: addContentsHandler,
      init: initStateForCopy
    }
  }
  const STEP_STATUS = {
    1: {
      title: ACTION_MAP[action].title,
      buttonText: '下一步',
      data: eventData,
      onClick: ACTION_MAP[action].eventOnClick,
    },
    2: {
      title: '設定活動內容(顯示於我的戰績)',
      buttonText: '完成',
      data: contentsData,
      onClick: ACTION_MAP[action].contentOnClick,
    },
  }

  const handleReset = () => {
    setStep(1)
    setLoading(false)
    setEventData(DEFAULT_EVENT_DATA)
    setContentsData(DEFAULT_CONTENT_DATA)
  }
  const buildUsersAndDataHandler = async () => {
    const usersResponse = await buildUsers(eventId)
    const event = await findOne(eventId)
    const contentsResponse = await Promise.all(event.contents.map((content) => buildData(content.id)))
    if (usersResponse.status !== 200 || contentsResponse.some((result) => result.status !== 200))
      return Alert.error(usersResponse.data?.msg || contentsResponse[0].data?.msg || 'build data or build users error!')
    return handleReset()
  }
  const handleComplete = () => {
    Alert.success('完成活動設定！')
    buildUsersAndDataHandler()
  }
  const VerifyData = () => {
    const currentData = STEP_STATUS[step].data
    return VERIFY_STEP_DATA[step].every((item) => {
      return Array.isArray(currentData) ? currentData.every((dataItem) => dataItem[item]) : currentData[item]
    })
  }
  const handleOnClick = async () => {
    if (!VerifyData()) return Alert.error('必填欄位請填寫！')
    setLoading(true)
    const result = await STEP_STATUS[step].onClick()
    setLoading(false)
    if (result === 'FAILED') return Alert.error('發生錯誤，請稍候嘗試')
    if (Object.keys(STEP_STATUS).length === step) {
      handleComplete()
      history.push('/driver_campaign')
    }
    return setStep(step + 1)
  }
  const getDriversEventsUsers = async () => {
    const params = { q: '{users}' }
    const data = await getDriversEvents(params)
    if (data.status !== 200) return Alert.error('發生錯誤，請稍候嘗試')
    const { users } = data.data.data
    return setDriverUsers(users)
  }
  useEffect(() => {
    ACTION_MAP[action].init(id)
    getDriversEventsUsers()
  }, [])

  return (
    <>
      {loading && (
        <div>
          <Loader size="md" center vertical />
          <LoaderBackground />
        </div>
      )}
      <Wrapper>
        <Breadcrumb>
          <span>
            <Link to="/driver_campaign">司機活動後台 /</Link>
          </span>
          <span>{ACTION_MAP[action].title}</span>
        </Breadcrumb>
        <TitleWrapper>
          <StepStatus>{`${step}/${Object.keys(STEP_STATUS).length}`}</StepStatus>
          <Title>{STEP_STATUS[step]?.title}</Title>
        </TitleWrapper>
      </Wrapper>
      <Content>
        {step === 1 && <AddEvent eventData={eventData} setEventData={setEventData} driverUsers={driverUsers} />}
        {step === 2 && <AddContents eventId={ACTION_MAP[action].eventId} contentsData={contentsData} setContentsData={setContentsData} />}
        <ButtonsWrapper>
          <Button appearance="primary" onClick={handleOnClick}>
            {STEP_STATUS[step]?.buttonText}
          </Button>
        </ButtonsWrapper>
      </Content>
    </>
  )
})

export default DriverCampaign
