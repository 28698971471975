import React, { useState } from 'react'
import TitleHeader from '../../components/views/TitleHeader'
import ClientPreview from '../../components/drivers/ClientPreview'
import ReservationPreview from "../../components/drivers/ReservationPreview";

class Drivers extends React.Component {
	initState = () => {
		return {
			driverId: this.props.match.params.driver_id || '',
		}
	}

	state = this.initState()

	get isInIframe() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true
		}
	}
	render() {
		const { driverId } = this.state

		return (
			<>
				{!this.isInIframe && 
					<TitleHeader>
						<h4 className="list-status">司機資訊：{driverId}</h4>
					</TitleHeader>
				}
				<ClientPreview driverId={driverId} />
				<ReservationPreview driverId={driverId} />
			</>
		)
	}
}

export default Drivers
