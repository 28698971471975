import React, { Component } from 'react'
import CarouselCard from './CarouselCard';
import Card from './Card';
import { DatePicker } from 'rsuite';

class Simulator extends Component {

	render() {
		const { filterData, form, addModel, currentTab, handleSimulatorDate } = this.props
		return (
			<div className="simulator-wrap">
				{!addModel && <div className="simulator-date-filter">
					<DatePicker
						defaultValue={form.end_time ? new Date(form.end_time) : new Date()}
						onOk={(date) => handleSimulatorDate(date)}
						placement={'bottomEnd'}
						appearance={'default'}
						placeholder={'Select Date'}
						isoWeek
						hideMinutes={second => second % 30 !== 0}
						format={'YYYY-MM-DD HH:mm'}
						ranges={[{ value: new Date(), closeOverlay: true }]} />
				</div>}
				<div className="simulator-pad">
					<CarouselCard filterData={filterData.carousel} addModel={addModel} form={form} currentTab={currentTab} />
					<div className="simulator-banner-card">
						<div className="subtitle_01">天天領</div>
					</div>
					<Card filterData={filterData.cards} addModel={addModel} form={form} currentTab={currentTab} />
				</div>
			</div>
		)
	}

}

export default Simulator
