import React, { useEffect, useState } from 'react'
import { InputPicker, Alert } from 'rsuite'
import { getExchangeStores } from '../../api'

const StorePicker = ({ selectedStore, setSelectedStore }) => {
  const [stores, setStores] = useState([])

  useEffect(() => {
    const getStores = async () => {
      try {
        const { data: { data } = {} } = await getExchangeStores()
        setStores(data.map(v => ({ label: v.name, value: v.id })))
      } catch (error) {
        Alert.error(error?.message || 'fetch error')
      }
    }
    getStores()
  }, [])

  return (
    <InputPicker
      data={stores}
      defaultValue={selectedStore}
      onChange={(value) => setSelectedStore(value)}
      cleanable={false}
      placeholder="請選擇辦公室"
      style={{ width: 220 }}
    />
  )
}

export default StorePicker
