import React from 'react'
import { Popover, Whisper } from 'rsuite'
import { Cell } from 'rsuite-table'

const BotImageCell = ({ rowData, dataKey, ...props }) => {
	const speaker = <Popover><b>{rowData[dataKey].hero.url}</b></Popover>
	return (
		<Cell {...props}>
			<Whisper placement="top" speaker={speaker}>
				<img src={rowData[dataKey].hero.url} alt="" />
			</Whisper>
		</Cell>
	)
}

export default BotImageCell
