import React, { useEffect, useState } from 'react'
import { SelectPicker, Alert } from 'rsuite'
import { getRentalCouponCategories } from '../../api'

const CategoryPicker = ({ value, setValue }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await getRentalCouponCategories()
        setData(result.data.map((v) => ({ label: v.category, value: v.id })))
      } catch (error) {
        Alert.error(error?.message || 'fetch coupon category error')
      }
    }
    fetch()
  }, [])

  return <SelectPicker data={data} value={value} placement="topStart" onChange={setValue} cleanable={false} style={{ width: 300 }} />
}

export default React.memo(CategoryPicker)
