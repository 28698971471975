/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react'
import { Placeholder } from 'rsuite'
import { Body, Text, Cover, Heading, Root, Content, SecondaryButton, EmptyCover, PrimaryButton } from './Card.style'

function Card({ cover = '', title = '', content = '', primaryButtonText, primaryButtonBg = '#111111', secondaryButtonText }) {
  const paragraph = useMemo(
    () => ({
      title: <Placeholder.Paragraph style={{ marginBottom: '8px' }} rows={1} />,
      content: <Placeholder.Paragraph style={{ marginBottom: '8px' }} rows={4} rowMargin={4} />,
    }),
    [],
  )

  return (
    <Root>
      {cover ? <Cover src={cover} /> : <EmptyCover />}
      <Body>
        <Heading>{title || paragraph.title}</Heading>
        <Content>{content ? content.split('\\n').map((text, i) => <Text key={i}>{text}</Text>) : paragraph.content}</Content>
        <PrimaryButton bg={primaryButtonBg}>{primaryButtonText}</PrimaryButton>
        {!!secondaryButtonText && <SecondaryButton>{secondaryButtonText}</SecondaryButton>}
      </Body>
    </Root>
  )
}

export default Card
