import React from 'react'
import { Input, InputGroup } from 'rsuite'
import { FormController, FormField, FormLabel, SectionTitle, Stack } from './styles'

const PanelMainPage = () => {
  return (
    <Stack>
      <SectionTitle>主態活動頁</SectionTitle>
      <FormField isRequired>
        <FormLabel>標題</FormLabel>
        <FormController>
          <Input disabled value="同活動標題" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>圖片</FormLabel>
        <FormController>
          <Input placeholder="輸入圖片連結" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>內文</FormLabel>
        <FormController>
          <Input componentClass="textarea" rows={5} placeholder="輸入活動說明" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>按鈕文字</FormLabel>
        <FormController>
          <Input placeholder="輸入文字" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>活動詳情網址</FormLabel>
        <FormController>
          <Input placeholder="輸入網址" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>預設好友頭像圖片</FormLabel>
        <FormController>
          <Input placeholder="輸入圖片連結" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>好友領券數量文字</FormLabel>
        <FormController>
          <InputGroup>
            <InputGroup.Addon>我已經</InputGroup.Addon>
            <Input placeholder="抓了" />
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>99</InputGroup.Addon>
            <Input placeholder="隻小鬼囉" />
          </InputGroup>
        </FormController>
      </FormField>
      <SectionTitle>分享成功頁</SectionTitle>
      <FormField isRequired>
        <FormLabel>圖片</FormLabel>
        <FormController>
          <Input placeholder="輸入圖片連結" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>標題</FormLabel>
        <FormController>
          <Input placeholder="分享成功" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>說明文字</FormLabel>
        <FormController>
          <Input placeholder="你還可以繼續分享給其他朋友喔！" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>按鈕文字</FormLabel>
        <FormController>
          <Input placeholder="繼續分享給其他朋友" />
        </FormController>
      </FormField>
      <SectionTitle>查看客態頁面 (已開啟禮物)</SectionTitle>
      <FormField isRequired>
        <FormLabel>圖片</FormLabel>
        <FormController>
          <Input placeholder="輸入圖片連結" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>標題文字</FormLabel>
        <FormController>
          <Input placeholder="朋友已開啟禮物囉！" />
        </FormController>
      </FormField>
      <SectionTitle>查看客態頁面 (未開啟禮物)</SectionTitle>
      <FormField isRequired>
        <FormLabel>圖片</FormLabel>
        <FormController>
          <Input placeholder="輸入圖片連結" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>標題文字</FormLabel>
        <FormController>
          <Input placeholder="朋友還未打開禮物" />
        </FormController>
      </FormField>
    </Stack>
  )
}

export default PanelMainPage
