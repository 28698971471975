/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { find, isEmpty, isArray, map, toLower } from 'lodash'
import { Alert, DateRangePicker, SelectPicker } from 'rsuite'
import { DropdownGroup } from '../../../components/views'
import MultiSelector from './MultiSelector'
import GeneSelector from './GeneSelector'

const DimensionWrapper = styled.div`
  display: flex;
  align-items: center;
  & > div {
    margin-right: 12px;
  }
`

const REGISTER_TYPE_OPTIONS = [
  {
    name: '指定期間',
    value: 'DATE_PERIOD',
  },
  {
    name: '所有註冊用戶',
    value: 'ALL',
  },
]

// 之前寫死 VERIFY_DIMENSION_DATA 要檢查什麼，希望之後由 api 回的東西來判斷
const getDimensionVerifyData = (filters, type) => {
  let selectType = find(filters, { type }).selector_type
  selectType = isArray(selectType) ? selectType : [selectType]
  const dimensionData = []
  selectType.forEach((type) => {
    // DATE_PERIOD: 選擇起迄日期
    if (type === 'DATE_PERIOD') {
      dimensionData.push('start_date')
      dimensionData.push('end_date')
      // REGION_WITHOUT_NONE: 少掉「不分縣市」選項，仍然是驗證有沒有 'region'
    } else if (type === 'REGION_WITHOUT_NONE') {
      dimensionData.push('region')
      // OPERATOR_WITH_NUMERIC_VALUE: 要選一個 operator 和值
    } else if (type === 'OPERATOR_WITH_NUMERIC_VALUE') {
      dimensionData.push('operator')
      dimensionData.push('numeric_value')
    } else {
      dimensionData.push(toLower(type))
    }
  })
  return dimensionData
}

// 檢查介於之間
const isBetweenValid = (numeric_value) => {
  const [first, second] = numeric_value.map(Number)
  const isValid = first && second && second > first
  if (!isValid) Alert.warning('請確認「介於之間」的數值！')
  return isValid
}

// 檢查 GENE 選項
const checkGeneStatus = (parameters) => {
  // basic check: period 和 dama_rules
  if (!['period', 'dama_rules'].every((property) => parameters[property])) return false
  // 檢查 GENE 細項
  const { parameters: geneParameters, type: geneType } = parameters.dama_rules[0]
  const { operator, numeric_value, region, roi_group, gene_cai_label, null_percentage_label, time_label } = geneParameters
  if (geneType === 'REGION') return !isEmpty(region)
  if (geneType === 'ROI_GROUP') return !isEmpty(roi_group)
  if (geneType === 'GENE_CAI_LABEL') return !isEmpty(gene_cai_label)
  if (geneType === 'NULL_PERCENTAGE_LABEL') return !isEmpty(null_percentage_label)
  if (geneType === 'TIME_LABEL') return !isEmpty(time_label)
  if (operator === 'BETWEEN' && numeric_value && !isBetweenValid(numeric_value)) return false
  return operator && !isEmpty(numeric_value)
}

const checkLifeStyleStatus = (parameters) => {
  if (!['lifestyle_period', 'dama_rules'].every((property) => parameters[property])) return false
  const { parameters: lifeStyleParameters, type: lifeStyleType } = parameters.dama_rules[0]
  const { coupon_sensitivity_label, null_percentage_label, cai_label, nes_label, time_label, time_label_trip_percentage, roi, roi_group } =
    lifeStyleParameters
  if (lifeStyleType === 'COUPON_SENSITIVITY_LABEL') return !isEmpty(coupon_sensitivity_label)
  if (lifeStyleType === 'NULL_PERCENTAGE_LABEL') return !isEmpty(null_percentage_label)
  if (lifeStyleType === 'CAI_LABEL') return !isEmpty(cai_label)
  if (lifeStyleType === 'NES_LABEL') return !isEmpty(nes_label)
  if (lifeStyleType === 'TIME_LABEL') return !isEmpty(time_label)
  if (lifeStyleType === 'TIME_LABEL_TRIP_PERCENTAGE') return !isEmpty(time_label_trip_percentage)
  if (lifeStyleType === 'ROI') return !isEmpty(roi)
  if (lifeStyleType === 'ROI_GROUP') return !isEmpty(roi_group)
}

const checkLocalOrNonLocalStatus = (parameters) => {
  if (!['yearmonth', 'dama_rules'].every((property) => parameters[property])) return false

  const { parameters: localParameters, type: localType } = parameters.dama_rules[0]
  const { region, local_type, trips } = localParameters

  if (localType === 'REGION') return !isEmpty(region)
  if (localType === 'LOCAL_TYPE') return !isEmpty(local_type)
  if (localType === 'TRIPS') return !isEmpty(trips)
}

// 檢查各維度
const checkFinishStatus = (type, newParameters, filters) => {
  if (type === 'GENE') return checkGeneStatus(newParameters)
  if (type === 'LIFESTYLE') {
    return checkLifeStyleStatus(newParameters)
  }
  if (type === 'LOCAL_OR_NONLOCAL') {
    return checkLocalOrNonLocalStatus(newParameters)
  }
  // check BETWEEN
  const { operator, numeric_value } = newParameters
  if (operator === 'BETWEEN' && numeric_value && !isBetweenValid(numeric_value)) return false
  // 取得應該要驗證的資料
  const verifyData = getDimensionVerifyData(filters, type)
  return verifyData?.every((property) => !isEmpty(newParameters[property]))
}

const DimensionSelector = ({ data, filters, options, handleParameters, disabled }) => {
  const { id, type, parameters } = data
  const [geneGroups, setGeneGroups] = useState([])
  const [lifeStyleGroups, setLifeStyleGroups] = useState([])
  const [localGroups, setLocalGroups] = useState([])
  const [serviceGroups, setServiceGroups] = useState([])
  const [selectType, setSelectType] = useState([])
  const [selectGroup, setSelectGroup] = useState([])
  const [selectLifeStyleGroup, setSelectLifeStyleGroup] = useState([])
  const [selectLocalGroup, setSelectLocalGroup] = useState([])
  const [selectServiceGroup, setSelectServiceGroup] = useState([])
  const [registerType, setRegisterType] = useState(null)

  // 註冊專用 Dropdown => 處理 ALL 情況
  const handleRegisterDropdown = (select) => {
    if (select === registerType) return
    setRegisterType(select)
    handleParameters(id, {
      ...data,
      finished: select === 'ALL',
      parameters: select === 'ALL' ? { start_date: 'DAY_0', end_date: dayjs().format('YYYY-MM-DD') } : {},
    })
  }

  const handleYearmonth = (date) => {
    const yearmonth = dayjs(+date).format('YYYYMM')
    const newParameters = { ...parameters }
    newParameters.yearmonth = yearmonth
    handleParameters(id, { ...data, parameters: newParameters, finished: checkFinishStatus(type, newParameters, filters) })
  }

  // 日期選擇
  const handleDatePeriod = (value) => {
    const [startDate, endDate] = value.map((date) => dayjs(+date).format('YYYY-MM-DD'))
    const newParameters = { ...parameters }
    newParameters.start_date = startDate
    newParameters.end_date = endDate
    handleParameters(id, { ...data, parameters: newParameters, finished: checkFinishStatus(type, newParameters, filters) })
  }

  // GENE group 選擇
  const handleSelectGeneGroup = (type) => {
    const { selector_type } = find(geneGroups, { type })
    setSelectGroup(selector_type)
    handleParameters(id, {
      ...data,
      parameters: { ...parameters, dama_rules: [{ type, parameters: {} }] },
    })
  }

  // LIFESTYLE group 選擇
  const handleSelectLifeStyleGroup = (type) => {
    const { selector_type } = find(lifeStyleGroups, { type })
    setSelectLifeStyleGroup(selector_type)
    handleParameters(id, {
      ...data,
      parameters: { ...parameters, dama_rules: [{ type, parameters: {} }] },
    })
  }
  // 本地異地選擇
  const handleSelectLocalGroup = (type) => {
    const { selector_type } = find(localGroups, { type })
    setSelectLocalGroup(selector_type)
    handleParameters(id, {
      ...data,
      parameters: { ...parameters, dama_rules: [{ type, parameters: {} }] },
    })
  }

  // 服務類型選擇（計程車/租車）
  const handleSelectNewUserStatus = (type) => {
    const { selector_type } = find(serviceGroups, { type })
    setSelectServiceGroup(selector_type)
    handleParameters(id, {
      ...data,
      parameters: { ...parameters, dama_rules: [{ type, parameters: {} }] },
    })
  }

  const handleGeneSelect = (value, property) => {
    const { type: geneType, parameters: geneParameters } = parameters.dama_rules[0]
    if (geneParameters?.[property] === value) return
    const newGeneParameters = { ...geneParameters }
    newGeneParameters[property] =
      Array.isArray(value) && value.indexOf(undefined) !== -1
        ? value.filter((row) => {
            return row !== undefined ? row : false
          })
        : value
    // 如果是切換 operator 就順便清空值
    if (property === 'operator') {
      newGeneParameters.numeric_value = null
    }
    const newParameters = { ...parameters, dama_rules: [{ type: geneType, parameters: { ...newGeneParameters } }] }
    handleParameters(id, {
      ...data,
      finished: checkGeneStatus(newParameters),
      parameters: newParameters,
    })
  }

  const handleLifeStyleSelect = (value, property) => {
    const { type: lifeStyleType, parameters: lifeStyleParameters } = parameters.dama_rules[0]
    if (lifeStyleParameters?.[property] === value) return
    const newLifeStyleParameters = { ...lifeStyleParameters }
    newLifeStyleParameters[property] = value
    const newParameters = {
      ...parameters,
      dama_rules: [
        {
          type: lifeStyleType,
          parameters: {
            ...newLifeStyleParameters,
          },
        },
      ],
    }
    handleParameters(id, {
      ...data,
      finished: checkLifeStyleStatus(newParameters),
      parameters: newParameters,
    })
  }

  const handleLocalOrNonLocalSelect = (value, property) => {
    const { type: localType, parameters: localParameters } = parameters.dama_rules[0]

    if (localParameters?.[property] === value) return
    const newLifeStyleParameters = { ...localParameters }
    newLifeStyleParameters[property] = value
    const newParameters = {
      ...parameters,
      dama_rules: [
        {
          type: localType,
          parameters: {
            ...newLifeStyleParameters,
          },
        },
      ],
    }

    handleParameters(id, {
      ...data,
      finished: checkLocalOrNonLocalStatus(newParameters),
      parameters: newParameters,
    })
  }

  const handleNewUserStatusSelect = (value, property) => {
    const { type: serviceType, parameters: serviceParameters } = parameters.dama_rules[0]

    if (serviceParameters?.[property] === value) return
    const newLifeStyleParameters = { ...serviceParameters }
    newLifeStyleParameters[property] = value
    const newParameters = {
      ...parameters,
      dama_rules: [
        {
          type: serviceType,
          parameters: {
            ...newLifeStyleParameters,
          },
        },
      ],
    }

    handleParameters(id, {
      ...data,
      finished: Number(value[0]) > 0 && Number(value[1]) > 0 && Number(value[2]) > 0,
      parameters: newParameters,
    })
  }

  // other select
  const handleOtherSelect = (value, property) => {
    if (parameters[property] === value) return
    const newParameters = { ...parameters }
    newParameters[property] = value
    // 如果是切換 operator 就順便清空值
    if (property === 'operator') {
      newParameters.numeric_value = null
    }
    handleParameters(id, {
      ...data,
      finished: checkFinishStatus(type, newParameters, filters),
      parameters: newParameters,
    })
  }

  useEffect(() => {
    const { type, parameters } = data
    if (!type || !filters) return
    const { selector_type, selector_group } = find(filters, { type })

    // GENE 有 selector_group 要選
    if (type === 'GENE') {
      if (parameters?.dama_rules?.[0].type) {
        console.log('parameters?.dama_rules?.[0].type: ', parameters?.dama_rules?.[0].type)
        const { selector_type: sub_selector_type } = find(selector_group, { type: parameters?.dama_rules?.[0].type })
        setSelectGroup(sub_selector_type)
      }
      setGeneGroups(
        map(selector_group, (obj) => {
          return {
            ...obj,
            value: obj.type,
          }
        }),
      )
    }
    if (type === 'LIFESTYLE') {
      if (parameters?.dama_rules?.[0].type) {
        const { selector_type: sub_selector_type } = find(selector_group, { type: parameters?.dama_rules?.[0].type })
        setSelectLifeStyleGroup(sub_selector_type)
      }
      setLifeStyleGroups(
        map(selector_group, (obj) => {
          return {
            ...obj,
            value: obj.type,
          }
        }),
      )
    }

    if (type === 'NEW_USER_STATUS') {
      if (parameters?.dama_rules?.[0].type) {
        const { selector_type: sub_selector_type } = find(selector_group, { type: parameters?.dama_rules?.[0].type })
        setSelectServiceGroup(sub_selector_type)
      }
      setServiceGroups(
        map(selector_group, (obj) => {
          return {
            ...obj,
            value: obj.type,
          }
        }),
      )
    }

    if (type === 'LOCAL_OR_NONLOCAL') {
      if (parameters?.dama_rules?.[0].type) {
        const { selector_type: sub_selector_type } = find(selector_group, { type: parameters?.dama_rules?.[0].type })
        setSelectLocalGroup(sub_selector_type)
      }
      setLocalGroups(
        map(selector_group, (obj) => {
          return {
            ...obj,
            value: obj.type,
          }
        }),
      )
    }
    if (type === 'USER_REGISTERED_DATE') {
      setRegisterType(data.parameters?.start_date === 'DAY_0' ? 'ALL' : 'DATE_PERIOD')
    }
    setSelectType(selector_type)
  }, [data, filters])

  return (
    <DimensionWrapper>
      {/* 註冊期間 */}
      {type === 'USER_REGISTERED_DATE' && (
        <>
          <DropdownGroup
            style={{ width: 'min-Content' }}
            activeKey={registerType}
            options={REGISTER_TYPE_OPTIONS}
            onSelect={handleRegisterDropdown}
            disabled={disabled}
          />
          {registerType !== 'ALL' && (
            <DateRangePicker
              value={parameters.start_date && parameters.end_date ? [new Date(parameters.start_date), new Date(parameters.end_date)] : []}
              appearance="default"
              cleanable={false}
              isoWeek
              onChange={handleDatePeriod}
              placeholder="選擇期間"
              disabled={disabled}
            />
          )}
        </>
      )}
      {/* GENE，拉出來處理 */}
      {type === 'GENE' && (
        <>
          <SelectPicker
            style={{ width: '20vw' }}
            data={options.GENE_PERIOD}
            groupBy="group"
            labelKey="name"
            searchable={false}
            cleanable={false}
            renderMenuGroup={(menuGroup) => {
              return menuGroup === 'undefined' ? '其他選項' : menuGroup
            }}
            placeholder="選擇期間"
            onSelect={(v) => handleOtherSelect(v, 'period')}
            value={parameters.period || ''}
            disabled={disabled}
          />
          <DropdownGroup
            style={{ width: 'min-Content', paddingRight: '12px', marginRight: '12px' }}
            activeKey={parameters?.dama_rules?.[0].type}
            options={geneGroups}
            onSelect={handleSelectGeneGroup}
            disabledList={[]}
            placeholder="選擇基因"
            disabled={disabled}
          />
          {selectGroup &&
            selectGroup.map((group, i) => {
              console.log('group: ', group)
              return (
                <GeneSelector
                  key={`${group}_${i + 1}`}
                  geneType={parameters?.dama_rules?.[0].type}
                  type={group}
                  optionData={options[group]}
                  parameters={parameters?.dama_rules?.[0].parameters}
                  handleGeneSelect={handleGeneSelect}
                  property={type === 'REGION_WITHOUT_NONE' ? 'region' : toLower(parameters?.dama_rules?.[0].type)}
                  disabled={disabled}
                />
              )
            })}
        </>
      )}
      {type === 'LIFESTYLE' && (
        <>
          <DropdownGroup
            style={{ width: 'min-Content' }}
            activeKey={parameters[toLower(`${type}_PERIOD`)]}
            options={options[`${type}_PERIOD`]}
            placeholder="時間週期"
            onSelect={(v) => handleOtherSelect(v, toLower(`${type}_PERIOD`))}
            disabled={disabled}
          />
          <DropdownGroup
            style={{ width: 'min-Content' }}
            activeKey={parameters?.dama_rules?.[0].type}
            options={lifeStyleGroups}
            onSelect={handleSelectLifeStyleGroup}
            disabledList={[]}
            placeholder="維度"
            disabled={disabled}
          />
          {selectLifeStyleGroup &&
            selectLifeStyleGroup.map((group, i) => {
              return (
                <MultiSelector
                  key={`${group}_${i + 1}`}
                  type={group}
                  property={toLower(parameters?.dama_rules?.[0].type)}
                  optionData={options[parameters?.dama_rules?.[0].type]}
                  parameters={parameters?.dama_rules?.[0].parameters}
                  handleSelect={handleLifeStyleSelect}
                  disabled={disabled}
                />
              )
            })}
        </>
      )}

      {type === 'LOCAL_OR_NONLOCAL' && (
        <>
          <DropdownGroup
            style={{ width: 'min-Content' }}
            activeKey={parameters?.dama_rules?.[0].type}
            options={localGroups}
            onSelect={handleSelectLocalGroup}
            disabledList={[]}
            placeholder="維度"
            disabled={disabled}
          />
          {selectLocalGroup &&
            selectLocalGroup.map((group, i) => {
              return (
                <MultiSelector
                  key={`${group}_${i + 1}`}
                  type={group}
                  property={toLower(parameters?.dama_rules?.[0].type)}
                  optionData={options[group]}
                  parameters={parameters?.dama_rules?.[0].parameters}
                  handleSelect={handleLocalOrNonLocalSelect}
                  disabled={disabled}
                />
              )
            })}
        </>
      )}

      {type === 'NEW_USER_STATUS' && (
        <>
          <DropdownGroup
            style={{ width: 'min-Content' }}
            activeKey={parameters?.dama_rules?.[0].type}
            options={serviceGroups}
            onSelect={handleSelectNewUserStatus}
            disabledList={[]}
            placeholder="服務"
            disabled={disabled}
          />
          {selectServiceGroup &&
            selectServiceGroup.map((group, i) => {
              return (
                <MultiSelector
                  key={`${group}_${i + 1}`}
                  type={group}
                  property={toLower(parameters?.dama_rules?.[0].type)}
                  optionData={options[group]}
                  parameters={parameters?.dama_rules?.[0].parameters}
                  handleSelect={handleNewUserStatusSelect}
                  disabled={disabled}
                />
              )
            })}
        </>
      )}
      {/* 其他維度 */}
      {isArray(selectType) &&
        selectType.map((type) => {
          return (
            <MultiSelector
              key={type}
              type={type}
              property={type === 'REGION_WITHOUT_NONE' ? 'region' : toLower(type)}
              optionData={options[type]}
              parameters={parameters}
              handleSelect={handleOtherSelect}
              handleDatePeriod={handleDatePeriod}
              handleYearmonth={handleYearmonth}
              disabled={disabled}
            />
          )
        })}
    </DimensionWrapper>
  )
}

export default React.memo(DimensionSelector)
