import React from 'react'
import styled from 'styled-components'

const DemoModel = styled.div`
  width: 160px;
  height: 160px;
  border: 1px solid #bbbcbb;
  margin-bottom: 10px;
  border-radius: 4px;
  color: #bbbcbb;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 11px;
  margin-top: 5px;
`
const Text = styled.div`
  flex: 1 1;
  text-align: center;
`
const ModelHr = styled.div`
  height: 100%;
  width: 1px;
  background-color: #bbbcbb;
`

const ModelDemo = React.memo(({ currentLineBotModel }) => {
  return (
    <DemoModel>
      <Text>Action 1</Text>
      {currentLineBotModel !== 'a' && (
        <>
          <ModelHr />
          <Text>Action 2</Text>
        </>
      )}
      {currentLineBotModel === 'c' && (
        <>
          <ModelHr />
          <Text>Action 3</Text>
        </>
      )}
    </DemoModel>
  )
})

export default ModelDemo
