import React from 'react'
import styled from 'styled-components'
import { Tag, Icon, Timeline } from 'rsuite'

const Wrapper = styled.div`
  width: 100%;
  height: min-content;
  flex: 1;
  padding: 0 1vw;
  .rs-steps-item-title {
    font-size: 14px;
  }
`
const InfoWrapper = styled.div`
  text-align: left;
  display: flex;
  margin: 5px;
`
const InfoTitle = styled.div`
  font-weight: 600;
  width: 35%;
`
const InfoDesc = styled.div`
  text-align: left;
  width: 65%;
`
const TimelineTitle = styled.div`
  color: #68737d;
  margin-bottom: 12px;
`

const TripInfo = ({ title, desc, tag, icon }) => {
  return (
    <InfoWrapper>
      <InfoTitle>
        {icon && <Icon icon={icon} style={{ marginRight: '4px' }} />}
        {title}
      </InfoTitle>
      <InfoDesc>
        {desc}
        {tag && <Tag style={{ display: 'inline', marginLeft: '4px' }}>{tag}</Tag>}
      </InfoDesc>
    </InfoWrapper>
  )
}

const TimelineItem = ({ title, timeline, description }) => {
  return (
    <Timeline.Item>
      <TimelineTitle>{title}</TimelineTitle>
      <p>{timeline}</p>
      {description &&
        description.map((data) => (
          <div key={data}>
            <br />
            {data}
          </div>
        ))}
    </Timeline.Item>
  )
}

const LOCALIZED = {
  cash: '現金',
  creditcard: '信用卡',
  company: '企業簽單',
  collectable: '平台代墊',
  linepay: 'LINE Pay',
  applepay: 'Apple Pay',
  googlepay: 'Google Pay',
  native: 'LINE TAXI APP',
  line: 'LINE',
  facebook: 'Facebook',
  app: '店家叫車',
  USER_REQUESTED: '用戶叫車',
  USER_RESERVED_TIME: '用戶用車時間',
  TRIP_STARTED: '行程開始',
  TRIP_CANCELED: '行程取消',
  ENROUTE_PICKUP: '司機前往中',
  REQUESTING_DRIVER: '尋找司機中',
  PENDING_RESPONSE_DRIVER: '尋找司機中',
  WAITING_SPECIFY: '等待司機接單中',
  WAITING_DISTRIBUTION: '司機接受等候派單中',
  DRIVER_ASSIGNED: '等待司機接單中（先搶先贏）',
  DRIVER_ACCEPTED: '司機指派任務',
  DRIVER_REJECTED: '司機拒絕',
  DRIVER_RESERVED: '司機已預約',
  DRIVER_ENROUTE: '司機前往中',
  DRIVER_ARRIVED: '司機已到達',
  TRIP_FINISHED: '行程結束',
  DRIVER_CALL_RIDER: '司機撥打電話給乘客',
  TRIP_PAYMENT_PENDING: '等待付款中',
  TRIP_PAYMENT_PROCESSED: '付款完成',
  ESTIMATE: '設定地址',
}

const DATE_OPTIONS = {
  hour12: false,
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  month: '2-digit',
  day: '2-digit',
  timeZone: 'Asia/Taipei',
}

const TripDetails = React.memo(({ basic, trip_updates }) => {
  const handleLastUpdate = (last_update) => {
    return last_update ? new Date(last_update * 1000).toLocaleString('zh-tw', DATE_OPTIONS) : '--'
  }

  const getTripTag = ({ airport, request_time, reserve_time }) => {
    if (airport) return '機場接送'
    return request_time !== reserve_time ? '預約單' : '即時單'
  }

  return (
    <Wrapper>
      <>
        <TripInfo title="行程編號" desc={basic.trip_hash} tag={getTripTag(basic)} />
        <TripInfo title="行程狀態" desc={LOCALIZED[basic.trip_status]} />
        <TripInfo title="叫車管道" desc={LOCALIZED[basic.user_type]} />
        <TripInfo title="付款方式" desc={LOCALIZED[basic.payment_method]} />
        <TripInfo title="車資" desc={basic.amount} />
      </>
      <hr />
      {basic.airport && (
        <>
          <TripInfo title="機場接送 - 車款" desc={basic?.driver_info?.car_brand} />
          <TripInfo title="機場接送 - 司機" desc={basic?.driver_info?.name} />
          <TripInfo title="機場接送 - 司機電話" desc={basic?.driver_info?.phone_number} />
          <TripInfo title="機場接送 - 車牌" desc={basic?.driver_info?.plate_number} />
          <hr />
        </>
      )}
      <TripInfo title="上車點" desc={basic.start_address} icon="arrow-right" />
      {basic.stops && basic.stops.map(({ address }) => <TripInfo key={address} title="停靠點" desc={address} icon="dot-circle-o" />)}
      <TripInfo title="目的地" desc={basic?.lastAddress?.address || '無'} icon="map-signs" />
      <hr />
      <Timeline>
        {trip_updates.map(({ update_type, last_update, description, uid }) => (
          <TimelineItem
            key={update_type}
            title={`${LOCALIZED[update_type] ? LOCALIZED[update_type] : update_type}（${uid === basic.user_id ? '乘客' : '司機'} ${uid}）`}
            timeline={handleLastUpdate(last_update)}
            description={description}
          />
        ))}
      </Timeline>
    </Wrapper>
  )
})

export default TripDetails
