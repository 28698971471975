import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'rsuite'
import Preview from './Preview'

const ConfirmCompanyTripModal = React.memo(({ show, close, confirm, payload, companyInfo }) => {
  return (
    <Modal backdrop="static" show={show} onHide={close} size="xs">
      <Modal.Header>
        <Modal.Title style={{ textAlign: 'center' }}>確認變更為企業簽單</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginTop: '10px' }}>
        {payload && <Preview calculation={payload.calculation} note={payload.submit_note} companyInfo={companyInfo} />}
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={close} appearance="subtle">
            取消
          </Button>
          <Button onClick={confirm} appearance="primary">
            確認送出
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
})

export default ConfirmCompanyTripModal
