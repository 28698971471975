import React from 'react'
import { Tag } from 'rsuite'
import { Cell } from 'rsuite-table'

const getTagInfo = (type) => {
  switch (type) {
    case 'monthly':
      return {
        name: 'Monthly',
        color: 'orange'
      }
    case 'weekly':
      return {
        name: 'Weekly',
        color: 'green'
      }
    case 'daily':
      return {
        name: 'Daily',
        color: 'blue'
      }
    default:
      return {
        name: 'None',
        color: ''
      }
  }
}

const TypeCell = React.memo(({ rowData, ...props }) => {
	const tagInfo = getTagInfo(rowData.schedule_type)
	return (
		<Cell {...props}>
			<Tag style={{ marginRight: 4 }} color={tagInfo.color}>{tagInfo.name}</Tag>
		</Cell>
	)
})

export default TypeCell