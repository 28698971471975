/* eslint-disable react/no-children-prop */
import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ContextStore } from '../reducers'
import Login from '../pages/login'
import ResetPassword from '../pages/reset_password'
import News from '../pages/news'
import FinanceStatement from '../pages/finance_statement'
import FinanceOffset from '../pages/finance_offset'
import FinanceCtbcAllocation from '../pages/finance_allocation_ctbc'
import FinanceDriverAllocation from '../pages/finance_allocation_driver_payment'
import FinanceTransactionsAllocation from '../pages/finance_allocation_transactions'
import FinanceDriverInsurance from '../pages/finance_driver_insurance'
import FinanceDriverSystemFee from '../pages/finance_driver_system_fee'
import HappyHours from '../pages/happyhours'
import ScheduledPin from '../pages/scheduled_pin'
import { MemberCenter } from '../pages/member_center'
import DispatchArea from '../pages/dispatch_area'
import VegboxControl from '../pages/vegbox_control'
import Delivery from '../pages/delivery'
import Rewards from '../pages/rewards'
import MapboxMap from '../pages/mapbox'
import Trip from '../pages/trip'
import Dashboard from '../pages/dashboard'
import Advertisement from '../pages/marketing/advertisement'
import Campaign from '../pages/marketing/campaign'
import UserReport from '../pages/marketing/user_report'
import ModifyTable from '../pages/modify_table'
import Drivers from '../pages/drivers'
import BusinessPayment from '../pages/company/business_payment'
import CompanyList from '../pages/company/company_list'
import CompanyInfo from '../pages/company/company_info'
import PassFail from '../pages/pass_fail'
import AboutMe from '../pages/user/info/user_info'
import Transactions from '../pages/user/transactions/transactions'
import Scheduler from '../pages/scheduler'
import Equipped from '../pages/equipped'
import TripTransaction from '../pages/trip_transaction'
import { AddEvent } from '../pages/dama'
import EventManagement from '../pages/dama/EventManagement'
import DamaAddUserGroup from '../pages/dama/addUserGroup'
import UserGroup from '../pages/dama/UserGroup'
import DriverCampaigns from '../pages/driver_campaign'
import DriverCampaign from '../pages/driver_campaign/DriverCampaign'
import PersonnelManagement from '../pages/identities'
import TripRoute from '../pages/trip_route'
import { CreateMGMCampaign, EditMGMCampaign, MGMCampaigns } from '../pages/mgm'
import { DriverMileage } from '../pages/driver_mileage'
import DriverMileageBatch from '../pages/driver_mileage_batch'
import CreateReward from '../pages/driver_mileage/CreateReward'
import ExchangeStuff from '../pages/driver_mileage/ExchangeStuff'
import { DriversIdentifiers, CreateDriversIdentifiers } from '../pages/drivers_identifiers'
import { BlockedHistory } from '../pages/blocked_history'
import { CanceledReservation } from '../pages/canceled_reservation'
import ApplyUserGroupDAMA from '../pages/dama/ApplyUserGroupDAMA'
import CreateUserGroupDAMA from '../pages/dama/CreateUserGroupDAMA'
import DuplicateUserGroupDAMA from '../pages/dama/DuplicateUserGroupDAMA'
import EditUserGroupDAMA from '../pages/dama/EditUserGroupDAMA'
import { Receipt } from '../pages/receipt'
import CreateEventV2 from '../pages/dama/CreateEventV2'
import DuplicateEventV2 from '../pages/dama/DuplicateEventV2'
import EditEventV2 from '../pages/dama/EditEventV2'
import { SearchUser, UserInfo } from '../pages/user_info'
import { DriversViceContractHistory } from '../pages/drivers_vice_contract_history'
import { RentalCoupons, EditRentalCoupon } from '../pages/rental_coupons'
import MGMGenerator from '../pages/mgm_generator'
import CreateCoupon from '../pages/new_coupons/create'
import CouponsRoot from '../pages/new_coupons'
import UpdateCoupon from '../pages/new_coupons/update'
import DuplicateCoupon from '../pages/new_coupons/duplicate'
import { SurgePriceMultiplierTests, NewSurgePriceMultiplierTests, SurgePriceMultiplierTestsDetail } from '../pages/surge_price_multiplier_tests'

const Routes = () => {
  const { auth } = React.useContext(ContextStore)
  if (!auth.user) {
    console.log('unauth')
    return !auth.loading && <Login />
  }

  const isPassed = (user, roles) => {
    // 'Admin','Default','Engineer','Marketing','Operation','Part-time','Sales','SalesManager','Accountant'
    if (!user) {
      return PassFail
    }
    return roles.forEach((role) => {
      if (user.role === role) return null
      return PassFail
    })
  }

  // 為了 React router v5.1 or higher 而做的角色權限控制
  // roles: [ 'Admin','Default','Engineer','Marketing','Operation','Part-time','Sales','SalesManager','Accountant']
  const switchViewByRole = (roles = [], component) => {
    if (auth.user && roles.includes(auth.user.role)) {
      return React.createElement(component)
    }
    return <PassFail />
  }

  return (
    <Switch>
      <Route exact path="/" children={<Dashboard />} />
      <Route path="/reset_password" children={<ResetPassword />} />
      <Route path="/mapbox" children={<MapboxMap />} />
      <Route path="/trip" children={<Trip />} />
      <Route path="/news" children={<News />} />
      <Route path="/finance/offset" children={<FinanceOffset />} />
      <Route path="/finance/statement" children={<FinanceStatement />} />
      <Route path="/finance/allocation/ctbc" children={<FinanceCtbcAllocation />} />
      <Route path="/finance/allocation/driver" children={<FinanceDriverAllocation />} />
      <Route path="/finance/allocation/transactions" children={<FinanceTransactionsAllocation />} />
      <Route path="/finance/driver_insurance" children={<FinanceDriverInsurance />} />
      <Route path="/finance/driver_system_fee" children={<FinanceDriverSystemFee />} />
      <Route path="/marketing/advertisement" children={<Advertisement />} />
      <Route path="/marketing/campaign" children={<Campaign />} />
      <Route path="/marketing/coupons" children={<CouponsRoot />} exact />
      <Route path="/marketing/coupons/create" children={<CreateCoupon />} />
      <Route path="/marketing/coupons/:promo_id/update" children={<UpdateCoupon />} />
      <Route path="/marketing/coupons/:promo_id/duplicate" children={<DuplicateCoupon />} />
      <Route path="/marketing/rental_coupons" children={<RentalCoupons />} exact />
      <Route path="/marketing/rental_coupons/add" children={<EditRentalCoupon mode="add" />} />
      <Route path="/marketing/rental_coupons/:id" children={<EditRentalCoupon />} exact />
      <Route path="/marketing/rental_coupons/:id/copy" children={<EditRentalCoupon mode="copy" />} />
      <Route path="/marketing/rewards" children={<Rewards />} />
      <Route path="/marketing/happyhours" children={<HappyHours />} />
      <Route path="/marketing/mgm_generator" children={<MGMGenerator />} />
      <Route path="/marketing/scheduled_pin" children={<ScheduledPin />} />
      <Route path="/marketing/member_center" children={<MemberCenter />} />
      <Route path="/marketing/user_report" children={<UserReport />} />
      <Route path="/dispatch_area" children={<DispatchArea />} />
      <Route path="/vegbox_control" children={<VegboxControl />} />
      <Route path="/delivery" children={<Delivery />} />
      <Route path="/equipped" children={<Equipped />} />
      {/* [/modify_table/*, /drivers/:driver_id, /company/business_payment/*. /company/company_list/*, /users/:user_id] 需要 Refactor 成 FC 才能升級 React Router v5.1 */}
      <Route path="/modify_table/:table_name" component={ModifyTable} />
      <Route path="/modify_table" component={ModifyTable} />
      <Route path="/drivers/:driver_id" component={Drivers} />
      <Route path="/scheduler" children={<Scheduler />} />
      <Route
        path="/company/business_payment/:search_value"
        component={isPassed(auth.user, ['Admin', 'SalesManager', 'Sales', 'Accountant', 'Engineer']) || BusinessPayment}
      />
      <Route
        path="/company/business_payment"
        component={isPassed(auth.user, ['Admin', 'SalesManager', 'Sales', 'Accountant', 'Engineer']) || BusinessPayment}
      />
      <Route
        path="/company/company_list"
        component={isPassed(auth.user, ['Admin', 'SalesManager', 'Sales', 'Accountant', 'Engineer']) || CompanyList}
      />
      <Route
        path="/company/company_info/:id"
        component={isPassed(auth.user, ['Admin', 'SalesManager', 'Sales', 'Accountant', 'Engineer']) || CompanyInfo}
      />
      <Route path="/users/:user_id" component={isPassed(auth.user, ['Admin', 'Engineer', 'Operation', 'Marketing']) || AboutMe} />
      <Route path="/transactions" children={switchViewByRole(['Admin', 'Operation'], Transactions)} />
      <Route path="/cs/trip_transaction" children={<TripTransaction />} />
      <Route path="/cs/trip_route" children={<TripRoute />} />
      <Route path="/cs/receipt" children={<Receipt />} exact />
      <Route path="/cs/receipt/:trip_id" render={(props) => <Receipt key={props.match.params.trip_id} />} />
      <Route path="/dama/add_event" children={<AddEvent />} />
      <Route path="/dama/event_management/add" component={CreateEventV2} />
      <Route path="/dama/event_management/:id/duplicate" component={DuplicateEventV2} />
      <Route path="/dama/event_management/:id/edit" component={EditEventV2} />
      <Route path="/dama/event_management" children={<EventManagement />} exact />
      <Route path="/dama/user_group/add" component={CreateUserGroupDAMA} />
      <Route path="/dama/user_group/add/old" component={DamaAddUserGroup} />
      <Route path="/dama/user_group/:id/apply" component={ApplyUserGroupDAMA} />
      <Route path="/dama/user_group/:id/duplicate" component={DuplicateUserGroupDAMA} />
      <Route path="/dama/user_group/:id/edit" component={EditUserGroupDAMA} />
      <Route path="/dama/user_group" component={UserGroup} />
      {/* [/driver_campaign, /driver_campaign/:id, /add_driver_campaign, /add_driver_campaign/:id] 需要 Refactor 才能升級到 React Router v5.1 or higher */}
      <Route path="/driver_campaign" component={DriverCampaigns} exact />
      <Route path="/driver_campaign/:id" render={(routeProps) => <DriverCampaign action="edit" {...routeProps} />} />
      <Route path="/add_driver_campaign" render={(routeProps) => <DriverCampaign action="create" {...routeProps} />} exact />
      <Route path="/add_driver_campaign/:id" render={(routeProps) => <DriverCampaign action="copy" {...routeProps} />} />
      <Route path="/identities/personnel_management" children={<PersonnelManagement />} />
      <Route path="/mgm" children={<MGMCampaigns />} exact />
      <Route path="/mgm/create" children={<CreateMGMCampaign />} exact />
      <Route path={['/mgm/:id/edit', '/mgm/:id/duplicate']} children={<EditMGMCampaign />} exact />
      <Route path="/driver_mileage" children={<DriverMileage />} exact />
      <Route path="/driver_mileage/:driver_id/create" children={<CreateReward />} />
      <Route path="/driver_mileage/:driver_id/exchange" children={<ExchangeStuff />} />
      <Route path="/driver_mileage_batch" children={<DriverMileageBatch />} exact />
      <Route path="/drivers_identifiers" children={<DriversIdentifiers />} exact />
      <Route path="/drivers_identifiers/:driver_id/add" children={<CreateDriversIdentifiers />} />
      <Route path="/drivers_identifiers/:driver_id" render={(props) => <DriversIdentifiers key={props.match.params.driver_id} />} />
      <Route path="/blocked_history" children={<BlockedHistory />} exact />
      <Route path="/blocked_history/:driver_id" render={(props) => <BlockedHistory key={props.match.params.driver_id} />} />
      <Route path="/canceled_reservation" children={<CanceledReservation />} exact />
      <Route path="/canceled_reservation/:driver_id" render={(props) => <CanceledReservation key={props.match.params.driver_id} />} />
      <Route path="/surge_price_multiplier_tests" children={<SurgePriceMultiplierTests />} exact />
      <Route path="/surge_price_multiplier_tests/add" children={<NewSurgePriceMultiplierTests />} exact />
      <Route path="/surge_price_multiplier_tests/:test_id" children={<SurgePriceMultiplierTestsDetail />} exact />
      <Route path="/user_info" children={<SearchUser />} exact />
      <Route path="/user_info/:uid" children={<UserInfo />} />
      <Route path="/drivers_vice_contract_history/:driver_id" render={(props) => <DriversViceContractHistory key={props.match.params.driver_id} />} />
      <Route path="*" render={() => <Redirect to="/" />} />
    </Switch>
  )
}
export default Routes
