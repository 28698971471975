import React from 'react'
import { Icon } from 'rsuite'
import { PreviewOpenedPageWrapper } from './styles'
import defaultTheme from './theme'

const PreviewOpenedPage = ({ theme = defaultTheme }) => {
  return (
    <PreviewOpenedPageWrapper theme={theme}>
      <div className="top">
        <div className="icon" />
        <div className="icon">
          <Icon icon="close" />
        </div>
      </div>
      <div className="body">
        <div className="cover" />
        <div className="title">朋友開啟禮物囉！</div>
      </div>
    </PreviewOpenedPageWrapper>
  )
}

export default PreviewOpenedPage
