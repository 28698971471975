import styled from 'styled-components'

export const Root = styled.div`
  width: 100%;
  padding: 16px 20px;
`

export const Title = styled.h6`
  width: 100%;
  margin: 16px 0;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 0.5px;
    background-color: #a5a6a5;
    margin-top: 5px;
  }
`

export const ListBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 8px 0;
`

export const ListItem = styled.div`
  max-width: 20rem;
  display: flex;
  align-items: center;
  gap: 4px;

  & div:first-child {
    flex: 1;
  }
`
