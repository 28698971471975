import React, { useState, useMemo } from 'react'
import dayjs from 'dayjs'
import { includes } from 'lodash'
import TitleHeader from '../../components/views/TitleHeader'
import { Spacer } from "./ReactUI";
import { Checkbox, Button, Drawer, Tree } from 'rsuite'

const getTreeTableList = (dataList) => {
	// 整理類別
	const re = /.+\//
	let tagList = ['default']
	dataList.forEach(({ description, table}) => {
		let str = description || table
		if (re.test(str)) {
			const tag = str.match(re)[0]
			// 過濾重複、28 號後移除安心快送相關
			if(includes(tagList, tag)) return
			if (dayjs().isAfter(dayjs('2022-01-28')) && ['蔬果箱/', '餐廳外送/', '安心快送/', '預購/'].includes(tag)) return
			tagList.push(tag)
		}
	})

	let treeList = []
	treeList = tagList.map((tag, index) => {
		if (tag === 'default')
			return {
				table: tag,
				children: dataList.filter(({ description, table}) => !re.test(description || table)),
			}
		return {
			table: tag.slice(0, -1), // 去除多餘的 / 符號
			children: dataList.filter(({ description, table}) => {
				let str = description || table
				return re.test(str) && str.match(re)[0] === tag
			}),
		}
	})

	return treeList
}

const Head = ({ isHidden, changeTable, tableList, isFilterHidden, changeFilterHidden }) => {
	const [showDrawer, setDrawer] = useState(false)
	const [showTypes, setTypes] = useState([])

	const setExpandTypes = (type) => {
		let expands = [...showTypes]
		if (expands.indexOf(type) < 0) {
			expands.push(type)
			setTypes(expands)
		} else {
			setTypes(expands.filter((item) => item !== type))
		}
	}

	const treeList = useMemo(() => getTreeTableList(tableList), [tableList])

	if (isHidden) return <div />
	return <TitleHeader>
		<h4 className="list-status">選擇表格</h4>
		<p>iFrame不顯示這欄</p>
		<Spacer />
		<Checkbox checked={isFilterHidden} onChange={(value, checked) => changeFilterHidden(checked)}>isFilterHidden</Checkbox>
		<Button onClick={() => setDrawer(true)} style={{ color: 'white', backgroundColor: 'black', margin: 8 }}>Tables</Button>
		<Drawer show={showDrawer} onHide={() => setDrawer(false)}>
			<Drawer.Header>
				<Drawer.Title>選擇表格</Drawer.Title>
			</Drawer.Header>
			<Drawer.Body>
				<Tree
					data={treeList}
					childrenKey="children"
					valueKey="table"
					expandItemValues={showTypes}
					onSelect={(activeNode, value) => {
						activeNode.hasOwnProperty('description') ? changeTable(value) : setExpandTypes(activeNode.table)
					}}
					renderTreeNode={({ description, table }) => description || table}
				/>
			</Drawer.Body>
		</Drawer>
	</TitleHeader>
}

export default React.memo(Head)
