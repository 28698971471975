import React from 'react'
import styled from 'styled-components'
import { IconButton, Icon, Divider } from 'rsuite'
import { Cell } from 'rsuite-table'
import { checkDeletable } from '../../pages/news/helper'

const ActionCellWrap = styled(Cell)`
	& > div {
		display: flex;
		justify-content: flex-start;
		align-items: center;	
	}
`

const ActionCell = ({ rowData, dataKey, activeKey, toggleAddMode, handlePreviewMode, handleDeleteNews, ...props }) => {	
	const handleEditAction = () => {
		handlePreviewMode(rowData)
		toggleAddMode()
	}

	const handleCopyAction = () => {
		handlePreviewMode(rowData, 'copy')
		toggleAddMode()
	}

	const handleDeleteAction = () => {
		handleDeleteNews(rowData.id)
	}


	return (
		<ActionCellWrap {...props}>
			{activeKey === 'news' &&(
				<div>
					<IconButton
						appearance="subtle"
						onClick={handleEditAction}
						icon={<Icon icon="edit2" />}
					/>
					<Divider vertical />
				</div>
			)}
			<IconButton
				appearance="subtle"
				onClick={handleCopyAction}
				icon={<Icon icon="copy-o" />}
			/>
			{ checkDeletable(rowData) &&(
				<div>
					<Divider vertical />
					<IconButton
						appearance="subtle"
						onClick={handleDeleteAction}
						icon={<Icon icon="trash2" />}
					/>
				</div>
			)}
		</ActionCellWrap>
	)
}

export default React.memo(ActionCell)
