import React from 'react'
import { isEmpty } from 'lodash'
import { Icon, Button } from 'rsuite'

export const ListWrap = React.memo(({ status, children, addListText, onClick, onClickSub, addListTextSub }) => {
	return (
		<div className="list-wrap" >
			<div className="list-status-wrap">
				<h4 className="list-status">{status}</h4>
				<div className="add-list-wrap">
					{addListText && <button className="add-list" onClick={onClick}>{addListText}</button>}
					{addListTextSub && <button className="add-list-sub" onClick={onClickSub}>{addListTextSub}</button>}
				</div>
			</div>
			<div className="list-top">
				{children}
			</div>
		</div>
	)
})

export const FormSection = React.memo(({ title, children, buttonText, onButtonClick }) => {
	return (
		<div className="add-formGrop">
			<div className="add-title-wrap">
				<div className="subtitle_0">{title}{isEmpty(buttonText) ? null : <Button className="form-section-button" size="xs" appearance="primary" onClick={onButtonClick}>{buttonText}</Button>}</div>
			</div>
			<div className="coupon-form-section">
				{children}
			</div>
		</div>
	)
})

export const SubGroupWrap = React.memo(({ title, children, errorMessage, style }) => {
	return (
		<div className="sub-group" style={style}>
			<div className="sub-group-title">
				<p>{title}</p>
				{errorMessage && <div className="title-error caption_01"><Icon icon={'warning'} style={{ color: '#f44336' }} /><span>{errorMessage}</span></div>}
			</div>
			{children}
	</div>
	)
})

