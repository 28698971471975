export const activityType = [
  {
    name: '一般活動',
    value: 'NORMAL'
  },
  {
    name: '首次乘車',
    value: 'FIRST_TIME'
  },
  {
    name: '機場接送',
    value: 'SHUTTLE'
  }
]