import React, { useState } from 'react'
import {
  getAgreement,
  blockAgreement,
  blockPricePlan,
  postCreateAgreement,
  postCreatePricePlan,
} from '../../../api'
import moment from 'moment-timezone'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

class AgreementManager extends React.Component {
  state = {
    agreements: [],
    start_time: '',
    end_time: '',
    description: '',
    planStatuses: ['服務費', '折扣'],
    status: '折扣',
    threshold: '0',
    plan_value: '',
    max_value: '',
    agreement_id: '',
    showThreshold: false,
    newAgreement: false,
    newPlan: false,
  }

  componentDidMount() {
    this.getAgreement()
  }

  getAgreement = async () => {
    const data = await getAgreement({
      company_id: this.props.company_id,
    })
    if (data.status !== 200) return
    // console.log('data' + JSON.stringify(data))
    this.setState({ agreements: data.data })
  }

  blockAgreement = async (id) => {
    const data = await blockAgreement({
      id: id,
      company_id: this.props.company_id,
    })
    // console.log('data' + JSON.stringify(data))
    if (data.status !== 200) return
    alert('封存合約成功')
    window.location.reload()
  }

  blockPricePlan = async (id) => {
    const data = await blockPricePlan({
      id,
    })
    console.log('data' + JSON.stringify(data))
    if (data.status !== 200) return
    alert('封存折扣成功')
    window.location.reload()
  }

  getPlanDescription = (price_plan) => {
    let str = ''
    str += price_plan.threshold == 0 ? '' : `滿 ${price_plan.threshold} 元, `
    str += price_plan.status == 'FEE' ? '服務費' : `折扣`
    str += '為 '
    if (price_plan.plan_value == 0) {
      str += '0'
      return str
    }
    str +=
      price_plan.plan_value < 1
        ? Math.floor(price_plan.plan_value * 100) + '%,'
        : price_plan.plan_value + ' 元 '
    str += '\n'
    if (price_plan.plan_value < 1) {
      str += '最多 '
      str +=
        price_plan.max_value == 0 ? '沒有上限' : `${price_plan.max_value} 元`
      str += '\n'
    }

    return str
  }

  inputHandler = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  createAgreement = () => {
    this.setState({
      start_time: '',
      end_time: '',
      description: '',
      newAgreement: true,
    })
  }

  postCreateAgreement = async () => {
    if (
      !this.state.description ||
      !this.state.start_time ||
      !this.state.end_time
    ) {
      alert('不能有值為空')
      return
    }
    const data = await postCreateAgreement({
      description: this.state.description,
      start_time: moment(this.state.start_time).unix(),
      end_time: moment(this.state.end_time).endOf('day').unix(),
      company_id: this.props.company_id,
    })
    if (data.status !== 200) return
    window.location.reload()
  }

  createPricePlan = (agreement_id) => {
    this.setState({
      showThreshold: this.displayThreshold(agreement_id, '折扣'),
      start_time: '',
      end_time: '',
      description: '',
      status: '折扣',
      threshold: '0',
      plan_value: '',
      max_value: '',
      agreement_id: agreement_id,
      newPlan: true,
    })
  }

  closeCreatePricePlan = () => {
    this.setState({
      newPlan: false,
    })
  }

  postCreatePricePlan = async () => {
    if (
      !this.state.status ||
      !this.state.description ||
      !this.state.start_time ||
      !this.state.end_time ||
      !this.state.threshold ||
      !this.state.plan_value ||
      !this.state.max_value
    ) {
      alert('不能有值為空')
      return
    }
    const data = await postCreatePricePlan({
      agreement_id: this.state.agreement_id,
      status: this.state.status == '服務費' ? 'FEE' : 'DISCOUNT',
      description: this.state.description,
      threshold: this.state.threshold,
      plan_value: this.state.plan_value,
      max_value: this.state.max_value,
      start_time: moment(this.state.start_time).startOf('month').unix(),
      end_time: moment(this.state.end_time).startOf('month').unix(),
    })
    if (data.status !== 200) return
    window.location.reload()
  }

  displayThreshold = (agreement_id, status) => {
    const agreement = this.state.agreements.find(
      (e) => e.id == agreement_id && e.company_price_plans
    )
    if (agreement && agreement.company_price_plans) {
      const price_plan = agreement.company_price_plans.find(
        (e) =>
          (e.status == 'FEE' ? '服務費' : '折扣') == status && e.threshold == 0
      )
      if (price_plan) {
        return true
      }
    }
    return false
  }

  render() {
    const viewThreshold = []
    if (this.state.showThreshold) {
      viewThreshold.push(
        <div className="form-group row">
          <div className="col-sm-10">
            <label className="input-group-text">門檻條件</label>
            <TextField
              type="text"
              name="threshold"
              className="form-control"
              value={this.state.threshold}
              onChange={this.inputHandler}
              placeholder="門檻條件"
            />
          </div>
        </div>
      )
    }
    return (
      <div>
        <div className="form-group">
          <button
            type="button"
            className="company-add-button"
            onClick={this.createAgreement}
          >
            新增合約
          </button>
        </div>

        <Popover
          id="newAgreement"
          open={this.state.newAgreement}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div style={{ padding: 10, textAlign: 'center' }}>
            <div className="modal-header">
              <h4 className="modal-title" id="newAgreementLabel">
                新增合約
              </h4>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-sm-10">
                  <label className="input-group-text">開始時間</label>
                  <TextField
                    type="text"
                    name="start_time"
                    className="form-control"
                    value={this.state.start_time}
                    onChange={this.inputHandler}
                    placeholder="YYYY-MM-DD"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-10">
                  <label className="input-group-text">結束時間</label>
                  <TextField
                    type="text"
                    name="end_time"
                    className="form-control"
                    value={this.state.end_time}
                    onChange={this.inputHandler}
                    placeholder="YYYY-MM-DD"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-10">
                  <label className="input-group-text">描述</label>
                  <TextField
                    type="text"
                    name="description"
                    className="form-control"
                    value={this.state.description}
                    onChange={this.inputHandler}
                    placeholder="描述"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                onClick={() => {
                  this.setState({
                    newAgreement: false,
                  })
                }}
              >
                取消
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.postCreateAgreement}
              >
                建立合約
              </button>
            </div>
          </div>
        </Popover>

        <Popover
          id="newPlan"
          open={this.state.newPlan}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div style={{ padding: 10, textAlign: 'center' }}>
            <div className="modal-header">
              <h4 className="modal-title" id="newPlanLabel">
                新增折扣
              </h4>
            </div>
            <div className="modal-body">
              <TextField
                select
                label="類別--"
                value={this.state.status}
                onChange={(event) => {
                  this.setState({
                    status: event.target.value,
                    showThreshold: this.displayThreshold(
                      this.state.agreement_id,
                      event.target.value
                    ),
                  })
                }}
                margin="normal"
              >
                {this.state.planStatuses.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <div className="form-group row">
                <div className="col-sm-10">
                  <label className="input-group-text">開始時間</label>
                  <TextField
                    type="text"
                    name="start_time"
                    className="form-control"
                    value={this.state.start_time}
                    onChange={this.inputHandler}
                    placeholder="YYYY-MM"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-10">
                  <label className="input-group-text">結束時間</label>
                  <TextField
                    type="text"
                    name="end_time"
                    className="form-control"
                    value={this.state.end_time}
                    onChange={this.inputHandler}
                    placeholder="YYYY-MM"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-10">
                  <label className="input-group-text">描述</label>
                  <TextField
                    type="text"
                    name="description"
                    className="form-control"
                    value={this.state.description}
                    onChange={this.inputHandler}
                    placeholder="描述"
                  />
                </div>
              </div>
              {viewThreshold}
              <div className="form-group row">
                <div className="col-sm-10">
                  <label className="input-group-text">
                    操作值(小於一為％，大於一為元)
                  </label>
                  <TextField
                    type="text"
                    name="plan_value"
                    className="form-control"
                    value={this.state.plan_value}
                    onChange={this.inputHandler}
                    placeholder="%|元"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-10">
                  <label className="input-group-text">最高值(元)</label>
                  <TextField
                    type="text"
                    name="max_value"
                    className="form-control"
                    value={this.state.max_value}
                    onChange={this.inputHandler}
                    placeholder="最高值"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                onClick={this.closeCreatePricePlan}
              >
                取消
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.postCreatePricePlan}
              >
                建立折扣
              </button>
            </div>
          </div>
        </Popover>

        {this.state.agreements.length > 0 &&
          this.state.agreements.map(function (agreement) {
            return (
              <div className="box" style={{ paddingTop: 30 }}>
                <div className="agreement-title-wrap">
                  <h5 className="box-title">合約： {agreement.description}</h5>
                </div>
                <div className="agreement-title-wrap">
                  <h5>
                    期間：
                    {moment(agreement.start_time * 1000).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )}{' '}
                    ~{' '}
                    {moment(agreement.end_time * 1000).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )}
                  </h5>
                </div>
                <div className="agreement-title-wrap">
                  <button
                    id={'agreement' + agreement.id}
                    className="company-white-button"
                    onClick={() => this.blockAgreement(agreement.id)}
                  >
                    封存合約
                  </button>
                  <button
                    type="submit"
                    className="company-white-button"
                    onClick={() => this.createPricePlan(agreement.id)}
                  >
                    {' '}
                    新增價錢策略
                  </button>
                </div>

                <div className="box-body table-responsive no-padding">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <td>狀態</td>
                        <td>簡述</td>
                        <td>描述</td>
                        <td>門檻</td>
                        <td>值</td>
                        <td>最高值</td>
                        <td>起始時間</td>
                        <td>結束時間</td>
                        <td>動作</td>
                      </tr>
                    </thead>
                    <tbody>
                      {agreement.company_price_plans.map(function (price_plan) {
                        return (
                          <tr key={price_plan.id}>
                            <td>
                              {price_plan.status === 'FEE' ? '服務費' : `折扣`}
                            </td>
                            <td style={{ width: '150px' }}>
                              {this.getPlanDescription(price_plan)}
                            </td>
                            <td style={{ width: '150px' }}>
                              {price_plan.description}
                            </td>
                            <td>{price_plan.threshold}</td>
                            <td>{price_plan.plan_value}</td>
                            <td>{price_plan.max_value}</td>
                            <td>
                              {moment(price_plan.start_time * 1000).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )}
                            </td>
                            <td>
                              {moment(price_plan.end_time * 1000).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )}
                            </td>
                            <td>
                              <button
                                id={
                                  'agreement' +
                                  agreement.id +
                                  'plan' +
                                  price_plan.id
                                }
                                className="company-white-button"
                                onClick={() =>
                                  this.blockPricePlan(price_plan.id)
                                }
                              >
                                封存
                              </button>
                            </td>
                          </tr>
                        )
                      }, this)}
                    </tbody>
                  </table>
                </div>
              </div>
            )
          }, this)}
      </div>
    )
  }
}

export default AgreementManager
