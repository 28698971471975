/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useContext, useCallback } from 'react'
import dayjs from 'dayjs'
import { DatePicker, Breadcrumb, Modal, Alert, Input, Icon } from 'rsuite'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import { isEmpty, isString, cloneDeep } from 'lodash'
import { ContextStore } from '../../reducers'
import { getCoordination, checkPointValid } from '../../utils/map'
import { generateRandomCode as generateCode } from '../../utils'
import { getEvent, postImage, postEventWithPin, deleteEvent } from '../../api'
import { MapCell } from '../../components/map'
import { AddListModel, FormGrop, FormGropWrap, AddListGrop, ListWrap, FormSection, SubGroupWrap } from '../../components/views'
import PopWarning from '../../components/popWarning'
import { TableController } from '../../components/table'
import { ActionCell, WhisperCell } from '../../components/table/cell'
import 'react-datepicker/dist/react-datepicker.css'

const initCurrentState = {
  id: null,
  title: '',
  desc: '',
  startDate: '',
  endDate: '',
  icon: '',
  cover: '',
  pins: [],
  titleError: '',
  descError: '',
  startDateError: '',
  endDateError: '',
  iconError: '',
  coverError: '',
}

const ScheduledPin = () => {
  const [user, setUser] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [isAddMode, setAddMode] = useState(false)
  const [mapEditType, setMapEditType] = useState('')
  const [pinEvents, setPinEvents] = useState([])
  const [warning, setWarning] = useState('')
  const [currentInfo, setCurrentInfo] = useState(initCurrentState)
  const { auth } = useContext(ContextStore)

  const fetchPinEvent = async () => {
    const response = await getEvent({ toPopulate: 'pin' })
    if (response.status !== 200) return Alert.error(response.data)
    const { data } = response
    return setPinEvents(data)
  }

  const toggleAddMode = () => {
    setAddMode(!isAddMode)
  }

  const handlePostArea = async () => {
    const { title, desc, startDate, endDate, icon, cover, pins } = currentInfo
    setCurrentInfo({
      ...currentInfo,
      titleError: !title ? '請填寫或選擇項目' : '',
      startDateError: !startDate ? '請填寫或選擇項目' : '',
      endDateError: !endDate ? '請填寫或選擇項目' : '',
      iconError: !icon ? '請填寫或選擇項目' : '',
      coverError: !cover ? '請填寫或選擇項目' : '',
    })
    if (!title || !startDate || !endDate || !icon || !cover) return Alert.error('欄位不能為空！', 3000)
    if (isEmpty(pins)) return Alert.error('請填入至少一個踩點！', 3000)

    const newPins = []
    let pointError = ''
    pins.forEach((pin) => {
      const { lat, lng, address } = pin
      if (!checkPointValid({ lat, lng })) {
        pointError = '請確認座標資訊，須為座標數字'
        return
      }
      if (isEmpty(address)) {
        pointError = '請確認座標資訊，輸入地址不為空'
        return
      }
      newPins.push({
        title,
        lat,
        lng,
        address,
        img_url: cover,
        icon_url: icon,
      })
    })
    if (!isEmpty(pointError)) return Alert.error(pointError, 3000)

    // call api
    const body = {
      name: title,
      description: desc,
      start_time: startDate,
      end_time: endDate,
      pins: newPins,
    }
    console.log('body', body)

    const response = await postEventWithPin(body)
    if (response.status !== 200) return setWarning('新增失敗，請稍候再嘗試')
    setWarning('新增成功！')
    return toggleAddMode()
  }
  console.log('currentInfo.pins', currentInfo.pins)
  const handleEditOrCopy = (data, action) => {
    const { id, name, description, start_time, end_time, pin } = data
    if (action === 'copy') Alert.success('已複製訊息', 3000)

    const copyPins = cloneDeep(pin).map((pin) => {
      const { x, y } = pin.latlng
      return {
        id: generateCode(4),
        address: pin.address,
        lat: x,
        lng: y,
      }
    })
    setCurrentInfo({
      id: action === 'copy' ? null : id,
      title: name || '',
      desc: description || '',
      startDate: start_time || '',
      endDate: end_time || '',
      icon: pin ? pin[0].icon_url : '',
      cover: pin ? pin[0].img_url : '',
      pins: pin ? copyPins : [],
      titleError: '',
      descError: '',
      startDateError: '',
      endDateError: '',
      iconError: '',
      coverError: '',
    })
  }

  const handleDelete = async (id) => {
    const response = await deleteEvent({ data: { eventId: id } })
    if (response.status !== 200) return setWarning('刪除失敗，請稍候再嘗試')
    return setWarning('刪除成功！')
  }

  const handleOnChange = (e, setStateKey) => {
    const value = e.target ? e.target.value : e
    setCurrentInfo({
      ...currentInfo,
      [setStateKey]: value,
    })
  }

  const handleDatePicker = (date, stateKey) => {
    setCurrentInfo({
      ...currentInfo,
      [stateKey]: dayjs(+date).unix(),
    })
  }

  const handleUploadIcon = async (file) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('file', file.blobFile)
    formData.append('doc_type', 'image')
    const data = await postImage(user.admin_id, formData)
    if (data.status !== 200) return
    setLoading(false)
    handleOnChange(data.data.image_url, 'icon')
  }

  const handleUploadCover = async (file) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('file', file.blobFile)
    formData.append('doc_type', 'image')
    const data = await postImage(user.admin_id, formData)
    if (data.status !== 200) return
    setLoading(false)
    handleOnChange(data.data.image_url, 'cover')
  }

  const handlePinState = (action, id, newData) => {
    switch (action) {
      case 'add':
        setCurrentInfo({
          ...currentInfo,
          pins: [...currentInfo.pins, newData],
        })
        break
      case 'delete':
        setCurrentInfo({
          ...currentInfo,
          pins: isString(id)
            ? currentInfo.pins.filter((pin) => pin.id !== id)
            : currentInfo.pins.map((pin) => {
                if (pin.id !== id) return pin
                pin.toDelete = true
                return pin
              }),
        })
        break
      default:
        break
    }
  }

  const handlePinChange = (id, type, value) => {
    const pins = [...currentInfo.pins]
    const newPins = pins.map((pin) => {
      if (pin.id !== id) return pin
      pin[type] = value
      return pin
    })
    setCurrentInfo({
      ...currentInfo,
      pins: newPins,
    })
  }

  const handleWarningConfirm = () => {
    setWarning('')
    fetchPinEvent()
  }

  const handleClose = useCallback(() => {
    setMapEditType('')
  }, [])

  const handleLocations = (rowData, dataKey) => {
    const pins = rowData[dataKey]
    return pins.map((pin) => getCoordination(pin.latlng))
  }

  const renderPinAds = (title, img, icon) => {
    return (
      <div className="pin-image-preview">
        <div className="db-marker-info-wrap">
          <div className="db-marker-info-close" />
          <img className="db-marker-img" src={img} alt="" />
          <div className="db-marker-text-wrap">
            <div className="db-marker-title">{title}</div>
            <div className="db-marker-text body_04">前往叫車</div>
          </div>
        </div>
        <div className="db-marker-wrap">
          <div className="db-marker">
            <img className="db-marker-logo" src={icon} alt="" />
          </div>
        </div>
      </div>
    )
  }

  // init
  useEffect(() => {
    if (auth && auth.user) setUser(auth.user)
    fetchPinEvent()
  }, [])

  // reset add mode
  useEffect(() => {
    if (isAddMode) return
    setCurrentInfo(initCurrentState)
  }, [isAddMode])

  return (
    <>
      <div style={{ width: '100%' }}>
        {!isEmpty(mapEditType) && <Modal size="lg" show={!isEmpty(mapEditType)} onHide={handleClose} />}
        {warning && <PopWarning warning={warning} textCenter confirm={handleWarningConfirm} />}
        {isAddMode ? (
          <AddListModel>
            <Breadcrumb>
              <Breadcrumb.Item onClick={toggleAddMode}>地圖踩點</Breadcrumb.Item>
              <Breadcrumb.Item active>新增</Breadcrumb.Item>
            </Breadcrumb>
            <AddListGrop title={currentInfo.id ? '檢視踩點' : '新增廣告踩點'}>
              {currentInfo.id && (
                <FormGrop title="ID" type="children">
                  <Input value={currentInfo.id} disabled />
                </FormGrop>
              )}
              <FormGrop
                title="活動標題*"
                placeholder="Title"
                type="input"
                value={currentInfo.title}
                handleOnChange={handleOnChange}
                setStateKey="title"
                errorMessage={currentInfo.titleError}
              />
              <FormGropWrap>
                <FormGrop title="開始時間" type="children" errorMessage={currentInfo.startDateError}>
                  <DatePicker
                    defaultValue={currentInfo.startDate ? new Date(currentInfo.startDate * 1000) : undefined}
                    onOk={(date) => handleDatePicker(date, 'startDate')}
                    placement="bottomStart"
                    appearance="subtle"
                    placeholder="選擇日期時間"
                    isoWeek
                    hideMinutes={(second) => second % 5 !== 0}
                    format="YYYY-MM-DD HH:mm"
                    ranges={[{ value: new Date(), closeOverlay: true }]}
                  />
                </FormGrop>
                <FormGrop title="結束時間" type="children" errorMessage={currentInfo.endDateError}>
                  <DatePicker
                    defaultValue={currentInfo.endDate ? new Date(currentInfo.endDate * 1000) : undefined}
                    onOk={(date) => handleDatePicker(date, 'endDate')}
                    placement="bottomStart"
                    placeholder="選擇日期時間"
                    appearance="subtle"
                    isoWeek
                    hideMinutes={(second) => second % 5 !== 0}
                    format="YYYY-MM-DD HH:mm"
                    ranges={[{ value: new Date(), closeOverlay: true }]}
                  />
                </FormGrop>
              </FormGropWrap>
              <div className="pin-image-setting">
                <FormGrop
                  title="廣告踩點 ─ 封面"
                  placeholder="輸入踩點封面圖網址"
                  type="input"
                  value={currentInfo.cover}
                  handleOnChange={handleOnChange}
                  setStateKey="cover"
                  errorMessage={currentInfo.coverError}
                />
                <FormGrop type="uploader" multiple={false} handleOnUpload={handleUploadCover} isLoading={isLoading} />
              </div>
              <div className="pin-image-setting">
                <FormGrop
                  title="廣告踩點 ─ 圖標"
                  placeholder="輸入踩點 Icon 圖片網址（請選擇 .png 格式，記得去背）"
                  type="input"
                  value={currentInfo.icon}
                  handleOnChange={handleOnChange}
                  setStateKey="icon"
                  errorMessage={currentInfo.iconError}
                />
                <FormGrop type="uploader" multiple={false} handleOnUpload={handleUploadIcon} isLoading={isLoading} />
              </div>
              {renderPinAds(currentInfo.title, currentInfo.cover, currentInfo.icon)}
              <FormSection
                title="設置踩點座標"
                buttonText="新增 Pin"
                onButtonClick={() => handlePinState('add', null, { id: generateCode(4), address: '', lat: '', lng: '' })}
              >
                {!isEmpty(currentInfo.pins) && <p className="pin-number-hint">{`目前新增 ${currentInfo.pins.length} 個的座標點`}</p>}
                {currentInfo.pins &&
                  currentInfo.pins.map(({ id, address, lat, lng }, index) => {
                    return (
                      <div key={`${id}_${index}`} className="pin-group">
                        <SubGroupWrap title={`踩點${index + 1}`}>
                          <FormGrop
                            placeholder="地址"
                            type="input"
                            value={address}
                            handleOnChange={(e) => handlePinChange(id, 'address', e.target.value)}
                          />
                          <FormGrop
                            placeholder="X 座標"
                            type="input"
                            value={lat}
                            handleOnChange={(e) => handlePinChange(id, 'lat', e.target.value)}
                          />
                          <FormGrop
                            placeholder="Y 座標"
                            type="input"
                            value={lng}
                            handleOnChange={(e) => handlePinChange(id, 'lng', e.target.value)}
                          />
                          {!currentInfo.id && <Icon icon="trash" size="lg" onClick={() => handlePinState('delete', id)} />}
                        </SubGroupWrap>
                      </div>
                    )
                  })}
              </FormSection>
            </AddListGrop>
            <div className="campaign-button-wrap">
              {!currentInfo.id && (
                <button type="button" className="campaign-confirm-button" onClick={handlePostArea}>
                  新增
                </button>
              )}
              <button type="button" className="campaign-cancel-button" onClick={toggleAddMode}>
                取消
              </button>
            </div>
          </AddListModel>
        ) : (
          <div>
            <ListWrap status="地圖踩點 LBS Push" addListText="+ 新增踩點" onClick={toggleAddMode} />
            <TableController data={pinEvents} topHeight={104} rowHeight={230}>
              <Column width={60} flexGrow={0} align="center">
                <HeaderCell>ID</HeaderCell>
                <Cell dataKey="id" />
              </Column>
              <Column minWidth={140} flexGrow={1}>
                <HeaderCell>踩點標題</HeaderCell>
                <WhisperCell dataKey="name" />
              </Column>
              <Column minWidth={80} flexGrow={1}>
                <HeaderCell>活動開始時間</HeaderCell>
                <Cell>{(rowData) => dayjs(rowData.start_time * 1000).format('YY-MM-DD HH:mm')}</Cell>
              </Column>
              <Column minWidth={80} flexGrow={1}>
                <HeaderCell>活動結束時間</HeaderCell>
                <Cell>{(rowData) => dayjs(rowData.end_time * 1000).format('YY-MM-DD HH:mm')}</Cell>
              </Column>
              <Column minWidth={300} flexGrow={2} align="center">
                <HeaderCell>踩點地圖</HeaderCell>
                <MapCell dataKey="pin" type="point" center="center" multi handleMapData={handleLocations} />
              </Column>
              <Column width={140} align="center">
                <HeaderCell>操作</HeaderCell>
                <ActionCell toggle={toggleAddMode} handleEdit={handleEditOrCopy} handleDelete={handleDelete} view />
              </Column>
            </TableController>
          </div>
        )}
      </div>
    </>
  )
}

export default ScheduledPin
