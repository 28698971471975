import { nameFilter, periodFilter} from './filters'
import { isEmpty } from 'lodash'

const filterHappyHours = (searchWord, inProgress, activities, showNum = 100) => {
  if (!inProgress && isEmpty(searchWord)) return activities.filter((item, index) => index < showNum)

  const activitiesByName = nameFilter(searchWord, activities)
  if (!inProgress) return activitiesByName.filter((item, index) => index < showNum)

  const activitiesByPeriod = periodFilter(activitiesByName)
  return activitiesByPeriod.filter((item, index) => index < showNum)
}

export { filterHappyHours }