import React from 'react'
import { Popover, Whisper } from 'rsuite'
import { isEmpty } from 'lodash'
import { Cell } from 'rsuite-table'
import { transLocation, getCoordination } from '../../utils/map'
import { default as Map } from './Map'

const MapCell = ({ rowData, dataKey, center, type, multi, handleMapData,...props }) => {
  let mapData = rowData[dataKey]
  if (multi) mapData = handleMapData(rowData, dataKey) // 多區域

  function getValue() {
    let value
    if (multi) {
      value = mapData
    } else {
      value = type === 'point' ? getCoordination(mapData) : transLocation(mapData)
    }
    return !isEmpty(value) ? JSON.stringify(value, null, 2) : ''
  }

  const output = getValue()
  const speaker = (
		<Popover>
			<div className="description-wrap">
				{output}
			</div>
		</Popover>
	)
  return (
		<Cell {...props}>
      {!isEmpty(mapData) && <>
        {type !== 'point' && (
          <Map
            id={`map-${dataKey}-${rowData.id || rowData.promo_id}`}
            center={getCoordination(rowData[center])}
            latlngs={multi ? mapData : transLocation(mapData)}
            padding={'90px'}
            multi={multi}
          />
        )}
        {type === 'point' && (
          <Map
            id={`map-${dataKey}-${rowData.id}`}
            point={multi ? mapData : getCoordination(mapData)}
            padding={'90px'}
            multi={multi}
          />
        )}
      </>}
			{!isEmpty(output) && <Whisper placement="autoHorizontal" speaker={speaker} width={200}><a>查看座標</a></Whisper>}
		</Cell>
	)
}

export default React.memo(MapCell)

