import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Alert, Breadcrumb, Button, ControlLabel, DatePicker, Form, FormControl, FormGroup, Icon, Loader, SelectPicker, TagPicker } from 'rsuite'
import { Link, useParams, useHistory } from 'react-router-dom'
import { debounce, remove } from 'lodash'
import dayjs from 'dayjs'
import { array, date, number, object, string } from 'yup'
import { FormContainer, Root, Title } from './styles'
import { getUserGroupV2, postUserGroup } from '../../api'
import { TEST_TRACK_GROUP, USER_PROPORTION } from './const'

const schema = object().shape({
  groupTitle: string().required('分眾名稱不能空白'),
  userGroupRefDisplay: string().required('讀取的名單不能空白'),
  excludeUserGroup: array().of(number()).nullable(),
  proportion: array().of(number()).nullable(),
  testGroup: string().nullable(),
  testWeek: date().required('追蹤分析的日期不能空白'),
})

const DEFAULT_FORM_VALUE = { groupTitle: '', userGroupRefDisplay: '', excludeUserGroup: null, proportion: null, testGroup: null, testWeek: null }

const ApplyUserGroupDAMA = () => {
  const history = useHistory()
  const { id } = useParams()
  const [userGroupRef, setUserGroupRef] = useState(null)
  const [userGroupExists, setUserGroupExists] = useState([])
  const [userGroupExistsCache, setUserGroupExistsCache] = useState([])
  const userGroupExistsRef = useRef([])
  const [formValue, setFormValue] = useState(DEFAULT_FORM_VALUE)
  const [isSearchExistUserGroup, setIsSearchExistUserGroup] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isInitializing, setIsInitializing] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsInitializing(true)

      try {
        const userGroupRefResponse = await getUserGroupV2({ id })

        if (userGroupRefResponse.status !== 200) {
          throw new Error(userGroupRefResponse.data.msg)
        }

        const ref = Array.isArray(userGroupRefResponse.data) && userGroupRefResponse.data.length ? userGroupRefResponse.data[0] : null

        if (ref) {
          const userGroupRefDisplay = `${ref.table}  ${ref?.id ? `(${ref.id}-${ref.title})` : ''}`
          setUserGroupRef(ref)
          setFormValue((prev) => {
            const next = { ...prev, userGroupRefDisplay }
            if (ref.settings?.distribution) next.proportion = ref.settings.distribution
            if (ref.settings?.testGroup) next.testGroup = ref.settings.testGroup
            if (ref.settings?.filter) next.excludeUserGroup = ref.settings.filter
            return next
          })
        }

        const idArray = ref.settings?.filter ? ref.settings.filter : []

        const userGroupExistsResponse = await getUserGroupV2({ limit: 10, offset: 0 })

        if (userGroupExistsResponse.status !== 200) {
          throw new Error(userGroupExistsResponse.data.msg)
        }

        if (Array.isArray(userGroupExistsResponse.data) && userGroupExistsResponse.data.length) {
          const userGroupExists = [...userGroupExistsResponse.data]
          // 判斷應用的排除名單有沒有在選項裡
          const idContained = idArray.filter((id) => userGroupExistsResponse.data.some((item) => item.id === id))
          const idNotContained = idArray.filter((id) => !idContained.includes(id))

          if (idNotContained.length) {
            const response = await getUserGroupV2({ id: idNotContained.join(',') })
            if (response.status !== 200) {
              throw new Error(response.data.msg)
            }
            if (Array.isArray(response.data)) {
              response.data.forEach((item) => userGroupExists.push(item))
            }
          }
          userGroupExistsRef.current = userGroupExists
          setUserGroupExists(userGroupExists)
          const cache = userGroupExists.filter((item) => idArray.includes(item.id))
          setUserGroupExistsCache(cache)
        }
      } catch (e) {
        return Alert.error(e.message)
      }
      setIsInitializing(false)
    }

    fetchData()
  }, [id])

  const handleSearchExistUserGroup = useCallback(async (searchStr = '') => {
    setIsSearchExistUserGroup(true)
    const response = await getUserGroupV2({ search: searchStr, limit: 10, offset: 0 })
    if (response.status !== 200) {
      return Alert.error(response.data.msg)
    }
    setUserGroupExists(response.data)
    return setIsSearchExistUserGroup(false)
  }, [])

  const handleRenderMenu = useCallback(
    (menu) => {
      if (isSearchExistUserGroup) {
        return (
          <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
            <Icon icon="spinner" spin />
            Loading...
          </p>
        )
      }
      return menu
    },
    [isSearchExistUserGroup],
  )

  const handleExistUserGroupExited = useCallback(() => {
    setUserGroupExists(userGroupExistsRef.current)
  }, [])

  const handleExistUserGroupSelect = useCallback((value, item) => {
    setUserGroupExistsCache((prev) => {
      remove(prev, (v) => v.id === value.id)
      prev.push(item)
      return [...prev]
    })
  }, [])

  const handleDisableDate = useCallback((date) => dayjs(date).day() !== 1, [])

  const handleSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true)
      await schema.validate(formValue)
      const { dataset, table } = userGroupRef
      const payload = {
        title: formValue.groupTitle,
        rules: [
          {
            type: 'USER_GROUP',
            parameters: { dataset, table },
          },
        ],
        testWeek: dayjs(formValue.testWeek).format('YYYY-MM-DD'),
      }

      if (formValue.excludeUserGroup) payload.filter = formValue.excludeUserGroup
      if (formValue.proportion) payload.distribution = formValue.proportion
      if (formValue.testGroup) payload.testGroup = formValue.testGroup

      const response = await postUserGroup(payload)

      if (response.status !== 200) throw new Error(response.data.msg)

      if (Array.isArray(response.data)) {
        response.data.forEach(({ title, user_group_id }) => {
          Alert.success(`新增 id 為 ${user_group_id} 的分眾名單「${title}」！`, 2000)
        })
      }

      return history.push('/dama/user_group')
    } catch (e) {
      Alert.error(e.message)
      setIsSubmitting(false)
    }
  }, [formValue, userGroupRef, history])

  return (
    <Root>
      <Breadcrumb>
        <Breadcrumb.Item componentClass={Link} to="/dama/user_group">
          DAMA+分眾名單管理
        </Breadcrumb.Item>
        <Breadcrumb.Item active>應用分眾名單</Breadcrumb.Item>
      </Breadcrumb>
      <Title>應用分眾名單</Title>
      <FormContainer>
        {isInitializing && <Loader backdrop content="loading..." vertical />}
        <Form formValue={formValue} onChange={setFormValue} onSubmit={handleSubmit}>
          <FormGroup>
            <ControlLabel>分眾名稱</ControlLabel>
            <FormControl name="groupTitle" disabled={isInitializing || isSubmitting} />
          </FormGroup>
          <FormGroup>
            <ControlLabel>讀取名單</ControlLabel>
            <FormControl name="userGroupRefDisplay" disabled />
          </FormGroup>
          <FormGroup>
            <ControlLabel>排除已建立的分眾名單</ControlLabel>
            <FormControl
              name="excludeUserGroup"
              accepter={TagPicker}
              placeholder="選擇欲排除之分眾名單名稱"
              data={userGroupExists}
              cacheData={userGroupExistsCache}
              labelKey="title"
              valueKey="id"
              onSearch={debounce(handleSearchExistUserGroup, 300)}
              onExited={handleExistUserGroupExited}
              onSelect={handleExistUserGroupSelect}
              renderMenu={handleRenderMenu}
              disabled={isInitializing || isSubmitting}
              searchable
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>拆分多組分眾</ControlLabel>
            <FormControl
              name="proportion"
              accepter={SelectPicker}
              data={USER_PROPORTION}
              labelKey="name"
              searchable={false}
              placeholder="選擇拆分比例"
              disabled={isInitializing || isSubmitting}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>追蹤參數設定</ControlLabel>
            <FormControl
              name="testGroup"
              accepter={SelectPicker}
              data={TEST_TRACK_GROUP}
              labelKey="name"
              searchable={false}
              placeholder="選擇追蹤參數"
              disabled={isInitializing || isSubmitting}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>追蹤分析的日期</ControlLabel>
            <FormControl
              name="testWeek"
              accepter={DatePicker}
              format="YYYY-MM-DD"
              ranges={[]}
              appearance="default"
              cleanable={false}
              disabledDate={handleDisableDate} // 只能填入星期一作為分析日
              placeholder="Test Week 分析日"
              disabled={isInitializing || isSubmitting}
              placement="autoVerticalStart"
              oneTap
            />
          </FormGroup>
          <FormGroup>
            <Button type="submit" appearance="primary" loading={isSubmitting} disabled={isInitializing || isSubmitting}>
              送出
            </Button>
          </FormGroup>
        </Form>
      </FormContainer>
    </Root>
  )
}

export default ApplyUserGroupDAMA
