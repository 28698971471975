import React from 'react'
import { Cell } from 'rsuite-table'
import { Toggle } from 'rsuite';

export const ToggleCell = ({ rowData, dataKey, ...props }) => {
	return (
		<Cell {...props}>
			<Toggle size="md" checked={Boolean(rowData[dataKey])} />
		</Cell>
	)
}

export default React.memo(ToggleCell)