import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal, Button } from 'rsuite'

const MODEL_DATA = ['a', 'b', 'c']

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
`
const ModelItem = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
  border: 1px solid rgba(122, 146, 173, 0.4);
  cursor: pointer;
  transition: 0.3s;
  ${(props) =>
    props.$model === 'b' &&
    `
    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: rgba(122, 146, 173, 0.4);
      left: 50%;
      transform: translateX(-50%);
    }
  `}
  ${(props) =>
    props.$model === 'c' &&
    `
    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: rgba(122, 146, 173, 0.4);
      left: 33.333333333333336%;
    }
    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: rgba(122, 146, 173, 0.4);
      left: 66.66666666666667%;
    }
  `}
  &:active {
    transform: scale(0.9);
  }
  & span {
    color: #7a92ad;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    font-weight: 700;
    pointer-events: none;
  }
`
const Image = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  bottom: 10px;
`

const FlexMessageModel = React.memo(({ displayModel, setActiveModel, setModelDisplay }) => {
  const [selectedModel, setTheSelectedModel] = useState('')

  const handleConfirm = () => {
    setActiveModel(selectedModel)
    setModelDisplay(false)
  }
  return (
    <Modal show={displayModel} onHide={() => setModelDisplay(false)}>
      <Modal.Header>
        <Modal.Title>選擇版型</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Wrapper>
          {MODEL_DATA.map((item) => (
            <ModelItem $model={item} key={item} onClick={() => setTheSelectedModel(item)}>
              <span>{`版型${item}`}</span>
              {selectedModel === item && <Image src="https://static.taxigo-cdn.com/icons/general/s-checkbox-checked.svg" alt="" />}
            </ModelItem>
          ))}
        </Wrapper>
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" onClick={handleConfirm}>
          確認
        </Button>
      </Modal.Footer>
    </Modal>
  )
})

export default FlexMessageModel
