import React from 'react'
import { Breadcrumb } from 'rsuite'
import MGMForm from './mgm_form'
import { Top, Heading, Wrapper } from './styles'

const CreateMGMCampaign = () => {
  return (
    <Wrapper>
      <Top>
        <Breadcrumb>
          <Breadcrumb.Item href="/mgm">MGM活動後台</Breadcrumb.Item>
          <Breadcrumb.Item aria-current="page" active>
            建立活動
          </Breadcrumb.Item>
        </Breadcrumb>
        <Heading>活動內容</Heading>
      </Top>
      <MGMForm />
    </Wrapper>
  )
}

export default CreateMGMCampaign
