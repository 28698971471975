import dayjs from 'dayjs'
import { toNumber } from 'lodash'

const getQuarter = (equipped_q_time) => {
  if (!equipped_q_time) return ''

  let quarter = ''
  let [currentYear, currentMonth] = dayjs(equipped_q_time*1000).format('YYYY-MM').split('-').map(toNumber)
  switch (currentMonth) {
    case 3:
      quarter = 'Q2'
      break
    case 6:
      quarter = 'Q3'
      break
    case 9:
      quarter = 'Q4'
      break
    case 12:
      currentYear += 1
      quarter = 'Q1'
      break
    default:
      break
  }
  return `${currentYear}-${quarter}`
}

export default getQuarter