import React, { useState, useEffect, useContext } from 'react'
import { ContextStore } from '../../../reducers'
import AddListModel from '../../../components/views/AddListModel'
import FormGrop from '../../../components/views/FormGrop'
import AddListGrop from '../../../components/views/AddListGrop'
import PopWarning from '../../../components/popWarning'
import TableController from '../../../components/table/Table'
import ListWrap from '../../../components/views/ListWrap'
import RefundModal from '../../../components/transactions/RefundModal'

import { processPayment, refundPayment, getTransactions } from '../../../api'

import { Breadcrumb, Modal, Alert, Input, Dropdown } from 'rsuite'
import { Column, HeaderCell, Cell } from 'rsuite-table'


// export const getTransactions = (uid) => API.get(`/admin/payment?uid=${uid}`)
// export const processPayment = (body) => API.post(`/admin/payment`, body)
// export const refundPayment = (body) => API.post(`/admin/payment/refund`, body)

const Transactions = () => {
	const { auth } = useContext(ContextStore)
	const [warning, setWarning] = useState('')
	const [processRefundWarning, setProcessRefundWarning] = useState('')
	const [processPaymentWarning, setProcessPaymentWarning] = useState('')
	const [uid, setUID] = useState('')
	const [driver_id, setDriverID] = useState(0)
	const [amount, setAmount] = useState(0)
	const [mid, setMid] = useState(0)
	const [merchandise, setMerchandise] = useState('')
	const [paymentMethod, setPaymentMethod] = useState('creditcard')
	const [isAddMode, setAddMode] = useState(false)
	const [showRefundModal, setShowRefundModal] = useState(false)
	const [transactions, setTransactions] = useState([])
	const [transaction_id, setTransactionID] = useState('')
	const [order_id, setOrderID] = useState('')
	const [transactionToBeRefunded, setTransactionToBeRefunded] = useState(undefined)
	const [amountToBeRefunded, setAmountToBeRefunded] = useState(0)

	const merchandiseType = [
		{ type: '', title: '無'},
		{ type: 'trip', title: '測試中 - 行程'},
		{ type: 'pass', title: '測試中 - 日日搭'},
		{ type: 'cancel_fee', title: '測試中 - 行程取消費用'},
		{ type: 'veg_box', title: '安心快送' },
		{ type: 'preorder', title: '安心快送-預購' },
		{ type: 'delivery', title: '餐廳外送'}
	]

	const paymentMethodType = [
		{ type: '', title: '無'},
		{ type: 'linepay', title: 'LINE Pay'},
		{ type: 'creditcard', title: '信用卡'}
	]

	const resetInput = async() => {
		setMerchandise('trip')
		setPaymentMethod('creditcard')
		setAmount(0)
		setMid(0)
		setAmountToBeRefunded(0)
		setTransactionToBeRefunded(undefined)
		setTransactionID('')
		setOrderID('')
	}

	const removeUndefinedValue = (updateValues) => {
		Object.entries(updateValues).map(([key, value]) => {
			if (value === undefined) { delete updateValues[key] }
		})

		return updateValues
	}

	const handleGetTransactions = async () => {
		const params = {
			uid: uid ? uid : undefined,
			mid: mid ? mid : undefined,
			type: merchandise? merchandise : undefined,
			transaction_id: transaction_id ? transaction_id : undefined,
			order_id: order_id ? order_id : undefined,
		}

		console.log(params)
		const response = await getTransactions(removeUndefinedValue(params))
		console.log('response', response)
		if (response.status !== 200) return setWarning('網路不穩，請稍後再試')
		setWarning('')
		setTransactions(response.data)
	}

	const showProcessPaymentWarning = async () => {
		if (!uid) {
			setWarning('請輸入uid')
			return
		}
		if (!amount) {
			setWarning('請輸入金額')
			return
		}
		if (!mid) {
			setWarning('請輸入商品編號')
			return
		}
		if (!merchandise) {
			setWarning('請輸入商品種類')
			return
		}
		setProcessPaymentWarning(`即將進行扣款\nuid ${uid}\nmid ${mid}\namount ${amount}\ntype ${merchandise}\npayment_method ${paymentMethod}\n`)
	}

	const handleProcessPayment = async () => {
		setProcessPaymentWarning('')
		const result = await processPayment({
			uid, mid, type: merchandise, amount, payment_method: paymentMethod, driver_id
		})
		console.log('result', result)
		if (result.status !== 200) {
			setWarning(`扣款失敗`)
		} else {
			setWarning(`扣款成功`)
			resetInput()
		}
	}

  	const toggleAddMode = () => {
	  	console.log('toggleAddMode isAddMode', isAddMode)
		setAddMode(!isAddMode)
	}

	const refund = (rowData) => {
		setTransactionToBeRefunded(rowData)
		setShowRefundModal(true)
		console.log('refund', rowData)
	}

	const handleRefundPayment = async () => {
		setProcessRefundWarning('')
		console.log('handleRefundPayment info', transactionToBeRefunded)
		const result = await refundPayment({
			transaction_id: transactionToBeRefunded.transaction_id,
			order_id: transactionToBeRefunded.order_id,
			amount: amountToBeRefunded,
			type: transactionToBeRefunded.type,
			mid: transactionToBeRefunded.mid
		})
		console.log('handleRefundPayment result', result)
		if (result.status !== 200) {
			setWarning(`退款失敗 - ${(result && result.data) ? result.data.message || result.data.msg : ''}`)
		} else {
			setWarning(`退款成功`)
			resetInput()
		}


	}
	const showProcessRefundWarning = async (amount_to_be_refunded) => {
		setShowRefundModal(false)
		if (!transactionToBeRefunded) {
			setWarning('異常錯誤')
			return
		}
		if (amount_to_be_refunded > transactionToBeRefunded.amount) {
			setWarning('金額超過原收款金額')
			return
		}
		setAmountToBeRefunded(amount_to_be_refunded)
		setProcessRefundWarning(`即將進行退款 ${amount_to_be_refunded}?`)
	}


  	return (<>
  	    {warning && <PopWarning warning={warning} textCenter={true} confirm={() => setWarning('')} />}
  	    {processPaymentWarning && <PopWarning warning={processPaymentWarning} textCenter={true} confirm={handleProcessPayment} />}
  	    {processRefundWarning && <PopWarning warning={processRefundWarning} textCenter={true} confirm={handleRefundPayment} cancel={() => setShowRefundModal(true)} isCancel={true}/>}
		<RefundModal currentStatus={showRefundModal}  toggleButtonModel={() => setShowRefundModal(false)} transactionData={transactionToBeRefunded} refundSubmit={showProcessRefundWarning} />
		{isAddMode ? (
				<AddListModel>
					<Breadcrumb>
						<Breadcrumb.Item onClick={toggleAddMode}>付款資訊</Breadcrumb.Item>
						<Breadcrumb.Item active>新增付款</Breadcrumb.Item>
					</Breadcrumb>
					<AddListGrop title='新增付款'>
					<FormGrop title={'User ID'} placeholder={'uid'} type={'input'} value={uid} handleOnChange={(e) => setUID(e.target.value)} errorMessage={warning} />
					<FormGrop title={'Driver ID'} placeholder={'行程付款請填寫Driver ID，其他品項可留白'} type={'input'} value={driver_id} handleOnChange={(e) => setDriverID(e.target.value)} errorMessage={warning} />
					<FormGrop title={'商品種類'} type={'children'}>
						<Dropdown title={merchandiseType.filter(option => option.type === merchandise)[0].title} onSelect={(type) => setMerchandise(type)}>
							{merchandiseType.map(option => { return <Dropdown.Item key= {option.type} eventKey={option.type}>{option.title}</Dropdown.Item> })}
						</Dropdown>
					</FormGrop>
					<FormGrop title={'商品編號'} placeholder={'商品編號'} type={'input'} value={mid} handleOnChange={(e) => setMid(e.target.value)} errorMessage={warning} />
					<FormGrop title={'付款方式'} type={'children'}>
						<Dropdown title={paymentMethodType.filter(option => option.type === paymentMethod)[0].title} onSelect={(method) => setPaymentMethod(method)}>
							{paymentMethodType.map(option => { return <Dropdown.Item key= {option.type} eventKey={option.type}>{option.title}</Dropdown.Item> })}
						</Dropdown>
					</FormGrop>
					<FormGrop title={'金額'} placeholder={'amount'} type={'input'} value={amount} handleOnChange={(e) => setAmount(e.target.value)} errorMessage={warning} />
					</AddListGrop>
					<div className="campaign-button-wrap">
						<button className="campaign-confirm-button" disabled>發送補刷連結</button>
						<button className="campaign-cancel-button" onClick={showProcessPaymentWarning}>直接進行扣款</button>
						<button className="campaign-cancel-button" onClick={toggleAddMode}>取消</button>
					</div>
				</AddListModel>
			) :
		(<AddListModel>
			<Breadcrumb>
				<Breadcrumb.Item onClick={toggleAddMode}>付款資訊</Breadcrumb.Item>
				<Breadcrumb.Item active>顯示付款資訊</Breadcrumb.Item>
			</Breadcrumb>
			<AddListGrop title='付款資訊'>
			<FormGrop title={'uid'} placeholder={'User ID'} type={'input'} value={uid} handleOnChange={(e) => setUID(e.target.value)} errorMessage={warning} />
			<FormGrop title={'transaction_id'} placeholder={'User ID'} type={'input'} value={transaction_id} handleOnChange={(e) => setTransactionID(e.target.value)} errorMessage={warning} />
			<FormGrop title={'order_id'} placeholder={'User ID'} type={'input'} value={order_id} handleOnChange={(e) => setOrderID(e.target.value)} errorMessage={warning} />
			<FormGrop title={'mid'} placeholder={'Merchandise ID'} type={'input'} value={mid} handleOnChange={(e) => setMid(e.target.value)} errorMessage={warning} />
			<FormGrop title={'商品種類'} type={'children'}>
				<Dropdown title={merchandiseType.filter(option => option.type === merchandise)[0].title} onSelect={(type) => setMerchandise(type)}>
					{merchandiseType.map(option => { return <Dropdown.Item key= {option.type} eventKey={option.type}>{option.title}</Dropdown.Item> })}
				</Dropdown>
			</FormGrop>
				<div className="campaign-button-wrap">
					<button className="campaign-confirm-button" onClick={handleGetTransactions}>{'查詢'}</button>
				</div>
				<ListWrap  addListText={'進行取款'} onClick={toggleAddMode}></ListWrap>
				<TableController data={transactions} rowHeight={100}>
					<Column minWidth={300} flexGrow={1}>
						<HeaderCell>訂單編號</HeaderCell>
						<Cell dataKey="order_id" />
					</Column>
					<Column minWidth={140} flexGrow={1}>
						<HeaderCell>付款紀錄</HeaderCell>
						<Cell dataKey="transaction_id" />
					</Column>
					<Column width={30} flexGrow={1}>
						<HeaderCell>狀態</HeaderCell>
						<Cell dataKey="status" />
					</Column>
					<Column width={30} flexGrow={1}>
						<HeaderCell>付款方式</HeaderCell>
						<Cell dataKey="payment_method" />
					</Column>
					<Column width={30} flexGrow={1}>
						<HeaderCell>商品種類</HeaderCell>
						<Cell dataKey="type" />
					</Column>
					<Column width={30} flexGrow={1}>
						<HeaderCell>商品編號</HeaderCell>
						<Cell dataKey="mid" />
					</Column>
					<Column width={30} flexGrow={1}>
						<HeaderCell>金額</HeaderCell>
						<Cell dataKey="amount"/>
					</Column>
					<Column width={30} flexGrow={1}>
						<HeaderCell>Action</HeaderCell>
						<Cell>
							{rowData => {
								if (['INPROGRESS', 'COMPLETED'].indexOf(rowData.status) !== -1) {
									return (
										<button className="campaign-confirm-button" onClick={() => refund(rowData)}>部分退刷</button>
									)
								}
							}}
						</Cell>
					</Column>
				</TableController>
				</AddListGrop>
				</AddListModel>)
			}
  	</>)
}

export default Transactions
