const generateCode = (num) => {
  const list = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789"
  let code = ""
  for(let i = 0; i < num; i++) {
    const word = Math.floor(Math.random() * list.length)
    code += list.charAt(word)
  }
  return code
}

export { generateCode }