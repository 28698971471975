import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Alert, Button, ButtonGroup, Checkbox, Icon, Input, InputGroup, Popover, Tag, TagGroup, Toggle, Whisper } from 'rsuite'
import dayjs from 'dayjs'
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'
import styled from 'styled-components'
import { cloneDeep, remove } from 'lodash'
import { getUserGroupV2 } from '../../api'
import { FREQUENCY_BY_DAY_LABEL, USER_GROUP_TABS } from '../../pages/dama/const'
import { isNumeric } from '../../pages/dama/helper'

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;

  .head-actions {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 24px;

    & .search-container {
      width: 240px;
    }

    & .filter-container {
      width: 120px;
    }
  }

  .bottom-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

const PER_PAGE_LIMIT = 10

const UserGroupPickTable = ({ pickedUserGroupById = [], onChange, isDisabled = false }) => {
  const [isInitializing, setIsInitializing] = useState(false)
  const [data, setData] = useState([])
  const [tagsData, setTagsData] = useState([])

  const offsetRef = useRef(0)
  const searchTextRef = useRef(null)
  const tabRef = useRef(USER_GROUP_TABS.ALL)

  const [isPrevPageDisabled, setIsPrevPageDisabled] = useState(offsetRef.current === 0)

  const calcOffset = useCallback((unit) => unit * PER_PAGE_LIMIT, [])

  const getParams = useCallback((custom) => {
    const params = { limit: PER_PAGE_LIMIT, offset: offsetRef.current, tab: tabRef.current, ...custom }

    if (isNumeric(searchTextRef.current)) {
      params.id = searchTextRef.current
    } else if (typeof searchTextRef.current === 'string') {
      params.search = searchTextRef.current
    } else {
      searchTextRef.current = null
    }

    offsetRef.current = params.offset
    tabRef.current = params.tab

    setIsPrevPageDisabled(offsetRef.current === 0)

    return params
  }, [])

  const fetchData = useCallback(async (params) => {
    try {
      setIsInitializing(true)
      const response = await getUserGroupV2(params)
      if (response.status !== 200) {
        throw new Error(response.data.msg)
      }
      const ref = response.data.map((item) => {
        return {
          ...item,
          name: item.title,
          member_count: item.count,
          create_time: dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss'),
        }
      })

      setData(ref)
    } catch (e) {
      return Alert.error(e.message)
    }
    setIsInitializing(false)
  }, [])

  useEffect(() => {
    fetchData(getParams())
  }, [fetchData, getParams])

  useEffect(() => {
    const fetchTags = async (id) => {
      try {
        const response = await getUserGroupV2({ id })
        if (response.status !== 200) {
          throw new Error(response.data.msg)
        }
        const ref = response.data.map((item) => {
          return {
            ...item,
            name: item.title,
            member_count: item.count,
            create_time: dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss'),
          }
        })
        setTagsData(ref)
      } catch (e) {
        return Alert.error(e.message)
      }
    }
    if (pickedUserGroupById.length) {
      fetchTags(pickedUserGroupById.join(','))
    } else {
      setTagsData([])
    }
  }, [pickedUserGroupById])

  const handleSelect = useCallback(
    (id, checked) => {
      const clone = cloneDeep(pickedUserGroupById)
      if (checked) {
        clone.push(id)
      } else {
        remove(clone, (item) => item === id)
      }
      onChange(clone)
    },
    [onChange, pickedUserGroupById],
  )

  const renderCheckboxCell = useCallback(
    (rowData) => {
      const isCheck = pickedUserGroupById.includes(rowData.id)
      return <Checkbox value={rowData.id} onChange={handleSelect} checked={isCheck} disabled={isDisabled} />
    },
    [handleSelect, pickedUserGroupById, isDisabled],
  )

  const renderUserGroupName = useCallback((rowData) => {
    const color = rowData.end_time && dayjs.unix(rowData.end_time).isAfter(new Date()) ? 'green' : null
    return (
      <Whisper placement="top" speaker={<Popover>{rowData.name}</Popover>}>
        <div>
          {!!rowData.auto_update && (
            <Tag style={{ marginRight: '4px' }} color={color}>
              範本
            </Tag>
          )}
          {rowData.name}
        </div>
      </Whisper>
    )
  }, [])

  const renderLastAutoUpdateTime = useCallback((rowData) => {
    return rowData.updated_at ? dayjs(rowData.updated_at).format('YYYY-MM-DD HH:mm') : '-'
  }, [])

  const renderFrequencyByDay = useCallback((rowData) => {
    if (rowData.frequency_by_day) {
      return FREQUENCY_BY_DAY_LABEL[rowData.frequency_by_day]
    }
    return '-'
  }, [])

  const renderUpdateEndTime = useCallback((rowData) => {
    return rowData.end_time ? dayjs.unix(rowData.end_time).format('YYYY-MM-DD') : '-'
  }, [])

  const handlePageChange = useCallback(
    (num) => () => {
      const nextOffset = offsetRef.current + num >= 0 ? offsetRef.current + num : offsetRef.current
      if (nextOffset !== offsetRef.current) {
        fetchData(getParams({ offset: nextOffset }))
      }
    },
    [fetchData, getParams],
  )

  const handleSearch = useCallback(() => {
    fetchData(getParams({ offset: calcOffset(0) }))
  }, [fetchData, getParams, calcOffset])

  const handleFilterToggle = useCallback(
    (toggle) => {
      fetchData(getParams({ tab: toggle ? USER_GROUP_TABS.AUTO_UPDATE : USER_GROUP_TABS.ALL, offset: calcOffset(0) }))
    },
    [fetchData, getParams, calcOffset],
  )

  const handleSearchTextChange = useCallback((val) => {
    searchTextRef.current = val.length ? val : null
  }, [])

  const renderTags = useCallback(() => {
    return tagsData.map((item) => {
      return (
        <Tag key={item.id} onClose={() => handleSelect(item.id, false)} closable={!isDisabled}>
          {`ID${item.id}_${item.name}`}
        </Tag>
      )
    })
  }, [tagsData, handleSelect, isDisabled])

  return (
    <Root>
      <div className="head-actions ">
        <InputGroup className="search-container">
          <Input onChange={handleSearchTextChange} onPressEnter={handleSearch} disabled={isDisabled} />
          <InputGroup.Button onClick={handleSearch} disabled={isDisabled}>
            <Icon icon="search" />
          </InputGroup.Button>
        </InputGroup>
        <Toggle size="lg" checkedChildren="範本" unCheckedChildren="全部" onChange={handleFilterToggle} disabled={isDisabled} />
      </div>
      <TagGroup>{renderTags()}</TagGroup>
      <Table height={300} data={data} loading={isInitializing} bordered virtualized>
        <Column width={50} align="center" fixed>
          <HeaderCell />
          <Cell style={{ display: 'flex', alignItems: 'center', padding: 0 }}>{renderCheckboxCell}</Cell>
        </Column>
        <Column width={80} align="center" fixed>
          <HeaderCell>ID</HeaderCell>
          <Cell dataKey="id" />
        </Column>
        <Column minWidth={120} flexGrow={1} align="center" fixed>
          <HeaderCell>名稱</HeaderCell>
          <Cell dataKey="name">{renderUserGroupName}</Cell>
        </Column>
        <Column align="center" width={80}>
          <HeaderCell>Label(分眾拆分)</HeaderCell>
          <Cell dataKey="label" />
        </Column>
        <Column align="center" width={80}>
          <HeaderCell>名單人數</HeaderCell>
          <Cell dataKey="member_count" />
        </Column>
        <Column width={120} flexGrow={1} align="center">
          <HeaderCell>最後自動更新時間</HeaderCell>
          <Cell dataKey="updated_at">{renderLastAutoUpdateTime}</Cell>
        </Column>
        <Column align="center">
          <HeaderCell>自動更新週期</HeaderCell>
          <Cell dataKey="frequency_by_day">{renderFrequencyByDay}</Cell>
        </Column>
        <Column width={120} flexGrow={1} align="center">
          <HeaderCell>更新直到</HeaderCell>
          <Cell dataKey="end_time">{renderUpdateEndTime}</Cell>
        </Column>
        <Column width={120} flexGrow={1}>
          <HeaderCell>創建時間</HeaderCell>
          <Cell dataKey="create_time" />
        </Column>
      </Table>
      <div className="bottom-actions">
        <ButtonGroup>
          <Button appearance="ghost" disabled={isPrevPageDisabled} onClick={handlePageChange(calcOffset(-1))}>
            上一頁
          </Button>
          <Button appearance="ghost" disabled={!data.length || data.length < PER_PAGE_LIMIT} onClick={handlePageChange(calcOffset(1))}>
            下一頁
          </Button>
        </ButtonGroup>
      </div>
    </Root>
  )
}

export default UserGroupPickTable
