import styled from 'styled-components'

const Label = styled.div`
  margin-top: 6px;
  font-weight: bold;
  min-width: 56px;
`

const Control = styled.div`
  flex-grow: 1;
`

export const FormGroup = styled.div`
  padding: 0 12px;
  margin-top: 20px;
  display: flex;
  column-gap: 20px;
`

export const UncheckedSpan = styled.span`
  color: #333;
`

export const TextWrapper = styled.div`
  div {
    line-height: 40px;
  }
`

export const Code = styled.code`
  background-color: #eee;
  border-radius: 3px;
  font-family: courier, monospace;
  padding: 3px 6px;
  margin: 0 6px;
  overflow-wrap: anywhere;
`

FormGroup.Label = Label
FormGroup.Control = Control
