import styled from 'styled-components'

export const PanelItem = styled.div`
  padding: 6px;
  display: flex;
  justify-content: center;
  row-gap: 6px;
`

export const TextBox = styled.div`
  flex-direction: column;
  flex-basis: 50%;
  text-align: center;
  row-gap: 6px;
  display: flex;
`

export const TextRow = styled.div`
  display: flex;
  margin-bottom: 16px;
`

export const Label = styled.div`
  flex-grow: 1;
`

export const Text = styled.div`
  color: #6c6c6c;
  max-width: 60%;
`

export const TextWrapper = styled.div`
  & > div {
    display: inline-block;
  }

  & > div:first-child {
    width: 120px;
  }

  & > div:nth-child(2) {
    color: #575757;
  }
`

export const Title = styled.div`
  color: #3498ff;
  font-size: 16px;
  font-weight: 500;
  margin-top: 16px;
`

export const Table = styled.table`
  td {
    padding: 13px 10px;
  }
`
