export const statusType = [
  {
    name: '成功',
    value: 'SUCCESS'
  },
  {
    name: '發生錯誤',
    value: 'ERROR'
  },
  {
    name: '執行中',
    value: 'RUNNING'
  },
  {
    name: '終止',
    value: 'TERMINATED'
  },
  {
    name: '已排程',
    value: 'SCHEDULED'
  },
]

export const scheduleType = [
  {
    name: '每日',
    value: 'daily'
  },
  {
    name: '每週',
    value: 'weekly'
  },
  {
    name: '每月',
    value: 'monthly'
  },
  {
    name: 'NONE',
    value: 'none'
  }
]