import React from 'react'
import { Breadcrumb, Content, Wrapper } from './styles'
import EventEditor from './EventEditor'

const AddEvent = React.memo(() => {
  return (
    <Wrapper>
      <Content>
        <Breadcrumb>
          <span>DaMaPlus /</span>
          <span>Add Campaign</span>
        </Breadcrumb>
        <EventEditor isAdd />
      </Content>
    </Wrapper>
  )
})

export default AddEvent
