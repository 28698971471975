import React, { useState } from 'react'

const NewPopWarning = ({
  isShow,
  warningTitle,
  warning,
  confirm,
  isCancel,
  cancel,
  textCenter,
  cancelText,
  confirmText,
  innerHTML,
  isClose,
  closePop,
  bottomImg,
  topImg,
  popImg,
  zIndex,
  url,
  createPortal,
  closeModal,
  textStyle,
  description,
  paddingStyle,
  isBlackButton,
  isWhiteButton,
  children,
  width,
  contentScroll,
}) => {
  const [isOpen, setIsOpen] = useState(true)

  const handleAnimationClassName = () => {
    if (createPortal) {
      return isOpen ? 'keyframes-show' : 'keyframes-disappear'
    }
    return isShow ? 'show' : 'visibility-hidden'
  }
  const handleConfirm = () => {
    if (createPortal) {
      setIsOpen(false)
      closeModal()
    }
    confirm && confirm()
  }
  const handleCancel = () => {
    if (createPortal) {
      setIsOpen(false)
      closeModal()
    }
    cancel && cancel()
  }
  const handleBackgroundClassName = () => {
    if (createPortal) {
      return isOpen ? 'show' : ''
    }
    return isShow ? 'show' : ''
  }

  return (
    <>
      <div
        className={`popWarning-wrap pop-up-animation ${handleAnimationClassName()}`}
        style={{ zIndex: zIndex && zIndex + 1, width: width && width }}
      >
        {isClose && (
          <button type="button" className="popWarning-close-area" onClick={closePop}>
            <img src="../../../static/img/index/m-close.svg" alt="" />
          </button>
        )}
        {topImg && <div className="popWarning-top-img" dangerouslySetInnerHTML={{ __html: topImg }} />}
        <div className="popWarning-text-wrap" style={paddingStyle ? { padding: paddingStyle } : {}}>
          {popImg && <div className="popWarning-header-img" dangerouslySetInnerHTML={{ __html: popImg }} />}
          {warningTitle && <h5 className="popWarning-header-title">{warningTitle}</h5>}
          {children && <>{children}</>}
          {innerHTML ? (
            <div
              className={`${textStyle || 'body_01'} popWarning-title ${textCenter && ' text-center'}`}
              dangerouslySetInnerHTML={{ __html: warning }}
            />
          ) : (
            <div className={`${textStyle || 'body_01'} ${textCenter && ' text-center'}`}>{warning}</div>
          )}
          {bottomImg && <img src={bottomImg} alt="" className="popWarning-img" />}
          {description && <div className={`body_04 popWarning-text-description ${contentScroll ? 'scroll' : ''}`}>{description}</div>}
        </div>
        {isBlackButton ||
          (isWhiteButton && (
            <div className="popWarning-btn-wrap popWarning-custom-btn-wrap">
              {isBlackButton && (
                <button type="button" className="popWarning-black-btn" onClick={handleConfirm}>
                  {confirmText || '確認'}
                </button>
              )}
              {isWhiteButton && (
                <button type="button" className="popWarning-white-btn" onClick={handleCancel}>
                  {cancelText || '確認'}
                </button>
              )}
            </div>
          ))}
        {!isBlackButton && !isWhiteButton && (
          <div className="popWarning-btn-wrap">
            {isCancel && (
              <button type="button" className="popWarning-btn h6" onClick={handleCancel}>
                {cancelText || '取消'}
              </button>
            )}
            <button type="button" className="popWarning-btn h6" onClick={handleConfirm}>
              {url ? (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {confirmText || '確認'}
                </a>
              ) : (
                confirmText || '確認'
              )}
            </button>
          </div>
        )}
      </div>
      <div className={`black-background z-index100 black-background-animation ${handleBackgroundClassName()}`} style={{ zIndex: zIndex && zIndex }} />
    </>
  )
}

export default React.memo(NewPopWarning)
