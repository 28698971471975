import React, { useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { debounce } from 'lodash'
import { Alert, ButtonGroup, Button, CheckPicker } from 'rsuite'

const StyledCheckPicker = styled(CheckPicker)`
  flex: 8;
  margin-right: 10px;
  background-color: #f5f6f7;
  outline: none;
  border: none;
  transition: 0.3s;
  &::placeholder {
    opacity: 0.5 !important;
  }
  &:focus {
    border-color: none;
    background-color: #f5fafb;
  }
`
const StyledButtonGroup = styled(ButtonGroup)`
  flex: 1.5;
`
const Wrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
`

const Label = styled.p`
  font-weight: 500;
  color: ${(props) => (props.variant === 'ride' ? '#2563eb' : '#059669')};
`

const CreateCouponFields = React.memo(
  ({ getCoupons, getRentalCoupons, handleFilterCouponField, handleOnChangeCouponFields, value, id, isDisabled = false, type }) => {
    const [coupons, setCoupons] = useState([])
    const [selectedItems, setSelectedItems] = useState(value ? [value] : [])
    // Follow: https://beta.reactjs.org/learn/you-might-not-need-an-effect 'Adjusting some state when a prop changes'
    const [prevValue, setPrevValue] = useState(value)
    if (value !== prevValue) {
      !value ? setSelectedItems([]) : setSelectedItems([value])
      setPrevValue(value)
    }
    const fetchCoupons = useCallback(
      async (params = { limit: 10, text: '' }) => {
        if (type === 'ride') {
          const response = await getCoupons({ limit: params.limit, promo_id: params.text })
          if (response.status !== 200) {
            Alert.error(response.data.msg)
            return
          }
          const data = response.data.map(({ promo_id, promo_title, end_date }) => ({
            promo_id: `${promo_id}`,
            promo_title,
            end_date: dayjs(end_date * 1000).format('YY-MM-DD HH:mm'),
          }))
          setCoupons(data)
        } else {
          const response = await getRentalCoupons({ limit: params.limit, keyword: params.text })
          if (response.status !== 200) {
            Alert.error(response.data.msg)
            return
          }
          const data = response.data.map(({ id, promo_title, end_date }) => ({
            promo_id: `${id}`,
            promo_title,
            end_date: dayjs(end_date * 1000).format('YY-MM-DD HH:mm'),
          }))
          setCoupons(data)
        }
      },
      [type, getCoupons, getRentalCoupons],
    )
    useEffect(() => {
      fetchCoupons()
    }, [fetchCoupons])
    /*
  // Follow pattern: https://beta.reactjs.org/learn/you-might-not-need-an-effect 'Adjusting some state when a prop changes'
  useEffect(() => {
    if (value) {
      setSelectedItems([value])
    }
  }, [value])
  */
    const debounceSearch = debounce(async (text) => {
      await fetchCoupons({ limit: 10, text })
    }, 300)

    return (
      <Wrapper>
        <Label variant={type}>{type === 'ride' ? '乘車券' : '租車券'}</Label>
        <StyledCheckPicker
          placement="topStart"
          cleanable={false}
          data={coupons}
          value={selectedItems}
          onSearch={debounceSearch}
          onSelect={(value, item) => {
            // handleOnChangeCouponFields(value, id)
            handleOnChangeCouponFields(item.promo_id, id)
          }}
          searchBy={(keyword, label, item) => {
            return `${item.promo_id}`.startsWith(keyword)
          }}
          placeholder={type === 'ride' ? '請輸入 Promo ID' : '請輸入 ID'}
          valueKey="promo_id"
          labelKey="promo_title"
          renderMenuItem={(label, item) => {
            return (
              <span>
                {`${item.promo_id} ${item.promo_title} `}
                <span style={{ color: '#777777', fontSize: 10 }}>{`${item.end_date}`}</span>
              </span>
            )
          }}
          renderValue={(value) => {
            return <span>{value.join(' , ')}</span>
          }}
          disabled={isDisabled}
        />
        <StyledButtonGroup size="md">
          {/* <StyledButton>確認</StyledButton> */}
          <Button onClick={handleFilterCouponField} disabled={isDisabled}>
            刪除
          </Button>
        </StyledButtonGroup>
      </Wrapper>
    )
  },
)

export default CreateCouponFields
