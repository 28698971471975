import React from 'react'
import { Cell } from 'rsuite-table'
import { TagItem } from './TagItem'

export const TypeCell = ({ rowData, ...props }) => {
	const isNews = rowData.type === 'NEWS'
	const isDeleted = rowData.status === 'DELETED'
	const isExpired = Boolean(rowData.end_time && new Date() > rowData.end_time*1000)
	const isFuture = Boolean(rowData.start_time && new Date() < rowData.start_time*1000)
	const inProgress = !isExpired && !isFuture

	return (
		<Cell {...props}>
				{isDeleted && <TagItem type={'deleted'}/>}
				{isNews && <TagItem type={'news'}/>}
				{isExpired && !isDeleted && <TagItem type={'expired'}/>}
				{isFuture && !isDeleted && <TagItem type={'future'}/>}
				{inProgress && !isDeleted && <TagItem type={'inprogress'}/>}
				{Boolean(rowData.cover) && <TagItem type={'cover'}/>}
		</Cell>
	)
}

export default React.memo(TypeCell)
