/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { find, isEmpty } from 'lodash'
import { Alert, Nav, Loader } from 'rsuite'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import { isString } from 'markdown-it/lib/common/utils'
import usePageSetting from '../../hook/usePageSetting'
import { REVIEW_STATUS } from './constants'
import { handleEquippedDocs, getQuarter } from './helper'
import { getDriverInfo, getEquippedList, postEquippedReview, deleteEquipped, getReasonSuggestion } from '../../api'
import { DriverInfo, ReviewSection, StatusCell, NameCell } from '../../components/equipped'
import { WhisperCell, DateCell, ButtonCell } from '../../components/table/cell'
import { ListWrap, AddListModel, AddListGrop, BreadcrumbNav, FormBottomButtons, FormSection } from '../../components/views'
import PopWarning from '../../components/popWarning'
import { TableController, SearchInput } from '../../components/table'
import 'react-datepicker/dist/react-datepicker.css'

const initialState = {
  driver_id: null,
  name: '',
  plate_number: '',
  car_brand: '',
  phone_number: '',
  avatar: '',
  enroll_date: null,
  vehicle_type: '',
  elite: null,
  equipped_id: null,
  equipped_q_time: null,
  equipped_note: '',
  accept_at: false,
  equipped_docs: [],
  equipped_pass_doc: [],
}

const Equipped = () => {
  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState('NEW')
  const [cacheList, setCacheList] = useState({
    NEW: [],
    REJECT: [],
    ACCEPT: [],
    BLOCK: [],
  })
  const [searchWord, setSearchWord] = useState('')
  const [reasons, setReasons] = useState([])
  const { isAddMode, list, currentInfo, warning, toggleAddMode, handleOnChange, setList, setCurrentInfo, setWarning } = usePageSetting(initialState)

  const fetchSuggestions = async () => {
    const response = await getReasonSuggestion({ type: 'EQUIPPED' })
    if (response.status !== 200) return console.log(response.data.msg)
    const { data } = response
    const suggestions = data.map(({ id, title }) => {
      return { name: title, value: id }
    })
    setReasons(suggestions)
  }

  const fetchAllData = async () => {
    setLoading(true)
    const newResponse = await getEquippedList('NEW')
    const rejectResponse = await getEquippedList('REJECT')
    const acceptResonse = await getEquippedList('ACCEPT')
    const blockResponse = await getEquippedList('BLOCK')
    if (newResponse.status !== 200 || rejectResponse.status !== 200 || acceptResonse.status !== 200) return setWarning('發生錯誤，請稍後再試')
    setCacheList({
      ...cacheList,
      NEW: newResponse.data || [],
      REJECT: rejectResponse.data || [],
      ACCEPT: acceptResonse.data || [],
      BLOCK: blockResponse.data || [],
    })
    setLoading(false)
  }

  const fetchDriverInfo = async (did) => {
    setLoading(true)
    const response = await getDriverInfo(did, { q: '{driver_profile, enroll_date, driver_review_page, membership_progress, equipped}' })
    setLoading(false)
    if (response.status !== 200) return setWarning('發生錯誤或無此用戶，請稍後再試')
    const { driver_profile, enroll_date, membership_progress, equipped } = response.data.data
    const { driver_id, last_name, first_name, phone_number, profile_image_url, vehicle_type, msg, type, car_brand, plate_number } = driver_profile
    if (type === 'INVALID_USER') return setWarning(msg)
    const { elite } = membership_progress
    const { id, q_time, accept_at, note, equipped_docs } = equipped
    setCurrentInfo({
      ...currentInfo,
      driver_id,
      name: `${last_name}${first_name}`,
      plate_number,
      car_brand,
      phone_number,
      avatar: profile_image_url,
      enroll_date: `${dayjs(enroll_date * 1000).format('YYYY')}年入隊`,
      vehicle_type,
      elite: Boolean(elite.elite),
      equipped_id: id || null,
      equipped_q_time: q_time,
      equipped_note: note || '',
      accept_at: Boolean(accept_at),
      equipped_docs: handleEquippedDocs(equipped_docs, driver_id), // 梳理 equipped_docs
      equipped_pass_doc: equipped_docs ? equipped_docs.filter((doc) => doc.review_type === 'ACCEPT') : [],
    })
    window.history.replaceState(null, null, `${window.location.pathname}?driver=${driver_id}`)
    if (!isAddMode) toggleAddMode()
  }

  const handleDeleteEquippedImage = async (id) => {
    const { driver_id } = currentInfo
    if (!id || !driver_id) return
    const body = { record_id: id, driver_id }
    const response = await deleteEquipped(body)
    if (response.status !== 200) return setWarning(response.data.msg)
    Alert.error('刪除成功!')
    fetchDriverInfo(driver_id)
  }

  const handleReviewDriver = (data) => {
    fetchDriverInfo(data.uid)
    toggleAddMode()
  }

  const handleUpdateReview = async () => {
    const { driver_id, equipped_note, equipped_docs } = currentInfo
    if (!driver_id) return setWarning('請審核有效的司機 DID')
    const isLackForReason = equipped_docs.find(
      ({ review_type, content }) => (review_type === 'REJECT' || review_type === 'BLOCK') && isEmpty(content),
    )
    if (isLackForReason) return setWarning('請確實填寫未通過理由！')

    // call api
    const body = {
      driver_id,
      note: { content: equipped_note },
      file: equipped_docs
        .filter((docs) => !isString(docs.id))
        .map(({ id, review_type, content }) => {
          return {
            id,
            review_type,
            content: review_type === 'ACCEPT' ? '' : content,
          }
        }),
    }
    console.log('body', body)
    const response = await postEquippedReview(body)
    if (response.status !== 200) return setWarning(response.data.msg)
    Alert.success(`成功更新 司機 DID 為 ${driver_id} 的審核！`)
    fetchAllData()
    toggleAddMode()
  }

  const handleReviewItem = (action, id, type, value) => {
    switch (action) {
      case 'edit':
        setCurrentInfo({
          ...currentInfo,
          equipped_docs: currentInfo.equipped_docs.map((item) => {
            const newItem = { ...item }
            if (newItem.id !== id) return newItem
            newItem[type] = value
            return newItem
          }),
        })
        break
      case 'delete':
        handleDeleteEquippedImage(id)
        break
      default:
        break
    }
  }

  const handleSearch = () => {
    if (!searchWord) return
    fetchDriverInfo(searchWord)
    setSearchWord('')
  }

  const handleWarningConfirm = () => {
    setWarning('')
    setList([])
    fetchAllData()
  }

  useEffect(() => {
    const toggleSpecificDriver = () => {
      const params = new URLSearchParams(window.location.search)
      const driverId = params.get('driver')
      if (driverId) {
        fetchDriverInfo(driverId)
      }
    }

    fetchSuggestions()
    fetchAllData()
    toggleSpecificDriver()
  }, [])

  useEffect(() => {
    setList(cacheList[tab])
  }, [tab, cacheList])

  const { equipped_q_time, equipped_docs, equipped_pass_doc } = currentInfo
  return (
    <div className="elite-review-wrapper">
      {loading && <Loader content="loading" size="md" />}
      <div>
        {warning && <PopWarning warning={warning} textCenter confirm={handleWarningConfirm} />}
        {isAddMode ? (
          <AddListModel>
            <BreadcrumbNav
              start="全標配審核列表"
              entry="審核司機"
              onClick={() => {
                toggleAddMode()
                window.history.replaceState(null, null, window.location.pathname)
              }}
            />
            <AddListGrop title="司機基本資訊">
              <DriverInfo info={currentInfo} handleOnChange={handleOnChange} />
              {!isEmpty(equipped_docs) && (
                <FormSection title={`審核項目${equipped_q_time ? `(審核季度：${getQuarter(equipped_q_time)})` : ''}`}>
                  {equipped_docs.map((doc, index) => {
                    const { id, uid, type } = doc
                    return (
                      <ReviewSection
                        key={`${id}_${uid}_${type}`}
                        index={index}
                        item={doc}
                        resultOptions={REVIEW_STATUS}
                        reasonOptions={reasons}
                        handleReviewItem={handleReviewItem}
                        setLoading={setLoading}
                        pass={!isEmpty(find(equipped_pass_doc, { id }))}
                        fetchDriverInfo={fetchDriverInfo}
                      />
                    )
                  })}
                </FormSection>
              )}
            </AddListGrop>
            <FormBottomButtons confirmText="送出審核結果" cancelText="取消" confirm={handleUpdateReview} cancel={toggleAddMode} />
          </AddListModel>
        ) : (
          <>
            <ListWrap status="全標配審核" />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Nav appearance="tabs" activeKey={tab} onSelect={(eventKey) => setTab(eventKey)}>
                {REVIEW_STATUS.map(({ name, value }) => {
                  return <Nav.Item key={value} eventKey={value}>{`${name}(${cacheList[value].length})`}</Nav.Item>
                })}
              </Nav>
              <SearchInput
                title="搜尋司機 ID"
                setSearchWord={setSearchWord}
                withButton
                onClick={handleSearch}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) handleSearch()
                }}
              />
            </div>
            <TableController data={list} topHeight={104} rowHeight={60}>
              <Column flexGrow={1} align="center">
                <HeaderCell>Photo ID</HeaderCell>
                <Cell dataKey="id" />
              </Column>
              <Column flexGrow={1} align="center">
                <HeaderCell>DID</HeaderCell>
                <Cell dataKey="uid" />
              </Column>
              <Column flexGrow={1.5}>
                <HeaderCell>電話</HeaderCell>
                <WhisperCell dataKey="phone_number" />
              </Column>
              <Column flexGrow={1.5}>
                <HeaderCell>司機姓名</HeaderCell>
                <NameCell dataKey="name" />
              </Column>
              <Column flexGrow={2}>
                <HeaderCell>最新送審時間</HeaderCell>
                <DateCell dataKey="driver_update" format="YYYY-MM-DD HH:mm" />
              </Column>
              <Column flexGrow={2.5}>
                <HeaderCell>審核狀態</HeaderCell>
                <StatusCell dataKey="status" />
              </Column>
              <Column flexGrow={2.5}>
                <HeaderCell>不通過原因（顯示於司機端）</HeaderCell>
                <WhisperCell dataKey="content" />
              </Column>
              <Column flexGrow={2.5}>
                <HeaderCell>備註</HeaderCell>
                <WhisperCell dataKey="note" />
              </Column>
              <Column flexGrow={2} align="center">
                <HeaderCell>審核查閱</HeaderCell>
                <ButtonCell title="審核查閱" color="orange" handleClick={handleReviewDriver} />
              </Column>
            </TableController>
          </>
        )}
      </div>
    </div>
  )
}

export default Equipped
