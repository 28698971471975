export const promoType = [
  {
    name: '重複領用',
    value: 'OPERATION',
  },
  {
    name: '首次租車',
    value: 'FIRST_TIME_USER',
  },
  {
    name: '單次領用',
    value: 'ONE_TIME_EVENT',
  },
  {
    name: '亂碼序號',
    value: 'SERIAL_NUMBER',
  },
]
