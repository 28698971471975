import React, { useState } from 'react'
import { Modal, Button, Input, DatePicker, SelectPicker } from 'rsuite'
import { FormGroup } from './styles'
import IntervalPicker from './IntervalPicker'
import dayjs from 'dayjs'

const BlockDriverModal = React.memo(({ show, close, confirm, driver, suggestions = [], suggestionsMap = new Map() }) => {
  const [startTime, setStartTime] = useState(dayjs().unix())
  const [interval, setInterval] = useState(1)
  const [reason, setReason] = useState('')
  const [note, setNote] = useState('')

  const handleDateSelect = (date) => setStartTime(dayjs(date).unix())
  const handleSuggestionSelect = (value) => setReason(suggestionsMap.get(value))

  const submit = () => {
    confirm({
      startTime,
      interval,
      reason,
      note,
    })
  }

  const payloadIsReady = startTime && interval && reason

  return (
    <Modal backdrop={true} show={show} onHide={close} size="sm">
      <Modal.Header>
        <Modal.Title>司機接單封鎖</Modal.Title>
        {driver && <p style={{ marginTop: '10px' }}>司機ID: {driver.uid}</p>}
      </Modal.Header>
      {driver && (
        <Modal.Body style={{ marginTop: '10px' }}>
          <FormGroup>
            <FormGroup.Label>開始時間</FormGroup.Label>
            <FormGroup.Control>
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                placeholder="選擇日期與時間"
                onSelect={handleDateSelect}
                cleanable={false}
                value={startTime ? new Date(startTime * 1000) : null}
                style={{ width: 200 }}
              />
            </FormGroup.Control>
          </FormGroup>
          <FormGroup>
            <FormGroup.Label>封鎖期長</FormGroup.Label>
            <FormGroup.Control>
              <IntervalPicker style={{ width: 200 }} value={interval} onChange={(value) => setInterval(value)} />
            </FormGroup.Control>
          </FormGroup>
          <FormGroup>
            <FormGroup.Label>理由項目</FormGroup.Label>
            <FormGroup.Control>
              <SelectPicker
                cleanable={false}
                data={suggestions}
                placeholder="選取公版"
                onSelect={handleSuggestionSelect}
                style={{ maxWidth: '90%', minWidth: 200 }}
                menuStyle={{ width: 400 }}
              />
            </FormGroup.Control>
          </FormGroup>
          <FormGroup>
            <FormGroup.Label>理由</FormGroup.Label>
            <FormGroup.Control>
              <Input value={reason} onChange={setReason} componentClass="textarea" rows={3} placeholder="理由" />
            </FormGroup.Control>
          </FormGroup>
          <FormGroup>
            <FormGroup.Label>Note</FormGroup.Label>
            <FormGroup.Control>
              <Input value={note} onChange={setNote} componentClass="textarea" rows={3} placeholder="Note" />
            </FormGroup.Control>
          </FormGroup>
        </Modal.Body>
      )}
      <Modal.Footer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={close} appearance="subtle">
            取消
          </Button>
          <Button disabled={!payloadIsReady} onClick={submit} color="red">
            封鎖
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
})

export default BlockDriverModal
