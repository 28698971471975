import React from 'react'
import { Icon } from 'rsuite'
import { PreviewExpiredPageWrapper } from './styles'
import defaultTheme from './theme'

const PreviewExpiredPage = ({ theme = defaultTheme, isMember = true }) => {
  return (
    <PreviewExpiredPageWrapper theme={theme}>
      <div className="top">
        <div className="icon" />
        <div className="icon">
          <Icon icon="close" />
        </div>
      </div>
      <div className="body">
        <div className="title">{'喔噢～\n這個魔法糖已經過期囉！'}</div>
        <div className="cover" />
        <div className="solid-button">{isMember ? '我也要分享' : '加入 LINE TAXI'}</div>
        {!isMember && <div className="tip">提醒您，需加入 LINE TAXI 好友，並完成註冊，乘車券才能正式歸戶喔！</div>}
        <div className="description">11/4 前每天開啟不同好友分享的萬聖節魔法糖，將驚喜獲得10~100元乘車券！當日開啟有效！</div>
      </div>
    </PreviewExpiredPageWrapper>
  )
}

export default PreviewExpiredPage
