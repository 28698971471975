import React from 'react'
import { Button } from 'rsuite'
import { Cell } from 'rsuite-table'

const ButtonCell = ({ rowData, dataKey, toggle, handleClick, title, color, size, ...props }) => {
  const handleButtonClick = () => {
    handleClick && handleClick(rowData)
    toggle && toggle()
  }

  return (
    <Cell {...props}>
      <Button color={color} appearance="primary" onClick={handleButtonClick} size={size || 'md'}>
        {title}
      </Button>
    </Cell>
  )
}

export default React.memo(ButtonCell)
