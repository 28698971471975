import React from 'react'
import { Popover, Whisper } from 'rsuite'
import { Cell } from 'rsuite-table'

const DiscountCell = ({ rowData, ...props }) => {
	const speaker = <Popover><b>{`打 ${rowData.amount * 10} 折，上限 ${rowData.max_amount} 元`}</b></Popover>
	return (
		<Cell {...props}>
			{rowData.max_amount ? 
			<Whisper placement="top" speaker={speaker}>
				<p>{rowData.amount < 1 ? `${rowData.amount * 10} 折` : `${rowData.amount} 元`}</p>
			</Whisper>:
			<p>{rowData.amount < 1 ? `${rowData.amount * 10} 折` : `${rowData.amount} 元`}</p>
			}
		</Cell>
	)
}

export default DiscountCell
