export const VALIDATION = {
  NO_USER_GROUP: '請選擇分眾名單',
  NO_EVENT_NAME: '請輸入活動名稱',
  NO_DATA: '請輸入 Promo ID 或是建立分眾推播',
  TEXT_IS_TEMP: '輸入框不能為空',
  ACTIONS_IS_TEMP: '請選擇版型並填輸入 action',
  NO_PROMO_ID: '請輸入 Promo ID',
  NO_EXECUTION_TIME: '請選擇指定時間',
}
export const CAMPAIGN_TYPE = {
  COUPON: 'COUPON',
  AUDIENCE: 'AUDIENCE',
  COUPON_AND_AUDIENCE: 'COUPON_AND_AUDIENCE',
}
export const EXECUTION_TYPE = {
  INSTANT: 'INSTANT', // SCHEDULE_TYPE: INSTANT
  SCHEDULE: 'SCHEDULE', // SCHEDULE_TYPE: SCHEDULE
  FOLLOW_COUPON_EXECUTION: 'FOLLOW_COUPON_EXECUTION',
}
export const SCHEDULE_TYPE = {
  INSTANT: 'INSTANT',
  SCHEDULED: 'SCHEDULED',
}

export const ACTION_EXECUTE_TIME = {
  COUPON: {
    NOTIFICATION: false,
    COUPONS: true,
  },
  AUDIENCE: {
    NOTIFICATION: true,
    COUPONS: false,
  },
  COUPON_AND_AUDIENCE: {
    NOTIFICATION: true,
    COUPONS: true,
  },
}

export const MESSAGE_TYPE = {
  IMAGEMAP: 'imagemap',
  FLEX_MESSAGE: 'flexMessage',
  TEXT: 'text',
}

export const ACTION_TYPE = {
  ISSUE_COUPON: 'ISSUE_COUPON',
  SEND_LINE_MESSAGE: 'SEND_LINE_MESSAGE',
  ISSUE_RENTAL_COUPON: 'ISSUE_RENTAL_COUPON',
}

export const TASK_STATUS = {
  DRAFT: { status: 'DRAFT', text: '草稿', color: '#8e8e8e', icon: 'data-decrease' },
  SUCCESS: { status: 'SUCCESS', text: '成功', color: '#14b54a', icon: 'check-circle' },
  PENDING: { status: 'PENDING', text: '待執行', color: '#4b8bff', icon: 'refresh2' },
  PROCESSING: { status: 'PROCESSING', text: '執行中', color: '#4b8bff', icon: 'refresh2' },
  PROCESSED: { status: 'PROCESSED', text: '已執行', color: '#33BBC5', icon: 'check-square-o' },
  FAILED: { status: 'FAILED', text: '執行失敗', color: '#ff0032', icon: 'close-circle' },
  CANCELLED: { status: 'CANCELLED', text: '已刪除', color: '#ff0032', icon: 'close-circle' },
}

export const FILTER_METHODS = {
  BY_FILE: 'upload_file',
  BY_MANUAL: 'bigquery',
}

export const USER_PROPORTION = [
  {
    name: '兩組：50%, 50%',
    value: [1, 1],
  },
  {
    name: '兩組：80%, 20%',
    value: [4, 1],
  },
  {
    name: '三組：33%, 33%, 34%',
    value: [1, 1, 1],
  },
]

// TODO: LT-7483 選項暫時先固定，之後再看是否變成 API 拿
export const TEST_TRACK_GROUP = [
  {
    name: 'GENE',
    value: 'GENE',
  },
  {
    name: 'GENE加發券',
    value: 'GENE加發券',
  },
  {
    name: '激進大禮包',
    value: '激進大禮包',
  },
  {
    name: '歷史未搭&64週未搭',
    value: '歷史未搭&64週未搭',
  },
  {
    name: '其他',
    value: '其他',
  },
]

export const UPLOAD_FILE_TYPE = {
  UID: 'uid',
  PHONE: 'phone_number',
}

export const UPLOAD_FILE_TYPE_LABEL = {
  [UPLOAD_FILE_TYPE.UID]: 'UID',
  [UPLOAD_FILE_TYPE.PHONE]: '電話號碼',
}

export const UPLOAD_FILE_TEMPLATE = {
  [UPLOAD_FILE_TYPE.UID]: [
    {
      sheetName: `工作表1`,
      thead: ['101', ' '],
      tbody: [
        ['30', ' '],
        ['3929', ' '],
        ['1639', ' '],
      ],
    },
  ],
  [UPLOAD_FILE_TYPE.PHONE]: [
    {
      sheetName: `工作表1`,
      thead: ['+886988578785', ' '],
      tbody: [
        ['+886900000030', ' '],
        ['+886900003925', ' '],
        ['+886900001639', ' '],
      ],
    },
  ],
}

export const FREQUENCY_BY_DAY = {
  A_DAY: 1,
  A_WEEK: 7,
  TWO_WEEKS: 14,
  FOUR_WEEKS: 28,
}

export const FREQUENCY_BY_DAY_LABEL = {
  [FREQUENCY_BY_DAY.A_DAY]: '每天一次',
  [FREQUENCY_BY_DAY.A_WEEK]: '每週一次',
  [FREQUENCY_BY_DAY.TWO_WEEKS]: '兩週一次',
  [FREQUENCY_BY_DAY.FOUR_WEEKS]: '四週一次',
}

export const FREQUENCY_BY_DAY_OPTIONS = [
  { value: FREQUENCY_BY_DAY.A_DAY, label: FREQUENCY_BY_DAY_LABEL[FREQUENCY_BY_DAY.A_DAY] },
  { value: FREQUENCY_BY_DAY.A_WEEK, label: FREQUENCY_BY_DAY_LABEL[FREQUENCY_BY_DAY.A_WEEK] },
  { value: FREQUENCY_BY_DAY.TWO_WEEKS, label: FREQUENCY_BY_DAY_LABEL[FREQUENCY_BY_DAY.TWO_WEEKS] },
  { value: FREQUENCY_BY_DAY.FOUR_WEEKS, label: FREQUENCY_BY_DAY_LABEL[FREQUENCY_BY_DAY.FOUR_WEEKS] },
]

export const USER_GROUP_TABS = {
  ALL: 'all',
  AUTO_UPDATE: 'auto_update',
}

// see more: https://taxigo-tw.atlassian.net/browse/LT-9651?focusedCommentId=29264
export const CAMPAIGN_OPERATE_ACTIONS = {
  NEW_TEMPLATE_AND_CAMPAIGN_SCHEDULE: 'NEW_TEMPLATE_AND_CAMPAIGN_SCHEDULE',
  NEW_TEMPLATE_DRAFT: 'NEW_TEMPLATE_DRAFT',
  NEW_CAMPAIGN_SCHEDULE: 'NEW_CAMPAIGN_SCHEDULE',
  NEW_CAMPAIGN_DRAFT: 'NEW_CAMPAIGN_DRAFT',
}

export const CAMPAIGNS_TAB = {
  DELIVERED: 'delivered',
  SCHEDULED: 'scheduled',
  OTHER: 'other',
}
export const CAMPAIGNS_SUB_TAB = {
  ALL: 'all',
  TEMPLATE: 'template',
  CAMPAIGN: 'campaign',
}

export const EVENT_MANAGEMENT_SEARCH_PARAMS_KEY = {
  TAB: 'tab',
  SUB_TAB: 'subtab',
}

export const EDIT_EVENT_SEARCH_PARAMS_KEY = {
  IS_TEMPLATE: 'is_template',
}
