import React from 'react'
import { Icon, Input, InputGroup } from 'rsuite'
import styled from 'styled-components'

const InputGroupWrapper = styled.div`
  width: 200px;
  margin: 5px 5px;
`

const InputGroupWidthButton = ({ placeholder, onChange }) => (
  <InputGroupWrapper>
    <InputGroup inside>
      <Input placeholder={placeholder} onChange={onChange} />
      <InputGroup.Button>
        <Icon icon="search" />
      </InputGroup.Button>
    </InputGroup>
  </InputGroupWrapper>
)

export default InputGroupWidthButton
