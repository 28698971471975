import React from 'react'
import { Table } from 'rsuite-table'

const TableController = ({ children, data, rowHeight, topHeight = 0, handleSortColumn, newsParams }) => {
	const { sortColumn, sortType } = newsParams
	const height = document.body.clientHeight - topHeight - 44

	return (
		<div>
			<Table
				height={height}
				data={data}
				id="table"
				affixHeader
				rowHeight={rowHeight}
				sortColumn={sortColumn}
        sortType={sortType}
				onSortColumn={(sortColumn, sortType) => { handleSortColumn(sortColumn, sortType) }} >
				{children}
			</Table>
		</div>
	)
}

export default React.memo(TableController)
