import React, { useState, useEffect } from 'react'
import { Panel, Button, Input, InputNumber, DatePicker, Toggle } from 'rsuite'
import { FormGroup, UncheckedSpan } from './styles'
import dayjs from 'dayjs'

const ReceiptForm = React.memo(({ before, submit }) => {
  const [form, setForm] = useState({})
  const [toggle, setToggle] = useState({
    reserve_time: false,
    distance: false,
    duration: false,
    start_time: false,
    start_address: false,
    end_time: false,
    end_address: false,
  })

  useEffect(() => {
    setForm(before)
  }, [before])

  const handleFormUpdate = (key, value) => setForm({ ...form, ...{ [key]: value } })
  const handleToggle = (key, value) => setToggle({ ...toggle, [key]: value })
  const handleDateSelect = (key, value) => handleFormUpdate(key, dayjs(value).unix())

  const handleSubmit = () => {
    const payload = {}
    for (const key of Object.keys(toggle)) {
      if (!toggle[key]) continue
      payload[key] = ['distance', 'duration'].includes(key) ? Number(form[key]) : form[key]
    }
    submit(payload)
  }

  const submitIsEnable = () => {
    for (const key of Object.keys(toggle)) {
      if (toggle[key] && form[key] !== '') return true
    }
    return false
  }

  return (
    <Panel bordered bodyFill style={{ width: 400 }}>
      <FormGroup>
        <FormGroup.Label>
          <Toggle
            checkedChildren="用車時間"
            unCheckedChildren={<UncheckedSpan>用車時間</UncheckedSpan>}
            value={toggle.reserve_time}
            onChange={(v) => handleToggle('reserve_time', v)}
          />
        </FormGroup.Label>
        <FormGroup.Control>
          <DatePicker
            disabled={!toggle.reserve_time}
            format="YYYY-MM-DD HH:mm"
            onSelect={(v) => handleDateSelect('reserve_time', v)}
            cleanable={false}
            value={new Date(form.reserve_time * 1000)}
            ranges={[]}
            style={{ width: 200 }}
          />
        </FormGroup.Control>
      </FormGroup>
      {form.distance !== null && (
        <FormGroup>
          <FormGroup.Label>
            <Toggle
              checkedChildren="行駛里程"
              unCheckedChildren={<UncheckedSpan>行駛里程</UncheckedSpan>}
              value={toggle.distance}
              onChange={(v) => handleToggle('distance', v)}
            />
          </FormGroup.Label>
          <FormGroup.Control>
            <InputNumber disabled={!toggle.distance} style={{ width: 150 }} value={form.distance} onChange={(v) => handleFormUpdate('distance', v)} />
          </FormGroup.Control>
        </FormGroup>
      )}
      {form.duration !== null && (
        <FormGroup>
          <FormGroup.Label>
            <Toggle
              checkedChildren="行駛時間"
              unCheckedChildren={<UncheckedSpan>行駛時間</UncheckedSpan>}
              value={toggle.duration}
              onChange={(v) => handleToggle('duration', v)}
            />
          </FormGroup.Label>
          <FormGroup.Control>
            <InputNumber
              disabled={!toggle.duration}
              postfix="秒"
              style={{ width: 188 }}
              value={form.duration}
              onChange={(v) => handleFormUpdate('duration', v)}
            />
          </FormGroup.Control>
        </FormGroup>
      )}
      {form.start_time !== null && (
        <FormGroup>
          <FormGroup.Label>
            <Toggle
              checkedChildren="上車時間"
              unCheckedChildren={<UncheckedSpan>上車時間</UncheckedSpan>}
              value={toggle.start_time}
              onChange={(v) => handleToggle('start_time', v)}
            />
          </FormGroup.Label>
          <FormGroup.Control>
            <DatePicker
              disabled={!toggle.start_time}
              format="HH:mm"
              onSelect={(v) => handleDateSelect('start_time', v)}
              cleanable={false}
              value={new Date(form.start_time * 1000)}
              ranges={[]}
              style={{ width: 200 }}
            />
          </FormGroup.Control>
        </FormGroup>
      )}
      <FormGroup>
        <FormGroup.Label>
          <Toggle
            checkedChildren="上車地址"
            unCheckedChildren={<UncheckedSpan>上車地址</UncheckedSpan>}
            value={toggle.start_address}
            onChange={(v) => handleToggle('start_address', v)}
          />
        </FormGroup.Label>
        <FormGroup.Control>
          <Input
            disabled={!toggle.start_address}
            value={form.start_address}
            onChange={(v) => handleFormUpdate('start_address', v)}
            componentClass="textarea"
            rows={3}
          />
        </FormGroup.Control>
      </FormGroup>
      {form.end_time !== null && (
        <FormGroup>
          <FormGroup.Label>
            <Toggle
              checkedChildren="下車時間"
              unCheckedChildren={<UncheckedSpan>下車時間</UncheckedSpan>}
              value={toggle.end_time}
              onChange={(v) => handleToggle('end_time', v)}
            />
          </FormGroup.Label>
          <FormGroup.Control>
            <DatePicker
              disabled={!toggle.end_time}
              format="HH:mm"
              onSelect={(v) => handleDateSelect('end_time', v)}
              cleanable={false}
              value={new Date(form.end_time * 1000)}
              ranges={[]}
              style={{ width: 200 }}
            />
          </FormGroup.Control>
        </FormGroup>
      )}
      <FormGroup>
        <FormGroup.Label>
          <Toggle
            checkedChildren="下車地址"
            unCheckedChildren={<UncheckedSpan>下車地址</UncheckedSpan>}
            value={toggle.end_address}
            onChange={(v) => handleToggle('end_address', v)}
          />
        </FormGroup.Label>
        <FormGroup.Control>
          <Input
            disabled={!toggle.end_address}
            value={form.end_address}
            onChange={(v) => handleFormUpdate('end_address', v)}
            componentClass="textarea"
            rows={3}
          />
        </FormGroup.Control>
      </FormGroup>
      <FormGroup style={{ width: '100%', marginTop: 40 }}>
        <Button disabled={!submitIsEnable()} onClick={handleSubmit} appearance="ghost" block>
          送出
        </Button>
      </FormGroup>
    </Panel>
  )
})

export default ReceiptForm
