import React from 'react'
import { Modal, Button, Icon } from 'rsuite'
import { TextWrapper, Code } from './styles'
import dayjs from 'dayjs'

const ConfirmModal = React.memo(({ show, close, confirm, preview = [] }) => {
  const previewMap = {
    reserve_time: { label: '用車時間', format: (v) => dayjs(v * 1000).format('YYYY-MM-DD HH:mm') },
    distance: { label: '行駛里程' },
    duration: { label: '行駛時間' },
    start_time: { label: '上車時間', format: (v) => dateFormat(v) },
    start_address: { label: '上車地址' },
    end_time: { label: '下車時間', format: (v) => dateFormat(v) },
    end_address: { label: '下車地址' },
  }

  const dateFormat = (v) => dayjs(v * 1000).format('HH:mm')

  return (
    <Modal backdrop={true} show={show} onHide={close}>
      <Modal.Header>
        <Modal.Title>乘車收據變更</Modal.Title>
      </Modal.Header>
      {preview && (
        <Modal.Body>
          <TextWrapper>
            {preview.map((v, i) => {
              const format = previewMap[v.key].format
              return (
                <div key={i}>
                  <span style={{ marginRight: 6 }}>{previewMap[v.key].label}</span>
                  <Code>{format ? format(v.before) : v.before}</Code>
                  <Icon icon="long-arrow-right" />
                  <Code>{format ? format(v.after) : v.after}</Code>
                </div>
              )
            })}
          </TextWrapper>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button onClick={confirm} appearance="primary">
          確定送出
        </Button>
        <Button onClick={close} appearance="subtle">
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  )
})

export default ConfirmModal
