import React from 'react'
import { ContextStore } from '../../../reducers'
import { DatePicker, Animation, Breadcrumb, Nav, Alert } from 'rsuite'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import moment from 'moment-timezone'
import { forEach, has, isEmpty } from 'lodash'
import { ActionCell, ModelCell, TitleCell, ModelDemo, LineBotModel, Collapse, Simulator } from '../../../components/campaign'
import ListWrap from '../../../components/views/ListWrap'
import { TypeData } from '../../../model/campaign'
import { postMktBroadcasting, putMktBroadcasting, getModifyTable2, postImage } from '../../../api'
import { creatContent, creactAction, parseContent, isJosnString } from './helper'
import AddListModel from '../../../components/views/AddListModel'
import FormGrop from '../../../components/views/FormGrop'
import FormGropWrap from '../../../components/views/FormGropWrap'
import AddListGrop from '../../../components/views/AddListGrop'
import PopWarning from '../../../components/popWarning'
import TableController from '../../../components/table/Table'
import "react-datepicker/dist/react-datepicker.css"

export class Campaign extends React.Component {
	state = {
		userId: '',
		startDate: '',
		addListModel: false,
		typeData: TypeData,
		audienceData: [],
		lineBotMessage: [{ label: '不需要搭配文字', value: 'no' }, { label: '搭配文字訊息', value: 'yes' }],
		actionData: [{ key: 'uri', text: 'uri', value: 'uri' }, { key: 'message', text: 'message', value: 'message' }],
		currentTitle: '',
		currentType: '',
		currentAudience: '',
		currentLineBotMessage: 'no',
		currentTextArea: '',
		currentUrl: '',
		currentButtonModel: false,
		currentLineBotModel: '',
		warning: '',
		MktData: [],
		startDateError: '',
		currentTitleError: '',
		currentTypeError: '',
		currentAudienceError: '',
		currentTextAreaError: '',
		currentUrlError: '',
		currentLineBotModelError: '',
		filterCampaign: [],
		filterTime: +(moment().format('X')),
		activeKey: 'schedule',
		isLoading: false
	}
	componentDidMount() {
		const { auth } = this.context
		this.setState({ userId: auth.user.admin_id })
		this.fetchMktBroadcasting()
		this.fetchAudienceData()
		this.fetchFilterCampaign()
	}
	fetchMktBroadcasting = async () => {
		let conditions = []
		const filter = this.state.activeKey === 'schedule' ? 'fireDate_gte' : 'fireDate_lte'
		conditions.push({ key: 'type', value: 'line' })
		conditions.push({ key: filter, value: +(moment().format('X')) })
		conditions.push({ key: 'limit', value: 100 })
		conditions.push({ key: 'offset', value: 0 })
		conditions.push({ key: 'desc', value: 'id' })
		const data = await getModifyTable2({ table: 'notifications', conditions })
		const response = data.data.data.get.data
		const MktData = []
		response.forEach((item) => {
			item.content = isJosnString(item.content) ? parseContent(item.content) : item.content
			MktData.push(item)
		})
		console.log('MktData', MktData)
		this.setState({ MktData })
	}
	fetchAudienceData = async () => {
		let conditions = []
		conditions.push({ key: 'limit', value: 50 })
		conditions.push({ key: 'offset', value: 0 })
		conditions.push({ key: 'desc', value: 'id' })
		const data = await getModifyTable2({ table: 'notifications_audience', conditions })
		if (data.status !== 200) return
		const result = data.data.data.get.data
		const audienceData = []
		forEach(result, (value, key) => {
			audienceData.push({ value: value.audience })
		})
		this.setState({ audienceData })
	}
	fetchFilterCampaign = async () => {
		const { filterTime } = this.state
		let conditions = []
		conditions.push({ key: 'type', value: 'line' })
		conditions.push({ key: 'fireDate_lte', value: filterTime })
		conditions.push({ key: 'limit', value: 1 })
		conditions.push({ key: 'offset', value: 0 })
		conditions.push({ key: 'desc', value: 'id' })
		const data = await getModifyTable2({ table: 'notifications', conditions })
		if (data.status !== 200) return
		const response = data.data.data.get.data
		const filterCampaign = []
		response.forEach((item) => {
			item.content = isJosnString(item.content) ? parseContent(item.content) : item.content
			filterCampaign.push(item)
		})
		console.log('filterCampaign', filterCampaign)
		this.setState({ filterCampaign })
	}
	handleSimulatorDate = (date) => {
		this.setState({ filterTime: +(moment(+date).format('X')) }, () => {
			this.fetchFilterCampaign()
		})
	}
	toggleAddListModle = () => {
		this.setState({ addListModel: !this.state.addListModel }, () => {
			const { addListModel } = this.state
			if (addListModel) return
			this.setState({
				currentUpdate: null,
				currentTitle: '',
				currentType: '',
				currentAudience: '',
				currentLineBotMessage: 'no',
				currentTextArea: '',
				currentUrl: '',
				currentLineBotModel: '',
				currentAction: null,
				startDate: '',
				startDateError: '',
				currentTitleError: '',
				currentTypeError: '',
				currentAudienceError: '',
				currentTextAreaError: '',
				currentUrlError: '',
				currentLineBotModelError: '',
			})
		})
	}
	handleOnChange = (e, setStateKey) => {
		const value = e.target ? e.target.value : e
		this.setState({ [setStateKey]: value })
	}
  handleAudienceSelect = (value) => {
		this.setState({ currentAudience: value })
	}
	handleTypeSelect = (index) => {
		this.setState({ currentType: TypeData[index].value })
	}
	handleActionSelect = (keyIndex, event, index) => {
		const { currentAction, actionData } = this.state
		currentAction[index].type = actionData[keyIndex].value
		this.setState({ currentAction })
	}
	handleActionText = (e, none, index) => {
		const { value } = e.target
		const { currentAction } = this.state
		if (currentAction[index].type === 'uri') {
			currentAction[index].linkUri = value
		} else {
			currentAction[index].text = value
		}
		this.setState({ currentAction })
	}
	toggleButtonModel = () => {
		this.setState({ currentButtonModel: !this.state.currentButtonModel })
	}
	handleLineBotModel = (e) => {
		const { model } = e.target.dataset
		let currentAction
		this.setState({ currentLineBotModel: model })
		if (model === 'a') {
			currentAction = creactAction(1)
		} else if (model === 'b') {
			currentAction = creactAction(2)
		} else {
			currentAction = creactAction(3)
		}
		this.setState({ currentAction })
	}
	handleDatePicker = (date) => {
		this.setState({ startDate: +(moment(+date).format('X')) })
	}
	handlePostMktBroadcasting = () => {
		const { currentTitle, currentType, currentAudience, startDate, userId, currentUrl, currentAction, currentTextArea, currentUpdate, currentLineBotModel } = this.state
		const object = { currentTitle, currentType, currentAudience, currentUrl, currentAction, startDate, currentLineBotModel }
		forEach(object, (value, key) => !value && this.setState({ [`${key}Error`]: '請填寫或選擇項目' }))
		if (!currentTitle || !currentType || !currentAudience || !currentUrl || !currentAction || !currentLineBotModel) return this.setState({ warning: '欄位不能為空！' })
		const isStop = currentAction.some((item) => {
			return (!has(item, ['linkUri']) && !has(item, ['text'])) || (has(item, ['linkUri']) && !item['linkUri']) || (has(item, ['text']) && !item['text'])
		})
		if (isStop) return this.setState({ warning: '欄位不能為空！' })
		const body = {
			title: currentTitle,
			type: currentType,
			content: `${creatContent(currentTitle, currentUrl, currentAction, currentTextArea)}`,
			audience: currentAudience,
			fireDate: startDate,
			admin_id: userId,
			id: currentUpdate ? currentUpdate.id : undefined
		}
		if (currentUpdate) return this.handlePutMktBroadcasting(body)
		postMktBroadcasting(body).then((data) => {
			if (data.status !== 200) return this.setState({ warning: '新增活動失敗，請稍候再嘗試' })
			this.setState({ warning: '新增活動成功！' }, () => {
				this.toggleAddListModle()
				this.fetchMktBroadcasting()
			})
		})
	}
	handlePutMktBroadcasting = (body) => {
		putMktBroadcasting(body).then((data) => {
			if (data.status !== 200) return this.setState({ warning: '新增活動失敗，請稍候再嘗試' })
			this.setState({ warning: '更新活動成功！' }, () => {
				this.toggleAddListModle()
				this.fetchMktBroadcasting()
			})
		})
	}
	handleUpdate = (data, action) => {
		action === 'copy' && Alert.success('已複製活動', 5000)
		const { title, type, fireDate, audience, content } = data
		const { lineText, baseUrl, model, actions } = content
		const currentAction = []
		!isEmpty(actions) && actions.forEach((item) => {
			const reg = /^((?:\[)*)|((?:\])*)$/g
			const text = item.text.replace(reg, '')
			item.type === 'uri'
				? currentAction.push({ area: item.area, type: item.type, linkUri: text })
				: currentAction.push({ area: item.area, type: item.type, text: text })
		})
		this.setState({
			currentUpdate: action === 'copy' ? null : data,
			currentTitle: title,
			currentType: type,
			startDate: fireDate,
			currentAudience: audience,
			currentLineBotMessage: lineText ? 'yes' : 'no',
			currentTextArea: lineText,
			currentUrl: baseUrl,
			currentLineBotModel: model,
			currentAction: currentAction,
		})
	}
	handleTabOnSelect = (activeKey) => {
		this.setState({ activeKey }, () => this.fetchMktBroadcasting())
	}
	handleOnUpload = async (file) => {
		this.setState({ isLoading: true })
		const formData = new FormData()
		formData.append('file', file.blobFile)
		formData.append('doc_type', 'imageMap')
		const data = await postImage(this.state.userId, formData)
		console.log('upload----', data);
		if (data.status !== 200) return
		this.setState({ isLoading: false })
		// this.handleOnChange(data.data.imag_e_url, 'currentUrl')
	}
	render() {
		const { startDate, addListModel, typeData, audienceData, actionData, currentTitle, currentType, currentAudience, lineBotMessage, activeKey, isLoading,
			currentLineBotMessage, currentTextArea, currentButtonModel, currentLineBotModel, currentAction, currentUrl, warning, MktData, filterCampaign,
			currentUpdate, currentTitleError, currentTypeError, startDateError, currentAudienceError, currentUrlError, currentLineBotModelError } = this.state
		const style = {
			width: '100%',
			paddingLeft: '10px'
    }

		return (
			<>
				<div className="campaign-wrap">
					{warning && <PopWarning warning={warning} textCenter={true} confirm={() => this.setState({ warning: '' })} />}
					{addListModel ? (
						<AddListModel toggleAddListModle={this.toggleAddListModle} >
							<LineBotModel currentStatus={currentButtonModel} currentLineBotModel={currentLineBotModel} handleLineBotModel={this.handleLineBotModel} toggleButtonModel={this.toggleButtonModel} />
							<Breadcrumb>
								<Breadcrumb.Item onClick={this.toggleAddListModle}>Campaign</Breadcrumb.Item>
								<Breadcrumb.Item active>Add Campaign</Breadcrumb.Item>
							</Breadcrumb>
							<AddListGrop title={'新增活動'}>
								<FormGrop title={'標題'} placeholder={'Title'} type={'input'} value={currentTitle} handleOnChange={this.handleOnChange} setStateKey={'currentTitle'} errorMessage={currentTitleError} />
								<FormGropWrap>
									<FormGrop title={'活動類型'} placeholder={'Select Type'} type={'select-button'} options={typeData} handleOnChange={this.handleTypeSelect} value={currentType} errorMessage={currentTypeError} />
									<FormGrop title={'開始時間'} placeholder={'Start Time'} type={'children'} errorMessage={startDateError}>
										<DatePicker
											defaultValue={startDate ? new Date(startDate * 1000) : undefined}
											onOk={this.handleDatePicker}
											placement={'bottomStart'}
											appearance={'subtle'}
											placeholder={'選擇日期和時間'}
											isoWeek
											hideMinutes={second => second % 30 !== 0}
											format={'YYYY-MM-DD HH:mm'}
											ranges={[{ value: new Date(), closeOverlay: true }]} />
									</FormGrop>
								</FormGropWrap>
								<FormGrop title={'推播受眾'} placeholder={'Select Audience'} type={'input-picker'} options={audienceData} handleOnChange={this.handleAudienceSelect} value={currentAudience} errorMessage={currentAudienceError} />
							</AddListGrop>
							<AddListGrop title={'新增模板'}>
								<FormGrop title={'LINE BOT 文字訊息'} placeholder={'Title'} type={'radio'} currentValue={currentLineBotMessage} options={lineBotMessage} handleOnChange={this.handleOnChange} setStateKey={'currentLineBotMessage'} />
								<Animation.Collapse in={currentLineBotMessage === 'yes'}>
									{(props, ref) => (
										<Collapse {...props} ref={ref}>
											<FormGrop title={'文字訊息'} placeholder={'請填寫訊息'} type={'textarea'} value={currentTextArea} handleOnChange={this.handleOnChange} setStateKey={'currentTextArea'} />
										</Collapse>
									)}
								</Animation.Collapse>
								<FormGrop title={'圖片網址'} placeholder={'Image url'} type={'input'} handleOnChange={this.handleOnChange} value={currentUrl} setStateKey={'currentUrl'} errorMessage={currentUrlError} />
								{/* <FormGrop type={'uploader'} multiple={false} handleOnUpload={this.handleOnUpload} isLoading={isLoading} /> */}
								<FormGrop title={'版型'} placeholder={currentLineBotModel ? `選擇版型 ${currentLineBotModel}` : '點擊選擇版型'} type={'button'} icon={'web'} handleOnChange={this.toggleButtonModel} errorMessage={currentLineBotModelError} />
								{currentLineBotModel && (
									<FormGrop title={`版型${currentLineBotModel} Demo`} type={'children'}>
										<ModelDemo currentLineBotModel={currentLineBotModel} />
									</FormGrop>
								)}
								{currentAction && currentAction.map((item, index) => (
									<FormGropWrap key={index} warningText={item.type === 'message' && '選擇 Message 模式請記得告知工程部門'} >
										<FormGrop index={index} title={`Action ${index + 1}`} placeholder={'Select Action'} type={'select-button'} options={actionData} icon={'angle down'} handleOnChange={this.handleActionSelect} value={item.type} errorMessage={!item.type && '請選擇項目'} />
										{item.type && <FormGrop title={`Action ${index + 1} ${item.type}`} placeholder={item.type} type={'input'} value={item.type === 'message' ? item.text || '' : item.linkUri || ''} index={index} handleOnChange={this.handleActionText} />}
									</FormGropWrap>
								))}
							</AddListGrop>
							<div className="campaign-button-wrap">
								<button className="campaign-confirm-button" onClick={this.handlePostMktBroadcasting}>{currentUpdate ? '更新' : '新增'}</button>
								<button className="campaign-cancel-button" onClick={this.toggleAddListModle}>取消</button>
							</div>
						</AddListModel>
					) : (
							<div className="campaign-content">
								<ListWrap status={'分眾推播'} addListText={'+ 新增活動'} onClick={this.toggleAddListModle}>
									<Nav appearance="tabs" activeKey={activeKey} onSelect={this.handleTabOnSelect} style={style} >
										<Nav.Item eventKey="schedule">排程中</Nav.Item>
										<Nav.Item eventKey="sent">已推播</Nav.Item>
									</Nav>
								</ListWrap>
								<TableController data={MktData} topHeight={104}>
									<Column minWidth={50} flexGrow={0.5} align="center" fixed>
										<HeaderCell>Id</HeaderCell>
										<Cell dataKey="id" />
									</Column>
									<Column minWidth={80} flexGrow={0.8} align="center">
										<HeaderCell>Type</HeaderCell>
										<Cell dataKey="type" />
									</Column>
									<Column minWidth={250} flexGrow={3}>
										<HeaderCell>Title</HeaderCell>
										<TitleCell dataKey="title" />
									</Column>
									<Column minWidth={80} flexGrow={1.5}>
										<HeaderCell>Audience</HeaderCell>
										<Cell dataKey="audience" />
									</Column>
									<Column minWidth={150} flexGrow={1.5}>
										<HeaderCell>Launch Date</HeaderCell>
										<Cell>
											{(rowData) => moment(rowData.fireDate * 1000).format('YYYY-MM-DD HH:mm')}
										</Cell>
									</Column>
									<Column width={80} align="center">
										<HeaderCell>Content</HeaderCell>
										<ModelCell dataKey="content" />
									</Column>
									<Column width={180}>
										<HeaderCell>Action</HeaderCell>
										<ActionCell dataKey="id" fetchData={this.fetchMktBroadcasting} toogleModle={this.toggleAddListModle} handleUpdate={this.handleUpdate} />
									</Column>
								</TableController>
							</div>
						)}
					<Simulator addListModel={addListModel} currentUrl={currentUrl} currentAction={currentAction} filterCampaign={filterCampaign} handleSimulatorDate={this.handleSimulatorDate} />
				</div>
			</>
		)
	}
}

Campaign.contextType = ContextStore
export default Campaign
