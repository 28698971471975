import React from 'react'
import styled from 'styled-components'
import { Tag } from 'rsuite'

const getTagInfo = (type) => {
  switch (type) {
    case 'normal':
      return {
        name: '一般活動',
        color: 'orange'
      }
    case 'shuttle':
      return {
        name: '機場接送',
        color: 'blue'
      }
    case 'first':
      return {
        name: '首次乘車',
        color: 'green'
      }
    default:
      return null
  }
}

//style
const TagWrap = styled(Tag)`
  margin-right: 4px;
`

export const TagItem = ({type}) => {
  const tagInfo = getTagInfo(type);
  return (
      <TagWrap color={tagInfo.color}>{tagInfo.name}</TagWrap>
  )
}

export default TagItem