/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react'
import { DatePicker, Alert } from 'rsuite'
import { isEmpty } from 'lodash'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import { SearchInput, TableController } from '../../components/table'
import { StatusCell } from '../../components/finance'
import { getFinancePayment, addFinancePayment, deleteFinancePayment } from '../../api'
import { ListWrap, AddListModel, FormGrop, AddListGrop, BreadcrumbNav } from '../../components/views'
import { WhisperCell, DateCell, ActionCell } from '../../components/table/cell'
import PopWarning from '../../components/popWarning'
import usePageSetting from '../../hook/usePageSetting'

const initialState = {
  driver_id: 0,
  amount: 0,
  date: '',
  description: '',
  idError: '',
  amountError: '',
  descriptionError: '',
  dateError: '',
}

const FinanceOffset = () => {
  const [searchWord, setSearchWord] = useState(null)
  const { isAddMode, list, currentInfo, warning, toggleAddMode, handleDatePicker, handleOnChange, setList, setCurrentInfo, setWarning } =
    usePageSetting(initialState)

  const handleSubmit = async () => {
    const { driver_id, amount, date, description } = currentInfo
    setCurrentInfo({
      ...currentInfo,
      idError: !driver_id ? '請填寫或選擇項目' : '',
      amountError: amount === 0 ? '請填寫或選擇項目' : '',
      dateError: !date ? '請填寫或選擇項目' : '',
      descriptionError: !description ? '請填寫或選擇項目' : '',
    })
    if (!driver_id || !amount || !date || amount === 0) return setWarning('欄位不能為空！')

    // call api
    const body = {
      driver_id,
      amount,
      description,
      created_time: date,
    }
    const response = await addFinancePayment(driver_id, body)
    if (response.status !== 200) return setWarning(response.data.msg)
    setWarning('新增成功！')
    setSearchWord(driver_id)
    toggleAddMode()
  }

  const fetchDriverPayments = async () => {
    const response = await getFinancePayment(searchWord)
    if (response.status !== 200) return Alert.error(response.data.msg)
    const { data } = response
    if (isEmpty(data)) Alert.error('未搜尋到任何紀錄')
    setList(data)
  }

  const handleDeletePayment = async (id, rowData) => {
    const { status } = rowData
    if (status === 'CANCELLED') return Alert.warning('撥款目前已取消')
    const response = await deleteFinancePayment(id)
    if (response.status !== 200) return Alert.error(response.data.msg)
    Alert.success('刪除成功')
    fetchDriverPayments()
  }

  useEffect(() => {
    isEmpty(searchWord) ? Alert.warning('請輸入搜尋 ID') : fetchDriverPayments()
  }, [searchWord])

  const { driver_id, amount, date, description, idError, amountError, dateError, descriptionError } = currentInfo
  return (
    <>
      {warning && <PopWarning warning={warning} textCenter confirm={() => setWarning('')} />}
      {isAddMode ? (
        <AddListModel>
          <BreadcrumbNav start="會計沖賬" entry="新增紀錄" onClick={toggleAddMode} />
          <AddListGrop title="新增紀錄">
            <FormGrop
              title="司機 ID*"
              placeholder="DID"
              type="number"
              value={driver_id}
              handleOnChange={handleOnChange}
              setStateKey="driver_id"
              errorMessage={idError}
            />
            <FormGrop
              title="撥款描述*"
              placeholder="Description"
              type="input"
              value={description}
              handleOnChange={handleOnChange}
              setStateKey="description"
              errorMessage={descriptionError}
            />
            <FormGrop
              title="金額*"
              placeholder="amount"
              type="number"
              value={amount}
              handleOnChange={handleOnChange}
              setStateKey="amount"
              errorMessage={amountError}
            />
            <FormGrop title="撥款日期*" type="children" errorMessage={dateError}>
              <DatePicker
                defaultValue={date ? new Date(date * 1000) : null}
                onOk={(date) => handleDatePicker(date, 'date')}
                placement="bottomStart"
                appearance="subtle"
                placeholder="選擇日期時間"
                isoWeek
                hideMinutes={(second) => second % 5 !== 0}
                format="YYYY-MM-DD"
                ranges={[{ value: new Date(), closeOverlay: true }]}
              />
            </FormGrop>
          </AddListGrop>
          <div className="campaign-button-wrap">
            <button type="button" className="campaign-confirm-button" onClick={handleSubmit}>
              送出
            </button>
          </div>
        </AddListModel>
      ) : (
        <>
          <ListWrap status="會計沖賬" addListText="+ 新增紀錄" onClick={toggleAddMode}>
            <SearchInput setSearchWord={setSearchWord} title="輸入欲搜尋的司機 ID" />
          </ListWrap>
          <TableController data={list} topHeight={104}>
            <Column flexGrow={1.5} align="center">
              <HeaderCell>ID</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column flexGrow={2} align="center">
              <HeaderCell>撥款狀態</HeaderCell>
              <StatusCell dataKey="status" />
            </Column>
            <Column flexGrow={2} align="center">
              <HeaderCell>項目</HeaderCell>
              <WhisperCell dataKey="description" />
            </Column>
            <Column flexGrow={1} align="center">
              <HeaderCell>金額</HeaderCell>
              <Cell dataKey="amount" />
            </Column>
            <Column flexGrow={2.5} align="center">
              <HeaderCell>更新時間</HeaderCell>
              <DateCell dataKey="last_update" format="YYYY-MM-DD HH:mm" />
            </Column>
            <Column flexGrow={2.5} align="center">
              <HeaderCell>預計撥款日期</HeaderCell>
              <DateCell dataKey="created_time" format="YYYY-MM-DD HH:mm" />
            </Column>
            <Column width={100} align="center">
              <HeaderCell>Action</HeaderCell>
              <ActionCell dataKey="id" noCopy handleDelete={handleDeletePayment} />
            </Column>
          </TableController>
        </>
      )}
    </>
  )
}

export default FinanceOffset
