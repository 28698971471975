import React from 'react'
import { ContextStore } from '../../../reducers'
import Carousel from '../../../components/advertisement/carousel'
import Banner from '../../../components/advertisement/banner'
import Card from '../../../components/advertisement/card'
import Broadcast from '../../../components/advertisement/broadcast/index'
import BotNotification from '../../../components/advertisement/botNotification/index'
import Tab from '../../../components/tab'
import PopWarning from '../../../components/popWarning'

const tabs = [
	{ tab: 'CAROUSEL', title: 'Web五張輪播', table: 'ads_carousel', state: 'carousel' },
	// { tab: 'BANNER', title: 'Banner', table: 'ads_banner', state: 'banner' },
	{ tab: 'CARD', title: 'Web小卡', table: 'ads_cards', state: 'cards' },
	{ tab: 'BROADCAST', title: 'Broadcast', table: 'user_broadcast', state: 'broadcast' },
	{ tab: 'BOT NOTIFICATION', title: '行程間Carousel', table: 'marketing_events', state: 'botNotification' }
]

class Advertisement extends React.Component {
	state = {
		userId: '',
		warning: undefined,
		currentTab: tabs[0],
	}

	componentDidMount() {
		const { auth } = this.context
		this.setState({ userId: auth.user.admin_id })
	}

	handleTab = (currentTab) => {
		this.setState({ currentTab }, () => {
		})
	}

	handlePopWarning = (warning) => {
		this.setState({ warning })
	}

	render() {
		const { userId, currentTab, warning } = this.state
		return (
			<div className="advertisement-main">
				{warning && <PopWarning warning={warning.text} textCenter={true} confirm={warning.confirm} cancel={warning.cancel} isCancel={warning.isCancel} />}
				<Tab tabs={tabs} currentTab={currentTab} handleTab={this.handleTab} />
				{currentTab.tab === 'CAROUSEL' && <Carousel userId={userId} currentTab={currentTab} fetchTable={this.fetchTable} handlePopWarning={this.handlePopWarning} />}
				{/* {currentTab.tab === 'BANNER' && <Banner userId={userId} currentTab={currentTab} fetchTable={this.fetchTable} handlePopWarning={this.handlePopWarning} />} */}
				{currentTab.tab === 'CARD' && <Card userId={userId} currentTab={currentTab} fetchTable={this.fetchTable} handlePopWarning={this.handlePopWarning} />}
				{currentTab.tab === 'BROADCAST' && <Broadcast handlePopWarning={this.handlePopWarning} />}
				{currentTab.tab === 'BOT NOTIFICATION' && <BotNotification handlePopWarning={this.handlePopWarning} />}
			</div>
		)
	}
}

Advertisement.contextType = ContextStore
export default Advertisement
