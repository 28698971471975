import React, { useEffect, useState } from 'react'
import { TagPicker, Alert } from 'rsuite'
import { getIdentifiersItems } from '../../api'

const IdentifiersPicker = React.memo(({ selectedItems, setSelectedItems, width = 300 }) => {
  const [items, setItems] = useState([])
  const [itemsMap, setItemsMap] = useState(new Map())

  useEffect(() => {
    const getItems = async () => {
      try {
        const { data: { data } = {} } = await getIdentifiersItems()
        setItems(data.map((v) => ({ label: v.name, value: v.id })))
        setItemsMap(
          data
            .map((v) => ({ ...v, amount: 1, action: '' }))
            .reduce((accu, curr) => {
              accu.set(curr.id, curr)
              return accu
            }, new Map()),
        )
      } catch (error) {
        Alert.error(error?.message || 'fetch error')
      }
    }
    getItems()
  }, [])

  const handleOnChange = (values) => {
    const selected = selectedItems.reduce((accu, curr) => {
      accu.set(curr.id, curr)
      return accu
    }, new Map())
    values = values.map((v) => itemsMap.get(v))
    for (const value of values) {
      const oldValue = selected.get(value.id)
      if (oldValue) {
        value.action = oldValue.action
        value.amount = oldValue.amount
      }
    }

    setSelectedItems(values)
  }

  return (
    <TagPicker
      data={items}
      value={selectedItems.map((v) => v.id)}
      onChange={handleOnChange}
      placeholder="識別物項目"
      cleanable={false}
      placement={'top'}
      style={{ width }}
    />
  )
})

export default IdentifiersPicker
