import React, { useCallback } from 'react'
import { ControlLabel, DatePicker, FormControl, FormGroup } from 'rsuite'
import { CustomForm } from './index.style'
import { useMGMConfig } from '../provider'

function StepInfo() {
  const { updateValue, value } = useMGMConfig()

  const handleChange = useCallback(
    (name) => (value) => {
      updateValue(name, value)
    },
    [updateValue],
  )

  return (
    <CustomForm>
      <FormGroup>
        <ControlLabel>活動代碼</ControlLabel>
        <FormControl onChange={handleChange('code')} value={value.code} />
      </FormGroup>
      <FormGroup>
        <ControlLabel>活動標題</ControlLabel>
        <FormControl onChange={handleChange('campaign_name')} value={value.campaign_name} />
      </FormGroup>
      <FormGroup>
        <ControlLabel>起始時間</ControlLabel>
        <FormControl
          name="start_time"
          accepter={DatePicker}
          format="YYYY-MM-DD HH:mm:ss"
          ranges={[
            {
              label: 'Now',
              value: new Date(),
            },
          ]}
          onChange={handleChange('start_time')}
          value={new Date(value.start_time * 1000)}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>結束時間</ControlLabel>
        <FormControl
          name="end_time"
          accepter={DatePicker}
          format="YYYY-MM-DD HH:mm:ss"
          ranges={[
            {
              label: 'Now',
              value: new Date(),
            },
          ]}
          onChange={handleChange('end_time')}
          value={new Date(value.end_time * 1000)}
        />
      </FormGroup>
    </CustomForm>
  )
}

export default StepInfo
