import React, { useCallback, useState } from 'react'
import { Button } from 'rsuite'
import PanelCoupon from './PanelCoupon'
import PanelBotCard from './PanelBotCard'
import PanelFirstAward from './PanelFirstAward'
import PanelInfo from './PanelInfo'
import PanelMainPage from './PanelMainPage'
import PanelVisitorPage from './PanelVisitorPage'
import { Footer, FormRoot, Panel } from './styles'
import Tabs, { MGM_FORM_TABS } from './Tabs'
import PanelTheme from './PanelTheme'
import PanelPreview from './PanelPreview'

const MGMForm = () => {
  const [currentTab, setCurrentTab] = useState(MGM_FORM_TABS.INFO)

  const handleTabChange = useCallback(
    (item) => () => {
      setCurrentTab(item)
    },
    [],
  )

  const renderPanel = useCallback(() => {
    switch (currentTab.key) {
      case MGM_FORM_TABS.INFO.key:
        return <PanelInfo />
      case MGM_FORM_TABS.MAIN_PAGE.key:
        return <PanelMainPage />
      case MGM_FORM_TABS.VISITOR_PAGE.key:
        return <PanelVisitorPage />
      case MGM_FORM_TABS.COUPON.key:
        return <PanelCoupon />
      case MGM_FORM_TABS.FIRST_AWARD.key:
        return <PanelFirstAward />
      case MGM_FORM_TABS.BOT_CARD.key:
        return <PanelBotCard />
      case MGM_FORM_TABS.THEME.key:
        return <PanelTheme />
      case MGM_FORM_TABS.PREVIEW.key:
        return <PanelPreview />
      default:
        return <></>
    }
  }, [currentTab])

  return (
    <FormRoot>
      <Tabs currentTab={currentTab} onTabChange={handleTabChange} />
      <Panel>{renderPanel()}</Panel>
      <Footer>
        <Button appearance="ghost">取消</Button>
        <Button appearance="primary">儲存</Button>
      </Footer>
    </FormRoot>
  )
}

export default MGMForm
