import React from 'react'
import { filter, find } from 'lodash'
import { Checkbox } from 'rsuite'

const optionType = [
  { id: 1, name: 'UID', value: 'uid' },
  { id: 2, name: '電話', value: 'phone_number' },
  { id: 3, name: 'Line ID', value: 'line_id' },
  { id: 4, name: 'Email', value: 'email' },
]

const getOptionName = (value) => {
  const { name } = find(optionType, { value })
  return name
}

const CheckboxSection = ({ options, setOptions }) => {
  const checkedList = filter(options, { isChecked: true })

  const handleAllOptions = (v, checked) => {
    setOptions([
      { id: 1, value: 'uid', isChecked: checked },
      { id: 2, value: 'phone_number', isChecked: checked },
      { id: 3, value: 'line_id', isChecked: checked },
      { id: 4, value: 'email', isChecked: checked },
    ])
  }

  const handleCheckOption = (value, checked) => {
    const newOptions = options.map((option) => {
      if (option.value !== value) return option
      const newOption = option
      newOption.isChecked = checked
      return newOption
    })
    setOptions(newOptions)
  }

  return (
    <div style={{ width: '100%', borderRadius: '4px' }}>
      <Checkbox style={{ borderBottom: '1px solid #eeeeee' }} checked={checkedList.length === 4} onChange={handleAllOptions}>
        全選
      </Checkbox>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {options.map((option) => {
          return (
            <Checkbox key={option.id} value={option.value} checked={option.isChecked} onChange={handleCheckOption}>
              {getOptionName(option.value)}
            </Checkbox>
          )
        })}
      </div>
    </div>
  )
}

export default React.memo(CheckboxSection)
