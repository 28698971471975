import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Alert, Button, Input, DatePicker, Modal, Toggle, Panel, Tag } from 'rsuite'
import { Heading, Wrapper } from '../receipt/styles'
import { FormGroup } from '../../components/blocked_history/styles'
import TemplatePicker from '../../components/surge_price_multiplier_tests/TemplatePicker'
import { createSurgePriceMultiplierTests } from '../../api'


const NewSurgePriceMultiplierTests = () => {
  const [loading, setLoading] = useState(false)
  const [displayName, setDisplayName] = useState('')
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [gridQuery, setGridQuery] = useState('')
  const [template, setTemplate] = useState({})
  const [file, setFile] = useState(null)
  const [active, setActive] = useState(false)
  const [show, setShow] = useState(false)
  const history = useHistory()

  const handleStartTimeSelect = (date) => setStartTime(dayjs(date).unix())
  const handleEndTimeSelect = (date) => setEndTime(dayjs(date).unix())

  const handleSelectTemplate = (_, item) => {
    setTemplate(item)
  }

  const handleChangeFile = (e) => {
    const file = e.target.files[0]
    if (file) setFile(file)
  }

  const confirm = () => setShow(true)
  const close = () => setShow(false)

  const readyToSubmit = () => {
    if (!template.id) return false
    if (template.destination_table_prefix && !file) return false

    return displayName && startTime && endTime && gridQuery
  }

  const submit = async () => {
    const formData = new FormData()
    formData.append('uploaded_file', file)
    formData.append('display_name', displayName)
    formData.append('start_time', startTime)
    formData.append('end_time', endTime)
    formData.append('grid_query', gridQuery)
    formData.append('template_id', template.id)
    formData.append('active', active ? 1 : 0)

    setLoading(true)
    const response = await createSurgePriceMultiplierTests(formData)
    setLoading(false)
    setFile(null)
    if (response.status !== 200) return Alert.error(response.data.message)
    Alert.success('新增成功')
    history.push('/surge_price_multiplier_tests')
  }

  return (
    <Wrapper>
      <Heading style={{ lineHeight: '2em' }}>新增浮動倍率測試</Heading>
      <Panel bordered bodyFill style={{ backgroundColor: '#fff', padding: '20px', maxWidth: 600, color: '#666' }}>
        <FormGroup>
          <FormGroup.Label>名稱</FormGroup.Label>
          <FormGroup.Control>
            <Input style={{ width: 250 }} value={displayName} onChange={setDisplayName} placeholder="輸入名稱" />
          </FormGroup.Control>
        </FormGroup>
        <FormGroup>
          <FormGroup.Label>開始時間</FormGroup.Label>
          <FormGroup.Control>
            <DatePicker
              format="YYYY-MM-DD HH:mm"
              placeholder="選擇日期與時間"
              onSelect={handleStartTimeSelect}
              cleanable={false}
              value={startTime ? new Date(startTime * 1000) : null}
              style={{ width: 200 }}
            />
          </FormGroup.Control>
        </FormGroup>
        <FormGroup>
          <FormGroup.Label>結束時間</FormGroup.Label>
          <FormGroup.Control>
            <DatePicker
              format="YYYY-MM-DD HH:mm"
              placeholder="選擇日期與時間"
              onSelect={handleEndTimeSelect}
              cleanable={false}
              value={endTime ? new Date(endTime * 1000) : null}
              style={{ width: 200 }}
            />
          </FormGroup.Control>
        </FormGroup>
        <FormGroup>
          <FormGroup.Label>網格查詢</FormGroup.Label>
          <FormGroup.Control>
            <Input value={gridQuery} style={{ width: 400 }} onChange={setGridQuery} componentClass="textarea" rows={3} placeholder="網格查詢" />
          </FormGroup.Control>
        </FormGroup>
        <FormGroup>
          <FormGroup.Label>測試樣板</FormGroup.Label>
          <FormGroup.Control>
            <TemplatePicker value={template.id} setValue={handleSelectTemplate} />
          </FormGroup.Control>
        </FormGroup>
        <FormGroup>
          <FormGroup.Label>檔案格式</FormGroup.Label>
          <FormGroup.Control>
            <Tag style={{ marginTop: 4 }}>
              { template.destination_table_schema || 'N/A' }
            </Tag>
          </FormGroup.Control>
        </FormGroup>
        <FormGroup>
          <FormGroup.Label>上傳檔案</FormGroup.Label>
          <FormGroup.Control>
            <input type="file" style={{ height: '100%', padding: 2, paddingTop: 4 }} onChange={handleChangeFile}/>
          </FormGroup.Control>
        </FormGroup>
        <FormGroup>
          <FormGroup.Label>啟用</FormGroup.Label>
          <FormGroup.Control>
            <Toggle style={{ marginTop: 6 }} value={active} onChange={setActive} />
          </FormGroup.Control>
        </FormGroup>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 30, marginBottom: 20 }}>
          <Button onClick={confirm} appearance="primary" disabled={!readyToSubmit()}>
            送出
          </Button>
          <Button style={{ marginLeft: 20 }} onClick={() => history.push('/surge_price_multiplier_tests')} appearance="subtle">
            返回
          </Button>
        </div>
      </Panel>
      <Modal backdrop="static" show={show} onHide={close} size="xs">
        <Modal.Body>
          確定送出?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={submit} appearance="primary" loading={loading}>
            確定
          </Button>
          <Button onClick={close} appearance="subtle">
            取消
          </Button>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  )
}

export default NewSurgePriceMultiplierTests
