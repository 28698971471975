import React, { useEffect, useState } from 'react'
import { SelectPicker, Alert } from 'rsuite'
import { getModifyTable2 } from '../../api'

const TemplatePicker = ({ value, setValue }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await getModifyTable2({ table: 'surge_price_multiplier_tests_templates', conditions: [{ key: 'limit', value: 100 }] })
        setData(result.data.data.get.data.map((v) => ({ ...v, label: v.name, value: v.id })))
      } catch (error) {
        Alert.error(error?.message || 'fetch surge price multiplier test templates error')
      }
    }
    fetch()
  }, [])

  return <SelectPicker data={data} value={value} placement="topStart" onSelect={setValue} cleanable={false} style={{ width: 250 }} placeholder="選取樣板" />
}

export default React.memo(TemplatePicker)
