import React from 'react'
import { ContextStore } from '../reducers'
import { ACTION } from '../constants/actions';
import { getUser } from '../api'

class AuthListener extends React.Component {
	componentDidMount() {
		const { authDispatch } = this.context

		getUser().then((data) => {
			console.log('user', data)
			const user = data.data.error ? null : data.data
			// if (user.status === 200) {
			// authDispatch({ type: ACTION.SET_CHECK_LOGIN_STATUS, logingStatus: user ? 'SUCCESS' : 'FAILURE' })
			// authDispatch({ type: ACTION.SET_USER_AUTHENTICATED, isLogin: user ? true : false })
			authDispatch({ type: ACTION.SET_USER, user })
			// }
			authDispatch({ type: ACTION.LOADING, loading: false })
		})

	}
	render() {
		return this.props.children
	}
}

AuthListener.contextType = ContextStore
export default AuthListener
