import React from 'react'
import { Popover, Whisper } from 'rsuite'
import { Cell } from 'rsuite-table'

const ButtonCell = ({ rowData, dataKey, ...props }) => {
	const PopoverWording = rowData[dataKey].length ? rowData[dataKey].map(key => {
		return (<p key={rowData.id}><strong>連結: </strong>{key.action.uri}<br></br><strong>文字: </strong>{key.action.label}</p>)
	}) : (<p key={rowData.id}><strong>文字: </strong>{rowData[dataKey].label}</p>)

	const speaker = (
		<Popover title="Button">
			<div className="description-wrap">
				{PopoverWording}
			</div>
		</Popover>
	)

	const buttonLabel = rowData[dataKey].length ? rowData[dataKey].map(key => {
		return `${key.action.label}`
	}) : `${rowData[dataKey].label}`

	return (
		<Cell {...props}>
			<Whisper placement="top" speaker={speaker}>
				<div>{buttonLabel}</div>
			</Whisper>
		</Cell>
	)
}

export default ButtonCell
