import styled from 'styled-components'

export const ListWrapper = styled.div`
  margin-bottom: 24px;
  & > button {
    margin-left: 4px;
  }
  &.flex {
    display: flex;
    justify-content: space-between;
  }
`
export const ListTitleWrapper = styled.div`
  margin-right: 30px;
  color: #797979;
  display: flex;
`
export const ListTitle = styled.div`
  font-size: 14px;
  flex-shrink: 0;
  margin-bottom: 2px;
  position: relative;
  ${(props) =>
    props.$require &&
    `
    &::after {
    content: '*';
    color: #F44336;
  }
  `}
  & .error-message .rs-error-message {
    top: -4px;
    background-color: #fde9ef;
    color: black;
    & .rs-error-message-arrow::after {
      border-color: #fde9ef;
    }
  }
`
