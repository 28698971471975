import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Button, Icon, Input, InputGroup, Loader, Panel } from 'rsuite'
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'
import ReceiptForm from '../../components/receipt/ReceiptForm'
import ConfirmModal from '../../components/receipt/ConfirmModal'
import { ReceiptWrapper, Heading, Wrapper } from './styles'
import { StyledPageButton } from '../dama/styles'
import { receiptLogs, putReceipt, sendReceipt } from '../../api'

const TABLE_COLUMNS = [
  { label: 'ID', dataKey: 'id', width: 80 },
  { label: 'Trip ID', dataKey: 'trip_id', width: 100 },
  { label: '操作人員', dataKey: 'operator', width: 150 },
  { label: '變更前', dataKey: 'before', width: 300, format: (v) => jsonFormat(v) },
  { label: '變更後', dataKey: 'after', width: 300, format: (v) => jsonFormat(v) },
  { label: '變更時間', dataKey: 'created_at', width: 150, format: (v) => dateFormat(v) },
]

const jsonFormat = (v) => JSON.stringify(v, null, 2)
const dateFormat = (v) => dayjs(v).format('YYYY-MM-DD HH:mm')

const Receipt = () => {
  const { trip_id = '' } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [tripId, setTripId] = useState(trip_id)
  const [receipt, setReceipt] = useState(null)
  const [before, setBefore] = useState({})
  const [logs, setLogs] = useState([])
  const [after, setAfter] = useState(null)
  const [popup, setPopup] = useState(false)
  const [preview, setPreview] = useState([])
  const [page, setPage] = useState(0)
  const limit = 5
  const history = useHistory()

  useEffect(() => {
    fetchReceipt(tripId)
  }, [])

  useEffect(() => {
    if (page < 0) return
    fetchLogs()
  }, [page])

  const handleSearchInputChange = (value) => setTripId(value)
  const handleSearchClick = async () => {
    history.push(`/cs/receipt/${tripId}`)
  }

  const fetchReceipt = async (tripId) => {
    if (!tripId) return

    setIsLoading(true)
    const { data: { text, replacements } = {}, status } = await sendReceipt({ trip_id: tripId, dryRun: true })
    setIsLoading(false)
    if (status !== 200) {
      Alert.error('receipt info fetch failed')
      return
    }

    if (!text) {
      setReceipt(null)
      setBefore({})
      Alert.error('查無此乘車收據')
      return
    }

    setReceipt(text)
    setBefore(replacements)
  }

  const fetchLogs = async () => {
    const params = { limit, offset: page * limit }
    const {
      data: { data },
      status,
    } = await receiptLogs(params)
    if (status !== 200) {
      Alert.error('receipt logs fetch failed')
      return
    }

    setLogs(data)
  }

  const handleSearch = async () => {
    await fetchReceipt(tripId)
    page === 0 ? await fetchLogs() : setPage(0)
  }

  const handlePopup = (payload) => {
    setPreview(formatPreview(payload))
    setAfter(payload)
    setPopup(true)
  }

  const formatPreview = (payload) => {
    const _preview = []
    for (const [key, value] of Object.entries(payload)) {
      _preview.push({ key, before: before[key], after: value })
    }
    return _preview
  }

  const submit = async () => {
    const { data: { data, message } = {}, status } = await putReceipt(tripId, {
      before,
      after,
    })
    if (status !== 200) {
      Alert.error(message || 'update receipt failed')
      return
    }
    data?.changedRows ? Alert.success('更新成功') : Alert.info('資料未變更')
    await handleSearch()
    setPopup(false)
  }

  return (
    <Wrapper>
      <Heading>乘車收據</Heading>
      <div style={{ display: 'flex', columnGap: 12, alignItems: 'center' }}>
        <InputGroup style={{ width: 200 }}>
          <Input value={tripId} placeholder="搜尋行程 TID" onChange={handleSearchInputChange} onPressEnter={handleSearchClick} />
          <Button onClick={handleSearchClick} disabled={!tripId || isLoading}>
            <Icon icon="search" />
          </Button>
        </InputGroup>
        {isLoading && <Loader />}
      </div>
      {receipt && (
        <div style={{ display: 'flex', justifyContent: 'flex-start', columnGap: 50, marginBottom: 30 }}>
          <Panel shaded bodyFill style={{ display: 'inline-block', width: 380 }}>
            <ReceiptWrapper style={{ zoom: 0.9, backgroundColor: '#fff' }} dangerouslySetInnerHTML={{ __html: receipt }} />
          </Panel>
          <ReceiptForm before={before} submit={handlePopup} />
        </div>
      )}
      <Table style={{ minWidth: 400 }} minHeight={450} autoHeight data={logs} loading={isLoading} bordered wordWrap>
        {TABLE_COLUMNS.map((v) => (
          <Column key={v.dataKey} width={v.width} align={v.align} verticalAlign="middle">
            <HeaderCell>{v.label}</HeaderCell>
            <Cell>
              {(rowData) => {
                if (v.dataKey === 'trip_id') {
                  return (
                    <Button appearance="link" onClick={() => history.push(`/cs/receipt/${rowData[v.dataKey]}`)}>
                      {rowData[v.dataKey]}
                    </Button>
                  )
                }
                if (['before', 'after'].includes(v.dataKey)) {
                  return <pre>{v.format(rowData[v.dataKey])}</pre>
                }
                return (
                  <span style={{ wordBreak: 'break-word', ...(v.style && { ...v.style }) }}>
                    {v.format ? v.format(rowData[v.dataKey], rowData) : rowData[v.dataKey]}
                  </span>
                )
              }}
            </Cell>
          </Column>
        ))}
      </Table>
      <div style={{ display: 'flex', marginBottom: 30 }}>
        <StyledPageButton disabled={page === 0} appearance="ghost" onClick={() => setPage(page - 1)}>
          上一頁
        </StyledPageButton>
        <StyledPageButton disabled={logs.length < limit} appearance="ghost" onClick={() => setPage(page + 1)}>
          下一頁
        </StyledPageButton>
      </div>
      <ConfirmModal show={popup} close={setPopup.bind(null, false)} confirm={submit} preview={preview} />
    </Wrapper>
  )
}

export default Receipt
