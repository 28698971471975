import { useCallback, useState } from 'react'

function useDisclosure(initialState = false, callbacks) {
  const { onOpen, onClose } = callbacks || {}
  const [opened, setOpened] = useState(initialState)

  const open = useCallback(() => {
    setOpened((isOpened) => {
      if (!isOpened) {
        onOpen && onOpen()
        return true
      }
      return isOpened
    })
  }, [onOpen])

  const close = useCallback(() => {
    setOpened((isOpened) => {
      if (isOpened) {
        onClose && onClose()
        return false
      }
      return isOpened
    })
  }, [onClose])

  const toggle = useCallback(() => {
    opened ? close() : open()
  }, [close, open, opened])

  return [opened, { open, close, toggle }]
}

export default useDisclosure
