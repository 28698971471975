import React, { useMemo } from 'react'
import { isArray } from 'lodash'
import { InputNumber, TagPicker } from 'rsuite'
import { DropdownGroup } from '../../../components/views'

const GeneSelector = ({ geneType, type, optionData, parameters, handleGeneSelect, property, disabled }) => {
  const placeholder = useMemo(
    // 月健康度、月機率：輸入提示比例數值
    () => (['HEALTHINESS', 'PROBABILITY'].includes(geneType) ? '請輸入篩選比例數值，輸入0.1=10%' : '請輸入趟次'),
    [geneType],
  )
  const value = parameters[property]
  const renderComponent = () => {
    switch (type) {
      case 'OPERATOR_WITH_NUMERIC_VALUE':
        // OPERATOR_WITH_NUMERIC_VALUE 有兩參數要填：operator、numeric_value
        return (
          <>
            <DropdownGroup
              style={{ width: 'min-Content' }}
              activeKey={parameters?.operator || ''}
              options={optionData}
              placeholder="選擇條件"
              onSelect={(v) => handleGeneSelect(v, 'operator')}
              disabled={disabled}
            />
            <InputNumber
              min={0}
              value={(isArray(parameters?.numeric_value) ? parameters?.numeric_value?.[0] : parameters?.numeric_value) || null}
              placeholder={placeholder}
              onChange={(v) => {
                if (parameters?.operator === 'BETWEEN') {
                  const betweenValue = [v, parameters?.numeric_value?.[1] || 0]
                  return handleGeneSelect(betweenValue, 'numeric_value')
                }
                return handleGeneSelect(v, 'numeric_value')
              }}
              disabled={disabled}
            />
            {/* 選擇「介於之間」 */}
            {parameters?.operator === 'BETWEEN' && (
              <InputNumber
                min={0}
                value={parameters?.numeric_value ? parameters?.numeric_value[1] : null}
                placeholder={placeholder}
                onChange={(v) => {
                  const betweenValue = [parameters?.numeric_value?.[0] || 0, v]
                  handleGeneSelect(betweenValue, 'numeric_value')
                }}
                disabled={disabled}
              />
            )}
          </>
        )
      case 'GENE_REGION':
        return (
          <DropdownGroup
            style={{ width: 'min-Content' }}
            activeKey={parameters?.region}
            options={optionData}
            placeholder="選擇區域"
            onSelect={(v) => handleGeneSelect(v, 'region')}
            disabled={disabled}
          />
        )
      case 'NULL_PERCENTAGE_LABEL':
        return (
          <TagPicker
            data={optionData}
            labelKey="name"
            style={{ minWidth: '30vw', marginLeft: '10px' }}
            placeholder={placeholder}
            value={Array.isArray(value) ? value : [value]}
            onChange={(selections) => handleGeneSelect(selections, property)}
            disabled={disabled}
          />
        )
      case 'ROI_GROUP':
        return (
          <DropdownGroup
            style={{ width: 'min-Content' }}
            activeKey={parameters?.roi_group}
            options={optionData}
            placeholder="選擇 ROI"
            onSelect={(v) => handleGeneSelect(v, 'roi_group')}
            disabled={disabled}
          />
        )
      case 'GENE_CAI_LABEL':
        return (
          <DropdownGroup
            style={{ width: 'min-Content' }}
            activeKey={parameters?.gene_cai_label}
            options={optionData}
            placeholder="選擇 CAI"
            onSelect={(v) => handleGeneSelect(v, 'gene_cai_label')}
            disabled={disabled}
          />
        )
      case 'TIME_LABEL':
        return (
          <DropdownGroup
            style={{ width: 'min-Content' }}
            activeKey={parameters?.time_label}
            options={optionData}
            placeholder="選擇時段偏好"
            onSelect={(v) => handleGeneSelect(v, 'time_label')}
            disabled={disabled}
          />
        )
      default:
        return null
    }
  }
  return renderComponent()
}

export default React.memo(GeneSelector)
