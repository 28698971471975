import React, { useState, useEffect } from 'react'
import { Button, Input, InputGroup, InputPicker, TagPicker } from 'rsuite'
import { findKey } from 'lodash'
import FormInput from './FormInput'
import { ListWrapper } from './styles'
import { EMPLOY_STATUS, EMPLOY_TYPE } from './const'

const convertObject = (objs) => {
  return Object.keys(objs).map((name) => ({ label: objs[name], value: name }))
}

const EmployeeForm = React.memo(({ model, isAdd, currentInfo, handleSuccess, handleClose, initInfo, roles = [], permissions = [] }) => {
  const [employeeInfo, setEmployeeInfo] = useState({})
  const [errors, setErrors] = useState({})
  const handleOnChange = (value, e, key = '') => {
    if (!key && !e.target?.name) return
    setEmployeeInfo({ ...employeeInfo, [key || e.target.name]: value })
  }
  const handleConfirmClick = async () => {
    const checkedResult = model.check(employeeInfo)
    // find fields have any errors
    if (findKey(checkedResult, (obj) => obj.hasError)) {
      setErrors(checkedResult)
      return
    }
    handleSuccess && handleSuccess(employeeInfo)
  }
  useEffect(() => {
    setEmployeeInfo(isAdd ? { ...currentInfo, id: '' } : currentInfo)
    return () => setEmployeeInfo(initInfo)
  }, [currentInfo, isAdd])

  return (
    <>
      {employeeInfo.id && (
        <FormInput title="id" $require>
          <Input value={employeeInfo.id} disabled />
        </FormInput>
      )}
      <FormInput title="姓名" $require error={errors.name}>
        <Input name="name" value={employeeInfo.name} onChange={handleOnChange} />
      </FormInput>
      <FormInput title="使用者帳號" $require error={errors.username}>
        <Input name="username" value={employeeInfo.username} onChange={handleOnChange} disabled={!isAdd} />
      </FormInput>
      <FormInput title="email" $require error={errors.email}>
        <Input name="email" value={employeeInfo.email} onChange={handleOnChange} disabled={!isAdd} />
      </FormInput>
      <FormInput title="手機號碼" $require error={errors.phone}>
        <InputGroup>
          <InputGroup.Addon>+886</InputGroup.Addon>
          <Input name="phone" value={employeeInfo.phone} onChange={handleOnChange} />
        </InputGroup>
      </FormInput>
      <FormInput title="部門" $require error={errors.role}>
        <TagPicker
          name="role"
          block
          data={convertObject(roles)}
          value={employeeInfo.role}
          placeholder="部門"
          onSelect={(value, item, e) => handleOnChange(value.length === 0 ? value : [item.value], e, 'role')}
        />
      </FormInput>
      <FormInput title="工作性質" $require error={errors.type}>
        <InputPicker
          name="type"
          block
          data={EMPLOY_TYPE}
          value={employeeInfo.type || ''}
          placeholder="工作性質"
          onChange={(value, e) => handleOnChange(value, e, 'type')}
        />
      </FormInput>
      <FormInput title="權限" error={errors.permissions}>
        <TagPicker
          name="permissions"
          valueKey={'name'}
          block
          data={permissions}
          value={employeeInfo.permissions}
          tagProps={{ closable: false }}
          placeholder="權限"
          placement={'top'}
          onSelect={(value, item, e) => handleOnChange(value, e, 'permissions')}
        />
      </FormInput>
      <FormInput title="在職狀態" $require error={errors.status}>
        <InputPicker
          name="status"
          block
          data={convertObject(EMPLOY_STATUS)}
          value={(employeeInfo.status || '').toUpperCase()}
          placeholder="在職狀態"
          onChange={(value, e) => handleOnChange(value, e, 'status')}
        />
      </FormInput>
      <ListWrapper style={{ display: 'flex', justifyContent: 'right' }}>
        <Button size="md" appearance="ghost" onClick={handleClose}>
          {isAdd ? '取消新增' : '取消更新'}
        </Button>
        <Button size="md" appearance="primary" onClick={handleConfirmClick}>
          {isAdd ? '新增成員' : '更新成員'}
        </Button>
      </ListWrapper>
    </>
  )
})

export default EmployeeForm
