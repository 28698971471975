import React, { useCallback, useMemo, useState } from 'react'
import { Avatar, Button, Icon, List } from 'rsuite'
import { cloneDeep, findIndex, omit, remove } from 'lodash'
import { ListBox, ListItemGrid, ListItemSlimTitle, ListItemTag, ListItemTitle, Root, HStack, VStack, TopBar } from './index.style'
import CreateModal from './CreateModal'
import EditModal from './EditModal'
import useDisclosure from '../../../hook/useDisclosure'
import { useMGMConfig } from '../provider'

function StepCoupons() {
  const [isCreateModalOpened, createModalAction] = useDisclosure()
  const [isEditModalOpened, editModalAction] = useDisclosure()
  const { value, updateValue } = useMGMConfig()
  const [currentEditData, setCurrentEditData] = useState()

  const couponsWithID = useMemo(
    () => ({
      sender: value.coupons.sender.map((item, i) => ({ ...item, id: `sender_${i}` })),
      receiver: value.coupons.receiver.map((item, i) => ({ ...item, id: `receiver_${i}` })),
    }),
    [value.coupons],
  )

  const handleOpenEditModal = useCallback(
    (data) => () => {
      setCurrentEditData(data)
      editModalAction.open()
    },
    [editModalAction],
  )

  const handleDelete = useCallback(
    (data) => () => {
      const newCoupons = cloneDeep(couponsWithID)
      remove(newCoupons[data.type], (n) => n.id === data.id)
      updateValue('coupons', newCoupons)
    },
    [couponsWithID, updateValue],
  )

  const renderReceiverItems = useCallback(() => {
    return couponsWithID.receiver.map((item) => (
      <List.Item key={item.id}>
        <ListItemGrid>
          <Avatar size="lg" src={item.webImageUrl} />
          <VStack>
            <ListItemTitle>{item.title}</ListItemTitle>
            <ListItemTag variant="receiver">客態獎項</ListItemTag>
            <HStack>
              <Icon icon="random" style={{ color: '#9ca3af' }} />
              <span>{item.ratio / 1000}</span>
            </HStack>
          </VStack>
          <VStack>
            <ListItemSlimTitle>乘車券</ListItemSlimTitle>
            {item.taxi.join(', ')}
          </VStack>
          <VStack>
            <ListItemSlimTitle>租車券</ListItemSlimTitle>
            {item.rental.join(', ')}
          </VStack>
          <HStack>
            <Button appearance="link" onClick={handleOpenEditModal({ type: 'receiver', ...item })}>
              編輯
            </Button>
            <Button appearance="link" onClick={handleDelete({ type: 'receiver', ...item })}>
              刪除
            </Button>
          </HStack>
        </ListItemGrid>
      </List.Item>
    ))
  }, [couponsWithID, handleOpenEditModal, handleDelete])

  const renderSenderItems = useCallback(() => {
    return couponsWithID.sender.map((item) => (
      <List.Item key={item.id}>
        <ListItemGrid>
          <Avatar size="lg" src={item.webImageUrl} />
          <VStack>
            <ListItemTitle>{item.title}</ListItemTitle>
            <ListItemTag variant="sender">主態獎項</ListItemTag>
            <HStack>
              <Icon icon="random" style={{ color: '#9ca3af' }} />
              <span>{item.ratio / 1000}</span>
            </HStack>
          </VStack>
          <VStack>
            <ListItemSlimTitle>乘車券</ListItemSlimTitle>
            {item.taxi.join(', ')}
          </VStack>
          <VStack>
            <ListItemSlimTitle>租車券</ListItemSlimTitle>
            {item.rental.join(', ')}
          </VStack>
          <HStack>
            <Button appearance="link" onClick={handleOpenEditModal({ type: 'sender', ...item })}>
              編輯
            </Button>
            <Button appearance="link" onClick={handleDelete({ type: 'sender', ...item })}>
              刪除
            </Button>
          </HStack>
        </ListItemGrid>
      </List.Item>
    ))
  }, [couponsWithID, handleOpenEditModal, handleDelete])

  const handleCreate = useCallback(
    (data) => {
      const newCoupons = cloneDeep(couponsWithID)
      newCoupons[data.type].push(omit(data, ['type']))
      updateValue('coupons', newCoupons)
      createModalAction.close()
    },
    [couponsWithID, updateValue, createModalAction],
  )

  const handleEdit = useCallback(
    (data) => {
      const newCoupons = cloneDeep(couponsWithID)
      const indexFromSender = findIndex(newCoupons.sender, (n) => n.id === data.id)
      const indexFromReceiver = findIndex(newCoupons.receiver, (n) => n.id === data.id)

      if (indexFromSender >= 0 && data.type === 'sender') {
        // 沒有編輯客主態
        newCoupons.sender.splice(indexFromSender, 1, data)
      } else if (indexFromSender >= 0 && data.type === 'receiver') {
        // 從主態變客態
        remove(newCoupons.sender, (n) => n.id === data.id)
        newCoupons.receiver.push(data)
      } else if (indexFromReceiver >= 0 && data.type === 'receiver') {
        // 沒有編輯客主態
        newCoupons.receiver.splice(indexFromReceiver, 1, data)
      } else if (indexFromReceiver >= 0 && data.type === 'sender') {
        // 從客態變主態
        remove(newCoupons.receiver, (n) => n.id === data.id)
        newCoupons.sender.push(data)
      }

      updateValue('coupons', newCoupons)
      editModalAction.close()
    },
    [couponsWithID, updateValue, editModalAction],
  )

  const availableSenderCouponRatio = useMemo(() => {
    if (value.coupons.sender.length) {
      const sum = value.coupons.sender.reduce((prev, current) => {
        return prev + current.ratio
      }, 0)
      return (1000 - sum) / 1000
    }
    return 1
  }, [value])

  const availableReceiverCouponRatio = useMemo(() => {
    if (value.coupons.receiver.length) {
      const sum = value.coupons.receiver.reduce((prev, current) => {
        return prev + current.ratio
      }, 0)
      return (1000 - sum) / 1000
    }
    return 1
  }, [value])

  return (
    <>
      <CreateModal
        show={isCreateModalOpened}
        onHide={createModalAction.close}
        onConfirm={handleCreate}
        availableSenderCouponRatio={availableSenderCouponRatio}
        availableReceiverCouponRatio={availableReceiverCouponRatio}
      />
      <EditModal
        show={isEditModalOpened}
        onHide={editModalAction.close}
        data={currentEditData}
        onConfirm={handleEdit}
        availableSenderCouponRatio={availableSenderCouponRatio}
        availableReceiverCouponRatio={availableReceiverCouponRatio}
      />
      <Root>
        <TopBar>
          <Button appearance="primary" onClick={createModalAction.open}>
            新增優惠券
          </Button>
        </TopBar>
        <ListBox>
          <List hover>
            {renderReceiverItems()}
            {renderSenderItems()}
          </List>
        </ListBox>
      </Root>
    </>
  )
}

export default StepCoupons
