import React from 'react'
import { Popover, Whisper } from 'rsuite'
import { Cell } from 'rsuite-table'

const BotContentCell = ({ rowData, dataKey, ...props }) => {
  const contents = rowData[dataKey]?.content?.contents
  if (!contents) return <Cell {...props} />
  const PopoverWording = contents[1] && contents[1].text ? <p>{contents[1].text}</p> : ''
  const speaker = (
    <Popover title="卡片內容">
      <div className="description-wrap">{PopoverWording}</div>
    </Popover>
  )

  return (
    <Cell {...props}>
      <Whisper placement="top" speaker={speaker}>
        <div>{PopoverWording}</div>
      </Whisper>
    </Cell>
  )
}

export default BotContentCell
