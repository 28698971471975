import styled from 'styled-components'

export const Root = styled.div`
  width: 100%;
  padding: 16px 20px;
`

export const Title = styled.h6`
  width: 100%;
  margin: 16px 0;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 0.5px;
    background-color: #a5a6a5;
    margin-top: 5px;
  }
`
