const createLayer = (id, type, layout = {}, paint = {}) => {
  return {
    id,
    type,
    source: id,
    layout,
    paint,
  }
}

export default createLayer
