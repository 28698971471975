import React, { forwardRef, useCallback, useMemo } from 'react'
import { Alert, Button, ButtonGroup, ButtonToolbar, Icon, IconButton, Popover, Table, Tag, Whisper, Panel } from 'rsuite'
import dayjs from 'dayjs'
import { forEach, isEmpty } from 'lodash'
import { getSerialCode } from '../../api'
import { Stack } from './cell.style'
import { carFunctionalTypes, carTypes, ondemandTypes } from '../../hook/coupons/useFormHelper'

export const CellTitle = forwardRef((props, ref) => {
  const { rowData, dataKey, ...cellProps } = props
  const { stats = {} } = rowData
  const speaker = (
    <Popover title={rowData[dataKey]}>
      {stats?.distinct_users && <p>{`領取人數：${stats.distinct_users} 人`}</p>}
      {stats?.total_distribute && <p>{`領取次數：${stats.total_distribute} 次`}</p>}
      {stats?.total_redeem && <p>{`使用張數：${stats.total_redeem} 張`}</p>}
      {stats?.total_spending && <p>{`總共花費：${stats.total_spending} 元`}</p>}
    </Popover>
  )

  return (
    <Table.Cell ref={ref} {...cellProps}>
      <Whisper placement="bottom" speaker={speaker}>
        <p>{rowData[dataKey]}</p>
      </Whisper>
    </Table.Cell>
  )
})

export const CellType = forwardRef((props, ref) => {
  const { rowData, dataKey, ...cellProps } = props

  const getTagProps = useCallback((value) => {
    switch (value) {
      case 'OPERATION':
        return {
          children: '重複領用',
          color: 'orange',
        }
      case 'FIRST_TIME_USER':
        return {
          children: '首次乘車',
          color: 'blue',
        }
      case 'ONE_TIME_EVENT':
        return {
          children: '單次領用',
          color: 'green',
        }
      case 'SERIAL_NUMBER':
        return {
          children: '亂碼序號',
          color: 'red',
        }
      default:
        return {
          children: value,
          color: null,
        }
    }
  }, [])

  const downloadCSV = useCallback(async () => {
    try {
      const { batch } = rowData
      const response = await getSerialCode(batch)
      if (response.status !== 200) throw new Error(response.data.msg)
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }))
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', `batch-${batch}`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      Alert.error(error.message)
    }
  }, [rowData])

  return (
    <Table.Cell ref={ref} {...cellProps}>
      <Stack>
        <Tag {...getTagProps(rowData[dataKey])} />
        {rowData.promo_type === 'SERIAL_NUMBER' ? (
          <IconButton icon={<Icon icon="file" />} color="red" appearance="ghost" size="xs" onClick={downloadCSV}>
            批量序號
          </IconButton>
        ) : (
          <>{rowData.promo_code}</>
        )}
      </Stack>
    </Table.Cell>
  )
})

export const CellLifespan = forwardRef((props, ref) => {
  const { rowData, ...cellProps } = props

  const setting = {
    send: {
      start_time: rowData.start_date
        ? dayjs(rowData.start_date * 1000).format('YY-MM-DD HH:mm')
        : dayjs(rowData.created_at * 1000).format('YYYY-MM-DD HH:mm'),
      end_time: rowData.end_date ? dayjs(rowData.end_date * 1000).format('YY-MM-DD HH:mm') : '未設定',
    },
    use: {
      start_time: rowData.enable_date ? dayjs(rowData.enable_date * 1000).format('YY-MM-DD HH:mm') : '未設定',
      end_time: dayjs.unix(rowData.valid_days).isAfter(dayjs('2000-01-01').toDate())
        ? dayjs(rowData.valid_days * 1000).format('YY-MM-DD HH:mm')
        : `領取後 ${rowData.valid_days} 天`,
    },
  }

  return (
    <Table.Cell ref={ref} {...cellProps}>
      <Stack>
        <p>領取設定</p>
        <p>
          開始：
          {setting.send.start_time}
        </p>
        <p>
          結束：
          {setting.send.end_time}
        </p>
        <p>使用設定</p>
        <p>
          開始：
          {setting.use.start_time}
        </p>
        <p>
          結束：
          {setting.use.end_time}
        </p>
      </Stack>
    </Table.Cell>
  )
})

const WEEK_DAYS = {
  0: '一',
  1: '二',
  2: '三',
  3: '四',
  4: '五',
  5: '六',
  6: '日',
}

export const CellUsingInterval = forwardRef((props, ref) => {
  const { rowData, ...cellProps } = props

  const { periods, recentInterval, weekDays } = useMemo(() => {
    let periods = []
    let recentInterval = null
    let weekDays = null
    const { designatedDailyTimeframe, designatedWeeklyTimeframe } = rowData
    if (!isEmpty(designatedDailyTimeframe)) {
      periods = designatedDailyTimeframe
    }
    if (!isEmpty(designatedWeeklyTimeframe)) {
      const { interval, hours_setting, day_of_week } = designatedWeeklyTimeframe[designatedWeeklyTimeframe.length - 1]
      periods = hours_setting
      recentInterval = interval
      weekDays = { ...day_of_week }
      forEach(weekDays, (value, key) => {
        ;(value === '0' || value === 0) && delete weekDays[key]
      })
    }
    return { periods, recentInterval, weekDays }
  }, [rowData])

  const renderContent = useCallback(() => {
    return (
      <>
        <p>
          {`以 ${rowData.enable_date ? dayjs(rowData.enable_date * 1000).format('YYYY-MM-DD') : '活動使用時間'} 作為起始`}
          {recentInterval && recentInterval !== 1 && <span>{`，設定每隔 ${recentInterval} 週`}</span>}
        </p>
        <p>
          {weekDays &&
            Object.keys(weekDays).map((day, index) => {
              return <span key={day}>{`${index > 0 ? '、' : ''}每週${WEEK_DAYS[day]}`}</span>
            })}
        </p>
        {periods.map(({ time_of_day_start, time_of_day_end, id }) => {
          const key = `${time_of_day_end}-${time_of_day_start}}-${id}`
          return <p key={key}>{`${time_of_day_start.substring(0, 5)} ~ ${time_of_day_end.substring(0, 5)}`}</p>
        })}
      </>
    )
  }, [rowData, recentInterval, periods, weekDays])

  const speaker = <Popover title="使用時段條件限制">{!isEmpty(periods) && renderContent()}</Popover>

  return (
    <Table.Cell ref={ref} {...cellProps}>
      <Whisper placement="bottom" speaker={speaker}>
        <Stack>{!isEmpty(periods) && renderContent()}</Stack>
      </Whisper>
    </Table.Cell>
  )
})

export const CellReleaseTotal = forwardRef((props, ref) => {
  const { rowData, ...cellProps } = props

  const total = useMemo(() => {
    if (rowData.batch) return rowData.total_number_of_coupons * rowData.count
    return rowData.total_number_of_coupons !== -1 ? rowData.total_number_of_coupons : '無限制'
  }, [rowData])

  return (
    <Table.Cell ref={ref} {...cellProps}>
      {total}
    </Table.Cell>
  )
})

export const CellDiscount = forwardRef((props, ref) => {
  const { rowData, ...cellProps } = props

  const content = useMemo(() => {
    let content = ''
    if (rowData.coupon_is_line_point_type) {
      const { feedback_amount, feedback_ratio, max_amount } = rowData.line_point_coupon_settings
      if (!feedback_amount) {
        content = `LINE Points 回饋 ${feedback_ratio * 100} %，上限 ${max_amount} 點`
      } else {
        content = `LINE Points 回饋 ${feedback_amount} 點`
      }
    } else if (rowData.max_amount) {
      content = `打 ${rowData.amount * 10} 折，上限 ${rowData.max_amount} 元`
    } else {
      content = rowData.amount < 1 ? `${rowData.amount * 10} 折` : `${rowData.amount} 元`
    }

    return content
  }, [rowData])

  const speaker = <Popover>{content}</Popover>

  return (
    <Table.Cell ref={ref} {...cellProps}>
      <Whisper placement="bottom" speaker={speaker}>
        <p>{content}</p>
      </Whisper>
    </Table.Cell>
  )
})

export const CellForCash = forwardRef((props, ref) => {
  const { rowData, ...cellProps } = props

  const forCash = Boolean(rowData.is_for_cash)

  return (
    <Table.Cell ref={ref} {...cellProps}>
      <h4>{forCash ? '✅' : '🚫'}</h4>
    </Table.Cell>
  )
})

export const CellMinimumCharge = forwardRef((props, ref) => {
  const { rowData, ...cellProps } = props

  return (
    <Table.Cell ref={ref} {...cellProps}>
      <p>{rowData.minimum_charge === 0 ? '無' : `${rowData.minimum_charge} 元`}</p>
    </Table.Cell>
  )
})

export const CellActions = forwardRef((props, ref) => {
  const { rowData, onDelete, onDuplicate, onEdit, ...cellProps } = props

  const handleClickDelete = useCallback(() => {
    onDelete(rowData)
  }, [rowData, onDelete])

  const handleClickEdit = useCallback(() => {
    onEdit(rowData)
  }, [rowData, onEdit])

  const handleClickDuplicate = useCallback(() => {
    onDuplicate(rowData)
  }, [rowData, onDuplicate])

  return (
    <Table.Cell ref={ref} {...cellProps}>
      <ButtonToolbar>
        <ButtonGroup>
          {rowData.promo_type !== 'SERIAL_NUMBER' && (
            <Button size="sm" appearance="link" onClick={handleClickEdit}>
              編輯
            </Button>
          )}
          <Button size="sm" appearance="link" onClick={handleClickDuplicate}>
            複製
          </Button>
          <Button size="sm" appearance="link" color="red" onClick={handleClickDelete}>
            刪除
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    </Table.Cell>
  )
})

export const CellFeatureMap = forwardRef((props, ref) => {
  const { rowData, onClickPickAndDropArea, ...cellProps } = props

  const data = rowData.coupon_settings_group || []

  return (
    <Table.Cell ref={ref} {...cellProps}>
      {data.map((row) => {
        const carTypesWording = `${row.cartype_feature_map
          .split(',')
          .map((code) => {
            return carTypes.find((item) => item.value === code).label
          })
          .join(',')}`
        const functionalFeatureMapArray = row.functional_feature_map.split(',')
        const pureFunctionalFeatureMap = functionalFeatureMapArray.filter((row) => !carTypes.some((item) => item.value === row))
        const carTypeInFunctionalFeatureMap = functionalFeatureMapArray.find((row) => carTypes.some((item) => item.value === row))
        let carFunctionalTypesWording = `${pureFunctionalFeatureMap
          .map((code) => {
            const functional = carFunctionalTypes.find((item) => item.value === code)
            return functional.label
          })
          .join(',')}`
        if (carTypeInFunctionalFeatureMap) {
          carFunctionalTypesWording += ',一般'
        }
        const isOndemandWording = ondemandTypes.find((item) => item.value === row.is_ondemand).label
        let pickupCount = 0
        let dropOffCount = 0
        if (Array.isArray(row.designated_areas)) {
          row.designated_areas.forEach((obj) => {
            if (obj.start_location && row.id === obj.coupon_settings_group_id) pickupCount += 1
            if (obj.end_location && row.id === obj.coupon_settings_group_id) dropOffCount += 1
          })
        }
        return (
          <Panel key={row.id} bordered>
            <p>
              主要車種：
              {carTypesWording}
            </p>
            <p>
              次要車種:
              {carFunctionalTypesWording}
            </p>
            <p>
              行程類型:
              {isOndemandWording}
            </p>
            {pickupCount + dropOffCount > 0 ? (
              <Button appearance="link" onClick={onClickPickAndDropArea(row.designated_areas)}>
                {`上下車地點： 總共 ${pickupCount + dropOffCount}組`}
              </Button>
            ) : (
              <p>無上下車地點</p>
            )}
          </Panel>
        )
      })}
    </Table.Cell>
  )
})
