import React from 'react'

import ReactDataSheet from 'react-datasheet'
import { isEmpty } from 'lodash'
import TitleHeader from '../../components/views/TitleHeader'
import PopWarning from '../../components/popWarning'
import { getModifyTable2, postTable, updateColumns, deleteTable, batchUpdateColumns } from '../../api'
import _ from 'lodash'
import { Dropdown, Alert, Divider, Checkbox } from 'rsuite'
import { Link } from 'react-router-dom'
import ModelType from "./ModelTypes"
import InputGroupModel from './InputGroupModel'
import queryString from 'query-string'
import { View, HStack, VStack, Spacer, Text } from "./ReactUI";
import { ContextStore } from '../../reducers'

import Head from './Head'

class ModifyTable extends React.Component {
	initState = () => {
		return {
			tableName: this.props.match.params.table_name || '',
			enableLoad: true,
			tableList: ['載入中'],
			data: [],
			columns: [],
			warning: '',
			show: false,
			newRow: [],
			selected: {},
			sorting: {
				key: '',
				value: '',
			},
			filterColumns: (index = 0) => {
				const [{ column_name: firstColumn } = {}] = this.state.columns
				return this.state.filters[index].column || firstColumn || ''
			},
			filters: [{
				column: '',
				operator_option: Object.keys(this.operators)[0],
				text: '',
				isHidden: false
			}],
			isFilterHidden: false,
			readOnly: false
		}
	}

	state = this.initState()

	async componentDidMount() {
		console.log('componentDidMount')

		await this.fetchTableList()

		this.queryStringToState()
  }

  async componentDidUpdate() {
    await this.waitForElementExist('.jsoneditor')
    let button = document.querySelector('.jsoneditor-expand-all');
    if (!document.querySelector('.jsoneditor-text')) {
      button.click();
    }
  }

  waitForElementExist = (element) => {
    return new Promise((res, rej) => {
      const timer = setInterval(() => {
        if (document.querySelector(element)) {
          clearInterval(timer)
          res()
        }
      }, 50)
    })
  }

	queryStringToState() {
		const { tableName } = this.state
		if (isEmpty(tableName)) {
			return
		}

		const { filter, sorting, readonly: readonlyFromURL, isFilterHidden } = queryString.parse(this.props.location.search)

		const toUpdate = {}

		if (filter) {
			const filters = JSON.parse(filter)

			if (filters.filter(f => !f.isHidden).length === 0) {
				const [emptyFilter] = this.initState().filters
				filters.push(emptyFilter)
			}

			toUpdate.filters = filters
		}

		if (sorting) {
			toUpdate.sorting = JSON.parse(sorting)
		}

		toUpdate.readOnly = (readonlyFromURL === 'true')
		toUpdate.isFilterHidden = (isFilterHidden === 'true') || readonlyFromURL === 'true' || this.isInIframe

		for (const { table, readOnly: tableReadOnly } of this.state.tableList) {
			if (table === tableName) {
				toUpdate.readOnly = tableReadOnly || (readonlyFromURL === 'true')
			}
		}

		this.setState(toUpdate, this.fetchTable)
	}

	get isInIframe() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true
		}
	}

	handleScroll = async (event) => {

		try {
			if (event.target.id !== 'table') {
				return
			}
		} catch (error) {
			return
		}

		const { target: { scrollHeight, scrollTop, clientHeight } } = event
		const { tableName, enableLoad } = this.state

		if (!tableName || !enableLoad)
			return

		if (!scrollTop) {
			return
		}

		if (scrollHeight - scrollTop === clientHeight) {
			console.log('load more')
			await this.fetchTable()
		}
	}

	deleteRow = async (id) => {
		console.log('deleteRow')

		const { tableName } = this.state
		const data = await deleteTable(tableName, id)
		this.handlePopWarning('')
		if (data.status !== 200) {
			return Alert.error(`刪除失敗: ${data.message}`, 3000)
		}
		await this.setState({ data: [] }, this.fetchTable)
		Alert.success('刪除成功', 3000)
	}

	fetchTableList = async () => {
		console.log('fetchTableList')
		const data = await getModifyTable2({ getTables: true })
		if (data.status !== 200) {
			return Alert.error('抓取失敗(可能沒權限)' + JSON.stringify(data), 6000)
		}
		this.setState({ tableList: data.data.data.tables || [] })
	}

	fetchTable = async () => {
		console.log('fetchTable')
		const { tableName } = this.state


		if (!tableName) return

		let conditions = []

		const whereclause = this.state.filters
			.filter((filter, index) => this.state.filterColumns(index) && filter.operator_option && filter.text)
			.map((filter, index) => {
				return {
					key: this.state.filterColumns(index) + this.operators[filter.operator_option],
					value: filter.text
				}
			})

		conditions = conditions.concat(whereclause)

		if (this.state.sorting.key) {
			conditions.push({ key: this.state.sorting.key, value: this.state.sorting.value })
		}

		conditions.push({ key: 'limit', value: 100 })
		conditions.push({ key: 'offset', value: this.state.data.length })

		const { data: { data: { get: { columns, data } = {} } = {} } = {}, status } = await getModifyTable2({ table: tableName, conditions })

		if (status !== 200) {
			return Alert.error('資料不存在或沒有權限' + JSON.stringify(status), 6000)
		}

		this.setState({
			columns,
			data: this.state.data.concat(data),
			enableLoad: data.length !== 0
		})
	}

	updateColumn = async (id, column) => {
		console.log('updateColumns')

		const { tableName } = this.state
		const body = {
			[column.column_name]: column.outputValue
		}

		const { data, status } = await updateColumns(tableName, id, body)

		if (status !== 200) {
			Alert.error(`更新失敗:${data.message}`, 6000)
			return
		} else {
			Alert.success('更新成功', 3000)
			return true
		}
	}

	handlePopWarning = (warning) => {
		console.log('handlePopWarning')

		this.setState({ warning })
	}

	render() {
		const { sidebar } = this.context

		return <div onScroll={this.handleScroll} style={{ overflow: 'hidden', width: `calc(100vw - ${sidebar.width + 24}px)` }}  >
			{this._warning}
			{this._model}

			<Head
				isHidden={this.isInIframe}
				tableName={this.state.tableName}
				tableList={this.state.tableList}
				isFilterHidden={this.state.isFilterHidden}
				changeFilterHidden={(isFilterHidden) => {
					console.log(isFilterHidden)
					this.setState({ isFilterHidden }, this.stateToQueryString)
				}}
				changeTable={(tableName) => {
					console.log('dropdownSelected', tableName)

					window.location = `/modify_table/${tableName}`
					// const { tableList } = this.state

					// const state = this.initState()
					// state.tableName = tableName
					// state.tableList = tableList

					// this.setState(state, () => {
					// 	this.props.history.push({ pathname: tableName })
					// 	this.queryStringToState()
					// })
				}}
			/>

			{!this.state.isFilterHidden && this._filter}
			{this._body}
		</div >
	}
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	get _warning() {
		const { warning: { text, confirm, cancel, isCancel } = {} } = this.state
		return text && <PopWarning warning={text} textCenter={true} confirm={confirm} cancel={cancel} isCancel={isCancel} />
	}

	get _model() {
		return <InputGroupModel
			style={{ width: '100%' }}
			newRow={this.state.newRow}
			show={this.state.show}
			closeModel={() => this.setState({ show: false })}
			okModel={async (body) => {
				console.log('okModel', body)

				const { status, data } = await postTable(this.state.tableName, body)

				if (status !== 200) {
					console.log('status', data)
					Alert.error(`insert failed :\n${data.message}`, 3000)
					return
				}

				this.setState({ show: false, data: [] }, this.fetchTable)
				Alert.success('Success', 3000)
			}}
		/>
	}

	get operators() {
		return {
			'=': '',
			'不等於': '_not',
			'>=': '_gte',
			'<=': '_lte',
			'包含': '_like',
			'不包含': '_not_like',
			'regexp': '_regex'
		}
	}

	stateToQueryString() {
		this.props.history.push({
			pathname: window.location.pathname,
			search: (() => {
				const queries = []

				if (this.state.sorting.key) {
					const sorting = JSON.stringify({ key: this.state.sorting.key, value: this.state.sorting.value })
					queries.push(new URLSearchParams({ sorting }).toString())
				}

				const availableFilters = this.state.filters.filter((filter, index) => this.state.filterColumns(index) && filter.operator_option && filter.text)
				if (availableFilters.length) {
					const stringify = JSON.stringify(
						availableFilters.map((filter, index) => {
							return {
								column: this.state.filterColumns(index),
								operator_option: filter.operator_option,
								text: filter.text,
								isHidden: filter.isHidden
							}
						})
					)

					queries.push(new URLSearchParams({ filter: stringify }).toString())
				}

				if (this.state.isFilterHidden) {
					queries.push(new URLSearchParams({ isFilterHidden: true }).toString())
				}

				if (!queries.length) {
					return ''
				}

				return `?` + queries.join('&')
			})()
		})
	}

	get _filter() {
		const stateAndParamChangeMaybeSelect = (index, key) => {
			return (e) => {
				const { filters } = this.state
				filters[index][key] = e.target.value

				this.setState({ filters }, () => {
					this.stateToQueryString()

					if (this.state.filters[index].text && key !== 'text') {
						this.setState({ data: [] }, this.fetchTable)
					}
				})
			}
		}

		const { sidebar } = this.context

		return <HStack style={{ width: '100%', backgroundColor: 'white' }}>
			<VStack style={{ width: '100%' }}>
				{this.state.filters.map((filter, index) => {
					if (filter.isHidden) {
						return <div></div>
					}

					return <HStack key={index}>
						<select id="filter-column" value={this.state.filterColumns(index) || 'id'} onChange={stateAndParamChangeMaybeSelect(index, 'column')} style={{ width: '150px' }} >
							{this.state.columns.map(({ column_name, description, column_comment }, index) => {
								return (
									<option key={`${column_name}-${index}`} value={column_name}>
										{description || column_comment || column_name}
									</option>
								)
							})}
						</select>

						<select id="filter-value" value={filter.operator_option} onChange={stateAndParamChangeMaybeSelect(index, 'operator_option')} style={{ width: '100px' }} >
							{Object.keys(this.operators).map(v => (<option key={v} value={v}>{v}</option>))}
						</select>

						<input
							className="input-wrap"
							name='text'
							placeholder='filter'
							value={filter.text}
							onChange={stateAndParamChangeMaybeSelect(index, 'text')}
							onKeyPress={event => {
								if (event.key === 'Enter') {
									this.setState({ data: [] }, this.fetchTable)
								}
							}}
							style={{ backgroundColor: '#FFF' }}
						/>

						<button
							disabled={this.isInIframe || !filter.text.length}
							style={{ padding: '8px', whiteSpace: 'nowrap' }}
							onClick={() => {
								const filters = this.state.filters
								filters[index].isHidden = true
								this.setState({ filters }, () => {
									this.stateToQueryString()
									this.fetchTable()
								})
							}}>hide filters</button>

						<button
							style={{ padding: '8px', whiteSpace: 'nowrap' }}
							onClick={() => {
								let newValue = this.state.filters.filter((filter, i) => index !== i)

								if (newValue.length === 0) {
									newValue = this.initState().filters
								}

								this.setState({ filters: newValue }, async () => {
									await this.fetchTable
									this.stateToQueryString()
								})
							}}>-</button>

						<button
							style={{ padding: '8px', whiteSpace: 'nowrap' }}
							disabled={index !== (this.state.filters.length - 1)}
							onClick={() => {
								if (index === (this.state.filters.length - 1)) {
									const { filters } = this.state
									const [initFilter] = this.initState().filters
									filters.push(initFilter)
									this.setState({ filters }, this.stateToQueryString)
								}
							}}>＋</button>

					</HStack>
				})}
			</VStack>

			<VStack>
				<button
					style={{ padding: '8px', whiteSpace: 'nowrap' }}
					onClick={() => this.setState({ show: true, newRow: this.emptyRow() })}
					disabled={!this.state.tableName}
				>新增
				</button>
				<Spacer />
			</VStack>
		</HStack >
	}

	get _body() {
		const { sidebar } = this.context

		// console.log('sidebar', sidebar)

		return <div id='table' style={{ width: `100%`, height: `calc(100vh - ${130 + this.state.filters.filter(f => !f.isHidden).length * 38}px)`, overflow: 'scroll', backgroundColor: "white" }}>
			<ReactDataSheet
				data={(() => {
					if (!this.state.columns) {
						return []
					} else if (!this.state.data.length) {
						return [this.titleRow()]
					}

					return [
						this.titleRow(),
						...this.dataRows()
					]
				})()}
				valueRenderer={cell => cell.value}
				valueViewer={cell => <p>{cell.value}</p>}
				//右鍵內容
				onContextMenu={(e, cell, i, j) => cell.readOnly ? e.preventDefault() : null}
				onSelect={({ start, end }) => {
          console.log('onSelected', start, end)
					let { selected } = this.state

					if (start.i !== end.i || start.j !== end.j) {
						console.log('multiple selection,  default behavior')

						if (start && start.i !== undefined && start.j !== undefined) {
							selected.start = start
						}
						if (end && end.i !== undefined && end.j !== undefined) {
							selected.end = end
						}
						this.setState({ selected })
					} else if (start.i === 0) {
						console.log('sorting')
						const { column_name } = this.titleRow()[start.j]
						let { sorting } = this.state
						sorting = {
							key: (() => {
								switch (sorting.key) {
									case 'asc':
										return ''
									case 'desc':
										return 'asc'
									default:
										return 'desc'
								}
							})(),
							value: column_name
						}


						this.setState({ selected: {}, sorting, data: [] }, () => {
							this.stateToQueryString()
							this.fetchTable()
						})
						return
					} else {
						selected = { start, end }
						this.setState({ selected })
					}
				}}
				//單選
				selected={this.state.selected}
				onCellsChanged={async changes => {
					console.log('onCellsChanged', changes)
					for (const { cell, row, col, value } of changes) {
						await this.updateColumn(cell.id, cell)
					}
				}}
			/>
		</div>
	}

	schema = () => {
		const { columns } = this.state

		return columns.reduce((a, column) => {
			a[column.column_name] = column
			return a
		}, {})
	}

	titleRow = () => {
		const { columns } = this.state

		let row = columns
			.map(r => {
				const obj = new ModelType.KeyName(r)
				if (obj.column_name === this.state.sorting.value) {
					switch (this.state.sorting.key) {
						case 'asc':
							obj.icon = 'angle-up'
							break
						case 'desc':
							obj.icon = 'angle-down'
							break
						default:
							obj.icon = ''
							break
					}
				}

				return obj
			})

		if (!this.state.readOnly && !this.state.isFilterHidden) {
			row = row.concat([
				new ModelType.KeyName({ column_name: '動作' })
			])
		}

		return row
	}

	pk = (schema = this.schema()) => Object.values(schema).filter(column => column.model_type === 'PK')[0].column_name

	dataRows = () => {
		const { data, columns } = this.state

		const copiedColumns = _.cloneDeep(columns)

		return data.map((item, i) => {
			copiedColumns.forEach((column, j) => {
				column.value = item[column.column_name] || undefined
				column.id = item[this.pk()]
				column.data_index = i
				//formceComponent onChange
				column.onChange = (e, index, key) => {
					const value = e?.target ? e.target.value : e
					console.log('onChange', key, value)
          const { data } = this.state
					data[index][key] = value

          this.setState({ data })
				}
			})

			let row = copiedColumns.map(column => {
				const Class = Type(column)
				const object = new Class(column)

				if (this.state.readOnly) {
					object.readOnly = this.state.readOnly
				}
				return object
			})

			if (!this.state.readOnly && !this.state.isFilterHidden) {
				row = row.concat([
					new ModelType.IconBtn({
						value: '刪除',
						icon: 'trash2',
						id: item['id'],
						onClick: () => {
							this.handlePopWarning({
								text: '確定刪除？',
								confirm: () => this.deleteRow(item.id),
								isCancel: true,
								cancel: () => this.handlePopWarning('')
							})
						}
					})
				])
			}

			return row
		})
	}

	emptyRow = () => {
		const { columns } = this.state

		return _.cloneDeep(columns)
			.filter(c => c.model_type !== 'PK')
			.filter(c => !this.state.readOnly && !c.readOnly)
			// .filter(r => !r)
			.map(column => {
				const Class = Type(column)
				return new Class(column)
			})
	}
}

const Type = ({ data_type, model_type } = {}) => {
	return ModelType[model_type || data_type] || ModelType['Str']
}

ModifyTable.contextType = ContextStore
export default ModifyTable
