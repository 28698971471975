import React from 'react'
import moment from 'moment-timezone'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import ListWrap from '../../views/ListWrap'
import AddModel from './AddModel'
import TableController from '../../table/Table'
import UrlCell from '../view/UrlCell'
import ParseCell from '../view/ParseCell'
import ActionCell from '../view/ActionCell'
import ImgCell from '../view/ImgCell'
import Simulator from '../view/Simulator';
import withAdsStateControl from '../../../utils/HOC/withAdsStateControl'

class Card extends React.Component {

	render() {
		const { adsData, form, addModel, actionData, currentUpdate, formError, showLoadMore, toggleSimulator, currentTab, filterData, isLoading,
			fetchTable,
			handleOpenAddModel,
			handleDatePicker,
			handleActionSelect,
			handleOnChange,
			handlePostTable,
			handleOpenUpdate,
			handleDelete,
			handleToggleSimulator,
			handleSimulatorDate,
			handleOnUpload,
			handlePayloadOnChange } = this.props
		return (
			<div className="cards-wrap">
				{addModel ?
					<AddModel form={form} actionData={actionData} currentUpdate={currentUpdate} formError={formError} isLoading={isLoading}
						handleCancel={handleOpenAddModel}
						handleDatePicker={handleDatePicker}
						handleActionSelect={handleActionSelect}
						handleOnChange={handleOnChange}
						handleAdd={handlePostTable}
						handleOnUpload={handleOnUpload}
						handlePayloadOnChange={handlePayloadOnChange} /> :
					<div className="cards-content">
						<ListWrap addListText={'+ 新增活動'} onClick={handleOpenAddModel} />
						<TableController data={adsData} topHeight={188}>
							<Column width={50} align="center" fixed>
								<HeaderCell>Id</HeaderCell>
								<Cell dataKey="id" />
							</Column>
							<Column minWidth={150} flexGrow={0.5}>
								<HeaderCell>Start</HeaderCell>
								<Cell>
									{(rowData) => moment(rowData.start_time * 1000).format('YYYY-MM-DD HH:mm')}
								</Cell>
							</Column>
							<Column minWidth={150} flexGrow={0.5}>
								<HeaderCell>End</HeaderCell>
								<Cell>
									{(rowData) => rowData.end_time ? moment(rowData.end_time * 1000).format('YYYY-MM-DD HH:mm') : '未設定'}
								</Cell>
							</Column>
							<Column width={150} align="center">
								<HeaderCell>Group Title</HeaderCell>
								<Cell dataKey="group_title" />
							</Column>
							<Column width={150} align="center">
								<HeaderCell>Title</HeaderCell>
								<Cell dataKey="title" />
							</Column>
							<Column width={150}>
								<HeaderCell>Description</HeaderCell>
								<UrlCell dataKey="description" />
							</Column>
							<Column minWidth={100} >
								<HeaderCell>Img</HeaderCell>
								<ImgCell dataKey="img" />
							</Column>
							<Column minWidth={100} align="center">
								<HeaderCell>Action</HeaderCell>
								<ParseCell dataKey="action" action="action" />
							</Column>
							<Column minWidth={120} flexGrow={0.5}>
								<HeaderCell>Link</HeaderCell>
								<ParseCell dataKey="action" action="payload" />
							</Column>
							<Column minWidth={50} align="center">
								<HeaderCell>Priority</HeaderCell>
								<Cell dataKey="priority" />
							</Column>
							<Column minWidth={180} flexGrow={0.5} align="center">
								<HeaderCell></HeaderCell>
								<ActionCell dataKey="id" handleUpdate={handleOpenUpdate} handleDelete={handleDelete} />
							</Column>
						</TableController>
						{showLoadMore && <div className="load-more-text subtitle_01" onClick={fetchTable}>Load more</div>}
					</div>
				}
				{(toggleSimulator || addModel) && <Simulator filterData={filterData} currentTab={currentTab} addModel={addModel} form={form} handleSimulatorDate={handleSimulatorDate} />}
			</div>
		)
	}
}

export default withAdsStateControl(Card)
