import { cloneDeep, omit } from 'lodash'
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'

const defaultValue = {
  code: '',
  start_time: Math.floor(Date.now() / 1000),
  end_time: Math.floor(Date.now() / 1000),
  coupons: { sender: [], receiver: [] },
  first_trip_reward: { sender: [], receiver: [] },
  bot_card: {
    event_title: '',
    official_site_url: '',
    firstTripReminder: {
      image: '',
      title: {
        sender: '',
        receiver: '',
      },
      description: '',
      button_color: '',
    },
    firstTripBonusLinePoints: {
      image: '',
      title: '',
      description: '',
      button_color: '',
    },
    share_client: {
      title: '',
      banner: '',
      content: '',
      solid_button_label: '',
      sub_button_label: '',
      sub_button_link: '',
    },
  },
  mgm_share_list: {
    code: '',
    title: '',
    banner: '',
    desc: '',
    buttonText: '',
    activityLink: '',
    friend: {
      defaultAvatar: '',
      formerText: '',
      latterText: '',
    },
    colors: {
      mode: 'dark',
      neutral: '',
      mainBg: '',
      listBg: '',
      title: '',
      buttonBg: '',
      buttonBorder: '',
      buttonText: '',
      box: {
        boxBg: '',
        boxTitle: '',
        boxText: '',
      },
      tag: {
        oldMember: '',
        firstRide: '',
        newMember: '',
      },
    },
  },
  share_page: {
    code: '',
    sender: {
      OPEN: {
        text: '',
        img: '',
      },
      ISSUED: {
        text: '',
        img: '',
      },
    },
    receiver: {
      description: '',
      register: '',
      rule: '',
      RECEIVED: {
        title: '',
        img: '',
      },
      DUE: {
        title: '',
        img: '',
      },
    },
    expired: {
      title: '',
      img: '',
      description: '',
    },
    share: {
      title: '',
      description: '',
      img: '',
      buttonText: '',
    },
    colors: {
      mode: 'dark',
      neutral: '',
      mainBg: '',
      title: '',
      buttonBg: '',
      buttonBorder: '',
      buttonText: '',
      awardText: '',
      awardBg: '',
      tagBg: '',
      tagText: '',
    },
  },
}

export const MGMConfigContext = createContext({
  value: defaultValue,
  updateValue: () => {},
})

export const useMGMConfig = () => useContext(MGMConfigContext)

const convertData = (key, value) => {
  switch (key) {
    case 'start_time':
      return Math.floor(new Date(value).valueOf() / 1000)
    case 'end_time':
      return Math.floor(new Date(value).valueOf() / 1000)
    case 'coupons': {
      const newValue = {}
      newValue.sender = value.sender.map((item) => omit(item, ['id', 'type']))
      newValue.receiver = value.receiver.map((item) => omit(item, ['id', 'type']))
      return newValue
    }
    default:
      return value
  }
}

export default function Provider({ children }) {
  const [value, setValue] = useState(defaultValue)

  const valueToCopy = useMemo(() => {
    return Object.keys(value).reduce((prev, current) => {
      return { ...prev, [current]: typeof value[current] !== 'string' ? JSON.stringify(value[current]) : value[current] }
    }, {})
  }, [value])

  const updateValue = useCallback((field = '', value = '') => {
    setValue((prev) => {
      const next = cloneDeep(prev)
      next[field] = convertData(field, value)
      if (field === 'code') {
        next.mgm_share_list.code = next[field]
        next.share_page.code = next[field]
      }
      return next
    })
  }, [])

  return <MGMConfigContext.Provider value={{ value, valueToCopy, updateValue }}>{children}</MGMConfigContext.Provider>
}
