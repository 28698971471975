import dayjs from 'dayjs'
import { toNumber, find, isEmpty } from 'lodash'

const handleEliteStatus = (driverInfo) => {
  let status = ''
  const [currentMonth, currentDate] = dayjs().format('MM-DD').split('-').map(toNumber)
  const { driver_id, elite, accept_at, equipped_docs } = driverInfo
  if (!driver_id) return status
  if (elite) {
    status = '本季已通過'
    // 如果在下季開放時間
    if ([3, 6, 9, 12].includes(currentMonth)) {
      if(isEmpty(equipped_docs)) status += '/下季未申請'
      if(find(equipped_docs, { review_type: 'REJECT' })) status += '/下季未通過'
      if(accept_at) status += '/下季已通過'
    }
  } else {
    if(isEmpty(equipped_docs)) status = '本季未申請'
    if(find(equipped_docs, { review_type: 'REJECT' })) status = '本季未通過'
  }
  return status
}

export default handleEliteStatus
