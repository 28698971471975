import styled from 'styled-components'

export const PreviewMainPageWrapper = styled.div`
  width: 100%;
  max-width: 375px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.mainBg};

  & .top {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;

    & .icon {
      width: 24px;
      height: 24px;
      color: white;
    }
  }

  & .body {
    width: 100%;
    padding: 24px 24px 36px;

    & .heading {
      font-size: 30px;
      font-weight: 600;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 22px;
      color: ${(props) => props.theme.title};
    }

    & .cover {
      width: 274px;
      height: 196px;
    }

    & .content {
      font-size: 13px;
      line-height: 1.38;
      text-align: center;
      margin: 32px 0;
      color: ${(props) => props.theme.neutral};
      white-space: pre-line;
    }

    & .solid-button {
      width: 100%;
      padding: 18px 16px;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid;
      margin-bottom: 26px;
      color: ${(props) => props.theme.buttonText};
      background-color: ${(props) => props.theme.buttonBg};
      border-color: ${(props) => props.theme.buttonBorder};
      font-size: 20px;
      font-weight: 600;
    }

    & .text-link {
      opacity: 0.6;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71;
      text-align: center;
      color: #fff8ef;
      text-decoration: underline;
    }
  }

  & .bottom {
    width: 100%;
    padding: 24px 12px 40px;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.listBg};

    & .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
      text-align: center;
      color: ${(props) => props.theme.title};
      margin-bottom: 20px;
    }

    & .row {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
      margin: 0;
    }

    & .cell {
      flex: 1;
      background-color: ${(props) => props.theme.box.bg};
      padding: 12px 10px;
      border-radius: 5px;

      & .cell-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        text-align: left;
        color: ${(props) => props.theme.box.title};
      }

      & .text {
        display: flex;
        gap: 4px;
        align-items: center;
        color: ${(props) => props.theme.box.text};
        font-size: 13px;
        line-height: 1.38;
        text-align: left;

        & .bold {
          font-size: 20px;
          font-weight: 600;
          line-height: 1.2;
          text-align: left;
        }
      }
    }

    .list {
      width: 100%;
      margin-top: 32px;

      & .list-item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 14px 0;

        & .image {
          width: 42px;
          height: 42px;
        }

        & .item-title {
          font-size: 14px;
          line-height: 1.5;
          text-align: left;
          color: #fff8ef;
        }

        & .item-content {
          font-size: 12px;
          line-height: 1.5;
          text-align: left;
          color: #fff8ef;
          opacity: 0.6;
        }

        & .item-body {
          flex: 1;
        }

        & .badge {
          padding: 2px 6px;
          border-radius: 3px;
          font-size: 12px;
          line-height: 1.5;
          text-align: left;
          color: #fff;

          &.first-ride {
            background-color: ${(props) => props.theme.tag.firstRide};
          }
          &.new-member {
            background-color: ${(props) => props.theme.tag.newMember};
          }
          &.old-member {
            background-color: ${(props) => props.theme.tag.oldMember};
          }
        }
      }
    }
  }
`

export const PreviewShareSuccessPageWrapper = styled.div`
  width: 100%;
  max-width: 375px;
  min-height: 762px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.mainBg};

  & .top {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;

    & .icon {
      width: 24px;
      height: 24px;
      color: white;
    }
  }

  & .body {
    width: 100%;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .cover {
      width: 160px;
      height: 160px;
      margin-top: 150px;
      margin-bottom: 16px;
    }

    & .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.22;
      text-align: center;
      color: ${(props) => props.theme.title};
      margin-bottom: 12px;
    }

    & .content {
      opacity: 0.6;
      font-size: 14px;
      line-height: 1.5;
      text-align: center;
      color: #fff8ef;
      margin-bottom: 60px;
      white-space: pre-line;
    }

    & .solid-button {
      width: 220px;
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 18px 16px;
      border-radius: 7px;
      border: 1px solid;
      color: ${(props) => props.theme.buttonText};
      background-color: ${(props) => props.theme.buttonBg};
      border-color: ${(props) => props.theme.buttonBorder};
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
      text-align: center;
      color: #fff;
    }
  }
`

export const PreviewOpenedPageWrapper = styled.div`
  width: 100%;
  max-width: 375px;
  min-height: 762px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.mainBg};

  & .top {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;

    & .icon {
      width: 24px;
      height: 24px;
      color: white;
    }
  }

  & .body {
    width: 100%;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .cover {
      width: 160px;
      height: 160px;
      margin-top: 150px;
      margin-bottom: 16px;
    }

    & .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.22;
      text-align: center;
      color: ${(props) => props.theme.title};
      margin-bottom: 12px;
    }
  }
`

export const PreviewNotOpenYetPageWrapper = PreviewOpenedPageWrapper

export const PreviewVisitorPageWrapper = styled.div`
  width: 100%;
  max-width: 375px;
  min-height: 762px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.mainBg};
  position: relative;

  & .top {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    & .icon {
      width: 24px;
      height: 24px;
      color: white;
    }
  }

  & .body {
    width: 100%;
    padding: 30px 24px 36px;

    & .title-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 14px;

      & .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.38;
        text-align: center;
        color: ${(props) => props.theme.title};
        border-bottom: 2px solid ${(props) => props.theme.title};
      }
    }

    & .heading {
      font-size: 30px;
      font-weight: 600;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 16px;
      color: ${(props) => props.theme.title};
    }

    & .cover {
      width: 270px;
      height: 237px;
    }

    & .content {
      font-size: 14px;
      line-height: 1.5;
      text-align: center;
      color: #fff8ef;
      margin: 30px 0 56px;
      color: ${(props) => props.theme.neutral};
      white-space: pre-line;
    }

    & .solid-button {
      width: 100%;
      padding: 18px 16px;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid;
      color: ${(props) => props.theme.buttonText};
      background-color: ${(props) => props.theme.buttonBg};
      border-color: ${(props) => props.theme.buttonBorder};
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
    }

    & .tip {
      opacity: 0.8;
      font-size: 13px;
      line-height: 1.38;
      text-align: left;
      color: ${(props) => props.theme.neutral};
      margin-top: 10px;
    }

    & .description {
      opacity: 0.4;
      font-size: 14px;
      line-height: 1.5;
      text-align: left;
      color: #fff8ef;
      margin-top: 24px;
    }
  }
`

export const PreviewJoinedPageWrapper = styled.div`
  width: 100%;
  max-width: 375px;
  min-height: 762px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.mainBg};
  position: relative;

  & .top {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    & .icon {
      width: 24px;
      height: 24px;
      color: white;
    }
  }

  & .body {
    width: 100%;
    padding: 100px 24px 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .cover {
      width: 160px;
      height: 160px;
      margin: 48px 0;
    }

    & .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
      text-align: center;
      color: #f0bdb0;
      margin-top: 52px;
      white-space: pre-line;
    }

    & .solid-button {
      width: 100%;
      padding: 18px 16px;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid;
      color: ${(props) => props.theme.buttonText};
      background-color: ${(props) => props.theme.buttonBg};
      border-color: ${(props) => props.theme.buttonBorder};
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
    }

    & .tip {
      opacity: 0.8;
      font-size: 13px;
      line-height: 1.38;
      text-align: left;
      color: ${(props) => props.theme.neutral};
      margin-top: 10px;
    }

    & .description {
      opacity: 0.4;
      font-size: 14px;
      line-height: 1.5;
      text-align: left;
      color: #fff8ef;
      margin-top: 24px;
    }
  }
`

export const PreviewExpiredPageWrapper = PreviewJoinedPageWrapper

export const PreviewBotCardWrapper = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: solid 1px #cecece;

  & .cover {
    width: 100%;
    height: 195px;
    object-fit: contain;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  & .body {
    width: 100%;
    padding: 16px 20px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    & .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
      color: #333333;
      margin-bottom: 12px;
    }

    & .content {
      font-size: 14px;
      line-height: 1.5;
      color: #666;
      margin-bottom: 20px;
      white-space: pre-line;
    }

    & .primary-button {
      height: 46px;
      border-radius: 7px;
      background-color: #2d2f2e;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 1.5;
      color: #fff;
      margin-bottom: 4px;
    }

    & .secondary-button {
      height: 46px;
      border-radius: 7px;
      font-size: 16px;
      line-height: 1.5;
      color: #6e84a0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
