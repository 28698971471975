import React from 'react'

function ListWrap({ status, children, addListText, onClick, onClickSub, addListTextSub }) {
	return (
		<div className="list-wrap" >
			<div className="list-status-wrap">
				<h4 className="list-status">{status}</h4>
				<div className="add-list-wrap">
					{addListText && <button className="add-list" onClick={onClick}>{addListText}</button>}
					{addListTextSub && <button className="add-list-sub" onClick={onClickSub}>{addListTextSub}</button>}
				</div>
			</div>
			<div className="list-top">
				{children}
			</div>
		</div>
	)
}

export default React.memo(ListWrap)
