import React, { useState, useEffect, useContext } from 'react'
import { ContextStore } from '../../../reducers'
import { CheckboxGroup, FileInput } from '../../../components/marketing'
import AddListModel from '../../../components/views/AddListModel'
import FormGrop from '../../../components/views/FormGrop'
import AddListGrop from '../../../components/views/AddListGrop'
import PopWarning from '../../../components/popWarning'
import { postUserReport } from '../../../api'

const initState = [
  { id: 1, value: 'uid', isChecked: false },
  { id: 2, value: 'phone_number', isChecked: false },
  { id: 3, value: 'line_id', isChecked: false },
  { id: 4, value: 'email', isChecked: false },
]

const UserReport = () => {
  const { auth } = useContext(ContextStore)
  const [warning, setWarning] = useState('')
  const [userId, setUserId] = useState(null)
  const [file, setFile] = useState(null)
  const [options, setOptions] = useState(initState)

  useEffect(() => {
    if (auth && auth.user) setUserId(auth.user.admin_id)
  }, [auth])

  const handleChangeFile = (e) => {
    const file = e.target.files[0]
    if (file) setFile(file)
  }

  const handleSubmit = async () => {
    if (!userId || !file) return

    const formData = new FormData()
    formData.append('excel', file)
    formData.append('options', JSON.stringify(options))
    const response = await postUserReport(formData)
    console.log('response', response)
    if (response.status !== 200) {
      setWarning(response.data.msg)
      return
    }
    setWarning('上傳成功！請到信箱收取報表')
    setFile(null)
    setOptions(initState)
  }

  return (
    <>
      {warning && <PopWarning warning={warning} textCenter confirm={() => setWarning('')} />}
      <AddListModel>
        <AddListGrop title="獲取報表">
          <FormGrop title="上傳用戶名單（excel 檔）" type="children">
            <FileInput handleChangeFile={handleChangeFile} />
          </FormGrop>
          <FormGrop title="需求資料欄位" type="children">
            <CheckboxGroup options={options} setOptions={setOptions} />
          </FormGrop>
        </AddListGrop>
        <div className="campaign-button-wrap">
          <button className="campaign-confirm-button" onClick={handleSubmit}>
            送出
          </button>
        </div>
      </AddListModel>
    </>
  )
}

export default UserReport
