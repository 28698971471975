import React from 'react'

function FormGropWrap({ children, warningText }) {
	return (
		<div className="add-formGrop-wrap">
			<div className="add-formGrop-children-wrap">
				{children}
			</div>
			{warningText && <div className="subtitle_02">{warningText}</div>}
		</div>
	)
}

export default React.memo(FormGropWrap)
