import React from 'react'
import { Icon, Dropdown } from 'rsuite'
import { Link } from 'react-router-dom'

const Drawer = ({ item, index }) => {
  return (
    <Dropdown eventKey={index} title={item.drawer.name} icon={<Icon icon={item.drawer.img} />} trigger="click" placement="rightStart">
      {item.drawer.item.map((el, index) => (
        <Link key={`${index.toString()}`} to={el.link}>
          <Dropdown.Item componentClass="span">{el.title}</Dropdown.Item>
        </Link>
      ))}
    </Dropdown>
  )
}

export default Drawer
