import React, { useCallback, useMemo } from 'react'
import { Alert, Button, ButtonToolbar, Icon, IconButton, Modal, Steps } from 'rsuite'
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { DataGroup, ConfigLabel, Content, Footer, Root, Stack, StepsBox, Title, ConfigGroup, ConfigValue } from './index.style'
import StepInfo from './StepInfo'
import StepCoupons from './StepCoupons'
import StepAward from './StepAward'
import StepBotCard from './StepBotCard'
import StepLandingPage from './StepLandingPage'
import StepSharePage from './StepSharePage'
import useDisclosure from '../../hook/useDisclosure'
import Provider, { useMGMConfig } from './provider'
import useClipboard from '../../hook/useClipboard'

function MGMConfigModal({ onHide, show }) {
  const { valueToCopy } = useMGMConfig()
  const { copy } = useClipboard()

  const handleClickCopy = useCallback(
    (text) => () => {
      copy(text)
      Alert.success('複製成功!')
    },
    [copy],
  )

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>MGM Config Result</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack>
          <DataGroup>
            <ConfigLabel>code</ConfigLabel>
            <ConfigGroup>
              <ConfigValue>{valueToCopy.code}</ConfigValue>
              <IconButton icon={<Icon icon="copy" />} onClick={handleClickCopy(valueToCopy.code)} />
            </ConfigGroup>
          </DataGroup>
          <DataGroup>
            <ConfigLabel>start_time</ConfigLabel>
            <ConfigGroup>
              <ConfigValue>{valueToCopy.start_time}</ConfigValue>
              <IconButton icon={<Icon icon="copy" />} onClick={handleClickCopy(valueToCopy.start_time)} />
            </ConfigGroup>
          </DataGroup>
          <DataGroup>
            <ConfigLabel>end_time</ConfigLabel>
            <ConfigGroup>
              <ConfigValue>{valueToCopy.end_time}</ConfigValue>
              <IconButton icon={<Icon icon="copy" />} onClick={handleClickCopy(valueToCopy.end_time)} />
            </ConfigGroup>
          </DataGroup>
          <DataGroup>
            <ConfigLabel>coupons</ConfigLabel>
            <ConfigGroup>
              <ConfigValue>{valueToCopy.coupons}</ConfigValue>
              <IconButton icon={<Icon icon="copy" />} onClick={handleClickCopy(valueToCopy.coupons)} />
            </ConfigGroup>
          </DataGroup>
          <DataGroup>
            <ConfigLabel>first_trip_reward</ConfigLabel>
            <ConfigGroup>
              <ConfigValue>{valueToCopy.first_trip_reward}</ConfigValue>
              <IconButton icon={<Icon icon="copy" />} onClick={handleClickCopy(valueToCopy.first_trip_reward)} />
            </ConfigGroup>
          </DataGroup>
          <DataGroup>
            <ConfigLabel>bot_card</ConfigLabel>
            <ConfigGroup>
              <ConfigValue>{valueToCopy.bot_card}</ConfigValue>
              <IconButton icon={<Icon icon="copy" />} onClick={handleClickCopy(valueToCopy.bot_card)} />
            </ConfigGroup>
          </DataGroup>
          <DataGroup>
            <ConfigLabel>mgm_share_list</ConfigLabel>
            <ConfigGroup>
              <ConfigValue>{valueToCopy.mgm_share_list}</ConfigValue>
              <IconButton icon={<Icon icon="copy" />} onClick={handleClickCopy(valueToCopy.mgm_share_list)} />
            </ConfigGroup>
          </DataGroup>
          <DataGroup>
            <ConfigLabel>share_page</ConfigLabel>
            <ConfigGroup>
              <ConfigValue>{valueToCopy.share_page}</ConfigValue>
              <IconButton icon={<Icon icon="copy" />} onClick={handleClickCopy(valueToCopy.share_page)} />
            </ConfigGroup>
          </DataGroup>
        </Stack>
      </Modal.Body>
    </Modal>
  )
}

function MGMGenerator() {
  const history = useHistory()
  const { pathname } = useLocation()
  const { path, url } = useRouteMatch()
  const [isModalOpened, modalControl] = useDisclosure()

  const currentStep = useMemo(() => {
    if (pathname.includes('step')) {
      return Number(pathname.substring(pathname.length - 1))
    }
    return 1
  }, [pathname])

  const getStepStatus = useCallback(
    (num) => {
      if (currentStep === num) {
        return 'process'
      }
      return 'wait'
    },
    [currentStep],
  )

  const handleClickStepItem = useCallback(
    (num) => () => {
      history.push(`${url}/step${num}`)
    },
    [history, url],
  )

  return (
    <Provider>
      <Root>
        <Title>MGM活動產生器</Title>
        <StepsBox>
          <Steps style={{ width: '60rem' }} current={currentStep}>
            <Steps.Item title="活動基本資訊" description="" status={getStepStatus(1)} onClick={handleClickStepItem(1)} />
            <Steps.Item title="優惠券" description="" status={getStepStatus(2)} onClick={handleClickStepItem(2)} />
            <Steps.Item title="首搭/租獎項" description="" status={getStepStatus(3)} onClick={handleClickStepItem(3)} />
            <Steps.Item title="推播小卡" description="" status={getStepStatus(4)} onClick={handleClickStepItem(4)} />
            <Steps.Item title="MGM 主頁" description="" status={getStepStatus(5)} onClick={handleClickStepItem(5)} />
            <Steps.Item title="分享頁面" description="" status={getStepStatus(6)} onClick={handleClickStepItem(6)} />
          </Steps>
        </StepsBox>
        <Content>
          <Switch>
            <Route exact path={[path, `${path}/step1`]}>
              <StepInfo />
            </Route>
            <Route path={`${path}/step2`}>
              <StepCoupons />
            </Route>
            <Route path={`${path}/step3`}>
              <StepAward />
            </Route>
            <Route path={`${path}/step4`}>
              <StepBotCard />
            </Route>
            <Route path={`${path}/step5`}>
              <StepLandingPage />
            </Route>
            <Route path={`${path}/step6`}>
              <StepSharePage />
            </Route>
          </Switch>
        </Content>
        <Footer>
          <ButtonToolbar>
            <Button appearance="primary" onClick={modalControl.open}>
              顯示設定結果
            </Button>
          </ButtonToolbar>
        </Footer>
      </Root>
      <MGMConfigModal show={isModalOpened} onHide={modalControl.close} />
    </Provider>
  )
}

export default MGMGenerator
