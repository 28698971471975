import React from 'react'
import { Tag } from 'rsuite'

const VERIFY_STATUS = {
  PROCESSING: 2, // 審核中
  FAILED: 3, // 審核失敗
  SUCCEED: 4, // 審核通過
  NEW: 5, // 新申請
  WAITING_REVIEW: 6, //待人工複審(等同 審核進行中)
  SUSPEND: 9, //暫不審核(等同 審核進行中)
  BLACK_LIST: 99, // 黑名單
  UNKNOWN: -9, // 未填寫(視同尚未送審)
  NONE: -1, // 查無狀態
}

const ReviewLabel = ({ status }) => {
  const reviewStatus = status ?? -1
  let component
  switch (reviewStatus) {
    case VERIFY_STATUS.FAILED:
      component = <Tag color="red">審核失敗</Tag>
      break
    case VERIFY_STATUS.SUCCEED:
      component = <Tag color="cyan">審核通過</Tag>
      break
    case VERIFY_STATUS.NEW:
      component = <Tag color="green">新申請</Tag>
      break
    case VERIFY_STATUS.PROCESSING:
    case VERIFY_STATUS.WAITING_REVIEW:
    case VERIFY_STATUS.SUSPEND:
      component = <Tag color="green">審核中</Tag>
      break
    case VERIFY_STATUS.BLACK_LIST:
      component = <Tag>黑名單</Tag>
      break
    case VERIFY_STATUS.UNKNOWN:
      component = <Tag>尚未送審</Tag>
      break
    case VERIFY_STATUS.NONE:
      component = '-'
      break
    default:
      component = <Tag>{`未知-${reviewStatus}`}</Tag>
      break
  }
  return component
}

export default React.memo(ReviewLabel)
