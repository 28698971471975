import React, { Component } from 'react'
import { IconButton, Icon, Divider } from 'rsuite'
import { Cell } from 'rsuite-table'

export class ActionCell extends Component {
	render() {
		const { rowData, dataKey, handleUpdate, handleDelete, ...props } = this.props
		return (
			<Cell {...props} className="link-group" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<IconButton
					appearance="subtle"
					onClick={() => handleUpdate(rowData)}
					icon={<Icon icon="edit2" />}
				/>
				<Divider vertical />
				<IconButton
					appearance="subtle"
					onClick={() => handleUpdate(rowData, 'copy')}
					icon={<Icon icon="copy-o" />}
				/>
				<Divider vertical />
				<IconButton
					appearance="subtle"
					onClick={() => handleDelete(rowData.id)}
					icon={<Icon icon="trash2" />}
				/>
			</Cell>
		)
	}
}

export default ActionCell
