import React from 'react'
import TagPicker from './TagPicker'
import { ButtonToolbar, Button } from 'rsuite'
import { PanelWrap } from './Wrap'

const ClientPreview = ({ driverId }) => {

	const handleProfile = () => {
		const profileLink = `${process.env.REACT_APP_DRIVER_WEB}/profile?driver=${driverId}`
		window.open(profileLink, "_blank")
	}


	return (
		<PanelWrap header="基本資訊" shaded collapsible >
			<div>
				<ButtonToolbar>
					<Button style={{ marginBottom: 20 }} appearance="default" onClick={handleProfile}>前往我的戰績</Button>
				</ButtonToolbar>
				<TagPicker driverId={driverId}/>
			</div>
		</PanelWrap>
	)
}

export default ClientPreview
