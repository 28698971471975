import styled from 'styled-components'

export const Root = styled.div`
  width: 240px;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
`

export const Cover = styled.img`
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`

export const EmptyCover = styled.div`
  width: 100%;
  height: 160px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: #f8fafc;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
`

export const Heading = styled.h2`
  font-size: 1.25rem;
  line-height: 1.75rem;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const Text = styled.p`
  font-size: 0.8rem;
  line-height: 1.1rem;
  white-space: 'pre-wrap';
  margin: 0;
`

export const PrimaryButton = styled.button`
  width: 100%;
  height: 36px;
  border-radius: 4px;
  background-color: ${(props) => props.bg || '#111111'};
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SecondaryButton = styled.button`
  width: 100%;
  height: 36px;
  border-radius: 4px;
  color: #3b82f6;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`
