import styled from 'styled-components'

export const Wrapper = styled.div`
  pointer-events: initial;
  min-height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  border-left: 1px solid #c5c6c7;
`

export const Top = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 30px 0;
`

export const Heading = styled.h2`
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
  color: #272c36;
`
