import React from 'react'

function AddListModel({ children }) {
	return (
		<div className="add-list-model">
			{children}
		</div>
	)
}

export default React.memo(AddListModel)
