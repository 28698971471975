import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { Button, ButtonGroup, Radio, RadioGroup, Tag, TagGroup } from 'rsuite'
import { Plus } from '@rsuite/icons'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { postCampaign, getUserGroupV2, putUpdateCampaign, putSubmitCampaign, postDamaPushMessage, postDamaImage, getCoupons } from '../../api'
import { createModel } from '../../utils'
import {
  StyledConfirmButton,
  StyledIconButton,
  StyledPageButton,
  StyledDatePicker,
  CreateInput,
  PageButtonWrapper,
  ButtonsWrapper,
  ItemWrapper,
  Title,
  SubTitle,
  CustomCellWrapper,
  RadioWrapper,
  SendTestCampaignWrapper,
} from './styles'
import CustomInputGroupWidthButton from './CustomInputGroupWidthButton'
import CreateCouponFields from './CreateCouponFields'
import CreateImageMapMessage from './CreateImageMapMessage'
import CreateTextMessage from './CreateTextMessage'
import createFlexMessageJSON from './helper/createFlexMessageJSON'
import MultiCheckboxTable from './CustomCell/MultiCheckboxTable'
import NewPopWarning from '../../components/popWarning/NewPopWarning'
import EventConfirmModal from '../../components/dama/EventConfirmModal'
import { VALIDATION, CAMPAIGN_TYPE, EXECUTION_TYPE, MESSAGE_TYPE, ACTION_TYPE, SCHEDULE_TYPE } from './const'
import { hasAudience, hasCoupon, isNumeric } from './helper'

const DEFAULT_NOTIFY_EXECUTION_TYPE = EXECUTION_TYPE.SCHEDULE

const DEFAULT_COUPON_EXECUTION_TYPE = EXECUTION_TYPE.SCHEDULE

const convertCouponFieldToParams = ({ text, ...fields }, executionType, executionTime) => {
  return { ...fields, action: ACTION_TYPE.ISSUE_COUPON, schedule_type: executionType, schedule: executionTime, payload: text }
}
const convertActionsToParams = (actions, executionType, executionTime) => {
  return { action: ACTION_TYPE.SEND_LINE_MESSAGE, schedule_type: executionType, schedule: executionTime, payload: actions }
}

const EventEditor = React.memo(({ isAdd, currentInfo, handleSuccess }) => {
  const [showConfirmModal, setShowConfirmModal] = useState({ open: false, id: '' })
  const [eventName, setEventName] = useState('')
  const [campaignId, setCampaignId] = useState([])
  const [campaignData, setCampaignData] = useState([])
  const [couponFields, setCouponFields] = useState([])
  const [actions, setActions] = useState([])
  const [campaignOffset, setCampaignOffset] = useState(0)
  const [campaignType, setCampaignType] = useState('')
  const [couponExecutionType, setCouponStartType] = useState(DEFAULT_COUPON_EXECUTION_TYPE)
  const [couponExecutionTime, setCouponExecutionTime] = useState('')
  const [notificationExecutionType, setNotificationStartType] = useState(DEFAULT_NOTIFY_EXECUTION_TYPE)
  const [notificationExecutionTime, setNotificationExecutionTime] = useState('')
  const [keyword, setKeyword] = useState('')
  // LT-8379 原本 campaignId 只紀錄 id，但因為要顯示已選分眾的標題資訊，新加一個 state 來紀錄
  const [selectUserGroupData, setSelectUserGroupsData] = useState(new Map())

  const handleOnClickAddCouponField = () => {
    setCouponFields([...couponFields, { text: '', id: dayjs().valueOf() }])
  }
  const handleRemoveCouponField = useCallback(
    (id) => {
      const filter = couponFields.filter((item) => item.id !== id)
      setCouponFields(filter)
    },
    [couponFields],
  )
  const handleOnChangeCouponFields = (value, id) => {
    const fields = couponFields.map((item) => (item.id === id ? { ...item, text: value } : item))
    setCouponFields(fields)
  }
  const handleOnClickAddActions = (isTextMessage) => {
    isTextMessage
      ? setActions([...actions, { text: '', id: dayjs().valueOf(), type: 'text' }])
      : setActions([
          ...actions,
          {
            title: '',
            id: dayjs().valueOf(),
            type: 'imagemap',
            baseSize: {
              width: 1040,
              height: 1040,
            },
          },
        ])
  }
  const handleRemoveActions = useCallback(
    (id) => {
      const filterActions = actions.filter((item) => item.id !== id)
      setActions(filterActions)
    },
    [actions],
  )
  const handleOnChangeTextMessage = (value, id) => {
    const newActions = actions.map((item) => (item.id === id ? { ...item, text: value } : item))
    setActions(newActions)
  }
  const handleOnChangeFlexMessage = (value, type, id) => {
    const newActions = actions.map((item) => (item.id === id ? { ...item, [type]: value } : item))
    setActions(newActions)
  }

  const getCampaignIdData = useCallback(
    async (param = {}) => {
      const params = { limit: 10, offset: campaignOffset * 10, ...param }
      if (isNumeric(keyword)) {
        params.id = keyword
      } else {
        params.search = keyword
      }
      const data = await getUserGroupV2(params)
      if (data.status !== 200) return createModel(<NewPopWarning createPortal warning={data.data.msg || '發生錯誤，請稍後再試'} textCenter />)
      const mapToCampaignData = data.data.map((item) => {
        return {
          ...item,
          Campaign_id: item.id,
          name: item.title,
          member_count: item.count,
          create_time: dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss'),
        }
      })
      // LT-8595 避免用戶中途更換關鍵字、仍停留在後面頁數，導回第一頁
      if (campaignOffset !== 0 && mapToCampaignData.length === 0) setCampaignOffset(0)
      return setCampaignData(mapToCampaignData)
    },
    [campaignOffset, keyword],
  )

  const handleCampaignType = (value) => {
    if (campaignType === value) return
    setCampaignType(value)
  }
  const handleCouponRadio = (value) => {
    if (couponExecutionType === value) return
    setCouponStartType(value)
  }
  const handleNotificationRadio = (value) => {
    if (notificationExecutionType === value) return
    setNotificationStartType(value)
  }
  const isDisplayFollowCouponRadio = () => {
    return campaignType === CAMPAIGN_TYPE.COUPON_AND_AUDIENCE && couponExecutionType === EXECUTION_TYPE.SCHEDULE && couponExecutionTime
  }
  const convertStateToBody = () => {
    const notifications_setting_list = actions.map((item) => {
      if (item.type === MESSAGE_TYPE.FLEX_MESSAGE) {
        // 目前還沒有支援 flexMessage
        return null
      }
      if (item.type === MESSAGE_TYPE.IMAGEMAP) {
        return { type: item.type, ...createFlexMessageJSON(item.altText, item.baseUrl, item.actions) }
      }
      return item
    })

    // 是否指定時間
    let couponsExecuteTime = null
    let notificationExecuteTime = null
    const actionHasCoupon = hasCoupon(campaignType)
    const actionHasAudience = hasAudience(campaignType)

    if (actionHasCoupon) {
      couponsExecuteTime = couponExecutionType === EXECUTION_TYPE.INSTANT ? dayjs().unix() : couponExecutionTime
    }
    if (actionHasAudience) {
      notificationExecuteTime = dayjs().unix()
      if (notificationExecutionType === EXECUTION_TYPE.SCHEDULE) {
        notificationExecuteTime = notificationExecutionTime
      } else if (notificationExecutionType === EXECUTION_TYPE.FOLLOW_COUPON_EXECUTION) {
        notificationExecuteTime = couponExecutionTime
      }
    }
    // const action_push_notification = { execTime: notificationExecuteTime, execute: ACTION_EXECUTE_TIME[campaignType].NOTIFICATION }
    // const action_issue_coupons = { execTime: couponsExecuteTime, execute: ACTION_EXECUTE_TIME[campaignType].COUPONS }

    const couponParams = actionHasCoupon ? couponFields.map((item) => convertCouponFieldToParams(item, couponExecutionType, couponsExecuteTime)) : []
    const actionParams = actionHasAudience ? [convertActionsToParams(actions, notificationExecutionType, notificationExecuteTime)] : []
    const mergedActions = [...couponParams, ...actionParams]

    return {
      notifications_setting_list,
      name: eventName || '',
      schedule: couponsExecuteTime || notificationExecuteTime,
      user_group: campaignId,
      content: {
        type: campaignType,
        notificationExecuteTime,
        couponsExecuteTime,
        actions: mergedActions,
      },
    }
  }
  const postCreateRequest = async (type = 'SCHEDULED') => {
    const body = convertStateToBody()
    /*
    console.log('couponFields...', couponFields)
    console.log('actions...', actions)
    console.log('body...', body)
     */
    const data = await postCampaign({ ...body, type, status: type })
    return data
  }
  const handlePostImage = async (file) => {
    const formData = new FormData()
    formData.append('file', file.blobFile)
    formData.append('doc_type', 'imageMap')
    const data = await postDamaImage('marketing', formData)
    if (data.status !== 200) {
      // createModel(<NewPopWarning createPortal warning={data.msg || '發生錯誤，請稍後再試'} textCenter />)
      return null
    }
    return data?.data
  }
  const handleConfirm = async () => {
    const data = await putSubmitCampaign(showConfirmModal.id)
    setShowConfirmModal({ id: '', open: false })
    if (data.status !== 200) {
      createModel(<NewPopWarning createPortal warning={data.data.msg || '發生錯誤，請稍後再試'} textCenter />)
    } else {
      createModel(<NewPopWarning createPortal warning="成功進入排程！" textCenter />)
      handleSuccess && typeof handleSuccess === 'function' && handleSuccess()
    }
  }
  const handleAddDraftClick = async () => {
    const data = await postCreateRequest(SCHEDULE_TYPE.SCHEDULED)
    if (data.status !== 200) return createModel(<NewPopWarning createPortal warning={data.data.msg || '發生錯誤，請稍後再試'} textCenter />)
    return setShowConfirmModal({ id: data.data.campaign_id, open: true })
  }
  const handleDraftConfirmClick = () => {
    setShowConfirmModal({ id: '', open: false })
    createModel(<NewPopWarning createPortal warning="成功新增草稿！" textCenter />)
    handleSuccess && typeof handleSuccess === 'function' && handleSuccess()
  }
  const handleEditSubmitClick = async () => {
    if (!currentInfo || !currentInfo.id) return
    // const body = convertStateToBody()
    const data = await putSubmitCampaign(currentInfo.id) // , { ...body, type: SCHEDULE_TYPE.INSTANT, status: SCHEDULE_TYPE.INSTANT })
    if (data.status !== 200) {
      createModel(<NewPopWarning createPortal warning={data.data.msg || '發生錯誤，請稍後再試'} textCenter />)
    } else {
      createModel(<NewPopWarning createPortal warning="成功進入排程！" textCenter />)
      handleSuccess && typeof handleSuccess === 'function' && handleSuccess()
    }
  }
  const handleEditUpdateClick = async () => {
    if (!currentInfo || !currentInfo.id) return
    const body = convertStateToBody()
    const data = await putUpdateCampaign(currentInfo.id, { ...body, type: SCHEDULE_TYPE.SCHEDULED, status: SCHEDULE_TYPE.INSTANT })
    if (data.status !== 200) {
      createModel(<NewPopWarning createPortal warning={data.data.msg || '發生錯誤，請稍後再試'} textCenter />)
    } else {
      createModel(<NewPopWarning createPortal warning="更新成功！" textCenter />)
      handleSuccess && typeof handleSuccess === 'function' && handleSuccess()
    }
  }
  const handlePushMessageClick = async () => {
    const data = await postDamaPushMessage({ messages: actions })
    if (!isEmpty(data.data)) {
      const details = data?.data?.details || []
      createModel(
        <NewPopWarning
          createPortal
          warning={details.map((detail) => (
            <>
              {detail.message}
              <br />
            </>
          ))}
          textCenter
        />,
      )
    }
  }
  const inputValidation = useMemo(() => {
    if (isEmpty(campaignId)) return VALIDATION.NO_USER_GROUP
    if (!eventName) return VALIDATION.NO_EVENT_NAME
    if (isEmpty(couponFields) && isEmpty(actions)) return VALIDATION.NO_DATA
    if (campaignType === CAMPAIGN_TYPE.COUPON) {
      if (couponExecutionType === EXECUTION_TYPE.SCHEDULE && !couponExecutionTime) return VALIDATION.NO_EXECUTION_TIME
      return couponFields.some((coupon) => !coupon.text) ? VALIDATION.NO_PROMO_ID : null
    }
    if (notificationExecutionType === EXECUTION_TYPE.SCHEDULE && !notificationExecutionTime) return VALIDATION.NO_EXECUTION_TIME
    if (campaignType === CAMPAIGN_TYPE.COUPON_AND_AUDIENCE) {
      if (couponFields.some((coupon) => !coupon.text)) return VALIDATION.NO_PROMO_ID
      if (couponExecutionType === EXECUTION_TYPE.SCHEDULE && !couponExecutionTime) return VALIDATION.NO_EXECUTION_TIME
    }
    if (isEmpty(actions)) return VALIDATION.ACTIONS_IS_TEMP
    if (actions.some((item) => item.type === MESSAGE_TYPE.TEXT && !item.text)) return VALIDATION.TEXT_IS_TEMP
    if (actions.some((item) => item.type === MESSAGE_TYPE.IMAGEMAP && (!item.altText || !item.baseUrl))) return VALIDATION.TEXT_IS_TEMP
    if (actions.some((item) => item.type === MESSAGE_TYPE.IMAGEMAP && isEmpty(item.actions))) return VALIDATION.ACTIONS_IS_TEMP
    if (
      actions.some(
        (item) =>
          item.type === MESSAGE_TYPE.IMAGEMAP &&
          item.actions.some((action) => !action.type || (action.type === 'uri' && !action.linkUri) || (action.type === 'message' && !action.text)),
      )
    )
      return VALIDATION.ACTIONS_IS_TEMP
    return null
  }, [
    campaignId,
    eventName,
    actions,
    campaignType,
    couponFields,
    couponExecutionType,
    couponExecutionTime,
    notificationExecutionType,
    notificationExecutionTime,
  ])

  // LT-8379 選擇分眾下拉選單資訊補充
  const fetchSelectUserGroupsData = useCallback(
    async (userGroups) => {
      if (isEmpty(userGroups)) return
      // 已經有該 id 資料就不要再打一次 API
      const promises = userGroups.filter((id) => !selectUserGroupData.has(id)).map((id) => getUserGroupV2({ id }))
      const results = await Promise.allSettled(promises)
      const newUserGroupsData = new Map(JSON.parse(JSON.stringify([...selectUserGroupData]))) // deep clone
      results.forEach((res) => {
        const [userGroup] = res.value.data
        newUserGroupsData.set(userGroup.id, userGroup)
      })
      setSelectUserGroupsData(newUserGroupsData)
    },
    [selectUserGroupData],
  )

  const handleSelectUserGroup = (data) => {
    const newUserGroupsData = new Map(JSON.parse(JSON.stringify([...selectUserGroupData]))) // deep clone
    newUserGroupsData.set(data.id, data)
    setSelectUserGroupsData(newUserGroupsData)
  }

  useEffect(() => {
    if (campaignOffset < 0) return
    getCampaignIdData()
  }, [campaignOffset])

  useEffect(() => {
    if (!isEmpty(currentInfo)) {
      setCampaignId(currentInfo.user_group_id || [])
      setEventName(currentInfo.name || '')
      setCampaignType(currentInfo.campaignType || '')
      setActions(currentInfo.actions || [])
      setCouponExecutionTime(currentInfo.couponExecutionTime || '')
      setCouponStartType(currentInfo.couponExecutionType || DEFAULT_COUPON_EXECUTION_TYPE)
      setCouponFields(currentInfo.couponFields || [])
      setNotificationExecutionTime(currentInfo.notificationExecutionTime || '')
      setNotificationStartType(currentInfo.notificationExecutionType || DEFAULT_NOTIFY_EXECUTION_TYPE)
      // LT-8379 獲取已選分眾的資訊
      fetchSelectUserGroupsData(currentInfo.user_group_id || [])
    }
  }, [currentInfo])

  return (
    <>
      <Title>選取分眾名單</Title>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CustomInputGroupWidthButton
          size="md"
          placeholder="輸入ID或名稱搜尋"
          value={keyword}
          onChange={setKeyword}
          onSearch={() => {
            getCampaignIdData({ offset: 0 }) // 更換完關鍵字從第一頁開始
          }}
        />
        {selectUserGroupData.size && (
          <>
            <div>已選分眾</div>
            <TagGroup style={{ marginLeft: 15 }}>
              {campaignId.map((currentId) => (
                <Tag
                  key={currentId}
                  size="lg"
                  closable
                  onClose={() => {
                    // 移除已選分眾
                    const newCampaignId = campaignId.filter((id) => id !== currentId)
                    setCampaignId(newCampaignId)
                  }}
                >
                  {`(${currentId}) ${selectUserGroupData.get(currentId)?.title || ''}`}
                </Tag>
              ))}
            </TagGroup>
          </>
        )}
      </div>
      {!isEmpty(campaignData) && (
        <CustomCellWrapper>
          <MultiCheckboxTable
            data={campaignData}
            setCampaignId={setCampaignId}
            campaignId={campaignId}
            handleSelectUserGroup={handleSelectUserGroup}
          />
          <PageButtonWrapper>
            {campaignOffset !== 0 && (
              <StyledPageButton appearance="ghost" onClick={() => setCampaignOffset(campaignOffset - 1)}>
                上一頁
              </StyledPageButton>
            )}
            {campaignData.length >= 10 && (
              <StyledPageButton appearance="ghost" onClick={() => setCampaignOffset(campaignOffset + 1)}>
                下一頁
              </StyledPageButton>
            )}
          </PageButtonWrapper>
        </CustomCellWrapper>
      )}
      {!!campaignId.length && (
        <>
          <ItemWrapper>
            <Title>活動名稱</Title>
            <CreateInput placeholder="請輸入名稱" value={eventName} onChange={(value) => setEventName(value)} />
          </ItemWrapper>
          <ItemWrapper>
            <Title>活動模式</Title>
            <RadioGroup name="radioList" inline value={campaignType} onChange={(value) => handleCampaignType(value)}>
              <Radio value={CAMPAIGN_TYPE.COUPON}>只發券</Radio>
              <Radio value={CAMPAIGN_TYPE.AUDIENCE}>只推播</Radio>
              <Radio value={CAMPAIGN_TYPE.COUPON_AND_AUDIENCE}>發券+推播</Radio>
            </RadioGroup>
          </ItemWrapper>
        </>
      )}
      {hasCoupon(campaignType) && (
        <>
          <Title>新增分眾發券</Title>
          <ItemWrapper>
            <Button appearance="ghost" block onClick={handleOnClickAddCouponField}>
              + 新增一組 Promo ID
            </Button>
            {!isEmpty(couponFields) && (
              <RadioGroup
                name="radioList"
                defaultValue={couponExecutionType || DEFAULT_COUPON_EXECUTION_TYPE}
                onChange={(value) => handleCouponRadio(value)}
              >
                <SubTitle>發券執行時間</SubTitle>
                <Radio value={EXECUTION_TYPE.INSTANT}>建立活動後立即發券</Radio>
                <RadioWrapper>
                  <Radio value={EXECUTION_TYPE.SCHEDULE}>指定日期時間發券</Radio>
                  <StyledDatePicker
                    disabled={couponExecutionType !== EXECUTION_TYPE.SCHEDULE}
                    format="YYYY-MM-DD HH:mm:ss"
                    placeholder="選擇日期與時間"
                    ranges={[]}
                    onOk={(date) => setCouponExecutionTime(dayjs(date).unix())}
                    onClean={() => setCouponExecutionTime('')}
                    disabledDate={(date) => !dayjs().isBefore(dayjs(date).add(1, 'day'))}
                    disabledHours={(hour, date) => !dayjs().isBefore(dayjs(date).hour(hour))}
                    defaultValue={couponExecutionTime ? couponExecutionTime * 1000 : null}
                  />
                </RadioWrapper>
              </RadioGroup>
            )}
            {couponFields.map((item) => (
              <CreateCouponFields
                key={item.id}
                getCoupons={getCoupons}
                handleOnChangeCouponFields={handleOnChangeCouponFields}
                handleFilterCouponField={() => handleRemoveCouponField(item.id)}
                value={item.text}
                id={item.id}
              />
            ))}
          </ItemWrapper>
        </>
      )}
      {hasAudience(campaignType) && (
        <>
          <Title>分眾推播</Title>
          <ItemWrapper>
            <ButtonsWrapper>
              <StyledIconButton appearance="default" icon={<Plus />} onClick={() => handleOnClickAddActions(true)}>
                新增文字訊息
              </StyledIconButton>
              <StyledIconButton appearance="default" icon={<Plus />} onClick={() => handleOnClickAddActions(false)}>
                新增圖文訊息
              </StyledIconButton>
            </ButtonsWrapper>
            {!isEmpty(actions) && (
              <>
                <RadioGroup
                  name="radioList"
                  defaultValue={notificationExecutionType || DEFAULT_NOTIFY_EXECUTION_TYPE}
                  onChange={(value) => handleNotificationRadio(value)}
                >
                  <SubTitle>發券推播時間</SubTitle>
                  <Radio value={EXECUTION_TYPE.INSTANT}>建立活動後立即推播</Radio>
                  {isDisplayFollowCouponRadio() && <Radio value="FOLLOW_COUPON_EXECUTION">跟著發券進行</Radio>}
                  <RadioWrapper>
                    <Radio value={EXECUTION_TYPE.SCHEDULE}>指定日期時間推播</Radio>
                    <StyledDatePicker
                      disabled={notificationExecutionType !== EXECUTION_TYPE.SCHEDULE}
                      format="YYYY-MM-DD HH:mm:ss"
                      placeholder="選擇日期與時間"
                      ranges={[]}
                      onOk={(date) => setNotificationExecutionTime(dayjs(date).unix())}
                      onClean={() => setNotificationExecutionTime('')}
                      disabledDate={(date) => !dayjs().isBefore(dayjs(date).add(1, 'day'))}
                      disabledHours={(hour, date) => !dayjs().isBefore(dayjs(date).hour(hour))}
                      defaultValue={notificationExecutionTime ? notificationExecutionTime * 1000 : null}
                    />
                  </RadioWrapper>
                </RadioGroup>
                <SendTestCampaignWrapper>
                  <Button appearance="ghost" onClick={handlePushMessageClick}>
                    推播給自己
                  </Button>
                </SendTestCampaignWrapper>
              </>
            )}
            {actions.map((item) =>
              item.type === 'text' ? (
                <CreateTextMessage
                  key={item.id}
                  handleOnChangeTextMessage={handleOnChangeTextMessage}
                  handleFilterActions={() => handleRemoveActions(item.id)}
                  value={item.text}
                  id={item.id}
                />
              ) : (
                <CreateImageMapMessage
                  key={item.id}
                  handleOnChangeFlexMessage={handleOnChangeFlexMessage}
                  handleFilterActions={() => handleRemoveActions(item.id)}
                  handlePostImage={handlePostImage}
                  id={item.id}
                  altText={item.altText || ''}
                  baseUrl={item.baseUrl || ''}
                  setFlexMessage={setActions}
                  actions={actions}
                />
              ),
            )}
          </ItemWrapper>
        </>
      )}
      {isAdd ? (
        <StyledConfirmButton block onClick={handleAddDraftClick} disabled={Boolean(inputValidation)}>
          儲存草稿
        </StyledConfirmButton>
      ) : (
        <ButtonGroup justified className="event-editor-button-group">
          <StyledConfirmButton block onClick={handleEditUpdateClick} disabled={Boolean(inputValidation)}>
            儲存草稿
          </StyledConfirmButton>
          <StyledConfirmButton block onClick={handleEditSubmitClick} disabled={Boolean(inputValidation)}>
            確認新增
          </StyledConfirmButton>
        </ButtonGroup>
      )}
      <EventConfirmModal
        show={showConfirmModal.open}
        handleClose={() => setShowConfirmModal({ id: '', open: false })}
        handleDraftConfirm={handleDraftConfirmClick}
        handleScheduleConfirm={handleConfirm}
      />
    </>
  )
})

export default EventEditor
