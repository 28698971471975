import React from 'react'
import { Table, Checkbox } from 'rsuite'
import styled from 'styled-components'

const StyledTable = styled(Table)`
  margin-bottom: 10px;
`
const CheckboxWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CustomCell = React.memo(({ data, setCampaignId, value }) => {
  const [checkedKeys, setCheckedKeys] = React.useState([])

  const handleCheck = (value, checked) => {
    const keys = checked ? [value] : checkedKeys.filter((item) => item !== value)
    setCheckedKeys(keys)
    checked && setCampaignId(value.Campaign_id)
  }

  return (
    <StyledTable height={250} data={data} virtualized id="table">
      <Table.Column width={50} align="center" flexGrow={0.5} fixed>
        <Table.HeaderCell />
        <Table.Cell dataKey="Campaign_id">
          {(rowData) => (
            <CheckboxWrapper>
              <Checkbox
                value={rowData}
                inline
                onChange={handleCheck}
                checked={checkedKeys.some((item) => item === rowData) || !!(rowData.id && value && rowData.id === value)}
              />
            </CheckboxWrapper>
          )}
        </Table.Cell>
      </Table.Column>
      <Table.Column width={160} flexGrow={0.5} fixed>
        <Table.HeaderCell>ID</Table.HeaderCell>
        <Table.Cell dataKey="Campaign_id" />
      </Table.Column>
      <Table.Column width={250} flexGrow={3}>
        <Table.HeaderCell>名稱</Table.HeaderCell>
        <Table.Cell dataKey="name" />
      </Table.Column>
      <Table.Column align="center" width={160} flexGrow={3}>
        <Table.HeaderCell>名單人數</Table.HeaderCell>
        <Table.Cell dataKey="member_count" />
      </Table.Column>
      <Table.Column width={200} flexGrow={3}>
        <Table.HeaderCell>創建時間</Table.HeaderCell>
        <Table.Cell dataKey="create_time" />
      </Table.Column>
    </StyledTable>
  )
})

export default CustomCell
