import styled from 'styled-components'

export const Label = styled.div`
  padding: 0 12px;
  color: #1675E0;
`

export const LabelWrapper = styled.div`
  line-height: 40px;
  background-color: #F7F7FA;
  border-radius: 4px;
`

export const Subtitle = styled.h6`
  :not(:first-of-type) {
    margin-top: 20px;
  }
`

export const TextWrapper = styled.div`
  div {
    height: 20px;
    line-height: 20px;
  }
`
