/* eslint-disable import/prefer-default-export */
export const ACTION = {
  SET_CHECK_LOGIN_STATUS: 'SET_CHECK_LOGIN_STATUS',
  SET_USER_AUTHENTICATED: 'SET_USER_AUTHENTICATED',
  SET_USER: 'SET_USER',
  LOGOUT: 'LOGOUT',
  LOADING: 'LOADING',
  GET_MARKETING_COUPON: 'GET_MARKETING_COUPON',
  GET_ADS_CAROUSEL: 'GET_ADS_CAROUSEL',
  FILTER_NEWS_ACTIVEKEY: 'FILTER_NEWS_ACTIVEKEY',
  FILTER_NEWS_PERIOD: 'FILTER_NEWS_PERIOD',
  FILTER_NEWS_DRIVERID: 'FILTER_NEWS_DRIVERID',
  SET_NEWS_OFFSET: 'SET_NEWS_OFFSET',
  SORT_NEWS_COLUMN: 'SORT_NEWS_COLUMN',

  /* Dama+ reducer */
  SET_COPY_DAMA_ITEM: 'SET_COPY_DAMA_ITEM',
  SET_READ_DAMA_ITEM: 'SET_READ_DAMA_ITEM',
}
