import React from 'react'

function Input(props) {
	const { name, type, placeholder, value, handleInput, handleSubmit } = props
	return (
		<form onSubmit={(e) => handleSubmit && handleSubmit(e)}>
			<input className="input-wrap" name={name} type={type} placeholder={placeholder} value={value} autoComplete="on" onChange={(e) => handleInput(e)} />
		</form>
	)
}

export default React.memo(Input)
