import React, { useCallback, useEffect } from 'react'
import { find } from 'lodash'
import { Table, Checkbox, Tag, Whisper, Popover } from 'rsuite'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { useLocation } from 'react-router-dom'
import { FREQUENCY_BY_DAY_LABEL } from '../const'

const StyledTable = styled(Table)`
  margin-bottom: 10px;
`
const CheckboxWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MultiCheckboxTable = React.memo(({ data, setCampaignId, campaignId, handleSelectUserGroup }) => {
  const { search } = useLocation()

  useEffect(() => {
    const userGroupId = new URLSearchParams(search).get('user-group')
    if (userGroupId) {
      setCampaignId((prev) => {
        if (!prev.includes(userGroupId)) return [...prev, Number(userGroupId)]
        return prev
      })
    }
  }, [search, setCampaignId, data])

  // 名單可多選，判斷加新 id 或移除 id
  const handleCheck = (checkedItem, checked) => {
    let newCampaignId = [...campaignId]
    if (checked) {
      newCampaignId.push(checkedItem.id)
      // 紀錄該 id 對應的分眾資料
      const selectItem = find(data, { id: checkedItem.id })
      handleSelectUserGroup(selectItem)
    } else {
      newCampaignId = campaignId.filter((id) => id !== checkedItem.id)
    }
    setCampaignId(newCampaignId)
  }

  const renderGroupName = useCallback((rowData) => {
    const color = rowData.end_time && dayjs.unix(rowData.end_time).isAfter(new Date()) ? 'green' : ''
    return (
      <Whisper placement="top" speaker={<Popover>{rowData.name}</Popover>}>
        <div>
          {!!rowData.auto_update && (
            <Tag style={{ marginRight: '4px' }} color={color}>
              範本
            </Tag>
          )}
          {rowData.name}
        </div>
      </Whisper>
    )
  }, [])

  const renderLastAutoUpdateTime = useCallback((rowData) => {
    return rowData.updated_at ? dayjs(rowData.updated_at).format('YYYY-MM-DD HH:mm') : '-'
  }, [])

  const renderFrequencyByDay = useCallback((rowData) => {
    if (rowData.frequency_by_day) {
      return FREQUENCY_BY_DAY_LABEL[rowData.frequency_by_day]
    }
    return '-'
  }, [])

  const renderUpdateEndTime = useCallback((rowData) => {
    return rowData.end_time ? dayjs.unix(rowData.end_time).format('YYYY-MM-DD') : '-'
  }, [])

  return (
    <StyledTable height={250} data={data} virtualized id="table">
      <Table.Column width={50} align="center" flexGrow={0.5} fixed>
        <Table.HeaderCell />
        <Table.Cell dataKey="Campaign_id">
          {(rowData) => (
            <CheckboxWrapper>
              <Checkbox value={rowData} inline onChange={handleCheck} checked={campaignId.includes(rowData.id)} />
            </CheckboxWrapper>
          )}
        </Table.Cell>
      </Table.Column>
      <Table.Column width={160} flexGrow={0.5} fixed>
        <Table.HeaderCell>ID</Table.HeaderCell>
        <Table.Cell dataKey="Campaign_id" />
      </Table.Column>
      <Table.Column width={200} flexGrow={3}>
        <Table.HeaderCell>名稱</Table.HeaderCell>
        <Table.Cell dataKey="name">{renderGroupName}</Table.Cell>
      </Table.Column>
      <Table.Column align="center" width={80}>
        <Table.HeaderCell>Label(分眾拆分)</Table.HeaderCell>
        <Table.Cell dataKey="label" />
      </Table.Column>
      <Table.Column align="center" width={80}>
        <Table.HeaderCell>名單人數</Table.HeaderCell>
        <Table.Cell dataKey="member_count" />
      </Table.Column>
      <Table.Column width={120} flexGrow={2} align="center">
        <Table.HeaderCell>最後自動更新時間</Table.HeaderCell>
        <Table.Cell dataKey="updated_at">{renderLastAutoUpdateTime}</Table.Cell>
      </Table.Column>
      <Table.Column align="center">
        <Table.HeaderCell>自動更新週期</Table.HeaderCell>
        <Table.Cell dataKey="frequency_by_day">{renderFrequencyByDay}</Table.Cell>
      </Table.Column>
      <Table.Column width={120} flexGrow={2} align="center">
        <Table.HeaderCell>更新直到</Table.HeaderCell>
        <Table.Cell dataKey="end_time">{renderUpdateEndTime}</Table.Cell>
      </Table.Column>
      <Table.Column width={200} flexGrow={3}>
        <Table.HeaderCell>創建時間</Table.HeaderCell>
        <Table.Cell dataKey="create_time" />
      </Table.Column>
    </StyledTable>
  )
})

export default MultiCheckboxTable
