
import React from 'react'

function apply(style) {
	if (style instanceof Array) {
		return style.reduce((a, b) => {
			Object.assign(a, b)
			return a
		}, {})
	}

	return style
}

export function View({ style, children }) {
	// return <div style={Object.assign(apply(style), { display: 'flex', 'justify-content': 'center', 'align-content': 'center' })}></div>
	return <div style={Object.assign(apply(style), { display: 'flex', })}>
		{children}
	</div>
}

export function HStack({ style, children }) {
	return <View style={[{ flexDirection: 'row' }, style]}>
		{children}
	</View>
}

export function VStack({ style, children }) {
	return <View style={[{ flexDirection: 'column' }, style]}>
		{children}
	</View>
}

export function Text({ style, children }) {
	return <View style={[style]}>
		{children}
	</View>
}


export function Spacer() {
	return <View style={{ flex: 1 }} />
}
