import dayjs from 'dayjs'
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Alert, Button, ButtonGroup, ButtonToolbar, Icon, IconButton, Modal, Nav, Tag, Tooltip, Whisper } from 'rsuite'
import { Column, HeaderCell, Cell, Table } from 'rsuite-table'
import { deleteCampaign, getCampaignSchedule, getCampaignsV2 } from '../../api'
import SearchInput from '../../components/table/SearchInput'
import {
  CAMPAIGNS_SUB_TAB,
  CAMPAIGNS_TAB,
  EDIT_EVENT_SEARCH_PARAMS_KEY,
  EVENT_MANAGEMENT_SEARCH_PARAMS_KEY,
  FREQUENCY_BY_DAY_LABEL,
  TASK_STATUS,
} from './const'
import { ButtonsWrapper, ManagementContainer, Root, StyledPageButton, Title } from './styles'
import CellWithPopover from './CustomCell/CellWithPopover'

const DEFAULT_TAB = CAMPAIGNS_TAB.DELIVERED
const DEFAULT_SUB_TAB = CAMPAIGNS_SUB_TAB.ALL

const sortWithDESC = (array) => array.sort((a, b) => b.id - a.id)

const EventManagement = () => {
  const { search, pathname } = useLocation()
  const history = useHistory()
  const [searchText, setSearchText] = useState('')
  const [data, setData] = useState([])
  const [isModalShow, setIsModalShow] = useState(false)
  const offsetRef = useRef(0)
  const deleteIdRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isInvoking, setIsInvoking] = useState(false)

  const currentTab = useMemo(() => new URLSearchParams(search).get(EVENT_MANAGEMENT_SEARCH_PARAMS_KEY.TAB) || DEFAULT_TAB, [search])

  const currentSubTab = useMemo(() => {
    const params = new URLSearchParams(search)
    if (params.get(EVENT_MANAGEMENT_SEARCH_PARAMS_KEY.TAB) === CAMPAIGNS_TAB.OTHER) {
      return DEFAULT_SUB_TAB
    }
    return params.get(EVENT_MANAGEMENT_SEARCH_PARAMS_KEY.SUB_TAB) || DEFAULT_SUB_TAB
  }, [search])

  const fetchData = useCallback(async ({ limit = 10, offset = 0, search = '', tab = DEFAULT_TAB, subtab = DEFAULT_SUB_TAB } = {}) => {
    setIsLoading(true)
    try {
      const resp = await getCampaignsV2({ limit, offset, search, tab, subtab })

      if (resp.status !== 200) {
        if (offset > 1) {
          offsetRef.current -= 1
        }
        throw new Error(resp.data.msg)
      }

      if (Array.isArray(resp.data) && resp.data.length) {
        setData((prev) => {
          if (offset > 1) {
            return sortWithDESC([...prev, ...resp.data])
          }
          return sortWithDESC(resp.data)
        })
      } else if (offset > 1) {
        offsetRef.current -= 1
      } else {
        setData([])
      }
    } catch (e) {
      Alert.error(e.message)
    }
    setIsLoading(false)
  }, [])

  useEffect(() => {
    fetchData({ tab: currentTab, subtab: currentSubTab })
  }, [fetchData, currentSubTab, currentTab])

  const handleSearch = useCallback(() => {
    fetchData({ search: searchText, tab: currentTab, subtab: currentSubTab })
  }, [searchText, fetchData, currentSubTab, currentTab])

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13) handleSearch()
    },
    [handleSearch],
  )

  const handleLoadMore = useCallback(() => {
    offsetRef.current += 1
    const params = { limit: 10, offset: offsetRef.current * 10, search: searchText, tab: currentTab, subtab: currentSubTab }
    fetchData(params)
  }, [fetchData, searchText, currentSubTab, currentTab])

  const handleEditRawData = useCallback(
    (rowData) => {
      const { id, status, next_campaign, isTemplate } = rowData
      if (status === TASK_STATUS.PENDING.status && next_campaign) {
        const next = dayjs.unix(next_campaign.schedule)
        const now = dayjs()
        const diff = next.diff(now, 'minutes')
        if (diff < 0) {
          // 下次發送時間是在過去
          return Alert.info('已過去的活動無法編輯')
        }
        if (rowData.template_id) {
          return Alert.info('由範本產生的活動無法編輯')
        }
        if (diff <= 20) return Alert.info('活動開始前20分鐘無法編輯')
      }

      history.push(`/dama/event_management/${id}/edit?${EDIT_EVENT_SEARCH_PARAMS_KEY.IS_TEMPLATE}=${isTemplate}`)
    },
    [history],
  )

  const handleDeleteRawData = useCallback(({ id }) => {
    setIsModalShow(true)
    deleteIdRef.current = id
  }, [])

  const handleDuplicateRawData = useCallback(
    ({ id }) => {
      history.push(`/dama/event_management/${id}/duplicate`)
    },
    [history],
  )

  const handleModalConfirm = useCallback(async () => {
    const id = deleteIdRef.current
    await deleteCampaign(id)
    await fetchData()
    setIsModalShow(false)
    deleteIdRef.current = null
  }, [fetchData])

  const handleModalClose = useCallback(() => {
    setIsModalShow(false)
    deleteIdRef.current = null
  }, [])

  const handleSearchParams = useCallback(
    (key, value) => {
      offsetRef.current = 0
      const params = new URLSearchParams(search)
      if (params.has(key)) {
        params.set(key, value)
      } else {
        params.append(key, value)
      }
      params.sort()
      history.push(`${pathname}?${params.toString()}`)
    },
    [search, history, pathname],
  )

  const handleTabChange = useCallback(
    (value) => () => {
      handleSearchParams(EVENT_MANAGEMENT_SEARCH_PARAMS_KEY.TAB, value)
    },
    [handleSearchParams],
  )

  const handleSubTabChange = useCallback(
    (value) => () => {
      handleSearchParams(EVENT_MANAGEMENT_SEARCH_PARAMS_KEY.SUB_TAB, value)
    },
    [handleSearchParams],
  )

  const renderParentID = useCallback((rowData) => {
    return rowData.template_id || '-'
  }, [])

  const renderCampaignName = useCallback((rowData) => {
    const color = rowData.last_execute_day && dayjs(rowData.last_execute_day).isAfter(dayjs()) ? 'green' : null
    return (
      <>
        {!!rowData.isTemplate && (
          <Tag style={{ marginRight: '4px' }} color={color}>
            範本
          </Tag>
        )}
        {rowData.name}
      </>
    )
  }, [])

  const renderGroupTitle = useCallback((rowData) => {
    if (Array.isArray(rowData.user_group)) {
      return rowData.user_group.map((item, index) => {
        const color = item.end_time && dayjs.unix(item.end_time).isAfter(new Date()) ? 'green' : null
        return (
          <Fragment key={item.user_group_id}>
            {!!item.auto_update && (
              <Tag style={{ marginRight: '4px' }} color={color}>
                範本
              </Tag>
            )}
            {item.user_group_title}
            {rowData.user_group.length - 1 === index ? '' : ' ,'}
          </Fragment>
        )
      })
    }
    return '-'
  }, [])

  const renderPromoID = useCallback((rowData) => {
    const { promo_ids } = rowData

    if (!promo_ids?.length) return '-'
    return promo_ids.join(', ')
  }, [])

  const renderPeriod = useCallback((rowData) => {
    if (rowData.period) {
      return FREQUENCY_BY_DAY_LABEL[rowData.period]
    }
    return '-'
  }, [])

  const renderLastExecuteDay = useCallback((rowData) => {
    if (rowData.last_execute_day) {
      return rowData.last_execute_day
    }
    return '-'
  }, [])

  const renderCouponDeliveryTime = useCallback((rowData) => {
    if (rowData?.coupon_issue_at) {
      return dayjs.unix(rowData.coupon_issue_at).format('YYYY-MM-DD HH:mm:ss')
    }
    if (rowData?.latest_campaign && rowData?.latest_campaign?.schedule) {
      return dayjs.unix(rowData.latest_campaign.schedule).format('YYYY-MM-DD HH:mm:ss')
    }
    return '-'
  }, [])

  const renderLastNotifyTime = useCallback((rowData) => {
    if (rowData.latest_campaign && rowData.latest_campaign.schedule) {
      return dayjs.unix(rowData.latest_campaign.schedule).format('YYYY-MM-DD HH:mm:ss')
    }
    return '-'
  }, [])

  const renderNextNotifyTime = useCallback((rowData) => {
    if (rowData.next_campaign && rowData.next_campaign.schedule) {
      return dayjs.unix(rowData.next_campaign.schedule).format('YYYY-MM-DD HH:mm:ss')
    }
    return '-'
  }, [])

  const renderStatus = useCallback((rowData) => {
    const key = rowData.status
    return (
      <>
        <Icon icon={TASK_STATUS[key]?.icon} style={{ color: TASK_STATUS[key]?.color }} />
        {TASK_STATUS[key]?.text}
      </>
    )
  }, [])

  const renderMemberCount = useCallback((rowData) => {
    const { user_group } = rowData
    if (!Array.isArray(user_group) || !user_group.length) return '-'
    return user_group.reduce((prev, current) => {
      return prev + current.user_count
    }, 0)
  }, [])

  const renderActions = useCallback(
    (rowData) => {
      const STATUS_ACTION_ICON = {
        SUCCESS: [{ icon: 'copy-o', callback: () => handleDuplicateRawData(rowData) }],
        PENDING: [
          { icon: 'edit', callback: () => handleEditRawData(rowData) },
          { icon: 'copy-o', callback: () => handleDuplicateRawData(rowData) },
          { icon: 'trash2', callback: () => handleDeleteRawData(rowData) },
        ],
        PROCESSING: [{ icon: 'copy-o', callback: () => handleDuplicateRawData(rowData) }],
        DRAFT: [
          { icon: 'edit', callback: () => handleEditRawData(rowData) },
          { icon: 'trash2', callback: () => handleDeleteRawData(rowData) },
        ],
        FAILED: [{ icon: 'copy-o', callback: () => handleDuplicateRawData(rowData) }],
        CANCELLED: [{ icon: 'copy-o', callback: () => handleDuplicateRawData(rowData) }],
      }

      const configs = STATUS_ACTION_ICON[rowData.status]
      if (configs) {
        return (
          <ButtonToolbar>
            {configs.map((item) => (
              <IconButton size="sm" key={item.icon} icon={<Icon icon={item.icon} />} onClick={item.callback} circle />
            ))}
          </ButtonToolbar>
        )
      }
      return null
    },
    [handleDeleteRawData, handleDuplicateRawData, handleEditRawData],
  )

  const handleInvoke = useCallback(async () => {
    try {
      setIsInvoking(true)
      const resp = await getCampaignSchedule()
      if (resp.status !== 200) {
        throw new Error(resp.data.msg)
      }
    } catch (e) {
      Alert.error(e.message)
    }
    setIsInvoking(false)
  }, [])

  return (
    <>
      <Root>
        <Title>活動管理</Title>
        <ManagementContainer>
          <SearchInput
            title="輸入ID或名稱搜尋"
            onKeyDown={handleKeyDown}
            onClick={handleSearch}
            value={searchText}
            setSearchWord={setSearchText}
            withButton
          />
          <div className="tab-container">
            <ButtonGroup>
              <Button appearance={currentTab === CAMPAIGNS_TAB.DELIVERED ? 'primary' : 'ghost'} onClick={handleTabChange(CAMPAIGNS_TAB.DELIVERED)}>
                已執行
              </Button>
              <Button appearance={currentTab === CAMPAIGNS_TAB.SCHEDULED ? 'primary' : 'ghost'} onClick={handleTabChange(CAMPAIGNS_TAB.SCHEDULED)}>
                排程中
              </Button>
              <Button appearance={currentTab === CAMPAIGNS_TAB.OTHER ? 'primary' : 'ghost'} onClick={handleTabChange(CAMPAIGNS_TAB.OTHER)}>
                其他
              </Button>
            </ButtonGroup>
          </div>
          {currentTab !== CAMPAIGNS_TAB.OTHER && (
            <Nav appearance="subtle">
              <Nav.Item active={currentSubTab === CAMPAIGNS_SUB_TAB.ALL} onClick={handleSubTabChange(CAMPAIGNS_SUB_TAB.ALL)}>
                全部
              </Nav.Item>
              <Nav.Item active={currentSubTab === CAMPAIGNS_SUB_TAB.TEMPLATE} onClick={handleSubTabChange(CAMPAIGNS_SUB_TAB.TEMPLATE)}>
                範本
              </Nav.Item>
              <Nav.Item active={currentSubTab === CAMPAIGNS_SUB_TAB.CAMPAIGN} onClick={handleSubTabChange(CAMPAIGNS_SUB_TAB.CAMPAIGN)}>
                非範本
              </Nav.Item>
            </Nav>
          )}
          <div className="table-container">
            <Table data={data} shouldUpdateScroll={false} loading={isLoading} fillHeight affixHeader bordered>
              <Column align="center" width={70}>
                <HeaderCell>父活動ID</HeaderCell>
                <Cell dataKey="template_id">{renderParentID}</Cell>
              </Column>
              <Column align="center" width={70}>
                <HeaderCell>ID</HeaderCell>
                <Cell dataKey="id" />
              </Column>
              <Column align="center" width={200}>
                <HeaderCell>活動名稱</HeaderCell>
                <Cell dataKey="name">{renderCampaignName}</Cell>
              </Column>
              <Column align="center" width={200} resizable>
                <HeaderCell>分眾名稱</HeaderCell>
                <CellWithPopover dataKey="user_group" wordWrap renderPopoverContent={renderGroupTitle}>
                  {renderGroupTitle}
                </CellWithPopover>
              </Column>
              <Column align="center" width={80}>
                <HeaderCell>Promo ID</HeaderCell>
                <CellWithPopover dataKey="promoId" wordWrap renderPopoverContent={renderPromoID}>
                  {renderPromoID}
                </CellWithPopover>
              </Column>
              <Column align="center" width={100}>
                <HeaderCell>活動頻率</HeaderCell>
                <Cell dataKey="period">{renderPeriod}</Cell>
              </Column>
              <Column align="center" width={200}>
                <HeaderCell>推播直到</HeaderCell>
                <Cell dataKey="last_execute_day">{renderLastExecuteDay}</Cell>
              </Column>
              <Column align="center" width={250} sortable>
                <HeaderCell>發券時間</HeaderCell>
                <Cell dataKey="couponTime">{renderCouponDeliveryTime}</Cell>
              </Column>
              <Column align="center" width={200} sortable>
                <HeaderCell>上次推播時間</HeaderCell>
                <Cell dataKey="latest_campaign">{renderLastNotifyTime}</Cell>
              </Column>
              <Column align="center" width={200} sortable>
                <HeaderCell>下次推播時間</HeaderCell>
                <Cell dataKey="next_campaign">{renderNextNotifyTime}</Cell>
              </Column>
              <Column align="center" width={100}>
                <HeaderCell>名單人數</HeaderCell>
                <Cell dataKey="memberCount">{renderMemberCount}</Cell>
              </Column>
              <Column align="center" width={100}>
                <HeaderCell>狀態</HeaderCell>
                <Cell dataKey="status">{renderStatus}</Cell>
              </Column>
              <Column align="left" width={150}>
                <HeaderCell>操作</HeaderCell>
                <Cell>{renderActions}</Cell>
              </Column>
            </Table>
          </div>
          <ButtonsWrapper>
            {currentTab === CAMPAIGNS_TAB.SCHEDULED && (
              <Whisper placement="top" trigger="hover" speaker={<Tooltip>系統每小時會自動更新一次排程中的活動，但按此可手動即時更新</Tooltip>}>
                <StyledPageButton appearance="ghost" onClick={handleInvoke} loading={isInvoking}>
                  手動更新
                </StyledPageButton>
              </Whisper>
            )}
            <StyledPageButton appearance="primary" onClick={handleLoadMore} loading={isLoading}>
              載入更多
            </StyledPageButton>
          </ButtonsWrapper>
        </ManagementContainer>
      </Root>
      <Modal show={isModalShow} onHide={handleModalClose} size="xs">
        <Modal.Body>確定要刪除活動？</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleModalConfirm} appearance="primary">
            確認
          </Button>
          <Button onClick={handleModalClose} appearance="subtle">
            取消
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default React.memo(EventManagement)
