import styled from 'styled-components'

export const FormRoot = styled.form``

export const TabRoot = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 30px;
`

export const TabItem = styled.div`
  min-width: 56px;
  min-height: 20px;
  line-height: 1.43;
  padding: 12px 12px 8px;
  border-bottom: 2px solid;
  border-color: ${(props) => (props.isActive ? '#1675e0' : 'transparent')};
  color: ${(props) => (props.isActive ? '#1675e0' : '#8e8e93')};
  cursor: pointer;
`

export const Panel = styled.div`
  border-top: 1px solid #c8d2e0;
  background-color: #ffffff;
`

export const Footer = styled.div`
  width: 100%;
  margin-top: 16px;
  padding: 16px 48px 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  flex-wrap: nowrap;
`

export const Stack = styled.div`
  padding: 32px 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const SectionTitle = styled.div`
  font-size: 18px;
  line-height: 1.33;
  color: #2589f5;
  padding-bottom: 6px;
  border-bottom: 1px solid #d2d2d2;
  display: flex;
  align-items: center;
  gap: 12px;

  & .sub-content {
    font-size: 14px;
    line-height: 1.43;
    color: #c5c6c7;
  }
`

export const FormField = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;

  & > span::after {
    content: '${(props) => (props.isRequired ? '*' : '')}';
    color: #f44336;
  }
`

export const FormLabel = styled.span`
  width: 160px;
  height: 40px;
  font-size: 16px;
  line-height: 1.38;
  color: #7a7a7a;
  display: flex;
  align-items: center;
`

export const FormController = styled.div`
  width: 300px;
  min-height: 36px;
  display: flex;
  align-items: center;
  gap: 12px;

  & > input,
  .rs-picker-input,
  .rs-picker-date,
  .rs-picker-select {
    flex: 1;
  }

  & input::placeholder {
    color: #8e8e93;
  }
`

export const ColorInputGroup = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 12px;

  & > input[type='color'] {
    width: 24px;
    height: 24px;
    padding: 0;
    border-radius: 3px;
    border: solid 1px #7a7a7a;
    &::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    &::-webkit-color-swatch {
      border: none;
    }
  }
`

export const PanelThemeContainer = styled.div`
  width: 100%;
  display: flex;

  & .left-box {
    flex: 36;

    .justify-end {
      display: flex;
      justify-content: flex-end;
    }

    .preview-button {
      background-color: #ff9800;
      color: #ffffff;
    }
  }

  & .right-box {
    flex: 25;
    background-color: #f5f5f5;
  }
`

export const HS = styled.div`
  max-width: calc(100vw - 360px);
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  overflow-x: auto;

  & .box {
    width: ${(props) => props.itemWidth || '360px'};
  }
`
