/* eslint-disable import/prefer-default-export */
import { forEach, find, isEmpty } from 'lodash'

const isSameArea = (loc1, loc2) => {
  return JSON.stringify(loc1) === JSON.stringify(loc2)
}

const getDesignatedAreas = (previousLocation, startLocation = [], endLocation = []) => {
  const areas = []
  const { startLocation: prevStartLocation, endLocation: prevEndLocation } = previousLocation
  // 讓 api 移除舊座標要給 null
  if (!isEmpty(previousLocation)) {
    forEach(prevStartLocation, (prevLoc, id) => {
      if (!find(startLocation, (loc) => isSameArea(loc, prevLoc))) {
        areas.push({ start_location: null, id })
      }
    })
    forEach(prevEndLocation, (prevLoc, id) => {
      if (!find(endLocation, (loc) => isSameArea(loc, prevLoc))) {
        areas.push({ end_location: null, id })
      }
    })
  }

  // 添加新座標
  forEach(startLocation, (loc) => {
    if (!find(prevStartLocation, (preLoc) => isSameArea(loc, preLoc))) areas.push({ start_location: loc })
  })
  forEach(endLocation, (loc) => {
    if (!find(prevEndLocation, (preLoc) => isSameArea(loc, preLoc))) areas.push({ end_location: loc })
  })
  return areas
}

export { getDesignatedAreas }
