import React, { useEffect, useState } from 'react'
import { Breadcrumb, Dropdown, IconButton, Icon } from 'rsuite'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import dayjs from 'dayjs'
import { isEmpty, isArray } from 'lodash'
import { getDeliveryOrders, refundDeliveryOrders } from '../../api'
import AddListModel from '../../components/views/AddListModel'
import FormGrop from '../../components/views/FormGrop'
import AddListGrop from '../../components/views/AddListGrop'
import PopWarning from '../../components/popWarning'
import "react-datepicker/dist/react-datepicker.css"
import { TableController } from '../../components/table'

const Orders = () => {
	const [apiWarning, setApiWarning] = useState('')
	const [code, setCode] = useState('')
	const [selections, setSelections] = useState([])
	const [date, setDate] = useState(dayjs().format('YYYY/MM/DD'))
	const [warning, setWarning] = useState('')
	const [currentInfo, setCurrentInfo] = useState({
		activityType: 'NORMAL',
		activeSelectionIndex: 0,
		activeSelection: '--',
		numberOfVehichle: 1,
		coupon: '',
		startDate: '',
		endDate: '',
		areaType: 'location',
		endAddress: '',
		startLocation: [],
		endLocation: [],
		titleError: '',
		couponError: '',
		startDateError: '',
		endDateError: '',
		startAddressError: '',
		endAddressError: '',
		startLocationError: '',
		endLocationError: '',
		restaurant: undefined
  })

	useEffect(() => {
		fetchDeliveryOrders()
	}, [date])

	useEffect(() => {
		setCurrentInfo({
			activityType: 'NORMAL',
			activeSelectionIndex: 0,
			activeSelection: '--',
			numberOfVehichle: 1,
			searchDate: date,
			endAddress: '',
			startLocation: [],
			endLocation: [],
			titleError: '',
			couponError: '',
			startDateError: '',
			endDateError: '',
			startAddressError: '',
			endAddressError: '',
			startLocationError: '',
			endLocationError: '',
		})
	}, [])

	const fetchDeliveryOrders = async () => {
		const response = await getDeliveryOrders(date)
		if(isEmpty(response) || !isArray(response.data)) return
    console.log('fetchDeliveryOrders', response)

		let index = 0
		const selection = []
		response.data.forEach((order) => {
			const record = selection.filter(({index, resto_name, time_segment, orders}) => resto_name === order.resto_name && time_segment === order.time_segment)
			if (record.length) {
				record[0].orders.push(order)
			} else {
				selection.push({
					index: index++,
					resto_name: order.resto_name, 
					resto_address: order.resto_address, 
					resto_lat: order.resto_lat, 
					resto_lng: order.resto_lng, 
					time_segment: order.time_segment,
					orders: [order]
				})
			}
		})
		setSelections(selection)
		if (selection && selection.length){
			setCurrentInfo({
				...currentInfo,
				activeSelectionIndex: 0,
				activeSelection: `${selection[0].resto_name} ${selection[0].time_segment} ${selection[0].orders.length} 筆訂單`
			})
		}
	}

	const handleSelectionSelect = (eventKey) => {
		if (eventKey === currentInfo.activeSelectionIndex) return
		console.log(currentInfo.activeSelectionIndex)
		
		setCurrentInfo({
			...currentInfo,
			activeSelectionIndex: eventKey,
			activeSelection: `${selections[eventKey].resto_name} ${selections[eventKey].time_segment} ${selections[eventKey].orders.length} 筆訂單`
		})
	}

	const handleOnChange = (e, setStateKey) => {
		const value = e.target ? e.target.value : e
		setCurrentInfo({
      ...currentInfo,
      [setStateKey]: value
    })
	}

	const handleDeleteAction = async () => {
		console.log('handleDeleteAction code', code)
		const response = await refundDeliveryOrders({ code })
		console.log('response', response)
		if (response.status !== 200) {
			setApiWarning(`刪除失敗 \n${response.data.message}`)
		} else {
			fetchDeliveryOrders()
			setWarning('')
		}
		setCode('')
	}

	const showWarningConfirm = async (code) => {
		setApiWarning('')
		setWarning(`確定要刪除這筆訂單 ${code} 嗎？`)
		setCode(code)
	}

	return (
	<>
		<div style={{ width: '100%', backgroundColor: 'white' }}>
		{warning && <PopWarning warning={warning} textCenter={true} confirm={handleDeleteAction} />}
		{apiWarning && <PopWarning warning={apiWarning} textCenter={true} confirm={() => { setApiWarning(''); setWarning(''); setCode('')}} />}
		<AddListModel >
			<Breadcrumb>
				<Breadcrumb.Item >餐廳外送</Breadcrumb.Item>
				<Breadcrumb.Item active>訂單查詢</Breadcrumb.Item>
			</Breadcrumb>
		</AddListModel>
		<AddListGrop title={'選擇日期'}>
			<FormGrop title={'日期'} placeholder={'xxxx-xx-xx'} type={'input'} value={currentInfo.searchDate}  handleOnChange={handleOnChange} setStateKey={'searchDate'} />
			<div className="campaign-confirm-button">
				<button className="campaign-confirm-button" onClick={() => setDate(currentInfo.searchDate)}>{'更新日期'}</button>
			</div>
		</AddListGrop>
		{selections && selections.length &&
			<AddListModel >
				<AddListGrop title={'點餐明細'}>
					<div style={{fontSize: '20px', fontWeight:500, margin: '20px 0'}}>
						{date}
					</div>
					<FormGrop title={'餐廳時段'} type={'children'}>
						<Dropdown
							title={currentInfo.activeSelection}
							activeKey={currentInfo.activeSelectionIndex}
							menuStyle={{width: '100%'}}
							onSelect={handleSelectionSelect}>
							{selections.map((selection)=> {
								return (<Dropdown.Item key={selection.index} eventKey={selection.index} children={`${selection.resto_name} ${selection.time_segment} ${selection.orders.length} 筆訂單`} />)
							})}
						</Dropdown>
					</FormGrop>
				</AddListGrop>
				{selections[currentInfo.activeSelectionIndex] && 
				<TableController data={selections[currentInfo.activeSelectionIndex].orders} topHeight={188}>
					<Column minWidth={50} align="center" fixed>
						<HeaderCell>Id</HeaderCell>
						<Cell dataKey="id" />
					</Column>
					<Column minWidth={50} >
						<HeaderCell>uid</HeaderCell>
						<Cell dataKey="uid" />
					</Column>
					<Column minWidth={50} >
						<HeaderCell>code</HeaderCell>
						<Cell dataKey="code" />
					</Column>
					<Column minWidth={70} >
						<HeaderCell>status</HeaderCell>
						<Cell dataKey="status" />
					</Column>
					<Column minWidth={200} align="center" flexGrow={0.5}>
						<HeaderCell>delivery_address</HeaderCell>
						<Cell dataKey="delivery_address" />
					</Column>
					<Column minWidth={70} flexGrow={0.5} align="center">
						<HeaderCell></HeaderCell>
						<Cell dataKey="id" className="link-group" style={{ display: 'flex', alignItems: 'center' }}>
							{rowData => {
								return (
									<IconButton
										appearance="subtle"
										onClick={() => showWarningConfirm(rowData.code)}
										icon={<Icon icon="trash2" />}
									/>
								);
							}}
						</Cell>
					</Column>
				</TableController>}
			</AddListModel>}
		</div>
	</>
	)
}

export default Orders
