/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { Loader, Button, Alert, TagPicker, Icon, IconButton, RadioGroup, Radio, DatePicker, Input } from 'rsuite'
import { isEmpty, find, map, debounce, maxBy, isNumber } from 'lodash'
import { ContextStore } from '../../reducers'
import { ACTION } from '../../constants/actions'
import PopWarning from '../../components/popWarning'
import { postUserGroup, estimateUserGroup, getUserGroupFilterOptions, getUserGroupV2, uploadUserGroup } from '../../api'
import { AddListModel, FormGrop, AddListGrop, DropdownGroup, SubGroupWrap } from '../../components/views'
import Dimension from './Dimension'
import ExportCsvButton from './Dimension/ExportCsvButton'

const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 5vh 0 2vh;
`
const DimensionWrapper = styled.div`
  padding: 18px;
  border: 2px solid;
  border-radius: 4px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  & > div {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }
  .rs-dropdown-menu {
    z-index: 20;
  }
  ${({ $error }) => `
    background: ${$error ? 'rgba(202,62,71, .1)' : 'rgba(157,211,168,0.3)'};
    border-color: ${$error ? 'rgba(202,62,71, .5)' : '#9dd3a8'};
  `}
`
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-right: 6px;
  }
`
// constants
const USER_PROPORTION = [
  {
    name: '兩組：50%, 50%',
    value: [1, 1],
  },
  {
    name: '兩組：80%, 20%',
    value: [4, 1],
  },
  {
    name: '三組：33%, 33%, 34%',
    value: [1, 1, 1],
  },
]
// TODO: LT-7483 選項暫時先固定，之後再看是否變成 API 拿
const TEST_GROUP = [
  {
    name: 'GENE',
    value: 'GENE',
  },
  {
    name: 'GENE加發券',
    value: 'GENE加發券',
  },
  {
    name: '激進大禮包',
    value: '激進大禮包',
  },
  {
    name: '歷史未搭&64週未搭',
    value: '歷史未搭&64週未搭',
  },
  {
    name: '其他',
    value: '其他',
  },
]
const ADD_GROUP_METHOD = {
  FILTER: 'FILTER', // 依據特定篩選條件
  UPLOAD: 'UPLOAD', // 上傳 csv 檔
  REFERENCE: 'REFERENCE', // 讀取某份已建立名單為依據
}
const UPLOAD_TYPE = {
  UID: {
    type: 'uid',
    name: 'UID',
    template: [
      {
        sheetName: `工作表1`,
        thead: ['101', ' '],
        tbody: [
          ['30', ' '],
          ['3929', ' '],
          ['1639', ' '],
        ],
      },
    ],
  },
  PHONE_NUMBER: {
    type: 'phone_number',
    name: '電話號碼',
    template: [
      {
        sheetName: `工作表1`,
        thead: ['+886988578785', ' '],
        tbody: [
          ['+886900000030', ' '],
          ['+886900003925', ' '],
          ['+886900001639', ' '],
        ],
      },
    ],
  },
}

const AddUserGroup = ({ history }) => {
  const [warning, setWarning] = useState('')
  const [loading, setLoading] = useState(false)
  const [addMethod, setAddMethod] = useState('') // 名單的建立方式：FILTER、UPLOAD、REFERENCE
  const [groupTitle, setGroupTitle] = useState('') // 名單標題
  // 1. 第一種建立方式 FILTER
  const [rules, setRules] = useState([])
  const [filters, setFilters] = useState([])
  const [options, setOptions] = useState([])
  // 2. 第二種建立方式：UPLOAD 上傳檔案
  const [uploadType, setUploadType] = useState(UPLOAD_TYPE.UID.type)
  const [uploadFile, setUploadFile] = useState(null)
  // 3. 第三種建立方式：讀取已建立的名單
  const [referenceGroup, setReferenceGroup] = useState(null)
  // 排除名單功能
  const [searchLoading, setSearchLoading] = useState(false)
  const [userGroups, setUserGroups] = useState([])
  const [cacheData, setCacheData] = useState([])
  const [excludeValue, setExcludeValue] = useState([])
  // 拆分名單功能
  const [proportion, setProportion] = useState(null)
  // 追蹤 Tag 功能（給 DATA/mkt 使用的）
  const [testGroup, setTestGroup] = useState(null)
  const [testWeek, setTestWeek] = useState(null)

  const { damaPlus, damaPlusDispatch } = React.useContext(ContextStore)

  const isAddFilterMode = useMemo(() => addMethod === ADD_GROUP_METHOD.FILTER, [addMethod])
  const isUploadFileMode = useMemo(() => addMethod === ADD_GROUP_METHOD.UPLOAD, [addMethod])
  const isReferenceGroupMode = useMemo(() => !isEmpty(referenceGroup), [referenceGroup])

  const handleParseFile = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = (e) => {
        const data = e.target.result.split(',\r').join()
        resolve(data)
      }
    })
  }

  const handleChangeFile = (e) => {
    const inputFile = e.target.files[0]
    const fileExtension = inputFile?.type.split('/')[1]
    const allowedExtensions = ['csv']
    if (!allowedExtensions.includes(fileExtension)) {
      return Alert.warning('請上傳 csv 文件')
    }
    setUploadFile(inputFile)
  }

  const resetRules = useCallback(() => {
    setRules([])
    setExcludeValue([])
    setProportion(null)
    setTestGroup(null)
    setTestWeek(null)
  }, [])

  const handleSubmit = async (isEstimate) => {
    if (!groupTitle || !addMethod) return setWarning('請輸入分眾名稱或選擇篩選方式！')
    let body
    // 1. 第一種建立方式 FILTER
    if (isAddFilterMode) {
      if (isEmpty(rules) || find(rules, { finished: false })) return setWarning('請完成各項篩選條件的設置！')
      const ruleData = rules.map((data) => {
        const newData = { ...data }
        delete newData.id
        delete newData.finished
        const { coupon_type } = data.parameters
        // 整理多選項為 NONE 或僅一個的情況
        if (coupon_type && coupon_type.length === 1) {
          newData.parameters = {
            ...data.parameters,
            coupon_type: coupon_type[0],
          }
        }
        // 過濾 coupon_type 裡 null 與 undefined，避免 api error （LT-8544）
        else if (Array.isArray(coupon_type)) {
          const safeCouponType = coupon_type.filter((item) => item !== null && item !== undefined)
          newData.parameters = {
            ...data.parameters,
            coupon_type: safeCouponType,
          }
        }
        return newData
      })
      body = {
        title: groupTitle,
        rules: ruleData,
      }
      // 2. 第二種建立方式：讀取已建立的名單
    } else if (isReferenceGroupMode && referenceGroup?.table) {
      const { dataset, table } = referenceGroup
      body = {
        title: groupTitle,
        rules: [
          {
            type: 'USER_GROUP',
            parameters: { dataset, table },
          },
        ],
      }
      // 3. 第三種建立方式： UPLOAD 上傳檔案
    } else {
      const parsedData = await handleParseFile(uploadFile)
      body = {
        title: groupTitle,
        file: parsedData,
        type: uploadType,
      }
    }
    // 其他功能：排除部分名單、拆分比例、追蹤 Tag
    if (excludeValue?.length > 0) body.filter = excludeValue
    if (proportion) body.distribution = proportion
    if (testGroup) body.testGroup = testGroup
    if (testWeek) {
      body.testWeek = testWeek
    } else if (!isUploadFileMode && !isEstimate) {
      return Alert.warning('請選擇 Test Week 分析日')
    }
    // 僅「篩選維度」時，會使用到試算功能
    if (isEstimate) {
      setLoading(true)
      const response = await estimateUserGroup(body)
      setLoading(false)
      if (response.status !== 200) return Alert.error(response.data.msg)
      const { size } = response.data
      return Alert.success(`共有 ${size} 位使用者！`)
    }
    console.log('----body----', body)
    let response
    setLoading(true)
    if (isUploadFileMode) {
      const formBody = Object.keys(body)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`)
        .join('&')
      response = await uploadUserGroup(formBody)
    } else {
      response = await postUserGroup(body)
    }
    setLoading(false)
    if (response.status !== 200) return Alert.error(response.data.msg)
    if (isUploadFileMode) {
      Alert.success('上傳成功！', 2000)
      return history.push('/dama/user_group')
    }
    response.data.forEach(({ title, user_group_id }) => {
      Alert.success(`新增 id 為 ${user_group_id} 的分眾名單「${title}」！`, 2000)
    })
    resetRules()
    setGroupTitle('')
    return history.push('/dama/user_group')
  }

  const handleDimensionType = (id, action, type) => {
    switch (action) {
      case 'add':
        return setRules([...rules, { id, finished: false, type: null, parameters: {} }])
      case 'delete':
        return setRules(rules.filter((data) => data.id !== id))
      case 'edit':
        return setRules(rules.map((data) => (data.id === id ? { ...data, type, finished: false, parameters: {} } : data)))
      default:
        break
    }
  }

  const handleParameters = (id, newRule) => {
    setRules(rules.map((data) => (data.id === id ? newRule : data)))
  }

  const handlePickerSearch = async (keyword, isInitial) => {
    if (!isInitial && !keyword) return
    setSearchLoading(true)
    const response = await getUserGroupV2({ search: keyword })
    if (Array.isArray(response.data)) {
      setSearchLoading(false)
      setUserGroups(response.data.splice(0, 10))
    }
  }

  const debounceSearch = debounce((text) => {
    handlePickerSearch(text)
  }, 300)

  useEffect(() => {
    // 拿維度篩選及選項
    const getFilterAndOptions = async () => {
      const response = await getUserGroupFilterOptions()
      if (response.status !== 200) {
        Alert.error(response.data.msg)
        return
      }
      const { filter, options } = response.data
      const mapFilters = map(filter, (obj) => {
        return {
          ...obj,
          value: obj.type,
        }
      })
      setFilters(mapFilters)
      setOptions(options)
    }
    getFilterAndOptions()
    handlePickerSearch('', true)
  }, [])

  useEffect(() => {
    const getAllUserGroupAndExcludeValue = async (editFilters = []) => {
      const response = await getUserGroupV2({ id: editFilters.join(',') })
      if (response.data) {
        const editCacheData = response.data.filter((data) => editFilters.includes(data.id))
        setCacheData(editCacheData)
        setExcludeValue(editFilters)
      }
    }
    if (filters.length === 0 || options.length === 0) return
    if (damaPlus && !isEmpty(damaPlus.item)) {
      const { item, type } = damaPlus
      const { settings } = item // title, rules, distribution, excludeValue
      // 1. 如果是複製名單條件
      if (type === 'COPY') {
        if (settings?.rules) {
          // 複製的名單，有可能是透過讀取功能建立的、個別處理
          const isCopyReferenceGroup = settings.rules?.length === 1 && settings.rules[0].type === 'USER_GROUP'
          if (isCopyReferenceGroup) {
            const { dataset, table } = settings.rules[0].parameters
            setReferenceGroup({ dataset, table })
            setAddMethod(ADD_GROUP_METHOD.REFERENCE)
          } else {
            const newRules = settings.rules.map((data, index) => ({ ...data, id: index + 1, finished: true }))
            setRules(newRules)
            setAddMethod(ADD_GROUP_METHOD.FILTER)
          }
        }
      }
      // 2. 如果是讀取名單
      if (type === 'READ') {
        const { dataset, table, title, id } = item
        setReferenceGroup({ dataset, table, title, id })
        setAddMethod(ADD_GROUP_METHOD.REFERENCE)
      }
      // distribution、testGroup 和 excludeValue 條件留著，testWeek 考量時間可能超過就不帶入
      if (settings?.distribution) setProportion(settings.distribution)
      if (settings?.testGroup) setTestGroup(settings.testGroup)
      if (settings?.filter) getAllUserGroupAndExcludeValue(settings.filter)
    }

    return () => {
      damaPlusDispatch({ type: ACTION.SET_COPY_DAMA_ITEM, item: {} })
      damaPlusDispatch({ type: ACTION.SET_READ_DAMA_ITEM, item: {} })
    }
  }, [damaPlus, damaPlusDispatch, filters, options])

  return (
    <>
      {warning && <PopWarning warning={warning} textCenter confirm={() => setWarning('')} />}
      <AddListModel>
        {loading && <Loader size="md" center vertical style={{ marginLeft: 260, zIndex: 10 }} />}
        <AddListGrop title="新增分眾名單">
          <h6>分眾名稱</h6>
          <FormGrop placeholder="請輸入 Title" type="input" handleOnChange={(e) => setGroupTitle(e.target.value)} value={groupTitle} />
          {isReferenceGroupMode ? (
            <>
              <h6>讀取名單</h6>
              <Input value={`${referenceGroup?.table} ${referenceGroup?.id ? `（${referenceGroup?.id}-${referenceGroup?.title}）` : ''}`} disabled />
            </>
          ) : (
            <SectionWrapper>
              <h6>篩選方式</h6>
              <RadioGroup className="tag-radio-group" inline value={addMethod} onChange={(value) => setAddMethod(value)} appearance="picker">
                <Radio value="FILTER" onClick={() => handleDimensionType((maxBy(rules, 'id')?.id ?? 0) + 1, 'add')}>
                  新增篩選維度
                </Radio>
                <Radio value="UPLOAD" onClick={resetRules}>
                  檔案上傳
                </Radio>
              </RadioGroup>
            </SectionWrapper>
          )}
          {rules.map((rule) => {
            const { id, type, finished } = rule
            return (
              <DimensionWrapper key={id} $error={!finished}>
                <div>
                  <DropdownGroup
                    style={{ width: 'min-Content', borderRight: '2px solid #dadada', paddingRight: '12px', marginRight: '12px' }}
                    activeKey={type}
                    options={filters}
                    onSelect={(selectType) => handleDimensionType(id, 'edit', selectType)}
                    disabledList={[]}
                    placeholder="選擇維度"
                  />
                  <Dimension data={rule} filters={filters} options={options} handleParameters={handleParameters} />
                </div>
                <Button appearance="ghost" onClick={() => handleDimensionType(id, 'delete')}>
                  - 移除
                </Button>
              </DimensionWrapper>
            )
          })}
          {isUploadFileMode && (
            <>
              <SubGroupWrap title="1. 選擇上傳類型">
                <RadioGroup inline value={uploadType} onChange={(value) => setUploadType(value)}>
                  {Object.values(UPLOAD_TYPE)?.map(({ name, type, template }) => {
                    return (
                      <Radio key={type} value={type}>
                        {name}
                        <ExportCsvButton buttonText="範例下載" fileName={type} sheetData={template} />
                      </Radio>
                    )
                  })}
                </RadioGroup>
              </SubGroupWrap>
              <SubGroupWrap title="2. 上傳 csv">
                <input style={{ padding: '5px' }} type="file" accept=".csv" onChange={handleChangeFile} />
              </SubGroupWrap>
            </>
          )}
          {!isUploadFileMode && (
            <>
              <SectionWrapper>
                <h6>排除已建立分眾名單</h6>
              </SectionWrapper>
              <TagPicker
                creatable={false}
                data={userGroups}
                cacheData={cacheData}
                value={excludeValue}
                placeholder="請輸入欲排除之分眾名單名稱"
                style={{ width: '60vw', padding: '5px 0', margin: '10px 0' }}
                labelKey="title"
                valueKey="id"
                onChange={setExcludeValue}
                onSearch={debounceSearch}
                onSelect={(value, item) => {
                  setCacheData([...cacheData, item])
                }}
                renderMenu={(menu) => {
                  if (searchLoading) {
                    return (
                      <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                        <Icon icon="spinner" spin />
                        Loading...
                      </p>
                    )
                  }
                  return menu
                }}
                renderMenuItem={(label, item) => {
                  return (
                    <span>
                      {`${item.title} `}
                      <span style={{ color: '#777777', fontSize: 10, marginLeft: 10 }}>{`${dayjs(item.created_at).format('YYYY-MM-DD HH:mm')}`}</span>
                      <span style={{ color: '#348498', fontSize: 10, marginLeft: 10 }}>{isNumber(item.label) ? `(label: ${item.label})` : ''}</span>
                    </span>
                  )
                }}
              />
            </>
          )}
          {addMethod && (
            <>
              <SectionWrapper>
                <h6>將篩選結果拆分多組分眾</h6>
              </SectionWrapper>
              <DropdownGroup
                style={{ width: 'min-content', padding: '5px 0', margin: '10px 0' }}
                activeKey={proportion}
                options={USER_PROPORTION}
                onSelect={(value) => setProportion(value)}
                placeholder="選擇拆分比例"
              />
              {proportion && <IconButton appearance="subtle" onClick={() => setProportion(null)} icon={<Icon icon="trash2" />} />}
            </>
          )}
          {!isUploadFileMode && (
            <>
              <SectionWrapper>
                <h6>追蹤參數設定</h6>
              </SectionWrapper>
              <div>
                <DropdownGroup
                  style={{ width: 'min-content', padding: '5px 0', margin: '10px 0' }}
                  activeKey={testGroup}
                  options={TEST_GROUP}
                  onSelect={(value) => setTestGroup(value)}
                  placeholder="Test Group"
                />
                {testGroup && <IconButton appearance="subtle" onClick={() => setTestGroup(null)} icon={<Icon icon="trash2" />} />}
              </div>
              <div>
                <DatePicker
                  style={{ width: 'min-content', padding: '5px 0', margin: '10px 0' }}
                  format="YYYY-MM-DD"
                  value={testWeek ? dayjs(testWeek, 'YYYY-MM-DD').toDate() : null}
                  ranges={[]}
                  appearance="default"
                  cleanable={false}
                  placeholder="Test Week 分析日"
                  disabledDate={(date) => dayjs(date).day() !== 1} // 只能填入星期一作為分析日
                  onChange={(date) => setTestWeek(dayjs(+date).format('YYYY-MM-DD'))}
                />
                {testWeek && <IconButton appearance="subtle" onClick={() => setTestWeek(null)} icon={<Icon icon="trash2" />} />}
              </div>
            </>
          )}
        </AddListGrop>
        <ButtonsWrapper>
          {isAddFilterMode && (
            <Button appearance="default" onClick={() => handleSubmit(true)}>
              人數試算
            </Button>
          )}
          <Button appearance="primary" onClick={() => handleSubmit(false)}>
            送出
          </Button>
        </ButtonsWrapper>
      </AddListModel>
    </>
  )
}

export default AddUserGroup
