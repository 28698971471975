/* eslint-disable consistent-return */
import React, { useState, useEffect, useContext } from 'react'
import { Loader, Button, InputNumber, Dropdown, Input } from 'rsuite'
import { ContextStore } from '../../reducers'
import { FileInput } from '../../components/finance'
import PopWarning from '../../components/popWarning'
import { postBatchAddDriverMileages } from '../../api'
import { AddListModel, FormGrop, AddListGrop } from '../../components/views'

const MILEAGE_CATEGORIES = [
  '活動獎勵',
  '認同獎勵',
  '推薦獎勵',
  '上線獎勵',
  '接單獎勵',
  '優良駕駛',
  '客訴進線懲戒',
  '購買兌換點數',
  '點數過期',
  '加值方案贈點',
]

const DriverMileageBatch = () => {
  const { auth } = useContext(ContextStore)
  const [warning, setWarning] = useState('')
  const [userId, setUserId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState(null)
  const [mileage, setMileage] = useState(10)
  const [category, setCategory] = useState('活動獎勵')
  const [file, setFile] = useState(null)

  useEffect(() => {
    if (auth && auth.user) setUserId(auth.user.admin_id)
  }, [auth])

  const handleChangeFile = (e) => {
    const file = e.target.files[0]
    if (file) setFile(file)
  }

  const handleSubmit = async () => {
    if (!userId || !file) return

    const formData = new FormData()
    formData.append('file', file)
    formData.append('category', category)
    formData.append('title', title)
    formData.append('miles', mileage)
    setLoading(true)
    const response = await postBatchAddDriverMileages(formData)
    setLoading(false)
    if (response.status !== 200) return setWarning(response.data.msg)
    setWarning('上傳成功！')
    setFile(null)
  }

  return (
    <>
      {warning && <PopWarning warning={warning} textCenter confirm={() => setWarning('')} />}
      <AddListModel>
        {loading && <Loader size="md" center vertical style={{ marginLeft: 260, zIndex: 10 }} />}
        <AddListGrop title="司機批次點數發放">
          <FormGrop title="點數項目" type="children">
            <Dropdown
              title={category}
              activeKey={category}
              style={{ width: 250 }}
              menuStyle={{ width: '100%' }}
              onSelect={(value) => setCategory(value)}
            >
              {MILEAGE_CATEGORIES.map((item) => {
                return (
                  <Dropdown.Item key={item} eventKey={item}>
                    {item}
                  </Dropdown.Item>
                )
              })}
            </Dropdown>
          </FormGrop>

          <FormGrop title="點數內容" type="children">
            <Input placeholder="點數內容" value={title} onChange={(value) => setTitle(value)} />
          </FormGrop>
          <FormGrop title="預設點數（如檔案沒有設定 miles 欄位，會自動帶入這個點數）" type="children">
            <InputNumber size="sm" style={{ width: 100 }} min={1} value={mileage} onChange={(value) => setMileage(Number(value))} step={10} />
          </FormGrop>
          <FormGrop title="上傳檔案" type="children">
            <FileInput handleChangeFile={handleChangeFile} />
          </FormGrop>
          <FormGrop type="children">
            <Button appearance="link" onClick={() => window.open(`${process.env.REACT_APP_API_URI}/console/driver/mileage/batch_sample`)}>
              下載範例檔案
            </Button>
          </FormGrop>
        </AddListGrop>
        <div className="campaign-button-wrap">
          <button type="button" className="campaign-confirm-button" onClick={handleSubmit}>
            送出
          </button>
        </div>
      </AddListModel>
    </>
  )
}

export default DriverMileageBatch
