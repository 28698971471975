import React from 'react'
import { Title } from './styles'
import { Divider } from 'rsuite'

const Subtitle = ({ children }) => {
  return (
    <div>
      <Title>{children}</Title>
      <Divider style={{ margin: '6px 0 -6px 0' }} />
    </div>
  )
}

export default React.memo(Subtitle)
