import React, { useCallback, useMemo } from 'react'
import { Button, ButtonToolbar } from 'rsuite'
import { cloneDeep } from 'lodash'
import { ListBox, ListItem, Root, Title } from './index.style'
import CouponInput from '../../../components/coupons/CouponInput'
import { useMGMConfig } from '../provider'

function StepAward() {
  const { value, updateValue } = useMGMConfig()

  const senderCoupons = useMemo(
    () => value.first_trip_reward.sender.map((item, index) => ({ ...item, id: `sender_${index}` })),
    [value.first_trip_reward],
  )

  const receiverCoupons = useMemo(
    () => value.first_trip_reward.receiver.map((item, index) => ({ ...item, id: `receiver_${index}` })),
    [value.first_trip_reward],
  )

  const handleAddTaxiCoupon = useCallback(
    (target) => () => {
      const new_first_trip_reward = cloneDeep(value.first_trip_reward)
      new_first_trip_reward[target].push({ type: 'coupon', value: '' })
      updateValue('first_trip_reward', new_first_trip_reward)
    },
    [value, updateValue],
  )

  const handleAddRentalCoupon = useCallback(
    (target) => () => {
      const new_first_trip_reward = cloneDeep(value.first_trip_reward)
      new_first_trip_reward[target].push({ type: 'rental', value: '' })
      updateValue('first_trip_reward', new_first_trip_reward)
    },
    [value, updateValue],
  )

  const handleChange = useCallback(
    (id) => (val) => {
      const new_first_trip_reward = cloneDeep(value.first_trip_reward)
      const senderCouponIndex = senderCoupons.findIndex((item) => item.id === id)
      const receiverCouponIndex = receiverCoupons.findIndex((item) => item.id === id)
      if (senderCouponIndex >= 0) {
        new_first_trip_reward.sender.splice(senderCouponIndex, 1, { type: senderCoupons[senderCouponIndex].type, value: Number(val) || undefined })
      } else if (receiverCouponIndex >= 0) {
        new_first_trip_reward.receiver.splice(receiverCouponIndex, 1, {
          type: receiverCoupons[receiverCouponIndex].type,
          value: Number(val) || undefined,
        })
      }
      updateValue('first_trip_reward', new_first_trip_reward)
    },
    [receiverCoupons, senderCoupons, updateValue, value],
  )

  const handleDelete = useCallback(
    (id) => () => {
      const new_first_trip_reward = cloneDeep(value.first_trip_reward)
      const senderCouponIndex = senderCoupons.findIndex((item) => item.id === id)
      const receiverCouponIndex = receiverCoupons.findIndex((item) => item.id === id)
      if (senderCouponIndex >= 0) {
        new_first_trip_reward.sender.splice(senderCouponIndex, 1)
      } else if (receiverCouponIndex >= 0) {
        new_first_trip_reward.receiver.splice(receiverCouponIndex, 1)
      }
      updateValue('first_trip_reward', new_first_trip_reward)
    },
    [value, updateValue, senderCoupons, receiverCoupons],
  )

  const renderCouponItems = useCallback(
    (data) => {
      return data.map((item) => {
        return (
          <ListItem key={item.id}>
            {item.type === 'coupon' && <CouponInput variant="taxi" onChange={handleChange(item.id)} value={item.value} />}
            {item.type === 'rental' && <CouponInput variant="rental" onChange={handleChange(item.id)} value={item.value} />}
            <Button onClick={handleDelete(item.id)}>刪除</Button>
          </ListItem>
        )
      })
    },
    [handleChange, handleDelete],
  )

  return (
    <Root>
      <Title>主態獎項</Title>
      <ButtonToolbar>
        <Button appearance="primary" onClick={handleAddTaxiCoupon('sender')}>
          新增乘車券
        </Button>
        <Button appearance="primary" onClick={handleAddRentalCoupon('sender')}>
          新增租車券
        </Button>
      </ButtonToolbar>
      <ListBox>{renderCouponItems(senderCoupons)}</ListBox>
      <Title>客態獎項</Title>
      <ButtonToolbar>
        <Button appearance="primary" onClick={handleAddTaxiCoupon('receiver')}>
          新增乘車券
        </Button>
        <Button appearance="primary" onClick={handleAddRentalCoupon('receiver')}>
          新增租車券
        </Button>
      </ButtonToolbar>
      <ListBox>{renderCouponItems(receiverCoupons)}</ListBox>
    </Root>
  )
}

export default StepAward
