import React from 'react'
import { Icon } from 'rsuite'
import { PreviewVisitorPageWrapper } from './styles'
import defaultTheme from './theme'

const PreviewVisitorPage = ({ theme = defaultTheme, isMember = true }) => {
  return (
    <PreviewVisitorPageWrapper theme={theme}>
      <div className="top">
        <div className="icon" />
        <div className="icon">
          <Icon icon="close" />
        </div>
      </div>
      <div className="body">
        <div className="title-box">
          <div className="title">好友送來</div>
        </div>
        <div className="heading">XXXX乘車券</div>
        <div className="cover" />
        <div className="content">新朋友完成註冊首次搭乘 你和好友都能再獲得LINE POINTS 25點</div>
        <div className="solid-button">{isMember ? '查看乘車券' : '加入 LINE TAXI'}</div>
        {!isMember && <div className="tip">提醒您，需加入 LINE TAXI 好友，並完成註冊，乘車券才能正式歸戶喔！</div>}
        <div className="description">11/4 前每天開啟不同好友分享的萬聖節魔法糖，將驚喜獲得10~100元乘車券！當日開啟有效！</div>
      </div>
    </PreviewVisitorPageWrapper>
  )
}

export default PreviewVisitorPage
