import React, { PureComponent } from 'react'
import moment from 'moment-timezone'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import ListWrap from '../../views/ListWrap'
import AddModel from './AddModel'
import TableController from '../../table/Table'
import ParseCell from '../view/ParseCell'
import ActionCell from '../view/ActionCell'
import ImgCell from '../view/ImgCell'
import Simulator from '../view/Simulator'
import withAdsStateControl from '../../../utils/HOC/withAdsStateControl'

class Carousel extends PureComponent {
  render() {
    const {
      adsData,
      form,
      addModel,
      actionData,
      currentUpdate,
      formError,
      showLoadMore,
      toggleSimulator,
      currentTab,
      filterData,
      isLoading,
      fetchTable,
      handleOpenAddModel,
      handleDatePicker,
      handleActionSelect,
      handleOnChange,
      handlePostTable,
      handleOpenUpdate,
      handleDelete,
      handlePayloadOnChange,
      handleOnUpload,
      handleSimulatorDate,
    } = this.props
    return (
      <div className="carousel-wrap">
        {addModel ? (
          <AddModel
            form={form}
            actionData={actionData}
            currentUpdate={currentUpdate}
            formError={formError}
            adsData={adsData}
            isLoading={isLoading}
            handleCancel={handleOpenAddModel}
            handleDatePicker={handleDatePicker}
            handleActionSelect={handleActionSelect}
            handleOnChange={handleOnChange}
            handleAdd={handlePostTable}
            handlePayloadOnChange={handlePayloadOnChange}
            handleOnUpload={handleOnUpload}
            handleSimulatorDate={handleSimulatorDate}
          />
        ) : (
          <div className="carousel-content">
            <ListWrap addListText="+ 新增活動" onClick={handleOpenAddModel} />
            <TableController data={adsData} topHeight={188}>
              <Column width={50} align="center" fixed>
                <HeaderCell>Id</HeaderCell>
                <Cell dataKey="id" />
              </Column>
              <Column minWidth={150} flexGrow={0.5}>
                <HeaderCell>Start</HeaderCell>
                <Cell>{(rowData) => moment(rowData.start_time * 1000).format('YYYY-MM-DD HH:mm')}</Cell>
              </Column>
              <Column minWidth={150} flexGrow={0.5}>
                <HeaderCell>End</HeaderCell>
                <Cell>{(rowData) => (rowData.end_time ? moment(rowData.end_time * 1000).format('YYYY-MM-DD HH:mm') : '未設定')}</Cell>
              </Column>
              <Column width={100}>
                <HeaderCell>Img</HeaderCell>
                <ImgCell dataKey="img" />
              </Column>
              <Column minWidth={100} align="center">
                <HeaderCell>Action</HeaderCell>
                <ParseCell dataKey="action" action="action" />
              </Column>
              <Column minWidth={120} flexGrow={0.5}>
                <HeaderCell>Link</HeaderCell>
                <ParseCell dataKey="action" action="payload" />
              </Column>
              <Column minWidth={50} align="center">
                <HeaderCell>Priority</HeaderCell>
                <Cell dataKey="priority" />
              </Column>
              <Column minWidth={180} flexGrow={0.5} align="center">
                <HeaderCell>操作</HeaderCell>
                <ActionCell dataKey="id" handleUpdate={handleOpenUpdate} handleDelete={handleDelete} />
              </Column>
            </TableController>
            {showLoadMore && (
              <div role="presentation" className="load-more-text subtitle_01" onClick={fetchTable}>
                Load more
              </div>
            )}
          </div>
        )}
        {(toggleSimulator || addModel) && (
          <Simulator filterData={filterData} currentTab={currentTab} addModel={addModel} form={form} handleSimulatorDate={handleSimulatorDate} />
        )}
      </div>
    )
  }
}

export default withAdsStateControl(Carousel)
