import React from 'react'
import dayjs from 'dayjs'
import { Cell } from 'rsuite-table'

const PeriodCell = ({ rowData, ...props }) => {
  const setting = {
    send: {
      start_time: rowData.start_date? dayjs(rowData.start_date * 1000).format('YY-MM-DD HH:mm') : dayjs(rowData.created_at * 1000).format('YYYY-MM-DD HH:mm'),
      end_time: rowData.end_date? dayjs(rowData.end_date * 1000).format('YY-MM-DD HH:mm'): '未設定',
    },
    use: {
      start_time: rowData.enable_date? dayjs(rowData.enable_date * 1000).format('YY-MM-DD HH:mm'): '未設定',
      end_time: rowData.valid_days > 1000 ? dayjs(rowData.valid_days * 1000).format('YY-MM-DD HH:mm'): `領取後 ${rowData.valid_days} 天`,
    }
  }


	return (
		<Cell {...props}>
      <>
        <div className="period-section">
          <div className="period-title">領取設定</div>
          <p>開始：{setting.send.start_time}</p>
          <p>結束：{setting.send.end_time}</p>
        </div>
        <div className="period-section">
        <div className="period-title">使用設定</div>
          <p>開始：{setting.use.start_time}</p>
          <p>結束：{setting.use.end_time}</p>
        </div>
      </>
		</Cell>
	)
}

export default PeriodCell