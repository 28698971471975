import React, { memo } from 'react'
import { Button, Popover, Whisper } from 'rsuite'

const OfflineInfo = ({ transaction }) => {
  const { order_id, driver_id, trip_id } = transaction
  const speaker = (
    <Popover title="路招刷卡">
      <p>{`Order ID：${order_id}`}</p>
      <p>{`司機 DID：${driver_id}`}</p>
      <p>{`行程 TID：${trip_id}`}</p>
    </Popover>
  )

  return (
    <Whisper placement="top" speaker={speaker}>
      <Button appearance="link" style={{ padding: 0 }}>
        {transaction.order_id}
      </Button>
    </Whisper>
  )
}

export default memo(OfflineInfo)
