import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  padding: 40px 22px 20px 20px;
  background-color: #ffffff;
  top: -20px;
`
const Button = styled.button.attrs({
  type: 'button',
})`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #1e201f;
  &:not(:first-child) {
    margin-left: 16px;
  }
`
const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  &::after {
    content: url('https://storage.googleapis.com/web-static-files-bucket/icons/general/arrow-right.svg');
    width: 16px;
    height: 16px;
    margin-left: 2px;
  }
`
const EquityNumber = styled.h5`
  font-size: 28px;
  line-height: 20px;
  margin-bottom: 14px;
`

const EquityButton = ({ title, number = 0, isPrivacy = false, onClick, test }) => {
  return (
    <Button $isPrivacy={isPrivacy} onClick={onClick} data-test={test}>
      <EquityNumber>{number}</EquityNumber>
      <Title $isPrivacy={isPrivacy}>{title}</Title>
    </Button>
  )
}
const EquitySection = () => {
  return (
    <Root>
      <EquityButton title="乘車券" number={10} />
      <EquityButton title="T里程紀錄" number={23} />
      <EquityButton title="我的徽章" number={5} />
    </Root>
  )
}

export default EquitySection
