import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Alert, Button, Icon } from 'rsuite'
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'
import { Heading, Wrapper } from '../receipt/styles'
import { StyledPageButton } from '../dama/styles'
import { getSurgePriceMultiplierTests, patchSurgePriceMultiplierTest, getSurgePriceMultiplierLastModified } from '../../api'

const TABLE_COLUMNS = [
  { label: 'ID', dataKey: 'id', width: 80 },
  { label: '名稱', dataKey: 'display_name', width: 200 },
  { label: '開始時間', dataKey: 'start_time', width: 150, format: (v) => dateFormat(v * 1000) },
  { label: '結束時間', dataKey: 'end_time', width: 150, format: (v) => dateFormat(v * 1000) },
  { label: '啟用', dataKey: 'active', width: 80, align: 'center' },
  { label: '建立時間', dataKey: 'created_at', width: 150, format: (v) => dateFormat(v) },
  { label: '操作', dataKey: 'action', width: 150, align: 'center' },
]

const dateFormat = (v) => {
  if (!v || v < 0) return '-'
  return dayjs(v).format('YYYY-MM-DD HH:mm')
}

const SurgePriceMultiplierTests = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [tests, setTests] = useState([])
  const [page, setPage] = useState(0)
  const [updating, setUpdating] = useState({})
  const [lastModified, setLastModified] = useState('-')
  const limit = 10
  const history = useHistory()

  useEffect(() => {
    if (page < 0) return
    fetchTests()
  }, [page])

  useEffect(() => {
    fetchLastModified()
  }, [])

  const fetchTests = async () => {
    const params = { limit, offset: page * limit }
    setIsLoading(true)
    const {
      data: { data },
      status,
    } = await getSurgePriceMultiplierTests(params)
    setIsLoading(false)
    if (status !== 200) {
      Alert.error('surge multiplier tests fetch failed')
      return
    }

    setTests(data)
  }

  const fetchLastModified = async () => {
    const { data } = await getSurgePriceMultiplierLastModified()
    if (!data?.data) return
    setLastModified(dateFormat(Number(data.data)))
  }

  const activate = async (id, active) => {
    setUpdating({ ...updating, [id]: true })
    const { status } = await patchSurgePriceMultiplierTest(id, { active: !active })
    await sleep(1000)
    setUpdating({ ...updating, [id]: false })
    if (status !== 200) {
      Alert.error('operation failed')
      return
    }
    Alert.success('更新成功')
    await fetchTests()
  }

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  return (
    <Wrapper>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Heading style={{ lineHeight: '2em' }}>浮動倍率測試</Heading>
        <Button color="cyan" onClick={() => history.push('/surge_price_multiplier_tests/add')}>
          新增測試
        </Button>
      </div>
      <Table style={{ minWidth: 400 }} minHeight={450} autoHeight data={tests} loading={isLoading} bordered wordWrap>
        {TABLE_COLUMNS.map((v) => (
          <Column key={v.dataKey} width={v.width} align={v.align} verticalAlign="middle">
            <HeaderCell>{v.label}</HeaderCell>
            <Cell>
              {(rowData) => {
                if (v.dataKey === 'active') {
                  return rowData[v.dataKey] === 1
                    ? <Icon icon="check" style={{ color: '#1f7505' }} />
                    : ''
                }

                if (v.dataKey === 'action') {
                  return (
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                      <Button onClick={() => history.push(`/surge_price_multiplier_tests/${rowData.id}`)} appearance="link">查看</Button>
                      <Button size="xs" style={{ color: rowData.active === 1 ? 'red' : '#1f7505' }} loading={updating[rowData.id]} onClick={activate.bind(null, rowData.id, rowData.active)}>{rowData.active === 1 ? '停用' : '啟用'}</Button>
                    </div>
                  )
                }

                return (
                  <span style={{ wordBreak: 'break-word', ...(v.style && { ...v.style }) }}>
                    {v.format ? v.format(rowData[v.dataKey], rowData) : rowData[v.dataKey]}
                  </span>
                )
              }}
            </Cell>
          </Column>
        ))}
      </Table>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
        <code style={{ fontSize: 12 }}>浮動倍率最近更新於: {lastModified}</code>
        <div>
          <StyledPageButton disabled={page === 0} appearance="ghost" onClick={() => setPage(page - 1)}>
            上一頁
          </StyledPageButton>
          <StyledPageButton disabled={tests.length < limit} appearance="ghost" onClick={() => setPage(page + 1)}>
            下一頁
          </StyledPageButton>
        </div>
      </div>
    </Wrapper>
  )
}

export default SurgePriceMultiplierTests
