import React from 'react'
import { Cell } from 'rsuite-table'
import { Toggle } from 'rsuite';

export const ToggleCell = ({ rowData, ...props }) => {
	const isForCash = Boolean(rowData.is_for_cash)
	return (
		<Cell {...props}>
			<Toggle size="md" checked={isForCash} />
		</Cell>
	)
}

export default React.memo(ToggleCell)