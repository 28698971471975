import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Breadcrumb, Button } from 'rsuite'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import MemberCenterPreview from './MemberCenterPreview'
import MemberCenterForm from './MemberCenterForm'
import { WhisperCell, DateCell, ActionCell } from '../../components/table/cell'
import { ListWrap, AddListModel } from '../../components/views'
import { TableController } from '../../components/table'
import PopWarning from '../../components/popWarning'

const FAKE_LIST_DATA = [
  {
    id: 2,
    name: '中秋限定桃花錦囊',
    start_time: 1662652800,
    end_time: 1663516799,
    profile_view: {
      avatarImgUrl: null,
      bannerImgUrl: 'https://storage.googleapis.com/web-static-files-bucket/icons/general/mgm_202209_member_avatar.png',
    },
    bulletin_view: {
      bannerImgUrl: 'https://storage.googleapis.com/web-static-files-bucket/icons/general/mgm_202209_activity_wall.png',
      mainColor: '#f4789e',
      periodBgColor: '#75d1d8',
      periodTextColor: '#ffffff',
      missionButtonBgColor: '#f4789e',
      officialLink: 'https://lihi1.com/EdUHQ',
    },
    mission_items: [
      {
        title: '任務 1',
        desc: '分享桃花錦囊給好友，好友最高可得100元乘車券！',
        buttonText: '馬上參加',
        buttonLink: 'https://lihi1.com/ih6lr',
      },
      {
        title: '任務 2',
        desc: '推薦好友新註冊首次搭乘後，一起再拿 LINE POINTS 30 點',
        buttonText: '馬上參加',
        buttonLink: 'https://lihi1.com/E50El',
      },
    ],
  },
  {
    id: 1,
    name: '萌鬼驚喜 8月活動',
    start_time: 1660233600,
    end_time: 1661443199,
    profile_view: {
      avatarImgUrl: 'https://storage.googleapis.com/web-static-files-bucket/icons/general/mgm_202208_member_avatar.svg',
      bannerImgUrl: 'https://storage.googleapis.com/web-static-files-bucket/icons/general/mgm_202208_member_banner.svg',
    },
    bulletin_view: {
      bannerImgUrl: 'https://storage.googleapis.com/web-static-files-bucket/icons/general/mgm_202208_activity_wall.svg',
      mainColor: '#1d1a51',
      periodBgColor: '#2afeb0',
      periodTextColor: '#1e201f',
      missionButtonBgColor: '#1d1a51',
      officialLink: '',
    },
    mission_items: [
      {
        title: '任務 1',
        desc: '每天分享送驚喜盒，你和好友都能獲得乘車券，好友最高可得50元乘車券！',
        buttonText: '馬上參加',
        buttonLink: '',
      },
      {
        title: '任務 2',
        desc: '推薦好友新註冊首次搭乘後，一起再拿LINE POINTS 30點！',
        buttonText: '馬上參加',
        buttonLink: '',
      },
    ],
  },
]

const ContentWrapper = styled.div`
  width: 100%;
`
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-right: 20px;
    padding-left: 25px;
    padding-right: 25px;
  }
`
const PreviewNav = styled.div`
  display: flex;
  justify-content: space-between;
`

const initialData = {
  name: '',
  start_time: null,
  end_time: null,
  bulletin_view: {},
  profile_view: {},
  mission_items: [{ title: '任務 1' }],
}

const MemberCenter = () => {
  const [currentData, setCurrentData] = useState(initialData)
  const [list, setList] = useState([])
  const [previewData, setPreviewData] = useState({})
  const [isAddMode, setAddMode] = useState(false)
  const [warning, setWarning] = useState('')

  const toggleAddMode = () => {
    setPreviewData({})
    setAddMode(!isAddMode)
  }

  const handleEditOrCopyEvent = (rowData, action) => {
    const newEventData = { ...rowData }
    if (action === 'copy') delete newEventData.id
    toggleAddMode()
    setCurrentData(newEventData)
    setPreviewData(newEventData)
  }

  useEffect(() => {
    setList(FAKE_LIST_DATA)
  }, [])

  return (
    <>
      <div className="campaign-wrap">
        {warning && <PopWarning warning={warning} textCenter confirm={() => setWarning('')} />}
        {isAddMode ? (
          <AddListModel>
            <PreviewNav>
              <Breadcrumb>
                <Breadcrumb.Item onClick={toggleAddMode}>會員中心活動列表</Breadcrumb.Item>
                <Breadcrumb.Item active>{currentData.id ? '修改活動' : '新增活動'}</Breadcrumb.Item>
              </Breadcrumb>
              <Button color="orange" style={{ padding: '5px 20px' }} appearance="primary" onClick={() => setPreviewData(currentData)}>
                預覽
              </Button>
            </PreviewNav>
            <MemberCenterForm currentData={currentData} setCurrentData={setCurrentData} />
            <ButtonsWrapper>
              <Button appearance="default" onClick={toggleAddMode}>
                取消
              </Button>
              <Button appearance="primary" onClick={() => {}}>
                {currentData.id ? '更新' : '新增'}
              </Button>
            </ButtonsWrapper>
          </AddListModel>
        ) : (
          <ContentWrapper>
            <ListWrap status="會員中心設置(Beta)" addListText="+ 新增活動" onClick={toggleAddMode} />
            <TableController data={list} topHeight={104}>
              <Column flexGrow={0.5} align="center" fixed>
                <HeaderCell>ID</HeaderCell>
                <Cell dataKey="id" />
              </Column>
              <Column minWidth={80} flexGrow={1.5} align="center">
                <HeaderCell>任務牆名稱</HeaderCell>
                <WhisperCell dataKey="name" />
              </Column>
              <Column minWidth={80} flexGrow={1.5} align="center">
                <HeaderCell>活動顯示時間</HeaderCell>
                <DateCell dataKey="start_time" format="YYYY-MM-DD HH:mm" />
              </Column>
              <Column minWidth={80} flexGrow={1.5}>
                <HeaderCell>活動結束時間</HeaderCell>
                <DateCell dataKey="end_time" format="YYYY-MM-DD HH:mm" />
              </Column>
              <Column flexGrow={1.5} align="center">
                <HeaderCell>Action</HeaderCell>
                <ActionCell dataKey="id" handleEdit={handleEditOrCopyEvent} handleDelete={() => {}} />
              </Column>
            </TableController>
          </ContentWrapper>
        )}
        <MemberCenterPreview previewData={previewData} setPreviewData={setPreviewData} />
      </div>
    </>
  )
}

export default MemberCenter
