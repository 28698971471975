import React, {useState} from 'react'
import {getCompanyById} from "../../../api";

import moment from 'moment-timezone'

class ChangeLog extends React.Component {
    state = {
        deposit_log: []
    }

    componentDidMount() {
        this.getCompany()
    }

    getCompany = async () => {
        const data = await getCompanyById(this.props.company_id)
        const [company] = data.data.data.company
        // console.log('companys' + JSON.stringify(company))
        this.setState({
            deposit_log: company.deposit_log
        })
    }

    render() {
        const deposit_table = []
        if (this.state.deposit_log && this.state.deposit_log.length > 0) {
            this.state.deposit_log.map((log) => {
                const info_obj = JSON.parse(log.info)
                deposit_table.push(
                    <tr key={log.id}>
                        <td>{log.id}</td>
                        <td>{info_obj.deposit}</td>
                        <td>{info_obj.deposit_note}</td>
                        <td>{info_obj.paid_date}</td>
                        <td>{moment(log.timestamp).format('YYYY-MM-DD HH:mm')}</td>
                        <td>{log.admin_id}</td>
                    </tr>
                )
            })
        }
        return (
            <div>
                <table className="table table-bordered table-striped">
                    <thead>
                    <tr>
                        <td>紀錄ID</td>
                        <td>保證金金額</td>
                        <td>保證金註記</td>
                        <td>保證金入帳</td>
                        <td>更新時間</td>
                        <td>更新人</td>
                    </tr>
                    </thead>
                    <tbody>
                    {deposit_table}
                    </tbody>
                </table>
            </div>
        )
    }

}

export default ChangeLog
