import React from 'react'
import { Dropdown } from 'rsuite'
import { getDropdownTitle } from '../../utils'

// disabledList: 如果有禁用的選項就填入
function DropdownGroup({ options, onSelect, activeKey, style, disabledList = [], placeholder, disabled }) {
  return (
    <Dropdown style={style} title={getDropdownTitle(options, activeKey, placeholder)} activeKey={activeKey} onSelect={onSelect} disabled={disabled}>
      {options &&
        options.map(({ name, value }) => {
          const isDisabled = new Set(disabledList).has(value)
          // eslint-disable-next-line react/no-children-prop
          return <Dropdown.Item key={value} eventKey={value} children={name} disabled={isDisabled} />
        })}
    </Dropdown>
  )
}

export default React.memo(DropdownGroup)
