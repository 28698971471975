import React, { useState, useCallback, useEffect } from 'react'
import { Tag, TagGroup } from 'rsuite'
import dayjs from 'dayjs'
import MultiCheckboxTable from './CustomCell/MultiCheckboxTable'
import CustomInputGroupWidthButton from './CustomInputGroupWidthButton'
import NewPopWarning from '../../components/popWarning/NewPopWarning'
import { createModel } from '../../utils'
import { getUserGroupV2 } from '../../api'
import { isNumeric } from './helper'
import { CustomCellWrapper, PageButtonWrapper, StyledPageButton } from './styles'

const UserGroupTable = React.memo(({ selectedIds = [], selectedGroups = [], setSelectedIds }) => {
  const [userGroups, setUserGroups] = useState([])
  const [offset, setOffset] = useState(0)
  const [keyword, setKeyword] = useState('')
  const [selectUserGroupData, setSelectUserGroupsData] = useState(new Map(Object.entries(selectedGroups.reduce((accu, curr) => {
    accu[curr.id] = { id: curr.id, title: curr.title }
    return accu
  }, {}))))

  useEffect(() => {
    if (offset < 0) return
    getUserGroups()
  }, [offset])

  const getUserGroups = useCallback(
    async (param = {}) => {
      const params = { limit: 10, offset: offset * 10, ...param }
      if (isNumeric(keyword)) {
        params.id = keyword
      } else {
        params.search = keyword
      }
      const data = await getUserGroupV2(params)
      if (data.status !== 200) return createModel(<NewPopWarning createPortal warning={data.data.msg || '發生錯誤，請稍後再試'} textCenter />)

      setUserGroups(data.data.map((item) => ({
        ...item,
        Campaign_id: item.id,
        name: item.title,
        member_count: item.count,
        create_time: dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss'),
      })))
    }, [offset, keyword]
  )

  const handleSelectUserGroup = (data) => {
    const newUserGroupsData = new Map(JSON.parse(JSON.stringify([...selectUserGroupData]))) // deep clone
    newUserGroupsData.set(data.id, data)
    setSelectUserGroupsData(newUserGroupsData)
    setSelectedIds(Array.from(newUserGroupsData.keys()))
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CustomInputGroupWidthButton
          size="md"
          placeholder="輸入ID或名稱搜尋"
          value={keyword}
          onChange={setKeyword}
          onSearch={() => {
            getUserGroups({ offset: 0 }) // 更換完關鍵字從第一頁開始
          }}
        />
        {selectedIds.length && (
          <>
            <div>已選分眾</div>
            <TagGroup style={{ marginLeft: 15 }}>
              {selectedIds.map((currentId) => (
                <Tag
                  key={currentId}
                  size="lg"
                  closable
                  onClose={() => setSelectedIds(selectedIds.filter((id) => id !== currentId))}
                >
                  {`(${currentId}) ${selectUserGroupData.get(currentId)?.title || `${selectUserGroupData.get(currentId + '')?.title}` || ''}`}
                </Tag>
              ))}
            </TagGroup>
          </>
        )}
      </div>
      <CustomCellWrapper>
        <MultiCheckboxTable
          data={userGroups}
          setCampaignId={setSelectedIds}
          campaignId={selectedIds}
          handleSelectUserGroup={handleSelectUserGroup}
        />
        <PageButtonWrapper>
          {offset !== 0 && (
            <StyledPageButton appearance="ghost" onClick={() => setOffset(offset - 1)}>
              上一頁
            </StyledPageButton>
          )}
          {userGroups.length >= 10 && (
            <StyledPageButton appearance="ghost" onClick={() => setOffset(offset + 1)}>
              下一頁
            </StyledPageButton>
          )}
        </PageButtonWrapper>
      </CustomCellWrapper>
    </>
  )
})

export default UserGroupTable
