import React from 'react'
import { Icon } from 'rsuite'
import { PreviewMainPageWrapper } from './styles'
import defaultTheme from './theme'

const PreviewMainPage = ({ theme = defaultTheme }) => {
  return (
    <PreviewMainPageWrapper theme={theme}>
      <div className="top">
        <div className="icon" />
        <div className="icon">
          <Icon icon="close" />
        </div>
      </div>
      <div className="body">
        <h2 className="heading">萬聖節魔法糖</h2>
        <div className="cover" />
        <div className="content">
          {'每天分享魔法糖送驚喜，好友成功開啟後，你和好友都能獲得乘車券，好友最高可得100元乘車券！\n好友新註冊首搭後，一起再拿LINE POINTS 25點'}
        </div>
        <div className="solid-button">分享魔法糖給好友</div>
        <div className="text-link">查看活動詳情</div>
      </div>
      <div className="bottom">
        <div className="title">我的分享好友清單</div>
        <div className="row">
          <div className="cell">
            <div className="cell-title">已領券好友</div>
            <div className="text">
              <span>累積</span>
              <span className="bold">88</span>
              <span>名</span>
            </div>
          </div>
          <div className="cell">
            <div className="cell-title">已完成首搭</div>
            <div className="text">
              <span>累積</span>
              <span className="bold">88</span>
              <span>名</span>
            </div>
          </div>
          <div className="cell">
            <div className="cell-title">累積乘車券</div>
            <div className="text">
              <span className="bold">888</span>
              <span>元</span>
            </div>
          </div>
        </div>
        <div className="list">
          <div className="list-item">
            <img className="image" src="https://static.taxigo-cdn.com/icons/general/Ic_img_mgm_pie.png" alt="mgm_pie" />
            <div className="item-body">
              <div className="item-title">賴貼士</div>
              <div className="item-content">我已解鎖 1 顆魔法糖</div>
            </div>
            <div className="badge first-ride">完成首搭</div>
          </div>
          <div className="list-item">
            <img className="image" src="https://static.taxigo-cdn.com/icons/general/Ic_img_mgm_pie.png" alt="mgm_pie" />
            <div className="item-body">
              <div className="item-title">賴貼士</div>
              <div className="item-content">我已解鎖 1 顆魔法糖</div>
            </div>
            <div className="badge new-member">尚未首搭</div>
          </div>
          <div className="list-item">
            <img className="image" src="https://static.taxigo-cdn.com/icons/general/Ic_img_mgm_pie.png" alt="mgm_pie" />
            <div className="item-body">
              <div className="item-title">賴貼士</div>
              <div className="item-content">我已解鎖 1 顆魔法糖</div>
            </div>
            <div className="badge old-member">老朋友</div>
          </div>
        </div>
      </div>
    </PreviewMainPageWrapper>
  )
}

export default PreviewMainPage
