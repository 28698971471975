import _ from 'lodash'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Breadcrumb, Button, Input, InputPicker, Radio } from 'rsuite'
import { object, string } from 'yup'
import { getDriverReward, postDriverReward } from '../../api'
import { ContextStore } from '../../reducers'
import { RewardOptions, DriverInfo, Heading, Subject, SubmitRow, Wrapper } from './styles'

const REWARD_TYPE_POSITIVE = 'REWARD_TYPE_POSITIVE' // 獎勵
const REWARD_TYPE_NEGATIVE = 'REWARD_TYPE_NEGATIVE' // 懲處

const formSchema = object().shape({
  mileage_driver_id: string().required('請選擇獎懲項目'), // 選擇獎品的 id
  trip_id: string().nullable(), // 預設是 null
  admin_id: string().required(),
})

const CreateReward = () => {
  const { auth } = useContext(ContextStore)
  const history = useHistory()
  const { driver_id } = useParams()

  const [driverInfo, setDriverInfo] = useState({
    id: driver_id,
    name: '',
    total_miles: 0,
    expired_miles: 0,
  })

  const [mileageDriverList, setMileageDriverList] = useState([])
  const [rewardType, setRewardType] = useState(REWARD_TYPE_POSITIVE)
  const [currentCategoryValue, setCurrentCategoryValue] = useState(null) // category
  const [currentRewardOptionValue, setCurrentRewardOptionValue] = useState(null) // id
  const [tripId, setTripId] = useState(null)

  const [isLoading, setIsLoading] = useState(false)

  const filteredMileageDriverList = useMemo(() => {
    return mileageDriverList.filter((item) => {
      // if (typeof item.miles !== 'number') {
      //   先隱藏 miles 是 null 的情況
      // }

      if (typeof item.miles === 'number' && rewardType === REWARD_TYPE_POSITIVE) {
        return item.miles >= 0
      }

      if (typeof item.miles === 'number' && rewardType === REWARD_TYPE_NEGATIVE) {
        return item.miles < 0
      }

      return false
    })
  }, [mileageDriverList, rewardType])

  const categoryData = useMemo(() => {
    const category = _.uniq(filteredMileageDriverList.map((item) => item.category))
    return category.map((item) => ({ label: item, value: item }))
  }, [filteredMileageDriverList])

  const rewardOptionsData = useMemo(() => {
    if (!currentCategoryValue) return []
    return filteredMileageDriverList
      .filter((item) => item.category === currentCategoryValue)
      .map((item) => {
        if (typeof item.miles !== 'number') {
          return { value: item.id, label: item.title }
        }
        if (item.miles >= 0) {
          return { value: item.id, label: `${item.title} +${item.miles}點` }
        }
        return { value: item.id, label: `${item.title} ${item.miles}點` }
      })
  }, [filteredMileageDriverList, currentCategoryValue])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getDriverReward(driver_id)
        if (!data.data) {
          throw new Error(data.message)
        }
        setDriverInfo({
          id: data.data.driver_info.id,
          name: data.data.driver_info.name,
          total_miles: data.data.total_miles,
          expired_miles: data.data.expired_miles,
        })
        setMileageDriverList(data.data.mileage_driver_list)
      } catch (error) {
        Alert.error(error.message)
      }
    }
    fetchData()
  }, [driver_id])

  const handleRewardTypeChange = useCallback((value) => {
    setRewardType(value)
    setCurrentCategoryValue(null)
    setCurrentRewardOptionValue(null)
    setTripId(null)
  }, [])

  const handleCategoryChange = useCallback((value) => {
    setCurrentCategoryValue(value)
  }, [])

  const handleRewardOptionChange = useCallback((value) => {
    setCurrentRewardOptionValue(value)
  }, [])

  const handleTripIdChange = useCallback((value) => {
    setTripId(value)
  }, [])

  const handleSubmit = useCallback(async () => {
    try {
      const body = {
        mileage_driver_id: currentRewardOptionValue, // 選擇獎品的 id
        trip_id: tripId, // 預設是 null
        admin_id: auth.user.admin_id,
      }
      await formSchema.validate(body)
      setIsLoading(true)
      const { data } = await postDriverReward(driverInfo.id, body)
      if (!data.data) {
        throw new Error(data.message)
      }
      Alert.success('新增成功')
      history.push(`/driver_mileage?did=${driverInfo.id}`)
    } catch (error) {
      Alert.error(error.message)
      setIsLoading(false)
    }
  }, [currentRewardOptionValue, driverInfo.id, auth, history, tripId])

  return (
    <Wrapper>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/driver_mileage">司機獎勵積點(點數商品)</Breadcrumb.Item>
        <Breadcrumb.Item active>新增司機獎懲</Breadcrumb.Item>
      </Breadcrumb>
      <Heading>新增司機獎懲</Heading>
      <DriverInfo>
        <div className="cell">
          <p className="subject">司機ID</p>
          <span className="content">{`${driverInfo.id} ${driverInfo.name}`}</span>
        </div>
        <div className="cell">
          <p className="subject">目前點數</p>
          <span className="content">{driverInfo.total_miles}</span>
        </div>
        <div className="cell">
          <p className="subject">即將到期的點數</p>
          <span className="content">{driverInfo.expired_miles}</span>
        </div>
      </DriverInfo>
      <Subject>1.獎懲類型</Subject>
      <div>
        <Radio value={REWARD_TYPE_POSITIVE} onChange={handleRewardTypeChange} checked={rewardType === REWARD_TYPE_POSITIVE}>
          獎勵
        </Radio>
        <Radio value={REWARD_TYPE_NEGATIVE} onChange={handleRewardTypeChange} checked={rewardType === REWARD_TYPE_NEGATIVE}>
          懲處
        </Radio>
      </div>
      <Subject>2.獎懲選項</Subject>
      <RewardOptions>
        <div className="cell">
          <p className="subject">A.選擇獎懲類別</p>
          <InputPicker value={currentCategoryValue ?? ''} data={categoryData} onChange={handleCategoryChange} placement="auto" cleanable={false} />
        </div>
        <div className="cell">
          <p className="subject">B.選擇項目</p>
          <InputPicker
            value={currentRewardOptionValue ?? ''}
            data={rewardOptionsData}
            onChange={handleRewardOptionChange}
            placement="auto"
            cleanable={false}
          />
        </div>
        <div className="cell">
          <p className="subject">C.填寫行程ID</p>
          <Input placeholder="選填" onChange={handleTripIdChange} value={tripId ?? ''} />
        </div>
      </RewardOptions>
      <SubmitRow>
        {/* 依照獎懲類型做切換 */}
        {rewardType === REWARD_TYPE_NEGATIVE ? (
          <Button onClick={handleSubmit} color="red" disabled={isLoading}>
            確認新增懲處
          </Button>
        ) : (
          <Button onClick={handleSubmit} color="green" disabled={isLoading}>
            確認新增獎勵
          </Button>
        )}
      </SubmitRow>
    </Wrapper>
  )
}

export default CreateReward
