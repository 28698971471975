import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 200,
	},
	content: {
		padding: 20,
	},
	menu: {
		width: 200,
	},
	button: {
		margin: 10
	}
});

const user_list = [
	{
		value: '101',
		label: 'Hayden',
	},
	{
		value: '221544',
		label: 'TaxiGo 貼車體廣告',
	}
];
class TripController extends React.Component {
	state = this.props.data;

	handleChange = name => event => {
		this.props.handleChange(this.props.index, name, event.target.value)
		this.setState({ [name]: event.target.value });
	}

	componentWillReceiveProps(nextProps) {
		this.setState(nextProps.data)
	}

	render() {
		const { classes } = this.props

		return (
			<div className={classes.content}>
				<Grid container spacing={24}>
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<form className={classes.container} noValidate autoComplete="off">
								<TextField
									id="standard-required"
									label="User ID"
									className={classes.textField}
									value={this.state.user_id}
									onChange={this.handleChange('user_id')}
									margin="normal"
								/>
								<TextField
									id="standard-select-user"
									select
									label="用戶選擇"
									className={classes.textField}
									value={this.state.user_id}
									onChange={this.handleChange('user_id')}
									SelectProps={{
										MenuProps: {
											className: classes.menu,
										},
									}}
									helperText="Please select an user"
									margin="normal"
								>
									{user_list.map(option => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</TextField>
								<TextField
									id="standard-name"
									label="Driver ID"
									className={classes.textField}
									value={this.state.driver_id}
									onChange={this.handleChange('driver_id')}
									margin="normal"
								/>
								<TextField
									id="datetime-local"
									label="Reserve time"
									type="datetime-local"
									value={this.state.reservation_time}
									className={classes.textField}
									onChange={this.handleChange('reservation_time')}
									margin="normal" />
								<TextField
									id="standard-required"
									label="Start Address"
									className={classes.textField}
									value={this.state.start_address}
									onChange={this.handleChange('start_address')}
									margin="normal"
								/>
								<TextField
									id="standard-required"
									label="Start Latitude"
									className={classes.textField}
									value={this.state.start_latitude}
									onChange={this.handleChange('start_latitude')}
									margin="normal"
								/>
								<TextField
									id="standard-required"
									label="Start Longitude"
									className={classes.textField}
									value={this.state.start_longitude}
									onChange={this.handleChange('start_longitude')}
									margin="normal"
								/>
								<TextField
									id="standard-required"
									label="End Address"
									className={classes.textField}
									value={this.state.end_address}
									onChange={this.handleChange('end_address')}
									margin="normal"
								/>
								<TextField
									id="standard-required"
									label="End Latitude"
									className={classes.textField}
									value={this.state.end_latitude}
									onChange={this.handleChange('end_latitude')}
									margin="normal"
								/>
								<TextField
									id="standard-required"
									label="End Longitude"
									className={classes.textField}
									value={this.state.end_longitude}
									onChange={this.handleChange('end_longitude')}
									margin="normal"
								/>
								<TextField
									id="standard-textarea"
									label="With placeholder multiline"
									placeholder="Placeholder"
									value={this.state.note}
									multiline
									className={classes.textField}
									onChange={this.handleChange('note')}
									margin="normal"
								/>
							</form>
							<Button variant="outlined" className={classes.button} onClick={() => this.props.duplicate(this.state)}>
								Duplicate
                            </Button>
							<Button variant="outlined" color="secondary" className={classes.button} onClick={() => this.props.delete(this.props.index)}>
								Delete
                            </Button>
						</Paper>
					</Grid>
				</Grid>
			</div>
		);
	}
}

TripController.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TripController);
