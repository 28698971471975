/* eslint-disable consistent-return */
import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { Button, Uploader } from 'rsuite'
import { filter, lowerCase, isEmpty } from 'lodash'
import { DropdownGroup } from '../views'
import { postEquippedImage } from '../../api'
import { REVIEW_ITEMS } from '../../pages/equipped/constants'

const ReviewSectionWrapper = styled.div`
  border-radius: 4px;
  background-color: #eaeaea;
  padding: 16px;
  color: #1e201f;
  font-size: 14px;
  margin-bottom: 30px;
  & > div {
    display: flex;
  }
  ${(props) => {
    switch (props.$type) {
      case 'block':
      case 'reject':
        return `background-color: #ff9494;`
      case 'accept':
        return `background-color: #9dd3a8;`
      default:
        return ``
    }
  }}
`
const PhotoSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`
const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  background-color: transparent;
  width: 36vw;
  height: 20vw;
  border-radius: 7px;
  border: 1px dashed #333;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    background-color: transparent;
    transform: ${(props) => `rotate(${props.$deg}deg)`};
  }
  ${(props) =>
    props.$warning &&
    `
      &::after {
        content: "司機未上傳照片";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `}
`
const ButtonsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  & > div {
    display: flex;
  }
  button {
    margin-right: 5px;
  }
`
const ResultSection = styled.div`
  textarea {
    border-radius: 7px;
    border: 1px solid #d1d1d1;
    width: 30vw;
  }
  & > * {
    margin-bottom: 3px;
  }
`
const RequiredTitle = styled.p`
  &::after {
    content: '*';
    color: red;
  }
`
const UploadButton = styled(Uploader)`
  .rs-uploader-trigger-btn {
    background-color: #2096f3;
    color: #fff;
    font-weight: bold;
  }
`

const ReviewSection = ({ index, item, resultOptions, reasonOptions, handleReviewItem, pass, setLoading, fetchDriverInfo }) => {
  const [imgUrl] = useState(item.url)
  const [deg, setDeg] = useState(0)

  const { isEmptyItem, isRejected } = useMemo(() => {
    const { review_type, url } = item
    return {
      isRejected: review_type === 'REJECT' || review_type === 'BLOCK',
      isEmptyItem: isEmpty(url),
    }
  }, [item])

  const handleUpload = async (file) => {
    const { uid, type } = item
    setLoading(true)
    const formData = new FormData()
    formData.append('file', file.blobFile)
    formData.append('doc_type', type)
    const response = await postEquippedImage(uid, formData)
    if (response.status !== 200) return console.log(response.data.msg)
    setLoading(false)
    const { url } = response.data.data
    console.log('upload----------', url)
    fetchDriverInfo(uid) // 上傳完後刷新狀態
  }

  return (
    <ReviewSectionWrapper $type={lowerCase(item.review_type)}>
      <h6>{`${index + 1}. ${REVIEW_ITEMS[item.type]}`}</h6>
      <div>
        <PhotoSection>
          <ImageWrapper $warning={isEmptyItem} $deg={deg}>
            <img alt="" src={imgUrl} />
          </ImageWrapper>
          <ButtonsWrapper>
            <Button disabled={isEmptyItem} appearance="default" onClick={() => setDeg(deg + 90)}>
              旋轉
            </Button>
            <div>
              <Button disabled={isEmptyItem || pass} appearance="default" onClick={() => handleReviewItem('delete', item.id)}>
                刪除
              </Button>
              {item.review_type !== 'BLOCK' && (
                <UploadButton disabled={pass} listType="picture-text" fileListVisible={false} onUpload={handleUpload}>
                  <Button>上傳照片</Button>
                </UploadButton>
              )}
            </div>
          </ButtonsWrapper>
        </PhotoSection>
        <ResultSection>
          <RequiredTitle>審核結果</RequiredTitle>
          <DropdownGroup
            activeKey={item.review_type}
            options={resultOptions}
            disabledList={pass ? ['NEW'] : []}
            onSelect={(e) => {
              if (e === item.review_type) return
              handleReviewItem('edit', item.id, 'review_type', e)
            }}
          />
          {isRejected && (
            <>
              <RequiredTitle>請選擇不通過原因 (若通過不必填寫)</RequiredTitle>
              <DropdownGroup
                activeKey=""
                options={reasonOptions}
                onSelect={(e) => {
                  const wording = filter(reasonOptions, { value: e })[0].name
                  handleReviewItem('edit', item.id, 'content', isEmpty(item.content) ? wording : item.content + wording)
                }}
              />
              <p>其他原因</p>
              <textarea
                data-key={index}
                type="text"
                placeholder="其他原因說明"
                value={item.content || ''}
                onChange={(e) => {
                  handleReviewItem('edit', item.id, 'content', e.target.value)
                }}
              />
            </>
          )}
        </ResultSection>
      </div>
    </ReviewSectionWrapper>
  )
}

export default React.memo(ReviewSection)
