import React from 'react'
import {
  getPaymentRecords,
  updatePaymentRecords,
  refreshPayments,
  openContent,
  sendEmail,
  sendInvoiceEmail,
} from '../../../api'
import dayjs from 'dayjs'
import TitleHeader from '../../../components/views/TitleHeader'
import { Icon, InputGroup, Input } from 'rsuite'
import CompanyPaymentTable from '../../../components/company/CompanyPaymentTable'

class BusinessPayment extends React.Component {
  state = {
    payments: [],
    search_term: this.props.match.params.search_value || '',
    page: 1,
    offset: 0,
    limit: 20,
    showLoadmore: true,
    loading: true,
  }

  componentDidMount() {
    this.getPaymentRecords(true)
  }

  rsuiteHandleInputChange = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  onSearch = () => {
    this.setState({ offset: 0, payments: [] }, () => {
      this.getPaymentRecords(true)
    })
  }

  getPaymentRecords = async (isLoadMore = false) => {
    const { limit, offset, search_term, payments } = this.state
    const Unpaid = ~this.state.search_term.indexOf('未付款') ? 'PENDING' : ''
    const paid = ~this.state.search_term.indexOf('已付款') ? 'FINISHED' : ''
    const query = Unpaid === '' && paid === '' ? search_term : Unpaid + paid

    const params = {
      limit: isLoadMore ? limit : offset,
      offset: isLoadMore ? offset : 0,
      query,
    }

    this.setState({ loading: true })
    const result = await getPaymentRecords(params)
    this.setState({ loading: false })
    if (result.status !== 200) return alert('搜尋失敗')
    const data = result.data
    if (isLoadMore) {
      this.setState({
        payments: payments.concat(data),
        offset: offset + data.length,
      })
    } else {
      this.setState({ payments: data })
    }
    this.setState({ showLoadmore: data.length >= limit })
    data.forEach((v) => {
      this.setState({ [`note-${v.payment.id}`]: v.payment.note })
      this.setState({ [`payment_day-${v.payment.id}`]: v.payment.payment_day ? new Date(v.payment.payment_day * 1000) : null })
    })
  }

  handlePageChange = (page) => {
    this.setState({ page })
  }

  handlePicker = async (name, date, payment_id, status, note) => {
    this.setState({
      [name]: date,
    })
    this.updatePaymentRecords(
      payment_id,
      status,
      note,
      dayjs(date).startOf('day').unix()
    )
  }

  updatePaymentRecords = async (
    payment_id,
    status,
    note = undefined,
    payment_day = undefined
  ) => {
    const body = {
      payment_id,
      status,
      note,
      payment_day,
    }

    const data = await updatePaymentRecords(body)
    if (data.status !== 200) return alert('更新失敗')
    this.getPaymentRecords()
  }

  refresh_payments = async () => {
    const data = await refreshPayments()
    if (data.status !== 200) return
    window.location.reload()
  }

  getPlanDescription = (amount, value) => {
    if (value) {
      const agreement = JSON.parse(value)
      let str = `合約 id:${agreement.id}\n`
      str += `${dayjs(agreement.start_time * 1000).format(
        'YYYY-MM-DD'
      )} ~ ${dayjs(agreement.end_time * 1000).format('YYYY-MM-DD')}\n`
      if (agreement.company_fee && agreement.company_fee.id) {
        const company_fee = agreement.company_fee
        str += `服務費\n`
        if (company_fee.plan_value === 0) {
          str += '為零'
        } else if (company_fee.plan_value < 1) {
          str += ` + (${amount} * ${Math.floor(
            company_fee.plan_value * 100
          )}%)\n`
          str += '最多 '
          str +=
            company_fee.max_value === 0
              ? '沒有上限'
              : `${company_fee.max_value} 元`
          str += '\n'
        } else if (company_fee.plan_value >= 1) {
          str += ` + ${company_fee.plan_value} \n`
        } else {
          str += '未知'
        }
      }
      if (agreement.company_discount && agreement.company_discount.id) {
        const company_discount = agreement.company_discount
        str += `折扣\n`
        if (company_discount.plan_value === 0) {
          str += '為零'
        } else if (company_discount.plan_value < 1) {
          str += ` - (${amount} * ${Math.floor(
            company_discount.plan_value * 100
          )}%) \n`
          str += '最多 '
          str +=
            company_discount.max_value === 0
              ? '沒有上限'
              : `${company_discount.max_value} 元`
        } else if (company_discount.plan_value >= 1) {
          str += ` - ${company_discount.plan_value} \n`
        } else {
          str += '未知'
        }
      }
      return str
    } else {
      return '沒有合約'
    }
  }

  openContent = async (company_id, month, receipt_type = 'RECEIPT') => {
    const params = {
      company_id,
      month,
      dryrun: true,
    }
    const method = receipt_type === 'RECEIPT' ? openContent : sendInvoiceEmail
    const data = await method(params)
    const wnd = window.open('about:blank', '')
    wnd.document.write(data.data)
    wnd.document.close()
  }

  sendEmail = async (company_id, month, receipt_type = 'RECEIPT') => {
    const method = receipt_type === 'RECEIPT' ? sendEmail : sendInvoiceEmail
    const data = await method({
      company_id,
      month,
      dryrun: false,
    })
    if (data.status !== 200) return
    alert('已送出')
  }

  render() {
    const { payments, search_term, showLoadmore } = this.state
    return (
      <div style={{ width: '100%' }}>
        <TitleHeader>
          <h3 className="box-title">Payments ({payments.length})</h3>
        </TitleHeader>
        <button
          className="add-list"
          style={{ marginBottom: 20 + 'px' }}
          onClick={this.refresh_payments}
        >
          更新三個月紀錄
        </button>
        <div className="box">
          <InputGroup style={{ width: 200 + 'px' }}>
            <Input
              name="table_search"
              placeholder="Search"
              value={search_term}
              onChange={(event) =>
                this.rsuiteHandleInputChange('search_term', event)
              }
            />
            <InputGroup.Button onClick={this.onSearch}>
              <Icon icon="search" />
            </InputGroup.Button>
          </InputGroup>
          <CompanyPaymentTable
            state={this.state}
            payments={payments}
            openContent={this.openContent}
            sendEmail={this.sendEmail}
            handlePicker={this.handlePicker}
            getPlanDescription={this.getPlanDescription}
            updatePaymentRecords={this.updatePaymentRecords}
            rsuiteHandleInputChange={this.rsuiteHandleInputChange}
          />
          {showLoadmore && (
            <div
              className="load-more-text subtitle_01"
              onClick={() => this.getPaymentRecords(true)}
            >
              Load more
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default BusinessPayment
