import React, { useCallback } from 'react'
import { Col, ControlLabel, Form, FormControl, FormGroup, Grid, Radio, RadioGroup, Row } from 'rsuite'
import { cloneDeep, set } from 'lodash'
import { Root, Title } from './index.style'
import { useMGMConfig } from '../provider'

function StepSharePage() {
  const { value, updateValue } = useMGMConfig()

  const handleChange = useCallback(
    (name) => (val) => {
      const new_share_page = cloneDeep(value.share_page)
      set(new_share_page, name, val)
      updateValue('share_page', new_share_page)
    },
    [value, updateValue],
  )

  return (
    <Root>
      <Title>主態-對方未開啟</Title>
      <Grid fluid>
        <Row>
          <Col xs={24} lg={12}>
            <Form>
              <FormGroup>
                <ControlLabel>圖片</ControlLabel>
                <FormControl value={value.share_page.sender.OPEN.img} onChange={handleChange('sender.OPEN.img')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>文案</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  rows={3}
                  value={value.share_page.sender.OPEN.text}
                  onChange={handleChange('sender.OPEN.text')}
                />
              </FormGroup>
            </Form>
          </Col>
          <Col xs={24} lg={6} />
        </Row>
      </Grid>
      <Title>主態-對方已開啟</Title>
      <Grid fluid>
        <Row>
          <Col xs={24} lg={12}>
            <Form>
              <FormGroup>
                <ControlLabel>圖片</ControlLabel>
                <FormControl value={value.share_page.sender.ISSUED.img} onChange={handleChange('sender.ISSUED.img')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>文案</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  rows={3}
                  value={value.share_page.sender.ISSUED.text}
                  onChange={handleChange('sender.ISSUED.text')}
                />
              </FormGroup>
            </Form>
          </Col>
          <Col xs={24} lg={6} />
        </Row>
      </Grid>
      <Title>客態-基本資訊</Title>
      <Grid fluid>
        <Row>
          <Col xs={24} lg={12}>
            <Form>
              <FormGroup>
                <ControlLabel>文案</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  rows={3}
                  value={value.share_page.receiver.description}
                  onChange={handleChange('receiver.description')}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>未註冊</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  rows={3}
                  value={value.share_page.receiver.register}
                  onChange={handleChange('receiver.register')}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>規則</ControlLabel>
                <FormControl componentClass="textarea" rows={3} value={value.share_page.receiver.rule} onChange={handleChange('receiver.rule')} />
              </FormGroup>
            </Form>
          </Col>
          <Col xs={24} lg={6} />
        </Row>
      </Grid>
      <Title>客態-分享已接收</Title>
      <Grid fluid>
        <Row>
          <Col xs={24} lg={12}>
            <Form>
              <FormGroup>
                <ControlLabel>圖片</ControlLabel>
                <FormControl value={value.share_page.receiver.RECEIVED.img} onChange={handleChange('receiver.RECEIVED.img')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>標題</ControlLabel>
                <FormControl value={value.share_page.receiver.RECEIVED.title} onChange={handleChange('receiver.RECEIVED.title')} />
              </FormGroup>
            </Form>
          </Col>
          <Col xs={24} lg={6} />
        </Row>
      </Grid>
      <Title>客態-分享過期</Title>
      <Grid fluid>
        <Row>
          <Col xs={24} lg={12}>
            <Form>
              <FormGroup>
                <ControlLabel>圖片</ControlLabel>
                <FormControl value={value.share_page.receiver.DUE.img} onChange={handleChange('receiver.DUE.img')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>標題</ControlLabel>
                <FormControl value={value.share_page.receiver.DUE.title} onChange={handleChange('receiver.DUE.title')} />
              </FormGroup>
            </Form>
          </Col>
          <Col xs={24} lg={6} />
        </Row>
      </Grid>
      <Title>活動結束</Title>
      <Grid fluid>
        <Row>
          <Col xs={24} lg={12}>
            <Form>
              <FormGroup>
                <ControlLabel>圖片</ControlLabel>
                <FormControl value={value.share_page.expired.img} onChange={handleChange('expired.img')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>標題</ControlLabel>
                <FormControl value={value.share_page.expired.title} onChange={handleChange('expired.title')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>文案</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  rows={3}
                  value={value.share_page.expired.description}
                  onChange={handleChange('expired.description')}
                />
              </FormGroup>
            </Form>
          </Col>
          <Col xs={24} lg={6} />
        </Row>
      </Grid>
      <Title>分享成功</Title>
      <Grid fluid>
        <Row>
          <Col xs={24} lg={12}>
            <Form>
              <FormGroup>
                <ControlLabel>圖片</ControlLabel>
                <FormControl value={value.share_page.share.img} onChange={handleChange('share.img')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>標題</ControlLabel>
                <FormControl value={value.share_page.share.title} onChange={handleChange('share.title')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>文案</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  rows={3}
                  value={value.share_page.share.description}
                  onChange={handleChange('share.description')}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>按鈕文字</ControlLabel>
                <FormControl value={value.share_page.share.buttonText} onChange={handleChange('share.buttonText')} />
              </FormGroup>
            </Form>
          </Col>
          <Col xs={24} lg={6} />
        </Row>
      </Grid>
      <Title>主題</Title>
      <Grid fluid>
        <Row>
          <Col xs={24} lg={12}>
            <Form>
              <FormGroup>
                <ControlLabel>顏色主題</ControlLabel>
                <FormControl accepter={RadioGroup} value={value.share_page.colors.mode} onChange={handleChange('colors.mode')} inline>
                  <Radio value="light">Light</Radio>
                  <Radio value="dark">Dark</Radio>
                </FormControl>
              </FormGroup>
              <FormGroup>
                <ControlLabel>顏色 - neutral</ControlLabel>
                <FormControl value={value.share_page.colors.neutral} onChange={handleChange('colors.neutral')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>顏色 - mainBg</ControlLabel>
                <FormControl value={value.share_page.colors.mainBg} onChange={handleChange('colors.mainBg')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>顏色 - title</ControlLabel>
                <FormControl value={value.share_page.colors.title} onChange={handleChange('colors.title')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>顏色 - buttonBg</ControlLabel>
                <FormControl value={value.share_page.colors.buttonBg} onChange={handleChange('colors.buttonBg')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>顏色 - buttonBorder</ControlLabel>
                <FormControl value={value.share_page.colors.buttonBorder} onChange={handleChange('colors.buttonBorder')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>顏色 - buttonText</ControlLabel>
                <FormControl value={value.share_page.colors.buttonText} onChange={handleChange('colors.buttonText')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>顏色 - awardText</ControlLabel>
                <FormControl value={value.share_page.colors.awardText} onChange={handleChange('colors.awardText')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>顏色 - awardBg</ControlLabel>
                <FormControl value={value.share_page.colors.awardBg} onChange={handleChange('colors.awardBg')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>顏色 - tagBg</ControlLabel>
                <FormControl value={value.share_page.colors.tagBg} onChange={handleChange('colors.tagBg')} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>顏色 - tagText</ControlLabel>
                <FormControl value={value.share_page.colors.tagText} onChange={handleChange('colors.tagText')} />
              </FormGroup>
            </Form>
          </Col>
          <Col xs={24} lg={6} />
        </Row>
      </Grid>
    </Root>
  )
}

export default StepSharePage
