import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import dayjs from 'dayjs'

const Root = styled.div`
  background: #f5f5f5;
  margin-top: 40px;
`
const Banner = styled.h4`
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  position: relative;
  height: 83px;
  padding: 16px 20px 20px;
  background-color: ${(props) => props.$backgroundColor};
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  color: #ffffff;
`
const ActivityPeriod = styled.div`
  width: min-content;
  margin-top: 4px;
  margin-left: 2px;
  padding: 0 6px;
  border-radius: 2px;
  color: ${(props) => props.$textColor};
  font-size: 14px;
  font-family: 'LINESeed';
  white-space: nowrap;
  background-color: ${(props) => props.$bgColor || '#ffffff'};
  line-height: 19px;
`
const ActivityBannerImg = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 160px;
  height: 120px;
`
const OfficialLink = styled.a`
  position: absolute;
  bottom: 4px;
  right: 8px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #ffffff;
  &::after {
    content: url(https://storage.googleapis.com/web-static-files-bucket/icons/general/arrow-right-white.svg);
    width: 16px;
    height: 16px;
    margin-left: 2px;
  }
`
const MissionWrapper = styled.div`
  max-width: 375px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  color: ${({ $isDone }) => ($isDone ? '#a5a6a5' : '#1e201f')};
  background-color: ${(props) => (props.$isDone ? 'transparent' : '#ffffff')};
  &:active {
    background-color: #eeefee;
  }
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: calc(100vw - 40px);
    height: 1px;
    background-color: '#f5f5f5';
  }
`

const Title = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 6px;
`
const Description = styled.div`
  color: ${(props) => (props.$isDone ? '06GREY_GREEN' : '03DARK_GREY_GREEN')};
`
const Button = styled.button`
  height: min-content;
  margin-left: 16px;
  border-radius: 45px;
  padding: 9px 14px;
  white-space: nowrap;
  background-color: ${({ $isDone, $buttonBg }) => ($isDone ? '#d2d2d2' : $buttonBg)};
  color: #ffffff;
  ${(props) =>
    props.$isDone &&
    css`
      pointer-events: none;
      position: relative;
      padding: 9px 14px 9px 28px;
      &::before {
        content: ${(props) => props.iconUrl};
        @include size(24px, 24px);
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translateY(-50%);
      }
    `}
`

const MissionItem = ({ title, desc, buttonBg, buttonText, isDone, onClick }) => {
  return (
    <MissionWrapper $isDone={isDone}>
      <div>
        <Title>{title}</Title>
        <Description $isDone={isDone}>{desc}</Description>
      </div>
      <Button $buttonBg={buttonBg} $isDone={isDone} onClick={onClick}>
        {buttonText}
      </Button>
    </MissionWrapper>
  )
}
const ActivityWall = ({ previewData }) => {
  const { name, start_time, end_time, bulletin_view, mission_items } = previewData
  const { bannerImgUrl, mainColor, periodBgColor, periodTextColor, missionButtonBgColor, officialLink } = bulletin_view

  const activityPeriod = `${dayjs(start_time).format('MM/DD')}~${dayjs(end_time).format('MM/DD')}`
  return (
    <Root>
      <Banner $backgroundColor={mainColor}>
        {name}
        <ActivityPeriod $bgColor={periodBgColor} $textColor={periodTextColor || mainColor}>
          {activityPeriod}
        </ActivityPeriod>
        <ActivityBannerImg>
          <img src={bannerImgUrl} alt="" />
        </ActivityBannerImg>
        <OfficialLink href={officialLink} rel="noopener noreferrer" target="_blank">
          官網
        </OfficialLink>
      </Banner>
      {mission_items.map(({ title, desc, buttonText, isDone = false, buttonLink }) => (
        <MissionItem
          key={title}
          title={title}
          desc={desc}
          buttonBg={missionButtonBgColor}
          buttonText={buttonText}
          isDone={isDone}
          onClick={() => window.open(buttonLink, '_blank')}
        />
      ))}
    </Root>
  )
}

export default memo(ActivityWall)
