import React from "react";

function Loading() {
	return (
		<div className="loading-wrap">
			loading
		</div>
	);
}

export default Loading;
