/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
import React from 'react'
import { forEach, isEmpty } from 'lodash'
import { InputGroup, Input, Icon } from 'rsuite'
import { getCompany, createCompany } from '../../../api'
import AddCompany from '../../../components/company/AddCompany'

class CompanyList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      companys: [],
      search_term: '',
      offset: 0,
      limit: 100,
      showAddCompany: false,
      account: '',
      name: '',
      tax_num: '',
      nick_name: '',
      description: '',
      postal_code: '',
      address: '',
      email: '',
      quota: 50000,
      deposit: 0,
      telephone_num: '',
      mobile_num: '',
      group_name: '',
      user_id: 1,
      user_name: '',
      user_email: '',
    }
  }

  componentDidMount() {
    this.getCompany()
  }

  getCompany = async () => {
    const { limit, offset, search_term, companys } = this.state
    const response = await getCompany(limit, offset, search_term)
    const newCompanyData = response.data.data.company
    if (search_term !== '') {
      this.setState({ companys: newCompanyData, offset: newCompanyData.length })
    } else {
      this.setState({
        companys: companys.concat(newCompanyData),
        offset: offset + newCompanyData.length,
      })
    }
  }

  addCompany = async () => {
    const {
      account,
      name,
      tax_num,
      nick_name,
      description,
      postal_code,
      address,
      email,
      quota,
      deposit,
      telephone_num,
      mobile_num,
      group_name,
      user_id,
      user_name,
      user_email,
    } = this.state

    const body = {
      account,
      name,
      tax_num,
      nick_name,
      description,
      postal_code,
      address,
      email,
      quota,
      deposit,
      telephone_num,
      mobile_num,
      group_name,
      user_id,
      user_name,
      user_email,
    }
    let msg = ''
    forEach(body, (value, key) => {
      if (key === 'group_name' || key === 'deposit') return
      if (!value || value === '') {
        msg += `${key}值為空`
      }
    })
    if (msg) return alert(msg)

    const response = await createCompany(body)
    console.log(JSON.stringify(response))
    if (response.status !== 200) return
    this.clearBody()
    const { showAddCompany } = this.state
    this.setState({ showAddCompany: !showAddCompany })
    await this.getCompany()
  }

  clearBody = () => {
    this.setState({
      account: '',
      name: '',
      tax_num: '',
      nick_name: '',
      description: '',
      postal_code: '',
      address: '',
      email: '',
      quota: 50000,
      deposit: '',
      telephone_num: '',
      mobile_num: '',
      group_name: '',
      user_id: 1,
      user_name: '',
      user_email: '',
    })
  }

  onSearch = () => {
    const { search_term } = this.state
    if (isEmpty(search_term)) return alert('請先輸入欲搜尋的名稱')
    this.setState({ companys: [], offset: 0 }, () => {
      this.getCompany()
    })
  }

  toggleAddCompany = () => {
    console.log('toggleAddCompany')
    const { showAddCompany } = this.state
    this.setState({ showAddCompany: !showAddCompany })
  }

  handleInputChange = (event, setStateKey) => {
    const { target } = event
    const value = target.type === 'checkbox' ? target.checked : target.value
    this.setState({
      [setStateKey]: value,
    })
  }

  renderCompanyList = () => {
    const { search_term, companys } = this.state
    return (
      <div className="company-list-table-wrap">
        <table className="table table-hover">
          <thead>
            <tr>
              <td>公司編號</td>
              <td>公司名稱</td>
              <td>isBlocked</td>
              <td>quota</td>
              <td>統編</td>
              <td>E-mail</td>
              <td>電話</td>
              <td>電話</td>
              <td>地址</td>
            </tr>
          </thead>
          <tbody>
            {companys.map((company) => {
              const company_info_url = `/company/company_info/${company.id}`
              return (
                <tr key={company.id} className="company-list-row" onClick={() => (window.location = company_info_url)}>
                  <td>{company.id}</td>
                  <td>{company.name}</td>
                  <td>{company.isBlocked}</td>
                  <td>{company.quota}</td>
                  <td>{company.tax_num}</td>
                  <td>{company.email}</td>
                  <td>{company.mobile_num}</td>
                  <td>{company.telephone_num}</td>
                  <td>{company.address}</td>
                </tr>
              )
            }, this)}
          </tbody>
        </table>
        {search_term === '' && (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <button type="submit" className="company-add-button" onClick={this.getCompany}>
              Load More
            </button>
          </div>
        )}
      </div>
    )
  }

  render() {
    const {
      showAddCompany,
      account,
      name,
      tax_num,
      nick_name,
      description,
      postal_code,
      address,
      email,
      quota,
      deposit,
      telephone_num,
      mobile_num,
      user_name,
      user_email,
      group_name,
      companys,
    } = this.state

    return (
      <>
        <div className="company-list-header">
          <h4 className="list-status">{`公司列表 (${companys.length})`}</h4>
          <InputGroup size="md" className="title-search-bar">
            <Input placeholder="Search" style={{ width: 150 }} onChange={(value) => this.setState({ search_term: value })} />
            <InputGroup.Button onClick={this.onSearch}>
              <Icon icon="search" />
            </InputGroup.Button>
          </InputGroup>
        </div>
        {showAddCompany ? (
          <AddCompany
            account={account}
            name={name}
            tax_num={tax_num}
            nick_name={nick_name}
            description={description}
            postal_code={postal_code}
            address={address}
            email={email}
            quota={quota}
            deposit={deposit}
            telephone_num={telephone_num}
            mobile_num={mobile_num}
            user_name={user_name}
            user_email={user_email}
            group_name={group_name}
            handleOnChange={this.handleInputChange}
            addCompany={this.addCompany}
            toggleAddCompany={this.toggleAddCompany}
          />
        ) : (
          <div className="company-add-wrap">
            <button type="button" className="company-add-button" onClick={this.toggleAddCompany}>
              新增公司
            </button>
          </div>
        )}

        {!showAddCompany && this.renderCompanyList()}
      </>
    )
  }
}

export default CompanyList
