import React from 'react'
import moment from 'moment-timezone'
import { forEach } from 'lodash'
import { Alert } from 'rsuite'
import { getModifyTable2, postTable, putTable, deleteTable, getAds, postImage } from '../../api'
import { parseContent } from '../../pages/marketing/advertisement/helper'

const withAdsStateControl = (WrappedComponent) => {
	return class extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				addModel: false,
				actionData: [{ value: 'open_link' }, { value: 'redirect' }],
				currentUpdate: false,
				form: {},
				resetForm: {},
				formError: {},
				limit: 20,
				offset: 0,
				adsData: [],
				adsColumns: [],
				showLoadMore: true,
				toggleSimulator: true,
				filterTime: +(moment().format('X')),
				filterData: [],
				desc: 'id',
				isLoading: false
			}
		}

		componentDidMount() {
			this.fetchTable()
			this.fetchFilterTable()
		}

		fetchTable = async () => {
			const { limit, desc, adsData } = this.state
			const { currentTab } = this.props
			let conditions = []
			conditions.push({ key: 'limit', value: limit })
			conditions.push({ key: 'offset', value: adsData.length })
			conditions.push({ key: 'desc', value: desc })
			const data = await getModifyTable2({ table: currentTab.table, conditions })
			if (data.status !== 200) return
			this.setState({ adsColumns: data.data.data.get.columns })
			this.handleResponse(data)
		}

		fetchFilterTable = async () => {
			const { filterTime } = this.state
			const carousel = await getAds(filterTime, 'carousel')
			const cards = await getAds(filterTime, 'cards')
			if (carousel.status !== 200 || cards.status !== 200) return
			const filterData = {
				carousel: carousel.data,
				cards: cards.data
			}
			this.setState({ filterData })
		}

		handleResponse = (data) => {
			const { adsData: preAdsData, adsColumns } = this.state
			const result = data.data.data.get.data
			const newResult = []
			const form = {}

			result.forEach((item) => {
				adsColumns.forEach((column) => {
					if (column.model_type === 'JSONString') {
						item[column.column_name] = parseContent(item[column.column_name])
					}
					if (column.column_name === 'admin_id') {
						form[column.column_name] = this.props.userId
					} else {
						form[column.column_name] = ''
					}
				})
				newResult.push(item)
			})
			console.log('result', newResult)

			if (result.length < 20) {
				this.setState({
					showLoadMore: false,
					adsData: preAdsData.concat(newResult),
					resetForm: form,
					form
				})
			} else {
				this.setState({
					adsData: preAdsData.concat(newResult),
					resetForm: form,
					form
				})
			}
		}

		handlePostTable = () => {
			if (this.validate()) return Alert.error('請填寫欄位', 5000)
			const { form, adsColumns, currentUpdate } = this.state
			const { currentTab } = this.props
			const body = {}
			adsColumns.forEach((column) => {
				if (['id', 'created_time'].includes(column.column_name)) return
				if (column.model_type === 'JSONString') {
					body[column.column_name] = JSON.stringify(form[column.column_name])
				} else if (['Num', 'int'].includes(column.model_type)) {
					body[column.column_name] = parseInt(form[column.column_name])
				} else {
					body[column.column_name] = form[column.column_name]
				}
			})

			if (currentUpdate) return this.handlePutTable(form.id, body)
			postTable(currentTab.table, body).then((data) => {
				if (data.status === 200) {
					this.setState({ addModel: false, limit: 20, offset: 0, adsData: [] }, () => {
						this.resetForm()
						this.fetchTable()
					})
				} else {
					Alert.error('新增失敗，請稍後再試', 5000)
				}
			})
		}

		handlePutTable = async (id, body) => {
			const { currentTab } = this.props
			const data = await putTable(currentTab.table, id, '/batch', body)
			if (data.status === 200) {
				this.setState({ addModel: false, limit: 20, offset: 0, adsData: [] }, () => {
					this.resetForm()
					this.fetchTable()
				})
				Alert.success('更新成功', 3000)
			} else {
				Alert.error('更新失敗，請稍後再試', 5000)
			}
		}

		handleDeleteTable = (id) => {
			const { handlePopWarning, currentTab } = this.props
			deleteTable(currentTab.table, id).then((data) => {
				if (data.status === 200) {
					this.setState({ limit: 20, offset: 0, adsData: [] }, () => {
						this.resetForm()
						this.fetchTable()
					})
					handlePopWarning('')
					Alert.success('刪除成功', 3000)
				}
			})
		}

		handleDelete = (id) => {
			const { handlePopWarning } = this.props
			handlePopWarning({
				text: '確定刪除活動？',
				confirm: () => this.handleDeleteTable(id),
				isCancel: true,
				cancel: () => handlePopWarning('')
			})
		}

		handleOnUpload = async (file) => {
			this.setState({ isLoading: true })
			const formData = new FormData()
			formData.append('file', file.blobFile)
			formData.append('doc_type', 'image')
			const data = await postImage(this.props.userId, formData)
			console.log('upload----', data);
			if (data.status !== 200) return
			this.setState({ isLoading: false })
			this.handleOnChange(data.data.image_url, 'img')
		}

		handleOnChange = (e, setStateKey) => {
			const { form } = this.state
			const value = e.target ? e.target.value : e
			this.setState({ form: { ...form, [setStateKey]: value } })
		}

		handlePayloadOnChange = (e) => {
			const { form } = this.state
			this.setState({ form: { ...form, action: { ...form.action, payload: e.target.value } } })
		}

		handleActionSelect = (index) => {
			const { form, actionData } = this.state
			this.setState({ form: { ...form, action: { ...form.action, action: actionData[index].value } } })
		}

		handleDatePicker = (date, name) => {
			this.setState({ form: { ...this.state.form, [name]: +(moment(+date).format('X')) } }, () => {
			})
		}

		handleOpenAddModel = () => {
			const { addModel } = this.state
			this.setState({ addModel: !addModel, currentUpdate: false }, () => {
				if (!this.state.addModel) {
					this.resetForm()
				}
			})
		}

		handleOpenUpdate = (data, action) => {
			const form = {}
			this.state.adsColumns.forEach((column) => {
				if (column.column_name === 'admin_id') {
					form[column.column_name] = this.props.userId
				} else {
					form[column.column_name] = data[column.column_name]
				}
			})
			this.setState({
				form,
				currentUpdate: action !== 'copy',
				addModel: true
			}, () => { console.log(this.state.form) })
		}

		validate = () => {
			const { form } = this.state
			let formError = {}
			forEach(form, (value, key) => {
				if (!value && !['id', 'created_time'].includes(key)) {
					formError[key] = '請填寫或選擇項目'
				} else {
					delete formError[key]
				}
			})
			this.setState({ formError })
			return Boolean(Object.keys(formError).length)
		}

		resetForm = () => {
			this.setState({ form: this.state.resetForm, formError: {} })
		}

		handleToggleSimulator = () => {
			this.setState({ toggleSimulator: !this.state.toggleSimulator }, () => {
				if (this.state.toggleSimulator) return this.fetchFilterTable()
			})
		}

		handleSimulatorDate = (date) => {
			this.setState({ filterTime: +(moment(+date).format('X')) }, () => {
				this.fetchFilterTable()
			})
		}

		render() {
			return <WrappedComponent {...this.state} {...this.props}
				fetchTable={this.fetchTable}
				handleOnChange={this.handleOnChange}
				handleActionSelect={this.handleActionSelect}
				handleOpenAddModel={this.handleOpenAddModel}
				handleOpenUpdate={this.handleOpenUpdate}
				handleDatePicker={this.handleDatePicker}
				handlePostTable={this.handlePostTable}
				handleDeleteTable={this.handleDeleteTable}
				handleDelete={this.handleDelete}
				handleLoadMore={this.handleLoadMore}
				handlePayloadOnChange={this.handlePayloadOnChange}
				handleOnUpload={this.handleOnUpload}
				handleToggleSimulator={this.handleToggleSimulator}
				fetchFilterTable={this.fetchFilterTable}
				handleSimulatorDate={this.handleSimulatorDate}
			/>
		}
	}
}

export default withAdsStateControl
