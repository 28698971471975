import { isArray, isEmpty } from 'lodash'
const transLocation = (location) => {
  let newLocation = []
  if (isArray(location) && !isEmpty(location)) {
    newLocation = (location.length > 1 ? location : location[0]).map((point) => {
      const { x, y } = point
      return x < y ? { lat: x, lng: y } : { lat: y, lng: x }
    })
  }
  return newLocation
}

export { transLocation }