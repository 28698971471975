import React from 'react'
import { Checkbox, CheckboxGroup } from 'rsuite'

// onChange
const StatusCheckbox = ({ includeExpired, includeFuture, handleStatusChange }) => {
  let checkList = []
  includeExpired && checkList.push('expired')
  includeFuture && checkList.push('future')

  return (
    <CheckboxGroup style={{ fontSize: 12, margin: '0 10px' }} inline value={checkList} onChange={(v) => handleStatusChange(v)}>
      <Checkbox value='expired'>包含已過期</Checkbox>
      <Checkbox value='future'>包含未開始</Checkbox>
    </CheckboxGroup>
  )
}

export default React.memo(StatusCheckbox)