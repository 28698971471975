export const REVIEW_STATUS = [
  {
    name: '未審核',
    value: 'NEW',
  },
  {
    name: '不通過',
    value: 'REJECT',
  },
  {
    name: '已通過',
    value: 'ACCEPT',
  },
  {
    name: '未通過，且不能重新上傳',
    value: 'BLOCK',
  },
]

export const REVIEW_ITEMS = {
  FRONT: '車頭正面外觀',
  RIGHT: '車身右側外觀',
  LEFT: '車身左側外觀',
  BACK: '車尾後側外觀',
  INSIDE_LEFT: '左車窗內側和駕駛座背面',
  INSIDE_RIGHT: '右車窗內側',
  INSIDE_BACK: '副駕駛座後側',
}
