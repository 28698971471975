import React from 'react'
import { InputNumber, Panel, Button } from 'rsuite'
import TimePeriodSelector from './TimePeriodSelector'
import { generateCode } from '../../pages/coupons/helper'

const DailySetting = ({ data = [], handleTimePeriod }) => {

  const onAddButtonClick = () => {
    handleTimePeriod('add', null, { 'id': generateCode(4), 'time_of_day_start': null , 'time_of_day_end': null })
  }

  return (
    <Panel header="選擇時間規則：日" bordered>
      <div className="input-number-wrap time-period">
        <span>{'設定'}</span>
        <InputNumber 
          size="sm"
          style={{ width: 100}} 
          value={data.length}
          onChange={(value) => console.log(value)}/>
        <span>{'組時間'}</span>
        <Button appearance="ghost" size="xs" onClick={onAddButtonClick}>新增區段</Button>
      </div>
      {data.map((data) => {
        return (
          <TimePeriodSelector
            key={data.id}
            data={data}
            handleTimePeriod={handleTimePeriod}
          />
        )
      })}
    </Panel>
  )
}

export default React.memo(DailySetting)

