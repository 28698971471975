import React from 'react'
import styled from 'styled-components'
import { IconButton, Icon, Divider, Whisper, Tooltip } from 'rsuite'
import { Cell } from 'rsuite-table'
import { useHistory } from 'react-router-dom'

const ActionCellWrap = styled(Cell)`
  & > div {
    display: flex;
    align-items: center;
  }
`

const TooltipControl = ({ children, text = '' }) => {
  return (
    <Whisper placement="top" trigger="hover" speaker={<Tooltip>{text}</Tooltip>}>
      {children}
    </Whisper>
  )
}

const UserGroupActionCell = ({ rowData, toggle, handleRead, handleCopy, handleDelete, handleEdit, ...props }) => {
  const history = useHistory()

  const handleAction = (action) => {
    switch (action) {
      case 'read':
        handleRead(rowData)
        toggle && toggle()
        break
      case 'copy':
        handleCopy && handleCopy(rowData)
        toggle && toggle()
        break
      case 'delete':
        handleDelete(rowData.id || rowData, rowData)
        break
      case 'edit':
        handleEdit(rowData)
        break
      case 'add-event-2':
        history.push(`/dama/event_management/add?user-group=${rowData.id}`)
        break
      default:
        break
    }
  }

  return (
    <ActionCellWrap {...props}>
      <TooltipControl text="可根據固定的使用者名單去操作">
        <IconButton appearance="subtle" onClick={() => handleAction('read')} icon={<Icon icon="folder" />} />
      </TooltipControl>
      <Divider vertical />
      <TooltipControl text="可根據已有的分眾篩選條件去操作">
        <IconButton appearance="subtle" onClick={() => handleAction('copy')} icon={<Icon icon="copy" />} />
      </TooltipControl>
      {rowData.frequency_by_day && rowData.end_time && (
        <>
          <Divider vertical />
          <IconButton appearance="subtle" onClick={() => handleAction('edit')} icon={<Icon icon="edit" />} />
        </>
      )}
      <Divider vertical />
      <IconButton appearance="subtle" onClick={() => handleAction('delete')} icon={<Icon icon="trash2" />} />
      <Divider vertical />
      <IconButton appearance="ghost" size="sm" icon={<Icon icon="plus" />} color="green" onClick={() => handleAction('add-event-2')}>
        新增活動
      </IconButton>
    </ActionCellWrap>
  )
}

export default React.memo(UserGroupActionCell)
