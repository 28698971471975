import React from 'react'
import { Cell } from 'rsuite-table'
import { Tag } from 'rsuite'
import { createFeatureMapValue } from '../../pages/coupons/helper'

const FEATURE_MAP = {
  [createFeatureMapValue('8388608', null, 0)]: {
    label: 'All 多元',
    color: '#22c55e',
  },
  [createFeatureMapValue('1048576', null, 0)]: {
    label: 'All 小黃',
    color: '#fbbf24',
  },
  [createFeatureMapValue('1', null, 0)]: {
    label: '星選',
    color: '#1d4ed8',
  },
  [createFeatureMapValue('2', null, 0)]: {
    label: '所有寵物車',
    color: '#ef4444',
  },
  [createFeatureMapValue('512', null, 0)]: {
    label: '所有寵物車',
    color: '#ef4444',
  },

  [createFeatureMapValue('8388610', null, 0)]: { label: '寵物多元', color: '#22c55e' },
  [createFeatureMapValue('8389120', null, 0)]: { label: '寶寶多元', color: '#22c55e' },
  [createFeatureMapValue('1049088', null, 0)]: { label: '寶寶小黃', color: '#fbbf24' },
  [createFeatureMapValue('1048578', null, 0)]: { label: '寵物小黃', color: '#fbbf24' },
  [createFeatureMapValue('1082130432', null, 0)]: { label: '六人座多元', color: '#22c55e' },
  [createFeatureMapValue('545259520', null, 0)]: { label: '豪華多元', color: '#22c55e' },
  [createFeatureMapValue('8388672', null, 0)]: { label: '無障礙多元', color: '#22c55e' },
  [createFeatureMapValue('1074790400', null, 0)]: { label: '六人座小黃', color: '#fbbf24' },
  [createFeatureMapValue('9437184', null, 0)]: { label: '限小黃與多元', color: '#ef4444' },
  [createFeatureMapValue(null, null, 0)]: { label: '無限制', color: '#020617' },
  [createFeatureMapValue('67108864', null, 0)]: { label: '格上All(預約)', color: '#06b6d4' },
  [createFeatureMapValue('1140850688', null, 0)]: { label: '格上經濟租賃車XL(預約)', color: '#06b6d4' },
  [createFeatureMapValue('603979776', null, 0)]: { label: '格上商務租賃車(預約)', color: '#06b6d4' },
  [createFeatureMapValue('33554432', null, 0)]: { label: '全峰All', color: '#1d4ed8' },
  [createFeatureMapValue('1107296256', null, 0)]: { label: '全峰經濟租賃車XL', color: '#1d4ed8' },
  [createFeatureMapValue('570425344', null, 0)]: { label: '全峰商務租賃車', color: '#1d4ed8' },
  [createFeatureMapValue('67108864', null, 1)]: { label: '格上All(即時)', color: '#06b6d4' },
  [createFeatureMapValue('1140850688', null, 1)]: { label: '格上經濟租賃車XL(即時)', color: '#06b6d4' },
  [createFeatureMapValue('603979776', null, 1)]: { label: '格上商務租賃車(即時)', color: '#06b6d4' },
  [createFeatureMapValue(null, '8388608', 0)]: {
    label: '一般多元',
    color: '#22c55e',
  },
  [createFeatureMapValue(null, '1048576', 0)]: {
    label: '一般小黃',
    color: '#fbbf24',
  },
  [createFeatureMapValue(null, '67108864', 0)]: {
    label: '格上經濟租賃車(預約)',
    color: '#06b6d4',
  },
  [createFeatureMapValue(null, '67108864', 1)]: {
    label: '格上經濟租賃車(即時)',
    color: '#06b6d4',
  },
  [createFeatureMapValue(null, '33554432', 0)]: {
    label: '全峰經濟租賃車',
    color: '#1d4ed8',
  },
  [createFeatureMapValue('67108864', null, 2)]: {
    label: '租賃車All',
    color: '#a855f7',
  },
  [createFeatureMapValue(null, '83886080', 2)]: {
    label: '經濟租賃車',
    color: '#a855f7',
  },
  [createFeatureMapValue('1140850688', null, 2)]: {
    label: '經濟租賃車XL',
    color: '#a855f7',
  },
  [createFeatureMapValue('603979776', null, 2)]: {
    label: '商務租賃車',
    color: '#a855f7',
  },
  [createFeatureMapValue('16777216', null, 0)]: {
    label: '機場接送預約',
    color: '#ec4899',
  },
  [createFeatureMapValue('16777216', null, 1)]: { label: '機場接送即時', color: '#ec4899' },
  [createFeatureMapValue('16777216', null, 2)]: { label: '機場接送即時+預約', color: '#ec4899' },
}

const FeatureMapCell = ({ rowData, ...props }) => {
  const match = FEATURE_MAP[createFeatureMapValue(rowData?.feature_map, rowData?.exact_feature_map, rowData?.is_ondemand_shuttle)]

  return <Cell {...props}>{match && <Tag style={{ backgroundColor: match.color, color: 'white' }}>{match.label}</Tag>}</Cell>
}

export default React.memo(FeatureMapCell)
