import React from 'react'

function AddListGrop({ children, title }) {
	return (
		<div className="add-list-item">
			<h5 className="add-list-title">{title}</h5>
			{children}
		</div>
	)
}

export default React.memo(AddListGrop)
