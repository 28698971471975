import React, { forwardRef, useCallback } from 'react'
import { Icon, Input, InputGroup } from 'rsuite'
import styled from 'styled-components'

const CustomInputAddon = styled(InputGroup.Addon)`
  display: flex;
  gap: 4px;
`

const CouponInput = forwardRef(({ variant, style, className, ...inputProps }, ref) => {
  const renderAddonContent = useCallback(() => {
    switch (variant) {
      case 'taxi':
        return (
          <>
            <Icon icon="cab" />
            乘車券
          </>
        )
      case 'rental':
        return (
          <>
            <Icon icon="car" />
            租車券
          </>
        )
      default:
        return <></>
    }
  }, [variant])

  return (
    <InputGroup style={style} className={className}>
      <CustomInputAddon>{renderAddonContent()}</CustomInputAddon>
      <Input ref={ref} {...inputProps} />
    </InputGroup>
  )
})

export default CouponInput
