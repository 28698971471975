/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Popover, Whisper } from 'rsuite'
import { Cell } from 'rsuite-table'

const TitleCell = ({ rowData, ...props }) => {
  const { stats = {} } = rowData
  const speaker = (
    <Popover title={`乘車優惠券：${rowData.promo_title}`}>
      {stats?.distinct_users && <p>{`領取人數：${stats.distinct_users} 人`}</p>}
      {stats?.total_distribute && <p>{`領取次數：${stats.total_distribute} 次`}</p>}
      {stats?.total_redeem && <p>{`使用張數：${stats.total_redeem} 張`}</p>}
      {stats?.total_spending && <p>{`總共花費：${stats.total_spending} 元`}</p>}
    </Popover>
  )

  return (
    <Cell {...props}>
      <Whisper placement="right" speaker={speaker}>
        <a>{rowData.promo_title}</a>
      </Whisper>
    </Cell>
  )
}

export default React.memo(TitleCell)
