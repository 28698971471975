import React from 'react'
import { Tag } from 'rsuite'

const getTagInfo = (type) => {
  switch (type) {
    case 'pin':
      return {
        name: '置頂',
        color: 'yellow'
      }
    case 'cover':
      return {
        name: '蓋版',
        color: 'green'
      }
    case 'inprogress':
      return {
        name: '效期內',
        color: 'orange'
      }  
    case 'expired':
      return {
        name: '已過期',
        color: null
      }
    case 'future':
      return {
        name: '預約中',
        color: 'blue'
      }  
    case 'deleted':
      return {
        name: '已刪除',
        color: 'red'
      }
    case 'news':
      return {
        name: '群發',
        color: 'cyan'
      }
    default:
      return null
  }
}


export const TagItem = ({type}) => {
  const tagInfo = getTagInfo(type);
  return (
      <Tag style={{ margin: '0 4px'}}color={tagInfo.color}>{tagInfo.name}</Tag>
  )
}

export default React.memo(TagItem)