import React, { Component } from 'react'
// import { ContextStore } from '../../reducers'
import { Panel, Icon } from 'rsuite'

class Welcome extends Component {
	render() {
		const { user } = this.props
		return (
			<Panel className="welcome-wrap">
				<h4 className="content"> (~`･∀･)~ {user.name}! </h4>
			</Panel>
		)
	}

}
export default Welcome
