import React from 'react'
import styled from 'styled-components'
import { RadioGroup, InputNumber, IconButton, Icon } from 'rsuite'
import { ListWrapper, ListTitleWrapper, ListTitle, InputStyled, StyledRadio } from './styles'

const ContentTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #2589f5;
  padding-bottom: 10px;
  margin-bottom: 24px;
`
const ContentTitle = styled.div`
  color: #2589f5;
  font-size: 18px;
`
const StyledInputNumber = styled(InputNumber)`
  width: 100px;
  margin-right: 24px;
`
const StyledIconButton = styled(IconButton)`
  margin-left: 10px;
`

const DISPLAY_TYPE = {
  BOX: 'BOX',
  LIST: 'LIST',
  PROGRESS: 'PROGRESS',
}

const ContentItem = React.memo(({ data, updateContentItemData, index, removeContentItem }) => {
  return (
    <>
      <ContentTitleWrapper>
        <ContentTitle>{`活動區塊 ${index + 1}`}</ContentTitle>
        {index + 1 > 1 && (
          <StyledIconButton appearance="ghost" icon={<Icon icon="trash2" />} color="red" size="sm" onClick={() => removeContentItem(data.idKey)} />
        )}
      </ContentTitleWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>顯示方式</ListTitle>
        </ListTitleWrapper>
        <RadioGroup name="radioList" inline onChange={(value) => updateContentItemData(data.idKey, 'type', value)} value={data.type}>
          <StyledRadio value={DISPLAY_TYPE.BOX}>BOX</StyledRadio>
          <StyledRadio value={DISPLAY_TYPE.PROGRESS}>PROGRESS</StyledRadio>
        </RadioGroup>
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>顯示標題</ListTitle>
        </ListTitleWrapper>
        <InputStyled
          placeholder="輸入標題，例：累計獎金、尖峰時段 {{value}} 趟"
          value={data.title || ''}
          onChange={(value) => updateContentItemData(data.idKey, 'title', value)}
        />
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>顯示副標</ListTitle>
        </ListTitleWrapper>
        <InputStyled
          placeholder="輸入副標題，例：{{value}}、{{value}}趟、{{value}}人、總趟數{{value}}、{{value}} / 40單"
          value={data.body || ''}
          onChange={(value) => updateContentItemData(data.idKey, 'body', value)}
        />
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>顯示位置</ListTitle>
        </ListTitleWrapper>
        <ListTitleWrapper>
          <ListTitle>直欄 Row</ListTitle>
        </ListTitleWrapper>
        <StyledInputNumber defaultValue={data.row} min={0} onChange={(value) => updateContentItemData(data.idKey, 'row', value)} />
        <ListTitleWrapper>
          <ListTitle>橫列 Column</ListTitle>
        </ListTitleWrapper>
        <StyledInputNumber defaultValue={data.column} min={0} onChange={(value) => updateContentItemData(data.idKey, 'column', value)} />
      </ListWrapper>
      <ListWrapper>
        <ListTitleWrapper>
          <ListTitle $require>SQL</ListTitle>
        </ListTitleWrapper>
        <InputStyled
          componentClass="textarea"
          placeholder="輸入活動條件 SQL"
          value={data.query || ''}
          onChange={(value) => updateContentItemData(data.idKey, 'query', value)}
        />
      </ListWrapper>
    </>
  )
})

export default ContentItem
