import React from 'react'

function TitleHeader({ children }) {
	return (
		<div className="list-wrap" >
			<div className="list-status-wrap">
				{children && children}
			</div>
		</div>
	)
}

export default React.memo(TitleHeader)
