import React from 'react'
import styled from 'styled-components'
import { Panel } from 'rsuite'

export const PanelWrap = styled(Panel)`
	background: white;
	margin: 20px;
`

export const TagPickerWrap = styled.div`
  display: flex;
`