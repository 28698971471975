import React from 'react'
import { Icon, Input, InputGroup } from 'rsuite'
import styled from 'styled-components'

const InputGroupWrapper = styled.div`
  width: 200px;
  margin: 20px 10px;
`

const CustomInputGroupWidthButton = ({ value, placeholder, onChange, onSearch }) => (
  <InputGroupWrapper>
    <InputGroup inside>
      <Input value={value} placeholder={placeholder} onChange={onChange} />
      <InputGroup.Button onClick={onSearch}>
        <Icon icon="search" />
      </InputGroup.Button>
    </InputGroup>
  </InputGroupWrapper>
)

export default CustomInputGroupWidthButton
