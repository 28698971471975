import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { Alert, Nav, Tag } from 'rsuite'
import { Column, HeaderCell, Cell, Table } from 'rsuite-table'
import { uniqWith } from 'lodash'
import dayjs from 'dayjs'
import { getUserGroupV2, deleteUserGroup } from '../../api'
import ActionCell from './CustomCell/UserGroupActionCell'
import { WhisperCell, DateCell } from '../../components/table/cell'
import PopWarning from '../../components/popWarning'
import { isNumeric } from './helper'
import SearchInput from '../../components/table/SearchInput'
import { ButtonsWrapper, FlexWrapper, StyledPageButton, Title } from './styles'
import { FILTER_METHODS, FREQUENCY_BY_DAY_LABEL, USER_GROUP_TABS } from './const'
import CellWithPopover from './CustomCell/CellWithPopover'

const UserGroup = ({ history }) => {
  const [groupList, setGroupList] = useState([])
  const [warning, setWarning] = useState('')

  const [searchText, setSearchText] = useState('')
  const [currentTab, setCurrentTab] = useState(USER_GROUP_TABS.ALL)

  const offsetRef = useRef(0)

  const handleCopyGroup = (rowData) => {
    // 用戶名單的產生 1. 篩選維度 or 2. 上傳檔案
    if (rowData.view.source === FILTER_METHODS.BY_FILE) {
      return Alert.warning('為檔案上傳的名單！')
    }
    history.push(`/dama/user_group/${rowData.id}/duplicate`)
  }

  const handleReadAndModify = (rowData) => {
    Alert.info(`讀取 ID ${rowData.id} 的名單`)
    history.push(`/dama/user_group/${rowData.id}/apply`)
  }

  const handleEdit = useCallback(
    (rowData) => {
      if (rowData.view.source === FILTER_METHODS.BY_FILE) {
        return Alert.warning('為檔案上傳的名單！')
      }
      history.push(`/dama/user_group/${rowData.id}/edit`)
    },
    [history],
  )

  const fetchData = useCallback(async ({ id = '', search = '', limit = 10, offset = 0, tab = 'all' } = {}) => {
    let tasks

    /**
     * LT-9652 為了能同時得到 ID 與 分眾名單名稱的搜尋結果，只要搜尋文字是數字都會同時搜尋 id 與 search
     */
    if (id.length) {
      tasks = [getUserGroupV2({ id, limit, offset, tab }), getUserGroupV2({ search: id, limit, offset, tab })]
    } else {
      tasks = [getUserGroupV2({ search, limit, offset, tab })]
    }
    const responses = await Promise.all(tasks)

    if (responses.some((item) => item.status !== 200)) {
      Alert.error(responses.map((item) => item.data.msg).join(', '))
      return
    }

    const data = responses.reduce((prev, current) => {
      current.data.map((x) => prev.push(x))
      return prev
    }, [])

    const filtered = uniqWith(data, (a, b) => a.id === b.id)

    if (offset > 1 && !filtered.length) {
      Alert.info('沒有更多資料了 👻')
      // Back to last offset
      offsetRef.current -= 1
      return
    }

    setGroupList((prev) => {
      if (offset > 1) {
        return [...prev, ...filtered]
      }
      return filtered
    })
  }, [])

  const handleDeleteGroup = async (id) => {
    const response = await deleteUserGroup(id)
    if (response.status !== 200) return Alert.error(response?.data?.msg || '目前還無法使用刪除功能！')
    offsetRef.current = 0
    fetchData({ tab: currentTab })
    return Alert.info('刪除成功！')
  }

  useEffect(() => {
    fetchData({ tab: currentTab })
  }, [fetchData, currentTab])

  const handleSearch = useCallback(() => {
    const params = isNumeric(searchText) ? { id: searchText, tab: currentTab } : { search: searchText, tab: currentTab }
    fetchData(params)
  }, [searchText, fetchData, currentTab])

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13) handleSearch()
    },
    [handleSearch],
  )

  const handleLoadMore = useCallback(() => {
    offsetRef.current += 1

    let searchFields = {}

    if (searchText) {
      searchFields = isNumeric(searchText) ? { id: searchText } : { search: searchText }
    }

    const params = { tab: currentTab, limit: 10, offset: offsetRef.current * 10, ...searchFields }

    fetchData(params)
  }, [searchText, fetchData, currentTab])

  const handleTabChange = useCallback(
    (value) => () => {
      // Reset offset
      offsetRef.current = 0
      setCurrentTab(value)
    },
    [],
  )

  const tableData = useMemo(() => {
    switch (currentTab) {
      case USER_GROUP_TABS.AUTO_UPDATE:
        return groupList.filter((item) => item.auto_update)
      default:
        return groupList
    }
  }, [groupList, currentTab])

  const renderFilterType = useCallback((rowData) => {
    switch (rowData.view.source) {
      case FILTER_METHODS.BY_MANUAL:
        return '新增篩選維度'
      case FILTER_METHODS.BY_FILE:
        return '檔案上傳'
      default:
        return null
    }
  }, [])

  const renderFilterValues = useCallback((rowData) => {
    if (Array.isArray(rowData.view.rules) && rowData.view.rules.length) return rowData.view.rules.join(' | ')
    return '-'
  }, [])

  const renderExcludeUserGroup = useCallback((rowData) => {
    if (Array.isArray(rowData.view.filter_table_title_list) && rowData.view.filter_table_title_list.length)
      return rowData.view.filter_table_title_list.join(' | ')
    return '-'
  }, [])

  const renderFrequencyByDay = useCallback((rowData) => {
    if (rowData.frequency_by_day) {
      return FREQUENCY_BY_DAY_LABEL[rowData.frequency_by_day]
    }
    return '-'
  }, [])

  const renderGroupTitle = useCallback((rowData) => {
    const color = rowData.end_time && dayjs.unix(rowData.end_time).isAfter(new Date()) ? 'green' : null
    return (
      <>
        {!!rowData.auto_update && (
          <Tag style={{ marginRight: '4px' }} color={color}>
            範本
          </Tag>
        )}
        {rowData.title}
      </>
    )
  }, [])

  return (
    <>
      {warning && <PopWarning warning={warning} textCenter confirm={() => setWarning('')} />}
      <FlexWrapper>
        <Title>分眾名單管理</Title>
        <SearchInput
          title="輸入ID或名稱搜尋"
          onKeyDown={handleKeyDown}
          onClick={handleSearch}
          value={searchText}
          setSearchWord={setSearchText}
          withButton
        />
        <Nav appearance="subtle">
          <Nav.Item active={currentTab === USER_GROUP_TABS.ALL} onSelect={handleTabChange(USER_GROUP_TABS.ALL)}>
            所有分眾
          </Nav.Item>
          <Nav.Item active={currentTab === USER_GROUP_TABS.AUTO_UPDATE} onSelect={handleTabChange(USER_GROUP_TABS.AUTO_UPDATE)}>
            範本分眾
          </Nav.Item>
        </Nav>
        <div className="table-container">
          <Table data={tableData} shouldUpdateScroll={false} fillHeight affixHeader bordered>
            <Column align="center">
              <HeaderCell>ID</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column flexGrow={4} minWidth={240} align="center">
              <HeaderCell>分眾名單</HeaderCell>
              <Cell dataKey="title">{renderGroupTitle}</Cell>
            </Column>
            <Column align="center">
              <HeaderCell>標籤</HeaderCell>
              <WhisperCell dataKey="label" />
            </Column>
            <Column align="center">
              <HeaderCell>數量</HeaderCell>
              <Cell dataKey="count" />
            </Column>
            <Column align="center">
              <HeaderCell>分眾篩選方式</HeaderCell>
              <CellWithPopover dataKey="view.source" wordWrap renderPopoverContent={renderFilterType}>
                {renderFilterType}
              </CellWithPopover>
            </Column>
            <Column flexGrow={4} minWidth={240} align="center">
              <HeaderCell>分眾篩選維度</HeaderCell>
              <CellWithPopover dataKey="view.rules" wordWrap renderPopoverContent={renderFilterValues}>
                {renderFilterValues}
              </CellWithPopover>
            </Column>
            <Column flexGrow={4} minWidth={200} align="center">
              <HeaderCell>排除分眾名單</HeaderCell>
              <CellWithPopover dataKey="view.filter_table_title_list" wordWrap renderPopoverContent={renderExcludeUserGroup}>
                {renderExcludeUserGroup}
              </CellWithPopover>
            </Column>
            <Column align="center">
              <HeaderCell>最後更新時間</HeaderCell>
              <DateCell dataKey="updated_at" format="YYYY-MM-DD HH:mm" />
            </Column>
            <Column align="center">
              <HeaderCell>自動更新週期</HeaderCell>
              <Cell dataKey="frequency_by_day">{renderFrequencyByDay}</Cell>
            </Column>
            <Column align="center">
              <HeaderCell>更新直到</HeaderCell>
              <DateCell dataKey="end_time" format="YYYY-MM-DD" />
            </Column>
            <Column minWidth={400} align="center" flexGrow={6}>
              <HeaderCell>Action</HeaderCell>
              <ActionCell
                dataKey="id"
                handleDelete={handleDeleteGroup}
                handleCopy={handleCopyGroup}
                handleRead={handleReadAndModify}
                handleEdit={handleEdit}
              />
            </Column>
          </Table>
        </div>
        <ButtonsWrapper>
          <StyledPageButton appearance="primary" onClick={handleLoadMore}>
            載入更多
          </StyledPageButton>
        </ButtonsWrapper>
      </FlexWrapper>
    </>
  )
}

export default UserGroup
