import React from 'react'
import { Icon } from 'rsuite'

const statusMap = {
  RESERVE_SUCCESS: {
    text: '預約成功',
  },
  CANCELED: {
    text: '已取消',
    icon: 'minus-circle',
    color: '#8e8e93',
  },
  ABOUT_TO_START: {
    text: '即將開始',
  },
  OVERTIME_CANCELED: {
    text: '逾時取消預約',
    icon: 'minus-circle',
    color: '#8e8e93',
  },
  IN_USE: {
    text: '用車中',
    icon: 'repeat',
    color: '#3498FF',
  },
  PENDING: {
    text: '待付款',
  },
  COMPLETED: {
    text: '已完成',
    icon: 'check-circle',
  },
  UNFINISHED: {
    text: '異常還車',
    icon: 'warning',
    color: '#ff45b1',
  },
}

const OrderStatus = ({ status }) => {
  const config = statusMap[status]
  return (
    config && (
      <div className="text-gray-2">
        <Icon icon={config?.icon || 'circle-o'} style={{ marginRight: 4, ...(config.color && { color: config.color }) }} />
        <span>{config?.text}</span>
      </div>
    )
  )
}

export default React.memo(OrderStatus)
