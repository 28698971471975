import React, { useCallback } from 'react'
import { TabItem, TabRoot } from './styles'

export const MGM_FORM_TABS = {
  INFO: { key: 'INFO', label: '活動資料' },
  MAIN_PAGE: { key: 'MAIN_PAGE', label: '主態活動頁' },
  VISITOR_PAGE: { key: 'VISITOR_PAGE', label: '客態活動頁' },
  COUPON: { key: 'COUPON', label: 'Coupon' },
  FIRST_AWARD: { key: 'FIRST_AWARD', label: '首搭獎勵' },
  BOT_CARD: { key: 'BOT_CARD', label: 'BOT卡片' },
  THEME: { key: 'THEME', label: '主題顏色' },
  PREVIEW: { key: 'PREVIEW', label: '預覽畫面' },
}

const Tabs = ({ currentTab = MGM_FORM_TABS.INFO, onTabChange }) => {
  const renderTabItems = useCallback(() => {
    return Object.values(MGM_FORM_TABS).map((item) => (
      <TabItem key={item.key} onClick={onTabChange(item)} isActive={currentTab.key === item.key}>
        {item.label}
      </TabItem>
    ))
  }, [onTabChange, currentTab])

  return <TabRoot>{renderTabItems()}</TabRoot>
}

export default Tabs
