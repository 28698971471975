/* eslint-disable consistent-return */
import React, { useState, useEffect, useContext } from 'react'
import { RadioGroup, Radio, Input, Loader } from 'rsuite'
import { ContextStore } from '../../reducers'
import { FileInput } from '../../components/finance'
import PopWarning from '../../components/popWarning'
import { postCtbcRecord } from '../../api'
import { AddListModel, FormGrop, AddListGrop } from '../../components/views'

const initState = { file: null, fileType: 'A0001', yearweek: '' }

const FinanceAllocationCtbc = () => {
  const { auth } = useContext(ContextStore)
  const [warning, setWarning] = useState('')
  const [userId, setUserId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [postData, setPostData] = useState(initState)

  useEffect(() => {
    if (auth && auth.user) setUserId(auth.user.admin_id)
  }, [auth])

  const handleChangeFile = (e) => {
    const file = e.target.files[0]
    if (file) setPostData({ ...postData, file })
  }

  const handleSubmit = async () => {
    const { fileType, yearweek, file } = postData
    if (!userId || !file || !fileType || !yearweek) return

    const formData = new FormData()
    formData.append('uploaded_file', file)
    formData.append('yearweek', yearweek)
    formData.append('type', fileType)
    setLoading(true)
    const response = await postCtbcRecord(formData)
    setLoading(false)
    if (response.status !== 200) return setWarning(response.data.msg)
    setWarning('上傳成功！請到信箱收取報表')
    setPostData(initState)
  }

  const { fileType, yearweek } = postData
  return (
    <>
      {warning && <PopWarning warning={warning} textCenter confirm={() => setWarning('')} />}
      <AddListModel>
        {loading && <Loader size="md" center vertical style={{ marginLeft: 260, zIndex: 10 }} />}
        <AddListGrop title="上傳中信撥款紀錄">
          <FormGrop title="撥款週期" type="children">
            <Input value={yearweek} onChange={(yearweek) => setPostData({ ...postData, yearweek })} style={{ width: 'max-content' }} />
          </FormGrop>
          <FormGrop title="檔案類型" type="children">
            <RadioGroup inline value={fileType} onChange={(value) => setPostData({ ...postData, fileType: value })}>
              <Radio value="A0001">A0001</Radio>
              <Radio value="ACH">ACH</Radio>
            </RadioGroup>
          </FormGrop>
          <FormGrop title="上傳檔案" type="children">
            <FileInput handleChangeFile={handleChangeFile} />
          </FormGrop>
        </AddListGrop>
        <div className="campaign-button-wrap">
          <button type="button" className="campaign-confirm-button" onClick={handleSubmit}>
            送出
          </button>
        </div>
      </AddListModel>
    </>
  )
}

export default FinanceAllocationCtbc
