import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Alert, Loader } from 'rsuite'
import CouponForm from '../../components/new_coupons/form'
import { getCoupons } from '../../api'
import { COUPON_FORM_FEATURES } from '../../hook/coupons/useFormHelper'

const DuplicateCoupon = () => {
  const { promo_id } = useParams()
  const [isInitializing, setIsInitializing] = useState(true)
  const [data, setData] = useState()

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsInitializing(true)
        const params = { search: promo_id }
        const resp = await getCoupons(params)
        if (resp.status !== 200) throw new Error(resp.data.msg)
        if (!resp.data.length) {
          throw new Error(` 無法取得優惠券 ${promo_id}`)
        }
        setData(resp.data[0])
      } catch (error) {
        Alert.error(error.message)
      }
      setIsInitializing(false)
    }
    fetch()
  }, [promo_id])

  return (
    <>
      {isInitializing && <Loader center content="loading" />}
      {!isInitializing && data && <CouponForm feature={COUPON_FORM_FEATURES.DUPLICATE} initialValues={data} />}
    </>
  )
}

export default DuplicateCoupon
