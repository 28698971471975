import React, { useCallback, useState } from 'react'
import { Button, Input, InputPicker } from 'rsuite'
import PreviewMainPage from '../preview/PreviewMainPage'
import { FormController, FormField, FormLabel, PanelThemeContainer, SectionTitle, Stack, ColorInputGroup } from './styles'

const PanelTheme = () => {
  const [togglePreview, setTogglePreview] = useState(false)

  const handlePreviewClick = useCallback(() => {
    setTogglePreview((prev) => !prev)
  }, [])

  return (
    <PanelThemeContainer>
      <div className="left-box">
        <Stack>
          <div className="justify-end">
            <Button className="preview-button" onClick={handlePreviewClick}>
              預覽
            </Button>
          </div>
          <SectionTitle>各頁面通用顏色</SectionTitle>
          <FormField isRequired>
            <FormLabel>外觀模式</FormLabel>
            <FormController>
              <InputPicker placeholder="請選擇" />
            </FormController>
          </FormField>
          <FormField isRequired>
            <FormLabel>標題</FormLabel>
            <FormController>
              <ColorInputGroup>
                <Input placeholder="#ffffff" />
                <input type="color" />
              </ColorInputGroup>
            </FormController>
          </FormField>
          <FormField isRequired>
            <FormLabel>內文</FormLabel>
            <FormController>
              <ColorInputGroup>
                <Input placeholder="#ffffff" />
                <input type="color" />
              </ColorInputGroup>
            </FormController>
          </FormField>
          <FormField isRequired>
            <FormLabel>主要背景</FormLabel>
            <FormController>
              <ColorInputGroup>
                <Input placeholder="#ffffff" />
                <input type="color" />
              </ColorInputGroup>
            </FormController>
          </FormField>
          <FormField isRequired>
            <FormLabel>按鈕文字</FormLabel>
            <FormController>
              <ColorInputGroup>
                <Input placeholder="#ffffff" />
                <input type="color" />
              </ColorInputGroup>
            </FormController>
          </FormField>
          <FormField isRequired>
            <FormLabel>按鈕背景</FormLabel>
            <FormController>
              <ColorInputGroup>
                <Input placeholder="#ffffff" />
                <input type="color" />
              </ColorInputGroup>
            </FormController>
          </FormField>
          <FormField isRequired>
            <FormLabel>按鈕邊線</FormLabel>
            <FormController>
              <ColorInputGroup>
                <Input placeholder="#ffffff" />
                <input type="color" />
              </ColorInputGroup>
            </FormController>
          </FormField>
          <SectionTitle>好友清單顏色</SectionTitle>
          <FormField isRequired>
            <FormLabel>好友清單背景</FormLabel>
            <FormController>
              <ColorInputGroup>
                <Input placeholder="#ffffff" />
                <input type="color" />
              </ColorInputGroup>
            </FormController>
          </FormField>
          <FormField isRequired>
            <FormLabel>統計盒標題</FormLabel>
            <FormController>
              <ColorInputGroup>
                <Input placeholder="#ffffff" />
                <input type="color" />
              </ColorInputGroup>
            </FormController>
          </FormField>
          <FormField isRequired>
            <FormLabel>統計盒副標題</FormLabel>
            <FormController>
              <ColorInputGroup>
                <Input placeholder="#ffffff" />
                <input type="color" />
              </ColorInputGroup>
            </FormController>
          </FormField>
          <FormField isRequired>
            <FormLabel>統計盒背景</FormLabel>
            <FormController>
              <ColorInputGroup>
                <Input placeholder="#ffffff" />
                <input type="color" />
              </ColorInputGroup>
            </FormController>
          </FormField>
          <FormField isRequired>
            <FormLabel>舊會員背景</FormLabel>
            <FormController>
              <ColorInputGroup>
                <Input placeholder="#ffffff" />
                <input type="color" />
              </ColorInputGroup>
            </FormController>
          </FormField>
          <FormField isRequired>
            <FormLabel>新會員背景</FormLabel>
            <FormController>
              <ColorInputGroup>
                <Input placeholder="#ffffff" />
                <input type="color" />
              </ColorInputGroup>
            </FormController>
          </FormField>
          <FormField isRequired>
            <FormLabel>完成首搭背景</FormLabel>
            <FormController>
              <ColorInputGroup>
                <Input placeholder="#ffffff" />
                <input type="color" />
              </ColorInputGroup>
            </FormController>
          </FormField>
        </Stack>
      </div>
      {togglePreview && (
        <div className="right-box">
          <Stack>
            <PreviewMainPage />
          </Stack>
        </div>
      )}
    </PanelThemeContainer>
  )
}

export default PanelTheme
