import React from 'react'
import { Cell } from 'rsuite-table'
import { TagItem } from './TagItem'

export const TypeCell = ({ rowData, ...props }) => {
	return (
		<Cell {...props}>
			{rowData.type === 'NORMAL' && <TagItem type={'normal'} />}
			{rowData.type === 'SHUTTLE' && <TagItem type={'shuttle'} />}
			{rowData.type === 'FIRST_TIME' && <TagItem type={'first'} />}
		</Cell>
	)
}

export default TypeCell
