import React from 'react'
import { DatePicker, Breadcrumb } from 'rsuite'
import AddListModel from '../../views/AddListModel'
import AddListGrop from '../../views/AddListGrop'
import FormGropWrap from '../../views/FormGropWrap'
import FormGrop from '../../views/FormGrop'

function AddModel({ form, currentUpdate, handleAdd, handleCancel, handleOnChange, actionData, handleDatePicker, handleActionSelect, formError, handlePayloadOnChange, handleOnUpload, isLoading }) {
	return (
		<div className="add-model-wrap">
			<AddListModel>
				<Breadcrumb>
					<Breadcrumb.Item onClick={handleCancel}>Carousel</Breadcrumb.Item>
					<Breadcrumb.Item active>Add Carousel</Breadcrumb.Item>
				</Breadcrumb>
				<AddListGrop title={'新增活動'}>
					<FormGropWrap>
						<FormGrop title={'開始時間'} type={'children'} errorMessage={formError.start_time} >
							<DatePicker
								defaultValue={form.start_time ? new Date(form.start_time * 1000) : undefined}
								onOk={(date) => handleDatePicker(date, 'start_time')}
								placement={'bottomStart'}
								appearance={'subtle'}
								placeholder={'From...'}
								isoWeek
								hideMinutes={second => second % 30 !== 0}
								format={'YYYY-MM-DD HH:mm'}
								ranges={[{ value: new Date(), closeOverlay: true }]} />
						</FormGrop>
						<FormGrop title={'結束時間'} type={'children'} errorMessage={formError.end_time}>
							<DatePicker
								defaultValue={form.end_time ? new Date(form.end_time * 1000) : undefined}
								onOk={(date) => handleDatePicker(date, 'end_time')}
								placement={'bottomStart'}
								appearance={'subtle'}
								placeholder={'To...'}
								isoWeek
								hideMinutes={second => second % 30 !== 0}
								format={'YYYY-MM-DD HH:mm'}
								ranges={[{ value: new Date(), closeOverlay: true }]} />
						</FormGrop>
					</FormGropWrap>
					<FormGrop title={'圖片網址'} placeholder={'Image url'} type={'input'} handleOnChange={handleOnChange} value={form.img} setStateKey={'img'} errorMessage={formError.img} />
					<FormGrop type={'uploader'} multiple={false} handleOnUpload={handleOnUpload} isLoading={isLoading} />
				</AddListGrop>
				<AddListGrop title={'設定'}>
					<FormGrop title={'優先順序'} placeholder={'Priority'} type={'input'} handleOnChange={handleOnChange} value={form.priority} setStateKey={'priority'} errorMessage={formError.priority} />
					<FormGrop title={'連結開啟方式'} placeholder={'Action'} type={'select-button'} handleOnChange={handleActionSelect} options={actionData} value={form.action.action} errorMessage={formError.action} />
					<FormGrop title={'網址'} placeholder={'Url'} type={'input'} handleOnChange={handlePayloadOnChange} value={form.action.payload} setStateKey={'payload'} errorMessage={formError.payload} />
				</AddListGrop>
				<div className="campaign-button-wrap">
					<button className="campaign-confirm-button" onClick={handleAdd}>{currentUpdate ? '更新' : '新增'}</button>
					<button className="campaign-cancel-button" onClick={handleCancel}>取消</button>
				</div>
			</AddListModel>
		</div>
	)

}

export default AddModel
