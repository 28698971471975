import React, { useState } from 'react'
import {
  getCompanyUserList,
  createCompanyUser,
  putCompanyUser,
  forceCompanyUser,
  setAdminPermission,
  sendVerifyMail,
} from '../../../api'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

class UserList extends React.Component {
  state = {
    users: [],
    createUser: false,
    editUser: false,
    forceUser: false,
    planStatuses: ['BLOCKED', 'PENDING', 'ACCEPTED'],

    user_id: '',
    user_name: '',
    user_email: '',
    group_name: '',

    user_rider_id: '',
    user_status: '',
  }

  componentDidMount() {
    this.getCompanyUserList()
  }

  getCompanyUserList = async () => {
    const data = await getCompanyUserList(this.props.company_id)
    console.log('getCompanyAdmin' + JSON.stringify(data.data.data.users))
    this.setState({ users: data.data.data.users })
  }

  createUser = async () => {
    const body = {
      name: this.state.user_name,
      email: this.state.user_email,
      group_name: this.state.group_name,
    }
    if (this.state.user_name === '' || this.state.email === '') {
      return
    }
    if (this.state.group_name === '') {
      delete body.group_name
    }

    const result = await createCompanyUser(this.props.company_id, body)
    if (result.status !== 200) return
    alert('新增成功')
    window.location.reload()
  }

  editUser = async () => {
    if (this.state.user_name === '' || this.state.email === '') {
      return
    }
    const body = {
      name: this.state.user_name,
      email: this.state.user_email,
      company: this.props.company_id,
    }
    const result = await putCompanyUser(this.state.user_id, body)
    if (result.status !== 200) return
    alert('編輯成功')
    window.location.reload()
  }

  forceUser = async () => {
    const body = {
      status: this.state.user_status,
      rider_id:
        this.state.user_rider_id === '' ? null : this.state.user_rider_id,
      company: this.props.company_id,
    }
    console.log(JSON.stringify(body))
    const result = await forceCompanyUser(this.state.user_id, body)
    if (result.status !== 200) return
    alert('強制設定成功')
    window.location.reload()
  }

  setAdminPermission = async (unit_id) => {
    const body = {
      company: this.props.company_id,
      permission: 'ADMIN',
    }
    const result = await setAdminPermission(unit_id, body)
    if (result.status !== 200) return
    alert('設定成功')
    window.location.reload()
  }

  send_verify_mail = async (id, company) => {
    const result = await sendVerifyMail({
      id: [id],
      company,
    })
    if (result.status !== 200) return
    alert('發送成功')
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  render() {
    return (
      <>
        <Popover
          id="createUser"
          open={this.state.createUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {/*新增成員*/}
          <div style={{ padding: 10, textAlign: 'center' }}>
            <h4>新增成員</h4>
            <div>
              <label htmlFor="inputName" className="col-sm-2 control-label">
                姓名
              </label>
              <div className="col-sm-10">
                <TextField
                  name="user_name"
                  className="form-control"
                  id="inputName"
                  placeholder="員工姓名"
                  value={this.state.user_name}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div>
              <label htmlFor="inputName" className="col-sm-2 control-label">
                E-mail
              </label>
              <div className="col-sm-10">
                <TextField
                  name="user_email"
                  className="form-control"
                  id="inputName"
                  placeholder="E-mail"
                  value={this.state.user_email}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div>
              <label htmlFor="inputName" className="col-sm-2 control-label">
                指定群組名稱(可以不填)
              </label>
              <div className="col-sm-10">
                <TextField
                  name="group_name"
                  className="form-control"
                  id="inputName"
                  placeholder="群組名稱"
                  value={this.state.group_name}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div style={{ padding: 10 }}>
              <button
                type="button"
                className="btn btn-default"
                onClick={() => {
                  this.setState({
                    createUser: false,
                  })
                }}
              >
                取消
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.createUser}
              >
                新增成員
              </button>
            </div>
          </div>
        </Popover>

        <Popover
          id="editUser"
          open={this.state.editUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {/*編輯成員*/}
          <div style={{ padding: 10, textAlign: 'center' }}>
            <h4>編輯成員</h4>
            <div>
              <label htmlFor="inputName" className="col-sm-2 control-label">
                姓名
              </label>
              <div className="col-sm-10">
                <TextField
                  name="user_name"
                  className="form-control"
                  id="inputName"
                  placeholder="員工姓名"
                  value={this.state.user_name}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div>
              <label htmlFor="inputName" className="col-sm-2 control-label">
                E-mail
              </label>
              <div className="col-sm-10">
                <TextField
                  name="user_email"
                  className="form-control"
                  id="inputName"
                  placeholder="E-mail"
                  value={this.state.user_email}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>

            <div style={{ padding: 10 }}>
              <button
                type="button"
                className="btn btn-default"
                onClick={() => {
                  this.setState({
                    editUser: false,
                  })
                }}
              >
                取消
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.editUser}
              >
                更新成員
              </button>
            </div>
          </div>
        </Popover>

        <Popover
          id="forceUser"
          open={this.state.forceUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {/*危險設定成員*/}
          <div style={{ padding: 10, textAlign: 'center' }}>
            <h4>非法設定成員</h4>
            <div>
              <label htmlFor="inputName" className="col-sm-2 control-label">
                非法指定 rider_id
              </label>
              <div className="col-sm-10">
                <TextField
                  name="user_rider_id"
                  className="form-control"
                  id="inputName"
                  placeholder="user_rider_id"
                  value={this.state.user_rider_id}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>

            <TextField
              select
              label="狀態"
              value={this.state.user_status}
              onChange={(event) => {
                this.setState({
                  user_status: event.target.value,
                })
              }}
              margin="normal"
            >
              {this.state.planStatuses.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <div style={{ padding: 10 }}>
              <button
                type="button"
                className="btn btn-default"
                onClick={() => {
                  this.setState({
                    forceUser: false,
                  })
                }}
              >
                取消
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.forceUser}
              >
                非法設定
              </button>
            </div>
          </div>
        </Popover>

        <div>
          <button
            type="submit"
            className="company-add-button"
            style={{ marginBottom: 20 + 'px' }}
            onClick={() => {
              this.setState({
                createUser: true,
              })
            }}
          >
            手動新增成員
          </button>

          <div className="box">
            <div className="box-body table-responsive no-padding">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <td>姓名</td>
                    <td>E-mail</td>
                    <td>叫車ID</td>
                    <td>status</td>
                    <td>permission</td>
                    <td>group</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.users.map(function (user) {
                    return (
                      <tr key={user.id}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.user_id}</td>
                        <td>{user.status}</td>
                        <td>{user.permission}</td>
                        <td>{user.group_name}</td>
                        <td>
                          <div className="user-control-wrap">
                            <button
                              type="submit"
                              className="company-white-button user-list-control-button"
                              onClick={() => {
                                this.setState({
                                  user_id: user.id,
                                  user_name: user.name,
                                  user_email: user.email,
                                  user_rider_id: user.user_id,
                                  user_status: user.status,
                                  editUser: true,
                                })
                              }}
                            >
                              編輯
                            </button>
                            <button
                              type="submit"
                              className="company-white-button user-list-control-button"
                              onClick={() => {
                                this.setAdminPermission(user.id)
                              }}
                            >
                              設為 Admin
                            </button>
                          </div>
                          <button
                            id={'padding' + user.id}
                            className="company-white-button user-list-control-button"
                            onClick={() =>
                              this.send_verify_mail(
                                user.id,
                                this.props.company_id
                              )
                            }
                          >
                            {' '}
                            {'重新發送驗證信 ' + user.name}{' '}
                          </button>

                          <button
                            className="dangerous-button"
                            // style={{ marginBottom: 20 + 'px' }}
                            onClick={() => {
                              this.setState({
                                user_id: user.id,
                                user_rider_id: user.user_id,
                                user_status: user.status,
                                forceUser: true,
                              })
                            }}
                          >
                            非法設定成員
                          </button>
                        </td>
                      </tr>
                    )
                  }, this)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default UserList
