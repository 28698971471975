import React from 'react'
import { Container } from 'rsuite'
import { ContextStore } from '../../reducers'
import Header from '../../components/header'
import Sidebar from '../../components/sidebar'
import Content from '../../components/content'
import Loading from '../../components/loading'

function MainLayout({ children }) {
  const { auth } = React.useContext(ContextStore)

  function isNotInIframe() {
    try {
      return window.self === window.top
    } catch (e) {
      return false
    }
  }

  return (
    <div className="main-layout-wrap" style={{ minHeight: '100vh' }}>
      <Container>
        {isNotInIframe() && auth.user && <Sidebar />}
        <Container>
          {isNotInIframe() && <Header user={auth.user} />}
          <Content>{children}</Content>
          {/* <Footer>Footer</Footer> */}
        </Container>
      </Container>
    </div>
  )
}

export default MainLayout
