import React from 'react'

function RadioBox({ label, value, index, currentValue, handleOnChange, setStateKey }) {
	return (
		<label className="control control-radio" htmlFor={index}>
			<input type="radio" value={value} name="radio" id={index} onChange={(e) => handleOnChange(e, setStateKey)} checked={currentValue === value} />
			<div className="control-indicator"></div>
			<div className="subtitle_01">{label}</div>
		</label>
	)
}

export default React.memo(RadioBox)
