import React from 'react'
import { Tag } from 'rsuite'
import dayjs from 'dayjs'

const StatusLabel = React.memo(({ logs }) => {
  let component
  const reservationDisabled = logs.some((v) => v.status === 'SYS_BLOCKED')
  const canceledLogs = logs.filter((v) => v.time > dayjs().subtract(90, 'days').unix() && ['DRIVER_CANCELLED', 'AUTO'].includes(v.status))
  switch (true) {
    case reservationDisabled:
      component = (
        <Tag color="red">
          <strong>關閉</strong>
        </Tag>
      )
      break
    case canceledLogs.length === 0:
    case canceledLogs.length === 1:
      component = (
        <Tag color="green">
          <strong>正常</strong>
        </Tag>
      )
      break
    case canceledLogs.length === 2:
      if (dayjs().unix() > canceledLogs[0].time + 86400 * 7) {
        component = (
          <Tag color="green">
            <strong>正常</strong>
          </Tag>
        )
      } else {
        component = (
          <Tag color="yellow">
            <strong>封鎖七天</strong>
          </Tag>
        )
      }
      break
    default:
      component = (
        <Tag color="red">
          <strong>永久封鎖</strong>
        </Tag>
      )
      break
  }
  return component
})

export default StatusLabel
