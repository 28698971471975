import React from 'react'
import { Modal, Button } from 'rsuite'

const NewsModal = ({ showModal, setModalShown, value, setSearchInput, handleSearchUser }) => {
  const handleConfirm = () => {
    handleSearchUser(value)
    setModalShown(false)
  }

  const handleClose = () => {
    setModalShown(false)
  }

  const handleOnChange = (e) => {
    setSearchInput(e.target.value)
  }

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>請輸入司機 ID</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input type="number" placeholder="輸入司機 ID" value={value} onChange={handleOnChange}/>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleConfirm} appearance="primary">
          搜尋
        </Button>
        <Button onClick={handleClose} appearance="subtle">
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(NewsModal)