import React, { useState, useEffect, useCallback } from 'react'
import { debounce, isEmpty, orderBy } from 'lodash'
import { Alert, Button, Table } from 'rsuite'
import usePageSetting from '../../hook/usePageSetting'
import { AddListModel, AddListGrop, BreadcrumbNav, ListWrap, InputGroupWidthButton } from '../../components/views'
import { CommonActionCell as ActionCell } from '../../components/table/cell'
import { EmployeeForm } from '../../components/identities'
import EmployeeModel from './models'
import { EMPLOY_STATUS } from '../../components/identities/const'
import { ListWrapper } from '../../components/identities/styles'
import { IdentityServices } from '../../api'

const { Column, HeaderCell, Cell, Pagination } = Table

const initCurrentInfo = {
  id: null,
  name: '',
  username: '',
  email: '',
  phone: '',
  role: '',
  type: '',
  status: 'ACTIVE',
  permissions: [],
}

const convertResponseToState = (data) => {
  return data.map(({ admin_id: id, full_name: name, phone_number: phone, status, type, role, permissions, ...data }) => ({
    ...data,
    role: [role],
    id,
    name,
    type: type || 'PT',
    originalType: type,
    status: status || 'QUIT',
    originalStatus: status,
    phone,
    permissions: permissions.map(v => v.name) || [],
  }))
}

const convertStateToReqBody = ({ id, name, phone, role, status, ...data }) => {
  // const statusValue = Object.keys(EMPLOY_STATUS).find((key) => EMPLOY_STATUS[key] === status)
  return { id: id || undefined, full_name: name, phone_number: `+886${phone}`, status, role: role[0], ...data }
}

const PersonnelManageMent = () => {
  const [params, setParams] = useState({ page: 0, search: '' })
  const [pageInfo, setPageInfo] = useState({ currentPage: 0, totalPage: 0, totalCount: 0 })
  const [isAdd, setIsAdd] = useState(false)
  const [sortColumn, setSortColumn] = useState(null)
  const [sortType, setSortType] = useState(null)
  const [roles, setRoles] = useState([])
  const { warning, setWarning, isAddMode, toggleAddMode, list, setList, currentInfo, setCurrentInfo, permissions, setPermissions } = usePageSetting(initCurrentInfo)
  const handleOnSortColum = (sortColumn, sortType) => {
    if (isEmpty(sortColumn)) return
    const newData = orderBy([...list], [sortColumn], [sortType])
    setList(newData)
    setSortColumn(sortColumn)
    setSortType(sortType)
  }
  const debounceSearch = debounce((text) => {
    setParams({ page: 1, search: text })
  }, 300)
  const fetchList = useCallback(async (queryParams = {}) => {
    const { page, ...restParams } = queryParams
    const meta = { offset: page < 1 ? 0 : (page - 1) * 20, limit: 20, ...restParams }
    // await setList(FAKE_LIST_DATA)
    const data = await IdentityServices.getIdentityAdmins(meta)
    if (data.status !== 200) {
      setWarning(data.data.msg)
      return
    }

    const { data: responseData, meta: responseMeta } = data.data
    const convertedData = convertResponseToState(responseData || [])
    setList(convertedData)
    setPageInfo({
      currentPage: responseMeta?.current_page || 1,
      totalPage: responseMeta?.total_page || 1,
      totalCount: responseMeta?.total_count || 0,
    })
  }, [])
  const fetchRoles = async () => {
    // await setRoles([])
    const data = await IdentityServices.getIdentityRoles()
    if (data.status !== 200) {
      setWarning(data.data.msg)
      return
    }
    const roleMap = data.data?.role?.reduce((acc, item) => {
      acc[item] = item
      return acc
    }, {})
    setRoles(roleMap || [])
  }
  const fetchPermissions = async () => {
    const data = await IdentityServices.getPermissions()
    if (data.status !== 200) {
      setWarning(data.data.msg)
      return
    }
    setPermissions(data.data?.data?.map(v => {
      const label = v.description ? `${v.name}(${v.description})` : v.name
      return { label, ...v }
    }))
  }
  const fetchIdentityData = async (id) => {
    if (list.length === 0) return
    window.history.replaceState(null, null, `${window.location.pathname}?identity_id=${id}&page=${params.page}&search=${params.search}`)
    setCurrentInfo(list.find((info) => info.id === Number(id)))
  }
  const handleSelectPage = (page) => {
    setParams({ ...params, page })
  }
  const handleSuccess = useCallback(
    async (body) => {
      const convertedBody = convertStateToReqBody(body)
      const data = isAdd ? await IdentityServices.postIdentityAdmins(convertedBody) : await IdentityServices.putIdentityAdmins(body.id, convertedBody)
      if (data.status !== 200) {
        setWarning(data.data?.msg || '操作失敗')
        return
      }
      isAddMode && toggleAddMode()
      Alert.info(isAdd ? '新增成功' : '修改成功')
      window.history.replaceState(null, null, `${window.location.pathname}?page=${params.page}`)
      await fetchList(params)
    },
    [isAdd, isAddMode, params, fetchList, toggleAddMode, setWarning],
  )
  const handleAddClick = () => {
    setIsAdd(true)
    toggleAddMode()
    setCurrentInfo(initCurrentInfo)
  }
  const handleEmailClick = useCallback(
    async (id) => {
      const data = await IdentityServices.postIdentityResetPassword(id)
      if (data.status !== 200) {
        setWarning(data.data?.msg || '發信失敗')
        return
      }
      Alert.info('成功寄信')
    },
    [setWarning],
  )
  const handleEditClick = (id) => {
    if (isAddMode) return
    setIsAdd(false)
    toggleAddMode()
    fetchIdentityData(id)
  }

  const getIconHandlers = (rowData, dataKey) => {
    return EMPLOY_STATUS[(rowData[dataKey] || '').toUpperCase()] === EMPLOY_STATUS.ACTIVE
      ? [
          { icon: 'envelope', handler: () => handleEmailClick(rowData.id) },
          { icon: 'edit2', handler: () => handleEditClick(rowData.id) },
        ]
      : [{ icon: 'edit2', handler: () => handleEditClick(rowData.id) }]
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const page = Number(searchParams.get('page')) || params.page || 1
    const search = Number(searchParams.get('search')) || params.search || ''
    setParams({ ...params, page, search })
  }, [])

  useEffect(() => {
    if (params.page !== 0) {
      fetchList(params)
      fetchRoles()
      fetchPermissions()
    }
  }, [params, fetchList])

  useEffect(() => {
    if (warning) {
      const warningStr = warning === 'Not Authorized' ? '用戶沒有權限' : warning
      Alert.error(warningStr)
    }
  }, [warning])

  useEffect(() => {
    if (list.length === 0) return
    const toggleSpecificIdentity = () => {
      const searchParams = new URLSearchParams(window.location.search)
      const identityId = searchParams.get('identity_id')
      if (identityId) {
        fetchIdentityData(identityId)
        setIsAdd(false)
        if (!isAddMode) toggleAddMode()
      }
    }
    toggleSpecificIdentity()
  }, [list])

  return isAddMode ? (
    <AddListModel>
      <BreadcrumbNav
        start="公司成員列表"
        entry={isAdd ? '新增成員' : '編輯成員'}
        onClick={() => {
          toggleAddMode()
          // window.history.replaceState(null, null, `${window.location.pathname}?page=${params.page}&search=${params.search}`)
          window.history.replaceState(null, null, window.location.pathname)
        }}
      />
      <AddListGrop title={isAdd ? '新增成員' : '編輯成員'}>
        <EmployeeForm
          model={EmployeeModel}
          isAdd={isAdd}
          currentInfo={currentInfo}
          roles={roles}
          permissions={permissions}
          handleSuccess={handleSuccess}
          handleClose={() => {
            toggleAddMode()
            window.history.replaceState(null, null, `${window.location.pathname}?page=${params.page}&search=${params.search}`)
            // window.history.replaceState(null, null, window.location.pathname)
          }}
          initInfo={initCurrentInfo}
        />
      </AddListGrop>
    </AddListModel>
  ) : (
    <>
      <ListWrap status="成員列表" />
      {!warning && (
        <ListWrapper className="flex">
          <InputGroupWidthButton size="md" placeholder="請輸入中文名/部門搜尋" onChange={debounceSearch} />
          <Button className="add-list" onClick={handleAddClick}>
            + 新增成員
          </Button>
        </ListWrapper>
      )}
      <Table autoHeight data={list} virtualized shouldUpdateScroll sortColumn={sortColumn} sortType={sortType} onSortColumn={handleOnSortColum}>
        <Column width={70} fixed>
          <HeaderCell>ID</HeaderCell>
          <Cell dataKey="id" />
        </Column>
        <Column>
          <HeaderCell>中文名</HeaderCell>
          <Cell dataKey="name" />
        </Column>
        <Column>
          <HeaderCell>使用者帳號</HeaderCell>
          <Cell dataKey="username" />
        </Column>
        <Column width={250}>
          <HeaderCell>email</HeaderCell>
          <Cell dataKey="email" />
        </Column>
        <Column width={150} style={{ fontFamily: 'initial' }}>
          <HeaderCell>手機號碼</HeaderCell>
          <Cell dataKey="phone">{(rowData) => (rowData?.phone ? `+886${rowData.phone}` : '')}</Cell>
        </Column>
        <Column align="center" sortable>
          <HeaderCell>部門</HeaderCell>
          <Cell dataKey="role" />
        </Column>
        <Column align="center" sortable>
          <HeaderCell>工作性質</HeaderCell>
          <Cell dataKey="type" />
        </Column>
        <Column align="center" sortable>
          <HeaderCell>在職狀態</HeaderCell>
          <Cell dataKey="status">{(rowData) => EMPLOY_STATUS[(rowData.status || '').toUpperCase()] || '離職'}</Cell>
        </Column>
        <Column align="center" width={200}>
          <HeaderCell>操作</HeaderCell>
          <ActionCell dataKey="status" iconAndHandlers={getIconHandlers} />
        </Column>
      </Table>
      <Pagination
        last={false}
        first={false}
        ellipsis
        boundaryLinks
        activePage={params.page}
        pages={pageInfo.totalPage}
        maxButtons={5}
        onChangePage={handleSelectPage}
        renderLengthMenu={() => {}}
        renderTotal={() => {}}
      />
    </>
  )
}

export default React.memo(PersonnelManageMent)
