import styled from 'styled-components'

export const TextRow = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
`

export const Label = styled.div`
  width: 120px;
`

export const Text = styled.div`
  font-weight: 500;
`
