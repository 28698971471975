import React, { useState } from 'react'
import { Modal, Button, Input } from 'rsuite'
import { FormGroup } from '../blocked_history/styles'

const ToggleModal = React.memo(({ show, close, confirm, action = 'off' }) => {
  const text = action === 'off' ? '關閉預約功能' : '開啟預約功能'
  return (
    <Modal backdrop={true} show={show} onHide={close} size="sm">
      <Modal.Header>
        <Modal.Title>{text}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        確定{text}?
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close} appearance="subtle">
          取消
        </Button>
        <Button onClick={confirm} appearance="primary">
          確定
        </Button>
      </Modal.Footer>
    </Modal>
  )
})

export default ToggleModal
