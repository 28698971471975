import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Sidebar, Sidenav, Nav, Navbar, Icon } from 'rsuite'
import { ContextStore } from '../../reducers'
import SidebarItem from './SidebarItem'
import Drawer from './Drawer'

const SidebarModel = React.memo(() => {
  const context = useContext(ContextStore)
  const [expand, setExpand] = useState(true)
  const [width, setWidth] = useState(260)

  const handleToggle = () => {
    const width = !expand ? 260 : 56
    setWidth(width)
    setExpand(!expand)
    context.sidebarDispatch({ type: 'width', width })
  }
  return (
    <Sidebar style={{ display: 'flex', flexDirection: 'column', width, height: '100vh', overflowY: 'auto' }} width={width} collapsible>
      <Sidenav.Header className="sidenav-header">
        <img className="sidebar-icon" src="https://static.taxigo-cdn.com/logo/logo_84x84.png" alt="" />
        <div className="sidenav-header-title">TaxiGo Console</div>
      </Sidenav.Header>
      <Sidenav expanded={expand} style={{ flex: '1 1 auto' }}>
        <Sidenav.Body>
          <Nav>
            {SidebarItem.map((item, index) => {
              if (item.drawer) {
                return <Drawer key={item.drawer.name} item={item} index={index} />
              }
              return (
                <Nav.Item
                  key={item.link}
                  eventKey={item.link}
                  className="sidebar-nav-item"
                  componentClass="span"
                  icon={<Icon icon={item.img} />}
                  style={{ height: '50px', display: 'block' }}
                >
                  <Link className="sidebar-selected" to={item.link}>
                    {item.title}
                  </Link>
                </Nav.Item>
              )
            })}
          </Nav>
        </Sidenav.Body>
      </Sidenav>
      <Navbar className="nav-toggle">
        <Navbar.Body>
          <Nav pullRight>
            <Nav.Item onClick={handleToggle} style={{ width: 56, textAlign: 'center' }}>
              <Icon icon={expand ? 'angle-left' : 'angle-right'} />
            </Nav.Item>
          </Nav>
        </Navbar.Body>
      </Navbar>
    </Sidebar>
  )
})

SidebarModel.contextType = ContextStore
export default SidebarModel
