import React, { useCallback } from 'react'
import { Col, ControlLabel, Form, FormControl, FormGroup, Grid, Radio, RadioGroup, Row } from 'rsuite'
import { cloneDeep, set } from 'lodash'
import { Root } from './index.style'
import { useMGMConfig } from '../provider'

function StepLandingPage() {
  const { updateValue, value } = useMGMConfig()

  const handleChange = useCallback(
    (name) => (val) => {
      const new_mgm_share_list = cloneDeep(value.mgm_share_list)
      set(new_mgm_share_list, name, val)
      updateValue('mgm_share_list', new_mgm_share_list)
    },
    [value, updateValue],
  )

  return (
    <Root>
      <Grid fluid>
        <Row>
          <Col xs={24} lg={12}>
            <Grid>
              <Row>
                <Form>
                  <FormGroup>
                    <ControlLabel>標題</ControlLabel>
                    <FormControl value={value.mgm_share_list.title} onChange={handleChange('title')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>圖片連結</ControlLabel>
                    <FormControl value={value.mgm_share_list.banner} onChange={handleChange('banner')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>說明文案</ControlLabel>
                    <FormControl componentClass="textarea" rows={3} value={value.mgm_share_list.desc} onChange={handleChange('desc')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>按鈕文字</ControlLabel>
                    <FormControl value={value.mgm_share_list.buttonText} onChange={handleChange('buttonText')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>「查看活動詳情」連結</ControlLabel>
                    <FormControl value={value.mgm_share_list.activityLink} onChange={handleChange('activityLink')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>客態 Avatar 圖片</ControlLabel>
                    <FormControl value={value.mgm_share_list.friend.defaultAvatar} onChange={handleChange('friend.defaultAvatar')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>客態解任務動詞</ControlLabel>
                    <FormControl value={value.mgm_share_list.friend.formerText} onChange={handleChange('friend.formerText')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>客態解任務受詞</ControlLabel>
                    <FormControl value={value.mgm_share_list.friend.latterText} onChange={handleChange('friend.latterText')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>顏色主題</ControlLabel>
                    <FormControl accepter={RadioGroup} value={value.mgm_share_list.colors.mode} onChange={handleChange('colors.mode')} inline>
                      <Radio value="dark">Dark</Radio>
                      <Radio value="light">Light</Radio>
                    </FormControl>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>顏色 - neutral</ControlLabel>
                    <FormControl value={value.mgm_share_list.colors.neutral} onChange={handleChange('colors.neutral')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>顏色 - mainBg</ControlLabel>
                    <FormControl value={value.mgm_share_list.colors.mainBg} onChange={handleChange('colors.mainBg')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>顏色 - listBg</ControlLabel>
                    <FormControl value={value.mgm_share_list.colors.listBg} onChange={handleChange('colors.listBg')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>顏色 - title</ControlLabel>
                    <FormControl value={value.mgm_share_list.colors.title} onChange={handleChange('colors.title')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>顏色 - buttonBg</ControlLabel>
                    <FormControl value={value.mgm_share_list.colors.buttonBg} onChange={handleChange('colors.buttonBg')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>顏色 - buttonBorder</ControlLabel>
                    <FormControl value={value.mgm_share_list.colors.buttonBorder} onChange={handleChange('colors.buttonBorder')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>顏色 - buttonText</ControlLabel>
                    <FormControl value={value.mgm_share_list.colors.buttonText} onChange={handleChange('colors.buttonText')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>顏色 - boxBg</ControlLabel>
                    <FormControl value={value.mgm_share_list.colors.box.boxBg} onChange={handleChange('colors.box.boxBg')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>顏色 - boxTitle</ControlLabel>
                    <FormControl value={value.mgm_share_list.colors.box.boxTitle} onChange={handleChange('colors.box.boxTitle')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>顏色 - boxText</ControlLabel>
                    <FormControl value={value.mgm_share_list.colors.box.boxText} onChange={handleChange('colors.box.boxText')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>顏色 - oldMember</ControlLabel>
                    <FormControl value={value.mgm_share_list.colors.tag.oldMember} onChange={handleChange('colors.tag.oldMember')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>顏色 - firstRide</ControlLabel>
                    <FormControl value={value.mgm_share_list.colors.tag.firstRide} onChange={handleChange('colors.tag.firstRide')} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>顏色 - newMember</ControlLabel>
                    <FormControl value={value.mgm_share_list.colors.tag.newMember} onChange={handleChange('colors.tag.newMember')} />
                  </FormGroup>
                </Form>
              </Row>
            </Grid>
          </Col>
          <Col xs={24} lg={6}>
            {}
          </Col>
        </Row>
      </Grid>
    </Root>
  )
}

export default StepLandingPage
