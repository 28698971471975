import React from 'react'
import { Cell } from 'rsuite-table'
import { Tag } from 'rsuite'

export const CategoryCell = ({ value, ...props }) => {
  const categoryMap = {
    IMPORTANT: {
      name: '重要',
      color: 'cyan',
    },
    EVENT: {
      name: '活動',
      color: 'green',
    },
    SYSTEM: {
      name: '系統',
      color: null,
    },
  }

  const category = categoryMap[value]

  return <Tag color={category.color}>{category.name}</Tag>
}

export default React.memo(CategoryCell)
