import { createContext, useContext } from 'react'

const DriverMileageContext = createContext({
  openDeleteModal: (_id = '') => {},
})

export const useDriverMileage = () => useContext(DriverMileageContext)

const DriverMileageProvider = DriverMileageContext.Provider

export default DriverMileageProvider
