import React, { Component } from 'react'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import { DatePicker, Breadcrumb } from 'rsuite'
import moment from 'moment-timezone'
import { forEach } from 'lodash'
import { ContextStore } from '../../../reducers'
import { postTable, putTable, getAdvancedCondition, getBotCarousel, postImage, deleteTable } from '../../../api'
import { parseContent, createContent } from './helper/parseContent'
import { TitleCell } from '../../campaign'
import { triggerAtData, adEventType } from '../../../model/campaign'
import BotContentCell from './BotContentCell'
import AddListModel from '../../views/AddListModel'
import AddListGrop from '../../views/AddListGrop'
import FormGropWrap from '../../views/FormGropWrap'
import FormGrop from '../../views/FormGrop'
import ListWrap from '../../views/ListWrap'
import PopWarning from '../../popWarning'
import TableController from '../../table/Table'
import BroadcastActionCell from '../broadcast/BroadcastActionCell'
import BotImageCell from './BotImageCell'

export class BotNotification extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userId: '',
      offset: 0,
      limit: 20,
      table: 'marketing_events',
      mktEvent: [],
      showLoadMore: true,
      addModal: false,
      startDate: '',
      endDate: '',
      endDateError: '',
      startDateError: '',
      currentTitle: '',
      currentTitleError: '',
      currentTriggerAt: '',
      currentTriggerAtError: '',
      currentPriority: 0,
      currentPriorityError: '',
      currentCardTitle: '',
      currentCardTitleError: '',
      currentTextArea: '',
      currentTextAreaError: '',
      currentBackgroundColor: '#ffffff',
      currentBackgroundColorError: '',
      currentBtnText: '',
      currentBtnTextError: '',
      currentBtnColor: '#ffffff',
      currentBtnColorError: '',
      currentImgUrl: '',
      currentImgUrlError: '',
      currentCondition: '',
      currentConditionError: '',
      currentAdType: '',
      warning: undefined,
      currentLink: '',
      currentLinkError: '',
      currentCoupon: '',
      currentCouponError: '',
      confirmAlert: null,
      conditions: [],
      isLoading: false,
    }
  }

  componentDidMount() {
    const { auth } = this.context
    this.setState({ userId: auth.user.admin_id })
    this.fecthMktEvent()
    this.fetchAdvancedCondition()
  }

  fecthMktEvent = async () => {
    const { mktEvent, limit } = this.state
    const botCarousel = await getBotCarousel({ limit, offset: mktEvent.length })
    if (botCarousel.status !== 200) return
    this.handleResponse(botCarousel.data)
  }

  handleResponse = (data) => {
    const { offset: preOffset, mktEvent: premktEvent, limit } = this.state
    const botEventData = []

    data.forEach((item) => {
      const newItem = { ...item }
      newItem.content = parseContent(item.content)
      botEventData.push(newItem)
    })
    console.log(botEventData, 'botEventResult')
    if (data.length < 20) {
      this.setState({
        showLoadMore: false,
        mktEvent: premktEvent.concat(botEventData),
      })
    } else {
      this.setState({
        offset: preOffset + limit,
        mktEvent: premktEvent.concat(botEventData),
      })
    }
  }

  fetchAdvancedCondition = async () => {
    const data = await getAdvancedCondition()
    if (data.status !== 200) return
    const response = data.data
    const conditions = response.map((condition) => {
      return { key: condition.name, value: condition.desc }
    })
    conditions.push({ key: 0, value: '無' })
    this.setState({ conditions })
  }

  // eslint-disable-next-line consistent-return
  deleteAdvancedCondition = async (id, port) => {
    const response = await deleteTable('marketing_events_advance_condition', id)
    if (response.status !== 200) return this.setState({ warning: '更新活動失敗，請稍候再嘗試' })
    if (port === 'update')
      return this.setState({ warning: '更新活動成功 (*´ω`)人(´ω`*)', confirmAlert: true, limit: 20, offset: 0, mktEvent: [] }, () => {
        this.fecthMktEvent()
      })
  }

  handleDatePicker = (date, name) => {
    this.setState({ [name]: +moment(+date).format('X') })
  }

  handleToggleAddModel = () => {
    const { addModal } = this.state
    this.setState({ addModal: !addModal }, () => {
      const { addModal } = this.state
      if (addModal) return
      this.setState({
        currentUpdate: null,
        startDate: '',
        endDate: '',
        endDateError: '',
        startDateError: '',
        currentTitle: '',
        currentTitleError: '',
        currentTriggerAt: '',
        currentTriggerAtError: '',
        currentPriority: 0,
        currentPriorityError: '',
        currentCardTitle: '',
        currentCardTitleError: '',
        currentTextArea: '',
        currentTextAreaError: '',
        currentBackgroundColor: '#ffffff',
        currentBackgroundColorError: '',
        currentBtnText: '',
        currentBtnTextError: '',
        currentBtnColor: '#ffffff',
        currentBtnColorError: '',
        currentImgUrl: '',
        currentImgUrlError: '',
        currentCondition: '',
        currentConditionError: '',
        warning: '',
        currentLink: '',
        currentLinkError: '',
        currentCoupon: '',
        currentCouponError: '',
        currentFunctionId: '',
        currentAdType: '',
      })
    })
  }

  handleTriggerSelect = (index) => {
    this.setState({ currentTriggerAt: triggerAtData[index].value })
  }

  handleConditions = (index) => {
    const { conditions } = this.state
    this.setState({ currentCondition: conditions[index] })
  }

  handleAdTypeSelect = (index) => {
    this.setState({ currentAdType: adEventType[index].value })
  }

  handleOnChange = (e, setStateKey) => {
    const value = e.target ? e.target.value : e
    this.setState({
      [setStateKey]: value,
      [`${setStateKey}Error`]: false,
    })
  }

  handlePost = async () => {
    const {
      table,
      startDate,
      endDate,
      currentTitle,
      currentTriggerAt,
      currentPriority,
      currentCardTitle,
      currentTextArea,
      currentBackgroundColor,
      currentBtnText,
      currentBtnColor,
      currentImgUrl,
      currentLink,
      currentUpdate,
      currentCondition,
      currentAdType,
      currentCoupon,
      currentFunctionId,
      userId,
    } = this.state

    const object = {
      startDate,
      endDate,
      currentTitle,
      currentTriggerAt,
      currentPriority,
      currentCardTitle,
      currentTextArea,
      currentBackgroundColor,
      currentBtnText,
      currentBtnColor,
      currentImgUrl,
      currentLink,
      currentCoupon,
    }

    forEach(object, (value, key) => {
      if (value) return
      this.setState({ [`${key}Error`]: '請填寫或選擇項目' })
    })

    let currentFinalLink = ''
    const liff_ID = process.env.LIFF_ID || '1536637821-vbMPko6Z'

    currentAdType === 'PAGE_LINK'
      ? (currentFinalLink = currentLink.trim())
      : (currentFinalLink = `https://liff.line.me/${liff_ID}/line/partner?coupon_code=${currentCoupon.trim()}&redirect_uri=${currentLink.trim()}`)

    const body = {
      start_time: startDate,
      end_time: endDate,
      title: currentTitle,
      content: `${createContent(
        currentCardTitle,
        currentTextArea,
        currentImgUrl,
        currentBackgroundColor,
        currentBtnText,
        currentBtnColor,
        currentFinalLink,
      )}`,
      trigger_at: currentTriggerAt,
      admin_id: userId,
      priority: currentPriority,
      id: currentUpdate ? currentUpdate.id : undefined,
    }
    if (!currentTitle || !currentTriggerAt || !currentCardTitle || !currentTextArea || !currentBtnText || !currentImgUrl || !currentLink)
      return this.setState({ warning: '欄位不得空白' })
    // 要更新的話，currentFunctionId 等於要更新 condition table 裡的 id (丟到 api 非 event id)
    if (currentUpdate) return this.handlePutMktEvent(body, currentFunctionId)

    const postMkt = await postTable(table, body)
    console.log('post data', postMkt)
    if (postMkt.status !== 200) return this.setState({ warning: '新增活動失敗，請稍候再嘗試 (|||ﾟдﾟ)' })
    if (!currentCondition || !currentCondition.key)
      return this.setState({ warning: '新增活動成功 (*´ω`)人(´ω`*)', confirmAlert: true, limit: 20, offset: 0, mktEvent: [] }, () => {
        this.fecthMktEvent()
      })
    return this.handlePostCondition(postMkt.data.insertId)
  }

  handlePutMktEvent = async (body, function_id) => {
    const { table, currentCondition, currentUpdate } = this.state
    const { id } = body
    const putMkt = await putTable(table, id, '/batch', body)
    if (putMkt.status !== 200) return this.setState({ warning: '更新活動失敗，請稍候再嘗試' })
    if (!currentCondition.key && currentUpdate.function_id) return this.deleteAdvancedCondition(currentUpdate.function_id, 'update')
    if (!currentCondition || !currentCondition.key)
      return this.setState({ warning: '更新活動成功 (*´ω`)人(´ω`*)', confirmAlert: true, limit: 20, offset: 0, mktEvent: [] }, () => {
        this.fecthMktEvent()
      })
    return this.handlePostCondition(id, function_id)
  }

  handlePostCondition = async (eventId, insertId) => {
    const { currentCondition, currentUpdate } = this.state
    const event_id = eventId
    const condition_id = insertId

    const conditionBody = {
      function: currentCondition.key,
      event_id,
      description: currentCondition.value,
    }

    let setCondition
    if (currentUpdate) {
      if (condition_id === null) {
        setCondition = await postTable('marketing_events_advance_condition', conditionBody)
      } else {
        setCondition = await putTable('marketing_events_advance_condition', condition_id, '/batch', conditionBody)
      }
    } else {
      setCondition = await postTable('marketing_events_advance_condition', conditionBody)
    }

    if (setCondition.status !== 200) {
      this.setState({
        warning: `${currentUpdate ? '更新條件失敗，請稍候再嘗試' : '新增條件失敗，請稍候再編輯活動 (|||ﾟдﾟ)'}`,
        confirmAlert: true,
        limit: 20,
        offset: 0,
        mktEvent: [],
      })
    } else {
      this.setState({
        warning: `${currentUpdate ? '更新活動成功！' : '新增活動成功 (*´ω`)人(´ω`*)'}`,
        confirmAlert: true,
        limit: 20,
        offset: 0,
        mktEvent: [],
      })
    }
    this.fecthMktEvent()
  }

  handleUpdate = (data, action) => {
    const { conditions } = this.state
    const { title, trigger_at, start_time, end_time, priority, content, function_id } = data

    this.checkAdType(content)
    const linkDetail = content.footer.contents[0].action.uri ? content.footer.contents[0].action.uri : content.footer.contents[0].action.data

    const condition = conditions.filter((condition) => condition.key === data.function)

    const isCouponEvent = linkDetail.includes('coupon_code')
    const couponCode = isCouponEvent ? linkDetail.split('?')[1].split('&')[0].slice(12) : ''
    const link = isCouponEvent ? linkDetail.split('?')[1].split('&')[1].slice(13) : linkDetail

    this.setState({
      currentUpdate: action !== 'copy' ? data : false,
      startDate: start_time,
      endDate: end_time,
      currentTitle: title,
      currentTriggerAt: trigger_at,
      currentPriority: priority,
      currentCardTitle: content.content.contents[0].text,
      currentTextArea: content.content.contents[1].text,
      currentImgUrl: content.hero.url,
      currentBackgroundColor: content.content.backgroundColor ? content.content.backgroundColor : '#ffffff',
      currentBtnText: content.footer.contents[0].action.label,
      currentBtnColor: content.footer.contents[0].color ? content.footer.contents[0].color : '#ffffff',
      currentLink: link,
      currentCoupon: couponCode,
      currentCondition: condition[0] || { key: 0, value: '無' },
      currentFunctionId: function_id,
    })
  }

  checkAdType = (content) => {
    const checkContent = content.footer.contents[0].action.uri ? content.footer.contents[0].action.uri : content.footer.contents[0].action.data
    checkContent.includes('coupon_code')
      ? this.setState({ currentAdType: 'COUPON_REDIRECT' })
      : this.setState({ currentAdType: 'PAGE_LINK' }, () => {})
  }

  handleConfirm = () => {
    const { confirmAlert } = this.state
    if (confirmAlert) return this.handleToggleAddModel()
    return this.setState({ warning: '' })
  }

  handleupdatedata = (child_limit, child_offset, child_mktEvent) => {
    this.setState({ limit: child_limit, offset: child_offset, mktEvent: child_mktEvent })
  }

  handleOnUpload = async (file) => {
    this.setState({ isLoading: true })
    const formData = new FormData()
    formData.append('file', file.blobFile)
    formData.append('doc_type', 'image')
    const { userId } = this.state
    const data = await postImage(userId, formData)
    if (data.status !== 200) return
    this.setState({ isLoading: false })
    this.handleOnChange(data.data.image_url, 'currentImgUrl')
  }

  render() {
    const {
      table,
      addModal,
      mktEvent,
      startDate,
      endDate,
      startDateError,
      endDateError,
      currentUpdate,
      currentTitle,
      currentTitleError,
      currentTriggerAt,
      currentTriggerAtError,
      currentPriority,
      currentPriorityError,
      currentCardTitle,
      currentCardTitleError,
      currentTextArea,
      currentTextAreaError,
      currentBackgroundColor,
      currentBackgroundColorError,
      currentBtnText,
      currentBtnTextError,
      currentBtnColor,
      currentBtnColorError,
      currentImgUrl,
      currentImgUrlError,
      currentLink,
      currentLinkError,
      currentCondition,
      currentConditionError,
      currentAdType,
      currentAdTypeError,
      currentCoupon,
      currentCouponError,
      showLoadMore,
      warning,
      conditions,
      isLoading,
    } = this.state
    const { handlePopWarning } = this.props
    return (
      <div className="bot-notification-wrap">
        {warning && <PopWarning warning={warning} textCenter confirm={this.handleConfirm} />}
        {addModal ? (
          <AddListModel>
            <Breadcrumb>
              <Breadcrumb.Item onClick={this.handleToggleAddModel}>Bot Notification</Breadcrumb.Item>
              <Breadcrumb.Item active>Add Bot Notification</Breadcrumb.Item>
            </Breadcrumb>
            <AddListGrop title="新增活動">
              <FormGropWrap>
                <FormGrop title="開始時間" type="children" errorMessage={startDateError}>
                  <DatePicker
                    defaultValue={startDate ? new Date(startDate * 1000) : undefined}
                    onOk={(date) => this.handleDatePicker(date, 'startDate')}
                    placement="bottomStart"
                    appearance="subtle"
                    placeholder="From..."
                    isoWeek
                    hideMinutes={(second) => second % 30 !== 0}
                    format="YYYY-MM-DD HH:mm"
                    ranges={[{ value: new Date(), closeOverlay: true }]}
                  />
                </FormGrop>
                <FormGrop title="結束時間" type="children" errorMessage={endDateError}>
                  <DatePicker
                    defaultValue={endDate ? new Date(endDate * 1000) : undefined}
                    onOk={(date) => this.handleDatePicker(date, 'endDate')}
                    placement="bottomStart"
                    appearance="subtle"
                    placeholder="To..."
                    isoWeek
                    hideMinutes={(second) => second % 30 !== 0}
                    format="YYYY-MM-DD HH:mm"
                    ranges={[{ value: new Date(), closeOverlay: true }]}
                  />
                </FormGrop>
              </FormGropWrap>
              <FormGrop
                title="主旨"
                placeholder="Title"
                type="input"
                handleOnChange={this.handleOnChange}
                value={currentTitle}
                setStateKey="currentTitle"
                errorMessage={currentTitleError}
              />
              <FormGrop
                title="觸發狀態"
                placeholder="Trigger At"
                type="select-button"
                options={triggerAtData}
                handleOnChange={this.handleTriggerSelect}
                value={currentTriggerAt}
                errorMessage={currentTriggerAtError}
              />
              <FormGrop
                title="推播條件（選填）"
                placeholder="Conditions"
                type="select-button"
                options={conditions}
                handleOnChange={this.handleConditions}
                value={currentCondition.value}
                errorMessage={currentConditionError}
              />
              <FormGrop
                title="優先順序"
                placeholder="Priority"
                type="number"
                min={0}
                max={25}
                handleOnChange={this.handleOnChange}
                value={currentPriority}
                setStateKey="currentPriority"
                errorMessage={currentPriorityError}
              />
            </AddListGrop>
            <AddListGrop title="卡片設定">
              <FormGrop
                title="卡片標題"
                placeholder="Card Title"
                type="input"
                handleOnChange={this.handleOnChange}
                value={currentCardTitle}
                setStateKey="currentCardTitle"
                errorMessage={currentCardTitleError}
              />
              <FormGrop
                title="卡片內容"
                placeholder="Content"
                type="textarea"
                value={currentTextArea}
                handleOnChange={this.handleOnChange}
                setStateKey="currentTextArea"
                errorMessage={currentTextAreaError}
              />
              <FormGrop
                title="圖片網址"
                placeholder="Image URL"
                type="input"
                value={currentImgUrl}
                handleOnChange={this.handleOnChange}
                setStateKey="currentImgUrl"
                errorMessage={currentImgUrlError}
              />
              <FormGrop type="uploader" multiple={false} handleOnUpload={this.handleOnUpload} isLoading={isLoading} />
              <FormGrop title="卡片背景顏色" type="children" errorMessage={currentBackgroundColorError}>
                <div>
                  <input
                    type="text"
                    name="currentBackgroundColor"
                    placeholder="Background Color"
                    value={currentBackgroundColor}
                    onChange={(e) => this.handleOnChange(e, 'currentBackgroundColor')}
                  />
                </div>
                <div className="bot-card-backgroundcolor">
                  <span>背景顏色(預設白色)</span>
                  <div className="color-square" style={{ backgroundColor: currentBackgroundColor ? `${currentBackgroundColor}` : null }} />
                </div>
              </FormGrop>
              <FormGrop
                title="按鈕文字"
                placeholder="Button Text"
                type="input"
                handleOnChange={this.handleOnChange}
                value={currentBtnText}
                setStateKey="currentBtnText"
                errorMessage={currentBtnTextError}
              />
              <FormGrop title="按鈕顏色" type="children" errorMessage={currentBtnColorError}>
                <div>
                  <input
                    type="text"
                    name="currentBtnColor"
                    placeholder="Background Color"
                    value={currentBtnColor}
                    onChange={(e) => this.handleOnChange(e, 'currentBtnColor')}
                  />
                </div>
                <div className="bot-btn-backgroundcolor">
                  <span>按鈕顏色(預設白色)</span>
                  <div className="color-square" style={{ backgroundColor: currentBtnColor ? `${currentBtnColor}` : null }} />
                </div>
              </FormGrop>
              <FormGrop
                title="活動類型"
                placeholder="Ad Type"
                type="select-button"
                options={adEventType}
                handleOnChange={this.handleAdTypeSelect}
                value={currentAdType}
                errorMessage={currentAdTypeError}
              />
              {currentAdType === 'PAGE_LINK' && (
                <FormGrop
                  title="連結網址"
                  placeholder="Link"
                  type="input"
                  value={currentLink}
                  handleOnChange={this.handleOnChange}
                  setStateKey="currentLink"
                  errorMessage={currentLinkError}
                />
              )}
              {currentAdType === 'COUPON_REDIRECT' && (
                <FormGropWrap>
                  <FormGrop
                    title="Coupon Code"
                    placeholder="Coupon Code"
                    type="input"
                    value={currentCoupon}
                    handleOnChange={this.handleOnChange}
                    setStateKey="currentCoupon"
                    errorMessage={currentCouponError}
                  />
                  <FormGrop
                    title="連結網址"
                    placeholder="Link"
                    type="input"
                    value={currentLink}
                    handleOnChange={this.handleOnChange}
                    setStateKey="currentLink"
                    errorMessage={currentLinkError}
                  />
                </FormGropWrap>
              )}
            </AddListGrop>
            <div className="campaign-button-wrap">
              <button type="button" className="campaign-confirm-button" onClick={this.handlePost}>
                {currentUpdate ? '更新' : '新增'}
              </button>
              <button type="button" className="campaign-cancel-button" onClick={this.handleToggleAddModel}>
                取消
              </button>
            </div>
          </AddListModel>
        ) : (
          <>
            <ListWrap addListText="新增 Bot 推播卡片" onClick={this.handleToggleAddModel} />
            <TableController data={mktEvent} topHeight={104}>
              <Column minWidth={50} flexGrow={0.5} align="center" fixed>
                <HeaderCell>ID</HeaderCell>
                <Cell dataKey="id" />
              </Column>
              <Column minWidth={150} flexGrow={1} align="center">
                <HeaderCell>Start</HeaderCell>
                <Cell>{(rowData) => moment(rowData.start_time * 1000).format('YYYY-MM-DD HH:mm')}</Cell>
              </Column>
              <Column minWidth={150} flexGrow={1} align="center">
                <HeaderCell>End</HeaderCell>
                <Cell>{(rowData) => moment(rowData.end_time * 1000).format('YYYY-MM-DD HH:mm')}</Cell>
              </Column>
              <Column minWidth={100} flexGrow={1}>
                <HeaderCell>Title</HeaderCell>
                <TitleCell dataKey="title" />
              </Column>
              <Column width={150} flexGrow={2} align="center">
                <HeaderCell>Content</HeaderCell>
                <BotContentCell dataKey="content" />
              </Column>
              <Column width={120} flexGrow={1} align="center">
                <HeaderCell>Image</HeaderCell>
                <BotImageCell dataKey="content" />
              </Column>
              <Column minWidth={50} flexGrow={1.5} align="center">
                <HeaderCell>Trigger At</HeaderCell>
                <Cell dataKey="trigger_at" />
              </Column>
              <Column minWidth={50} flexGrow={1.5} align="center">
                <HeaderCell>Conditions</HeaderCell>
                <Cell>
                  {(rowData) => {
                    const condition = conditions.filter((condition) => condition.key === rowData.function)
                    return (condition[0] && condition[0].value) || rowData.function
                  }}
                </Cell>
              </Column>
              <Column minWidth={50} flexGrow={0.5} align="center">
                <HeaderCell>Priority</HeaderCell>
                <Cell dataKey="priority" />
              </Column>
              <Column minWidth={180} flexGrow={1} align="center">
                <HeaderCell />
                <BroadcastActionCell
                  dataKey="id"
                  table={table}
                  handleUpdate={this.handleUpdate}
                  fetchData={this.fecthMktEvent}
                  toogleModle={this.handleToggleAddModel}
                  handleupdatedata={this.handleupdatedata}
                  handlePopWarning={handlePopWarning}
                />
              </Column>
            </TableController>
            {showLoadMore && (
              <div role="presentation" className="load-more-text subtitle_01" onClick={this.fecthMktEvent}>
                Load more
              </div>
            )}
          </>
        )}
      </div>
    )
  }
}

BotNotification.contextType = ContextStore
export default BotNotification
