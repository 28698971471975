import React, { useState, useEffect } from 'react'
import ModalColumn from './ModalColumn'
import { handleTransactions, handlePaymentMethod } from '../../pages/trip_transaction/helper'

const Preview = React.memo(({ calculation, note, companyInfo }) => {
  const [refund, setRefund] = useState(0)

  useEffect(() => {
    setRefund(handleTransactions(calculation.after.transactions).refund)
  }, [calculation])

  return (
    <>
      {companyInfo && (
        <>
          <ModalColumn show={!!companyInfo.company} title="公司" value={companyInfo.company} style={{ maxWidth: 200 }} />
          <ModalColumn show={!!companyInfo.group} title="公司群組" value={companyInfo.group} style={{ maxWidth: 200 }} />
        </>
      )}
      <ModalColumn
        title="付款方式"
        previous={handlePaymentMethod(calculation.before.payment_method)}
        newValue={handlePaymentMethod(calculation.after.payment_method)}
      />
      <ModalColumn
        show={calculation.before.trip_status !== calculation.after.trip_status}
        title="行程狀態"
        value={calculation.after.trip_status}
        style={{ fontSize: '0.8em', letterSpacing: -0.8 }}
      />
      <ModalColumn title="應付車資" previous={calculation.before.trip_fare} newValue={calculation.after.trip_fare} />
      <ModalColumn
        show={calculation.before.coupon || calculation.after.coupon}
        title="乘車券"
        previous={calculation.before.coupon}
        newValue={`${calculation.after.coupon}`}
      />
      <ModalColumn title="企業簽單金額" previous={calculation.before.total_company} newValue={calculation.after.total_company} />
      <ModalColumn
        show={calculation.before.total_collectable !== calculation.after.total_collectable}
        title="平台代墊金額"
        previous={calculation.before.total_collectable}
        newValue={`${calculation.after.total_collectable}`}
      />
      <ModalColumn show={!!refund} title="應退刷金額" value={`退 -$${refund}`} style={{ color: 'red' }} />
      <ModalColumn show={!!note} title="變更原因" value={note} />
    </>
  )
})

export default Preview
