import React from 'react'
import FormGrop from '../views/FormGrop'
import FormGropWrap from '../views/FormGropWrap'

const AddCompany = (props) => {
  const {
    account,
    name,
    tax_num,
    nick_name,
    description,
    postal_code,
    address,
    email,
    quota,
    deposit,
    telephone_num,
    mobile_num,
    user_name,
    user_email,
    group_name,
    handleOnChange,
    addCompany,
    toggleAddCompany,
  } = props
  return (
    <div className="add-company-wrap">
      <FormGropWrap>
        <FormGrop
          title="帳號"
          placeholder="帳號"
          type="input"
          handleOnChange={handleOnChange}
          value={account}
          setStateKey="account"
        />
        <FormGrop
          title="統一編號"
          placeholder="統一編號"
          type="input"
          handleOnChange={handleOnChange}
          value={tax_num}
          setStateKey="tax_num"
        />
      </FormGropWrap>
      <FormGropWrap>
        <FormGrop
          title="全名"
          placeholder="全名"
          type="input"
          handleOnChange={handleOnChange}
          value={name}
          setStateKey="name"
        />
        <FormGrop
          title="簡稱"
          placeholder="簡稱"
          type="input"
          handleOnChange={handleOnChange}
          value={nick_name}
          setStateKey="nick_name"
        />
      </FormGropWrap>
      <FormGrop
        title="說明"
        placeholder="說明"
        type="input"
        handleOnChange={handleOnChange}
        value={description}
        setStateKey="description"
      />
      <FormGropWrap>
        <FormGrop
          title="郵遞區號"
          placeholder="郵遞區號"
          type="input"
          handleOnChange={handleOnChange}
          value={postal_code}
          setStateKey="postal_code"
        />
        <FormGrop
          title="地址"
          placeholder="地址"
          type="input"
          handleOnChange={handleOnChange}
          value={address}
          setStateKey="address"
        />
      </FormGropWrap>
      <FormGrop
        title="公司郵件"
        placeholder="公司郵件"
        type="input"
        handleOnChange={handleOnChange}
        value={email}
        setStateKey="email"
      />
      <FormGropWrap>
        <FormGrop
          title="總預算"
          placeholder="總預算"
          type="input"
          handleOnChange={handleOnChange}
          value={quota}
          setStateKey="quota"
        />
        <FormGrop
          title="保證金"
          placeholder="保證金"
          type="input"
          handleOnChange={handleOnChange}
          value={deposit}
          setStateKey="deposit"
        />
      </FormGropWrap>
      <FormGropWrap>
        <FormGrop
          title="室內電話"
          placeholder="室內電話"
          type="input"
          handleOnChange={handleOnChange}
          value={telephone_num}
          setStateKey="telephone_num"
        />
        <FormGrop
          title="手機"
          placeholder="手機"
          type="input"
          handleOnChange={handleOnChange}
          value={mobile_num}
          setStateKey="mobile_num"
        />
      </FormGropWrap>
      <FormGrop
        title="負責人姓名"
        placeholder="負責人姓名"
        type="input"
        handleOnChange={handleOnChange}
        value={user_name}
        setStateKey="user_name"
      />
      <FormGrop
        title="負責人電郵"
        placeholder="負責人電郵"
        type="input"
        handleOnChange={handleOnChange}
        value={user_email}
        setStateKey="user_email"
      />
      <FormGrop
        title="負責人部門(可以不戴但不能叫車)"
        placeholder="負責人部門"
        type="input"
        handleOnChange={handleOnChange}
        value={group_name}
        setStateKey="group_name"
      />
      <div className="company-add-wrap">
        <button
          type="button"
          className="company-white-button"
          onClick={toggleAddCompany}
        >
          取消新增
        </button>
        <button
          type="button"
          className="company-add-button"
          onClick={addCompany}
        >
          新增公司
        </button>
      </div>
    </div>
  )
}

export default AddCompany
