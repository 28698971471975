import React, { useCallback } from 'react'
import { Icon, IconButton, Input, InputGroup, InputPicker, SelectPicker } from 'rsuite'
import styled from 'styled-components'
import { FormController, FormField, FormLabel, SectionTitle, Stack } from './styles'

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
`

const RandomSumText = styled.div`
  flex: 5;
  font-size: 16px;
  line-height: 1.38;
  color: #575757;
`

const AddCol = styled.div`
  flex: 8;
`

const InlineFormField = styled(FormField)`
  flex: 4;
`

const TinyFormController = styled(FormController)`
  width: 200px;
`

const TinyFormLabel = styled(FormLabel)`
  width: auto;
`

const DelCol = styled.div`
  flex: 1;
`

const PanelCoupon = () => {
  const renderItems = useCallback(() => {
    return (
      <Row>
        <InlineFormField isRequired>
          <TinyFormLabel>乘車券</TinyFormLabel>
          <TinyFormController>
            <SelectPicker />
          </TinyFormController>
        </InlineFormField>
        <InlineFormField isRequired>
          <TinyFormLabel>圖片</TinyFormLabel>
          <TinyFormController>
            <Input placeholder="輸入圖片連結" />
          </TinyFormController>
        </InlineFormField>
        <InlineFormField isRequired>
          <TinyFormLabel>機率</TinyFormLabel>
          <TinyFormController>
            <InputGroup>
              <Input placeholder="輸入機率" />
              <InputGroup.Addon>/1000</InputGroup.Addon>
            </InputGroup>
          </TinyFormController>
        </InlineFormField>
        <DelCol>
          <IconButton icon={<Icon icon="trash" />} appearance="ghost">
            移除
          </IconButton>
        </DelCol>
      </Row>
    )
  }, [])

  return (
    <Stack>
      <SectionTitle>主態領取的乘車券</SectionTitle>
      <FormField isRequired>
        <FormLabel>乘車券</FormLabel>
        <FormController>
          <InputPicker placeholder="請選擇" />
        </FormController>
      </FormField>
      <SectionTitle>客態領取的乘車券</SectionTitle>
      {renderItems()}
      <Row>
        <AddCol>
          <IconButton icon={<Icon icon="plus" />} appearance="ghost">
            新增獎項
          </IconButton>
        </AddCol>
        <RandomSumText>機率總計：0/1000</RandomSumText>
      </Row>
    </Stack>
  )
}

export default PanelCoupon
