import styled from 'styled-components'

export const Heading = styled.h4`
  color: #7a92ad;
  padding-left: 4px;
`

export const Wrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 30px;
  min-height: 100%;
`

export const PanelItem = styled.div`
  padding: 6px;
  display: flex;
  justify-content: center;
  row-gap: 6px;
`
