import React, { useState } from 'react'
import { find, isEmpty } from 'lodash'
import { Modal, Button, Input, Alert } from 'rsuite'
import { reviewTripAdjustment } from '../../api'

const ReviewModal = ({ tripDate, show, handleClose, fetchTripData, fetchList, loading, setLoading }) => {
  const [reviewReason, setReviewReason] = useState('')

  const handleReview = async (type) => {
    if (isEmpty(reviewReason)) return Alert.warning('請確實填寫審核原因')
    setLoading(true)
    // call api
    const { id, trip_id } = find(tripDate.adjustment, { status: 'PENDING' }) || {}
    const body = {
      review_note: reviewReason,
      type,
    }
    const response = await reviewTripAdjustment(id, body)
    setLoading(false)
    if (response.status !== 200) return Alert.error(response.data.msg)
    fetchTripData(trip_id)
    fetchList()
    return handleClose()
  }

  return (
    <Modal backdrop="static" size="xs" show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title style={{ textAlign: 'center' }}>填寫申請通過/不通過原因</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Input
          style={{ marginTop: '20px' }}
          placeholder="請在此輸入審核原因"
          type="text"
          value={reviewReason}
          onChange={(value) => setReviewReason(value)}
        />
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
        <Button appearance="subtle" onClick={() => handleReview('reject')} disabled={loading}>
          不通過
        </Button>
        <Button appearance="primary" onClick={() => handleReview('approve')} disabled={loading}>
          通過
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(ReviewModal)
