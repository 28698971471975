import styled from 'styled-components'

export const Heading = styled.h4`
  color: #7a92ad;
  padding-left: 4px;
`

export const Wrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 30px;
  min-height: 100%;
`

export const Title = styled.h5``

const Label = styled.div`
  margin-top: 6px;
  min-width: 56px;
`

const Control = styled.div`
  flex-grow: 1;
`

export const FormGroup = styled.div`
  padding: 0 12px;
  margin-top: 20px;
  display: flex;
  column-gap: 20px;
`

FormGroup.Label = Label
FormGroup.Control = Control
