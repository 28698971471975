import { find, isEmpty } from 'lodash'

const handleTransactions = (transactions) => {
  const refund = transactions
    .filter((v) => v.action === 'REFUND' && !['company', 'collectable'].includes(v.payment_method))
    .map((v) => v.amount)
    .reduce((accu, curr) => (accu += curr), 0)
  const collectableObj = find(transactions, { action: 'ADD', payment_method: 'collectable' }) || {}
  const offlineTransaction = find(transactions, ({ action, order_id }) => action === 'UPDATE' && !isEmpty(order_id)) || {}

  return {
    refund,
    collectable: collectableObj.amount || 0,
    offline: offlineTransaction || null,
  }
}

export default handleTransactions
