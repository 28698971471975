import React, { useEffect, useState } from 'react'
import { SelectPicker, Alert } from 'rsuite'
import { getRentalCoupons } from '../../api'
import dayjs from 'dayjs'

const CouponPicker = ({ value, setValue }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await getRentalCoupons({ available: true, promo_types: ['OPERATION', 'ONE_TIME_EVENT'], limit: 100 })
        setData(result.data.map((v) => ({ label: `${v.promo_title} | $${v.amount} | ${expiryDate(v.valid_days)}`, value: v.id })))
      } catch (error) {
        Alert.error(error?.message || 'fetch coupon error')
      }
    }
    fetch()
  }, [])

  const expiryDate = (valid_days) => {
    return valid_days < 10000 ? `${valid_days}天` : dayjs(valid_days * 1000).format('MM月DD日 HH:mm')
  }

  return (
    <SelectPicker
      data={data}
      value={value}
      onChange={setValue}
      cleanable={false}
      placeholder="選取優惠券"
      style={{ width: 320, maxWidth: '70%' }}
      menuStyle={{ fontSize: 12 }}
    />
  )
}

export default React.memo(CouponPicker)
