import React from 'react'
import { Popover, Whisper } from 'rsuite'
import { Cell } from 'rsuite-table'

const TitleCell = ({ rowData, dataKey, ...props }) => {
	const { name, description } = rowData
	const speaker = (
		<Popover title={name} visible>
			<p>{description}</p>
		</Popover>
	)

	return (
		<Cell {...props}>
			<Whisper placement="right" speaker={speaker}>
				<p>{rowData[dataKey]}</p>
			</Whisper>
		</Cell>
	)
}

export default TitleCell
