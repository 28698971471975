import React from 'react'
import { Input } from 'rsuite'
import { FormController, FormField, FormLabel, SectionTitle, Stack } from './styles'

const PanelBotCard = () => {
  return (
    <Stack>
      <SectionTitle>分享活動卡片</SectionTitle>
      <FormField isRequired>
        <FormLabel>圖片</FormLabel>
        <FormController>
          <Input placeholder="輸入圖片連結" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>標題</FormLabel>
        <FormController>
          <Input placeholder="輸入文字" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>內文</FormLabel>
        <FormController>
          <Input componentClass="textarea" rows={5} placeholder="輸入文字" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>活動詳情網址</FormLabel>
        <FormController>
          <Input placeholder="輸入網址" />
        </FormController>
      </FormField>
      <SectionTitle>首搭提醒卡片</SectionTitle>
      <FormField isRequired>
        <FormLabel>圖片</FormLabel>
        <FormController>
          <Input placeholder="輸入圖片連結" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>標題</FormLabel>
        <FormController>
          <Input placeholder="輸入文字" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>內文</FormLabel>
        <FormController>
          <Input componentClass="textarea" rows={5} placeholder="輸入文字" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>活動詳情網址</FormLabel>
        <FormController>
          <Input placeholder="輸入網址" />
        </FormController>
      </FormField>
      <SectionTitle>完成首搭卡片</SectionTitle>
      <FormField isRequired>
        <FormLabel>圖片</FormLabel>
        <FormController>
          <Input placeholder="輸入圖片連結" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>標題</FormLabel>
        <FormController>
          <Input placeholder="輸入文字" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>內文</FormLabel>
        <FormController>
          <Input componentClass="textarea" rows={5} placeholder="輸入文字" />
        </FormController>
      </FormField>
      <FormField isRequired>
        <FormLabel>活動詳情網址</FormLabel>
        <FormController>
          <Input placeholder="輸入網址" />
        </FormController>
      </FormField>
    </Stack>
  )
}

export default PanelBotCard
