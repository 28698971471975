import React from 'react'
import { Modal, Button, Divider } from 'rsuite'
import TextRow from './TextRow'
import Subtitle from './Subtitle'
import TransactionTable from './TransactionTable'

const invoiceType = {
  2: '二聯發票',
  3: '三聯發票',
}

const invoiceCategory = {
  3: '手機條碼',
  5: '會員載具',
}

const DetailModal = ({ show, close, info, invoice }) => {
  const billing = info.billing

  return (
    <Modal backdrop={true} show={show} onHide={close} className="order-detail">
      <Modal.Header>
        <Modal.Title style={{ fontWeight: 'normal' }}>交易紀錄與明細</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextRow label="訂單ID" value={info.id} />
        <TextRow label="訂單編號" value={info.hash} />
        <Subtitle>租金總計</Subtitle>
        <TextRow label="時間租金" value={billing?.timeAmount} prefix="$" />
        <TextRow label="優惠方案" show={!!billing?.promotionInfos?.length} value={billing?.promotionDiscount} prefix="-$" />
        <TextRow label="優惠券" show={!!billing?.usedCoupons?.[0]?.discount} value={billing?.usedCoupons?.[0]?.discount} prefix="-$" />
        <Subtitle>額外費用</Subtitle>
        <TextRow label="eTag" value={billing?.etagAmount} prefix="$" />
        <TextRow label="逾時租金" show={!!billing?.overTimeFee} value={billing?.overTimeFee} prefix="$" />
        <TextRow label="里程費用" show={!!billing?.odoMeterAmount} value={billing?.odoMeterAmount} prefix="$" />
        <Subtitle>發票資訊</Subtitle>
        <TextRow label="發票類型" value={invoiceType[invoice?.type] || invoice?.type} />
        <TextRow label={invoiceCategory[invoice?.category] || '載具ID'} value={invoice?.carrierID} />
        <TextRow show={!!invoice?.id} label="統一編號" value={invoice?.id} />
        <TextRow show={!!invoice?.id} label="發票抬頭" value={invoice?.title} />
        <Subtitle>交易紀錄</Subtitle>
        <TransactionTable data={info.transactions} />
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" onClick={close}>
          關閉
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(DetailModal)
