import { isNumber } from 'lodash'

const checkPointValid = ({ lat, lng }) => {
  const x = Number(lat)
  const y = Number(lng)
  if (!isNumber(x) || !isNumber(y)) return false
  if (x < 22 || x > 123) return false
  if (y < 22 || y > 123) return false
  return true
}

export { checkPointValid }