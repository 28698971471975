/* eslint-disable indent */
import React, { Component } from 'react'
import { Carousel } from 'rsuite';

class CarouselCard extends Component {

	render() {
		const { filterData, form, addModel, currentTab } = this.props
		return (
			<div className="simulator-carousel-card">
				<Carousel autoplay >
					{!addModel && filterData && filterData.map((item, index) => {
						if (index < 5) {
							return (<img key={`sim-${index}`} src={item.img} alt="" onClick={() => window.open(item.action.payload)} />)
						}
					})}
				</Carousel>
				{form && currentTab.tab === 'CAROUSEL' && <img key='form-mode' src={form.img} alt="" onClick={() => window.open(form.action.payload)} />}
			</div>
		)
	}
}

export default CarouselCard
