import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Modal, Table, Drawer, Panel } from 'rsuite'
import { Link, useHistory } from 'react-router-dom'
import { cloneDeep, remove } from 'lodash'
import { Foot, HStack, Heading, Root, Stack, Top } from './index.style'
import { SearchInput } from '../../components/table'
import { deleteBatch, getCoupons, deleteCoupon as deleteCouponApi } from '../../api'
import {
  CellActions,
  CellDiscount,
  CellFeatureMap,
  CellForCash,
  CellLifespan,
  CellMinimumCharge,
  CellReleaseTotal,
  CellTitle,
  CellType,
  CellUsingInterval,
} from '../../components/new_coupons/cell'
import useDisclosure from '../../hook/useDisclosure'
import Map from '../../components/map/Map'

const CouponsRoot = () => {
  const history = useHistory()

  const [searchText, setSearchText] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [coupons, setCoupons] = useState([])
  const [canLoadMore, setCanLoadMore] = useState(true)
  const [couponForDelete, setCouponForDelete] = useState(null)
  const [designatedAreasForDisplay, setDesignatedAreasForDisplay] = useState(null)

  const [isDelConfirmOpen, delConfirmModal] = useDisclosure()
  const [isAreaDisplayOpen, areaDisplayDrawer] = useDisclosure()

  const { startLocation, endLocation } = useMemo(() => {
    const startLocation = []
    const endLocation = []
    if (Array.isArray(designatedAreasForDisplay)) {
      designatedAreasForDisplay.forEach((elem) => {
        if (Array.isArray(elem.start_location)) {
          elem.start_location.forEach((row) => {
            startLocation.push(row.map((pos) => ({ lat: pos.x, lng: pos.y })))
          }, [])
        }
        if (Array.isArray(elem.end_location)) {
          elem.end_location.forEach((row) => {
            endLocation.push(row.map((pos) => ({ lat: pos.x, lng: pos.y })))
          }, [])
        }
      })
    }
    return { startLocation, endLocation }
  }, [designatedAreasForDisplay])

  const fetchCoupons = useCallback(async ({ offset = 0, search = '' } = {}) => {
    try {
      setIsLoading(true)
      const params = {
        // stats: 1, // 因 api 回的速度很慢，先不拿領券細節
        limit: 20,
        search,
        offset,
      }
      const resp = await getCoupons(params)
      if (resp.status !== 200) throw new Error(resp.data.msg)
      setCanLoadMore(resp.data.length >= params.limit)
      setCoupons((prev) => {
        return offset ? prev.concat(resp.data) : resp.data
      })
    } catch (error) {
      Alert.error(error.message)
    }
    setIsLoading(false)
  }, [])

  const deleteCouponBatch = useCallback(async (batch) => {
    const resp = await deleteBatch({ data: { batch } })
    if (resp.status !== 200) throw new Error(resp.data.msg)
    setCoupons((prev) => {
      const next = cloneDeep(prev)
      remove(next, (c) => c.batch === batch)
      return next
    })
  }, [])

  const deleteCoupon = useCallback(async (promo_id) => {
    const resp = await deleteCouponApi({ data: { promo_id } })
    if (resp.status !== 200) throw new Error(resp.data.msg)
    setCoupons((prev) => {
      const next = cloneDeep(prev)
      remove(next, (c) => c.promo_id === promo_id)
      return next
    })
  }, [])

  const handleConfirmDeleteCoupon = useCallback(async () => {
    try {
      console.log('couponForDelete: ', couponForDelete)

      if (couponForDelete.batch) {
        await deleteCouponBatch(couponForDelete.batch)
      } else {
        await deleteCoupon(couponForDelete.promo_id)
      }
      Alert.success(`刪除 ${couponForDelete.promo_title} 成功!`)
      delConfirmModal.close()
      setCouponForDelete(null)
    } catch (error) {
      Alert.error(error.message)
    }
  }, [deleteCouponBatch, deleteCoupon, couponForDelete, delConfirmModal])

  const handleDelete = useCallback(
    async (coupon) => {
      setCouponForDelete(coupon)
      delConfirmModal.open()
    },
    [delConfirmModal],
  )

  const handleEdit = useCallback(
    (coupon) => {
      history.push(`/marketing/coupons/${coupon.promo_id}/update`)
    },
    [history],
  )

  const handleDuplicate = useCallback(
    (coupon) => {
      history.push(`/marketing/coupons/${coupon.promo_id}/duplicate`)
    },
    [history],
  )

  const handleSearch = useCallback(() => {
    fetchCoupons({ search: searchText })
  }, [fetchCoupons, searchText])

  const handleSearchKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13) handleSearch()
    },
    [handleSearch],
  )

  const handleLoadMore = useCallback(() => {
    fetchCoupons({ offset: coupons.length, search: searchText })
  }, [fetchCoupons, coupons, searchText])

  const handleRowHeight = useCallback((rowData) => {
    const base = 180
    const padding = 26
    const count = Array.isArray(rowData?.coupon_settings_group) && rowData.coupon_settings_group.length > 0 ? rowData.coupon_settings_group.length : 1
    return count > 1 ? count * base + padding : 300
  }, [])

  const handleClickPickAndDropArea = useCallback(
    (designatedAreas) => () => {
      setDesignatedAreasForDisplay(designatedAreas)
      areaDisplayDrawer.open()
    },
    [areaDisplayDrawer],
  )

  useEffect(() => {
    fetchCoupons()
  }, [fetchCoupons])

  return (
    <>
      <Root>
        <Heading>優惠券</Heading>
        <Top>
          <SearchInput
            title="搜索優惠券名稱或promo_id"
            setSearchWord={setSearchText}
            onClick={handleSearch}
            onKeyDown={handleSearchKeyDown}
            withButton
          />
          <Button appearance="primary" componentClass={Link} to="/marketing/coupons/create">
            新增優惠券
          </Button>
        </Top>
        <Table
          height={window.innerHeight - 240}
          width={window.screen.width - 300}
          rowHeight={handleRowHeight}
          data={coupons}
          loading={isLoading}
          shouldUpdateScroll={false}
          bordered
          cellBordered
        >
          <Table.Column width={100} align="center" fixed>
            <Table.HeaderCell>Promo ID</Table.HeaderCell>
            <Table.Cell dataKey="promo_id" />
          </Table.Column>
          <Table.Column width={150} align="center">
            <Table.HeaderCell>優惠券種類</Table.HeaderCell>
            <CellType dataKey="promo_type" />
          </Table.Column>
          <Table.Column width={200} align="center">
            <Table.HeaderCell>名稱</Table.HeaderCell>
            <CellTitle dataKey="promo_title" />
          </Table.Column>
          <Table.Column width={240} align="center">
            <Table.HeaderCell>發放及使用時間設定</Table.HeaderCell>
            <CellLifespan />
          </Table.Column>
          <Table.Column width={240} align="center">
            <Table.HeaderCell>限制使用時段</Table.HeaderCell>
            <CellUsingInterval />
          </Table.Column>
          <Table.Column width={360} align="center">
            <Table.HeaderCell>Feature Map</Table.HeaderCell>
            <CellFeatureMap onClickPickAndDropArea={handleClickPickAndDropArea} />
          </Table.Column>
          <Table.Column width={100} align="center">
            <Table.HeaderCell>每次領取</Table.HeaderCell>
            <Table.Cell dataKey="count" />
          </Table.Column>
          <Table.Column width={100} align="center">
            <Table.HeaderCell>總發張數</Table.HeaderCell>
            <CellReleaseTotal />
          </Table.Column>
          <Table.Column width={150} align="center">
            <Table.HeaderCell>乘車優惠</Table.HeaderCell>
            <CellDiscount />
          </Table.Column>
          <Table.Column width={100} align="center">
            <Table.HeaderCell>可用現金</Table.HeaderCell>
            <CellForCash />
          </Table.Column>
          <Table.Column width={100} align="center">
            <Table.HeaderCell>低消門檻</Table.HeaderCell>
            <CellMinimumCharge />
          </Table.Column>
          <Table.Column width={200} align="center" fixed="right">
            <Table.HeaderCell>操作</Table.HeaderCell>
            <CellActions onEdit={handleEdit} onDuplicate={handleDuplicate} onDelete={handleDelete} />
          </Table.Column>
        </Table>
        <Foot>
          {canLoadMore && (
            <Button appearance="ghost" size="sm" onClick={handleLoadMore}>
              載入更多
            </Button>
          )}
        </Foot>
      </Root>
      <Modal size="xs" show={isDelConfirmOpen} onHide={delConfirmModal.close}>
        {couponForDelete && (
          <>
            <Modal.Body>{`確定要刪除優惠券 ${couponForDelete.promo_title}？`}</Modal.Body>
            <Modal.Footer>
              <Button onClick={handleConfirmDeleteCoupon} appearance="primary" color="red">
                確認刪除
              </Button>
              <Button onClick={delConfirmModal.close} appearance="subtle">
                取消
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
      <Drawer size="lg" show={isAreaDisplayOpen} onHide={areaDisplayDrawer.close}>
        <Drawer.Header>
          <Drawer.Title>上下車地點</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <Stack>
            <Panel header="上車區域" bordered>
              {startLocation.length ? (
                <HStack>
                  <div style={{ flex: 1, minWidth: '400px' }}>
                    <Map id="drawer_pick_up_area" latlngs={startLocation} width="100%" padding="30%" multi />
                  </div>
                  <Panel header="經緯度座標" bordered>
                    {JSON.stringify(startLocation)}
                  </Panel>
                </HStack>
              ) : (
                '無'
              )}
            </Panel>
            <Panel header="下車區域" bordered>
              {endLocation.length ? (
                <HStack>
                  <div style={{ flex: 1, minWidth: '400px' }}>
                    <Map id="drawer_drop_off_area" latlngs={endLocation} width="70%" padding="30%" multi />
                  </div>
                  <Panel header="經緯度座標" bordered>
                    {JSON.stringify(endLocation)}
                  </Panel>
                </HStack>
              ) : (
                '無'
              )}
            </Panel>
          </Stack>
        </Drawer.Body>
      </Drawer>
    </>
  )
}

export default CouponsRoot
