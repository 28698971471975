import React, {useState} from 'react'
import {Alert, Loader} from 'rsuite'
import {PanelWrap} from './Wrap'
import ReleaseReservationModal from './ReleaseReservationModal'
import {TableController} from '../../components/table'
import {Column, HeaderCell, Cell} from 'rsuite-table'
import {ButtonCell} from "../table/cell";
import {getReservationTrips} from "../../api";
import dayjs from 'dayjs'

const ReservationPreview = ({driverId}) => {
    const [trips, setTrips] = useState([])

    const [loading, setLoading] = useState(false)
    const [showReview, setReview] = useState(false)
    const [tripData, setTripData] = useState({trip: {}})


    const fetchReservationTrips = async () => {
        const response = await getReservationTrips(driverId)
        if (response.status === 200) {
            setTrips(response.data)
        } else {
            Alert.error(response.data.msg)
        }
    }

    const handleReleaseTrip = async (trip) => {
        if (!trip.trip_id) return
        setTripData(trip)
        setReview(true)
    }

    return (
        <>
            {loading && <Loader content="loading" size="md"/>}
            <ReleaseReservationModal
                tripData={tripData}
                show={showReview}
                handleClose={() => setReview(false)}
                fetchReservationTrips={fetchReservationTrips}
                loading={loading}
                setLoading={setLoading}
            />
            <PanelWrap header="預約行程" onSelect={fetchReservationTrips} shaded collapsible>
                <div>
                    <TableController data={trips} topHeight={104} rowHeight={120}>
                        <Column flexGrow={2} align="center">
                            <HeaderCell>行程</HeaderCell>
                            <Cell dataKey="trip_id">
                                {(trip) => {
                                    const trip_url = `https://backend.taxigo.com.tw/trip/${trip.trip_id}`
                                    const hash_url = `https://trip.taxigo.com.tw/${trip.trip_hash}`
                                    return (<div>
                                        <a href={trip_url}>TRIP <br/>{trip.trip_id}</a>
                                        <br/>
                                        <a href={hash_url}>HASH <br/>{trip.trip_hash}</a>
                                    </div>)
                                }}
                            </Cell>
                        </Column>
                        <Column flexGrow={0.5} align="center">
                            <HeaderCell>乘客</HeaderCell>
                            <Cell>
                                {(trip) => {
                                    const user_url = `https://backend.taxigo.com.tw/users/${trip.user_id}`
                                    return (<div>
                                        <a href={user_url}>{trip.user_id}</a>
                                    </div>)
                                }}
                            </Cell>
                        </Column>
                        <Column flexGrow={2} align="center">
                            <HeaderCell>內容</HeaderCell>
                            <Cell>
                                {(trip) => {
                                    return (<div>
                                        付款方式:{trip.payment_method}
                                        <br/>
                                        紀錄:
                                        <br/>{trip.notes}
                                        <br/>
                                        FEATURE MAP:
                                        <br/>
                                        {trip.feature_map}
                                    </div>)
                                }}
                            </Cell>
                        </Column>
                        <Column flexGrow={2} wordWrap>
                            <HeaderCell>行程狀態</HeaderCell>
                            <Cell dataKey="trip_status"/>
                        </Column>
                        <Column flexGrow={2} align="center">
                            <HeaderCell>地址</HeaderCell>
                            <Cell>
                                {(trip) => {
                                    return (<div>
                                        上車地址：<br/>{trip.start_address}<br/>下車地址：<br/>{trip.end_address}
                                    </div>)
                                }}
                            </Cell>
                        </Column>
                        <Column flexGrow={2} align="center">
                            <HeaderCell>時間</HeaderCell>
                            <Cell>
                                {(trip) => {
                                    return (<div>
                                        叫車時間 <br/> {dayjs(trip.request_time * 1000).format('YYYY-MM-DD HH:mm')}<br/>用車時間<br/>{dayjs(trip.reserve_time * 1000).format('YYYY-MM-DD HH:mm')}
                                    </div>)
                                }}
                            </Cell>
                        </Column>
                        <Column flexGrow={1} align="center">
                            <HeaderCell>功能</HeaderCell>
                            <ButtonCell dataKey="button" title="釋放行程" color="yellow"
                                        handleClick={handleReleaseTrip}/>
                        </Column>
                    </TableController>
                </div>
            </PanelWrap>
        </>
    )
}

export default ReservationPreview
