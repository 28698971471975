import React from 'react'
import { IconButton, Icon, Divider, Alert } from 'rsuite'
import { Cell } from 'rsuite-table'

const ActionCell = ({ rowData, dataKey, toggleAddMode, handleEditOrCopy, handleDeleteCoupon, ...props }) => {
  const handleAction = (action) => {
    switch (action) {
      case 'copy':
        handleEditOrCopy(rowData, 'copy')
        return toggleAddMode()
      case 'edit':
        if (rowData?.stats?.distinct_users) return Alert.error('已有人領取，禁止修改！', 3000)
        handleEditOrCopy(rowData)
        return toggleAddMode()
      case 'delete':
        return handleDeleteCoupon(rowData)
      default:
        return null
    }
  }

  return (
    <Cell className="action-cell" {...props}>
      {rowData.promo_type !== 'SERIAL_NUMBER' && (
        <>
          <IconButton appearance="subtle" onClick={() => handleAction('edit')} icon={<Icon icon="edit2" />} />
          <Divider vertical />
        </>
      )}
      <IconButton appearance="subtle" onClick={() => handleAction('copy')} icon={<Icon icon="copy-o" />} />
      <Divider vertical />
      <IconButton appearance="subtle" onClick={() => handleAction('delete')} icon={<Icon icon="trash2" />} />
    </Cell>
  )
}

export default React.memo(ActionCell)
