import React, { useCallback } from 'react'
import { Table, Icon, IconButton } from 'rsuite'
import styled from 'styled-components'

const IconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

// iconAndHanldlers: (rowData, dataKey) => [{ icon: '', handler: () => {}, style: {}, className: '' }]
const ActionCell = React.memo(({ rowData, dataKey, iconAndHandlers, ...props }) => {
  const key = rowData[dataKey]

  const renderIcons = useCallback(() => {
    return iconAndHandlers(rowData, dataKey).map((action) => (
      <IconButton
        key={action.icon}
        style={action.style || {}}
        className={action.className || ''}
        appearance="subtle"
        icon={<Icon icon={action.icon || 'eye'} />}
        onClick={action.handler}
      />
    ))
  }, [rowData, dataKey, iconAndHandlers])

  if (!key) return null
  return (
    <Table.Cell {...props}>
      <IconWrapper>{renderIcons()}</IconWrapper>
    </Table.Cell>
  )
})

export default ActionCell
