import React from 'react'
import { Breadcrumb } from 'rsuite'

function BreadcrumbNav({ start, entry, onClick }) {
	return (
		<Breadcrumb>
      <Breadcrumb.Item onClick={onClick}>{start}</Breadcrumb.Item>
      <Breadcrumb.Item active>{entry}</Breadcrumb.Item>
		</Breadcrumb>
	)
}

export default React.memo(BreadcrumbNav)
