/* eslint-disable consistent-return */
import React, { useState, useEffect, useContext } from 'react'
import { Alert, Loader } from 'rsuite'
import { isEmpty } from 'lodash'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import { ContextStore } from '../../reducers'
import { SearchInput, TableController } from '../../components/table'
import { ChargeStatusCell, InvoiceStatusTag, FileInput } from '../../components/finance'
import { getDriverSystemFeePayments, postDriverSystemFeePayments, putDriverSystemFeeRefund } from '../../api'
import { ListWrap, AddListModel, FormGrop, AddListGrop, BreadcrumbNav } from '../../components/views'
import { DateCell, ButtonCell } from '../../components/table/cell'
import PopWarning from '../../components/popWarning'
import usePageSetting from '../../hook/usePageSetting'

const initialState = {
  limit: 100,
  offset: 0,
}

const FinanceDriverSystemFee = () => {
  const { auth } = useContext(ContextStore)
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [userId, setUserId] = useState(null)
  const [searchWord, setSearchWord] = useState(null)
  const { isAddMode, list, currentInfo, warning, toggleAddMode, setList, setCurrentInfo, setWarning } = usePageSetting(initialState)

  const fetchDriverSystemFeePayments = async (limit, offset, driverId) => {
    const response = await getDriverSystemFeePayments(limit, offset, driverId)
    if (response.status !== 200) return Alert.error(response.data.msg)
    const { data } = response.data
    if (isEmpty(data)) Alert.error('未搜尋到任何紀錄')
    window.history.replaceState(null, null, `${window.location.pathname}?limit=${limit}&offset=${offset}&driver=${driverId}`)
    setList(data)
    setCurrentInfo({ ...currentInfo, offset: data.length === limit ? offset + data.length : offset })
  }

  const handleSearch = () => {
    const { limit, offset } = currentInfo
    if (!searchWord) return
    setCurrentInfo({ offset: 0 })
    fetchDriverSystemFeePayments(limit, offset, searchWord)
  }

  const handleChangeFile = (e) => {
    const file = e.target.files[0]
    if (file) setFile(file)
  }

  const handleSubmit = async () => {
    if (!userId || !file) return

    const formData = new FormData()
    formData.append('uploaded_file', file)
    setLoading(true)
    const response = await postDriverSystemFeePayments(formData)
    setLoading(false)
    if (response.status !== 200) return setWarning(response.data.msg)
    setWarning('上傳成功！請到信箱收取報表')
    setFile(null)
  }

  const handleDriverRefund = async (data) => {
    const { limit, offset } = currentInfo
    const { id, driver_id } = data
    const response = await putDriverSystemFeeRefund({ id, driver_id })
    if (response.status !== 200) return setWarning(response.data.msg)
    setWarning('已申請退款/取消！')
    fetchDriverSystemFeePayments(limit, offset, searchWord)
  }

  useEffect(() => {
    const toggleSpecificTrip = () => {
      const { limit, offset } = currentInfo
      const params = new URLSearchParams(window.location.search)
      const driverId = params.get('driver')
      if (driverId) {
        setSearchWord(driverId)
        fetchDriverSystemFeePayments(limit, offset, driverId)
      }
    }

    toggleSpecificTrip()
    if (auth && auth.user) setUserId(auth.user.admin_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  return (
    <>
      {warning && <PopWarning warning={warning} textCenter confirm={() => setWarning('')} />}
      {isAddMode ? (
        <AddListModel>
          <BreadcrumbNav
            start="平台系統設定費"
            entry="新增紀錄"
            onClick={() => {
              setSearchWord('')
              toggleAddMode()
              setList([])
              window.history.replaceState(null, null, window.location.pathname)
            }}
          />
          {loading && <Loader size="md" center vertical style={{ marginLeft: 260, zIndex: 10 }} />}
          <AddListGrop title="上傳名單">
            <FormGrop title="上傳檔案" type="children">
              <FileInput handleChangeFile={handleChangeFile} />
            </FormGrop>
          </AddListGrop>
          <div className="campaign-button-wrap">
            <button type="button" className="campaign-confirm-button" onClick={handleSubmit}>
              送出
            </button>
          </div>
        </AddListModel>
      ) : (
        <>
          <ListWrap status="平台系統設定費 - 入帳/取消" addListText="+ 新增紀錄" onClick={toggleAddMode}>
            <SearchInput
              title="搜尋司機 ID"
              value={searchWord}
              setSearchWord={setSearchWord}
              withButton
              onClick={handleSearch}
              onKeyDown={(e) => {
                if (e.keyCode === 13) handleSearch()
              }}
            />
          </ListWrap>
          <TableController data={list} topHeight={104}>
            <Column flexGrow={1.5} align="center">
              <HeaderCell>ID</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column flexGrow={2} align="center">
              <HeaderCell>DID</HeaderCell>
              <Cell dataKey="driver_id" />
            </Column>
            <Column flexGrow={1} align="center">
              <HeaderCell>金額</HeaderCell>
              <Cell dataKey="amount" />
            </Column>
            <Column flexGrow={2} align="center">
              <HeaderCell>扣款狀態</HeaderCell>
              <ChargeStatusCell dataKey="status" />
            </Column>
            <Column flexGrow={2.5} align="center">
              <HeaderCell>扣款時間</HeaderCell>
              <DateCell dataKey="fund_time" format="YYYY-MM-DD HH:mm" />
            </Column>
            <Column flexGrow={2.5} align="center">
              <HeaderCell>最後更新時間</HeaderCell>
              <DateCell dataKey="last_update" format="YYYY-MM-DD HH:mm" />
            </Column>
            <Column flexGrow={2} align="center">
              <HeaderCell>Charge ID</HeaderCell>
              <Cell dataKey="charge_id" />
            </Column>
            <Column flexGrow={2} align="center">
              <HeaderCell>發票號碼</HeaderCell>
              <InvoiceStatusTag />
            </Column>
            <Column flexGrow={2} align="center">
              <HeaderCell>Action</HeaderCell>
              <ButtonCell title="退款/取消" handleClick={handleDriverRefund} size="xs" />
            </Column>
          </TableController>
        </>
      )}
    </>
  )
}

export default FinanceDriverSystemFee
