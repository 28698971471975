const transPolygonStr = (value) => {
  let polygonStr = ''
  const arr = value || JSON.parse(value)[0]

  // 拼湊成 Polygon 要求的格式
  arr.forEach((point, index) => {
    const { lat, lng } = point
    if (index === 0) {
      polygonStr += '((' + lng + ' ' + lat + ','
    } else if (index === arr.length - 1) {
      polygonStr += lng + ' ' + lat + '))'
    } else {
      polygonStr += lng + ' ' + lat + ','
    }
  })
  return polygonStr
}

export { transPolygonStr }