import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Button, Icon, Input, InputGroup, Loader, Tag } from 'rsuite'
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'
import { Heading, Title, Wrapper, HStack } from '../driver_mileage/styles'
import { getDriverInfo, blockUser, unblockUser, getModifyTable2 } from '../../api'
import BlockDriverModal from '../../components/blocked_history/BlockDriverModal'
import UnblockDriverModal from '../../components/blocked_history/UnblockDriverModal'
import StatusLabel from '../../components/blocked_history/StatusLabel'
import BlockDriversBatchModal from '../../components/blocked_history/BlockDriversBatchModal'

const TABLE_COLUMNS = [
  { label: '建立時間', dataKey: 'created_time', width: 110, format: (v) => dateFormat(v) },
  { label: '封鎖項目', dataKey: 'reason', width: 250 },
  { label: '開始時間', dataKey: 'start_time', width: 110, format: (v) => dateFormat(v) },
  { label: '結束時間', dataKey: 'end_time', width: 110, format: (v) => dateFormat(v) },
  { label: 'Note', dataKey: 'note', width: 185, style: { letterSpacing: '.3px' } },
  { label: '操作人員', dataKey: 'blocked_by_name', width: 100 },
  { label: '解除者', dataKey: 'unblocked_by_name', width: 100 },
  { label: '狀態', dataKey: 'status', width: 90 },
  { label: '動作', dataKey: 'action', width: 80 },
]

const dateFormat = (v) => {
  if (v === -1) return '永久'
  return dayjs(v * 1000).format('YYYY-MM-DD HH:mm')
}

const BlockedHistory = () => {
    const {driver_id = ''} = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [driverId, setDriverId] = useState(driver_id)
    const [driver, setDriver] = useState(null)
    const [logs, setLogs] = useState([])
    const [popup, setPopup] = useState(false)
    const [unblockPopup, setUnblockPopup] = useState(false)
    const [blockDriversBatchPopup, setBlockDriversBatchPopup] = useState(false)
    const [unblockId, setUnblockId] = useState(null)
    const [suggestions, setSuggestions] = useState([])
    const [suggestionsMap, setSuggestionsMap] = useState(new Map())
    const history = useHistory()

  useEffect(() => {
    handleSearch()
    getBlockedSuggestion()
  }, [])

  const handleSearchInputChange = (value) => setDriverId(value)
  const handleSearchClick = async () => {
    if (driver_id === driverId) {
      await handleSearch()
      return
    }
    history.push(`/blocked_history/${driverId}`)
  }

  const handleSearch = async () => {
    if (!driverId) return

    setIsLoading(true)
    const { data: { data } = {}, status } = await getDriverInfo(driverId, { q: '{basic,blocked_history,blocked_status}' })
    setIsLoading(false)
    if (status !== 200) {
      Alert.error('driver info fetch failed')
      return
    }
    if (!data?.basic) {
      setDriver(null)
      Alert.error('沒有此司機資料')
      return
    }

    setDriver({
      uid: driverId,
      name: `${data.basic.last_name}${data.basic.first_name}`,
      blocked_status: data.blocked_status,
    })
    setLogs(data.blocked_history || [])
  }

  const getBlockedSuggestion = async () => {
    const data = await getModifyTable2({ table: 'BlockedSuggestion', conditions: [{ key: 'limit', value: 100 }] })
    if (data.status !== 200) {
      Alert.error('blocked suggestion fetch failed')
      return
    }
    const rows = data?.data?.data?.get?.data || []
    setSuggestions(rows.map((v) => ({ label: v.title, value: v.id })))
    setSuggestionsMap(rows.reduce((accu, curr) => accu.set(curr.id, curr.content), new Map()))
  }

  const submit = async (payload) => {
    const {
      data: { message },
    } = await blockUser({
      user_id: driverId,
      start_time: payload.startTime,
      end_time: payload.interval === -1 ? -1 : payload.startTime + 86400 * payload.interval,
      reason: payload.reason,
      note: payload.note,
    })
    if (message) {
      Alert.error(message)
      return
    }
    await handleSearch()
    setPopup(false)
  }

  const popupUnblock = (id) => {
    setUnblockPopup(true)
    setUnblockId(id)
  }

  const unblock = async (notes) => {
    const {
      data: { message },
    } = await unblockUser({
      id: unblockId,
      notes,
    })
    if (message) {
      Alert.error(message)
      return
    }
    await handleSearch()
    setUnblockPopup(false)
  }

    return (
        <Wrapper>
            <Heading>司機接單封鎖</Heading>
            <Title>司機ID</Title>
            <HStack style={{justifyContent: 'space-between', minWidth: '450px'}}>
                <div style={{display: 'flex', columnGap: '10px', alignItems: 'baseline'}}>
                    <div>
                        <InputGroup>
                            <Input value={driverId} placeholder="請輸入司機ID" onChange={handleSearchInputChange}
                                   onPressEnter={handleSearchClick}/>
                            <InputGroup.Addon>
                                <Icon icon="search"/>
                            </InputGroup.Addon>
                        </InputGroup>
                    </div>
                    <Button appearance="primary" onClick={handleSearchClick} disabled={!driverId || isLoading}>
                        查詢
                    </Button>
                    {isLoading && <Loader/>}
                    {driver && driver.blocked_status && <Tag color="red">封鎖中</Tag>}
                </div>
                <div style={{display: 'flex', columnGap: '10px', alignItems: 'baseline'}}>
                    <div>
                        <Button onClick={() => setPopup(true)} style={{height: '100%'}} color="red" disabled={!driver}>
                            新增封鎖
                        </Button>
                    </div>
                    <div>
                        <Button onClick={() => setBlockDriversBatchPopup(true)} style={{height: '100%'}} color="red">
                            批量封鎖司機
                        </Button>
                    </div>
                </div>
            </HStack>
            <Table minHeight={450} autoHeight data={logs} loading={isLoading} bordered wordWrap>
                {TABLE_COLUMNS.map((v) => (
                    <Column key={v.dataKey} width={v.width} verticalAlign="middle">
                        <HeaderCell>{v.label}</HeaderCell>
                        <Cell>
                            {(rowData) => {
                                if (v.dataKey === 'action') {
                                    return <Button onClick={() => popupUnblock(rowData.id)}>取消</Button>
                                }
                                if (v.dataKey === 'status') {
                                    return <StatusLabel startTime={rowData.start_time} endTime={rowData.end_time}/>
                                }
                                return (
                                    <span style={{wordBreak: 'break-word', ...(v.style && {...v.style})}}>
                    {v.format ? v.format(rowData[v.dataKey], rowData) : rowData[v.dataKey]}
                  </span>
                                )
                            }}
                        </Cell>
                    </Column>
                ))}
            </Table>
            <BlockDriverModal
                show={popup}
                close={setPopup.bind(this, false)}
                confirm={submit}
                driver={driver}
                suggestions={suggestions}
                suggestionsMap={suggestionsMap}
            />
            <UnblockDriverModal show={unblockPopup} close={setUnblockPopup.bind(this, false)} confirm={unblock}/>
            <BlockDriversBatchModal show={blockDriversBatchPopup} close={setBlockDriversBatchPopup.bind(this, false)}/>
        </Wrapper>
    )
}

export default BlockedHistory
