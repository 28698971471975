import React, { Component } from 'react'

export class PopWarning extends Component {
	render() {
		const { warning, confirm, isCancel, cancel, textCenter, cancelText, confirmText, innerHTML, isClose, closePop, bottomImg, popImg, zIndex } = this.props
		return (
			<React.Fragment>
				<div className="popWarning-wrap" style={{ zIndex: zIndex && zIndex + 1 }}>
					{isClose && <div className="popWarning-close-area" onClick={closePop} >
						<img src='../../../static/img/index/m-close.svg' alt="" />
					</div>}
					<div className="popWarning-text-wrap">
						{popImg &&
							<div className="popWarning-header-img" dangerouslySetInnerHTML={{ __html: popImg }}>
							</div>}
						{innerHTML
							? <div className={`body_01 popWarning-title ${textCenter && ' text-center'}`} dangerouslySetInnerHTML={{ __html: warning }}></div>
							: <div className={`body_01 popWarning-title ${textCenter && ' text-center'}`}>{warning}</div>}
						{bottomImg && <img src={bottomImg} alt="" className="popWarning-img" />}
					</div>
					<div className="popWarning-btn-wrap">
						{isCancel && <button className="popWarning-btn" onClick={cancel}>{cancelText ? cancelText : '取消'}</button>}
						<button className="popWarning-btn" onClick={confirm}>{confirmText ? confirmText : '確認'}</button>
					</div>
				</div>
				<div className="black-background z-index100" style={{ zIndex: zIndex && zIndex }}></div>
			</React.Fragment>
		)
	}
}

export default PopWarning
