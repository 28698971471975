import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'
import { Panel, Pagination } from 'rsuite'
import { getDriversViceContractLog } from '../../api'

const TABLE_COLUMNS = [
  { label: 'ID', dataKey: 'id', width: 80 },
  { label: '變更前', dataKey: 'before', width: 300, format: (v) => jsonFormat(v) },
  { label: '變更後', dataKey: 'after', width: 300, format: (v) => jsonFormat(v) },
  { label: '操作人員', dataKey: 'operator', width: 100 },
  { label: '操作時間', dataKey: 'created_at', width: 150, format: (v) => dateFormat(v) },
]

const jsonFormat = (v) => (v?.length ? v.join(', ') : '-')
const dateFormat = (v) => {
  if (!v) return '-'
  return dayjs(v).format('YYYY-MM-DD HH:mm')
}

const DriversViceContractHistory = () => {
  const { driver_id = '' } = useParams()
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const limit = 5
  const filteredData = data.filter((v, i) => {
    const start = limit * (page - 1)
    const end = start + limit
    return i >= start && i < end
  })

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    if (!driver_id) return

    setIsLoading(true)
    const { data: { data } = {}, status } = await getDriversViceContractLog(driver_id)
    setIsLoading(false)
    setData(data)
  }

  return (
    <Panel bordered bodyFill style={{ paddingBottom: 12 }}>
      <Table style={{ minWidth: 400, height: 300 }} autoHeight data={filteredData} loading={isLoading} wordWrap>
        {TABLE_COLUMNS.map((v) => (
          <Column key={v.dataKey} width={v.width} align={v.align} verticalAlign="middle">
            <HeaderCell>{v.label}</HeaderCell>
            <Cell>
              {(rowData) => {
                return (
                  <span style={{ color: '#575757', wordBreak: 'break-word', ...(v.style && { ...v.style }) }}>
                    {v.format ? v.format(rowData[v.dataKey], rowData) : rowData[v.dataKey]}
                  </span>
                )
              }}
            </Cell>
          </Column>
        ))}
      </Table>
      <Pagination
        prev
        next
        maxButtons={1}
        size="xs"
        activePage={page}
        onSelect={setPage}
        pages={Math.ceil(data.length / limit)}
        style={{ marginTop: 12, marginRight: 12, float: 'right' }}
      />
    </Panel>
  )
}

export default DriversViceContractHistory
