import React from 'react'
// import { debounce } from 'lodash'
import { Input, InputGroup, Icon } from 'rsuite'

const SearchInput = ({ title, setSearchWord, withButton, onClick, onKeyDown, value }) => {
  // const debounceChange = debounce((text) => {
  //   setSearchWord(text)
  // }, 500)

  return (
    <InputGroup size="sm" style={{ maxWidth: '20%', margin: '5px' }}>
      <Input value={value} placeholder={title || '搜尋名稱（區分大小寫）'} type="text" onChange={setSearchWord} onKeyDown={onKeyDown} />
      {withButton && (
        <InputGroup.Button onClick={onClick}>
          <Icon icon="search" />
        </InputGroup.Button>
      )}
    </InputGroup>
  )
}

export default React.memo(SearchInput)
