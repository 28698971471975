import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Button, Icon, Input, InputGroup, Loader, Tag } from 'rsuite'
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'
import { Heading, Title, Wrapper } from '../driver_mileage/styles'
import { canceledReservation, getDriverInfo, postCanceledReservation, putCanceledReservation, toggleReservation } from '../../api'
import AddModal from '../../components/canceled_reservation/AddModal'
import EditModal from '../../components/canceled_reservation/EditModal'
import ToggleModal from '../../components/canceled_reservation/ToggleModal'
import StatusLabel from '../../components/canceled_reservation/StatusLabel'

const TABLE_COLUMNS = [
  { label: '建立時間', dataKey: 'time', width: 150, format: (v) => dateFormat(v) },
  { label: '狀態', dataKey: 'status', width: 200 },
  { label: 'Trip ID', dataKey: 'trip_id', width: 100 },
  { label: 'Note', dataKey: 'note', width: 250, style: { letterSpacing: '.3px' } },
  { label: '動作', dataKey: 'action', width: 80, align: 'center' },
]

const dateFormat = (v) => {
  return dayjs(v * 1000).format('YYYY-MM-DD HH:mm')
}

const CanceledReservation = () => {
  const { driver_id = '' } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [driverId, setDriverId] = useState(driver_id)
  const [driver, setDriver] = useState(null)
  const [logs, setLogs] = useState([])
  const [popup, setPopup] = useState(false)
  const [unblockPopup, setUnblockPopup] = useState(false)
  const [unblockId, setUnblockId] = useState(null)
  const [tripId, setTripId] = useState(null)
  const [togglePopup, setTogglePopup] = useState(false)
  const [toggleAction, setToggleAction] = useState('off')
  const history = useHistory()

  useEffect(() => {
    handleSearch()
  }, [])

  const handleSearchInputChange = (value) => setDriverId(value)
  const handleSearchClick = async () => {
    if (driver_id === driverId) {
      await handleSearch()
      return
    }
    history.push(`/canceled_reservation/${driverId}`)
  }

  const handleSearch = async () => {
    if (!driverId) return

    setIsLoading(true)
    const { data: { data } = {}, status } = await getDriverInfo(driverId, { q: '{basic}' })
    setIsLoading(false)
    if (status !== 200) {
      Alert.error('driver info fetch failed')
      return
    }

    if (!data?.basic) {
      setDriver(null)
      Alert.error('沒有此司機資料')
      return
    }

    const { data: _logs } = await canceledReservation(driverId)
    setDriver({
      uid: driverId,
      name: `${data.basic.last_name}${data.basic.first_name}`,
    })
    setLogs(_logs || [])
    setToggleAction(_logs.some((v) => v.status === 'SYS_BLOCKED') ? 'on' : 'off')
  }

  const isExistedTrip = (tripId) => {
    return logs
      .filter((v) => v.status !== 'CANCELLED' && v.trip_id)
      .map((v) => v.trip_id)
      .includes(Number(tripId))
  }

  const submit = async (payload) => {
    if (isExistedTrip(payload.tripId)) {
      Alert.warning('此封鎖預約行程已存在')
      return
    }
    const {
      data: { message },
    } = await postCanceledReservation({
      driver_id: driverId,
      trip_id: payload.tripId,
      note: payload.note,
    })
    if (message) {
      Alert.error(message)
      return
    }
    await handleSearch()
    setPopup(false)
  }

  const popupUnblock = (id, tripId) => {
    setUnblockPopup(true)
    setUnblockId(id)
    setTripId(tripId)
  }

  const unblock = async (note) => {
    const {
      data: { message },
    } = await putCanceledReservation(unblockId, {
      driver_id: driverId,
      trip_id: tripId,
      note,
    })
    if (message) {
      Alert.error(message)
      return
    }
    await handleSearch()
    setUnblockPopup(false)
  }

  const toggle = async () => {
    const {
      data: { message },
    } = await toggleReservation({
      enable: toggleAction,
      driver_id: driverId,
    })
    if (message) {
      Alert.error(message)
      return
    }
    await handleSearch()
    setTogglePopup(false)
  }

  return (
    <Wrapper>
      <Heading>預約單封鎖</Heading>
      <Title>司機ID</Title>
      <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: 600 }}>
        <div style={{ display: 'flex', columnGap: 10, alignItems: 'baseline' }}>
          <div>
            <InputGroup>
              <Input value={driverId} placeholder="請輸入司機ID" onChange={handleSearchInputChange} onPressEnter={handleSearchClick} />
              <InputGroup.Addon>
                <Icon icon="search" />
              </InputGroup.Addon>
            </InputGroup>
          </div>
          <Button appearance="primary" onClick={handleSearchClick} disabled={!driverId || isLoading}>
            查詢
          </Button>
          {isLoading && <Loader />}
          {driver && <StatusLabel logs={logs} />}
        </div>
        <div>
          <Button onClick={() => setTogglePopup(true)} style={{ height: '100%' }} disabled={!driver}>
            {toggleAction === 'on' ? '開啟預約功能' : '關閉預約功能'}
          </Button>
          <Button onClick={() => setPopup(true)} style={{ height: '100%', marginLeft: 20 }} color="cyan" disabled={!driver}>
            手動新增
          </Button>
        </div>
      </div>
      <Table minHeight={450} autoHeight data={logs} loading={isLoading} bordered wordWrap>
        {TABLE_COLUMNS.map((v) => (
          <Column key={v.dataKey} width={v.width} align={v.align} verticalAlign="middle">
            <HeaderCell>{v.label}</HeaderCell>
            <Cell>
              {(rowData) => {
                if (v.dataKey === 'action') {
                  if (rowData.status === 'CANCELLED') return <></>
                  return (
                    <Button size="sm" color="red" onClick={() => popupUnblock(rowData.id, rowData.trip_id)}>
                      解除
                    </Button>
                  )
                }
                if (v.dataKey === 'status') {
                  return <Tag>{rowData['status']}</Tag>
                }
                return (
                  <span style={{ wordBreak: 'break-word', ...(v.style && { ...v.style }) }}>
                    {v.format ? v.format(rowData[v.dataKey], rowData) : rowData[v.dataKey]}
                  </span>
                )
              }}
            </Cell>
          </Column>
        ))}
      </Table>
      <AddModal show={popup} close={setPopup.bind(this, false)} confirm={submit} driver={driver} />
      <EditModal show={unblockPopup} close={setUnblockPopup.bind(this, false)} confirm={unblock} />
      <ToggleModal show={togglePopup} close={setTogglePopup.bind(this, false)} confirm={toggle} action={toggleAction} />
    </Wrapper>
  )
}

export default CanceledReservation
