/* eslint-disable indent */
import React, { Component } from 'react'
import SwipeableViews from 'react-swipeable-views'

class Card extends Component {
	state = {
		index: 0
	}

	render() {
		const { filterData, form, addModel, currentTab } = this.props
		const SwipeableStyles = {
			root: {
				width: '204px',
				height: '300px',
				overflowX: 'initial !important',
			}
		}
		return (
			<div className="simulator-card">
				{!addModel && filterData &&
					filterData.map((list, index) => {
						return (
							<React.Fragment key={index}>
								<div className="card-title">
									<div className="adver-edition-title h5">{list.title}</div>
								</div>
								<div className="card-swipe-wrap">
									<SwipeableViews style={SwipeableStyles.root} index={this.state.index} >
										{list.cards.map((card, index) => {
											return (
												<div key={index} onClick={() => window.open(card.action.payload)}>
													<div className="adver-card-wrap">
														<div className="adver-card-img">
															<img className="" src={card.img} alt="" />
														</div>
														<div className="adver-card-content">
															<h4 className="h7">{card.desc}</h4>
															<div className="content-source body_03">{card.title}</div>
														</div>
													</div>
												</div>
											)
										})}
									</SwipeableViews>
								</div>
							</React.Fragment>)
					})
				}
				{form && addModel && currentTab.tab === 'CARD' &&
					<React.Fragment>
						<div className="card-title">
							<div className="adver-edition-title h5">{form.group_title}</div>
						</div>
						<div className="card-swipe-wrap">
							<div onClick={() => window.open(form.action.payload)}>
								<div className="adver-card-wrap">
									<div className="adver-card-img">
										<img className="" src={form.img} alt="" />
									</div>
									<div className="adver-card-content">
										<div className="h7">{form.description}</div>
										<div className="content-source body_03">{form.title}</div>
									</div>
								</div>
							</div>
						</div>
					</React.Fragment>}

			</div>
		)
	}
}

export default Card
