import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Alert, Button, Icon, Input, InputGroup, Loader, Panel, Avatar, Message } from 'rsuite'
import { Heading, Wrapper, PanelItem } from './styles'
import { searchUser } from '../../api'

const SearchUser = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const query = searchParams.get('query') || ''
  const [keyword, setKeyword] = useState(query)
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState(null)
  const history = useHistory()

  useEffect(() => {
    handleFetchUser()
  }, [query])

  const handleSearch = () => {
    if (!keyword) return
    if (keyword === query) return
    history.push({
      pathname: '/user_info',
      search: `?${new URLSearchParams({ query: keyword })}`,
    })
  }

  const handleRedirect = () => history.push(`/user_info/${user.uid}`)
  const handleKeywordChange = (value) => setKeyword(value)
  const handleFetchUser = async () => {
    setUser(null)
    if (!query) return

    setIsLoading(true)
    const { data, status } = await searchUser({ query: `+886${query.slice(1)}` })
    setIsLoading(false)
    if (status !== 200) {
      Alert.error('search user failed')
      return
    }

    if (!data) return
    setUser(data)
  }

  return (
    <Wrapper className="search-user">
      <Heading>用戶資訊</Heading>
      <div style={{ display: 'flex', columnGap: 12, alignItems: 'center' }}>
        <InputGroup style={{ width: 250 }}>
          <InputGroup.Addon>+886</InputGroup.Addon>
          <Input value={keyword} placeholder="0912345678" onChange={handleKeywordChange} onPressEnter={handleSearch} />
          <Button onClick={handleSearch} disabled={!keyword || isLoading}>
            <Icon icon="search" />
          </Button>
        </InputGroup>
        {isLoading && <Loader />}
      </div>
      {user && (
        <Panel bordered bodyFill style={{ width: 200, padding: '10px 0' }}>
          <PanelItem>
            <Avatar circle src={user.profile_image_url} />
          </PanelItem>
          <PanelItem style={{ flexDirection: 'column', color: '#444', textAlign: 'center' }}>
            <span>{user.first_name}</span>
            <span>{user.phone_number}</span>
          </PanelItem>
          <PanelItem>
            <Button onClick={handleRedirect} appearance="ghost">
              用戶資訊
            </Button>
          </PanelItem>
        </Panel>
      )}
      {!isLoading && query && !user && <Message description="查無用戶"></Message>}
    </Wrapper>
  )
}

export default SearchUser
