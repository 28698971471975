import { ACTION } from '../constants/actions'

const initialState = {
	error: null,
	logingStatus: 'LOADING',
	isLogin: false,
	user: null,
	isLoadingGetUser: false,
	loading: true
}

function authReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION.SET_CHECK_LOGIN_STATUS:
			return {
				...state,
				logingStatus: action.logingStatus
			}
		case ACTION.SET_USER_AUTHENTICATED:
			return {
				...state,
				isLogin: action.isLogin
			}
		case ACTION.SET_USER:
			return {
				...state,
				user: action.user,
				isLoadingGetUser: false
			}
		case ACTION.LOGOUT:
			return {
				...state,
				isLogin: false,
				user: null
			}
		case ACTION.LOADING:
			return {
				...state,
				loading: action.loading
			}
		default:
			return state
	}
}

export { initialState, authReducer }
