import React, { useEffect, useState, useCallback } from 'react'
import { DatePicker, Input, Modal } from 'rsuite'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import { isEmpty } from 'lodash'
import { getRewards, createReward, deleteReward } from '../../api'
import { filterRewards } from './helper'
import { transLocation } from '../../utils/map'
import usePageSetting from '../../hook/usePageSetting'
import { WhisperCell, DateCell, ActionCell } from '../../components/table/cell'
import { ListWrap, AddListModel, FormGrop, FormGropWrap, AddListGrop, BreadcrumbNav, FormBottomButtons } from '../../components/views'
import { MapCell, PreviewMap, GeoGenerator } from '../../components/map'
import PopWarning from '../../components/popWarning'
import { TableController, MoreButton, TableFilter } from '../../components/table'
import "react-datepicker/dist/react-datepicker.css"

const initCurrentInfo = {
	id: undefined,
	title: '',
	startDate: '',
	endDate: '',
	reward: 0,
	cap: 0,
	area: [],
	titleError: '',
	startDateError: '',
	endDateError: '',
	rewardError: '',
	capError: '',
	areaError: '',
}

const Rewards = () => {
	const { isAddMode, list, currentInfo, warning, toggleAddMode, handleOnChange, handleDatePicker, setList, setCurrentInfo, setWarning } = usePageSetting(initCurrentInfo)
	const [inProgress, setInProgress] = useState(false)
	const [searchWord, setSearchWord] = useState('')
	const [showMoreButton, setMoreButton] = useState(true)
	const [editLocationModal, setEditLocationModal] = useState('')

	// init
	useEffect(() => {
		fetchRewards()
	}, [])

	const fetchRewards = async (currentNum) => {
		const limit = 10
		let page = 1
		if (currentNum) page = (currentNum / limit) + 1
		const response = await getRewards(page, limit)
		if (response.status !== 200) return console.log(response.data)
		const responseData = response.data
		if (responseData.length < limit) setMoreButton(false)
		const newData = page !== 1 ? list.concat(responseData) : responseData
		console.log('fetch', newData)
		setList(newData)
	}

	const handlePostActivity = async () => {
		const { title, startDate, endDate, reward, cap, area } = currentInfo
		// check if required input is empty
		setCurrentInfo({
			...currentInfo,
			titleError: !title? '請填寫或選擇項目': '',
			startDateError: !startDate? '請填寫或選擇項目': '',
			endDateError: !endDate? '請填寫或選擇項目': '',
			rewardError: reward === 0 ? '請填寫或選擇項目': '',
		})
		if (!title || !startDate || !endDate || reward === 0) return setWarning('欄位不能為空！')
		if (isEmpty(area)) return setWarning('請設定獎勵範圍')

		// call api
		let body = {
			title,
			start_time: startDate,
			end_time: endDate,
			reward,
			cap,
			area
		}
		console.log('body', body)
		const response = await createReward(body)
		if (response.status !== 200) return setWarning('新增失敗，請稍候再嘗試')
		setWarning('新增成功！')
		toggleAddMode()
	}

	const handleCopyActivity = (data) => {
		const { title, start_time, end_time, area, reward, cap } = data

		setCurrentInfo({
			id: undefined,
			title: title || '',
			startDate: start_time || '',
			endDate: end_time || '',
			reward: reward,
			cap: cap,
			area: transLocation(area) || [],
			titleError: '',
			startDateError: '',
			endDateError: '',
			rewardError: '',
			capError: '',
			areaError: '',
		})
	}

	const handleDeleteActivity = async (id) => {
		let body = { data: { reward_id: id }} 
		const response = await deleteReward(body)
		if (response && response.status !== 200) {
			let errMessage = '刪除失敗，請稍候再嘗試'
			if (response.data) errMessage = response.data.msg
			return setWarning(errMessage)
		}
		setWarning('刪除成功')
	}

	const handleWarningConfirm = () => {
		setWarning('')
		setList([])
		fetchRewards()
	}

	const handleClose = useCallback(() => {
    setEditLocationModal('')
  }, [])

return (
	<>
		<div style={{ width: '100%'}}>
		{!isEmpty(editLocationModal) && <Modal size={'lg'} show={!isEmpty(editLocationModal)} onHide={handleClose} >
			<GeoGenerator
				type="range"
				value={currentInfo.area} 
				handleClose={handleClose}
				setStateKey={editLocationModal}
				setEditLocation={setEditLocationModal}
				handleOnChange={handleOnChange}/>
		</Modal>}
		{warning && <PopWarning warning={warning} textCenter={true} confirm={handleWarningConfirm} />}
		{isAddMode ? (
			<AddListModel >
				<BreadcrumbNav start="Rewards" entry="Create Rewards" onClick={toggleAddMode} />
				<AddListGrop title={'新增接單獎勵'}>
					{currentInfo.id  && <FormGrop title={'活動 ID'} type='children'><Input value={currentInfo.id} disabled /></FormGrop>}
					<FormGrop title={'活動名稱*'} placeholder={'Title'} type={'input'} value={currentInfo.title} handleOnChange={handleOnChange} setStateKey={'title'} errorMessage={currentInfo.titleError} />
					<FormGropWrap>
						<FormGrop title={'活動開始'} type={'children'} errorMessage={currentInfo.startDateError}>
							<DatePicker
								defaultValue={currentInfo.startDate ? new Date(currentInfo.startDate * 1000) : undefined}
								onOk={(date) => handleDatePicker(date, 'startDate')}
								placement={'bottomStart'}
								appearance={'subtle'}
								placeholder={'選擇日期時間'}
								isoWeek
								hideMinutes={second => second % 5 !== 0}
								format={'YYYY-MM-DD HH:mm'}
								ranges={[{ value: new Date(), closeOverlay: true }]} />
						</FormGrop>
						<FormGrop title={'活動結束'} type={'children'} errorMessage={currentInfo.endDateError}>
							<DatePicker
								defaultValue={currentInfo.endDate ? new Date(currentInfo.endDate * 1000) : undefined}
								onOk={(date) => handleDatePicker(date, 'endDate')}
								placement={'bottomStart'}
								placeholder={'選擇日期時間'}
								appearance={'subtle'}
								isoWeek
								hideMinutes={second => second % 5 !== 0}
								format={'YYYY-MM-DD HH:mm'}
								ranges={[{ value: new Date(), closeOverlay: true }]} />
						</FormGrop>
					</FormGropWrap>
					<FormGrop title={'獎勵*'} placeholder={'Reward'} type={'number'} value={currentInfo.reward} handleOnChange={handleOnChange} setStateKey={'reward'} errorMessage={currentInfo.rewardError} />
					<FormGrop title={'設置上限'} placeholder={'Limit'} type={'number'} value={currentInfo.cap} handleOnChange={handleOnChange} setStateKey={'cap'} errorMessage={currentInfo.capError} />
					<PreviewMap
						title={'獎勵範圍'}
						mapId={'rewards-map'}
						latlngs={currentInfo.area}
						setEditLocation={setEditLocationModal}
						setStateKey={'area'}
					/>
				</AddListGrop>
				<FormBottomButtons
          confirmText={currentInfo.id ? '更新' : '新增'}
          cancelText="取消"
          confirm={handlePostActivity}
          cancel={toggleAddMode}
        />
			</AddListModel>
		) : (
			<div>
				<ListWrap status={'接單獎勵'} addListText={'+ 新增'} onClick={toggleAddMode}>
					<TableFilter
						inProgress={inProgress}
						searchWord={searchWord}
						setInProgress={setInProgress}
						setSearchWord={setSearchWord}
					/>
				</ListWrap>
				<TableController data={filterRewards(searchWord, inProgress, list)} topHeight={104} rowHeight={230}>
					<Column width={50} flexGrow={0} align="center">
						<HeaderCell>ID</HeaderCell>
						<Cell dataKey="id" />
					</Column>
					<Column width={140} flexGrow={2}>
						<HeaderCell>活動標題</HeaderCell>
						<WhisperCell dataKey="title" />
					</Column>
					<Column width={80} flexGrow={2}>
						<HeaderCell>開始時間</HeaderCell>
						<DateCell dataKey="start_time" defaultKey="created_at" format="YYYY-MM-DD HH:mm" />
					</Column>
					<Column width={80} flexGrow={2}>
						<HeaderCell>結束時間</HeaderCell>
						<DateCell dataKey="end_time" defaultValue="未設定" format="YYYY-MM-DD HH:mm" />
					</Column>
					<Column width={50} align="center">
						<HeaderCell>獎勵</HeaderCell>
						<Cell dataKey="reward" />
					</Column>
					<Column width={50} align="center">
						<HeaderCell>上限</HeaderCell>
						<Cell dataKey="cap" />
					</Column>
					<Column minWidth={250} flexGrow={2} align="center">
						<HeaderCell>獎勵範圍</HeaderCell>
						<MapCell dataKey="area" />
					</Column>
					<Column width={100}>
						<HeaderCell>複製 / 刪除</HeaderCell>
						<ActionCell toggle={toggleAddMode} handleCopy={handleCopyActivity} handleDelete={handleDeleteActivity} />
					</Column>
				</TableController>
				{showMoreButton && <MoreButton onClick={()=> fetchRewards(list.length)} />}
			</div>
			)}
		</div>
	</>
	)
}

export default Rewards
