import React, { useState, useRef, useEffect } from 'react'
// import styled from 'styled-components'
import mapboxgl from 'mapbox-gl'
import { isEmpty } from 'lodash'
import createGeoJSONCircleSource from './helper/createGeoJSONCircleSource'
import createSource from './helper/createSource'
import createLayer from './helper/createLayer'

const DATE_OPTIONS = {
  hour12: false,
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
  timeZone: 'Asia/Taipei',
}
const LOCALIZED = {
  cash: '現金',
  creditcard: '信用卡',
  company: '企業簽單',
  collectable: '平台代墊',
  linepay: 'LINE Pay',
  applepay: 'Apple Pay',
  googlepay: 'Google Pay',
  native: 'LINE TAXI APP',
  line: 'LINE',
  facebook: 'Facebook',
  app: '店家叫車',
  USER_REQUESTED: '用戶叫車',
  USER_RESERVED_TIME: '用戶用車時間',
  TRIP_STARTED: '行程開始',
  TRIP_CANCELED: '行程取消',
  ENROUTE_PICKUP: '司機前往中',
  REQUESTING_DRIVER: '尋找司機中',
  PENDING_RESPONSE_DRIVER: '尋找司機中',
  WAITING_SPECIFY: '等待司機接單中',
  WAITING_DISTRIBUTION: '司機接受等候派單中',
  DRIVER_ASSIGNED: '等待司機接單中（先搶先贏）',
  DRIVER_ACCEPTED: '司機指派任務',
  DRIVER_REJECTED: '司機拒絕',
  DRIVER_RESERVED: '司機已預約',
  DRIVER_ENROUTE: '司機前往中',
  DRIVER_ARRIVED: '司機已到達',
  TRIP_FINISHED: '行程結束',
  DRIVER_CALL_RIDER: '司機撥打電話給乘客',
  TRIP_PAYMENT_PENDING: '等待付款中',
  TRIP_PAYMENT_PROCESSED: '付款完成',
  ESTIMATE: '設定地址',
}

/*
  NOTE:
  - non_trip_related_point 其他派單司機
  - before_trip_features 前往載客路徑
  - during_trip_features 行程路徑
*/

const TripMap = ({ basic, trip_updates, routes, estimatedData, arrivalRange, focusType }) => {
  const [map, setMap] = useState(null)
  const mapContainer = useRef(null)
  const [suggestRoutes, setSuggestRoutes] = useState(0)
  const [markers, setMarkers] = useState([])
  const [positions, setPositions] = useState({})

  // 搬來 zendesk 的地圖設置
  useEffect(() => {
    const initializeMap = ({ setMap, mapContainer }) => {
      mapboxgl.accessToken = 'pk.eyJ1IjoiaGF5ZGVuaHVhbmciLCJhIjoiY2trbTN6d3ltMm15djJ1bW44dDBhem0xeSJ9.PE4hASOrSF1YW0UXgg4Yhw'
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/haydenhuang/ckknffx0m0a6t17lbsd1hnk5m',
        center: [121.5396627, 25.0407284],
        zoom: 12,
        locale: 'zh-tw',
      })

      map.on('load', () => {
        setMap(map)
        map.resize()
      })

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      })

      // 鼠標移到行程路徑
      map.on('mouseenter', 'during_trip_features', (e) => {
        // Change the cursor style as a UI indicator.
        map.getCanvas().style.cursor = 'pointer'

        const coordinates = e.features[0].geometry.coordinates.slice()
        const { description } = e.features[0].properties

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        popup.setLngLat(coordinates).setHTML(description).addTo(map)
      })
      map.on('mouseleave', 'during_trip_features', () => {
        map.getCanvas().style.cursor = ''
        popup.remove()
      })

      // 鼠標移到前往載客路徑
      map.on('mouseenter', 'before_trip_features', (e) => {
        // Change the cursor style as a UI indicator.
        map.getCanvas().style.cursor = 'pointer'

        const coordinates = e.features[0].geometry.coordinates.slice()
        const { description } = e.features[0].properties

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        popup.setLngLat(coordinates).setHTML(description).addTo(map)
      })
      map.on('mouseleave', 'before_trip_features', () => {
        map.getCanvas().style.cursor = ''
        popup.remove()
      })
      // 鼠標移到接單司機
      map.on('mouseenter', 'non_trip_related_point', (e) => {
        // Change the cursor style as a UI indicator.
        map.getCanvas().style.cursor = 'pointer'
        const coordinates = e.features[0].geometry.coordinates.slice()
        const { description } = e.features[0].properties

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }
        // Populate the popup and set its coordinates
        // based on the feature found.
        popup.setLngLat(coordinates).setHTML(description).addTo(map)
      })
      map.on('mouseleave', 'non_trip_related_point', () => {
        map.getCanvas().style.cursor = ''
        popup.remove()
      })
      // 鼠標移到司機已抵達
      map.on('mouseenter', 'driver_arrived_pin', (e) => {
        // Change the cursor style as a UI indicator.
        map.getCanvas().style.cursor = 'pointer'
        const coordinates = e.features[0].geometry.coordinates.slice()
        const { description } = e.features[0].properties

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }
        // Populate the popup and set its coordinates
        // based on the feature found.
        popup.setLngLat(coordinates).setHTML(description).addTo(map)
      })
      map.on('mouseleave', 'driver_arrived_pin', () => {
        map.getCanvas().style.cursor = ''
        popup.remove()
      })
    }

    if (!map) initializeMap({ setMap, mapContainer })
  }, [map])

  useEffect(() => {
    async function setRoute() {
      const tripMakers = []
      let startLatlng
      if (map) {
        // REMOVE: 移除 layer 和 source
        if (map.getLayer('during_trip_features')) map.removeLayer('during_trip_features')
        if (map.getSource('during_trip_features')) map.removeSource('during_trip_features')
        if (map.getLayer('before_trip_features')) map.removeLayer('before_trip_features')
        if (map.getSource('before_trip_features')) map.removeSource('before_trip_features')
        if (map.getLayer('non_trip_related_point')) map.removeLayer('non_trip_related_point')
        if (map.getSource('non_trip_related_point')) map.removeSource('non_trip_related_point')
        if (map.getLayer('driver_arrived_pin')) map.removeLayer('driver_arrived_pin')
        if (map.getSource('driver_arrived_pin')) map.removeSource('driver_arrived_pin')
        if (map.getLayer('start_location_range')) map.removeLayer('start_location_range')
        if (map.getSource('start_location_range')) map.removeSource('start_location_range')
        // REMOVE: 移除建議路線
        for (let i = 0; i <= suggestRoutes; i += 1) {
          if (map.getLayer(`route-${i}`)) map.removeLayer(`route-${i}`)
          if (map.getSource(`route-${i}`)) map.removeSource(`route-${i}`)
        }
        // REMOVE: 移除 marker
        if (markers && markers.length) {
          markers.forEach((marker) => marker.remove())
        }

        if (basic) {
          const { start_latlng = {}, stops = [], lastAddress = {} } = basic
          // 標示上車點
          if (start_latlng) {
            startLatlng = [start_latlng.y, start_latlng.x]
            tripMakers.push(new mapboxgl.Marker({ color: '#2ea628' }).setLngLat([start_latlng.y, start_latlng.x]).addTo(map))
          }
          // 標示停靠點
          if (!isEmpty(stops)) {
            stops.forEach((stop) => {
              tripMakers.push(new mapboxgl.Marker({ color: '#e06b1d' }).setLngLat([stop.latlng.y, stop.latlng.x]).addTo(map))
            })
          }
          // 標示下車點/最後停靠點
          const { latlng: lastLatlng } = lastAddress
          if (lastLatlng) {
            tripMakers.push(new mapboxgl.Marker({ color: '#e06b1d' }).setLngLat([lastLatlng.y, lastLatlng.x]).addTo(map))
          }
        }
        setMarkers(tripMakers)
      }
      const bounds = []

      // 預估路線
      const estimatedPath = []
      if (estimatedData) {
        estimatedData.forEach((estimated) => {
          const path = []
          estimated.estimate.multi_directions.forEach((multi_directions) => {
            multi_directions.forEach((route) => {
              // eslint-disable-next-line no-use-before-define
              const decoded_path = google.maps.geometry.encoding.decodePath(route)
              decoded_path.forEach((latlng) => {
                path.push([latlng.lng(), latlng.lat()])
                bounds.push([latlng.lng(), latlng.lat()])
              })
            })
          })
          estimatedPath.push(path)
        })
      }

      if (routes) {
        let mergeDetails = []
        routes.forEach((pin) => mergeDetails.push(pin))

        trip_updates.forEach(({ update_type, last_update, uid }) => {
          if (update_type !== 'DRIVER_CALL_RIDER') {
            mergeDetails.push({
              uid,
              event: update_type,
              timestamp: last_update,
            })
          }
        })
        mergeDetails = mergeDetails.sort((a, b) => a.timestamp - b.timestamp)

        const userDetails = []
        mergeDetails.forEach((item) => {
          if (!userDetails[item.uid]) {
            userDetails[item.uid] = {
              uid: item.uid,
              details: mergeDetails.filter((path) => path.uid === item.uid),
            }
          }
        })

        userDetails.forEach((user) => {
          let status
          let location
          const path = []
          user.details.forEach((detail) => {
            if (detail.event) {
              status = detail.event
              if (location) {
                path.push({
                  uid: detail.uid,
                  event: detail.event,
                  location,
                  timestamp: detail.timestamp,
                })
              }
            }
            if (detail.location) {
              location = detail.location
              if (status) {
                path.push({
                  uid: detail.uid,
                  event: status,
                  location: detail.location,
                  timestamp: detail.timestamp,
                })
              }
            }
          })
          // eslint-disable-next-line no-param-reassign
          user.path = path
        })

        const otherDriversFeatures = []
        const duringTripFeatures = []
        const beforeTripFeatures = []
        userDetails.forEach((user) => {
          // 其他派單司機
          if (user.uid !== basic.driver_id) {
            user.path.forEach((pin) => {
              const datetime = new Date(pin.timestamp * 1000).toLocaleString('zh-tw', DATE_OPTIONS)
              otherDriversFeatures.push({
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [pin.location.y, pin.location.x],
                },
                properties: {
                  description: `其他司機 ${pin.uid} @${datetime} ${LOCALIZED[pin.event]}`,
                },
              })
              bounds.push([pin.location.y, pin.location.x])
            })
          } else {
            user.path.forEach((pin) => {
              const dateTime = new Date(pin.timestamp * 1000).toLocaleString('zh-tw', DATE_OPTIONS)
              if (['TRIP_STARTED', 'DRIVER_ARRIVED', 'TRIP_FINISHED', 'TRIP_PAYMENT_PENDING', 'TRIP_PAYMENT_PROCESSED'].indexOf(pin.event) === -1) {
                beforeTripFeatures.push({
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [pin.location.y, pin.location.x],
                  },
                  properties: {
                    description: `接單司機 ${pin.uid} @${dateTime} ${LOCALIZED[pin.event] || pin.event}`,
                  },
                })
              } else {
                duringTripFeatures.push({
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [pin.location.y, pin.location.x],
                  },
                  properties: {
                    description: `接單司機 ${pin.uid} @${dateTime} ${LOCALIZED[pin.event] || pin.event}`,
                  },
                })
              }
              bounds.push([pin.location.y, pin.location.x])
            })
          }
        })
        if (map) {
          // 新增 LT-8341：圈出上車點範圍
          map.addSource('start_location_range', createGeoJSONCircleSource(startLatlng, arrivalRange * 0.001))
          map.addLayer(createLayer('start_location_range', 'fill', {}, { 'fill-color': '#fffa5e', 'fill-opacity': 0.7 }))

          // 建議路線
          estimatedPath.forEach((path, index) => {
            const sourceId = `route-${index}`
            map.addSource(sourceId, {
              type: 'geojson',
              data: {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: path,
                },
              },
            })
            map.addLayer(
              createLayer(
                sourceId,
                'line',
                {
                  'line-join': 'round',
                  'line-cap': 'round',
                },
                {
                  'line-color': index === 0 ? '#32CD32' : '#a9dbad',
                  'line-width': 6,
                },
              ),
            )
          })
          setSuggestRoutes(estimatedPath.length)
          // 行程路徑
          map.addSource('during_trip_features', createSource.setGeoJSON(duringTripFeatures))
          map.addLayer(createLayer('during_trip_features', 'circle', {}, { 'circle-radius': 5, 'circle-color': '#3197de' }))
          // 前往載客路徑
          map.addSource('before_trip_features', createSource.setGeoJSON(beforeTripFeatures))
          map.addLayer(createLayer('before_trip_features', 'circle', {}, { 'circle-radius': 5, 'circle-color': '#88a1b3' }))
          // 新增 LT-8341：司機滑已抵達後的第一個 Pin 點
          let driverArrivedPin = null
          const { trips_driver_arrived } = basic
          if (trips_driver_arrived && trips_driver_arrived?.driver_location?.x) {
            const { driver_location, distance, driver_id, created_at } = trips_driver_arrived
            const dateTime = new Date(created_at * 1000).toLocaleString('zh-tw', DATE_OPTIONS)
            driverArrivedPin = {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [driver_location.y, driver_location.x],
              },
              properties: {
                description: `接單司機 ${driver_id} @${dateTime} 司機滑已抵達，目的地距離${distance}m`,
              },
            }
            map.addSource('driver_arrived_pin', createSource.setGeoJSON([driverArrivedPin]))
            map.addLayer(createLayer('driver_arrived_pin', 'circle', {}, { 'circle-radius': 5, 'circle-color': '#f6003c' }))
          }
          // 其他派單司機
          map.addSource('non_trip_related_point', createSource.setGeoJSON(otherDriversFeatures))
          map.addLayer(createLayer('non_trip_related_point', 'circle', {}, { 'circle-radius': 5, 'circle-color': '#995781' }))

          // 讓畫面平移到路線區域
          const real_bounds = bounds.reduce((real_bounds, coordinates) => {
            return real_bounds.extend(coordinates)
          }, new mapboxgl.LngLatBounds(bounds[0], bounds[0]))
          map.fitBounds(real_bounds, { padding: 50 })
          // 存 position
          setPositions({
            START_ADDRESS: startLatlng,
            START_RANGE: startLatlng,
            DRIVER_ARRIVED: driverArrivedPin?.geometry?.coordinates,
          })
        }
      }
    }
    const { google } = window
    if (google) setRoute()
  }, [routes, trip_updates, estimatedData, basic, map])

  useEffect(() => {
    if (!focusType || !map) return
    const position = positions[focusType]
    position && map.flyTo({ center: position, zoom: 17 })
  }, [focusType, positions, map])

  return (
    <div className="card">
      {/* eslint-disable-next-line no-return-assign */}
      <div ref={(el) => (mapContainer.current = el)} style={{ minWidth: '50vw', minHeight: '600px' }} />
    </div>
  )
}

export default TripMap
