import React from 'react'
import styled from 'styled-components'
import { Cell } from 'rsuite-table'
import { Popover, Whisper } from 'rsuite'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'

const format = 'YYYY-MM-DD HH:mm:ss'

const Hexagon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  ${props => `
    background: ${props.status}
  `}
`
const handleBackgroundColor = (status) => {
  switch (status) {
    case 'SUCCESS':
      return '#26D892'
    case 'CANCELED':
      return '#F2F25B'
    case 'ERROR':
      return '#EB4D4C'
    default:
      return '#eee'
  }
}

const Record = React.memo(({ data }) => {
  const { id, job, status, error_log, exec_start_at, exec_end_at, setting_json } = data

  const speaker = (
		<Popover title="" visible>
			{exec_start_at && <p>{`開始時間：${dayjs(exec_start_at * 1000).format(format)}`}</p>}
      {exec_end_at && <p>{`結束時間：${dayjs(exec_end_at * 1000).format(format)}`}</p>}
      {exec_start_at && exec_end_at && <p>{`花費時間：${dayjs(exec_end_at*1000).diff(dayjs(exec_start_at*1000), 'second')}s`}</p>}
      <p>{`結果： ${status}`}</p>
      {!isEmpty(error_log) && <p>{error_log}</p>}
		</Popover>
	)

  return (
    <div>
      <Whisper placement="topRight" speaker={speaker}>
        <Hexagon status={handleBackgroundColor(status)}/>
      </Whisper>
    </div>
  )
})

const RecordCell = ({ rowData,...props }) => {
  const records = rowData.recent_records.reverse() || []
  return (
    <Cell {...props} style={{ display: 'flex', justifyContent: 'center', gap: 5 }}>
      {records.map(data => {
        return <Record data={data} key={data.id} />
      })}
    </Cell>
  )
}

export default React.memo(RecordCell)