import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { Button, Radio, RadioGroup } from 'rsuite'
import { Plus } from '@rsuite/icons'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { airflowRuns, getUserGroup, postDamaPushMessage, postDamaImage, getCoupons } from '../../api'
import { createModel } from '../../utils'
import {
  StyledIconButton,
  StyledConfirmButton,
  StyledPageButton,
  StyledDatePicker,
  PageButtonWrapper,
  Breadcrumb,
  Wrapper,
  ButtonsWrapper,
  ItemWrapper,
  Title,
  SubTitle,
  Content,
  CustomCellWrapper,
  RadioWrapper,
  SendTestCampaignWrapper,
} from './styles'
import CreateCouponFields from './CreateCouponFields'
import CreateFlexMessage from './CreateFlexMessage'
import CreateTextMessage from './CreateTextMessage'
import createFlexMessageJSON from './helper/createFlexMessageJSON'
import CustomCell from './CustomCell/index'
import NewPopWarning from '../../components/popWarning/NewPopWarning'

const VALIDATION = {
  NO_DATA: '請輸入 Promo ID 或是建立分眾推播',
  TEXT_IS_TEMP: '輸入框不能為空',
  ACTIONS_IS_TEMP: '請選擇版型並填輸入 action',
  NO_PROMO_ID: '請輸入 Promo ID',
  NO_EXECUTION_TIME: '請選擇指定時間',
}
const CAMPAIGN_TYPE = {
  COUPON: 'COUPON',
  AUDIENCE: 'AUDIENCE',
  COUPON_AND_AUDIENCE: 'COUPON_AND_AUDIENCE',
}
const EXECUTION_TYPE = {
  RIGHT_ROW: 'RIGHT_ROW',
  SET_DATE: 'SET_DATE',
  FOLLOW_COUPON_EXECUTION: 'FOLLOW_COUPON_EXECUTION',
}

const ACTION_EXECUTE_TIME = {
  COUPON: {
    NOTIFICATION: false,
    COUPONS: true,
  },
  AUDIENCE: {
    NOTIFICATION: true,
    COUPONS: false,
  },
  COUPON_AND_AUDIENCE: {
    NOTIFICATION: true,
    COUPONS: true,
  },
}

const MESSAGE_TYPE = {
  IMAGEMAP: 'imagemap',
  FLEX_MESSAGE: 'flexMessage',
  TEXT: 'text',
}

const AddEvent = React.memo(() => {
  const [campaignId, setCampaignId] = useState('')
  const [campaignData, setCampaignData] = useState([])
  const [couponFields, setCouponFields] = useState([])
  const [actions, setActions] = useState([])
  const [campaignOffset, setCampaignOffset] = useState(0)
  const [campaignType, setCampaignType] = useState('')
  const [couponExecutionType, setCouponStartType] = useState(EXECUTION_TYPE.SET_DATE)
  const [couponExecutionTime, setCouponExecutionTime] = useState('')
  const [notificationExecutionType, setNotificationStartType] = useState(EXECUTION_TYPE.SET_DATE)
  const [notificationExecutionTime, setNotificationExecutionTime] = useState('')

  const handleOnClickAddCouponField = () => {
    if (couponFields.length > 0) return
    setCouponFields([...couponFields, { text: '', id: dayjs().valueOf() }])
  }
  const handleFilterCouponField = useCallback(
    (id) => {
      const filter = couponFields.filter((item) => item.id !== id)
      setCouponFields(filter)
    },
    [couponFields],
  )
  const handleOnChangeCouponFields = (value, id) => {
    const fields = couponFields.map((item) => (item.id === id ? { ...item, text: value } : item))
    setCouponFields(fields)
  }
  const handleOnClickAddActions = (isTextMessage) => {
    isTextMessage
      ? setActions([...actions, { text: '', id: dayjs().valueOf(), type: 'text' }])
      : setActions([...actions, { title: '', id: dayjs().valueOf(), type: 'imagemap' }])
  }
  const handleFilterActions = useCallback(
    (id) => {
      const filterActions = actions.filter((item) => item.id !== id)
      setActions(filterActions)
    },
    [actions],
  )
  const handleOnChangeTextMessage = (value, id) => {
    const newActions = actions.map((item) => (item.id === id ? { ...item, text: value } : item))
    setActions(newActions)
  }
  const handleOnChangeFlexMessage = (value, type, id) => {
    const newActions = actions.map((item) => (item.id === id ? { ...item, [type]: value } : item))
    setActions(newActions)
  }
  const inputValidation = useMemo(() => {
    if (isEmpty(couponFields) && isEmpty(actions)) return VALIDATION.NO_DATA
    if (campaignType === CAMPAIGN_TYPE.COUPON) {
      if (couponExecutionType === EXECUTION_TYPE.SET_DATE && !couponExecutionTime) return VALIDATION.NO_EXECUTION_TIME
      return couponFields.some((coupon) => !coupon.text) ? VALIDATION.NO_PROMO_ID : null
    }
    if (notificationExecutionType === EXECUTION_TYPE.SET_DATE && !notificationExecutionTime) return VALIDATION.NO_EXECUTION_TIME
    if (campaignType === CAMPAIGN_TYPE.COUPON_AND_AUDIENCE) {
      if (couponFields.some((coupon) => !coupon.text)) return VALIDATION.NO_PROMO_ID
      if (couponExecutionType === EXECUTION_TYPE.SET_DATE && !couponExecutionTime) return VALIDATION.NO_EXECUTION_TIME
    }
    if (isEmpty(actions)) return VALIDATION.ACTIONS_IS_TEMP
    if (actions.some((item) => item.type === MESSAGE_TYPE.TEXT && !item.text)) return VALIDATION.TEXT_IS_TEMP
    if (actions.some((item) => item.type === MESSAGE_TYPE.IMAGEMAP && (!item.title || !item.imgUrl))) return VALIDATION.TEXT_IS_TEMP
    if (actions.some((item) => item.type === MESSAGE_TYPE.IMAGEMAP && isEmpty(item.actions))) return VALIDATION.ACTIONS_IS_TEMP
    if (actions.some((item) => item.type === MESSAGE_TYPE.IMAGEMAP && item.actions.some((action) => !action.type || !action.value)))
      return VALIDATION.ACTIONS_IS_TEMP
    return null
  }, [actions, campaignType, couponFields, couponExecutionType, couponExecutionTime, notificationExecutionType, notificationExecutionTime])
  const handleConfirm = async () => {
    const coupons = couponFields.map((item) => item.text)
    const notifications_setting_list = actions.map((item) => {
      if (item.type === MESSAGE_TYPE.FLEX_MESSAGE) {
        // 目前還沒有支援 flexMessage
        return null
      }
      if (item.type === MESSAGE_TYPE.IMAGEMAP) {
        return { type: item.type, ...createFlexMessageJSON(item.title, item.imgUrl, item.actions) }
      }
      return item
    })

    // 是否指定時間
    let notificationExecuteTime = '0'
    let couponsExecuteTime = '0'

    if ([CAMPAIGN_TYPE.COUPON, CAMPAIGN_TYPE.COUPON_AND_AUDIENCE].includes(campaignType)) {
      couponsExecuteTime = couponExecutionType === EXECUTION_TYPE.RIGHT_ROW ? '0' : couponExecutionTime
    }
    if ([CAMPAIGN_TYPE.AUDIENCE, CAMPAIGN_TYPE.COUPON_AND_AUDIENCE].includes(campaignType)) {
      if (notificationExecutionType === EXECUTION_TYPE.SET_DATE) {
        notificationExecuteTime = notificationExecutionTime
      } else if (notificationExecutionType === EXECUTION_TYPE.FOLLOW_COUPON_EXECUTION) {
        notificationExecuteTime = couponExecutionTime
      }
    }
    const action_push_notification = { execTime: notificationExecuteTime, execute: ACTION_EXECUTE_TIME[campaignType].NOTIFICATION }
    const action_issue_coupons = { execTime: couponsExecuteTime, execute: ACTION_EXECUTE_TIME[campaignType].COUPONS }

    const body = {
      coupons: [CAMPAIGN_TYPE.COUPON, CAMPAIGN_TYPE.COUPON_AND_AUDIENCE].includes(campaignType) ? coupons : [],
      notifications_setting_list,
      id: campaignId,
      schedule_at: '0',
      actions: {
        action_issue_coupons,
        action_push_notification,
      },
    }
    const data = await airflowRuns(body)
    if (data.status !== 200) return createModel(<NewPopWarning createPortal warning={data.msg || '發生錯誤，請稍後再試'} textCenter />)
    return createModel(<NewPopWarning createPortal warning="建立成功！" textCenter />)
  }
  const getCampaignIdData = async () => {
    const params = { limit: 10, offset: campaignOffset * 10 }
    const data = await getUserGroup(params)
    if (data.status !== 200) return createModel(<NewPopWarning createPortal warning={data.msg || '發生錯誤，請稍後再試'} textCenter />)
    return setCampaignData(data.data)
  }
  const handleCampaignType = (value) => {
    if (campaignType === value) return
    setCampaignType(value)
  }
  const handleCouponRadio = (value) => {
    if (couponExecutionType === value) return
    setCouponStartType(value)
  }
  const handleNotificationRadio = (value) => {
    if (notificationExecutionType === value) return
    setNotificationStartType(value)
  }
  const isDisplayFollowCouponRadio = () => {
    return campaignType === CAMPAIGN_TYPE.COUPON_AND_AUDIENCE && couponExecutionType === EXECUTION_TYPE.SET_DATE && couponExecutionTime
  }
  const convertActions = useCallback(() => {
    return actions.map((action) => {
      if (action.type === 'text') return action
      const subActions = action.actions.map((item) => ({
        id: item.id,
        type: item.type,
        area: item.area,
        [item.type === 'uri' ? 'linkUri' : 'text']: item.value,
      }))
      return {
        baseSize: {
          width: 1040,
          height: 1040,
        },
        id: action.id,
        baseUrl: action.imgUrl,
        altText: action.title,
        type: action.type,
        actions: subActions,
      }
    })
  }, [actions])
  const handlePushMessageClick = async () => {
    const convertedActions = convertActions()
    const data = await postDamaPushMessage({ messages: convertedActions })
    if (!isEmpty(data.data)) {
      const details = data?.data?.details || []
      createModel(
        <NewPopWarning
          createPortal
          warning={details.map((detail) => (
            <>
              {detail.message}
              <br />
            </>
          ))}
          textCenter
        />,
      )
    }
  }
  const handlePostImage = async (file) => {
    const formData = new FormData()
    formData.append('file', file.blobFile)
    formData.append('doc_type', 'imageMap')
    const data = await postDamaImage('marketing', formData)
    if (data.status !== 200) {
      // createModel(<NewPopWarning createPortal warning={data.msg || '發生錯誤，請稍後再試'} textCenter />)
      return null
    }
    return data?.data
  }
  useEffect(() => {
    if (campaignOffset < 0) return
    getCampaignIdData()
  }, [campaignOffset])
  return (
    <Wrapper>
      <Content>
        <Breadcrumb>
          <span>DaMaPlus /</span>
          <span>Add Campaign</span>
        </Breadcrumb>
        <Title>選取分眾名單</Title>
        <ItemWrapper>
          <Button appearance="ghost" block onClick={getCampaignIdData}>
            連結 BigQuery拿取名單
          </Button>
        </ItemWrapper>
        {!isEmpty(campaignData) && (
          <CustomCellWrapper>
            <CustomCell data={campaignData} setCampaignId={setCampaignId} />
            <PageButtonWrapper>
              {campaignOffset !== 0 && (
                <StyledPageButton appearance="ghost" onClick={() => setCampaignOffset(campaignOffset - 1)}>
                  上一頁
                </StyledPageButton>
              )}
              {campaignData.length >= 10 && (
                <StyledPageButton appearance="ghost" onClick={() => setCampaignOffset(campaignOffset + 1)}>
                  下一頁
                </StyledPageButton>
              )}
            </PageButtonWrapper>
          </CustomCellWrapper>
        )}
        {campaignId && (
          <>
            <Title>活動模式</Title>
            <ItemWrapper>
              <RadioGroup name="radioList" inline onChange={(value) => handleCampaignType(value)}>
                <Radio value={CAMPAIGN_TYPE.COUPON}>只發券</Radio>
                <Radio value={CAMPAIGN_TYPE.AUDIENCE}>只推播</Radio>
                <Radio value={CAMPAIGN_TYPE.COUPON_AND_AUDIENCE}>發券+推播</Radio>
              </RadioGroup>
            </ItemWrapper>
          </>
        )}
        {[CAMPAIGN_TYPE.COUPON, CAMPAIGN_TYPE.COUPON_AND_AUDIENCE].includes(campaignType) && (
          <>
            <Title>新增分眾發券</Title>
            <ItemWrapper>
              <Button appearance="ghost" block onClick={handleOnClickAddCouponField}>
                + 新增一組 Promo ID
              </Button>
              {!isEmpty(couponFields) && (
                <RadioGroup
                  name="radioList"
                  defaultValue={couponExecutionType || EXECUTION_TYPE.SET_DATE}
                  onChange={(value) => handleCouponRadio(value)}
                >
                  <SubTitle>發券執行時間</SubTitle>
                  <Radio value={EXECUTION_TYPE.RIGHT_ROW}>建立活動後立即發券</Radio>
                  <RadioWrapper>
                    <Radio value={EXECUTION_TYPE.SET_DATE}>指定日期時間發券</Radio>
                    <StyledDatePicker
                      disabled={couponExecutionType !== EXECUTION_TYPE.SET_DATE}
                      format="YYYY-MM-DD HH:mm:ss"
                      placeholder="選擇日期與時間"
                      ranges={[]}
                      onOk={(date) => setCouponExecutionTime(dayjs(date).unix())}
                      onClean={() => setCouponExecutionTime('')}
                      disabledDate={(date) => !dayjs().isBefore(dayjs(date).add(1, 'day'))}
                      disabledHours={(hour, date) => !dayjs().isBefore(dayjs(date).hour(hour))}
                      defaultValue={couponExecutionTime ? couponExecutionTime * 1000 : null}
                    />
                  </RadioWrapper>
                </RadioGroup>
              )}
              {couponFields.map((item) => (
                <CreateCouponFields
                  key={item.id}
                  getCoupons={getCoupons}
                  handleOnChangeCouponFields={handleOnChangeCouponFields}
                  handleFilterCouponField={() => handleFilterCouponField(item.id)}
                  value={item.text}
                  id={item.id}
                />
              ))}
            </ItemWrapper>
          </>
        )}
        {[CAMPAIGN_TYPE.AUDIENCE, CAMPAIGN_TYPE.COUPON_AND_AUDIENCE].includes(campaignType) && (
          <>
            <Title>分眾推播</Title>
            <ItemWrapper>
              <ButtonsWrapper>
                <StyledIconButton appearance="default" icon={<Plus />} onClick={() => handleOnClickAddActions(true)}>
                  新增文字訊息
                </StyledIconButton>
                <StyledIconButton appearance="default" icon={<Plus />} onClick={() => handleOnClickAddActions(false)}>
                  新增圖文訊息
                </StyledIconButton>
              </ButtonsWrapper>
              {!isEmpty(actions) && (
                <>
                  <RadioGroup
                    name="radioList"
                    defaultValue={notificationExecutionType || EXECUTION_TYPE.SET_DATE}
                    onChange={(value) => handleNotificationRadio(value)}
                  >
                    <SubTitle>發券推播時間</SubTitle>
                    <Radio value={EXECUTION_TYPE.RIGHT_ROW}>建立活動後立即推播</Radio>
                    {isDisplayFollowCouponRadio() && <Radio value="FOLLOW_COUPON_EXECUTION">跟著發券進行</Radio>}
                    <RadioWrapper>
                      <Radio value={EXECUTION_TYPE.SET_DATE}>指定日期時間推播</Radio>
                      <StyledDatePicker
                        disabled={notificationExecutionType !== EXECUTION_TYPE.SET_DATE}
                        format="YYYY-MM-DD HH:mm:ss"
                        placeholder="選擇日期與時間"
                        ranges={[]}
                        onOk={(date) => setNotificationExecutionTime(dayjs(date).unix())}
                        onClean={() => setNotificationExecutionTime('')}
                        disabledDate={(date) => !dayjs().isBefore(dayjs(date).add(1, 'day'))}
                        disabledHours={(hour, date) => !dayjs().isBefore(dayjs(date).hour(hour))}
                        defaultValue={notificationExecutionTime ? notificationExecutionTime * 1000 : null}
                      />
                    </RadioWrapper>
                  </RadioGroup>
                  <SendTestCampaignWrapper>
                    <Button appearance="ghost" onClick={handlePushMessageClick}>
                      推播給自己
                    </Button>
                  </SendTestCampaignWrapper>
                </>
              )}
              {actions.map((item) =>
                item.type === 'text' ? (
                  <CreateTextMessage
                    key={item.id}
                    handleOnChangeTextMessage={handleOnChangeTextMessage}
                    handleFilterActions={() => handleFilterActions(item.id)}
                    value={item.text}
                    id={item.id}
                  />
                ) : (
                  <CreateFlexMessage
                    key={item.id}
                    handleOnChangeFlexMessage={handleOnChangeFlexMessage}
                    handleFilterActions={() => handleFilterActions(item.id)}
                    handlePostImage={handlePostImage}
                    id={item.id}
                    title={item.title || ''}
                    imgUrl={item.imgUrl || ''}
                    setFlexMessage={setActions}
                    actions={actions}
                  />
                ),
              )}
            </ItemWrapper>
          </>
        )}
        <StyledConfirmButton block onClick={handleConfirm} disabled={inputValidation}>
          確認新增
        </StyledConfirmButton>
      </Content>
    </Wrapper>
  )
})

export default AddEvent
