import React, { useMemo } from 'react'
import { Popover, Whisper } from 'rsuite'
import { Cell } from 'rsuite-table'

const NameCell = ({ rowData, ...props }) => {
  const name = useMemo(() => {
    const { first_name, last_name } = rowData
    return `${last_name}${first_name}`
  }, [rowData])

  const speaker = (
    <Popover>
      <b>{name}</b>
    </Popover>
  )
  return (
    <Cell {...props}>
      <Whisper placement="top" speaker={speaker}>
        <p>{name}</p>
      </Whisper>
    </Cell>
  )
}

export default React.memo(NameCell)
